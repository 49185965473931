const currentUrl = window.location.href;
const getCDN_URL = () => {
  let CDN_URL = "";
  if (typeof currentUrl !== "undefined") {
    if (currentUrl.includes("stage.xcpep.com")) {
      CDN_URL = "https://d1ks1attulv6al.cloudfront.net";
    }
  }
  return CDN_URL;
};
export const CDN_URL = getCDN_URL();