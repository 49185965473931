
import CancelIcon from "@mui/icons-material/Cancel";
import {
    Box,
    Divider,
    Modal,
    Typography
} from "@mui/material";
import { CDN_URL } from '../../../utlis/CDN_URL';
import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

export interface IBOMChemicalCompositionPDFViwerProps {
    onCloseModal: () => void;
    isOpen: boolean;
    partRowData: any;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    maxHeight: "96vh",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    overflow: "auto",
};

export default function BOMChemicalCompositionPDFViwer(props: IBOMChemicalCompositionPDFViwerProps) {
    const { onCloseModal, isOpen, partRowData } = props;
    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);
    const [pdfFile, setPdfFile] = useState<any>();

    useEffect(() => {
        if (partRowData?.doc_url) {
            setPdfFile(`${CDN_URL}${partRowData.doc_url}`);
        }
    }, [partRowData]);

    console.log("pdfFile", pdfFile);
    

    const { pdfDocument, pdfPage } = usePdf({
        file: pdfFile,
        page,
        canvasRef,
    });

    // const { pdfDocument, pdfPage } = usePdf({
    //     file: 'https://d1ks1attulv6al.cloudfront.net/page_1/488048/2422-F00500-01-01-01-01-08/chemical_com_srmts.pdf',
    //     page,
    //     canvasRef,
    // });

    console.log("partRowData", partRowData);

    return (
        <Modal open={isOpen} onClose={onCloseModal} aria-labelledby="modal-title">
            <Box sx={style}>
                <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            width: "100%",
                            textAlign: "center",
                            marginRight: "-2rem",
                        }}
                    >
                       Fetch {partRowData && partRowData?.part_name} from Chemical Composition PDF
                    </Typography>
                    <CancelIcon sx={{ cursor: "pointer", margin: "0.5rem" }} onClick={onCloseModal} />
                </Box>
                <Divider sx={{ borderColor: "primary.light", margin: "0 1rem", mb: "1rem" }} />

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '90vh' }}>                    
                    <canvas ref={canvasRef} />
                    {/* {pdfFile ? (
                        <canvas
                            ref={canvasRef}
                            id="canvas"
                            title={partRowData?.part_name}
                        />
                    ) : (
                        <Typography>No PDF file available</Typography>
                    )} */}
                </Box>
            </Box>
        </Modal>
    );
}
