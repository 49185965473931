import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Theme,
  useTheme,
  Divider,
  Typography,
  Skeleton,
  Button,
  Autocomplete,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CountriesCurrency from "../../utlis/CountriesCurrency";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "../../api-services";
import { fontSize } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { Padding } from "@mui/icons-material";
import { debounce } from "lodash";
import { useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

export interface ICurrencyChangeProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setScenarioCount?: any;
  setApiCallMain?: any;
  apiCallMain?: any;
  currentCurrency: string; // New prop for current currency
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      borderBottomColor: "primary.light",
      "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
      "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
    },
  },
};

function getStyles(name: string, countryName: string, theme: Theme) {
  return {
    fontWeight:
      countryName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const validationSchema = yup.object({
  current_currency: yup.mixed().required("Please Select Currency"),
  conversion_factor: yup.number().nullable(),
});

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "33rem",
    height: "fit-content",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
    paddingBottom: "1rem",
  },

  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  errormsg: {
    fontSize: "0.75rem",
    color: "#d32f2f",
    height: "1rem",
  },
};

const useStyles = makeStyles(() => ({
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function CurrencyChangeDynamic(props: ICurrencyChangeProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { projectId,topVault } = useRouteParams<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isbuttonLoading, setIsButtonLoading] = React.useState<any>(false);
  const [isLoadingReset, setIsLoadingReset] = React.useState<boolean>(false);
  const [increaseCount, setIncreaseCount] = React.useState<any>(0);
  const [fromCurrency, setFromCurrency] = React.useState<any>([]);
  const [tabSelection, setTabSelection] = useState<any>(1);
  const [applyActionName, setApplyActionName] = useState<any>("");
  const [fetchLoading, setFetchLoading] = useState<any>(false);
  const [applyLoading, setApplyLoading] = useState<any>(false);
  const [onTypingText, setOnTypingText] = useState<any>("");
  const [exchangeLoading, setExchangeLoading] = useState<any>(false);
  const [countryList, setCountryList] = useState<any>([]);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [isShowExchangeRate,setIsShowExchangeRate] = useState<boolean>(false);
  const {
    isOpen,
    onCloseModal,
    setCount,
    setScenarioCount,
    setApiCallMain,
    apiCallMain,
    currentCurrency,
  } = props;
  const { url } = useRouteMatch();
  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, projectId: projectId,top_vault:topVault, },
      0
    )
      .then((res: any) => {
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const okayClicked = () => {
    setCount((prev: any) => prev + 1);
    setApiCallMain((prev: any) => prev + 1);
    formik.values.conversion_factor = null;
    setApplyActionName("");
    //@ts-ignore
    formik.values.current_currency = null;
    setFromCurrency([]);
    formik.values.conversion_factor_manually = null;
    setTabSelection(1);
    getCountryData();
    onCloseModal();
  };

  const resetClicked = () => {
    setIsLoadingReset(true);
    API.put(
      "/customer/costing_conversion/",
      {
        project: projectId,
        top_vault:topVault,
        clear_currency_filter: true,
        module: url?.includes("analysis")
          ? "Analysis"
          : url.includes("costingsystemlanding") ||
            url.includes("costingscenarioabbrlanding") ||
            url.includes("costingscenario")
          ? "costing_create"
          : url.includes("costingqualitycheck")
          ? "lpp"
          : "Costing",
      },
      {},
      0
    )
      .then(() => {
        setApiCallMain((prev: any) => prev + 1);
        setCount((prev: any) => prev + 1);
        setIsLoadingReset(false);
        setFromCurrency([]);
        formik.values.conversion_factor = null;
        setApplyActionName("");
        formik.values.current_currency = currentCurrency; // Reset to the currency from props
        formik.values.conversion_factor_manually = null;
        getCountryData();
        sessionStorage.setItem("currency", currentCurrency); // Reset to the currency from props
        setScenarioCount((prev: any) => prev + 1);
        onCloseModal();
      })
      .catch(() => {
        setIsLoadingReset(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      current_currency: currentCurrency, // Set initial value from props
      conversion_factor: null,
      conversion_factor_manually: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let newObj = Object.keys(values)
        .filter((key) => key != "conversion_factor_manually")
        .reduce((acc: any, key: any) => {
          acc[key] = values[key];
          return acc;
        }, {});
      setIsLoading(true);
      API.put(
        "/customer/costing_conversion/",
        {
          ...newObj,
          ...{
            module: url?.includes("analysis")
              ? "Analysis"
              : url.includes("costingsystemlanding") ||
                url.includes("costingscenarioabbrlanding") ||
                url.includes("costingscenario")
              ? "costing_create"
              : url.includes("costingqualitycheck")
              ? "lpp"
              : "Costing",
            is_manual: false,
            project: projectId,
            top_vault:topVault,
          },
        },
        {},
        0
      )
        .then((res: any) => {
          setIsLoading(false);
          setTabSelection(2);
          setApplyActionName("auto");
          setScenarioCount((prev: any) => prev + 1);
          sessionStorage.setItem("currency", res.data[0]?.current_currency);
          sessionStorage.setItem(
            "currencyrate",
            fromCurrency[0]?.currency_rate
          );
          sessionStorage.setItem("actionKey", "auto");
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
  });

  const onCancelClick = () => {
    setFromCurrency([]);
    formik.values.conversion_factor = null;
    setApplyActionName("");
    formik.values.current_currency = currentCurrency; // Reset to the currency from props
    formik.values.conversion_factor_manually = null;
    getCountryData();
    onCloseModal();
  };

  const handleChangee = (val: any) => {
    if (val) {
      setOnTypingText(val?.currency_code);
      setIncreaseCount((prev: any) => prev + 1);
      formik.values.current_currency = val?.currency_code;
    } else {
      getCountryData();
    }
  };

  const manualClicked = () => {
    setIsLoading(true);
    API.put(
      "/customer/costing_conversion/",
      {
        current_currency: formik.values.current_currency || "INR",
        conversion_factor:formik.values.conversion_factor_manually &&  1 / formik.values.conversion_factor_manually || "",
        module: url?.includes("analysis")
          ? "Analysis"
          : url.includes("costingsystemlanding") ||
            url.includes("costingscenarioabbrlanding") ||
            url.includes("costingscenario")
          ? "costing_create"
          : url.includes("costingqualitycheck")
          ? "lpp"
          : "Costing",
        is_manual: true,
        project: projectId,
        top_vault:topVault,
      },
      {},
      0
    )
      .then((res: any) => {
        setIsLoading(false);
        setTabSelection(2);
        setApplyActionName("mannual");
        sessionStorage.setItem(
          "currencyrate_mannual",
          //@ts-ignore
          formik.values.conversion_factor_manually
        );
        sessionStorage.setItem("currency", res.data[0]?.current_currency);
        sessionStorage.setItem("actionKey", "mannual");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getManuallyButtonStatus = () => {
    if (formik.values.current_currency === "") {
      return true;
      //@ts-ignore
    } else if (formik.values.conversion_factor_manually == null) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (formik.values.current_currency !== "") {
      setExchangeLoading(true);
      API.get(
        "/customer/costing_conversion/",
        {
          get_conversion_rate: true,
          project: projectId,
          top_vault:topVault,
          to_currency: currentCurrency || "INR", // Use currentCurrency from props
          from_currency: formik.values.current_currency
          ? formik.values.current_currency:"INR",
          module: url?.includes("analysis")
            ? "Analysis"
            : url.includes("costingsystemlanding") ||
              url.includes("costingscenarioabbrlanding") ||
              url.includes("costingscenario")
            ? "costing_create"
            : url.includes("costingqualitycheck")
            ? "lpp"
            : "Costing",
        },
        0
      )
        .then((res: any) => {
          formik.values.conversion_factor = res.data[0]?.conversion_factor;
          setFromCurrency(res.data);
          setExchangeLoading(false);
        })
        .catch(() => {
          formik.values.conversion_factor = null;
          setExchangeLoading(false);
        });
    }
  }, [formik.values.current_currency, increaseCount]);

  useEffect(() => {
    return () => {
      setFromCurrency([]);
      formik.values.current_currency = currentCurrency; // Reset to the currency from props
      formik.values.conversion_factor = null;
      setApplyActionName("");
      formik.values.conversion_factor_manually = null;
    };
  }, []);

  const PartNameHandler = (item: any) => {
    setOnTypingText(item);
    if (item !== "") {
      ExactDebounce(item);
    } else {
      getCountryData();
      formik.values.conversion_factor = null;
      formik.values.current_currency = currentCurrency; // Reset to the currency from props
      formik.values.conversion_factor_manually = null;
      setFromCurrency([]);
    }
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      setCountryLoading(true);
      setCountryList([]);
      API.get(
        "customer/currency_search/",
        { search_key: criteria, project: projectId,top_vault:topVault, },
        0
      )
        .then((res: any) => {
          setCountryList(res.data);
          setCountryLoading(false);
        })
        .catch(() => {
          setCountryLoading(false);
        });
    }, 500)
  ).current;

  return (
    <>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {tabSelection === 1 ? (
          <Box sx={styled.boxSize}>
            <Box
              sx={{
                alignItem: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  Change Currency
                </Typography>
                {tabSelection === 1 && (
                  <CancelIcon
                    titleAccess="Close"
                    sx={{ cursor: "pointer", margin: "0.5rem" }}
                    onClick={() => onCancelClick()}
                  />
                )}
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <form
                style={{ alignItems: "center", padding: "0 1rem" }}
                onSubmit={formik.handleSubmit}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "10px",
                      marginBottom: "1rem",
                    }}
                  >
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: "1rem",
                        width: "8rem",
                      }}
                    >
                      Fetch Currency:
                    </Typography>

                    <FormControl
                      variant="standard"
                      sx={{
                        m: "1",
                        width: "100%",
                        minWidth: { lg: "120px", xl: "120px" },
                        maxWidth: { lg: "300px", xl: "350px" },
                      }}
                    >
                      <Autocomplete
                        defaultValue={currentCurrency} // Set default value from props
                        id="controllable-states-demo"
                        autoHighlight
                        filterOptions={(options, state) => options}
                        onChange={(event: any, newValue: string | null) => {
                          setIsShowExchangeRate(true);
                          handleChangee(newValue);
                        }}
                        loading={countryLoading}
                        inputValue={onTypingText}
                        getOptionLabel={(option: any) =>
                          option.currency_code == undefined
                            ? ""
                            : option.currency_code
                        }
                        onInputChange={(event, newInputValue) =>
                          PartNameHandler(newInputValue)
                        }
                        options={countryList && countryList}
                        sx={{ width: "100%", padding: "0 0.5rem" }}
                        placeholder="Search Part Name"
                        renderOption={(props: any, option: any) => (
                          <Box
                            component="li"
                            style={{
                              display: "flex",
                              fontSize: "1rem",
                            }}
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              src={`data:image/png;base64, ${option.flag}`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "0.5rem",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >{`${option.currency_name}  ${
                              option.currency_code
                            } ${
                              option.symbol ? "(" + option.symbol + ")" : " "
                            }`}</div>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            variant="standard"
                            sx={{
                              fontSize: "1rem",
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.light",
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      fontSize: "1rem",
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <Typography style={{ fontSize: "1rem", width: "8rem" }}>
                      Exchange Rate :&nbsp;
                    </Typography>
                    {fromCurrency?.length > 0 ? (
                      !exchangeLoading ? (
                        fromCurrency[0]?.currency_rate ? (
                          isShowExchangeRate ? <Box sx={{ fontSize: "1rem", width: "17rem" }}>{`1 ${
                            currentCurrency
                              ? currentCurrency
                              : "INR"
                          } = ${
                            fromCurrency[0]?.currency_rate
                              ? fromCurrency[0]?.currency_rate
                              : "--"
                          } ${formik.values.current_currency ? formik.values.current_currency : "INR"}`}</Box>:<Typography
                          align="center"
                          sx={{ fontSize: "1rem", width: "17rem" }}
                        >
                          Select currency to view exchange rate
                        </Typography>
                        ) : (
                          <Typography
                            align="center"
                            sx={{ fontSize: "1rem", width: "17rem" }}
                          >
                            Exchange Rate Not Available,Kindly Enter Manually
                          </Typography>
                        )
                      ) : (
                        <Typography
                          align="center"
                          sx={{ fontSize: "1rem", width: "17rem" }}
                        >
                          <Skeleton animation={"pulse"} />
                        </Typography>
                      )
                    ) : !exchangeLoading ? (
                      <Typography
                        align="center"
                        sx={{
                          fontSize: "1rem",
                          lineHeight: "1.2",
                          color:
                            formik.values.current_currency &&
                            fromCurrency?.length == 0
                              ? "red"
                              : "#007fff14",
                          width: "17rem",
                        }}
                      >
                        {formik.values.current_currency &&
                        fromCurrency?.length == 0
                          ? "Exchange rate is not available, kindly enter manually"
                          : "Select currency"}
                      </Typography>
                    ) : (
                      <Typography
                        align="center"
                        sx={{ fontSize: "1rem", width: "17rem" }}
                      >
                        <Skeleton animation={"pulse"} />
                      </Typography>
                    )}
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={
                        (formik.values.conversion_factor != null && isShowExchangeRate) ? false : true
                      }
                      sx={{
                        backgroundColor: "primary.main",
                        color: "primary.light",
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                      loading={fetchLoading}
                    >
                      Apply
                    </LoadingButton>
                  </Box>
                  <Box
                    sx={{
                      margin: "0.3rem 0",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography> or </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    columnGap: "1rem",
                    alignItems: "center",
                    padding: "0rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      textAlign: "left",
                      alignItems: "center",
                      padding: "0rem",
                      marginBottom: "1rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography style={{ fontSize: "1rem", width: "8rem" }}>
                      Enter Manually :
                    </Typography>
                    <Box sx={{ width: "17rem" }}>
                      <FormControl sx={{ m: 1, width: { lg: 200, xl: 250 } }}>
                        <TextField
                          type="number"
                          variant="standard"
                          id="conversion_factor_manually"
                          placeholder={
                            formik.values.current_currency
                              ? `Enter how much ${currentCurrency?currentCurrency:"INR"} in 1 ${isShowExchangeRate ?formik.values.current_currency : "--"}`
                              : ""
                          }
                          name="conversion_factor_manually"
                          onChange={formik.handleChange}
                          value={formik.values.conversion_factor_manually}
                          disabled={formik.values.current_currency == undefined}
                          sx={{
                            fontSize: "1rem",
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            ".MuiInputBase-input": { fontSize: "1rem" },
                          }}
                        />
                      </FormControl>
                    </Box>

                    <LoadingButton
                      variant="contained"
                      size="small"
                      disabled={getManuallyButtonStatus()}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "primary.light",
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                      onClick={() => manualClicked()}
                      loading={applyLoading}
                    >
                      Apply
                    </LoadingButton>
                  </Box>
                </Box>
                {formik.values.conversion_factor && (
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0 auto",
                      color: "green",
                      fontSize: "0.9rem",
                    }}
                  >
                    Costing of all the parts will be changed from{" "}
                    <b>
                      {url?.includes("analysis")
                        ? sessionStorage.getItem("currency_analysis")
                        : url.includes("costingsystemlanding") ||
                          url.includes("costingscenarioabbrlanding") ||
                          url.includes("costingscenario")
                        ? sessionStorage.getItem("costingtable")
                        : url.includes("costingqualitycheck")
                        ? sessionStorage.getItem("costingLpp")
                        : currentCurrency || "INR"}{" "}
                    </b>
                    to
                    <b>
                      &nbsp;
                      {`${
                        (formik.values.current_currency !== "" && isShowExchangeRate)
                          ? formik.values.current_currency
                          : "--"
                      }.`}
                    </b>
                    <br></br>
                    {`  Currency values are not updated automatically and are retained until you change them again`}
                    .
                  </Typography>
                )}
                {sessionStorage.getItem("currency") != currentCurrency &&
                  formik.values.current_currency == null &&
                  !url.includes("analysis") && (
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "0 auto",
                        color: "green",
                        fontSize: "0.9rem",
                      }}
                    >
                      {sessionStorage.getItem("actionKey") === "mannual"
                        ? `Current conversion manual rate 1 ${sessionStorage.getItem(
                            "currency"
                          )} = ${sessionStorage.getItem(
                            "currencyrate_mannual"
                          )} INR`
                        : `Current conversion exchange rate 1 ${sessionStorage.getItem(
                            "currency"
                          )} = ${sessionStorage.getItem("currencyrate")} INR`}
                    </Typography>
                  )}
                {sessionStorage.getItem("currency_analysis") !=
                  currentCurrency &&
                  formik.values.current_currency == null &&
                  url.includes("analysis") && (
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "0 auto",
                        color: "green",
                        fontSize: "0.9rem",
                      }}
                    >
                      {sessionStorage.getItem("actionKey") === "mannual"
                        ? `Current conversion manual rate 1 ${sessionStorage.getItem(
                            "currency_analysis"
                          )} = ${sessionStorage.getItem(
                            "currencyrate_analysis_mannual"
                          )} INR`
                        : `Current conversion exchange rate 1 ${sessionStorage.getItem(
                            "currency_analysis"
                          )} = ${sessionStorage.getItem(
                            "currencyrate_analysis"
                          )} INR`}
                    </Typography>
                  )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    size="small"
                    onClick={() => resetClicked()}
                    loading={isLoadingReset}
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                  >
                    Reset to {currentCurrency}{" "}
                    {/* Reset to the currency from props */}
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Box>
        ) : (
          <Box sx={styled.boxSize}>
            <Box
              sx={{
                alignItem: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                    color: "green",
                  }}
                >
                  Currency Successfully Changed!
                </Typography>
                {tabSelection === 1 && (
                  <CancelIcon
                    titleAccess="Close"
                    sx={{ cursor: "pointer", margin: "0.5rem" }}
                    onClick={onCloseModal}
                  />
                )}
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Typography
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0 auto 0.5rem",
                  color: "green",
                  fontSize: "0.9rem",
                  padding: "0 1rem",
                }}
              >
                Costing of all the parts have been changed from{" "}
                <b>{currentCurrency || sessionStorage.getItem("currency")}&nbsp;</b>
                to&nbsp;
                <b>
                  {`${
                    formik.values.current_currency !== ""
                      ? formik.values.current_currency
                      : "--"
                  }`}
                </b>
                <br />
                {/* ( 1 ${currentCurrency} = ${parseFloat(
                      //@ts-ignore
                      formik.values.conversion_factor
                      //@ts-ignore
                        ? formik.values.conversion_factor
                        //@ts-ignore
                        : 1 / formik.values.conversion_factor_manually
                    ).toFixed(2)} ${
                      formik.values.current_currency
                        ? formik.values.current_currency
                        : ""
                    }) */}
                {applyActionName !== "mannual"
                  ? ` Conversion Rate: 1 ${
                      currentCurrency
                        ? currentCurrency
                        : ""
                    } = ${
                      fromCurrency[0]?.currency_rate
                        ? fromCurrency[0]?.currency_rate
                        //@ts-ignore
                        : formik.values.conversion_factor_manually
                    } ${formik.values.current_currency}.`
                  : ` Conversion Rate: 1 ${
                      currentCurrency
                        ? currentCurrency
                        : ""
                    } = ${
                      //@ts-ignore
                      formik.values.conversion_factor_manually
                    } ${formik.values.current_currency}.`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 1rem",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setTabSelection(1)}
                >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setApiCallMain(apiCallMain + 1);
                    okayClicked();
                  }}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
}
