import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useRouteMatch } from "react-router-dom";
import calculator from "../../Assets/images/calculator.svg";
import { Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import "@szhsin/react-menu/dist/index.css";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import AddProcessModal from "./AddProcessModal";
import { API } from "../../api-services";
import InfoTreeModal from "./InfoTreeModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { CalculatorPasteModal } from "./CalculatorPasteModal";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import FormulaModal from "./FormulaModal";
import CircleIcon from "@mui/icons-material/Circle";
import FetchCostModel from "./FetchCostModel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { commodity_color, process_color } from "./CostingTemplateCategory";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FetchCostModelGroup from "./FetchCostModalGroup";
import NotesIcon from "@mui/icons-material/Notes";
import SequenceChangeModal from "./SequenceChangeModal";
import { PermissionData } from "./CostingTemplateConfig";
import FetchCostModelName from "./FetchCostModelName";
import CalculatorSequenceModal from "./CalculatorSequenceModal";
import CalculatorInfoModal from "./CalculatorInfoModal";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CostingTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  useraction?: any;
  getTreeData?: any;
  setParentCount: any;
  selectedTreeData?: any;
  categoryList: any,
  category?: any
  setAddFectchCounter?: any;
  type?: any;
  setHierarchyCount?: any;
  getCategoryInfo?:any
}
const CostingTreeTemplateFetchedData = (
  props: CostingTreeViewcomponentsProps
) => {
  const {
    HierarchyData,
    onSelectCallback,
    setSelectedTreeData,
    setCounterApiCall,
    useraction,
    getTreeData,
    setParentCount,
    selectedTreeData,
    categoryList,
    category,
    setAddFectchCounter,
    type,
    setHierarchyCount,
    getCategoryInfo
  } = props;


  const openSequenceChangeModal = useBaseParamsModal();
  const fetchCostModel = useBaseParamsModal();
  const calcInfoModal = useBaseParamsModal();
  const calcSequenceModel = useBaseParamsModal();
  const fetchCostModelBOP = useBaseParamsModal();
  const fetchCostModelGroup = useBaseParamsModal();
  const openFormula = useBaseParamsModal();
  const { selectedIdTree, calcSequence, categoryId, config_id ,costingType} = useRouteParams<any>();
  const infoModal = useBaseModal();
  const costModelPaste = useBaseParamsModal();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [categoryy, setCategoryy] = React.useState<any>();
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [seqanchorEl, setSeqAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [isOpen, setOpen] = useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>(null);
  const [rightClickedId, setRightClickedId] = React.useState<any>("");
  const [targetData, setTargetData] = React.useState<any>({});
  const inputElRef = React.useRef<any>();
  const [uploadCalcListLoader, setUploadCalcListLoader] =
    React.useState<any>(false);

  const open = Boolean(anchorEl);

  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([
    "Parent",
    "1Material",
    "2RawMaterial",
    "3BoughtOutPart",
    "4Process",
    "5Labour",
    "6Machine",
    "7Tool",
    "8Setup",
    "9Overheads",
  ]);
  const [selected, setSelected] = React.useState<any>();
  const addProcess = useBaseParamsModal();

  const getHeadingg = (fetchType: any) => {
    switch (fetchType) {
      case "RM":
        return "Raw Material";
      case "BOP":
        return "BOP Material";
      case "OH":
        return "Overhead";
      default:
        return "Overhead";
    }
  };

  // const handleChange = (
  //   nodeId: any,
  //   id: any,
  //   HierarchyAllData: any,
  //   itemId: any
  // ) => {
  //   onSelectCallback(nodeId, id, HierarchyAllData, itemId);
  //   if (HierarchyAllData && HierarchyAllData?.type === "calculator") {
  //     // console.log("HierarchyAllData", HierarchyAllData);
  //     setSelectedTreeData(HierarchyAllData);
  //     setSelected(nodeId);
  //     sessionStorage.setItem(
  //       "calculator_type",
  //       HierarchyAllData?.calculator_type
  //     );
  //   } else {
  //     setSelectedTreeData({});
  //     sessionStorage.removeItem("calculator_type");
  //   }

  //   let a: any = sessionStorage.getItem("Costingconfigurationnode");
  //   let val: any = JSON.parse(a);
  //   //  console.log(val, "val");


  //   if (val?.includes(id)) {


  //     let noDuplicate: any = val?.filter((item: any) => {
  //       return item != id;
  //     });
  //     let b: any = sessionStorage.setItem(
  //       "Costingconfigurationnode",
  //       JSON.stringify(noDuplicate)
  //     );
  //   } else {

  //     val.push(id);
  //     let b: any = sessionStorage.setItem(
  //       "Costingconfigurationnode",
  //       JSON.stringify(val)
  //     );
  //   }


  //   const expandedNodes = getAutoExpandedNodes(HierarchyAllData);
     
  //    const index = expanded.indexOf(id);
  //   if (index === -1) {
     
  //     expandedNodes?.forEach((item: any) => {
  //       if (!val?.includes(item)) {
  //         val.push(item); // Add to arr2 if not present
  //       }
  //     });
  //   } else {
      

  //     expandedNodes?.forEach((item: any) => {
  //       const indexx = val?.indexOf(item);
  //       if (indexx !== -1) {
  //         val.splice(indexx, 1); // Add to arr2 if not present
  //       }
  //     });
  //   }

  //   sessionStorage.setItem(
  //     "Costingconfigurationnode",
  //     JSON.stringify(val)
  //   );


  //   setActiveId(id);

  //   // const index = expanded.indexOf(id);

  //   const copyExpanded = [...expanded];
  //   if (index === -1) {
  //     copyExpanded?.push(id);
  //   } else {
  //     copyExpanded?.splice(index, 1);
  //   }

  //   setExpanded(copyExpanded);
  //   // setSelected(copyExpanded);
  // };

  const handleChange = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    onSelectCallback(nodeId, id, HierarchyAllData, itemId);
  
    // Handle calculator type logic
    if (HierarchyAllData && HierarchyAllData?.type === "calculator") {
      setSelectedTreeData(HierarchyAllData);
      setSelected(nodeId);
      sessionStorage.setItem("calculator_type", HierarchyAllData?.calculator_type);
    } else {
      setSelectedTreeData({});
      sessionStorage.removeItem("calculator_type");
    }
  
    // Get or initialize expanded nodes from session storage
    let a: any = sessionStorage.getItem("Costingconfigurationnode");
    let val: any = JSON.parse(a) || [];
  
    // Toggle the clicked node in the expanded list
    if (val?.includes(id)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != id;
      });
      sessionStorage.setItem(
        "Costingconfigurationnode",
        JSON.stringify(noDuplicate)
      );
    } else {
      val.push(id);
      sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(val));
    }
  
    // Get auto-expanded nodes (first child of each level)
    const expandedNodes = getAutoExpandedNodes(HierarchyAllData);
  
    // Add or remove expanded nodes based on the current state
    const index = expanded.indexOf(id);
    if (index === -1) {
      expandedNodes?.forEach((item: any) => {
        if (!val?.includes(item)) {
          val.push(item); // Add to arr2 if not present
        }
      });
    } else {
      expandedNodes?.forEach((item: any) => {
        const indexx = val?.indexOf(item);
        if (indexx !== -1) {
          val.splice(indexx, 1); // Remove from arr2 if present
        }
      });
    }
  
    // Update session storage with the new expanded nodes
    sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(val));
  
    // Set the active ID
    setActiveId(id);
  
    // Update the expanded state
    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(id);
  
      // Recursively expand the first child of each level
      const expandFirstChild = (node: any) => {
        if (node?.data && node?.data.length > 0) {
          const firstChild = node.data[0]; // Get the first child
          copyExpanded.push(firstChild.sequence_id); // Expand the first child
          expandFirstChild(firstChild); // Recursively expand its first child
        }
      };
      expandFirstChild(HierarchyAllData); // Start expanding from the clicked node
    } else {
      copyExpanded?.splice(index, 1);
    }
  
    // Update the expanded state
    setExpanded(copyExpanded);
  };
  const renderData = useMemo(() => {
    return (
      HierarchyAllData &&
      HierarchyAllData?.data?.map((subsystemData: any) => {
        return (
          <>
            <CostingTreeTemplateFetchedData
              HierarchyData={subsystemData}
              key={subsystemData?.sequence_id}
              useraction={useraction && useraction}
              setSelectedTreeData={setSelectedTreeData}
              onSelectCallback={onSelectCallback}
              setCounterApiCall={setCounterApiCall}
              getTreeData={getTreeData}
              setParentCount={setParentCount}
              selectedTreeData={selectedTreeData}
              categoryList={categoryList}
              category={category}
              setAddFectchCounter={setAddFectchCounter}
              type={type}
              getCategoryInfo={getCategoryInfo}
            />
          </>
        );
      })
    );
  }, [HierarchyAllData, category]);



  const clearFetchedCostModels = (data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to clear fetched cost model",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(
          `config/get_costing_configuration_based_on_costing_category/${data?.config_id}/`,
          {},

          0
        )
          .then(() => {
            setCounterApiCall((prev: any) => prev + 1);
          })
          .catch((e) => {
            Swal.fire({
              title: "Error!",
              text: e?.response?.data?.[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const getBackgroundColor = (data: any) => {
    if (data?.type === "commodity") {
      return commodity_color;
    } else if (data?.type === "process") {
      return process_color;
    } else if (data?.type === "commodity__process") {
      return process_color;
    } else {
      return "";
    }
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("Costingconfigurationnode");
    let val: any = JSON.parse(a) || [];
    if (val != null) {
      setExpanded(val);
      // setSelected(val);
    }
  }, [selectedIdTree, sessionStorage.getItem("Costingconfigurationnode"),]);
  //@ts-ignore
  // const menuItemClassName = ({ hover }) =>
  // 	hover ? 'my-menuitem-hover' : 'my-menuitem';
  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  const uploadCalc = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("commodity", HierarchyAllData?.commodity_id);
    formData.append("process", HierarchyAllData?.id);

    // commodity & process

    setUploadCalcListLoader(true);
    API.post("/cost/upload_calculator_data/", formData, {}, 0)
      .then(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Success!",
          text: "Calculator uploaded successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Error in uploading calculator",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    //convert into formdata

    uploadCalc(file);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleClose = () => {
    setContextMenu(null);
  };
  const PermissionValue = React.useContext<any>(PermissionData);

  const handleRightClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Prevent the default context menu
    setSeqAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSeqAnchorEl(null);
  };

  const handleChangeSequence = (data: any) => {
    calcSequenceModel.open(data);
    handleCloseMenu();
  };


  useEffect(() => {
    if (Array.isArray(categoryList) && categoryList?.length > 0) {
      const targetCategory = categoryList?.find((item) => {
        return item?.id === categoryId
      });
      setCategoryy(targetCategory);
    } 
  }, [categoryId])


  const getAutoExpandedNodes = (data: any, resultArr: any = []) => {
    const nodes = Array.isArray(data) ? data : [data]; // Wrap in array if not already an array
  
    nodes.forEach((node: any) => {
      // Check if node has exactly one child in its data array
      if (node?.data?.length === 1) {
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Recursively continue with its single child
        getAutoExpandedNodes(node?.data, resultArr);
      } else if (node?.data?.length > 1) {
        // If node has multiple children in data array
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Push sequence_id of only the first child
        resultArr.push(node?.data[0]?.sequence_id);
        // Recursively continue with the first child
        getAutoExpandedNodes(node?.data[0], resultArr);
      }
    });
  
    return resultArr;
  };

  // useEffect(() => {
  //     // const updatedData = HierarchyAllData && HierarchyAllData?.filter((item:any) => item.id !== config_id); 
  //     console.log(HierarchyAllData, "updatedData");
  //     // setSelectedTreeData(HierarchyAllData?.sequence_id);
  // }, [HierarchyAllData]);

  // console.log(expanded, "expanded");
  //  const aa:any = sessionStorage.getItem("Costingconfigurationnode")
  // console.log(sessionStorage.getItem("calculator_type"), "calculator_type costing");
  // console.log(JSON.parse(aa), "Costingconfigurationnode costing");

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.sequence_id}
        id={HierarchyAllData?.sequence_id}
        defaultParentIcon={
          HierarchyAllData?.type !== "hierarchy_parent" ? (
            <FolderIcon fill="primary" />
          ) : (
            <CircleIcon fill="primary" />
          )
        }
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.type !== "calculator" &&
          HierarchyAllData?.type !== "hierarchy_parent" && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
          // )
        }
        defaultExpandIcon={
          HierarchyAllData?.type !== "hierarchy_parent" ? (
            <FolderIcon color="primary" />
          ) : (
            <CircleIcon fill="primary" />
          )
        }
        defaultEndIcon={
          HierarchyAllData?.type === "calculator" ? (
            <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
          ) : (
            HierarchyAllData?.type !== "hierarchy_parent" && (
              <FolderIcon color="primary" />
            )
          )
        }
        selected={selected}
        onNodeSelect={() =>
          handleChange(
            HierarchyAllData?.name,
            HierarchyAllData?.sequence_id,
            HierarchyAllData,
            HierarchyAllData?.id
          )
        }
        // selected={selectedTreeData?.scequence_id}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.sequence_id == calcSequence
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
        // onContextMenu={(event: any) =>
        //   handleContextMenu(event, HierarchyAllData?.id)
        // }
        style={{ cursor: "context-menu" }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.sequence_id}
          nodeId={HierarchyAllData?.sequence_id}
          label={
            <Typography className={styles.treeViewData}>
              <Box
                className={styles.treeViewText}
                sx={
                  HierarchyAllData?.type === "calculator"
                    ? {
                      color: HierarchyAllData?.mapped_status
                        ? "green"
                        : "red",
                      // backgroundColor: HierarchyAllData?.type === "costing_category_parent" ? "#ddefff" : "",
                      backgroundColor: getBackgroundColor(HierarchyAllData),
                      border:
                        HierarchyAllData?.type === "hierarchy_parent"
                          ? "1px solid #007fff1f"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                    }
                    : {
                      color: "",
                      // backgroundColor: HierarchyAllData?.type === "costing_category_parent" ? "#ddefff" : "",
                      backgroundColor: getBackgroundColor(HierarchyAllData),
                      border:
                        HierarchyAllData?.type === "hierarchy_parent"
                          ? "1px solid #007fff1f"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                    }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    width: "100%",
                    backgroundColor:
                      // HierarchyAllData?.type === "calculator" &&
                      calcSequence == HierarchyAllData?.sequence_id
                        ? "#cee6fa"
                        : "",
                    color:
                      // HierarchyAllData?.type === "calculator" &&
                      calcSequence == HierarchyAllData?.sequence_id
                        ? "#007fff"
                        : "",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          width: "100%",
                          padding: '0.2rem',
                        }}
                        onContextMenu={(e) => {
                          if (
                            HierarchyAllData?.type === "config_estimation" ||
                            HierarchyAllData?.type === "process" ||
                            HierarchyAllData?.name === "Bought-Out Part" ||
                            (HierarchyAllData?.type === "calculator_category" &&
                              HierarchyAllData?.name === "Process")
                          ) {
                            handleRightClick(e);
                          }
                        }}
                      >
                        {HierarchyAllData?.name}{" "}
                        <Menu
                          anchorEl={seqanchorEl}
                          open={Boolean(seqanchorEl)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem
                            onClick={() => {
                              handleChangeSequence(HierarchyAllData);
                            }}
                          >
                            Change sequence of{" "}
                            {HierarchyAllData?.type === "calculator_category" &&
                              HierarchyAllData?.name === "Process"
                              ? "processes"
                              : "calculators"}{" "}
                          </MenuItem>
                        </Menu>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            justifyContent: "flex-end",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {HierarchyAllData?.type === "config_estimation" &&
                            HierarchyAllData?.calc_category == 2 && (
                              <>
                                {(PermissionValue?.includes("C") && category?.lock === false && costingType == 1) ? (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      event.preventDefault();

                                      if (
                                        HierarchyAllData?.name ===
                                        "Bought-Out Part"
                                      ) {
                                        fetchCostModelBOP.open("bop_material");
                                      } else if (
                                        HierarchyAllData?.type ===
                                        "config_estimation" &&
                                        HierarchyAllData?.calc_category == 2
                                      ) {
                                        fetchCostModelBOP.open({
                                          fetchType: "bop_material",
                                          estimation_id: HierarchyAllData?.id,
                                        });
                                      } else {
                                        fetchCostModelBOP.open("all");
                                      }
                                    }}
                                  />
                                ) : (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",

                                      color: "#ded7d7",
                                    }}
                                  />
                                )}
                              </>
                            )}

                          {HierarchyAllData?.type === "process_sub_calculator_cat" &&
                            (HierarchyAllData?.name === "Labour" || HierarchyAllData?.name === "Machine" || HierarchyAllData?.name === "Tool" || HierarchyAllData?.name === "Setup") && (
                              <>
                                {(category?.lock === false && PermissionValue?.includes("C") && costingType == 1) ? (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      if (HierarchyAllData?.name === "Labour") {
                                        fetchCostModel.open("labour");
                                      } else if (HierarchyAllData?.name === "Machine") {
                                        fetchCostModel.open("machine");
                                      } else if (HierarchyAllData?.name === "Tool") {
                                        fetchCostModel.open("tool");
                                      } else if (HierarchyAllData?.name === "Setup") {
                                        fetchCostModel.open("setup");
                                      }

                                    }}
                                  />
                                ) : (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",

                                      color: "#ded7d7",
                                    }}
                                  />
                                )}
                              </>
                            )}

                          {HierarchyAllData?.type === "calculator_category" &&
                            (

                              HierarchyAllData?.name === "Bought-Out Part") && (
                              <>
                                {(category?.lock === false && PermissionValue?.includes("C") && costingType == 1) ? (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      if (
                                        HierarchyAllData?.name === "Material"
                                      ) {
                                        fetchCostModel.open("material");
                                      } else if (
                                        HierarchyAllData?.name === "Process"
                                      ) {

                                        fetchCostModel.open("process");
                                      } else if (
                                        HierarchyAllData?.name === "Overheads"
                                      ) {
                                        fetchCostModel.open("overhead");
                                      } else {
                                        fetchCostModel.open("all");
                                      }
                                    }}
                                  />
                                ) : (
                                  <AddCircleIcon
                                    titleAccess="Fetch Calculators"
                                    sx={{
                                      fontSize: "18px",

                                      color: "#ded7d7",
                                    }}
                                  />
                                )}
                              </>
                            )}

                          {HierarchyAllData?.table_fetch === true && (
                            <>
                              {(PermissionValue?.includes("C") && category?.lock === false && costingType == 1) ? (
                                <CreateNewFolderIcon
                                  titleAccess="Fetch Calculators"
                                  sx={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    setTargetData(HierarchyAllData);
                                    if (
                                      HierarchyAllData?.name === "Raw Material"
                                    ) {
                                      fetchCostModelGroup.open("RM");
                                    } else if (
                                      HierarchyAllData?.name ===
                                      "Bought-Out Part"
                                    ) {
                                      fetchCostModelGroup.open("BOP");
                                    } else if (
                                      HierarchyAllData?.name === "Process"
                                    ) {
                                      fetchCostModelGroup.open("process");
                                    } else if (
                                      HierarchyAllData?.name === "OH"
                                    ) {
                                      fetchCostModelGroup.open("overhead");
                                    } else {
                                      fetchCostModelGroup.open("all");
                                    }
                                  }}
                                />
                              ) : (
                                <CreateNewFolderIcon
                                  titleAccess="Fetch Calculators"
                                  sx={{
                                    fontSize: "18px",
                                    color: "#ded7d7",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {HierarchyAllData?.type === "calculator" && <Box sx={{ padding: '0.2rem' }}>
                      <Typography sx={{ color: "black", }}>
                        {HierarchyAllData?.type === "calculator" &&
                          `[ UI: ${HierarchyAllData?.user_input_count || 0
                          }, DB: ${HierarchyAllData?.database_count || 0}, F: ${HierarchyAllData?.formula_count || 0
                          }, BP: ${HierarchyAllData?.bom_count || 0} ] ${HierarchyAllData?.total_fields_count || 0
                          }`}
                      </Typography>
                    </Box>}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        padding: "0 0.2rem",
                        width: "2.5rem",
                        textTransform: "none",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // borderRight: "1px solid #007fff1f",
                      }}
                    >
                      {HierarchyAllData?.type === "calculator" &&
                        (HierarchyAllData?.mapped_status === true ? (
                          <>

                            {PermissionValue?.includes("R") ? <InfoOutlinedIcon
                              sx={{
                                color: "#007FFF",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                              }}
                              onClick={() => calcInfoModal.open(HierarchyAllData)}
                            /> : <InfoOutlinedIcon
                              sx={{
                                color: "#ded7d7",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                              }}

                            />}
                          </>
                        ) : (
                          <>
                            {(PermissionValue?.includes("D") && category?.lock === false && costingType == 1) ? (
                              <ClearIcon
                                titleAccess="Remove fetched cost model"
                                sx={{
                                  fontSize: "15px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  clearFetchedCostModels(HierarchyAllData)
                                }
                              />
                            ) : (
                              <ClearIcon
                                titleAccess="Remove fetched cost model"
                                sx={{
                                  fontSize: "15px",
                                  color: "#ded7d7",
                                  cursor: "default !important",
                                }}
                              />
                            )}
                          </>
                        ))}
                    </Box>
                    {HierarchyAllData?.type === "hierarchy_parent" ? (
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0 0.2rem",
                          fontWeight: "bold",
                          width: "4rem",
                          textDecoration: "underline",
                          textAlign: "center",
                        }}
                      >
                        Qty
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0 0.2rem",
                          width: "4rem",
                          textTransform: "none",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          borderRight: "1px solid #007fff1f",
                        }}
                      >
                        {HierarchyAllData?.calc_count || 0}
                      </Box>
                    )}

                    {HierarchyAllData?.type === "hierarchy_parent" ? (
                      <>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0 0.2rem",
                            fontWeight: "bold",
                            width: { lg: '12rem', xl: "16rem" },
                            borderRight:
                              HierarchyAllData?.type === "hierarchy_parent"
                                ? "1px solid #007fff1f"
                                : "",
                          }}
                        >
                          Alias
                        </Box>
                      </>
                    ) : HierarchyAllData?.type === "commodity__process" ||
                      HierarchyAllData?.type === "process_sub_calculator_cat" ||
                      HierarchyAllData?.type === "commodity" ? (
                      <>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0 0.2rem",
                            width: { lg: '12rem', xl: "16rem" },
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            borderRight: "1px solid #007fff1f",
                          }}
                        ></Box>
                      </>
                    ) : (
                      <>
                        <Tooltip title={HierarchyAllData?.alias}>
                          <Box
                            sx={{
                              cursor: PermissionValue?.includes("U")
                                ? "pointer"
                                : "default !important",
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              width: { lg: '12rem', xl: "16rem" },
                              textTransform: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              borderRight: "1px solid #007fff1f",
                              textDecoration:
                                HierarchyAllData?.alias &&
                                (HierarchyAllData?.type ===
                                  "config_estimation" ||
                                  HierarchyAllData?.type === "process"
                                  // ||
                                  // (HierarchyAllData.name ===
                                  //   "Bought-Out Part" &&
                                  //   HierarchyAllData.type ===
                                  //     "sub_calculator_cat")

                                ) &&
                                HierarchyAllData?.calc_count > 0 &&
                                "underline",
                              color: "green",
                            }}
                            onClick={(ed: any) => {
                              if ((PermissionValue?.includes("U") || PermissionValue?.includes("C")) && !category?.lock) {
                                ed.stopPropagation();
                                ed.preventDefault();
                                if (
                                  HierarchyAllData?.alias &&
                                  (HierarchyAllData?.type ===
                                    "config_estimation" ||
                                    HierarchyAllData?.type === "process" ||
                                    (HierarchyAllData.name ===
                                      "Bought-Out Part" &&
                                      HierarchyAllData.type ===
                                      "sub_calculator_cat")) &&
                                  HierarchyAllData?.calc_count > 0
                                ) {
                                  openFormula.open(HierarchyAllData);
                                }
                              }
                            }}
                          >
                            {HierarchyAllData?.alias ? (
                              HierarchyAllData?.alias || ""
                            ) : (HierarchyAllData?.type ===
                              "config_estimation" ||
                              HierarchyAllData?.type === "process" ||
                              HierarchyAllData?.name === "Bought-Out Part") &&
                              HierarchyAllData?.calc_count > 0 ? (
                              <>
                                <p
                                  style={{
                                    color: (PermissionValue?.includes("U") && !categoryy?.lock && costingType == 1)
                                      ? "red"
                                      : "#ded7d7",
                                    textDecoration: (PermissionValue?.includes(
                                      "U"
                                    ) && !categoryy?.lock && costingType == 1)
                                      ? "underline"
                                      : "none",
                                  }}
                                  onClick={(ed: any) => {
                                    if (PermissionValue?.includes("U") && !categoryy?.lock && costingType == 1) {
                                      ed.stopPropagation();
                                      ed.preventDefault();
                                      openFormula.open(HierarchyAllData);
                                    }
                                  }}
                                >
                                  Add formula
                                </p>
                              </>
                            ) : (
                              HierarchyAllData?.alias || ""
                            )}
                          </Box>
                        </Tooltip>
                      </>
                    )}
                    {HierarchyAllData?.type === "hierarchy_parent" ? (
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0 0.2rem",
                          fontWeight: "bold",
                          width: "5rem",
                          textDecoration: "underline",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                        }}
                      >
                        Test results
                      </Box>
                    ) : HierarchyAllData?.type === "commodity__process" ||
                      HierarchyAllData?.type === "process_sub_calculator_cat" ||
                      HierarchyAllData?.type === "commodity" ? (
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0 0.2rem",
                          width: "5rem",
                          textTransform: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                        }}
                      ></Box>
                    ) : (
                      <Tooltip title={HierarchyAllData?.test_input || ""}>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0 0.2rem",
                            width: "5rem",
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          {HierarchyAllData?.test_input ||
                            HierarchyAllData?.test_input === 0
                            ? HierarchyAllData?.test_input
                            : ""}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
            </Typography>
          }
        >
          {renderData}
        </StyledTreeItem>
      </TreeView>

      <input
        type="file"
        style={{ display: "none" }}
        // required
        accept=".enc"
        id="file"
        name="file"
        ref={inputElRef}
        disabled={(PermissionValue?.includes("C") && categoryy?.lock && costingType == 1) ? false : true}
        onChange={handleChangeFile}
        onClick={handleInputClick}
        capture="user"
      />
      {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={rightClickedId && rightClickedId}
        />
      )}
      {openFormula.isOpen && (
        <FormulaModal
          isOpen={openFormula.isOpen}
          onCloseModal={openFormula.close}
          data={openFormula.propsId}
          getTreeData={getTreeData}
          setCounterApiCall={setCounterApiCall}
          setParentCount={setParentCount}

        />
      )}
      {calcSequenceModel.isOpen && (
        <CalculatorSequenceModal
          isOpen={calcSequenceModel.isOpen}
          onCloseModal={calcSequenceModel.close}
          data={calcSequenceModel.propsId}
          getTreeData={getTreeData}
        />
      )}
      {calcInfoModal.isOpen && (
        <CalculatorInfoModal
          isOpen={calcInfoModal.isOpen}
          onCloseModal={calcInfoModal.close}
          data={calcInfoModal.propsId}
          getTreeData={getTreeData}
        />
      )}

      {costModelPaste.isOpen && (
        <CalculatorPasteModal
          isOpen={costModelPaste.isOpen}
          onCloseModal={costModelPaste.close}
          HierarchyAllData={costModelPaste.propsId}
          setCounterApiCall={setCounterApiCall}
        />
      )}
      {fetchCostModel.isOpen && (
        <FetchCostModel
          isOpen={fetchCostModel.isOpen}
          onCloseModal={fetchCostModel.close}
          setParentCount={setParentCount}
          fetchType={fetchCostModel.propsId}
          parentTreeRefresh={getTreeData}
          setCounterApiCall={setCounterApiCall}
          type={type}
          getHeirarchyData={getTreeData}
          setHierarchyCount={setHierarchyCount}
          getCategoryInfo={getCategoryInfo}
        />
      )}
      {fetchCostModelBOP.isOpen && (
        <FetchCostModelName
          isOpen={fetchCostModelBOP.isOpen}
          onCloseModal={fetchCostModelBOP.close}
          setParentCount={setParentCount}
          fetchType={fetchCostModelBOP.propsId}
          parentTreeRefresh={getTreeData}
          setAddFectchCounter={setAddFectchCounter}
          getCategoryInfo={getCategoryInfo}
        />
      )}
      {
        fetchCostModelGroup.isOpen && (
          <FetchCostModelGroup
            isOpen={fetchCostModelGroup.isOpen}
            onCloseModal={fetchCostModelGroup.close}
            setParentCount={setParentCount}
            fetchType={fetchCostModelGroup.propsId}
            parentTreeRefresh={getTreeData}
            categoryList={categoryList}
            setAddFectchCounter={setAddFectchCounter}
            getCategoryInfo={getCategoryInfo}
          />
        )
      }
      {
        openSequenceChangeModal.isOpen && (
          <SequenceChangeModal
            isOpen={openSequenceChangeModal.isOpen}
            onCloseModal={openSequenceChangeModal.close}
            data={openSequenceChangeModal.propsId}
          />
        )
      }
    </>
  );
};

export default CostingTreeTemplateFetchedData;
