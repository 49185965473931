import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, Modal, Skeleton, Typography } from '@mui/material';
import { API } from '../../../api-services';
import LoaderImage from '../../../Assets/images/loading-screen-landing.gif';
import Image from '../../../ViewModule/FeatureLandingPage/ArchitectureImageCard';
import RViewerJS from 'viewerjs-react';
import { useHistory } from 'react-router-dom';
import { url } from 'inspector';

export interface IBOMImageBasedSearchModalProps {
	onCloseModal: any;
	isOpen: any;
	projectId?: any;
	projectName?: any;
	topVault?: any;
	vault?: any;
	treeabbr?: any;
	isLeaf?: any;
	copyStatus?: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

export default function BOMImageBasedSearchModal(
	props: IBOMImageBasedSearchModalProps
) {
	const { onCloseModal, isOpen, projectId, projectName, topVault, vault, treeabbr, isLeaf, copyStatus } = props;
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState<any>([]);
	const history = useHistory();
	const url = history.location.pathname;

	// console.log(valutId, "vaultId");

	React.useEffect(() => {
		setLoading(true);
		API.get('xcpep/search_image/', {
			vault: vault != 0 ? vault : undefined,
			top_vault: vault == 0 ? topVault : undefined,
		})
			.then((res) => {
				setLoading(false);
				setData(res?.data);
			})
			.catch((err: any) => {
				console.log('Server error');
				setLoading(false);
			});
	}, []);
	console.log(data, "data")
	// const bomPath = () => {
	//     ancestorGet && sessionStorage.setItem("node",JSON.stringify(ancestorGet));
	//      window.open( `/#/view/${projectId}/${topvaultId}/${productId}/null/true` );
	// };
	// bomentry/23/23-T-P02/46/66442/CP/69104/false/false

	const imageRedirectHandler = (selectedImage: any, item: any) => {
		console.log(item, selectedImage, "item")
		if (url.includes('bomentry')) {
			sessionStorage.removeItem("BOMCreateNode")
			sessionStorage.setItem("BOMCreateNode",
				JSON.stringify(item?.ancestors))
			window.open(`/#/bomentry/${projectId}/${projectName}/${topVault}/${selectedImage}/${item?.abbreviation ? item?.abbreviation : 'none'}/${0}/${item?.is_leaf}/${item?.is_copy}`);
		} else {
			sessionStorage.removeItem("node")
			sessionStorage.setItem("node",
				JSON.stringify(item?.ancestors))
			window.open(`/#/view/${projectId}/${topVault}/${selectedImage}/${item?.abbreviation ? item?.abbreviation : 'none'}/${item?.is_leaf}`);
		}
	};


	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Image Based Navigation
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					{!loading ? (
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(6, 1fr)',
								gap: '1rem',
								justifyContent: 'center',
								height: '85vh',
							}}
							adl-scrollbar='true'
							adl-scrollbar-width='0.3'>
							{data.image_data &&
								data.image_data.map((item: any) => {
									return (
										<Box sx={{ height: '100%', width: '100%' }}>
											<Image
												title='Go to Part/Assembly'
												placeholderImg={LoaderImage}
												src={item?.cdn_url}
												onClick={() => { imageRedirectHandler(item?.id, item,) }}
												style={{
													width: '100%',
													height: '10rem',
													objectFit: 'cover',
													cursor: 'pointer',
													borderRadius: '0.5rem',
												}}
											/>
											<Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>{item?.name}</Typography>
										</Box>
									);
								})}
						</Box>
					) : (
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(5, 1fr)',
								gap: '1rem',
								justifyContent: 'center',
								height: '85vh',
							}}>
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
						</Box>
					)}
				</Box>
			</Modal>
		</div>
	);
}
