import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../api-services";
import { MODULES } from "../Constants/modules.constants";
import { s3Url } from "../utlis/s3url.utils";

const S3DocumentsUpload = (fileList: any, itemId: any, setCounter: any, setLoading: any, onCloseModal: any, type: any, values: any, projectId: any, selectedVehicle: any, getDocumentsData: any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  },0).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `DispatchDocs/${itemId ? itemId : 0}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          if (type === "create") {
            API.post(
              "/drive/file/",
              {
                description: values.description,
                expiry_date: values.expiry_date,
                name: file?.name,
                outward: true,
                path: `/DispatchDocs/${itemId ? itemId : 0}/${random}/${file.name}`,
                project: projectId,
                type: file?.type,
                vehicle: selectedVehicle,
              },
              {}
            )
              .then((res: any) => {
                setLoading(false);
                setCounter((prev: any) => prev + 1);

                onCloseModal();
                getDocumentsData();
              })
              .catch((err: any) => {
                setLoading(false);
              });
          } else {
            API.patch(`/drive/file/${itemId}/`, {
                description:values.description,
                expiry_date:values.expiry_date,
                vehicle:selectedVehicle,
                name: file?.name,
                path: `/DispatchDocs/${itemId ? itemId : 0}/${random}/${file.name}`,
                type: file?.type,
            }, {})
              .then((res: any) => {
                setLoading(false);
                setCounter((prev: any) => prev + 1);

                onCloseModal();
                getDocumentsData();
              })
              .catch((err: any) => {
                setLoading(false);
              });
          }
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default S3DocumentsUpload;
