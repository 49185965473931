import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 60vw",
  minHeight: "450px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function FlagUploadModal({
  isOpen,
  onCloseModal,
  input,
  index,
  handleImageView,
  calculatorInputService,
  selectedIdTree,
  Lockstatus,
  useraction,
  inputs,
  fetchInputss
}: any) {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [inputt, setInputt] = useState<any>();
  const [isFlagDeleting, setIsFlagDeleting] = useState<boolean>(false);

  const fetchInputs = async () => {
    setLoading(true);
      try {
        const { data } = await calculatorInputService.get(selectedIdTree);
        
        if (data?.length > 0) {
          const targetInput = data?.find((itm:any)=> itm?.id == input?.id)
          if (targetInput) {
            setInputt(targetInput)
          }
         
          
        }
        
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not fetch input");
        
      }

      setTimeout(() => {
        setLoading(false);
      }, 700);
    };


  useEffect(()=> {
    setLoading(true);
    setInputt(input);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  },[input])

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    setIsUploading(true);
    const data = new FormData();
    if (e.target.files?.length) {
      //@ts-ignore
      data.append(`image0`, e.currentTarget.files[0]);

      API.put(`cost/calculator_field_image_upload/${inputt?.id}/`, data, {}, 0)
        .then((res: any) => {
          setIsUploading(false);
          
          //   ADMIN.toast.success("Data uploaded successfully");
          fetchInputs();
        })
        .catch((err: any) => {
          //   ADMIN.toast.error("Could not upload file");
          setIsUploading(false);
        });
    }
  };


  console.log(inputs.getCollection(), "parameter");
  // console.log(updatedFlagInput, "parameter");


  const handleDeleteFlag = () => {
     Swal.fire({
                    title: "Confirm?",
                    text: "Do You want to remove the flag?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Remove",
                    cancelButtonText: "Cancel",
                    customClass: {
                      container: "swal2Container",
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setIsFlagDeleting(true)
                      API.put(
                        `cost/calculator_field_image_upload/${inputt.id}/`,
                        { image0: "null" },
                        {},
                        0
                      )
                        .then((res: any) => {
                          setIsFlagDeleting(false);
                          //   ADMIN.toast.success("Data uploaded successfully");
                          fetchInputss();
                          onCloseModal()
                        })
                        .catch((err: any) => {
                          //   ADMIN.toast.error("Could not upload file");
                          setIsFlagDeleting(false);
                        }).finally(()=> {
                          setIsFlagDeleting(false);
                        });
                        Swal.close();
                    } else {
                        Swal.close(); 
                    }
                  });
  }

  return (
    <Modal
      open={isOpen}
      // onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      // closeAfterTransition
      // slots={{ backdrop: Backdrop }}
      // slotProps={{
      //   backdrop: {
      //     timeout: 500,
      //   },
      // }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "2.5rem",
            marginBottom: "1rem",
          }}
        >
          <Box></Box>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            View / Replace Flag Image of {inputt?.parameter_name}
          </Typography>

          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={()=> {
              fetchInputss();
              onCloseModal();
            }}
          />
        </Box>


        {loading ? (
          <Box sx={{display:"grid", placeItems:"center"}}>
        <Skeleton
          variant="rectangular"
          width="90%"
          height={450}
          sx={{ borderRadius: "8px" }}
        />
        </Box>
      ) : <Box
      component="img"
      src={inputt?.image_url}
      alt="Uploaded Preview"
      sx={{
        cursor:"pointer",
        width: "100%",
        maxHeight: "450px",
        objectFit: "contain",
        borderRadius: "8px",
        border: "1px solid #ddd",
        boxShadow: 1,
      }}
      onClick={() => handleImageView(index)}
    />}
        

        <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <LoadingButton
          disabled={Lockstatus?.lock_status == true ||
            !useraction?.includes("U") || isFlagDeleting}
            variant="contained"
            component="label"
            sx={{ mt: 2, fontSize:"0.85rem", height:"2rem" }}
            loading={isUploading}
          >
            Upload New flag
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleFileUpload(e, inputt?.id);
                //
              }}
            />
          </LoadingButton>
          <LoadingButton
            disabled={Lockstatus?.lock_status == true ||
              !useraction?.includes("U") || isUploading}
            variant="contained"
            component="label"
            sx={{ mt: 2 , fontSize:"0.85rem", height:"2rem", backgroundColor:"red",
              "&:hover": {
      backgroundColor: "red", // Maintain red on hover
    },
            }}
            loading={isFlagDeleting}
            onClick={() => {
              
              handleDeleteFlag()
              
            }}
          >
            Remove flag
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default FlagUploadModal;
