import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import TreeViewComponents from "../TreeViewComponents";
// import BomViewData from "./BomViewData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./CostingViewComponents.module.scss";
import { Box, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import { CostingAccordianParent } from "./CostingAccordianParent";
import { CostingGraphData } from "./CostingGraphData";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import CostingProductLevelGraph from "./CostingProductLevelGraph";
import CostingProductLevelTable from "./CostingProductLevelTable";
import ValidationImgs from "../../Assets/images/validationImg.png";
import { AUTH } from "../../Redux/Services/auth.service";
import TableIcon from "../../Assets/images/svgs/tableIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DownloadIcon from "@mui/icons-material/Download";
import { AxiosError, AxiosResponse } from "axios";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import { DialogComponent } from "../../utlis/DialogComponent";
import Footer from "../Footer";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import { CurrencyChangeDynamic } from "./CurrecyChangeDynamic";

const csvOptions: GridCsvExportOptions = {
  fileName: "Costing Table View In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{ height: "2rem", justifyContent: "flex-end", paddingRight: "1rem" }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {};

const CostingViewComponents = (props: Props) => {
  const { url } = useRouteMatch();
  useDocumentTitle("Costing View");
  const childRef = React.useRef<any>(null);
  const { projectId, topVault, vault, treeabbr, leafNode, scenarioId } =
    useParams<any>();
  const currencyModal = useBaseModal();
  const [showingStatus, setShowingStatus] = useState<any>([]);
  const [loader, setLoading] = useState<any>(false);
  const [AllProjectData, setAllorojectData] = useState<any>();
  const [HierarchyData, setHierarchyData] = useState<any>();
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>({});
  const [productlevelData, setProductLevelData] = React.useState<any>([]);
  const [isCostScenario, setIsCostScenario] = React.useState<any>(false);
  const openDialogModal = useBaseModal();
  const [productLevelLoading, setProductLevelLoading] =
    React.useState<any>(false);
  const [count, setCount] = React.useState<any>(0);
  const history = useHistory<any>();
  const [getLeaf, setGetLeaf] = useState<any>();
  const [isCostScenarioExist, setIsCostScenarioExist] = useState<any>();
  const [defaultCurrency, setDefaultCurrency] = useState<any>("INR");

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { top_vault: topVault, module: "Costing", get_default_currency: true },
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("currency", res.data[0]?.current_currency);
        setDefaultCurrency(res.data[0]?.current_currency);
      })
      .catch((err: any) => {});
  }, [topVault]);

  useEffect(() => {
    sessionStorage.setItem("historyProject", projectId);
  }, [projectId]);

  const getPermission = () => {
    let tempArr: any = [];
    if (showingStatus?.length > 0) {
      const { bom_validated, cost_validated } = showingStatus[0];
      if (bom_validated && cost_validated) {
        tempArr = ["accordian", "table-images", "graph", "assumption"];
      } else if (bom_validated === true && cost_validated === false) {
        tempArr = ["table-images"];
      } else {
        tempArr = [];
      }
      return tempArr;
    }
  };
  React.useEffect(() => {
    API.get(
      "/customer/module_permission/",
      {
        top_vault_id: topVault,
        module: "Costing-Scenario",
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setIsCostScenario(res.data?.action?.includes("R"));
        setIsCostScenarioExist(res.data?.scenario);
      })
      .catch((err: any) => {});
  }, [topVault, vault]);


  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "Costing",top_vault:topVault },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency", res.data[0]?.current_currency);
      url?.includes("analysis")
        ? sessionStorage.setItem(
            "actionkey_analysis",
            res.data[0]?.is_manual ? "mannual" : "auto"
          )
        : sessionStorage.setItem(
            "actionKey",
            res.data[0]?.is_manual ? "mannual" : "auto"
          );
      if (res.data[0]?.is_manual) {
        url?.includes("analysis")
          ? sessionStorage.setItem(
              "currencyrate_analysis_mannual",
              res.data[0]?.conversion_factor
            )
          : sessionStorage.setItem(
              "currencyrate_mannual",
              res.data[0]?.conversion_factor
            );
      } else {
        url?.includes("analysis")
          ? sessionStorage.setItem(
              "currencyrate_analysis",
              (1 / res.data[0]?.conversion_factor).toString()
            )
          : sessionStorage.setItem(
              "currencyrate",
              (1 / res.data[0]?.conversion_factor).toString()
            );
      }
    });
  }, [count]);

  const fetchSequentially = async () => {
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a) || [];

    // Check if vault is not 0 and val contains topVault
    if (vault != 0 && val?.includes(topVault?.toString())) {
      await childRef.current?.fetchChildData(topVault, "product");
      // Sequentially fetch data for each item in val
      for (let nodeId of val) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(
              nodeId,
              HierarchyAllData?.abbreviation
            );
          } catch (error) {
            console.error(
              `Failed to fetch child data for node ${nodeId}:`,
              error
            );
          }
        }
      }
    }
  };
  const fetchSequentiallyScenario = async () => {
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a) || [];

    // Check if vault is not 0 and val contains topVault
    if (vault != 0) {
      // await childRef.current?.fetchChildData(topVault, "product");
      // Sequentially fetch data for each item in val
      for (let nodeId of val) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(
              nodeId,
              HierarchyAllData?.abbreviation
            );
          } catch (error) {
            console.error(
              `Failed to fetch child data for node ${nodeId}:`,
              error
            );
          }
        }
      }
    }
  };
  const fetchSequentiallySearch = async (nodeIds: any) => {
    if (vault != 0 && nodeIds?.includes(topVault?.toString())) {
      await childRef.current?.fetchChildData(topVault, "product");
      // Sequentially fetch data for each item in nodeIds
      for (let nodeId of nodeIds) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(nodeId, "");
          } catch (error) {
            console.error(
              `Failed to fetch child data for node ${nodeId}:`,
              error
            );
          }
        }
      }
    }
  };
  const fetchFirstLevelData = () => {
    if (childRef.current && vault == 0) {
      childRef.current?.fetchChildData(topVault, "product");
    }
  };
  const fetchFirstLevelDataAll = () => {
    if (childRef.current) {
      childRef.current?.fetchChildData(topVault, "product");
    }
  };
  useEffect(() => {
    setHierarchyData(null);
    setHierarchyAllData({});
    if (url.includes("scenario") && treeabbr !== "product") {
      API.get(
        "/customer/costing_scenario_hierarchy/",
        { vault_id: vault },
        0
      ).then((res: any) => {
        setHierarchyData(res.data);
        setHierarchyAllData(res.data.data);
        if (childRef.current) {
          if (vault != 0) {
            fetchSequentiallyScenario();
          } else {
            fetchFirstLevelData();
          }
        }
      });
    } else {
      setLoading(true);
      API.get(
        "/xcpep/new_system_hierarchy_fetch",
        {
          top_vault:
            topVault == 0
              ? AllProjectData && AllProjectData?.products[0]?.vehicle_id
              : topVault,
          // hierarchy: true,
        },
        0
      )
        .then((res: any) => {
          setLoading(false);

          setHierarchyData(res?.data?.data);
          setHierarchyAllData(res.data.data);
          if (childRef.current) {
            if (vault != 0) {
              fetchSequentially();
            } else {
              fetchFirstLevelData();
            }
          }

          // if (topVault == 0) {
          //   setHierarchyActiveId(res.data?.data?.id);
          //   history.push(`/view/${projectId}/${res.data?.data?.id}/0/product/costing`);
          // } else {
          //   setHierarchyActiveId(topVault);
          //   history.push(
          //     `/view/${projectId}/${topVault}/${vault}/${treeabbr}/costing`
          //   );
          // }
        })
        .catch((err: any) => {
          setLoading(true);
        });
    }
  }, [topVault]);

  useEffect(() => {
    if (vault == 0) {
      setProductLevelLoading(true);
      API.get(
        "/customer/system_cost/",
        {
          top_vault: topVault,
          vehicle_cost: true,
          scenario: url.includes("scenario") ? scenarioId : undefined,
        },
        0
      )
        .then((res: any) => {
          setProductLevelData(res.data);
          setProductLevelLoading(false);
        })
        .catch((err: any) => {
          setProductLevelData([]);
          setProductLevelLoading(false);
        });
    }
  }, [vault, topVault, count]);

  const getPermissionCurrencyButton = () => {
    if (AUTH.isSuperUser) {
      return true;
    } else if (AUTH.user_category !== "Internal") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (vault != 0) {
      API.get(
        "/customer/costing_module/",
        {
          vault_id: vault,
          is_costing_validated: true,
        },
        0
      )
        .then((res: any) => {
          setShowingStatus(res.data);
        })
        .catch((err: any) => {
          setShowingStatus([]);
        });

      API.get("/customer/dashboard/", { check_leaf: vault }, 0)
        .then((res: any) => {
          setGetLeaf(res.data);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [vault]);

  useEffect(() => {
    API.get("/customer/dashboard/", {
      get_product_list: projectId,
      module: 16,
    }).then((res: any) => {
      setAllorojectData(res?.data[0]);
    });
  }, [projectId, count]);

  const ClosedExpendHandler = () => {
    // setExpendBom(false)
    if (!url.includes("scenario")) {
      // @ts-ignore
      sessionStorage.setItem("bomModule", false);
      history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/false`);
    } else {
      history.push(
        `/view/${projectId}/${topVault}/${vault}/${treeabbr}/costing/scenario`
      );
    }
  };

  const ProjectHandler = (ProductID: any, type: any) => {
    sessionStorage.removeItem("node");
    history.push(`/view/${projectId}/${ProductID}/0/product/costing`);
  };

  useEffect(() => {
    if (topVault != null) {
      let a: any = sessionStorage.getItem("node");
      let val: any = JSON.parse(a);
      if (val == null) {
        sessionStorage.setItem("node", JSON.stringify([topVault]));
      }
    }
  }, [topVault]);

  const onSelect = (value: any, abbreviation: any) => {
    // You can put whatever here
    setLoading(true);

    if (!url.includes("scenario")) {
      if (abbreviation == "product") {
        setLoading(false);
        history.push(`/view/${projectId}/${topVault}/0/product/costing`);
      } else {
        setLoading(false);
        history.push(
          `/view/${projectId}/${topVault}/${value}/${abbreviation}/costing`
        );
      }
    } else {
      if (abbreviation == "product") {
        setLoading(false);
        history.push(
          `/view/${projectId}/${topVault}/0/product/costing/scenario/${scenarioId}`
        );
      } else {
        setLoading(false);
        history.push(
          `/view/${projectId}/${topVault}/${value}/${abbreviation}/costing/scenario/${scenarioId}`
        );
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const [costingTableData, setCostingTableData] = useState<any>();
  const [costingTableDataLoader, setCostingTableDataLoader] =
    useState<boolean>(true);

  const handleClickOpen = () => {
    setOpen(true);
    setCostingTableData(undefined);
  };

  const handleClose = () => {
    setOpen(false);
    setCostingTableData(undefined);
  };

  useEffect(() => {
    if (open) {
      setCostingTableData(undefined);
      setCostingTableDataLoader(true);
      API.get(
        "xcpep/cost_mui_table/",
        {
          parent: vault,
          scenario: scenarioId ? scenarioId : undefined,
        },
        0
      ).then(
        (res: AxiosResponse) => {
          setCostingTableData(res?.data);
          setCostingTableDataLoader(false);
        },
        (err: AxiosError) => {
          setCostingTableDataLoader(false);
        }
      );
    }
  }, [open]);

  const handleExcelReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {}
  };

  const redirectInsideCosting: any = (abbr: any, vault: any, ancestor: any) => {
    history.push(`/view/${projectId}/${topVault}/${vault}/${abbr}/costing`);
    sessionStorage.setItem("node", JSON.stringify(ancestor));
    setOpen(false);
  };

  let rows: any = [];

  let columns: any = [
    {
      field: "sno",
      headerName: "SNo.",
      headerAlign: "left",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "partno",
      headerName: "Part No",
      headerAlign: "left",
      minWidth: 200,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <p
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              redirectInsideCosting(
                cellValues?.row?.abbreviation,
                cellValues?.row?.id,
                cellValues?.row?.ancestors
              )
            }
          >
            {cellValues?.row?.partno}
          </p>
        );
      },
    },
    {
      field: "partName",
      headerName: "Part Name",
      headerAlign: "left",
      minWidth: 390,
      flex: 1,
      type: "number",
      align: "left",
    },
    {
      field: "download",
      headerName: "Download",
      headerAlign: "left",
      minWidth: 80,
      flex: 1,
      align: "center",
      disableExport: true,
      renderCell: (cellValues: any) => {
        return (
          <DownloadIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleExcelReport(cellValues?.row?.id)}
          />
        );
      },
    },
    {
      field: "partType",
      headerName: "Part Type",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "category",
      headerName: `Category`,
      minWidth: 135,
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "netwt",
      headerName: `Wt.(Kg)`,
      description: "Individual part weight",
      minWidth: 80,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      field: "qty",
      headerName: `Qty`,
      minWidth: 40,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      field: "perUnitCost",
      headerName: `Per Unit Cost`,
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
      description: "Material Cost + Process Cost + Overhead Cost + Child Cost",
    },
    {
      field: "totalCost",
      headerName: `Total Cost`,
      description: "Qty x Per Unit Cost",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "childCost",
      headerName: `Child Cost`,
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      field: "materialCost",
      headerName: `RM Cost`,
      description: "Material Cost",
      minWidth: 80,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      field: "processCost",
      headerName: `Process Cost`,
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      field: "overheadCost",
      headerName: `OH Cost`,
      description: "Overhead Cost",
      minWidth: 80,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let dataGridDataGenerator: any =
    costingTableData &&
    costingTableData?.map((ideaItem: any, indexInner: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        sno: indexInner + 1,
        partno: ideaItem?.part_no,
        partName: ideaItem?.name,
        partType: ideaItem?.is_leaf,
        category: ideaItem?.category,
        netwt: (ideaItem?.weight / 1000).toFixed(2),
        qty: ideaItem?.vault_details?.Qty,
        perUnitCost: ideaItem?.vault_details?.per_unit_cost,
        totalCost: ideaItem?.vault_details?.total_cost,
        childCost: ideaItem?.vault_details?.child_part_cost,
        materialCost: ideaItem?.vault_details?.material_cost,
        processCost: ideaItem?.vault_details?.process_cost,
        overheadCost: ideaItem?.vault_details?.overhead_cost,
        abbreviation: ideaItem?.abbreviation,
        ancestors: ideaItem?.ancestors,
      };
      rows.push(objShared);
    });

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  const scenarioHandler = () => {
    API.get("/cost/scenario_product/", {
      vault: vault,
      get_ancestors: true,
      top_vault: topVault,
    })
      .then((res: any) => {
        sessionStorage.removeItem("nodeCostingScenario");
        sessionStorage.setItem(
          "nodeCostingScenario",
          JSON.stringify(res?.data?.ancestors)
        );
        sessionStorage.setItem("addScenarioStatus", "3");
        window.open(
          `/#/costingscenario/${projectId}/${
            res?.data?.project_name
          }/${topVault}/${vault}/1/${
            treeabbr ? (treeabbr == "product" ? "PRODUCT" : treeabbr) : "none"
          }/0`
        );
      })
      .catch((err) => {});
  };

  return (
    <div>
      <DialogComponent
        isOpen={openDialogModal.isOpen}
        onClose={openDialogModal.close}
        content={
          "We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
        }
        type={"firstdownload"}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        // aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            minWidth: "90%",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          {costingTableData &&
            costingTableData[0]?.vault_details?.current_currency && (
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                {`Cost summary table (${
                  costingTableData &&
                  costingTableData[0]?.vault_details?.current_currency
                })`}
              </Typography>
            )}
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={handleClose}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <DialogContent sx={{ padding: "0 0.5rem" }}>
          {vault == 0 ? (
            <Box
              sx={{
                height: "88vh",
                width: "100%",
                marginTop: "-1rem",
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                },
              }}
            >
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                {...dataStructureDataGrid}
                loading={costingTableDataLoader}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                style={{ marginRight: "0.5rem" }}
                sx={{
                  "& .MuiDataGrid-virtualScroller-thumb": {
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& .MuiDataGrid-virtualScroller-thumb: hover ": {
                    height: "3px",
                    backgroundColor: "#045DE9 !important",
                  },
                  ".MuiDataGrid-virtualScroller-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                density="compact"
                pageSize={28}
                rowsPerPageOptions={[28]}
              />
            </Box>
          ) : showingStatus && showingStatus[0]?.cost_validated ? (
            <Box
              sx={{
                height: "88vh",
                width: "100%",
                marginTop: "-1rem",
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                },
              }}
            >
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                {...dataStructureDataGrid}
                loading={costingTableDataLoader}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                style={{ marginRight: "0.5rem" }}
                sx={{
                  "& .MuiDataGrid-virtualScroller-thumb": {
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& .MuiDataGrid-virtualScroller-thumb: hover ": {
                    height: "3px",
                    backgroundColor: "#045DE9 !important",
                  },
                  ".MuiDataGrid-virtualScroller-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                density="compact"
                pageSize={28}
                rowsPerPageOptions={[28]}
              />
            </Box>
          ) : (
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "58vh",
                flexDirection: "column",
                width: "100%",
                display: "flex",
              }}
            >
              <img src={ValidationImgs} style={{ width: "14rem" }} />
              <Typography align="center" style={{ width: "100%" }} mt={2}>
                We are working on it...
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <div className={styles.view_container}>
        <div className={styles.tab_mainContainer}>
          <Box className={styles.icon_project}>
            <Box sx={{ width: "20rem", display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={() => ClosedExpendHandler()}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <span className={styles.productLevel}>
                <Typography style={{ fontSize: "0.87rem" }}>
                  {AllProjectData?.project_name}
                </Typography>
                <Typography
                  style={{ fontSize: "0.87rem" }}
                  className={styles.proddesc}
                >
                  {AllProjectData?.project_description}{" "}
                </Typography>
              </span>
            </Box>
            <div className={styles.tabBox_container}>
              {AllProjectData &&
                AllProjectData?.products?.map(
                  (projectData: any, index: any) => {
                    return (
                      <>
                        <span
                          className={
                            topVault == projectData?.vehicle_id
                              ? styles.Project_chip_active
                              : styles.Project_chip
                          }
                          onClick={() => {
                            ProjectHandler(
                              projectData?.vehicle_id,
                              "onclickHandler"
                            );
                          }}
                        >
                          <Typography
                            style={{ lineHeight: "1.2", fontSize: "0.87rem" }}
                          >
                            {projectData?.prod_id} - {projectData?.vehicle_name}
                          </Typography>
                        </span>
                      </>
                    );
                  }
                )}
            </div>
          </Box>
          <div className={styles.viewbtnSection}>
            {treeabbr != "product" &&
              getLeaf &&
              getLeaf[0]?.is_leaf == false && (
                <img
                  src={TableIcon}
                  onClick={handleClickOpen}
                  style={{ cursor: "pointer", width: "1.7rem" }}
                />
              )}
            {/* {url.includes("scenario") ? <Typography>Scenario Costing</Typography> : <Typography>Main Costing</Typography>} */}
            {isCostScenario && isCostScenarioExist && (
              <Button
                variant="contained"
                sx={{
                  fontSize: "0.87rem",
                  "&:hover": {
                    transform: "scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                size="small"
                onClick={scenarioHandler}
              >
                Go to Scenarios
              </Button>
            )}
            {getPermissionCurrencyButton() && (
              <Button
                variant="contained"
                className={styles.downloadBtn}
                size="small"
                endIcon={<ArrowDropDownIcon />}
                style={{ fontSize: "0.87rem" }}
                onClick={() => currencyModal.open()}
              >
                {sessionStorage.getItem("currency")}
              </Button>
            )}
          </div>
        </div>
        <div
          className={styles.recursive_container}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {!loader ? (
            <div
              className={styles.left_container}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {HierarchyData && (
                <TreeViewComponents
                  HierarchyData={HierarchyData && HierarchyData}
                  onSelectCallback={onSelect}
                  childRef={childRef}
                />
              )}
            </div>
          ) : (
            <>
              <Box sx={{ width: "30%", padding: "0 0.5rem" }}>
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "35%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "80%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "35%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "80%" }} />
              </Box>
            </>
          )}

          {!productLevelLoading ? (
            getPermission()?.length > 0 || vault == 0 ? (
              <div
                className={styles.righ_container}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "auto",
                  columnGap: "0.5rem",
                }}
              >
                <div style={{ width: "52%" }}>
                  {vault != 0 ? (
                    getPermission()?.includes("accordian") ? (
                      <CostingAccordianParent
                        setCount={setCount}
                        count={count}
                        getPermission={getPermission()}
                      />
                    ) : (
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <img src={ValidationImgs} style={{ width: "10rem" }} />
                        <Typography
                          align="center"
                          style={{ width: "100%" }}
                          mt={2}
                        >
                          We are working on it...
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <CostingProductLevelTable
                      count={count}
                      productLevelData={productlevelData}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: "48%",
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  }}
                >
                  {vault != 0 ? (
                    <CostingGraphData
                      permissionModule={getPermission()}
                      count={count}
                    />
                  ) : (
                    <CostingProductLevelGraph
                      productLevelData={productlevelData && productlevelData}
                      count={count}
                    />
                  )}
                </div>
              </div>
            ) : (
              getPermission()?.length == 0 && (
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {" "}
                  <img src={ValidationImgs} style={{ width: "15rem" }} />
                  <Typography align="center" style={{ width: "100%" }} mt={2}>
                    We are working on it...
                  </Typography>
                </Box>
              )
            )
          ) : (
            <>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    padding: "0 1rem",
                    margin: "0 auto",
                  }}
                >
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                  <Skeleton sx={{ width: "100%", height: "3rem" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 1rem",
                  }}
                >
                  <Box sx={{ height: "40%", padding: "1rem 0" }}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "4rem",
                      flexDirection: "row",
                      height: "8rem",
                      margin: "2rem 0",
                    }}
                  >
                    <Skeleton variant="rectangular" width="33%" height="100%" />
                    <Skeleton variant="rectangular" width="33%" height="100%" />
                    <Skeleton variant="rectangular" width="33%" height="100%" />
                  </Box>

                  <Box>
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                    <Skeleton width="100%" height="30%" />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>
      <CurrencyChangeDynamic
        isOpen={currencyModal.isOpen}
        onCloseModal={currencyModal.close}
        setCount={setCount}
        setApiCallMain={setCount}
        currentCurrency={defaultCurrency}
      />
      <Footer />
    </div>
  );
};
export default CostingViewComponents;
