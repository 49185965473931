import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import successIcon from "../../../Assets/images/success.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

function MHRCurrencySelectionModal({currencies, isOpen, 
    onCloseModal, updateFormikState, updateCurrencyCode ,
    handleCreateRevision,
    allCurrencies


}: any) {
  const history = useHistory()
    const [selectedCurrencies, setSelectedCurrencies] = useState<any>([]);
  const [currencyData, setCurrencyData] = useState<any>({});
  const [conversionData, setConversionData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(false);
  const [loadingCurrencies, setLoadingCurrencies] = useState<Record<string, boolean>>({});
  const [fetchLoading, setFetchLoading] = useState<any>(false);
  const [selectedDropdownCurrency, setSelectedDropdownCurrency] = useState<any>(null);

  const fetchDummyNumberr = async (currencyCode: string): Promise<number> => {
    setLoadingCurrencies((prev) => ({ ...prev, [currencyCode]: true }));
    try {
      const res = await API.get("/customer/costing_conversion/", {
       
          get_conversion_rate: true,
          from_currency: selectedDropdownCurrency, // This should be a valid string
          to_currency: currencyCode,
          module: "Costing",
  
      }, 0);
  
  
      return res.data?.[0]; // Return conversion rate or 0 if not found
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
      return 0; // Return a fallback value
    } finally {
        // Remove the loader field after fetching
        setLoadingCurrencies((prev) => ({ ...prev, [currencyCode]: false }));
      }
  };



  const handleSelect = async (currencyCode: string) => {
    const updatedSelection = selectedCurrencies?.includes(currencyCode)
      ? selectedCurrencies?.filter((code:any) => code !== currencyCode)
      : [...selectedCurrencies, currencyCode];

    setSelectedCurrencies(updatedSelection);


     // If the currency is being added, fetch its dummy number
     if (selectedCurrencies?.includes(currencyCode)) {
        setCurrencyData((prev:any) => {
            const updatedData = { ...prev };
            delete updatedData[currencyCode];
            return updatedData;
          });

          setConversionData((prev:any) => {
            const updatedData = { ...prev };
            delete updatedData[currencyCode];
            return updatedData;
          });
        
      } 
  };


  // Handle "Select All" Option
  const handleSelectAll = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
      setSelectedCurrencies(currencies?.map((curr:any) => curr?.currency_code));
    //   const dummyData: Record<string, number> = {};
    //   for (const currency of currencies) {
    //     dummyData[currency.currency_code] = await fetchDummyNumber(currency.currency_code);
    //   }
    //   setCurrencyData(dummyData);
    } else {
      setSelectedCurrencies([]);
      setCurrencyData({});
      setConversionData({});
    }
  };



  const handleApiCalls = async () => {
    if (!selectedDropdownCurrency) return; // Don't proceed if no dropdown currency is selected

    setIsLoading(true);

    const dummyData: Record<string, number> = {};
    const dummyConversiData: Record<string, number> = {};

    const filteredCurrency = currencies?.filter((currency:any) => {
        const matchingMachineCurrency = selectedCurrencies?.find(
          (mc:any) => mc === currency?.currency_code
        );
        
        if (matchingMachineCurrency) {
            return {
                ...currency,
                machine_currency_code: matchingMachineCurrency ?? "",
              };
        }
      
        
      });

    for (const currencyy of filteredCurrency) {

        const crate:any = await fetchDummyNumberr(currencyy?.currency_code);

        if (crate?.currency_rate !== 0) {
            dummyData[currencyy?.currency_code] = crate?.conversion_factor?.toFixed(4);
            dummyConversiData[currencyy.currency_code] = crate?.conversion_factor?.toFixed(4);
        }
        
        
      }
      setCurrencyData(dummyData);
      setConversionData(dummyConversiData)

   
    

    
    setIsLoading(false);
  };


  useEffect(() => {
    if (selectedDropdownCurrency && selectedCurrencies?.length > 1) {
      handleApiCalls();
    }
  }, [selectedDropdownCurrency, selectedCurrencies]);

  useEffect(() => {
   if (selectedCurrencies?.length <2) {
    setCurrencyData({})
    setConversionData({})
   }
  }, [selectedCurrencies]);

  const handleFetchCurrencies = () => {
if (selectedCurrencies?.length > 1 && Object.keys(currencyData)?.length > 1 ) {
    
    
    updateFormikState(currencyData);
    
   
 
} else if (selectedCurrencies?.length == 1) {
    const targetCurr = selectedCurrencies?.[0]
    
    updateCurrencyCode(targetCurr)
    updateFormikState({[targetCurr]:1})
   
 
}
  }


  useEffect(()=> {
    handleFetchCurrencies()
  },[currencyData, selectedCurrencies])

  // console.log(currencyData, "currency",);




const handleAddRevision = async () => {
  try {
    setFetchLoading(true);
    
    await handleCreateRevision(); // Ensures this completes before moving forward

    setFetchLoading(false);
    onCloseModal();
  } catch (error) {
    console.error("Error in handleCreateRevision:", error);
    setFetchLoading(false); // Ensure loading state is reset even if there's an error
  }
};

// console.log(currencyData, conversionData, "currency countryList");

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>

      <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Fetch Currency 
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={()=> {
                
                onCloseModal();

              }}
            />
          </Box>




      <Box sx={{ width: "100%", bgcolor: "white", p: 3, borderRadius: 2 }}>
      <TableContainer  sx={{ maxHeight: "60vh" }}>
          <Table stickyHeader >
            <TableHead >
              <TableRow sx={{backgroundColor:"primary.main"}}>
                {/* Select All Checkbox */}
                <TableCell padding="checkbox"  sx={{backgroundColor:"primary.main", color: "white", fontWeight: "bold" }}>
                  <Checkbox
                    checked={selectedCurrencies.length === currencies.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell  sx={{backgroundColor:"primary.main", color: "white", fontWeight: "bold" }}>Currency</TableCell>
                <TableCell  sx={{backgroundColor:"primary.main", color: "white", fontWeight: "bold" }}>Machines</TableCell>
                <TableCell  sx={{backgroundColor:"primary.main", color: "white", fontWeight: "bold" }}>Conversion Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currencies.map((currency:any) => (
                <TableRow key={currency.currency_code}>
                  {/* Checkbox */}
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCurrencies.includes(currency.currency_code)}
                      onChange={() => handleSelect(currency.currency_code)}
                    />
                  </TableCell>

                  {/* Currency Code + Flag */}
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`data:image/png;base64,${currency.flag}`}
                        alt={currency.currency_name}
                        width={24}
                        height={16}
                        style={{ marginRight: "8px" }}
                      />
                      <Typography>{currency.currency_code}</Typography>
                    </Box>
                  </TableCell>

                   {/* Machine Count */}
                   <TableCell>
                    
                      <Typography>{currency?.machine_currency_count}</Typography>
                  
                  </TableCell>

                  {/* Conversion Rate */}
                  <TableCell>
                    {conversionData[currency.currency_code] !== undefined ? (
                      <Typography>1 {currency?.currency_code} &nbsp; &nbsp; {`-->`}&nbsp; &nbsp;   {conversionData?.[currency?.currency_code]} {selectedDropdownCurrency}</Typography>
                    ) : (
                        <>
                        {loadingCurrencies?.[currency?.currency_code] ? <CircularProgress size={20} /> : "-"}
                        </>
                      
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Footer Dropdown */}
        <Box sx={{display:"flex",gap:"1rem", alignItems:"center"}}>
        {selectedCurrencies?.length > 1 ? (
          <Box mt={2} sx={{flex:1}}>
            <Select
            sx={{height:"3rem", width:"50%"}}
              fullWidth
              value={selectedDropdownCurrency || ""} 
              onChange={(e) => {
                setSelectedDropdownCurrency(e.target.value);
                updateCurrencyCode(e.target.value)
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Revision Currency
              </MenuItem>
              {allCurrencies?.map((currency:any) => (
                <MenuItem key={currency?.currency_code} value={currency?.currency_code}>
                  {currency?.currency_name} ({currency?.currency_code})
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : <Box mt={2} sx={{flex:1}}></Box>}

        {selectedCurrencies?.length > 0 && (
            <>
           <LoadingButton disabled={selectedCurrencies?.length == 1 ? selectedCurrencies?.length == 0 :  Object.keys(currencyData)?.length == 0} loading={fetchLoading} onClick={handleAddRevision} sx={{
                backgroundColor: 'primary.main',  // Set background to primary.main
                color: 'white', 
                height:"2.5rem",
                marginTop:"1.2rem",  
                '&:disabled': {
                  backgroundColor: 'grey',  // Ensure hover background stays the same
                } ,              // Set text color to white
                '&:hover': {
                  backgroundColor: 'primary.main',  // Ensure hover background stays the same
                },
                '&:active': {
                  backgroundColor: 'primary.main',  // Ensure active background stays the same
                },
              }}>
                Create
            </LoadingButton>

            </>
        )}

</Box>

      
      </Box>
      </Box>
    </Modal>
  );
}

export default MHRCurrencySelectionModal;
