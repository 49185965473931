import axios, {  } from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";

const PCBS3Upload = (fileList: any, pathName: any, setFlagS3Url:any, setUploadFile:any, setFlagUploaded:any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  
  
  var editedImageName: any = `EditedImage${random}`;
  var obj: Array<any> = [];
  setUploadFile(true)
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  },0).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `${pathName}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            
          },
        })
        .then(async (res: any) => {
          // update flag url
            setUploadFile(false)
            setFlagS3Url(`/${pathName}/${random}/${file.name}`)
            setFlagUploaded(true)
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default PCBS3Upload;