// react
import axios from "axios";
import { API } from "../api-services";
import { MODULES } from "../Constants/modules.constants";
import { s3Url } from "../utlis/s3url.utils";
import moment from "moment";


const CalibrationFileS3send = async (
   fileList: any,
   instrumentDescription: any,
   dateOfPurchase: any,
   lifeInYrs: any,
   calibrationRequired: any,
   lastCalibrationDate: any,
   calibrationExpiryDate: any,
   status:any,
   ownership: any,
   reloadCalibrationData:any,
   setReloadCalibrationData:any,
   onCloseModal: any,
   CalibrationActionType:any,
   setSavePopupLoader:any,
   id?:any,
   quantity?:any,
   sendImageAndReportCalibration?:any
) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );

// console.log(fileList,"file1")
   var obj: Array<any> = [];
   const sortAlphaNum = ( a: any, b: any ) => a.localeCompare( b, 'en', { numeric: true } );
   var img: any = "";
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
   if ( typeof ( fileList[0] ) !== "undefined" ) {
      API.get( "cad/cad_upload/", {
         module: MODULES.CAD,
      },0 ).then( res => {

         const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            // console.log(file,"file")
            const formData = new FormData();
            formData.append(
               "key",
               `Calibration/${random}/${file.name}`
            );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
               "X-Amz-Signature",
               res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );

            axios
               .post( s3Url, formData, {
                  onUploadProgress: data => {
                     //Set the progress value to show the progress bar
                  },
               } )
               .then( async ( res: any ) => {
                  if ( res.status === 204 ) {
                    sendImageAndReportCalibration.current.value.push(`Calibration/${random}/${file.name}`);
                    if(CalibrationActionType == "createCalibration"){
                    if(sendImageAndReportCalibration.current.value.length==2){
                        API.post('/xcpep/instrument_calibration/',{instrument_description:instrumentDescription,date_of_purchase:moment(dateOfPurchase).format('YYYY-MM-DD'),instrument_life:lifeInYrs,calibration_required:calibrationRequired=="YES"?true:false,last_calibration_date:calibrationRequired=='YES'?moment(lastCalibrationDate).format('YYYY-MM-DD'):null,calibration_expiry_date:calibrationRequired=='YES'?moment(calibrationExpiryDate).format('YYYY-MM-DD'):null,instrument_status:status,ownership:ownership,instrument_quantity:quantity,calibration_image:sendImageAndReportCalibration.current.value[0],calibration_report:sendImageAndReportCalibration.current.value[1]}).then((res:any)=>{
                            setReloadCalibrationData(!reloadCalibrationData);
                            setSavePopupLoader(false)
                            onCloseModal();
                          }); 
                    }
                }
                else {
                  if(sendImageAndReportCalibration.current.value.length==2){
                    API.put('/xcpep/instrument_calibration/'+id+'/',{instrument_description:instrumentDescription,date_of_purchase:moment(dateOfPurchase).format('YYYY-MM-DD'),instrument_life:lifeInYrs,calibration_required:calibrationRequired=="YES"?true:false,last_calibration_date:calibrationRequired=='YES'?moment(lastCalibrationDate).format('YYYY-MM-DD'):null,calibration_expiry_date:calibrationRequired=='YES'?moment(calibrationExpiryDate).format('YYYY-MM-DD'):null,instrument_status:status,ownership:ownership,instrument_quantity:quantity,calibration_image:sendImageAndReportCalibration.current.value[0],calibration_report:sendImageAndReportCalibration.current.value[1]}).then((res:any)=>{
                        setReloadCalibrationData(!reloadCalibrationData);
                        setSavePopupLoader(false)
                        onCloseModal(); 
                    })}
                }
                  }
               } );
         } );
      } );
   } 

};

// ----------------------------------------------------------------------------------

export default CalibrationFileS3send;
