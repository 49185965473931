import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { API } from "../../api-services";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ProjectDirectoryRows from "./ProjectDirectoryRows";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ProjectDirectoryModal from "./ProjectDirectoryModal";
import AddIcon from "@mui/icons-material/Add";
import ProjectDirectoryProductModal from "./ProjectDirectoryProductModal";
import EditIcon from "@mui/icons-material/Edit";
import Skeleton from "@mui/material/Skeleton";
import addIcon from "../../Assets/images/createFolderDrive.png";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { isUndefined } from "lodash";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import BOMPcbRoboflowFailedReportModal from "../BOMCreate/BOMEntry/BOMPcbRoboflowFailedReportModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";

type ProjectDirectoryVehiclePageProps = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ProjectDirectoryVehiclePage = (
  props: ProjectDirectoryVehiclePageProps
) => {
  const [expanded, setExpanded] = React.useState<string | false>();
  const failedMLOpen = useBaseParamsModal();
  const [VehiclaList, setVehiclaList] = useState<any>();
  const [accLoader, setAccLoader] = useState<any>(false);
  const [projectexpanded, setProjectexpanded] = React.useState<
    string | false
  >();

  const [ProductListData, setProductListData] = useState<any>([]);
  const [productCounter, setproductCounter] = React.useState<any>(0);
  const [projectIds, setprojectIds] = React.useState(null);
  const [Counter, setCounter] = useState<any>(0);

  const openDialogModal = useBaseModal();
  const openDialogModal2 = useBaseModal();
  const [ProductName, setProductName] = useState<any>(null);
  const [FormType, setFormType] = useState<any>();
  const [ProjectData, setProjectData] = useState<any>(null);
  const [ProductDescription, setProductDescription] = useState<any>(null);

  const [vehicletype, setvehicletype] = useState<any>(null);

  const [loader, setLoader] = useState<any>(false);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryList, setCountryList] = React.useState<any[]>([]);

  const getCountryData = () => {
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const ProjecthandleChange =
    (panel: string, projectIds: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setProjectexpanded(newExpanded ? projectIds : false);

      if (newExpanded !== false) {
        setLoader(true);
        setProductListData([]);

        API.get("xcpep/product_list/", { project: projectIds }).then(
          (res: any) => {
            setLoader(false);
            setProductListData(res?.data);
          }
        );
      }
    };

  const HandleOpenModal = (Ids: any) => {
    openDialogModal.open();
    setFormType("Add");
    setprojectIds(Ids);
    // setExpanded(false);
  };

  const HandleOpenModalProduct = (
    Id: any,
    type: any,
    item: any,
    VehicleIds: any
  ) => {
    if (type == "Edit") {
      openDialogModal.open();
      setFormType(type);
      setProjectData(item);

      setprojectIds(Id);
      // setProjectexpanded(false);
    }

    if (type === "Variant") {
      openDialogModal2.open();
      setProductName(null);
      setvehicletype(VehicleIds);
      setProductDescription(null);
      setprojectIds(Id);
      // setProjectexpanded(false);
    }
  };

  useEffect(() => {
    setAccLoader(true);
    API.get("/api/db/vehicle_type/", { module: 40 })
      .then((res: any) => {
        setVehiclaList(res?.data);
        setAccLoader(false);
      })
      .catch((err: any) => {
        console.log("server eeror");
        setAccLoader(false);
      });
  }, [Counter]);

  return (
    <div>
      {openDialogModal.isOpen && (
        <ProjectDirectoryModal
          isOpen={openDialogModal.isOpen}
          onCloseModal={openDialogModal.close}
          type={"projectType"}
          FormType={FormType}
          setCounter={setCounter}
          ProjectData={ProjectData && ProjectData}
          projectIds={projectIds && projectIds}
        />
      )}
      {openDialogModal2.isOpen && (
        <ProjectDirectoryProductModal
          isOpen={openDialogModal2.isOpen}
          onCloseModal={openDialogModal2.close}
          setproductCounter={setCounter}
          projectIds={projectIds && projectIds}
          setProductListData={setProductListData}
          VehicleIdstype={vehicletype}
          setProjectexpanded={setProjectexpanded}
        />
      )}
      {failedMLOpen.isOpen && (
        <BOMPcbRoboflowFailedReportModal
          isOpen={failedMLOpen.isOpen}
          onCloseModal={failedMLOpen.close}
          project={failedMLOpen.propsId}
        />
      )}
      {accLoader ? (
        <>
          <Box sx={{ padding: "0 1rem" }}>
            <Skeleton
              variant="rectangular"
              height="2.5rem"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="2.5rem"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="2.5rem"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="2.5rem"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{ height: "85vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {VehiclaList &&
              VehiclaList?.map((item: any, index: any) => {
                return (
                  <>
                    <Accordion
                      expanded={expanded === item?.id}
                      onChange={handleChange(item?.id)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        sx={{ minHeight: "36px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <Typography sx={{ fontSize: "1rem" }}>
                            {item?.name}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                display: "flex",
                                alignItems: "center",
                                columnGap: "5px",
                              }}
                            >
                              {" "}
                              <Typography
                                sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                              >
                                Projects : {item?.project_count}
                              </Typography>
                              <span>|</span>{" "}
                              <Typography
                                sx={{ fontSize: "1rem", width: "8rem" }}
                              >
                                <span>Products :</span> {item?.product_count}
                              </Typography>
                            </Typography>
                            <Button
                              variant="contained"
                              // onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              size="small"
                              onClick={() => HandleOpenModal(item?.id)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <AddIcon
                                  sx={{ fontSize: "1.2rem" }}
                                  onClick={() => HandleOpenModal(item?.id)}
                                />
                                <Typography
                                  sx={{ fontSize: "0.87rem", lineHeight: "1" }}
                                >
                                  Add Project
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        </Box>
                      </AccordionSummary>

                      {item?.project_list &&
                        item?.project_list.map((projectname: any, ind: any) => {
                          return (
                            <>
                              <Accordion
                                style={{ margin: "1rem !important" }}
                                expanded={projectexpanded === projectname?.id}
                                onChange={ProjecthandleChange(
                                  ind,
                                  projectname?.id
                                )}
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  sx={{
                                    minHeight: "36px",
                                    paddingLeft: "2rem",
                                  }}
                                >
                                  <AccordionDetails
                                    sx={{
                                      width: "55%",
                                      alignItems: "center",
                                      display: "flex",
                                      borderTop: "none !important",
                                      marginLeft: "0.5rem",
                                    }}
                                    // onClick={(event) => event.stopPropagation()}
                                    // onFocus={(event) => event.stopPropagation()}
                                    // @ts-ignore
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: "0.5rem",
                                      }}
                                    >
                                      {/* <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        HandleOpenModalProduct(
                                          projectname?.id,
                                          "Edit",
                                          projectname,
                                          null
                                        )
                                      }
                                      sx={{ display: 'flex', alignItems: 'center', }}
                                    > */}
                                      <EditIcon
                                        sx={{
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                          "&:hover": {
                                            transform: "Scale(1.05)",
                                            transition: "transform 0.5s ease",
                                          },
                                        }}
                                        onClick={() =>
                                          HandleOpenModalProduct(
                                            projectname?.id,
                                            "Edit",
                                            projectname,
                                            null
                                          )
                                        }
                                      />
                                      {/* </Button> */}
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        {projectname?.name} (
                                        {projectname?.description}){" "}
                                        {projectname?.currency || ""}
                                      </Typography>
                                    </Box>
                                  </AccordionDetails>

                                  <AccordionDetails
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    sx={{
                                      width: "45%",
                                      borderTop: "none !important",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: "1rem",
                                        justifyContent: "flex-end",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <SmsFailedIcon
                                        sx={{
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                        titleAccess="Failed Dataset Report"
                                        onClick={() =>
                                          failedMLOpen.open(projectname?.id)
                                        }
                                      />
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        {" "}
                                        <span>Products :</span>{" "}
                                        <span>
                                          {projectname?.product_count}
                                        </span>
                                      </Typography>

                                      <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() =>
                                          HandleOpenModalProduct(
                                            projectname?.id,
                                            "Variant",
                                            null,
                                            item?.id
                                          )
                                        }
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "0.87rem",
                                              lineHeight: "1",
                                            }}
                                          >
                                            Add Variant
                                          </Typography>
                                          <AddIcon
                                            sx={{ fontSize: "1.2rem" }}
                                            onClick={() =>
                                              HandleOpenModalProduct(
                                                projectname?.id,
                                                "Variant",
                                                null,
                                                item?.id
                                              )
                                            }
                                          />
                                        </Box>
                                      </Button>
                                    </Typography>
                                  </AccordionDetails>
                                </AccordionSummary>
                                {
                                  <AccordionDetails>
                                    <Box sx={{ marginLeft: "2rem" }}>
                                      {loader ? (
                                        <Box>
                                          <Skeleton />
                                          <Skeleton animation="wave" />
                                          <Skeleton animation={false} />
                                        </Box>
                                      ) : (
                                        <Table>
                                          <TableHead>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                paddingLeft: "1rem",
                                                width: "4.5rem",
                                              }}
                                            >
                                              Sl No.
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "10rem",
                                              }}
                                            >
                                              Variant
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "6rem",
                                              }}
                                            >
                                              Product ID
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "7rem",
                                              }}
                                            >
                                              Created On
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                              }}
                                            >
                                              OEM
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "6rem",
                                              }}
                                            >
                                              Mfg Year
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "8rem",
                                              }}
                                            >
                                              Wt. Error (%)
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "10rem",
                                              }}
                                            >
                                              Currency
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                fontSize: "1rem",
                                                borderColor: "primary.light",
                                                width: "5rem",
                                              }}
                                            ></TableCell>
                                          </TableHead>
                                          {ProductListData &&
                                            ProductListData?.map(
                                              (productItem: any, indx: any) => {
                                                // {console.log(SearchOption(productItem),"vijdvjfjvifijvfjivfjiv")}
                                                return (
                                                  <>
                                                    {!isUndefined(
                                                      productItem
                                                    ) && (
                                                      <ProjectDirectoryRows
                                                        items={
                                                          productItem &&
                                                          productItem
                                                        }
                                                        keys={indx}
                                                        currencyCodes={
                                                          currencyCodes
                                                        }
                                                        countryList={
                                                          countryList
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </Table>
                                      )}
                                    </Box>
                                  </AccordionDetails>
                                }
                              </Accordion>
                            </>
                          );
                        })}

                      {item?.project_list &&
                        item?.project_list?.length == 0 && (
                          <>
                            <AccordionDetails
                              sx={{
                                borderTop: "none !important",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  padding: "1rem",
                                  height: "8rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => HandleOpenModal(item?.id)}
                              >
                                <img
                                  src={addIcon}
                                  style={{ width: "7rem", height: "auto" }}
                                />
                                <Typography
                                  sx={{
                                    position: "relative",
                                    zIndex: "1",
                                    marginTop: "-4.4rem",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Add Project
                                </Typography>
                              </Box>
                            </AccordionDetails>
                          </>
                        )}
                    </Accordion>
                  </>
                );
              })}
          </Box>
        </>
      )}
    </div>
  );
};

export default ProjectDirectoryVehiclePage;
