import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InfoIcon from "@mui/icons-material/Info";
import Flag from "../../../Assets/images/flag.png";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LaunchIcon from "@mui/icons-material/Launch";
import MachineFlagModal from "./MachineFlagModal";
import { useShareWithDb } from "../useShareWithDb";
import MHRmoveModal from "./MHRmoveModal";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png"
import FetchMachineListModal from "./FetchMachineListModal";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import move from "../../../Assets/images/right move.svg";
import MachineGraph from "./MachineGraph";
import { MHRRevisionInfoModal } from "./MHRRevisionInfoModal";
import AddMachineModal from "./AddMachineModal";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import LockStatusInfoModal from "../LockStatusInfoModal";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import MachineHourRateActionComponents from "../ActionComponents/MachineHourRateActionComponents";
import Popover from '@mui/material/Popover';
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import FormulalistModal from "../../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/FormulalistModal";
import EditMachineModal from "./EditMachineModal";
import Select, { SelectChangeEvent } from '@mui/material/Select';

export interface IRawMaterialRevisionTableProps {


  setRecyclebinCounter: any;
  getSubRevisionData: any;
}

// const CustomExportButton = (props: ButtonProps) => (
//   <GridToolbarExportContainer {...props}>
//     <GridToolbarColumnsButton />
//     <GridCsvExportMenuItem options={csvOptions} />
//   </GridToolbarExportContainer>
// );

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '100%', xl: '100%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.6rem',
  }}>
    <span>
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));
const csvOptions: GridCsvExportOptions = {
  fileName: "Machine HourRate Table CSV",
  delimiter: ",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);



export function MachineHourRateTable(props: IRawMaterialRevisionTableProps) {
  const formulamodal: any = useBaseParamsModal();
  const { tab, year, month, revisionId, typeId, databaseName, categoryName, status, search, lockCounter } = useRouteParams<any>();
  const { setRecyclebinCounter, getSubRevisionData } = props
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);

  const [headerData, setHeaderData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);
  const [RowsItem, setRowsItem] = useState<any>();
  const [HourValue, setHourValue] = useState<any>();
  const [dayValues, setdayValues] = useState<any>();
  const [WokringValues, setWokringValues] = useState<any>();
  const [PowerRate, setPowerRate] = useState<any>();
  const [PowerCut, setPowerCut] = useState<any>();
  const [DieselGen, setDieselGen] = useState<any>();
  const [BankLoan, setBankLoan] = useState<any>();
  const [statusColor, setStatusColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const history = useHistory<any>();
  const machinemodal = useBaseModal();
  const machineAddAndFetchmodal = useBaseModal();
  const [Counter, setCounter] = useState<any>(0);
  const [tableLoader, settableLoder] = useState<any>(false);
  const [RowItem, setRowItem] = useState<any>();
  const infomodal = useBaseModal()
  const flagmodal = useBaseModal();
  const [Annualrent, setAnnualrent] = useState<any>()
  const [InitialInvestment, setInitialInvestment] = useState<any>()
  const [currencyCode, setCurrencyCode] = useState<any>()
  const [rowItems, setRowItems] = useState<any>({})
  const MachineMove = useBaseModal();
  const [SingleData, setSingleData] = useState<any>([])
  const { height, width } = useWindowDimensions();
  const graphmodal = useBaseModal()
  const [useraction, setuseraction] = useState<any>(null)
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile = useSelector(userSelectors.selectAll)
  const [selectedTab, setSelectedTab] = React.useState('');
  const lockmodal = useBaseModal()

  const Editmachinemodal = useBaseModal();
  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowData: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(rowData);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const EditHandler = (row: any) => {
    Editmachinemodal.open();
    setRowsItem(row);
  };

  const AccordianhandleChange = (categoryName: any) => {
    // getMaterialData(categoryName);
    // setTabVale(categoryName);
    setSelectedTab(categoryName)
    history.push(
      `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machine/${categoryName}/${status}/0/${lockCounter}`
    );
  };



  const DeleteHandler = (item: any) => {
    setAnchorEl(null);
    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/machine_cost/${item?.id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1)
          setRecyclebinCounter((prev: any) => prev + 1)
        }).catch((err: any) => {

        })
      }
    });





  }


  // For Initial tab select on load
  // React.useEffect(() => {
  // 	if (CategoryList && CategoryList.length > 0) {
  // 	  // Set the selected tab to the first item in categoryList when the component loads
  // 	  setSelectedTab(CategoryList[0].id);
  // 	  history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${CategoryList[0].id}/${status}/${search}`);
  // 	//   getMaterialData(categoryList[0].category);
  // 	}
  //   }, [CategoryList]);


  const handleShareWith: any = (item: any) => {

    setRowItems(item)
    MachineMove.open()
    setAnchorEl(null);
    // toolMaterialShare.open(idGet,shareItemCodeGet,listCommodityGet,shareItemNameGet)
  }
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const LockHandler = (item: any, type: any) => {
    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item[0]?.locked_user)) {
        API.put(`/api/db/machine_detail/${item[0]?.id}/`, {
          lock: type, revision: revisionId,

        }).then((res: any) => {
          API.get("/api/db/machine_detail/", {
            revision: revisionId,
          }).then((res: any) => {
            setHeaderData(res?.data);
          });
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item[0])
        lockmodal.open()
      }

    }
    if (type == true) {
      API.put(`/api/db/machine_detail/${item[0]?.id}/`, {
        lock: type, revision: revisionId,

      }).then((res: any) => {
        API.get("/api/db/machine_detail/", {
          revision: revisionId,
        }).then((res: any) => {
          setHeaderData(res?.data);
        });
      }).catch((err: any) => {
        console.log("Server error")
      })

    }
  }
  React.useEffect(() => {
    settableLoder(true);
    API.get("/api/db/machine_cost/", {
      revision: revisionId,
      commodity: categoryName === undefined ? 0 : categoryName,
    })
      .then((res: any) => {
        settableLoder(false);
        setTableData(res?.data);

      })
      .catch((err: any) => {
        settableLoder(false);
        console.log("Sever EEror");
      });
  }, [categoryName, Counter, lockCounter]);

  React.useEffect(() => {
    if (categoryName !== '0') {
      setSelectedTab(categoryName);
      // getCategoryList();
    }
  }, [categoryName, lockCounter]);

  const AddAndFetchMachineHandler = (e: any) => {
    e?.stopPropagation();
    machineAddAndFetchmodal.open();
  }


  React.useEffect(() => {
    if (headerData && headerData?.length > 0) {
      setHourValue(headerData && headerData[0]?.hours_per_shift);
      setdayValues(headerData && headerData[0]?.shifts_per_day);
      setWokringValues(headerData && headerData[0]?.days_per_year);
      setPowerRate(headerData && headerData[0]?.power_cost);
      setPowerCut(headerData && headerData[0]?.powercut);
      setDieselGen(headerData && headerData[0]?.generator_cost);
      setBankLoan(headerData && headerData[0]?.interest_rate);
      setAnnualrent(headerData && headerData[0]?.annual_land_rent)
      setInitialInvestment(headerData && headerData[0]?.initial_investment)
      setCurrencyCode(headerData && headerData[0]?.currency_code)

    }
  }, [headerData]);

  const redirectionHanlder = (item: any) => {
    history.push(
      `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machineDB/${categoryName}/true/0/${lockCounter}`
    );
  };

  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {/*Direct Menus here  */}
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellvalues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: 'primary.main', }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              elevation={1}
            >
              <MachineHourRateActionComponents
                cellValues={cellvalues.row}
                rowId={rowId}
                InfoHandler={InfoHandler}
                DeleteHandler={DeleteHandler}
                GraphHandler={GraphHandler}
                redirectionHanlder={redirectionHanlder}
                handleShareWith={handleShareWith}
                useraction={useraction}
                setAnchorEl={setAnchorEl}
              />
            </Popover>

            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Edit"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                  opacity: cellvalues?.row?.lock == true ? 0.3 : 1
                }}
                onClick={() => {
                  if (cellvalues?.row?.lock != true) { EditHandler(cellvalues?.row) }
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>


          // <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
          //   {cellvalues?.row.status ?
          //     <IconButton title="Machine Info" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => InfoHandler(cellvalues?.row)}><InfoIcon /></IconButton> :
          //     useraction && useraction?.includes("D") && <IconButton title="Delete" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => DeleteHandler(cellvalues?.row)}><DeleteIcon /></IconButton>
          //   }

          //   <IconButton title="Graph" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => GraphHandler(cellvalues?.row)}  ><EqualizerIcon /></IconButton>
          //   <IconButton title="Redirect" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => redirectionHanlder(cellvalues?.row)} ><LaunchIcon /> </IconButton>
          //   <IconButton title="flag" sx={{ color: 'primary.main', padding: '0' }}>{cellvalues.row.flag == null ? <FlagOutlinedIcon /> :
          //     <FlagIcon onClick={() => cellvalues.row.flag !== null && window.open(cellvalues?.row?.flag, "_blank")}
          //       sx={{ cursor: "pointer", color: 'green' }}
          //     />
          //   }</IconButton>

          //   {              useraction && useraction.includes("U") && <IconButton title="Move" sx={{ color: 'primary.main', padding: '0rem', cursor: "pointer" }} onClick={() => handleShareWith(cellvalues?.row)}> <img src={move} style={{ height: '1.3rem', cursor: 'pointer' }} /> </IconButton>}
          // </Box>
        );
      },
    },
    { field: "Ids", headerName: "Sl.No", width: 60 },
    {
      field: "machine_code",
      headerName: "Machine Code",
      width: 150,
    },
    {
      field: "name",
      headerName: "Machine Name",
      width: 150,
    },

    {
      field: "brand_name",
      headerName: "Brand Name",
      width: 130,
    },
    {
      field: "operation",
      headerName: "Operation",
      width: 150,
    },
    {
      field: "cost_per_hour",
      description: 'Machine Hour Rate',
      headerName: "MHR ",
      width: width > 1400 ? 160 : 140,
      align: 'right',
      headerAlign: 'right',
      cellClassName: "super-app-theme--cell",
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography sx={{ fontSize: '1rem', textDecoration: "underline", cursor: "pointer" }} onClick={() => {
              console.log(cellvalue?.row);

              formulamodal.open({
                revision_id: revisionId,
                machine_id: cellvalue?.row?.rowId,
                mhr_details: true
              })



            }}>
              {cellvalue?.row?.cost_per_hour?.toFixed(2)}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Hour',
      sortable: false,
      minWidth: width > 1370 ? 120 : 110,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "rental_cost",
      headerName: "Rental Cost",
      width: 120,
    },
    {
      field: "capital_cost",
      headerName: "Capital Cost",
      width: width > 1400 ? 130 : 110,
      align: 'left',
    },
    {
      field: "duty",
      headerName: "Duty",
      width: 80,
      align: 'left',
    },
    {
      field: "installation_cost",
      description: 'Installation Cost',
      headerName: "Inst Cost",
      width: width > 1400 ? 100 : 100,
      align: 'left',
    },

    {
      field: "life",
      headerName: "Life (Yrs)",
      width: width > 1400 ? 80 : 70,
    },
    {
      field: "maintanance",
      description: 'Maintanance Cost(%/Yrs/Shift)',
      headerName: "Maint.(%/Yrs/Shift)",
      width: width > 1400 ? 140 : 120,
    },

    {
      field: "consumption",
      description: 'Consumption Cost(%)',
      headerName: "Cons. Cost (%)",
      width: width > 1400 ? 110 : 100,
      align: 'right',
    },
    {
      field: "power_consumption",
      description: 'Average Power Consumption (kWh)',
      headerName: "Avg Pwr Cons.(kWh)",
      width: width > 1400 ? 140 : 120,
      align: 'right',
    },
    {
      field: "effectiveness",
      description: 'Effectiveness (%)',
      headerName: "Effect (%)",
      width: width > 1400 ? 90 : 80,
    },
    {
      field: "peak_power_factor",
      description: "Peak Power Factor",
      headerName: "Peak Pwr Fact",
      width: width > 1400 ? 120 : 100,
    },
    {
      field: "reference",
      headerName: "Reference",
      width: width > 1400 ? 100 : 80,
      renderCell: (cellvalue: any) => {
        var link = ["https", "http", "www"];
        var likData = String(cellvalue?.row?.reference);
        if (cellvalue?.row?.reference !== null) {


          if (cellvalue?.row?.reference.includes("/")) {
            return (
              <>
                {isUrl(cellvalue?.row?.reference) ? (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      borderBottom: "1px solid",
                    }}
                    onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    Link
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      // borderBottom: "1px solid",
                    }}
                  // onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    {cellvalue?.row?.reference}
                  </Typography>
                )}
              </>
            );
          }

        } else {
          return <>{cellvalue?.row?.reference}</>;
        }

      },
    },
    {
      field: "fixed_cost",
      description: "Fixed Cost(Currency/Year)",
      headerName: "Fxd Cst.(Curr/Yr)",
      width: width > 1400 ? 160 : 150,
      align: 'right',
      headerAlign: 'right',
      cellClassName: "super-app-theme--cell",
      renderCell: (cellvalues: any) => {
        return <> {cellvalues?.row?.fixed_cost}</>;
      },
    },
    {
      field: "maintanance_cost",
      description: "Maintanance Cost(Currency/Year)",
      headerName: "Maint Cost(Curr/Yr)",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 160 : 150,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellvalues: any) => {
        return <> {cellvalues?.row?.maintanance_cost}</>;
      },
    },

    {
      field: "consumption_cost",
      description: "Consumption Cost(Currency/Year)",
      headerName: "Cons.Cst(Curr/Yr)",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 160 : 150,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellvalues: any) => {
        return <> {cellvalues?.row?.consumption_cost}</>;
      },
    },
    {
      field: "variable_cost",
      description: "Variable Cost(Currency/Year)",
      headerName: "Var.Cst(Curr/Yr)",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 160 : 150,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellvalues: any) => {
        return <> {cellvalues?.row?.variable_cost}</>;
      },
    },
  ];


  const Rows: any = () => {
    return (
      TableData &&
      TableData?.map((item: any, index: any) => {
        return {
          Ids: index + 1,
          id: item?.id,
          rowId: item?.machine?.id,
          duty: item?.duty,
          machine_code: item?.machine?.machine_code,
          name: item?.machine?.name,
          brand_name: item?.machine?.brand_name,
          operation: item?.machine?.operation,
          description: item?.machine?.description,
          capital_cost: item?.capital_cost,
          installation_cost: item?.installation_cost,
          life: item?.machine?.life,
          cell_id: item?.machine?.id,
          fixed_cost: item?.fixed_cost,
          rental_cost: item?.rental_cost,
          status: item?.mapped_status,
          maintanance: item?.machine?.maintanance,
          maintanance_cost: item?.maintanance_cost,
          consumption: item?.machine?.consumption,
          consumption_cost: item?.consumption_cost,
          power_consumption: item?.machine?.power_consumption,
          cost_per_hour: item?.cost_per_hour,
          variable_cost: item?.variable_cost,
          reference: item?.machine?.reference,
          peak_power_factor: item?.machine?.peak_power_factor,
          effectiveness: item?.machine?.effectiveness,
          flag: item?.machine?.flag,
          currency_id: item?.currency_id,
          currency_code: item?.currency_code,
          unit: item?.unit,
          specification: item?.machine?.specification,
        };
      })
    );
  };

  const SaveHandler = () => {
    setLoader(true);
    API.put(`/api/db/machine_detail/${headerData[0]?.id}/`, {
      hours_per_shift: Number(HourValue),
      shifts_per_day: Number(dayValues),
      days_per_year: Number(WokringValues),
      power_cost: Number(PowerRate),
      powercut: Number(PowerCut),
      generator_cost: Number(DieselGen),
      interest_rate: Number(BankLoan),
      annual_land_rent: Number(Annualrent),
      initial_investment: Number(InitialInvestment)
    })
      .then((res: any) => {
        setStatusColor(false);
        setHourValue(res && res?.hours_per_shift);
        setdayValues(res && res?.shifts_per_day);
        setWokringValues(res && res?.days_per_year);
        setPowerRate(res && res?.power_cost);
        setPowerCut(res && res?.powercut);
        setDieselGen(res && res?.generator_cost);
        setBankLoan(res && res?.interest_rate);
        setCounter((prev: any) => prev + 1)
        setLoader(false);
        getSubRevisionData()
      })
      .catch((err: any) => {
        setStatusColor(false);
        setLoader(false);
      });
  };

  const AutoSaveHandler = () => {
    if ((HourValue && dayValues && WokringValues) || (PowerRate && DieselGen)) {
      API.put(`/api/db/machine_detail/${headerData[0]?.id}/`, {
        hours_per_shift: Number(HourValue),
        shifts_per_day: Number(dayValues),
        days_per_year: Number(WokringValues),
        power_cost: Number(PowerRate),
        powercut: Number(PowerCut),
        generator_cost: Number(DieselGen),
        interest_rate: Number(BankLoan),
        annual_land_rent: Number(Annualrent),
        initial_investment: Number(InitialInvestment)
      })
        .then((res: any) => {
          setStatusColor(false);
          setHourValue(res && res?.hours_per_shift);
          setdayValues(res && res?.shifts_per_day);
          setWokringValues(res && res?.days_per_year);
          setPowerRate(res && res?.power_cost);
          setPowerCut(res && res?.powercut);
          setDieselGen(res && res?.generator_cost);
          setBankLoan(res && res?.interest_rate);
          setCounter((prev: any) => prev + 1)
          setLoader(false);
          // getSubRevisionData()
        })
        .catch((err: any) => {
          setStatusColor(false);
          setLoader(false);
        });
    }
  };


  const AddmachineHanlder = (e: any) => {
    e?.stopPropagation();
    machinemodal.open();
  };

  const handlechange = (e: any, type: any) => {
    setStatusColor(true);

    if (type == "hours") {
      setHourValue(e?.target?.value);
    }
    if (type == "days") {
      setdayValues(e?.target?.value);
    }
    if (type == "Working") {
      setWokringValues(e?.target?.value);
    }
    if (type == "Rate") {
      setPowerRate(e?.target?.value);
    }
    if (type == "Cut") {
      setPowerCut(e?.target?.value);
    }
    if (type == "Diesel") {
      setDieselGen(e?.target?.value);
    }
    if (type == "loan") {
      setBankLoan(e?.target?.value);
    }
    if (type == "Annualrent") {
      setAnnualrent(e?.target?.value)
    } if (type == "InitialInvestment") {
      setInitialInvestment(e?.target?.value);
    }
  };
  const GraphHandler: any = (item: any) => {

    setRowItems(item)
    graphmodal.open()

  }
  const InfoHandler: any = (item: any) => {
    setRowItems(item)
    infomodal.open()
  }
  React.useEffect(() => {
    API.get("/api/db/machine_detail/", {
      revision: revisionId,
    }).then((res: any) => {
      setHeaderData(res?.data);
    });
  }, [revisionId, Counter, lockCounter]);

  React.useEffect(() => {
    setCommodityLoader(true);
    API.get("/api/db/revision_database/", {
      revision_id: revisionId,
      category_type: "machinedetail",
    })
      .then((res: any) => {
        setCommodityLoader(false);
        setCategoryList(res?.data);
        if (categoryName == 0) {
          setSelectedTab(res?.data[0]?.id)
          history.push(
            `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machine/${res?.data[0]?.id}/${status}/0/${lockCounter}`
          );
        }
        setSingleData(res?.data[0]?.id)
      })
      .catch((err: any) => {
        setCommodityLoader(false);
      });
  }, [revisionId, Counter, lockCounter]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId, Counter, lockCounter]);

  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }
    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }
    }
  }, []);

  const swalalterHandler = useCallback((type: any) => {

    if (type == true) {
      setCounter((prev: any) => prev + 1)
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
      <p style="color:#007fff;">Moved Successfully!</p>   
       </div>`,
      });
    } else {
      Swal.fire({
        html: `<div> 
      <p style="color:red;">Sever Error!</p>   
       </div>`,
        icon: 'error',
      });
    }



  }, []);




  return (
    <Box sx={{ width: { lg: "77vw", xl: '82vw' }, }}
    >

      {formulamodal.isOpen && (
        <FormulalistModal revisionId={formulamodal.propsId?.revision_id} isOpen={formulamodal.isOpen} onClose={() => formulamodal.close()} machine_id={formulamodal.propsId?.machine_id} mhr_details={formulamodal.propsId?.mhr_details} labour_id={formulamodal.propsId?.labour_id} lhr_details={formulamodal.propsId?.lhr_details} />
      )}



      {Editmachinemodal.isOpen && (
        <EditMachineModal
          type={"edit"}
          swalalterHandlerAdd={swalalterHandlerAdd}
          actions={RowsItem}
          setCounter={setCounter}
          onCloseModal={Editmachinemodal.close}
          isOpen={Editmachinemodal.isOpen}
        />
      )}


      {machinemodal.isOpen && (
        <FetchMachineListModal
          expanded={SingleData}
          onCloseModal={machinemodal.close}
          isOpen={machinemodal.isOpen}
          setCounter={setCounter}
          getSubRevisionData={getSubRevisionData}
        />
      )}

      {infomodal.isOpen &&
        <MHRRevisionInfoModal
          onCloseModal={infomodal.close}
          isOpen={infomodal.isOpen}
          Item={rowItems}

        />

      }
      {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}
      {MachineMove.isOpen && (
        <MHRmoveModal
          isOpen={MachineMove.open}
          onCloseModal={MachineMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandler}
        />
      )}
      {machineAddAndFetchmodal.isOpen && (
        <AddMachineModal
          isOpen={machineAddAndFetchmodal.open}
          onCloseModal={machineAddAndFetchmodal.close}
          setCounter={setCounter}
          swalalterHandlerAdd={swalalterHandlerAdd}
          type={"Add"}
        />
      )}

      {
        graphmodal.isOpen &&
        <MachineGraph
          isOpen={graphmodal.open}
          onCloseModal={graphmodal.close}
          rowItems={rowItems}
        />
      }
      {flagmodal?.isOpen && (
        <MachineFlagModal
          onCloseModal={flagmodal.close}
          isOpen={flagmodal.isOpen}
          RowItem={RowItem}
        />
      )}
      {CommodityLoader ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <Table sx={{ width: '100%',
               tableLayout: 'fixed' 
               }}>

{/* <TableRow  sx={{ width: '100%', }}>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: `${100 / 7}%`
                  }}>
                  <TextField
                    title="Hours/Shift"
                    variant="filled"
                    size="small"
                    label={width > 1100 ? "Hours/Shift" : "Hrs/Sft"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                       width:'100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    disabled={headerData[0]?.lock_status}
                    value={HourValue}
                    onChange={(e: any) => handlechange(e, "hours")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: `${100 / 7}%`
                  }}>
                  <TextField
                    title="Shifts/Day"
                    variant="filled"
                    size="small"

                    label={width > 1100 ? "Shifts/Day" : "Sfts/Dy"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    disabled={headerData[0]?.lock_status}
                    value={dayValues}
                    onChange={(e: any) => handlechange(e, "days")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                     width: `${100 / 7}%`
                  }}>
                  <TextField
                    title="Work Days/Year"
                    variant="filled"
                    size="small"
                    label={width > 1100 ? "Work Days/Year" : "W.Days/Yr"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    disabled={headerData[0]?.lock_status}
                    value={WokringValues}
                    onChange={(e: any) => handlechange(e, "Working")}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: `${100 / 7}%`
                  }}>

                  <TextField
                    title="Work Hours/Year"
                    variant="filled"
                    size="small"
                    disabled={true}
                    label={width > 1100 ? "Work Hours/Year" : "Wrk Hrs/Yr"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)!important',
                      },
                      '& .MuiFilledInput-input:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-input:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    value={headerData && headerData[0]?.yearly_workhours}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: `${100 / 7}%`
                  }}>
                  <TextField
                    title="Initial Investment (%)"
                    variant="filled"
                    size="small"
                    disabled={headerData[0]?.lock_status}
                    label={width > 1100 ? "Initial Investment (%)" : "Initial Investment (%)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={InitialInvestment}
                    onChange={(e: any) => handlechange(e, "InitialInvestment")}
                  />
                </TableCell>

                

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: `${100 / 7}%`
                  }}>
                  <TextField
                    title="Power Cut (%)"
                    disabled={headerData[0]?.lock_status}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="filled-basic" variant="filled"
                    label={width > 1100 ? "Power Cut (%)" : "Pwr Cut(%)"}
                    size="small"
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={PowerCut}
                    onChange={(e: any) => handlechange(e, "Cut")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: '5rem'
                  }}>
                  <Box sx={{ display: "flex", columnGap: '3px' }}>

                    {useraction && useraction?.includes("U") && <LoadingButton
                      sx={{ minWidth: '1rem', color: statusColor ? "red" : "primary.main" }}
                      loading={Loader}
                      onClick={() => SaveHandler()}
                      startIcon={<SaveIcon sx={{ marginRight: '-1rem' }} />}
                    />}
                    {headerData && headerData[0]?.lock_status ?
                      <LoadingButton startIcon={<LockIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(headerData, false)}></LoadingButton>
                      : <LoadingButton startIcon={<LockOpenIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(headerData, true)}></LoadingButton>
                    }</Box>
                </TableCell>
              </TableRow> */}

{/* 
              <TableRow sx={{ width: '100%', }}>
              

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                     width: `${100 / 5}%`
                  }}>
                  <TextField
                    title="Annual Land Rent  (INR/m2) "
                    variant="filled"
                    size="small"
                    disabled={headerData[0]?.lock_status}
                    label={width > 1100 ? "Annual Land Rent (INR/m2)" : "Annual Land Rent (INR/mm2)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={Annualrent}
                    onChange={(e: any) => handlechange(e, "Annualrent")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                     width: `${100 / 5}%`
                  }}>

                  <TextField
                    title="Government Power Rate (INR/KWh)"
                    variant="filled"
                    size="small"

                    disabled={headerData[0]?.lock_status}
                    label={width > 1400 ? "Govt. Pwr Rate (INR/KWh)" : "Govt. Pwr Rt(INR/KWh)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    value={PowerRate}
                    onChange={(e: any) => handlechange(e, "Rate")}
                  />
                </TableCell>

             

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                     width: `${100 / 5}%`
                  }}>

                  <TextField
                    title="Diesel Generator Rate (INR/KWh)"
                    variant="filled"
                    size="small"
                    disabled={headerData[0]?.lock_status}
                    label={width > 1100 ? "Diesel Gen. Rate (INR/KWh)" : "Dsl Gen. Rt(INR/KWh)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    value={DieselGen}
                    onChange={(e: any) => handlechange(e, "Diesel")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>

                  <TextField
                    title="Total Power Rate (INR/kWh)"
                    variant="filled"
                    size="small"
                    disabled={true}
                    label={width > 1400 ? "Total Pwr Rate (INR/kWh)" : "Ttl Pwr Rt(INR/kWh)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-input:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-input:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={headerData && headerData[0]?.effective_power_cost}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>
                  <TextField
                    title="Bank Loan Lending Rate (%)"
                    variant="filled"
                    size="small"
                    label={width > 1400 ? "Bank Loan Lending Rate (%)" : "Bnk Loan Ld Rt(%)"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: { lg: '8vw', xl: '9vw' },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",

                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    disabled={headerData[0]?.lock_status}
                    value={BankLoan}
                    onChange={(e: any) => handlechange(e, "loan")}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>

                  <TextField
                    title="Work Hours/Year"
                    variant="filled"
                    size="small"
                    disabled={true}
                    label={width > 1400 ? "Work Hrs/Yr" : "Wrk Hrs/Yr"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: { lg: '6vw', xl: '100%' },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)!important',
                      },
                      '& .MuiFilledInput-input:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-input:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },

                    }}
                    // type="number"
                    value={headerData && headerData[0]?.yearly_workhours}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    width: '5rem'
                  }}>
                  <Box sx={{ display: "flex", columnGap: '3px' }}>

                    {useraction && useraction?.includes("U") && <LoadingButton
                      sx={{ minWidth: '1rem', color: statusColor ? "red" : "primary.main" }}
                      loading={Loader}
                      onClick={() => SaveHandler()}
                      startIcon={<SaveIcon sx={{ marginRight: '-1rem' }} />}
                    />}
                    {headerData && headerData[0]?.lock_status ?
                      <LoadingButton startIcon={<LockIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(headerData, false)}></LoadingButton>
                      : <LoadingButton startIcon={<LockOpenIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(headerData, true)}></LoadingButton>
                    }</Box>
                </TableCell>
              </TableRow> */}
           


              <TableRow>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '10rem', lg: '9rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '10rem', lg: '9rem' }, backgroundColor: '#ddffe6', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Hours/Shift</Typography><Typography>:</Typography>
                    <TextField
                      // title="Hours/Shift"
                      variant="standard"
                      size="small"
                      // label={width > 1400 ? "Hours/Shift" : "Hrs/Sft"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: '3rem',
                        //  { lg: '4vw', xl: '100%' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      disabled={headerData[0]?.lock_status}
                      value={HourValue}
                      onChange={(e: any) => handlechange(e, "hours")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '15rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '15rem' }, backgroundColor: '#ddffe6', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '55%' }, }}>Shifts/Day"</Typography><Typography>:</Typography>
                    <TextField
                      // title="Shifts/Day"
                      variant="standard"
                      size="small"

                      // label={width > 1400 ? "Shifts/Day" : "Sfts/Dy"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: { lg: '5rem', xl: '6rem' },
                        // width: { lg: '4.5vw', xl: '100%' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },

                      }}
                      // type="number"
                      disabled={headerData[0]?.lock_status}
                      value={dayValues}
                      onChange={(e: any) => handlechange(e, "days")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '14rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '14rem' }, backgroundColor: '#ddffe6', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Work Days/Year</Typography><Typography>:</Typography>
                    <TextField
                      // title="Work Days/Year"
                      variant="standard"
                      size="small"
                      // label={width > 1400 ? "Work Days/Year" : "W.Days/Yr"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: { lg: '4rem', xl: '5rem' },
                        // width: { lg: '5vw', xl: '100%' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      // disabled={headerData[0]?.lock_status}
                      value={WokringValues}
                      onChange={(e: any) => handlechange(e, "Working")}
                      onBlur={() => AutoSaveHandler()}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '14rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '14rem' }, backgroundColor: 'white', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Work Hours/Year</Typography><Typography>:</Typography>
                    <TextField
                      // title="Work Hours/Year"
                      variant="standard"
                      size="small"
                      disabled={true}
                      // label={width > 1400 ? "Work Hrs/Yr" : "Wrk Hrs/Yr"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: '5rem',
                        // width: { lg: '6vw', xl: '100%' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)!important',
                        },
                        '& .MuiFilledInput-input:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-input:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },

                      }}
                      // type="number"
                      value={headerData && headerData[0]?.yearly_workhours}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '16rem', lg: '11rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '16rem', lg: '11rem' }, backgroundColor: '#ddffe6', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Initial Investment (%)</Typography><Typography>:</Typography>
                    <TextField
                      // title="Initial Investment (%)"
                      variant="standard"
                      size="small"
                      disabled={headerData[0]?.lock_status}
                      // label={width > 1400 ? "Initial Investment (%)" : "Initial Investment (%)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{

                        width: '4rem',
                        // width: { lg: '7vw', xl: '7vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      value={InitialInvestment}
                      onChange={(e: any) => handlechange(e, "InitialInvestment")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '17rem', lg: '11rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '17rem', lg: '11rem' }, backgroundColor: '#ddffe6', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Power Cut (%)</Typography><Typography>:</Typography>
                    <TextField
                      // title="Power Cut (%)"
                      disabled={headerData[0]?.lock_status}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="filled-basic" variant="standard"
                      // label={width > 1400 ? "Power Cut (%)" : "Pwr Cut(%)"}
                      size="small"
                      sx={{
                        width: '4rem',
                        // width: { lg: '6vw', xl: '100%' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      value={PowerCut}
                      onChange={(e: any) => handlechange(e, "Cut")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: '3rem' }}>
                  {headerData && headerData[0]?.lock_status ?
                    <LoadingButton startIcon={<LockIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(headerData, false)}></LoadingButton>
                    : <LoadingButton startIcon={<LockOpenIcon sx={{ marginRight: '-1rem' }} />} title="Lock" sx={{ minWidth: '1rem', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(headerData, true)}></LoadingButton>
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '10rem', lg: '9rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '10rem', lg: '9rem' }, backgroundColor: 'primary.light', padding: { lg: '0.6rem 0.5rem', xl: '0.4rem 0.5rem' }, borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '70%' }, }}>Currency </Typography> <Typography>:</Typography><Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '1rem', width: '3rem' }}>{currencyCode ?? 'NA'}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '15rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '15rem' }, backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '55%' }, }}>Annual Land Rent  ({currencyCode ?? 'NA'}/m2)</Typography><Typography>:</Typography>
                    <TextField
                      // title="Annual Land Rent  (INR/m2) "
                      variant="standard"
                      size="small"
                      disabled={headerData[0]?.lock_status}
                      // label={width > 1400 ? "Annual Land Rent (INR/m2)" : "Annual Land Rent (INR/mm2)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: { lg: '5rem', xl: '6rem' },
                        // width: { lg: '5vw', xl: '7vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      value={Annualrent}
                      onChange={(e: any) => handlechange(e, "Annualrent")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '14rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '14rem' }, backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '55%' } }}>Government Power ({currencyCode ?? 'NA'}/KWh)</Typography><Typography>:</Typography>
                    <TextField
                      // title="Government Power Rate (INR/KWh)"
                      variant="standard"
                      size="small"

                      disabled={headerData[0]?.lock_status}
                      // label={width > 1400 ? "Govt. Pwr Rate (INR/KWh)" : "Govt. Pwr Rt(INR/KWh)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{

                        width: { lg: '4rem', xl: '5rem' },
                        // width: { lg: '8vw', xl: '8.5vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },

                      }}
                      // type="number"
                      value={PowerRate}
                      onChange={(e: any) => handlechange(e, "Rate")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '19rem', lg: '14rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '19rem', lg: '14rem' }, backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '55%' }, }}>Diesel Generator ({currencyCode ?? 'NA'}/KWh)</Typography><Typography>:</Typography>

                    <TextField
                      // title="Diesel Generator Rate (INR/KWh)"
                      variant="standard"
                      size="small"
                      disabled={headerData[0]?.lock_status}
                      // label={width > 1400 ? "Diesel Gen. Rate (INR/KWh)" : "Dsl Gen. Rt(INR/KWh)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: { lg: '4rem', xl: '5rem' },
                        // width: { lg: '7vw', xl: '9vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },

                      }}
                      // type="number"
                      value={DieselGen}
                      onChange={(e: any) => handlechange(e, "Diesel")}
                      onBlur={() => AutoSaveHandler()}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '16rem', lg: '12rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '16rem', lg: '12rem' }, backgroundColor: 'white', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Total Power ({currencyCode ?? 'NA'}/kWh)</Typography><Typography>:</Typography>

                    <TextField
                      // title="Total Power Rate (INR/kWh)"
                      variant="standard"
                      size="small"
                      disabled={true}
                      // label={width > 1400 ? "Total Pwr Rate (INR/kWh)" : "Ttl Pwr Rt(INR/kWh)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: '4rem',
                        // width: { lg: '8vw', xl: '8.5vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38)',
                        },
                        '& .MuiFilledInput-input:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-input:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                      }}
                      // type="number"
                      value={headerData && headerData[0]?.effective_power_cost}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: { xl: '17rem', lg: '11rem' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.4rem', minWidth: { xl: '17rem', lg: '11rem' }, backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem" }}>
                    <Typography sx={{ whiteSpace: { lg: 'normal', xl: 'nowrap' }, fontSize: '0.87rem', width: { xl: '80%', lg: '65%' }, }}>Bank Loan Lending (%) </Typography><Typography>:</Typography>
                    <TextField
                      // title="Bank Loan Lending Rate (%)"
                      variant="standard"
                      size="small"
                      // label={width > 1400 ? "Bank Loan Lending Rate (%)" : "Bnk Loan Ld Rt(%)"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: '4rem',
                        // width: { lg: '8vw', xl: '9vw' },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",

                        },
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          backgroundColor: 'primary.light',
                          fontSize: '1rem !important',
                        },
                        ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },
                        '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        },

                      }}
                      // type="number"
                      disabled={headerData[0]?.lock_status}
                      value={BankLoan}
                      onChange={(e: any) => handlechange(e, "loan")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "white", borderRight: '1px solid', textAlign: "left", width: '3rem' }}>
                  {useraction && useraction?.includes("U") && <LoadingButton
                    sx={{ minWidth: '1rem', color: statusColor ? "red" : "primary.main" }}
                    loading={Loader}
                    onClick={() => SaveHandler()}
                    startIcon={<SaveIcon sx={{ marginRight: '-1rem' }} />}
                  />}
                </TableCell>
              </TableRow>
            </Table>
          </Box>

          <Box sx={{ paddingRight: '0.5rem', }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', width: '100%', borderBottom: '1px solid', }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.2">
              {CategoryList &&
                CategoryList?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          color: '#007fff',
                          height: '2rem',
                          minHeight: '2.1rem',
                          maxWidth: '100% !important',
                          padding: '0',
                          fontSize: '1rem',
                          width: '100%',
                          backgroundColor:
                            item?.id == selectedTab
                              ? 'primary.main'
                              : 'primary.light',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Typography
                          sx={{
                            color:
                              item?.id == selectedTab ? 'white' : '#007fff',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0 0.5rem',
                            fontSize: '1rem',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => AccordianhandleChange(item?.id)}>
                          {item?.category.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                    </>
                  );
                })}
            </Box> */}

            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: 'flex-start',
              margin: "0.3rem",
              columnGap: "0.5rem",
              height: '2rem',
            }}>
              {useraction && useraction?.includes("C") && (
                <>
                  <Box sx={{ width: '12rem' }}
                    onClick={(e: any) => AddmachineHanlder(e)}>
                    <Button size="small" sx={{
                      padding: '0.2rem 1rem', zIndex: 1, '&:hover': {
                        backgroundColor: "#007fff", color: "#fff",
                      }
                    }} startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}>
                      Fetch Machine
                    </Button>
                  </Box>
                  <Box sx={{ width: '18rem' }}
                    onClick={(e: any) => AddAndFetchMachineHandler(e)}>
                    <Button size="small" sx={{
                      padding: '0.2rem 1rem', zIndex: 1, '&:hover': {
                        backgroundColor: "#007fff", color: "#fff",
                      }
                    }} startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}>
                      Add & Fetch Machine
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                height: { lg: '69vh', xl: '76vh' },
                width: "100%",
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                  textAlign: "center !important",
                },
              }}>
              {Rows() && Rows() && (
                <DataGrid
                  loading={tableLoader}
                  headerHeight={42}
                  rowHeight={36}
                  rows={Rows()}
                  columns={columns}
                  disableSelectionOnClick={true}
                  components={{
                    Toolbar: () => {
                      return CustomToolbar();
                    },
                  }}
                  disableColumnSelector={true}
                  sx={{
                    "& ::-webkit-scrollbar": {
                      width: "0.4rem",
                      height: "0.4rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      // background: $thumb-color;
                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      // width: "4px",
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                    "&.MuiDataGrid-root": {
                      border: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                />
              )}
            </Box>
            {CategoryList && CategoryList.length == 0 && <>
              {useraction && useraction?.includes("C") && (
                <Box sx={{
                  textAlign: 'center', display: "flex", flexDirection: "row",
                  justifyContent: "end"
                }}
                  onClick={(e: any) => AddmachineHanlder(e)}>
                  <Button size="small" startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}>
                    Fetch Machine
                  </Button>
                </Box>
              )}
            </>
            }
          </Box>

        </>
      )}
    </Box>
  );
}

