import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  FormControl,
  MenuItem,
  Typography,
  Select,
  useTheme,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./ScenarioHome.module.scss";
import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import gotoArrow from '../../../Assets/images/rightArrows.svg';

type Anchor = "top" | "left" | "bottom" | "right";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name: string, personName: any, theme: Theme) {
//   return {
//     fontWeight:
//       personName?.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export interface IScenarioHomeProps { }

export default function ScenarioHome(props: IScenarioHomeProps) {
  const theme = useTheme();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { vault, topVault, projectId, treeabbr } = useRouteParams<any>();
  const [productLevelScenario, setProductLevelScenario] = React.useState<any>(
    {}
  );
  const [topVaultState, setTopVaultState] = React.useState<any>(topVault);
  const [productLevelGraphData, setProductLevelGraphData] = React.useState<any>(
    []
  );
  const [selectedProject, setSelectedProject] = useState<any>([projectId]);
  const [selectedDummyProducts, setSelectedDummyProducts] = useState<any>([]);
  const [checkedScenarioType, setCheckScenarioType] = React.useState<any>([1]);
  const [subSystemList, setSubSystemList] = React.useState<any>([]);
  const [subSystemListGraph, setSubSystemListGraph] = React.useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>([topVault]);
  const [subSystemListPart, setSubSystemListPart] = useState<any>([]);
  const [selectedSubSystem, setSelectSubSystem] = React.useState<any>(
    sessionStorage.getItem("system") || parseInt(vault)
  );
  const [selectedSubSystemPart, setSelectSubSystemPart] = React.useState<any>(
    sessionStorage.getItem("partsystem") || vault
  );
  const [systemData, setSystemData] = React.useState<any>({});
  const [selectedPart, setSelectedPart] = React.useState<any>(
    sessionStorage.getItem("part")
  );
  const [partlevelData, setPartLevelData] = React.useState<any>({});
  const [partlevelDataGraph, setPartLevelDataGraph] = React.useState<any>([]);
  const [partList, setPartList] = React.useState<any>([]);
  const [productAvailable, setProductAvailable] = useState<any>([]);
  const [isFetchingPartOptions, setIsFetchingPartOptions] =
    React.useState<any>(false);

  const [projectList, setProjectList] = useState<any>([]);
  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "Costing",top_vault:topVault },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency", res.data[0]?.current_currency);
      url?.includes("analysis")
        ? sessionStorage.setItem(
          "actionkey_analysis",
          res.data[0]?.is_manual ? "mannual" : "auto"
        )
        : sessionStorage.setItem(
          "actionKey",
          res.data[0]?.is_manual ? "mannual" : "auto"
        );
      if (res.data[0]?.is_manual) {
        url?.includes("analysis")
          ? sessionStorage.setItem(
            "currencyrate_analysis_mannual",
            res.data[0]?.conversion_factor
          )
          : sessionStorage.setItem(
            "currencyrate_mannual",
            res.data[0]?.conversion_factor
          );
      } else {
        url?.includes("analysis")
          ? sessionStorage.setItem(
            "currencyrate_analysis",
            (1 / res.data[0]?.conversion_factor).toString()
          )
          : sessionStorage.setItem(
            "currencyrate",
            (1 / res.data[0]?.conversion_factor).toString()
          );
      }
    });
  }, []);

  useEffect(() => {
    API.get(
      `/customer/dashboard/`,
      {
        get_module_project: "Costing",
      },
      0
    ).then((res: any) => {
      setProjectList(res?.data);
    });
  }, []);

  const scenarioSelectionArr: any = [
    { id: 1, name: "Product Costing Scenario" },
    { id: 2, name: "System Costing Scenario" },
    { id: 3, name: "Part Costing Scenario" },
  ];
  useEffect(() => {
    var prevstatee = 0;
    setTopVaultState((prev: any) => prev);
    if (topVaultState !== topVault) {
      sessionStorage.removeItem("system");
      sessionStorage.removeItem("partsystem");
      sessionStorage.removeItem("part");
      setSystemData({});
      setPartLevelData([]);
      setSubSystemList([]);
      setSubSystemListPart([]);
      setPartList([]);
      setSubSystemListGraph([]);
      setSelectSubSystem(undefined);
      setSelectSubSystemPart(undefined);
      setSelectedPart(undefined);
      setPartLevelDataGraph([]);
    }
  }, [topVault]);

  React.useEffect(() => {
    if (sessionStorage.getItem("system") || vault) {
      API.get(
        "/cost/scenario/",
        {
          //@ts-ignore
          system: parseInt(sessionStorage.getItem("system")) || parseInt(vault),
          type: 2,
        },
        0
      )
        .then((res: any) => {
          setSystemData(res.data);
          setSubSystemListGraph(res.data.data);
        })
        .catch((err: any) => {
          setSystemData({});
        });
    }
    if (sessionStorage.getItem("partsystem")) {
      setIsFetchingPartOptions(true);
      API.get(
        "/cost/cost_subsystem/",
        {
          //@ts-ignore
          sub_system: parseInt(sessionStorage.getItem("partsystem")),
          type: 4,
        },
        0
      )
        .then((res: any) => {
          setPartList(res.data);
          setIsFetchingPartOptions(false);
        })
        .catch((err: any) => {
          setPartList([]);
          setIsFetchingPartOptions(false);
        });
    }
    if (sessionStorage.getItem("part")) {

      API.get(
        "/cost/cost_subsystem/",
        {
          //@ts-ignore
          sub_system: parseInt(sessionStorage.getItem("part")),
          type: 4,
        },
        0
      )
        .then((res: any) => {
          setPartList(res.data);
          setIsFetchingPartOptions(false);
        })
        .catch((err: any) => {
          setPartList([]);
          setIsFetchingPartOptions(false);
        });
    }
    if (sessionStorage.getItem("part") && sessionStorage.getItem("partsystem"))
      API.get("/cost/scenario/", { part: sessionStorage.getItem("part") }, 0)
        .then((res: any) => {
          setPartLevelData(res.data);
          setPartLevelDataGraph(res.data.data);
        })
        .catch((err: any) => {
          setPartLevelData({});
        });
  }, [
    sessionStorage.getItem("system"),
    sessionStorage.getItem("partsystem"),
    sessionStorage.getItem("part"),
    topVault,
  ]);

  const selectScenarioType = (idd: any) => {
    if (idd !== 1) {
      if (checkedScenarioType.includes(idd)) {
        setCheckScenarioType((prev: any) =>
          prev.filter((itm: any) => itm !== idd)
        );
      } else {
        setCheckScenarioType((prev: any) => [...prev, idd]);
      }
    }
  };
  const selectAllProject = (productIds: any) => {
    if (productIds == projectId) {
      setSelectedDummyProducts([topVault]);
    } else {
      setSelectedDummyProducts([]);
    }
    setSelectedProject([productIds]);
    API.get(
      `/customer/dashboard/`,
      {
        get_costing_topvault: true,
        project: productIds,
      },
      0
    ).then((res: any) => {
      setProductAvailable(res?.data);
    });
  };

  useEffect(() => {
    selectAllProject(projectId);
  }, [projectId]);

  const handleChangePart = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectSubSystemPart(value);
    sessionStorage.setItem("partsystem", value);
    if (value) {
      setIsFetchingPartOptions(true);

      API.get(
        "/cost/cost_subsystem/",
        {
          sub_system: parseInt(value),
          type: 4,
        },
        0
      )
        .then((res: any) => {
          setPartList(res.data);
          setIsFetchingPartOptions(false);
        })
        .catch((err: any) => {
          setPartList([]);
          setIsFetchingPartOptions(false);
        });
    }
  };
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectSubSystem(value);
    sessionStorage.setItem("system", value);
    API.get(
      "/cost/scenario/",
      {
        system: parseInt(value),
        type: 2,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res.data);
      })
      .catch((err: any) => {
        setSystemData({});
      });
  };
  const handleChangePartList = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedPart(value);
    sessionStorage.setItem("part", value);
    if (value) {
      API.get("/cost/scenario/", { part: value }, 0)
        .then((res: any) => {
          setPartLevelData(res.data);
        })
        .catch((err: any) => {
          setPartLevelData({});
        });
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const getSubSystem = () => {

    setSubSystemList([]);
    setSubSystemListPart([]);
    API.get(
      "/cost/cost_subsystem/",
      {
        top_vault: topVault,
        type: 2,
      },
      0
    )
      .then((res: any) => {
        setSubSystemList(res.data);
      })
      .catch((err: any) => {
        setSubSystemList([]);
      });
    API.get(
      "/cost/cost_subsystem/",
      {
        top_vault: topVault,
        type: 3,
      },
      0
    )
      .then((res: any) => {
        setSubSystemListPart(res.data);
      })
      .catch((err: any) => {
        setSubSystemListPart([]);
      });
  };

  const buttonSX = {
    backgroundColor: "primary.main",
    color: "primary.light",
    width: "8rem",
    margin: "auto auto",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.light",
      width: "8rem",
      margin: "auto auto",
    },
  };
  const getselectedBackgroundColor = (idd: any) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const RoutetoCosting = (item: any) => {
    history.push(
      `/view/${projectId}/${item?.top_vault}/${item?.vault}/${item?.abbreviation}/costing/scenario/${item?.id}`
    );
  };

  const productLevelRoute = (item: any) => {
    history.push(
      `/view/${projectId}/${item?.top_vault}/${item.vault}/null/costing/scenario/${item?.id}`
    );
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const selectAllProduct = (productIds: any) => {
    setSelectedDummyProducts([productIds]);
    setSelectedProducts([productIds]);
  };

  const handleChangeScenario = (anchor: any) => {
    // history.push(
    //   `/idea_dashboard/${selectedProject[0]}/${selectedProducts[0]}`
    // );
  };

  useEffect(() => {
    getSubSystem();
  }, [projectId, topVault, vault]);
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <Typography
            className="label"
            style={{
              fontSize: "0.75rem",
              background: "white",
              padding: "1rem",
              color: "black",
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
            }}
          >
            <Box
              sx={{
                width: "0.75rem",
                height: "0.75rem",
                background: `${payload[0]?.payload?.color}`,
              }}
            ></Box>
            {`${label} : ${payload[0].value} ${sessionStorage.getItem(
              "currency"
            )}`}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const getProductLevelScenario = () => {
    API.get("/cost/scenario/", { top_vault: topVault })
      .then((res: any) => {
        setProductLevelScenario(res.data);
        let tempArr = res.data.data;
        // tempArr.push({id:0,name:res.data?.product_name,cost:res.data?.main_cost,color:"#007fff"})
        setProductLevelGraphData(res.data.data);
      })
      .catch((err: any) => {
        setProductLevelScenario({});
      });
  };

  const CustomizedWeightLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };

  React.useEffect(() => {
    getProductLevelScenario();
  }, [topVault]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        if (!open) {
          history.push(
            `/view/${selectedProject[0]}/${selectedProducts[0]}/${vault}/${treeabbr}/costing/scenario`
          );
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <List>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Project Name
              </Typography>
              {projectList &&
                projectList?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          checked={
                            selectedProject?.includes(item.proj_id)
                              ? true
                              : false
                          }
                          onClick={() => selectAllProject(item?.proj_id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                        <Box
                          key={index}
                          onClick={() => selectAllProject(item?.proj_id)}
                          className={styles.selectVehBtn}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.proj_id
                            ),
                            color: getSelectedProjectTextColor(item.proj_id),
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                            // marginBottom: '1rem'
                          }}
                        >
                          <Typography>{item.proj_name}</Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </div>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Product Name
              </Typography>
              {productAvailable &&
                productAvailable?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          checked={
                            selectedProducts?.includes(item?.topvault_id)
                              ? true
                              : false
                          }
                          onClick={() => selectAllProduct(item?.topvault_id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                        <Box
                          key={index}
                          onClick={() => selectAllProduct(item?.topvault_id)}
                          className={styles.selectVehBtn}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedBackgroundColor(
                              item.topvault_id
                            ),
                            color: getSelectedTextColor(item.topvault_id),
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                            // marginBottom: '1rem'
                          }}
                        >
                          <Typography>{item?.topvault_name}</Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </div>
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                className={styles.drawBtn}
                sx={{ marginRight: "1rem" }}
                variant="contained"
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Close
              </Button>
              <Button
                className={styles.drawBtn}
                size="small"
                onClick={() => handleChangeScenario(anchor)}
                disabled={
                  selectedProducts?.length < 1 ||
                  selectedProducts?.length > 1 ||
                  selectedDummyProducts?.length == 0
                }
              >
                <Button
                  className={styles.drawBtn}
                  variant="contained"
                  size="small"
                  onClick={toggleDrawer(anchor, false)}
                >
                  {" "}
                  Ok
                </Button>
              </Button>
            </div>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      <Box>
        <Box sx={{ width: "3rem", padding: "0 0.5rem" }}>
          <IconButton
            sx={{ cursor: "pointer" }}
          // onClick={backButtonHandler}
          >
            <ArrowBackIcon
              color="primary"
              titleAccess="Go Back"
              onClick={() =>
                history.push(
                  `/view/${projectId}/${topVault}/${vault}/${treeabbr}/costing`
                )
              }
            />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", height: "89vh" }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Select Project & Product
                      </Typography>
                      <Divider sx={{ margin: "1rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={styles.sideTitle}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Select Project & Product
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              columnGap: "10px",
              padding: "0 1rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: { lg: '87vh', xl: '89vh' },
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "primary.light",
                  height: "60vh",
                  borderTopRightRadius: "10px", borderTopLeftRadius: '10px',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.2rem 1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem" }}>
                    Product Costing Scenario
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 0.5fr 0.4fr",
                    backgroundColor: "primary.main",
                    alignItems: "center",
                    height: "2.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      padding: "0 0.5rem",
                    }}
                  >
                    {productLevelScenario && productLevelScenario?.product_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "right",
                    }}
                  >
                    {productLevelScenario && productLevelScenario?.main_cost}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </Typography>
                </Box>
                {productLevelScenario &&
                  productLevelScenario?.data?.map((item: any) => {
                    return (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "2fr 0.5fr 0.4fr",
                          alignItems: "center",
                          backgroundColor: "#007fff14",
                          marginBottom: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "primary.main",
                            padding: "0 0.5rem",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "1rem",
                              height: "1rem",
                              backgroundColor: item.color,
                            }}
                          ></Box>
                          {item && item?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "right",
                          }}
                        >
                          {item && item?.cost}
                        </Typography>
                        <Box
                          sx={{
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "center",
                          }}
                        >
                          <img src={gotoArrow} className={styles.gotoIcon} style={{ width: '20px',height:'22px', cursor: "pointer", }}
                            title="GO to Costing"
                            onClick={() =>
                              history.push(
                                `/view/${projectId}/${item?.top_vault
                                }/${0}/product/costing/scenario/${item?.id}`
                              )
                            }
                          // onClick={() => BomRouteHandler()}
                          />
                          {/* <MoreVertIcon
                            titleAccess="Details"
                            style={{
                              fontSize: "1.5rem",
                            }}
                          /> */}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box sx={{ width: '100%', height: '35%' }}>
                <ResponsiveContainer>
                  <BarChart
                    data={
                      productLevelGraphData && [
                        ...[
                          {
                            id: productLevelGraphData?.length + 1,
                            name: productLevelScenario?.product_name,
                            cost: productLevelScenario?.main_cost,
                            color: "#55B4B0",
                          },
                        ],
                        ...productLevelGraphData,
                      ]
                    }
                    margin={{
                      top: 25,
                      right: 20,
                      left: 30,
                      bottom: 20,
                    }}
                  >
                    {/* <Bar
                        dataKey="Cost"
                        barSize={100}
                        // fill="rgb(144, 238, 144)"
                        // style={{ overflow: "visible" }}
                        >
                      </Bar> */}

                    <XAxis
                      dataKey="name"
                      // angle={-90}
                      textAnchor="middle"
                      fontSize="0.75rem"
                      stroke="#000000"
                      interval={0}
                    />
                    <YAxis fontSize="0.75rem" stroke="#000000">
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "0.75rem",
                          fontWeight: "500",
                          fill: "#000000",
                        }}
                        angle={270}
                        dx={-40}
                      //value={ParameterNames}
                      />
                    </YAxis>
                    <Tooltip
                      cursor={{ fill: "white" }}
                      // @ts-ignore
                      content={<CustomTooltip />}
                    />
                    <Bar
                      barSize={40}
                      isAnimationActive={true}
                      dataKey="cost"
                      stackId="a"
                      fill="#b8d8be"
                    >
                      {productLevelGraphData &&
                        [
                          ...[
                            {
                              id: productLevelGraphData?.length + 1,
                              name: productLevelScenario?.product_name,
                              cost: productLevelScenario?.main_cost,
                              color: "#55B4B0",
                            },
                          ],
                          ...productLevelGraphData,
                        ]?.map((entry: any) => <Cell fill={entry?.color} />)}
                      {<LabelList dataKey="cost" position="top" fill="black" />}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
            <Box
              sx={{
                height: { lg: '87vh', xl: '89vh' },
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "primary.light",
                  height: "60vh",
                  borderTopRightRadius: "10px", borderTopLeftRadius: '10px',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.2rem 1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem" }}>
                    System Costing Scenario
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 0.5fr 0.4fr",
                    backgroundColor: "primary.main",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      minWidth: 150,
                      maxWidth: 270,
                      margin: "0.2rem 0.5rem",
                    }}
                  >
                    {/* <InputLabel id="demo-simple-select-standard-label">
                      system
                    </InputLabel> */}
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // multiple
                      sx={{
                        color: "white",
                        ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                          fontSize: "1rem",
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": {
                          color: "white",
                        },
                        "&:before": {
                          borderBottomColor: "white",
                        },
                      }}
                      size="small"
                      value={subSystemList && selectedSubSystem}
                      onChange={handleChange}
                      // input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {subSystemList &&
                        subSystemList.map((item: any) => (
                          <MenuItem
                            key={item.name}
                            value={item.id}
                            sx={{
                              color: item?.status === 0 ? "blue" : "green",
                              width: "auto",
                              fontSize: "1rem",
                              padding: "0.2rem 0.5rem",
                            }}
                          // style={getStyles(item.name, selectedSubSystem, theme)}
                          >
                            {`${item.name} (${item.status})`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Typography
                    style={{
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "right",
                    }}
                  >
                    {systemData && systemData?.main_cost}
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </Typography>
                </Box>
                {systemData &&
                  systemData?.data?.map((item: any) => {
                    return (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "2fr 0.5fr 0.4fr",
                          alignItems: "center",
                          backgroundColor: "#007fff14",
                          marginBottom: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "primary.main",
                            padding: "0 0.5rem",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "1rem",
                              height: "1rem",
                              backgroundColor: item?.color,
                            }}
                          ></Box>
                          {item && item?.name}
                        </Typography>
                        <Typography
                          style={{
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "right",
                          }}
                        >
                          {item && item?.cost}
                        </Typography>
                        <Box
                          sx={{
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "center",
                          }}
                        >
                          <img src={gotoArrow} className={styles.gotoIcon} style={{ width: '20px',height:'22px', cursor: "pointer", }}
                            title="GO to Costing"
                            onClick={() => RoutetoCosting(item)}
                          // onClick={() => BomRouteHandler()}
                          />
                          {/* <MoreVertIcon
                            titleAccess="Details"
                            style={{
                              fontSize: "1.5rem",
                            }}
                          /> */}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box sx={{ width: '100%', height: '35%' }}>
                <ResponsiveContainer>
                  <BarChart
                    data={
                      systemData && [
                        ...[
                          {
                            id: subSystemListGraph?.length + 1,
                            name: productLevelScenario?.product_name,
                            cost: systemData?.main_cost,
                            color: "#55B4B0",
                          },
                        ],
                        ...subSystemListGraph,
                      ]
                    }
                    margin={{
                      top: 25,
                      right: 20,
                      left: 30,
                      bottom: 20,
                    }}
                  >
                    {/* <Bar
                        dataKey="Cost"
                        barSize={100}
                        // fill="rgb(144, 238, 144)"
                        // style={{ overflow: "visible" }}
                        >
                      </Bar> */}

                    <XAxis
                      dataKey="name"
                      // angle={-90}
                      textAnchor="middle"
                      fontSize="0.75rem"
                      stroke="#000000"
                      interval={0}
                    />
                    <YAxis fontSize="0.75rem" stroke="#000000">
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "0.75rem",
                          fontWeight: "500",
                          fill: "#000000",
                        }}
                        angle={270}
                        dx={-40}
                      //value={ParameterNames}
                      />
                    </YAxis>
                    <Tooltip
                      cursor={{ fill: "white" }}
                      // @ts-ignore
                      content={<CustomTooltip />}
                    />
                    <Bar
                      barSize={40}
                      isAnimationActive={true}
                      dataKey="cost"
                      stackId="a"
                      fill="#b8d8be"
                    >
                      {systemData &&
                        [
                          ...[
                            {
                              id: subSystemListGraph?.length + 1,
                              name: productLevelScenario?.product_name,
                              cost: systemData?.main_cost,
                              color: "#55B4B0",
                            },
                          ],
                          ...subSystemListGraph,
                        ]?.map((entry: any) => <Cell fill={entry?.color} />)}
                      {<LabelList dataKey="cost" position="top" fill="black" />}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
            <Box
              sx={{
                height: { lg: '87vh', xl: '89vh' },
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "primary.light",
                  height: "60vh",
                  borderTopRightRadius: "10px", borderTopLeftRadius: '10px',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.2rem 1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem" }}>
                    Part Costing Scenario
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2.2fr 0.5fr 0.4fr",
                    backgroundColor: "primary.main",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControl
                      variant="standard"
                      sx={{
                        m: 1,
                        minWidth: { lg: 120, xl: 160 },
                        maxWidth: { lg: 120, xl: 200 },
                        margin: "0.2rem 0.5rem",
                        ".MuiInputBase-root.MuiInput-root-MuiSelect-root:before":
                        {
                          borderBottomColor: "white",
                        },
                      }}
                    >
                      {/* <InputLabel id="demo-simple-select-standard-label">
                        system
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        // multiple

                        sx={{
                          color: "white",
                          ".MuiSelect-select.MuiInputBase-input.MuiInput-input":
                          {
                            fontSize: "1rem",
                            color: "white",
                          },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "white",
                          },
                          "&:before": {
                            borderBottomColor: "white",
                          },
                        }}
                        placeholder="System"
                        value={selectedSubSystemPart}
                        size="small"
                        onChange={handleChangePart}
                        // input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {subSystemListPart &&
                          subSystemListPart.map((item: any) => (
                            <MenuItem
                              key={item.name}
                              value={item.id}
                              sx={{
                                color: item?.status === 0 ? "blue" : "green",
                                fontSize: "1rem",
                                padding: "0.2rem 0.5rem",
                              }}
                            // style={getStyles(item.name, selectedSubSystem, theme)}
                            >
                              {`${item.name} (${item.status})`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{
                        m: 1,
                        minWidth: { lg: 120, xl: 200 },
                        maxWidth: { lg: 120, xl: 200 },
                        margin: "0.2rem 0.5rem",
                        ".MuiInputBase-root.MuiInput-root-MuiSelect-root:before":
                        {
                          borderBottomColor: "white",
                        },
                      }}
                    >
                      {/* <InputLabel id="demo-simple-select-standard-label">
                        system
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        // multiple
                        sx={{
                          color: "white",
                          ".MuiSelect-select.MuiInputBase-input.MuiInput-input":
                          {
                            fontSize: "1rem",
                          },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "white",
                          },
                          "&:before": {
                            borderBottomColor: "white",
                          },
                        }}
                        placeholder="System"
                        value={selectedPart}
                        size="small"
                        onChange={handleChangePartList}
                        // input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                        disabled={isFetchingPartOptions}
                      >
                        {partList &&
                          partList.map((item: any) => (
                            <MenuItem
                              key={item.name}
                              value={item.id}
                              sx={{
                                color: item?.status === 0 ? "blue" : "green",
                                fontSize: "1rem",
                                padding: "0.2rem 0.5rem",
                              }}
                            // style={getStyles(item.name, selectedSubSystem, theme)}
                            >
                              {`${item.name} (${item.status})`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      padding: "0 0.5rem",
                      textAlign: "right",
                    }}
                  >
                    {partlevelData && partlevelData?.main_cost}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "white",
                      padding: "0 0.5rem",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </Typography>
                </Box>
                {partlevelData &&
                  partlevelData?.data?.map((item: any) => {
                    return (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "2.2fr 0.5fr 0.4fr",
                          backgroundColor: "#007fff14",
                          marginBottom: "2px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "primary.main",
                            padding: "0 0.5rem",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "1rem",
                              height: "1rem",
                              backgroundColor: item?.color,
                            }}
                          ></Box>

                          {item && item?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "right",
                          }}
                        >
                          {item && item?.cost}
                        </Typography>
                        <Box
                          sx={{
                            color: "primary.main",
                            padding: "0 0.5rem",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <img src={gotoArrow} className={styles.gotoIcon} style={{ width: '20px',height:'22px', cursor: "pointer", }}
                            title="GO to Costing"
                            onClick={() => productLevelRoute(item)}
                          // onClick={() => BomRouteHandler()}
                          />
                          {/* <MoreVertIcon
                            titleAccess="Details"
                            style={{
                              fontSize: "1.5rem",
                            }}
                          /> */}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box sx={{ width: '100%', height: '35%' }}>
                <ResponsiveContainer>
                  <BarChart
                    data={
                      partlevelData && [
                        ...[
                          {
                            id: partlevelDataGraph?.length + 1,
                            name: productLevelScenario?.product_name,
                            cost: partlevelData?.main_cost,
                            color: "#55B4B0",
                          },
                        ],
                        ...partlevelDataGraph,
                      ]
                    }
                    margin={{
                      top: 25,
                      right: 20,
                      left: 30,
                      bottom: 20,
                    }}
                  >
                    {/* <Bar
                        dataKey="Cost"
                        barSize={100}
                        // fill="rgb(144, 238, 144)"
                        // style={{ overflow: "visible" }}
                        >
                      </Bar> */}

                    <XAxis
                      dataKey="name"
                      // angle={-90}
                      textAnchor="middle"
                      fontSize="0.75rem"
                      stroke="#000000"
                      interval={0}
                    />
                    <YAxis fontSize="0.75rem" stroke="#000000">
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "0.75rem",
                          fontWeight: "500",
                          fill: "#000000",
                        }}
                        angle={270}
                        dx={-40}
                      //value={ParameterNames}
                      />
                    </YAxis>
                    <Tooltip
                      cursor={{ fill: "white" }}
                      // @ts-ignore
                      content={<CustomTooltip />}
                    />
                    <Bar
                      barSize={40}
                      isAnimationActive={true}
                      dataKey="cost"
                      stackId="a"
                      fill="#b8d8be"
                    // label={ <CustomizedWeightLabel /> }                 
                    >
                      {partlevelData &&
                        [
                          ...[
                            {
                              id: partlevelDataGraph?.length + 1,
                              name: productLevelScenario?.product_name,
                              cost: partlevelData?.main_cost,
                              color: "#55B4B0",
                            },
                          ],
                          ...partlevelDataGraph,
                        ]?.map((entry: any) => <Cell fill={entry?.color} />)}
                      {<LabelList dataKey="cost" position="top" fill="black" />}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
