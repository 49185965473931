import {
  Box,
  debounce,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from "@mui/icons-material/Edit";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import ScenarioCalculatorModel from "./ScenarioCalculatorModel";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { get } from "lodash";

function ScenarioItem({
  index,
  color,
  item,
  HierarchyAllData,
  setScenarioCount,
  getScenarioTreeData,
  calculatorData,
  setCalculatorData,
  TableData,
  decimalPoints,
  highlightedCellIndex,
  toggleHighlight,
  userProfile,
  reDirectToMainCosting
}: any) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,
  } = useRouteParams<any>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState<any>(null);
  const [initValue, setInitValue] = React.useState<any>(null);
  const [oldValue, setOldValue] = React.useState<any>("");
  const [costDetailsDataLoading, setCostDetailsDataLoading] =
    React.useState<any>(false);
  const [costDetailsData, setCostDetailsData] = React.useState<any>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [scenario, setScenario] = useState<any>(null)
  const openCalculator = useBaseParamsModal();
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setValue(value);
  };
  const updateDataVaultParams = async (val: any) => {
    if (oldValue != val) {
      setLoading(true);
      API.put(
        `/cost/scenario_hierarchy_data/`,
        {
          edited_value: Number(val),
          vault_id: vault,
          edit_scenario_value: true,
          type: HierarchyAllData?.type,
          est_id: item?.est_id,
          parent:
            HierarchyAllData?.type == "material"
              ? HierarchyAllData?.parent
              : null,
          id:
            HierarchyAllData?.type == "sub_calculator_cat"
              ? HierarchyAllData?.id
              : null,
        },
        {},
        0
      )
        .then((res: any) => {
          setTimeout(() => {
            setLoading(false);
            setOldValue(value);
            ADMIN.toast.info("Value updated successfully");
            if (HierarchyAllData?.type == "material") {
              getScenarioTreeData("material", "update");
              getScenarioTreeData("header", "update");
            } else if (HierarchyAllData?.type == "sub_calculator_cat") {
              getScenarioTreeData("process", "update");
              getScenarioTreeData("header", "update");
            } else if (HierarchyAllData?.type == "overhead") {
              getScenarioTreeData("overhead", "update");
              getScenarioTreeData("header", "update");
            }
          }, 400);

        })
        .catch((err: any) => {
          setLoading(false);
          const { data } = err.response;
          if (err.response && data) {
            Swal.fire({
              title: "Error!",
              text: data[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        }).finally(() => {
          setTimeout(() => {
            setEditMode(false);
          }, 400);

        });
    }
  };

  const ExactDebounceParameters = React.useRef(
    debounce(async (val: any) => {
      await updateDataVaultParams(val);
    }, 1000)
  ).current;

  const handleOpenCalculator = (with_link: any) => {
    if (TableDataSliced?.[index]?.status == true || TableDataSliced?.[index]?.status == null || TableDataSliced?.[index]?.status == undefined) {
      openCalculator.open(with_link);
    }
  };

  const handleEditToggle = (item: any) => { };

  const handleRevertEdit = () => {
    setLoading(true);
    API.put(
      `/cost/scenario_hierarchy_data/`,
      {
        remove_edited_value: true,
        vault_id: vault,
        edit_scenario_value: true,
        type: HierarchyAllData?.type,
        est_id: item?.est_id,
        parent:
          HierarchyAllData?.type == "material"
            ? HierarchyAllData?.parent
            : null,
        id:
          HierarchyAllData?.type == "sub_calculator_cat"
            ? HierarchyAllData?.id
            : null,
      },
      {},
      0
    )
      .then((res: any) => {
        setTimeout(() => {
          setLoading(false);

          setOldValue(value);
          ADMIN.toast.info("Cleared updated value successfully");
          if (HierarchyAllData?.type == "material") {
            getScenarioTreeData("material", "update");
            getScenarioTreeData("header", "update");

          } else if (HierarchyAllData?.type == "sub_calculator_cat") {
            getScenarioTreeData("process", "update");
            getScenarioTreeData("header", "update");
          } else if (HierarchyAllData?.type == "overhead") {
            getScenarioTreeData("overhead", "update");
            getScenarioTreeData("header", "update");
          }
        }, 400);

      })
      .catch((err: any) => {
        setLoading(false);
        const { data } = err.response;
        if (err.response && data) {
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }).finally(() => {
        setTimeout(() => {
          setEditMode(false);
        }, 400);

      });
  }

  useEffect(() => {
    if (typeof item?.scenario_cost === "string") {
      setValue(0);
      setOldValue(0);
      setInitValue(0);
    } else {
      setValue(item?.scenario_cost);
      setOldValue(item?.scenario_cost);
      setInitValue(item?.scenario_cost);
    }
  }, [item]);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getCostDetailsData = () => {
    setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenario ? scenario?.id : undefined,
      },
      0
    )
      .then((res: any) => {
        setCostDetailsData(res.data[0]);
        setSelectedCategory(res.data[0]?.costing_category);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        if (err?.response && err?.response?.data) {
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${err?.response?.data[0]}</p>   
           </div>`,
          });
        }
        setCostDetailsDataLoading(false);
      });
  };





  useEffect(() => {
    if (TableData?.length > 1) {
      setScenario(TableData?.[index + 1])
    }
  }, [item])

  const boxRef:any = useRef(null);
  var random: any = Math.floor(Math.random() * 1000000 + 1);

  const getPercentageTitle = (percentage_contribution: any) => {
    if (percentage_contribution > 0) {
      return `${percentage_contribution.toFixed(decimalPoints)}%`
    }
    return ''
  }

  const getValueTitle = (scenario_cost: any) => {
    if (scenario_cost > 0) {
      return `${item?.scenario_cost.toFixed(decimalPoints)}%`
    }
    return ''
  }
  const TableDataSliced = TableData?.slice(1) || [];

    // Effect to listen for clicks outside the Box
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      // console.log(value, "hiir");
      
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        
        setTimeout(() => {
          setEditMode(false);
        }, 1400);
      }
      
      
     
    };

    // Add event listener for clicks on the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <>
      {openCalculator.isOpen && (
        <ScenarioCalculatorModel
          isOpen={openCalculator.isOpen}
          onCloseModal={openCalculator.close}
          calculatorData={calculatorData}
          costDetailsData={costDetailsData}
          HierarchyAllData={HierarchyAllData}
          scenario={scenario}
          vault={vault}
          topVault={topVault}
          getCostDetailsData={getCostDetailsData}
          costEstimationId={item?.est_id}
          parentId={item?.parent_id}
          revision_id={item?.revision}
          assumption={item?.assumption}
          getScenarioTreeData={getScenarioTreeData}
          category_type={item?.category_type}
          decimalPoints={decimalPoints}
          PermissionDataa={TableDataSliced?.[index]}
          userProfile={userProfile}
          reDirectToMainCosting={reDirectToMainCosting}
          with_link={openCalculator.propsId}

        />
      )}
      <Box
        key={index}
        
        ref={boxRef}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.5rem",
          padding: "0.2rem",
          borderLeft: "1.75px solid",
          borderColor: item?.color ? item?.color : "inherit",
          height: "2rem",
          width: "100%",
        }}
      >
        {(HierarchyAllData?.type == "overhead" ||
          HierarchyAllData?.type == "material" ||
          HierarchyAllData?.type == "sub_calculator_cat") ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              padding: "0",
            }}
          >
            <>
              {TableDataSliced?.[index]?.action?.includes("U") && <IconButton onClick={() => handleEditToggle(index)} size="small" sx={{ padding: "3px" }}>
                <EditIcon
                  titleAccess="Edit value"
                  sx={{ fontSize: "1rem", color: TableDataSliced?.[index]?.status == true ? "primary.main" : TableDataSliced?.[index]?.status == false ? "grey" : "primary.main", cursor: TableDataSliced?.[index]?.status == true ? "pointer" : TableDataSliced?.[index]?.status == false ? "default !important" : "pointer", }}
                  onClick={() => {
                    if (TableDataSliced?.[index]?.status == true) {
                      setEditMode(true)
                    } else if (TableDataSliced?.[index]?.status == false) {

                    } else {
                      setEditMode(true)
                    }
                  }}

                />
              </IconButton>}
              {item?.value_is_edited && TableDataSliced?.[index]?.action?.includes("U") && (
                <IconButton onClick={() => handleRevertEdit()} size="small" sx={{ padding: "3px" }}>
                  <ClearIcon
                    titleAccess="Clear edit"
                    sx={{ fontSize: "1.1rem", color: "red", cursor: "pointer" }}
                    onClick={() => setEditMode(true)}
                  />
                </IconButton>
              )}
            </>
          </Box>
        ) : <Box title={getPercentageTitle(item?.percentage_contribution)} sx={{ width: TableData?.length >= 5 ? { lg: '4rem', xl: '5rem' } : '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {item?.percentage_contribution > 0 ? `${item?.percentage_contribution.toFixed(decimalPoints)}%` : ''}
        </Box>}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0 0.2rem",
            width: TableData?.length >= 5 ? { lg: '5rem', xl: '6rem' } : '100%',
            justifyContent: "flex-end",
          }}
        >
          {editMode ? (
            <TextField
              id={`standard-basic-${index}`}
              type="number"
              value={
                typeof item?.scenario_cost == "string"
                  ? parseFloat(item?.scenario_cost)?.toFixed(decimalPoints)
                  : value
              }
              disabled={!TableDataSliced?.[index]?.action?.includes("U")}
              onChange={handleInputChange}
              onBlur={(event: any) => {
                
                
                if (event?.target.value !== oldValue) {
                  ExactDebounceParameters(value);
                } else {
                  
                }
              }}
              variant="standard"
              size="small"
              sx={{
                color: item?.value_is_edited ? "red" : "inherit",
                textAlign: "right",
                width: "100%",
                "& .MuiFormLabel-root": { color: "#007fff" },
                "& .MuiFormLabel-root.Mui-focused": { color: "primary.main" },
                "& .Mui-focused": { color: "#007fff" },
                "& .Mui-error": { color: "#d32f2f" },
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              InputProps={{
                endAdornment: loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={16} />
                  </InputAdornment>
                ) : null,
              }}
            />
          ) : (
            <Typography
              title={
                TableDataSliced?.[index]?.status == true ||
                  TableDataSliced?.[index]?.status == null ||
                  TableDataSliced?.[index]?.status == undefined
                  ? getValueTitle(item?.scenario_cost) || item?.scenario_cost
                  : "In Progress"
              }
              sx={{
                color: (HierarchyAllData?.type == "calculator" && item?.value_is_edited) ? "grey" : (item?.value_is_edited ? "red" : "inherit"),
                paddingRight: "0.2rem",
                textAlign: "right",
                fontSize: "1rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor:
                  (HierarchyAllData?.type === "calculator" || TableDataSliced?.[index]?.status == true) ? "pointer" : (TableDataSliced?.[index]?.status == false ? "auto" : "pointer"),
                textDecoration:
                  HierarchyAllData?.type === "calculator"
                    ? (TableDataSliced?.[index]?.status == true ? "underline" : TableDataSliced?.[index]?.status == false ? "none" : "underline")
                    : "none",
              }}
              onClick={() => {
                if (TableDataSliced?.[index]?.action?.includes("R")) {
                  if (HierarchyAllData?.type === "calculator") {
                    if (typeof item?.scenario_cost === "string" || TableDataSliced?.[index]?.status == false) {

                    } else {
                      handleOpenCalculator(item?.with_link);
                    }
                  }
                }

              }}
            >

              {(TableDataSliced?.[index]?.status == true || TableDataSliced?.[index]?.status == null || TableDataSliced?.[index]?.status == undefined) ?
                ((HierarchyAllData?.type == "calculator" && item?.value_is_edited) ? "Disabled" :
                  !HierarchyAllData?.currency ?
                    // <Tooltip title={(typeof item?.scenario_cost === "string"
                    //   ? ((Number(item?.scenario_cost)?.toFixed(decimalPoints) == "NaN" || item?.scenario_cost == "" || item?.scenario_cost == "-") ? "-" : Number(item?.scenario_cost)?.toFixed(decimalPoints))
                    //   : item?.scenario_cost?.toFixed(decimalPoints) || "")}>
                    <span>Ref</span>
                    // </Tooltip>
                    :
                    (typeof item?.scenario_cost === "string"
                      ? ((Number(item?.scenario_cost)?.toFixed(decimalPoints) == "NaN" || item?.scenario_cost == "" || item?.scenario_cost == "-") ? "-" : (item?.max_cost == true ? (
                        <>
                          <span style={{ borderBottom: toggleHighlight ? "0.3px solid red" : "none" }}>{Number(item?.scenario_cost)?.toFixed(decimalPoints)}</span>
                        </>
                      ) : Number(item?.scenario_cost)?.toFixed(decimalPoints)))
                      : (item?.max_cost == true ? (<>
                        <span style={{ borderBottom: toggleHighlight ? "0.3px solid red" : "none" }}> {item?.scenario_cost?.toFixed(decimalPoints)}</span>
                      </>) : item?.scenario_cost?.toFixed(decimalPoints)) || ""))
                : TableDataSliced?.[index]?.status == false ?
                  <>In Progress</>
                  :
                  ""
              }
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ScenarioItem;
