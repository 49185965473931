import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { Box, Skeleton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { API } from "../../../api-services";

const csvOptions: any = {
  fileName: "Raw Material DataBase CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: any) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      width: "100%",
      height: "2.3rem",
      justifyContent: "flex-end",
      paddingRight: "1rem",
      // marginBottom: '0.5rem',
      marginTop: "-2.4rem",
    }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

function OperationDB({ database, selected, setSelected }: any) {
  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [tabValue, setTabValue] = useState<any>(null);
  const [tableData, setTableData] = React.useState<any>([]);
  const [filteredFields, setFilteredFields] = React.useState<any>([]);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

  const getOperationData = (category: any) => {
    setTabledataLoading(true);
      API.get('/api/db/stroke_rate/', { library_category: category }, 0).then((res: any) => {
            setTableData(res?.data);
            setTabledataLoading(false);
          });
  };

  const handleChange = (categoryName: any) => {
    setTabValue(categoryName);
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setTabValue(res.data?.[0]?.category);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  let columns: any = [
   {
         field: "actions",
         headerName: "Select",
         width: width > 100,
         renderCell: (cellValues: any) => {
           return (
             <Box
               sx={{
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "flex-end",
                 columnGap: "1rem",
               }}
             >
               <Checkbox
                 checked={selected?.id == cellValues?.row?.id}
                 onChange={() => setSelected(cellValues?.row)}
               />
             </Box>
           );
         },
       },
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "operationCode", headerName: "Operation Code", width: width > 1370 ? 340 : 220, },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 270 : 250,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: width > 1370 ? 270 : 250,

      flex: 1,
    },
    { field: "rate", headerName: "Rate/Unit (Curr)", width: width > 1370 ? 140 : 120, headerAlign: "right", align: 'right', cellClassName: "super-app-theme--cell" },
    // {
    //   field: "unit",
    //   headerName: "Unit",
    //   minWidth: width > 1370 ? 130 : 90,
    // },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = tableData && tableData?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.id,
      sno: index + 1,
      operationCode: ideaItem?.code,
      description: ideaItem?.description,
      remark: ideaItem?.remark,
      unit: ideaItem?.unit,
      rate: ideaItem?.rate_per_unit,
      category: ideaItem?.category,
      mapped_status: ideaItem?.mapped_status,
      currency_id: ideaItem?.currency_id,
      currency_code: ideaItem?.currency_code,
      lock: ideaItem?.lock,
    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };


  useEffect(() => {
    getCategoryList(database);
  }, [database]);

  useEffect(() => {
    getOperationData(tabValue);
  }, [tabValue]);

  // console.log(tableData, "parameter");
  // console.log(rows, "parameter");

  return (
    <>
      <Box sx={{ display: "flex", gap: "2%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            columnGap: "0.5rem",
            width: "14%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
            marginLeft: "1rem",
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.2"
        >
          {accordionLoading ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
          ) : (
            <>
              {categoryList &&
                categoryList?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          padding:"0.2rem 0.5rem",
                          color: "primary.main",
                          // height: "2rem",
                          // minHeight: "2.1rem",
                          maxWidth: "100% !important",
                          
                          fontSize: "1rem",
                          width: "100%",
                          backgroundColor:
                            item?.category == tabValue ? "primary.main" : "",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "0.1px solid #edf5ef",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              item?.category == tabValue
                                ? "white"
                                : "primary.main",
                            // height: "2rem",
                            // minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0.1rem 0rem",
                            fontSize: "1rem",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            whiteSpace: "nowrap",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleChange(item?.category)}
                        >
                          {item?.category?.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                    </>
                  );
                })}
            </>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            height: { lg: "84vh", xl: "85vh" },
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "#ddefff !important",
              textAlign: "center !important",
            },
          }}
        >
          <DataGrid
            headerHeight={42}
            rowHeight={36}
            {...dataStructureDataGrid}
            loading={tableDataLoading}
            // components={{
            //   Toolbar: () => {
            //     return CustomToolbar();
            //   },
            // }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
          />
        </Box>
      </Box>
    
    </>
  );
}

export default OperationDB;
