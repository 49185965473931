import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { ADMIN } from "../../Redux/Services/admin.service";
import { s3Url } from "../../utlis/s3url.utils";

const uploadS3Supplier = (
    commodityName:any,
    contactUsName:any,
    spocName:any,
    spocEmail:any,
    supplierName:any,
    systems:any,
    aboutUsName:any,
    productCategory:any,
    contactNumber:any,
    contactDialCode:any,
    supplierType:any,
    certification:any,
    fileList:any,
    setLoader:any,
    setSupplierDataApiCall:any,
    supplierDataApiCall:any,
    onCloseModal:any,
    pathName:any,
    type:any,
    id?:any,
    setrowIds?:any,
    plantpopup?:any,
    ErrorModalFiles?:any,
    setErrorModalFileMsg?:any,
) => {
    var random: any = Math.floor( Math.random() * 1000000 + 1 );
    var editedImageName: any = `EditedImage${random}`;
    var obj: Array<any> = [];
    API.get( "cad/cad_upload/", {
        module: MODULES.CAD,
    },0 ).then( res => {
        const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append( "key", `${pathName}/${random}/${file.name}` );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );
            axios
                .post( s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                    },
                } )
                .then( async ( res: any ) => {
                    if ( res.status === 204 ) {
                        if(type == "addSupplier"){
                        API.post('/api/db/supplier/',{logo:`/${pathName}/${random}/${file.name}`,commodity:commodityName,contact_info:contactUsName,spoc_name:spocName,is_oem:false,spoc_email:spocEmail,miscellaneous:"",name:supplierName,other_mfg_process:{},plants:{},subsystem:systems,supplier_details:aboutUsName,supplier_id:-1,vehicle_type:productCategory,contact:contactNumber,dialCode:contactDialCode,supplier_type:supplierType,certification:certification}).then((res:any)=>{
                            setLoader(false);
                            setSupplierDataApiCall(!supplierDataApiCall);
                            setrowIds(res?.data?.id);
                            plantpopup.open();
                          }).catch((err) => {
                            setLoader(false);
                            setErrorModalFileMsg(err.response.data);
                            ErrorModalFiles.open();
                          })
                        }
                        else{
                            API.put(`/api/db/supplier/${id}/`,{logo:`/${pathName}/${random}/${file.name}`,commodity:commodityName,contact_info:contactUsName,spoc_name:spocName,is_oem:false,spoc_email:spocEmail,miscellaneous:"",name:supplierName,other_mfg_process:{},plants:{},subsystem:systems,supplier_details:aboutUsName,supplier_id:-1,vehicle_type:productCategory,contact:contactNumber,dialCode:contactDialCode,supplier_type:supplierType,certification:certification}).then((res:any)=>{
                                setLoader(false);
                                setSupplierDataApiCall(!supplierDataApiCall);
                                onCloseModal();
                        }).catch((err) => {
                            setLoader(false);
                            setErrorModalFileMsg(err.response.data);
                            ErrorModalFiles.open();
                          })
                    }
                }
                    
                } ).catch( ( err ) => {
                    ADMIN.toast.error( "Not able to upload" );
                } );
        } );
    } );
};
// ----------------------------------------------------------------------------------
export default uploadS3Supplier;