import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import openFolder from "../Assets/images/svgs/openfolderblue.svg";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import { Box, Typography, Skeleton, Divider } from "@mui/material";
import { useParams, useRouteMatch } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./ViewComponents.module.scss";
import { API } from "../api-services";
import Paper from "@mui/material/Paper";
// web.cjs is required for IE11 support

import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "material-ui-nested-menu-item";
import { DialogComponent } from "../utlis/DialogComponent";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCompareCount,
  setIsCompare,
} from "../Redux/Actions/compareopen.action";
import swal from "sweetalert";
import axios from "axios";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });
  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  childRef?: any;
}

const TreeViewComponents = forwardRef((props: TreeViewComponentsProps) => {
  const history = useHistory();
  const [visibleChildren, setVisibleChildren] = useState<number>(300);
  const loadMoreChildren = () => {
    setVisibleChildren((prev) => prev + 300); // Load 300 more children
  };
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { projectId, topVault, vault, leafNode, treeabbr, scenarioId } =
    useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const { HierarchyData, onSelectCallback, childRef } = props;

  const [titleString, setTitleString] = React.useState<any>("");
  const imageIframeRef = useRef<any>(null);
  const [activeHoverId, setActiveHoverId] = useState<any>("");
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [expanded, setExpanded] = useState<any>([topVault]);
  const dispatch = useDispatch();
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  const store: any = useSelector((state) => state);
  const [selected, setSelected] = useState<any>([topVault]);
  const [systemloader, setSystemLoader] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<any>(topVault);
  const [isProduc, setIsProduct] = useState<boolean>(false);
  const [HierarchyAllData, setHierarchyAllData] = useState<any>(HierarchyData);
  const [menuItems, setMenuItems] = React.useState<any>();
  const [validatedModules, setValidatedModules] = React.useState<any>([]);
  const [contentRef, setContentRef] = React.useState<any>();
  const [ModalData, setModalData] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const appendChildData = (data: any, nodeId: any, newChildData: any): any => {
    const open = Boolean(anchorEl);

    // Base case: if this node's id matches nodeId, append newChildData
    if (data.id == nodeId) {
      return {
        ...data,
        children: [...(data.children || []), ...newChildData], // Append new data to the existing children
      };
    }

    // If the node has children, recursively search in the children
    if (data.children) {
      return {
        ...data,
        children: data.children.map((child: any) =>
          appendChildData(child, nodeId, newChildData)
        ),
      };
    }

    // If no match and no children, return the node as is
    return data;
  };

  useEffect(() => {
    return () => source.cancel("Operation canceled by the user.");
  }, [activeHoverId]);

  const getVault = (treeabbr: any, idd: any) => {
    if (treeabbr == "product") {
      return { top_vault: idd };
    } else {
      return { vault: idd };
    }
  };

  const handleRedirect = (module: any, treeabbr: any, id: any) => {
    if (module === "media" || module === "architecture") {
      API.get(
        `/image_repository/subsystem_fetch/`,
        {
          project: projectId,
          abbreviation: treeabbr == "product" ? 0 : treeabbr,
          image_category: treeabbr == "product" ? 1 : 2,
          media_type: "image",
        },
        0
      ).then((res: any) => {
        const topVaultKey: any =
          res.data &&
          res.data?.map((tvault: any) => {
            return tvault?.id;
          });

        const topVaults: any = topVaultKey?.slice(0, 5);
        if (module === "media") {
          if (treeabbr == "product") {
            res.data &&
              history.push(
                `/media-repository/image/${projectId}/0/1/0/${topVaults.toString()}`
              );
          } else {
            res.data &&
              history.push(
                `/media-repository/image/${projectId}/${treeabbr}/2/0/${topVaults.toString()}`
              );
          }
        } else if (module === "architecture") {
          if (treeabbr == "product") {
            res.data &&
              history.push(
                `/architecture/${projectId}/0/0/${topVaults.toString()}`
              );
          } else {
            res.data &&
              history.push(
                `/architecture/${projectId}/${treeabbr}/0/${topVaults.toString()}`
              );
          }
        }
      });
    }
    if (module === "features") {
      API.get("/customer/feature_view_data/", {
        project: projectId,
        analysis_type: 19,
        subsystem_list: true,
      })
        .then((res: any) => {
          // setFeatureTopVault(res.data?.allowed_top_vault);
          // setFeatureData(res?.data?.sub_system);
          const topVaultKey: any =
            res.data?.allowed_top_vault &&
            res.data?.allowed_top_vault?.map((tvault: any) => {
              return tvault?.id;
            });
          const topVaults: any = topVaultKey?.slice(0, 5);
          const FeatureVault: any =
            res?.data?.sub_system &&
            res?.data?.sub_system.filter((item: any, ind: any) => {
              return item.abbreviation == treeabbr;
            });

          var DataFilterFeatureId =
            FeatureVault && FeatureVault[0]?.abbreviation == "product"
              ? FeatureVault && FeatureVault[0]?.top_vault_id
              : FeatureVault && FeatureVault[0]?.vault_id;
          var FilterFeatureId = FeatureVault && FeatureVault[0]?.id;

          history.push(
            `/featurelandingPage/${projectId}/${topVaults?.toString()}/${DataFilterFeatureId}/${treeabbr}/${0}/${FilterFeatureId}/${0}/${0}`
          );
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (module === "bom") {
      history.push(`/view/${projectId}/${topVault}/${id}/${treeabbr}/true`);
    } else if (module === "costing") {
      history.push(`/view/${projectId}/${topVault}/${id}/${treeabbr}/costing`);
    } else if (module === "ideas") {
      if (id == topVault) {
        if (treeabbr == "product") {
          history.push(`/idea/${projectId}/${topVault}/0/product`);
        }
      } else {
        if (treeabbr == "null") {
          API.get(
            `/customer/dashboard/`,
            { vault_id: id, fetch_abbreviation: true },
            0
          ).then((response: any) => {
            history.push(
              `/idea/${projectId}/${topVault}/${response.data?.abbreviation}/${id}`
            );
          });
        } else {
          history.push(`/idea/${projectId}/${topVault}/${treeabbr}/${id}`);
        }
      }
    }
    if (module === "w") {
      if (url.includes("costing")) {
        API.get(
          "/customer/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: true,
            consolidate_type: "Costing",
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        ).then((res: any) => {
          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: true,
                consolidate_type: "Costing",
                scenario: url.includes("scenario") ? scenarioId : 0,
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            window.open(res?.data?.url, "_self");
          }
        });
        // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      } else {
        // openStatusContent.open();
        API.get(
          "/customer/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: true,
            consolidate_type: "BOM",
          },
          0
        ).then((res: any) => {
          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: true,
                consolidate_type: "BOM",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            //  openStatusContent.open();
            window.open(res?.data?.url, "_self");
          }
        });
        // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      }
    } else if (module === "w/o") {
      if (url.includes("costing")) {
        API.get(
          "/customer/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: false,
            consolidate_type: "Costing",
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        ).then((res: any) => {
          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: false,
                scenario: url.includes("scenario") ? scenarioId : 0,
                consolidate_type: "Costing",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            window.open(res?.data?.url, "_self");
          }
        });
      } else {
        // openStatusContent.open();
        API.get(
          "/customer/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: false,
            consolidate_type: "BOM",
          },
          0
        ).then((res: any) => {
          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: false,
                consolidate_type: "BOM",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            //  openStatusContent.open();
            window.open(res?.data?.url, "_self");
          }
        });
      }
    } else if (module === "Excel") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      API.get(
        "customer/individual_report/",
        {
          vault: id,
          individual_type: "Costing",
          scenario: url.includes("scenario") ? scenarioId : 0,
        },
        0
      ).then((res: any) => {
        window.open(res?.data, "_self");
      });
    } else if (module === "Detailed Excel") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      API.get(
        "customer/individual_report/",
        {
          vault: id,
          individual_type: "Costing",
          scenario: url.includes("scenario") ? scenarioId : 0,
          detail_report: true,
        },
        0
      ).then((res: any) => {
        window.open(res?.data, "_self");
      });
    }
    if (module === "withbom") {
      // openStatusContent.open()
      // // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      //  API.get("customer/consolidated_report/",{...getVault(),with_image:true,consolidate_type:"BOM"},0).then((res:any)=>{
      //   window.open(res?.data, "_self")
      //  })
    } else if (module === "withoutbom") {
      // openStatusContent.open()
      // // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      // API.get("customer/consolidated_report/",{...getVault(),with_image:false,consolidate_type:"BOM"},0).then((res:any)=>{
      //   window.open(res?.data, "_self")
      //  })
    } else if (module === "Excelbom") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      //  DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","success")
      API.get(
        "customer/individual_report/",
        { vault: id, individual_type: "BOM" },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => {});
    }
  };

  const handleCompareClick = (abbr: any, idd: any) => {
    API.post(
      "/customer/compare_cart/",
      {
        vault: parseInt(idd),
        project: projectId,
      },
      {},
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("is_compare", "true");
        dispatch(setIsCompare("true"));
        dispatch(setAddCompareCount(store.is_compare?.add_count + 1));
      })
      .catch((err: any) => {
        if (err.response.status === 400) {
          // swal("Selection Exceeds",'warning');
          swal({
            // title: "Good job!",
            text: "Selection Exceeds!",
            icon: "warning",
            //@ts-ignore
            button: "OK",
          });
        }
      });
  };
  const hoverData = (title: any, id: any) => {
    if (url.includes("costing")) {
      if (id == topVault) {
        setTitleString("");
        API.get(
          "/customer/system_cost/",
          {
            top_vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0,
          source.token
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      } else {
        setTitleString("");
        API.get(
          "/customer/system_cost/",
          {
            vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0,
          source.token
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      }
    }
  };

  const checkPresentModule = () => {
    if (url.includes("featurelandingPage")) {
      return "features";
    } else if (url.includes("true")) {
      return "bom";
    } else if (url.includes("costing")) {
      return "costing";
    }
  };

  const getMenuItems = (
    currentTarget: any,
    idd: any,
    is_leaf: any,
    abbreviation: any
  ) => {
    if (topVault == idd) {
      // setMenuItems((prev:any)=>[...prev,"Quick_Compare"])
      API.get(
        "/customer/view_data_module/",
        { top_vault: idd, validated: true },
        0
      )
        .then((res: any) => {
          setMenuItems(
            Object.keys(res.data).filter((item) => res.data[item] === true)
          );
          let checkedMod = Object.keys(res.data).filter(
            (item) => res.data[item] === true
          );
          if (
            url.includes("costing") &&
            checkedMod &&
            checkedMod?.includes("costing")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [
                ...prev,
                ...["Excel", "Detailed Excel"],
              ]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          if (
            url.includes("true") &&
            checkedMod &&
            checkedMod?.includes("bom")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excelbom"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }

          setAnchorEl(currentTarget);
        })
        .catch((err: any) => {
          setMenuItems([]);
        });
    } else {
      API.get("/customer/view_data_module/", { vault: idd, validated: true }, 0)
        .then((res: any) => {
          setMenuItems(
            Object.keys(res.data).filter(
              (item) => res.data[item] === true && item !== checkPresentModule()
            )
          );
          // setValidatedModules(
          //   Object.keys(res.data).filter((item) => res.data[item] === true)
          // )
          let checkedMod = Object.keys(res.data).filter(
            (item) => res.data[item] === true
          );
          if (
            url.includes("costing") &&
            checkedMod &&
            checkedMod?.includes("costing")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [
                ...prev,
                ...["Excel", "Detailed Excel"],
              ]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          if (
            url.includes("true") &&
            checkedMod &&
            checkedMod?.includes("bom")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excelbom"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          setAnchorEl(currentTarget);
        })
        .catch((err: any) => {
          setMenuItems({});
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getModule = (module: any) => {
    // if(module !== "Quick_Compare"){
    switch (module) {
      case "media":
        return "Go to Media";
      case "bom":
        return "Go to BOM";
      case "costing":
        return "Go to Costing";
      case "features":
        return "Go to Features";
      case "architecture":
        return "Go to Architecture";
      case "ideas":
        return "Go to Ideas";
      case "without":
        return "Costing with out images download";
      case "with":
        return "Costing with images download";
      case "Excel":
        return "Part cost excel download";
      case "Detailed Excel":
        return "Post cost detailed excel download";
      case "withoutbom":
        return "Costing with out images download";
      case "withbom":
        return "Costing with images download";
      case "Excelbom":
        return "Part BOM excel download";
      case "Download Report":
        return "Download Report";
    }
    // }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
    // setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
    // setOpenContext(true);
    getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };

  const handleChange = async (e: any, nodeId: any, abbreviation: any) => {
    sessionStorage.setItem("BOM_is_leaf", HierarchyAllData?.is_leaf);
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a) || [];

    // Only proceed to fetch child data if the node is not a leaf
    if (!HierarchyAllData.is_leaf) {
      if (!val.includes(nodeId)) {
        val.push(nodeId);
        sessionStorage.setItem("node", JSON.stringify(val));
        fetchChildData(nodeId, abbreviation); // Fetch child data when a node is expanded
      } else {
        let noDuplicate: any = val?.filter((item: any) => {
          return item != nodeId;
        });
        let b: any = sessionStorage.setItem(
          "node",
          JSON.stringify(noDuplicate)
        );
        if (noDuplicate?.includes(topVault)) {
        } else {
          noDuplicate.unshift(topVault);
        }
      }
    }

    const newExpanded = expanded.includes(nodeId)
      ? expanded.filter((id: any) => id !== nodeId)
      : [...expanded, nodeId];

    setExpanded(newExpanded);
    setSelected([nodeId]); // Select the node

    setActiveId(nodeId);
    onSelectCallback(
      nodeId,
      abbreviation,
      HierarchyAllData,
      HierarchyAllData.id
    );
  };

  useEffect(() => {
    // Retrieve previously expanded nodes on component mount
    const savedExpandedNodes = sessionStorage.getItem("node");
    if (savedExpandedNodes) {
      setExpanded(JSON.parse(savedExpandedNodes));
    }
  }, [topVault]);

  // Fetch child data dynamically when expanding a node, passing abbreviation as well
  const fetchChildData = async (nodeId: any, abbreviation: string) => {
    setLoading(true);
    if (url.includes("scenario") && treeabbr !== "product") {
      // API.get("/customer/costing_scenario_hierarchy/", { vault_id: vault }, 0).then((res: any) => {
      //   setHierarchyData(res.data)
      //   setHierarchyAllData(res.data.data);
      //   if(childRef.current){
      //     if(vault != 0){
      //   fetchSequentially();
      //     }else{
      //   fetchFirstLevelData();
      //     }
      //   }
      // })
      try {
        const response = await API.get(
          `/customer/costing_scenario_hierarchy/`,
          {
            id: nodeId,
            abbreviation: abbreviation,
          },
          0
        );

        const ids = new Set(response.data.map((item: any) => item.id));

        // Append the new child data to the node with the given nodeId
        const updatedData = appendChildData(
          {
            ...HierarchyAllData,
            children: HierarchyAllData?.children?.filter(
              (item: any) => !ids.has(item?.id)
            ),
          },
          nodeId,
          response.data
        );

        // Update the state with the modified hierarchy
        setHierarchyAllData(updatedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch child data:", error);
      }
    } else {
      try {
        const response = await API.get(
          `/xcpep/new_system_hierarchy_fetch/`,
          {
            id: nodeId,
            abbreviation: abbreviation,
          },
          0
        );

        const ids = new Set(response.data.map((item: any) => item.id));

        // Append the new child data to the node with the given nodeId
        const updatedData = appendChildData(
          {
            ...HierarchyAllData,
            children: HierarchyAllData?.children?.filter(
              (item: any) => !ids.has(item?.id)
            ),
          },
          nodeId,
          response.data
        );

        // Update the state with the modified hierarchy
        setHierarchyAllData(updatedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch child data:", error);
      }
    }
  };

  useImperativeHandle(childRef, () => ({
    fetchChildData,
  }));

  useEffect(() => {
    document.getElementById(vault)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, [vault]);

  return (
    <>
      {contentRef && (
        <>
          <Box className={styles.centeredContent}>
            <img
              ref={imageIframeRef}
              src={contentRef}
              height={200}
              width={200}
            />
          </Box>
        </>
      )}

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        className={styles.treetemp}
        // Control the icon and expand behavior based on is_leaf
        defaultParentIcon={<FolderIcon fill="primary" />}
        defaultCollapseIcon={
          HierarchyAllData && HierarchyAllData?.is_leaf == false ? (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          ) : (
            <InsertDriveFileIcon color="primary" />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false ? (
            <FolderIcon color="primary" />
          ) : (
            <InsertDriveFileIcon color="primary" />
          )
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={(e: any) => {
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation);
        }}
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == parseInt(vault)
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              ".MuiList-root.MuiMenu-list": {
                padding: 0,
              },
            }}
          >
            {menuItems &&
              menuItems?.length > 0 &&
              menuItems
                ?.filter(
                  (item: any) =>
                    item !== "scenario_exists" && item !== "scenario_created"
                )
                .map((item: any) => {
                  return (
                    <MenuItem
                      sx={{
                        display: "flex !important",
                        flexDirection: "column !important",
                        textAlign: "left !important",
                        padding: "0.1rem 0.5rem",
                        color: "primary.main",
                      }}
                      onClick={() =>
                        handleRedirect(
                          item,
                          HierarchyAllData?.abbreviation,
                          HierarchyAllData?.id
                        )
                      }
                      style={{
                        fontSize: "0.9rem",
                        alignItems: "flex-start",
                        padding: "0.1rem 0.5rem",
                      }}
                    >
                      {item &&
                      item !== "Download Report" &&
                      menuItems.includes(item) ? (
                        `${getModule(item)}`
                      ) : (
                        //@ts-ignore
                        <NestedMenuItem
                          style={{
                            padding: "0",
                            fontSize: "0.9rem",
                            color: "primary.main",
                          }}
                          label={getModule(item) && `${getModule(item)}`}
                          parentMenuOpen={open}

                          // onClick={handleItemClick}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "0 0.5rem",
                              color: "primary.main",
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontSize: "1rem",
                                color: "primary.main",
                                padding: "0.2rem 0.5rem",
                                // borderBottom:'1px solid #8080801c !important'
                              }}
                              onClick={() =>
                                handleRedirect(
                                  "w",
                                  HierarchyAllData?.abbreviation,
                                  HierarchyAllData?.id
                                )
                              }
                            >
                              Consolidated with images
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontSize: "1rem",
                                color: "primary.main",
                                padding: "0.2rem 0.5rem",
                              }}
                              onClick={() =>
                                handleRedirect(
                                  "w/o",
                                  HierarchyAllData?.abbreviation,
                                  HierarchyAllData?.id
                                )
                              }
                            >
                              Consolidated w/o images
                            </MenuItem>
                          </Box>
                        </NestedMenuItem>
                      )}
                    </MenuItem>
                  );
                })}
            {HierarchyAllData?.abbreviation !== "product" && (
              <MenuItem
                sx={{
                  display: "flex !important",
                  flexDirection: "column !important",
                  textAlign: "left !important",
                  padding: "0.1rem 0.5rem",
                  color: "primary.main",
                }}
                onClick={() =>
                  handleCompareClick(
                    HierarchyAllData?.abbreviation,
                    HierarchyAllData?.id
                  )
                }
                style={{
                  fontSize: "0.9rem",
                  alignItems: "flex-start",
                  padding: "0.1rem 0.5rem",
                }}
              >
                Add to Quick Compare
              </MenuItem>
            )}
          </Menu>
        </Paper>
        {loading ? (
          // Skeleton Loader Placeholder
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
            onMouseEnter={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              timeoutRef.current = setTimeout(() => {
                hoverData(
                  `${HierarchyAllData?.part_no} ${
                    HierarchyAllData?.part_no ? "x" : ""
                  } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
                  HierarchyAllData.id
                );
              }, 500);
              setActiveHoverId(HierarchyAllData.id);

              setActiveHoverId(HierarchyAllData.id);
            }}
            onMouseLeave={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              setActiveHoverId(undefined);
            }}
            title={titleString && url.includes("costing") && titleString}
            label={
              <>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={100} />
              </>
            }
          />
        ) : (
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
            onMouseEnter={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              timeoutRef.current = setTimeout(() => {
                hoverData(
                  `${HierarchyAllData?.part_no} ${
                    HierarchyAllData?.part_no ? "x" : ""
                  } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
                  HierarchyAllData.id
                );
              }, 500);
              setActiveHoverId(HierarchyAllData.id);

              setActiveHoverId(HierarchyAllData.id);
            }}
            onMouseLeave={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              setActiveHoverId(undefined);
            }}
            title={titleString && url.includes("costing") && titleString}
            label={
              <Typography className={styles.treeViewData}>
                <Typography
                  style={{ fontSize: "12px" }}
                  className={styles.treeViewText}
                  onContextMenu={(event: any) =>
                    handleClick(
                      event,
                      HierarchyAllData.id,
                      HierarchyAllData.is_leaf,
                      HierarchyAllData
                    )
                  }
                >
                  {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                  <br /> {HierarchyAllData?.name}
                </Typography>
              </Typography>
            }
          >
            {/* {HierarchyAllData?.children?.map((child: any) => {
              return (
                <TreeViewComponents
                  key={child.id}
                  ref={childRef} // Each child gets its own ref
                  HierarchyData={child}
                  onSelectCallback={onSelectCallback}
                />
              );
            })} */}
            {HierarchyAllData?.children &&
              HierarchyAllData.children
                ?.slice(0, visibleChildren) // Only render visible children
                ?.map((child: any) => (
                  <TreeViewComponents
                    key={child.id}
                    ref={childRef} // Each child gets its own ref
                    HierarchyData={child}
                    onSelectCallback={onSelectCallback}
                  />
                ))}
            {HierarchyAllData?.children &&
              HierarchyAllData.children.length > visibleChildren && (
                <Box
                  
                  sx={{ marginTop: "0.5rem",display:'flex',alignItems:'center' }}
                >
                  <Divider sx={{color:'black',width:'33%'}}/>
                  <p
                    style={{ cursor: "pointer", textDecoration: "underline",width:"18%",color:'black' }}
                    onClick={loadMoreChildren}
                  >
                    load more
                  </p>
                  <Divider sx={{color:'black',width:'33%'}}/>
                </Box>
              )}
          </StyledTreeItem>
        )}
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
});

export default TreeViewComponents;
