import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import highlighter from "../../Assets/images/highlighter.png";
import highlighterdisabled from "../../Assets/images/highlighterdisabled.png";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import analysisIcon from "../../Assets/images/plotgraphIcon.svg";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import {
  Chip,
  Typography,
  Skeleton,
  Badge,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  debounce,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./ScenarioCostingLanding.module.scss";
import { ScenarioCostingPage } from "./ScenarioCostingPage";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AddScenarioModal } from "./AddScenarioModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AddAssumptionModal from "./AddAssumptionModal";
import { API } from "../../api-services";
import { AxiosError, AxiosResponse } from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ADMIN } from "../../Redux/Services/admin.service";
import { ScenarioCostingCreateDrawer } from "./ScenarioCostingCreateDrawer";
import DownloadIcon from "@mui/icons-material/Download";
import ScenarioAnalysisModel from "./ScenarioAnalysisModel";
import Swal from "sweetalert2";

// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AUTH } from "../../Redux/Services/auth.service";
import { PermissionModal } from "./PermissionModal";
import DecimalModal from "./DecimalModal";
import SwitchInput from "./SwitchInput";
import ScenarioCostingPageListView from "./ScenarioCostingPageListView";
import ScenarioSearch from "./ScenarioSearch";
import RViewerJS from "viewerjs-react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Popover from '@mui/material/Popover';
import { CurrencyChangeDynamic } from "../../ViewModule/CostingView/CurrecyChangeDynamic";

export interface IScenarioCostingLandingProps { }

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  scenarioType: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

export function ScenarioCostingLanding(props: IScenarioCostingLandingProps) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,
  } = useRouteParams<any>();
  const [defaultCurrency, setDefaultCurrency] = useState<any>("INR");
const { url } = useRouteMatch();
  const childRef = React.useRef<any>(null);
  const [count, setCount] = React.useState<any>(0);
  const [status, setStatus] = React.useState<any>(null);
  const [toggleHighlight, setToggleHighlight] = React.useState<any>(false);
  const [productList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [showZeroRows, setShowZeroRows] = useState(false);
  const [ListLoading, setListLoading] = useState(false); // Loading state
  const [counts, setCounts] = React.useState<any>(0);
  const [apiCallMain, setApiCallMain] = React.useState(0);
  const [assumptionsCardCount, setAssumptionCardCount] = React.useState<any>(0);
  const [assCardCounts, setAssCardCounts] = React.useState<any>(0);
  const [configPermission, setConfigPermission] = React.useState<any>("");
  const [permission, setPermission] = React.useState<any>("");
  const [costStatus, setCostStatus] = React.useState<any>(null);
  const [title, setTitle] = React.useState<any>(null);
  const [warningMsg, setwarningMsg] = useState<any>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [locatorCount, setLocatorCount] = React.useState<any>(null);
  const [searchValueOptions, setSearchVaultsOptions] = React.useState<any>();
  const [scenarioDashboardOpen, setScenarioDashboardOpen] =
    React.useState<any>(false);
  const [totalCount, setTotalcount] = React.useState<number>(0);
  const [scenarioCount, setScenarioCount] = React.useState<number>(0);
  const [finalCostData, setFinalCostdata] = React.useState<any>(null);
  const [checkboxvalue, setcheckboxvalue] = React.useState<any>([]);
  const [selectedScenarios, setSelectedScenarios] = React.useState<any>([]);
  const [graphData, setgraphData] = React.useState<any>([]);
  const [disabledNodes, setDisabledNodes] = React.useState<any>([]);
  const [addCount, setAddCount] = React.useState<number>(0);
  const [decimalPoints, setDecimalPoints] = useState<number>(2);
  const [listView, setListView] = useState<any>();
  const [activeScenarios, setActiveScenarios] = useState<any>([]);
  const [oldScenario, setOldScenarios] = useState<any>([]);
  const [activeSelectedScenarios, setActiveSelectedScenarios] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [allScenarios, setAllScenarios] = useState<any>([]);
  const [viewSwitchInput, setViewSwitchInput] = useState<any>();

  const viewerRef = React.useRef<any>(null);
  // -----------------------------------------------------------------------

  const [costDetailsDataLoading, setCostDetailsDataLoading] = React.useState<any>(false);
  const [costDetailsData, setCostDetailsData] = React.useState<any>({});
  const [scenarioImageLoading, setscenarioImageLoading] = React.useState<any>(false);
  const [ScenarioImage, setScenarioImage] = React.useState<any>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [vaultInfo, setVaultInfo] = React.useState<any>(null);
  const currencyModal = useBaseModal();
  const decimalModal = useBaseModal();
  const ScenarioAnalysis = useBaseModal();
  const analysisModal = useBaseModal();
  const graphModal = useBaseModal();

  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const getDisableStatus = () => {
    if (costStatus) {
      return false;
    } else if (!costStatus) {
      return true;
    } else {
      return false;
    }
  };

  const getPermission = () => {
    API.get(
      "/cost/scenario_permission/",
      {
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data);
      })
      .catch((err: any) => { });
  };

  // Sceanario Filter
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Ensure anchorEl is set correctly
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Vault Sceanrio filter
  const [allVaultScenarios, setAllVaultScenarios] = useState<any>([]);
  const [isFilterActive, setIsFilterActive] = useState(sessionStorage.getItem("isFilterActive") ? JSON.parse(sessionStorage.getItem("isFilterActive") || "") : false);
  const [activeVaultScenarios, setActiveVaultScenarios] = useState<string[]>(sessionStorage.getItem("selectedValues") ? JSON.parse(sessionStorage.getItem("selectedValues") || "") : []);
  const [selectedValues, setSelectedValues] = useState<string[]>(sessionStorage.getItem("selectedValues") ? JSON.parse(sessionStorage.getItem("selectedValues") || "") : []);
  // To manage filter state

  const handleChange = (event: any) => {
    const newSelectedValues = event.target.value;

    // Limit selection to 5 items
    if (newSelectedValues.length <= 5) {
      setSelectedValues(newSelectedValues);
    }
  };

  const applyFilter = () => {
    if (selectedValues.length > 0) {
      setIsFilterActive(true); // Enable filter state
      setActiveVaultScenarios(selectedValues); // Apply selected values as filter
      sessionStorage.setItem("selectedValues", JSON.stringify(selectedValues));
      sessionStorage.setItem("isFilterActive", JSON.stringify(true));
    }
  };

  // Handle Clear Filter
  const clearFilter = () => {
    setIsFilterActive(false); // Disable filter state
    setSelectedValues([]); // Clear local selection state
    sessionStorage.removeItem("selectedValues");
    sessionStorage.removeItem("isFilterActive");
  };


  // const checkPermission = () => {
  //   API.get(
  //     "/auth/calibration_user_permission/",
  //     {
  //       costing_assumption: true,
  //       action: true,
  //       top_vault: topVault,
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       const tab = res.data.cost_main ? 1 : 2;

  //       window.open(
  //         `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/${tab}/${res.data.cost_main}/${res.data.cost_scenario}`
  //       );
  //     })
  //     .catch((err: any) => { });
  // };

  const handleChangeZeroRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowZeroRows(event.target.checked);
  };

  useEffect(() => {
    const decimal = localStorage.getItem("decimalPoint");
    if (decimal) {
      setDecimalPoints(Number(decimal));
    } else {
      setDecimalPoints(3);
    }
  }, []);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Costing Configuration",
      },
      0
    )
      .then((res: any) => {
        setConfigPermission(res?.data?.action);
        // sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const getPartInfo = (newInputValue: any) => {
    API.get(
      `/xcpep/vault/search/`,
      { search: newInputValue, project: projectId },
      0
    )
      .then((res) => {
        setSearchVaultsOptions(res.data);
      })
      .catch((err: AxiosError) => {
        setSearchVaultsOptions([]);
        const { data }: any = err?.response;
        // Swal.fire({
        //   icon: "error",
        //   html: `<div>
        //           <br />
        //           <p style="color:"red">${data[0]}</p>
        //         </div>`,
        // });
      });
  };

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [listView]);

  const getTableData = () => {
    setLoading(true);
    API.get(
      "/cost/scenario_data_header/",
      {
        ...getObj(),
        main_cost_info: true,
        selected_scenarios: selectedScenarios,
      },
      0
    )
      .then((res: any) => {
        if (res?.data?.[0]?.is_product_or_system == true) {
          setViewSwitchInput(res?.data?.[0]);
        } else {
          setViewSwitchInput(res?.data?.[0]);
        }

        setCostStatus(res?.data[0]?.cost_status);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (viewSwitchInput?.is_product_or_system == true) {
        setListView(false);
      } else if (viewSwitchInput?.is_product_or_system == false) {
        setListView(false);
      }
    }, 100);
  }, [viewSwitchInput]);

  // xcpep/vault_info/id

  const getTitleFromVault = () => {
    API.get("xcpep/vault_info/", { vault: vault }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.part_no} (${res?.data[0]?.name})`);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  const getTitleFromTopVault = () => {
    API.get("xcpep/top_vault/", { top_vault: topVault }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.name}`);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  const getActiveScenarios = () => {
    {
      API.get(
        "/cost/scneario_list_data_view/",
        { ...getObj(), all_scenario_list: true },
        0
      )
        .then((res: any) => {
          setAllScenarios(res?.data?.all_scenario_data);
          setOldScenarios(res?.data?.pre_selected_data);
          setActiveScenarios(res?.data?.pre_selected_data);
        })
        .catch((err: any) => {
          console.log("Sever Error");
        });
    }
  };

  const getActiveVaultScenarios = () => {
    {
      API.get(
        "/cost/scneario_list_data_view/",
        { ...getObj(), all_scenario_list_for_vault: true },
        0
      )
        .then((res: any) => {
          setAllVaultScenarios(res?.data?.all_scenario_data);
        })
        .catch((err: any) => {
          console.log("Sever Error");
        });
    }
  };


  React.useEffect(() => {
    getPermission();
    getTableData();
    getActiveScenarios();
    getActiveVaultScenarios();
    if (vault == 0) {
      getTitleFromTopVault();
    } else {
      getTitleFromVault();
    }
  }, [topVault, vault]);

  const getLocationsName = () => {
    API.get(
      "cost/assumption_card/",
      {
        revision_type: 2,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setCounts(res.data?.length);
      })
      .catch((err: any) => { });
  };
  const totalAssumptionCardCounts = () => {
    API.get(
      "cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: 2,
      },
      0
    )
      .then((res: any) => {
        setAssumptionCardCount(res?.data?.length);
      })
      .catch((err: any) => { });
    // https://api-stage2.xcpep.com/cost/assumption_card/?top_vault=49&assumption_type=2
  };
  React.useEffect(() => {
    getLocationsName();
    totalAssumptionCardCounts();
  }, [topVault, vault, projectId]);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        API.get(
          `/xcpep/product_list_new/`,
          { project: `${projectId}`, module: "Cost-Scenario" },
          0
        )
          .then((res: AxiosResponse) => {
            setProductList(res?.data);
            setSelectedProduct([topVault]);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <ScenarioCostingCreateDrawer
      count={count}
      setStatus={setStatus}
      locatorCount={locatorCount}
      setLocatorCount={setLocatorCount}
      // syncColor={syncColor}
      // setSyncColor={setSyncColor}
      scenarioDashboardOpen={scenarioDashboardOpen}
      setScenarioDashboardOpen={setScenarioDashboardOpen}
      anchorComing={anchor}
      toggleDrawer={toggleDrawer}
      productList={productList}
      setProductList={setProductList}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      isOpen={state[anchor]}
      childRef={childRef && childRef}
    />
  );

  const backButtonHandler: any = () => {
    history.goBack();
  };

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "costing_create",top_vault: topVault },
      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingtable",

        res.data[0]?.current_currency
      );
      // currency
      sessionStorage.setItem(
        "currency",

        res.data[0]?.current_currency
      );

      sessionStorage.setItem(
        "actionkey_costingtable",

        res.data[0]?.is_manual ? "mannual" : "auto"
      );

      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",

          res.data[0]?.conversion_factor
        );
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",

          (1 / res.data[0]?.conversion_factor).toString()
        );
      }
    });
  }, [count]);

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { top_vault: topVault, module: "costing_create", get_default_currency: true },
      0
    )
      .then((res: any) => {
        url?.includes('analysis') ? sessionStorage.setItem("currency_analysis",res.data[0]?.current_currency) : url.includes("costingsystemlanding") || url.includes("costingscenarioabbrlanding") || url.includes("costingscenario") ? sessionStorage.setItem("costingtable",res.data[0]?.current_currency) : url.includes("costingqualitycheck") ? sessionStorage.setItem("costingLpp",res.data[0]?.current_currency) : sessionStorage.setItem("currency",res.data[0]?.current_currency)
        setDefaultCurrency(res.data[0]?.current_currency);
      })
      .catch((err: any) => {});
  }, [topVault]);

  const AddScenarioModel = useBaseParamsModal();
  const addAssumptionModal = useBaseModal();
  const AddScenarioHandler = () => {
    AddScenarioModel.open();
  };

  const permissionModal = useBaseParamsModal();

  const permissionModalHandler = () => {
    permissionModal.open();
  };

  useEffect(() => {
    API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
      .then((res: any) => {
        // setWarningLoader(false);
        setwarningMsg(res?.data);
      })
      .catch((err: any) => {
        // setWarningLoader(false);
      });
  }, [vault]);

  const DeltaHandler = (id: any) => {
    API.get("/cost/cost_calculator_update/", { vault: warningMsg?.vault }, 0)
      .then((res: any) => {
        ADMIN.toast.info("Delta sync is in progress.");
        setCount((prev: any) => prev + 1);
        API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
          .then((res: any) => {
            // setWarningLoader(false);
            setwarningMsg(res?.data);
          })
          .catch((err: any) => {
            // setWarningLoader(false);
          });
      })
      .catch((err: any) => { });
  };

  const handleAssumptionRedirection = () => {
    window.open(
      `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/1/true/true`
    );
  };

  const AnalysisHandler = () => {
    ScenarioAnalysis.open();
  };

  const handleGraphRemove = (index: any) => {
    const updatedGraphData = graphData?.filter(
      (_: any, i: number) => i !== index
    ); // Remove the element that matches the index
    setgraphData(updatedGraphData);
  };

  // Pagination
  // const [page, setPage] = useState(1);
  // const [data, setData] = useState([]);

  // const fetchData = async (page: any) => {
  //   try {
  //     const response = await fetch(`https://api.example.com/data?page=${page}`);
  //     const result = await response.json();
  //     setData(result.data); // Assuming `result.data` contains the data
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData(page); // Fetch data when page changes
  // }, [page]);

  // const handlePageChange = (event: any, value: any) => {
  //   setPage(value);
  // };

  useEffect(() => {
    setscenarioImageLoading(true);
    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setScenarioImage(res.data[0]);
        setscenarioImageLoading(false);
      }).catch((err: any) => {
        console.log("Server Error");
        setscenarioImageLoading(false);
      });
  }, [vault]);
  // console.log("scenarioImage", scenarioId, ScenarioImage)

  const downloadHandler = () => {
    if (listView === false) {
      API.get(
        `/cost/donwload_scenario_report/`,
        { hierarchy_data: true, vault: vault },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
          ADMIN.toast.info("Scenario downloaded successfully");
        })
        .catch((err: any) => {
          // ADMIN.toast.info(err?.response?.[0]);
          console.log(err);
        });
    } else {
      API.get(
        `/cost/scenario_costing_consolidated_download/`,
        {
          [vault == 0 ? "top_vault" : "vault_id"]:
            vault == 0 ? topVault : vault,
        },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
          ADMIN.toast.info("Scenario downloaded successfully");
        })
        .catch((err: any) => {
          // ADMIN.toast.success(err?.response?.[0]);
          console.log(err);
        });
    }

    Swal.fire({
      title: "Download Scenario",
      text: "Downloading in the background, We let you know once the download is completed",
      icon: "info",
      confirmButtonText: "Ok",
      showCancelButton: false,
    });
    // .then((result) => {
    //   if (result.isConfirmed) {

    //   }
    // });

    // Swal.fire({
    //   title: 'Download Scenario',
    //   text: 'Do you want to download the scenario?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   cancelButtonText: 'No'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     API.get(
    //       `/cost/scenario_download/`,
    //       { vault: vault },
    //       0
    //     )
    //       .then((res: any) => {
    //         ADMIN.toast.success("Scenario downloaded successfully");
    //       })
    //       .catch((err: any) => {
    //         ADMIN.toast.error("Error in downloading scenario");
    //       });
    //   }
    // })
  };

  React.useEffect(() => {
    if (title?.split(" ")?.length > 0) {
      getPartInfo(title?.split(" ")?.[0]);
    }
  }, [title]);

  useEffect(() => {
    if (Array.isArray(searchValueOptions) && searchValueOptions?.length > 0) {
      setLocatorCount(searchValueOptions?.[0]);
    }
  }, [searchValueOptions]);

  const updateFinalCostChartData = (data: any) => {
    setFinalCostdata(data);
  };

  const allowedItems = [
    "-2--Material--calculator_category",
    "-5--Process--calculator_category",
    "-6--Overheads--calculator_category",
  ];

  const graphType = React.useMemo(() => {
    const isSplit =
      checkboxvalue.every((item: any) => allowedItems.includes(item)) &&
      checkboxvalue.length > 0;
    return isSplit ? "split" : "not_split";
  }, [checkboxvalue, allowedItems]);

  const getAnalysis = () => {
    API.get("/cost/scenario_analysis/", {
      vault_id: vault,
      selected_scenario: selectedScenarios.join(","),
      plot_graph_over: checkboxvalue.join(","),
    })
      .then((res: any) => {
        setgraphData(res?.data);
      })
      .catch((err: any) => { });
  };

  const shareScenario = useBaseParamsModal();

  const selectedScenarioss = allScenarios?.filter((option: any) =>
    activeScenarios?.includes(option?.id)
  );
  const updateDataVaultParams = async (val: any) => {
    setActiveSelectedScenarios(val);
  };

  const ExactDebounceParameters = React.useRef(
    debounce(async (val: any) => {
      await updateDataVaultParams(val);
    }, 500)
  ).current;



  const arraysAreEqual = (a: number[], b: number[]) => {
    if (a?.length !== b?.length) return false;
    const sortedA = [...a]?.sort();
    const sortedB = [...b]?.sort();
    return sortedA?.every((value, index) => value === sortedB?.[index]);
  };

  const getObjj = () => {
    if (viewSwitchInput?.vault !== null) {
      return { vault_id: viewSwitchInput?.vault };
    } else {
      return { top_vault: viewSwitchInput?.top_vault };
    }
  };

  useEffect(() => {
    const vaultinfoo = getObjj();

    setVaultInfo(vaultinfoo);
  }, [viewSwitchInput]);

  const showValidationMsg = (msg: any) => {
    Swal.fire({
      title: "",
      text: msg,
      icon: "info",
    });
  };

  return (
    <div>
      {AddScenarioModel.isOpen && (
        <AddScenarioModal
          isOpen={AddScenarioModel.isOpen}
          onCloseModal={AddScenarioModel.close}
          setCount={setCount}
          setScenarioCount={setScenarioCount}
          getTableData={getTableData}
          setAddCount={setAddCount}
          getActiveVaultScenarios={getActiveVaultScenarios}
          hasScenarios={viewSwitchInput?.length > 1}
          title={title && title}
        />
      )}

      {decimalModal.isOpen && (
        <DecimalModal
          decimalPoints={decimalPoints}
          setDecimalPoints={setDecimalPoints}
          isOpen={decimalModal.isOpen}
          onCloseModal={decimalModal.close}
          setCount={setCount}
          setScenarioCount={setScenarioCount}
          getTableData={getTableData}
          setAddCount={setAddCount}
        />
      )}
      {addAssumptionModal.isOpen && (
        <AddAssumptionModal
          isOpen={addAssumptionModal.isOpen}
          onCloseModal={addAssumptionModal.close}
          permissionn={permission}

        //   setCounter={setCount}
        />
      )}
      {currencyModal.isOpen && (
        <CurrencyChangeDynamic
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
          setScenarioCount={setScenarioCount}
          setApiCallMain={setApiCallMain}
          apiCallMain={apiCallMain}
          currentCurrency={defaultCurrency}
        />
      )}

      {ScenarioAnalysis.isOpen && (
        <ScenarioAnalysisModel
          isOpen={ScenarioAnalysis.isOpen}
          onCloseModal={ScenarioAnalysis.close}
          scenarioIdd={scenarioId}
          setCount={setCount}
          graphModal={graphModal}
          finalCostData={finalCostData}
          vault={vault}
          checkboxvalue={checkboxvalue}
          setcheckboxvalue={setcheckboxvalue}
          getAnalysis={getAnalysis}
          selectedScenarios={selectedScenarios}
          setSelectedScenarios={setSelectedScenarios}
          disabledNodes={disabledNodes}
          setDisabledNodes={setDisabledNodes}
          title={title}
          datas={graphData}
          graphType={graphType}
          onCloseGraphModal={graphModal.close}
          topVault={topVault}
          handleGraphRemove={handleGraphRemove}
        />
      )}

      {permissionModal.isOpen && (
        <PermissionModal
          isOpen={permissionModal.isOpen}
          onCloseModal={permissionModal.close}
          shareScenario={shareScenario}
          getTableData={getTableData}
          finalCostData={finalCostData}
          vault={vault}
        />
      )}

      <Box>
        <Box className={styles.titleSection} sx={{ paddingBottom: "0.5rem" }}>
          <Box
            sx={{
              width: { lg: "34%", xl: "40%" },
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Scenario Costing ( {projectName} )
            </Typography>
            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box>{title} </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              columnGap: "0.8rem",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "0.5rem",
            }}
          >
            {listView && <ScenarioSearch viewSwitchInput={viewSwitchInput} />}

            {listView && (
              <>
                {ListLoading ? (
                  <Skeleton
                    variant="rounded"
                    color="inherit"
                    sx={{ height: "2rem", width: "18rem" }}
                  />
                ) : (
                  <Select
                    size="small"
                    variant="standard"
                    multiple
                    value={selectedScenarioss}
                    onChange={(event) => {
                      const value = event.target.value;

                      // Enforce a maximum of 5 items
                      if (value.length > 5) {
                        value.shift(); // Remove the first item if the length exceeds 5
                      }

                      setActiveScenarios(value.map((item: any) => item.id)); // Set selected scenario IDs
                    }}
                    onBlur={() => {
                      if (arraysAreEqual(activeScenarios, oldScenario)) {
                      } else {
                        ExactDebounceParameters(activeScenarios);
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected.length > 1) {
                        const lastSelected = selected[selected.length - 1]; // Get the last selected item
                        const remainingCount = selected.length - 1; // Count remaining items
                        return (
                          <>
                            <Chip
                              key={lastSelected.id}
                              label={lastSelected.name}
                              size="small"
                              sx={{
                                backgroundColor: "primary.light",
                                width: { lg: "11rem", xl: "12rem" },
                              }}
                            />
                            {remainingCount > 0 && ` +${remainingCount}`}{" "}
                            {/* Show count of additional items */}
                          </>
                        );
                      }

                      return selected.map((item: any) => (
                        <Chip
                          key={item.id}
                          label={item.name}
                          size="small"
                          sx={{
                            backgroundColor: "primary.light",
                            width: { lg: "12rem", xl: "12rem" },
                          }}
                        />
                      ));
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "30rem", // Set the maximum height of the dropdown options
                          overflow: "auto", // Enable scrolling when content exceeds maxHeight
                        },
                      },
                    }}
                    sx={{
                      width: "18rem",
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {allScenarios.map((option: any) => (
                      <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </>
            )}

            {!listView && (
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={showZeroRows}
                    onChange={handleChangeZeroRow}
                    color="primary"
                  />
                }
                label={"Show Zero Rows"}
              />
            )}

            {!listView &&
              (permission ? (
                <>
                  {viewSwitchInput > 1 ? (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                      title="Add Scenario"
                      disabled={getDisableStatus()}
                      onClick={() => AddScenarioHandler()}
                      sx={{
                        fontSize: "12px",
                        padding: "0.2rem 0.7rem",
                        minWidth: "1.5rem",
                        lineHeight: 1,
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      Add Scenario
                    </Button>
                  ) : (
                    <>
                      {costStatus && costStatus === true ? (
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={
                            <AddIcon sx={{ marginRight: "-0.5rem" }} />
                          }
                          title="Add Scenario"
                          disabled={getDisableStatus()}
                          onClick={() => AddScenarioHandler()}
                          sx={{
                            fontSize: "12px",
                            padding: "0.2rem 0.7rem",
                            minWidth: "1.5rem",
                            lineHeight: 1,
                            "&:hover": {
                              transform: "Scale(1.05)",
                              transition: "transform 0.5s ease",
                            },
                          }}
                        >
                          Add Scenario
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={
                            <AddIcon sx={{ marginRight: "-0.5rem" }} />
                          }
                          title="Add Scenario"
                          onClick={() => {
                            if (!permission || !permission?.includes("C")) {
                              showValidationMsg(
                                "You don't have permission to add scenario"
                              );
                            } else if (!costStatus || costStatus === false) {
                              showValidationMsg(
                                "Validate main costing to add scenario"
                              );
                            }
                          }}
                          sx={{
                            fontSize: "12px",
                            padding: "0.2rem 0.7rem",
                            minWidth: "1.5rem",
                            background: "#a2a6a3",
                            cursor: "pointer",
                            lineHeight: 1,
                            "&:hover": {
                              background: "#a2a6a3",
                              transform: "Scale(1.05)",
                              transition: "transform 0.5s ease",
                            },
                          }}
                        >
                          Add Scenario
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  title="Add Scenario"
                  onClick={() => {
                    if (!permission || !permission?.includes("C")) {
                      showValidationMsg(
                        "You don't have permission to add scenario"
                      );
                    }
                  }}
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.7rem",
                    minWidth: "1.5rem",
                    background: "#a2a6a3",
                    cursor: "pointer",
                    lineHeight: 1,
                    "&:hover": {
                      background: "#a2a6a3",
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  Add Scenario
                </Button>
              ))}

            {(AUTH.isSuperUser || AUTH.isProjectAdmin) && (
              <Button
                variant="contained"
                size="small"
                // disabled={getDisableStatus()}
                onClick={() => permissionModalHandler()}
                sx={{
                  fontSize: "12px",
                  padding: { lg: "0.5rem 1rem", xl: "0.43rem 1rem" },
                  minWidth: "1.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
              >
                Permissions
              </Button>
            )}

            <Button
              variant="contained"
              className={styles.downloadBtn}
              size="small"
              endIcon={<ArrowDropDownIcon sx={{ fontSize: "1rem" }} />}
              sx={{
                fontSize: "12px",
                minWidth: "3rem",
                lineHeight: 1,
                padding: "0.2rem 0.7rem",
              }}
              onClick={() => currencyModal.open()}
            >
              {sessionStorage.getItem("currency")}
            </Button>

            {scenarioImageLoading ? <CircularProgress size={18} /> : ScenarioImage?.images?.length > 0 ? (
              <>
                {/* @ts-ignore */}
                <RViewerJS
                  ref={viewerRef} // Attach the ref to access the viewer instance
                  imageUrls={ScenarioImage?.images?.map((item: any) => item?.value)}
                >
                  <Box>
                    <Box>
                      {ScenarioImage?.images?.map((item: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              position: "relative",
                              cursor: "pointer",
                              width: "auto", // Set a minimum width for each image container
                              height: "auto", // Ensure height is consistent across images
                            }}
                          >
                            <Image
                              src={item?.image}
                              style={{
                                width: "2rem",
                                height: "2rem",
                                objectFit: "contain",
                                borderRadius: "5px",
                                cursor: "pointer",
                                display:
                                  index + 1 > 1 ? "none" : "block",
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </RViewerJS>
              </>
            ) : (
              ""
            )}

            {allVaultScenarios.length > 1 && <Box>
              <Badge
                badgeContent={selectedValues.length > 0 ? selectedValues.length : null}
                color="error"
              >
                <IconButton
                  title="Filter Scenario"
                  aria-describedby={id}
                  onClick={handleClick}
                  sx={{ padding: '3px', color: 'primary.main', marginTop: '-3px', marginRight: '-3px' }}
                >
                  <FilterAltIcon sx={{ cursor: 'pointer' }} />
                </IconButton>
              </Badge>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  zIndex: 1300, // Ensure Popover appears above other elements
                }}
              >
                <Box sx={{ padding: '0.5rem', maxWidth: '300px', overflow: 'auto' }}>
                  <Typography sx={{ fontSize: '1rem' }}>Select Scenario to filter (Max 5 Items)</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="multi-select-label"
                      multiple
                      size="small"
                      variant="standard"
                      value={selectedValues}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        const displayedValues = selected.slice(0, 2);
                        const remainingCount = selected.length - displayedValues.length;
                        return (
                          <>
                            {displayedValues.map((item: any) => (
                              <Chip key={item.id} label={item.name} sx={{ margin: '0.2rem' }} />
                            ))}
                            {remainingCount > 0 && (
                              <Chip label={`+${remainingCount}`} sx={{ margin: '0.2rem', backgroundColor: 'lightgray' }} />
                            )}
                          </>
                        );
                      }}
                      sx={{
                        width: '18rem',
                        '&:before': {
                          borderBottomColor: 'primary.light',
                        },
                        '& .MuiSelect-select': {
                          fontSize: '1rem',
                        },
                        '.MuiSvgIcon-root': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      {allVaultScenarios.map((option: any) => (
                        <MenuItem
                          key={option.id}
                          value={option}
                          sx={{
                            backgroundColor: selectedValues.some((selected: any) => selected.id === option.id)
                              ? 'rgba(0, 123, 255, 0.2)'
                              : 'transparent',
                            '&:hover': {
                              backgroundColor: selectedValues.some((selected: any) => selected.id === option.id)
                                ? 'rgba(0, 123, 255, 0.3)'
                                : 'rgba(0, 0, 0, 0.08)',
                            },
                          }}
                        >
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '1rem',
                      marginTop: '1rem',
                      width: '100%',
                    }}
                  >
                    <Button size="small" onClick={clearFilter} disabled={selectedValues.length === 0}>
                      Clear Filter
                    </Button>
                    <Button variant="contained" size="small" onClick={applyFilter} disabled={selectedValues.length === 0}>
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>}

            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.8rem",
                }}
              >

                {viewSwitchInput?.is_product_or_system && (
                  <SwitchInput
                    label="List View"
                    checked={listView}
                    setChecked={setListView}
                  />
                )}

                {!listView && (
                  <img
                    src={analysisIcon}
                    alt="Analysis"
                    style={{ height: "1.5rem", cursor: "pointer" }}
                    onClick={() => {
                      AnalysisHandler();
                    }}
                  />
                )}

                {toggleHighlight ? (
                  <img
                    src={highlighter}
                    alt="Highlighter"
                    style={{ height: "2rem", cursor: "pointer" }}
                    onClick={() => setToggleHighlight(false)}
                  />
                ) : (
                  <img
                    src={highlighterdisabled}
                    alt="Highlighter"
                    style={{ height: "2rem", cursor: "pointer" }}
                    onClick={() => setToggleHighlight(true)}
                  />
                )}

                {
                  <DownloadIcon
                    sx={{ fontSize: "2rem", cursor: "pointer" }}
                    onClick={() => downloadHandler()}
                  />
                }

                {warningMsg?.is_delta == true &&
                  warningMsg?.updated == false && (
                    <Button
                      variant="contained"
                      className={styles.downloadBtn}
                      size="small"
                      style={{
                        fontSize: "12px",
                        minWidth: "3rem",
                        lineHeight: 1,
                        padding: "0.45rem 0.7rem",
                      }}
                      onClick={() => DeltaHandler(warningMsg)}
                    >
                      Delta Update
                    </Button>
                  )}
                {permission && permission?.includes("R") && (
                  <CreditCardIcon
                    sx={{
                      color:
                        assumptionsCardCount > 0 ? "green" : "primary.main",
                      fontSize: { lg: "2.5rem", xl: "2.2rem" },
                      cursor: "pointer",
                    }}
                    onClick={() => addAssumptionModal.open()}
                    titleAccess={`Assumption Card: ${assumptionsCardCount && assumptionsCardCount
                      } Revisions: ${counts && counts}`}
                  />
                )}


              </Box>
            )}

            <Box display="flex" alignItems="center" gap={1}>
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 40, // Adjust the width as needed
                }}
              >
                <InputLabel
                  id="decimal-points-label"
                  shrink
                  sx={{ color: "primary.main", marginTop: "0.2rem" }}
                >
                  Dec.P
                </InputLabel>
                <Select
                  labelId="decimal-points-label"
                  variant="standard"
                  value={decimalPoints} // Ensure value is a number
                  onChange={(e: any) => {
                    const selectedValue = Number(e.target.value);
                    setDecimalPoints(selectedValue);
                    localStorage.setItem("decimalPoint", String(selectedValue));
                  }}
                  sx={{
                    fontSize: "0.9rem",
                    textAlign: "center", // Center align the text
                    ".MuiSelect-select": {
                      padding: 0, // Remove padding
                      textAlign: "center", // Center align inside the dropdown
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #000", // Optional: Customize the border
                    },
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                    {
                      fontSize: "1rem",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Decimal Precision" }}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {!listView && !isFilterActive && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (currentPage + 1 > 1) {
                      const newPage = currentPage - 1;
                      setCurrentPage(newPage);
                    }
                  }}
                  style={{
                    color: currentPage + 1 <= 1 ? "grey" : "#007BFF",
                    cursor: "pointer",
                  }}
                  disabled={currentPage + 1 <= 1}
                >
                  <ArrowBackIosIcon
                    sx={{ fontSize: "1.25rem", cursor: "pointer" }}
                  />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1.1rem",
                    marginLeft: "-0.2rem",
                    color: "#616161",
                  }}
                >
                  {currentPage + 1}
                </Typography>
                <IconButton
                  disabled={currentPage + 1 == totalCount}
                  onClick={() => {
                    const newPage = currentPage + 1;
                    setCurrentPage(newPage);
                  }}
                  style={{
                    color: currentPage + 1 == totalCount ? "grey" : "#007BFF",
                    cursor: "pointer",
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "1.25rem" }} />
                </IconButton>
              </div>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "0.5rem 1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={styles.sideTitle}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box
            sx={{
              width: { lg: "95%", xl: "96%" },
              height: { lg: "86vh", xl: "89vh" },
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box>
            ) : listView == true ? (
              <ScenarioCostingPageListView
                totalCount={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setTotalcount={setTotalcount}
                costDetailsData={costDetailsData}
                addCount={addCount}
                decimalPoints={decimalPoints}
                scenarioCostHeaderData={viewSwitchInput}
                setDecimalPoints={setDecimalPoints}
                selectedScenarios={activeSelectedScenarios}
                toggleHighlight={toggleHighlight}
                setListLoading={setListLoading}
                apiCallMain={apiCallMain}
                getActiveVaultScenarios={getActiveVaultScenarios}
              />
            ) : listView == false ? (
              <ScenarioCostingPage
                count={count}
                setStatus={setStatus}
                scenarioDashboardOpen={scenarioDashboardOpen}
                setScenarioDashboardOpen={setScenarioDashboardOpen}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                setTotalcount={setTotalcount}
                setScenarioCount={setScenarioCount}
                scenarioCount={scenarioCount}
                selectedCategory={selectedCategory}
                costDetailsData={costDetailsData}
                addCount={addCount}
                setAddCount={setAddCount}
                decimalPoints={decimalPoints}
                setDecimalPoints={setDecimalPoints}
                toggleHighlight={toggleHighlight}
                updateFinalCostChartData={updateFinalCostChartData}
                selectedScenarios={activeSelectedScenarios}
                scenarioCostHeaderData={viewSwitchInput}
                showZeroRows={showZeroRows}
                filterScenario={activeVaultScenarios}
                isFilterActive={isFilterActive}
                getActiveVaultScenarios={getActiveVaultScenarios}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
