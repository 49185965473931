// react

import axios from "axios";
import { API } from "../../../../api-services";
import { MODULES } from "../../../../Constants/modules.constants";
import { s3Url } from "../../../../utlis/s3url.utils";
import { getVideoCover } from "../../../../CustomHook/CreateVideoThumbnail";
const IdeaImageSingleUpload = (
  fileList: any,
  modName: any,
  paramId: any,
  top_vault: any,
  vault: any,
  setApiCallVaultData:any,
  setIsUpdating:any,
  ideaId:any,
  type:any
) => {
  // const file_name = file?.name;
//   modName === "Idea" 
  const formData = new FormData();
  var random: any = Math.floor( Math.random() * 1000000 + 1 );
  // const dispatch = useAppDispatch();
  // + "-" + uuidv4();
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
    product: top_vault == 0 ? vault : top_vault,
  },0).then((res) => {
    const data = fileList?.map((file: any, index: number) => {
      formData.append(
        "key",
        `${modName}/${top_vault}/${ideaId}/${vault ? vault : "Vehicle"}/${random}/${file.name || "editedImageName"}`
      );
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          if (res.status === 204) {
            if(type=="video") {
               const data = new FormData();
               // get the frame at 1.5 seconds of the video file
                const cover = await getVideoCover( file, 1.5 );
                try {
                  // get the frame at 1.5 seconds of the video file
                  const cover = await getVideoCover(file, 1.5);
                  //@ts-ignore
                  formData.set("file", cover, cover?.name);
                  formData.set("key", `${modName}/${top_vault}/${ideaId}/${vault ? vault : "Vehicle"}/${random}/${file.name.replace(".mp4", ".jpeg")}`);
                  formData.set("Content-Type", "image/jpeg");
                  axios.post(s3Url, formData);
                  // print out the result image blob
                } catch (ex) {
                  console.log("ERROR: ", ex);
                }
               //  @ts-ignore
                data.append("thumbnail", `/${modName}/${top_vault}/${ideaId}/${vault ? vault : "Vehicle"}/${random}/${file.name.replace(".mp4", ".jpeg")}`);
                data.append( "video", `/${modName}/${top_vault}/${ideaId}/${vault ? vault : "Vehicle"}/${random}/${file.name || "editedImageName"}`); 
                data.append("parameter_id",paramId)

                API.put(`/idea/pool/${ideaId}/`,data,{video:true},0).then((res:any)=>{
                  setApiCallVaultData((prev:any)=>!prev);            
                  setIsUpdating(false);
                }).catch((err:any)=>{})
            } else{
            const data = new FormData();
            data.append(
              `${paramId}`,
              `/${modName}/${top_vault}/${ideaId}/${vault ? vault : "Vehicle"}/${random}/${file.name || "editedImageName"}`
            );

            API.put(`/idea/pool/${ideaId}/`,data,{image:true,rgb:false},0).then((res:any)=>{
               setApiCallVaultData((prev:any)=>!prev);            
              setIsUpdating(false);
              }).catch((err:any)=>{             
             });
            }
            //   const action = await dispatch(
            //     updateIdeaImage({
            //       id: ideaId,
            //       parameterId: paramId,
            //       image: `/${modName}/${top_vault}/${vault}/${paramId}${file.name}`,
            //       rgb: false,
            //     })
            //   );
            //   updateIdeaImage.fulfilled.match(action);
            //   setIsUpdating(false);
            //   modName === "Idea" && setIsDisable(false);
            
          }
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default IdeaImageSingleUpload;
