


import React, { useEffect, useState } from 'react'
import { API } from '../../../api-services';
import { Box, Checkbox, Skeleton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';

function ToolDB({ database, selected, setSelected}:any) {
  const { height, width } = useWindowDimensions();
  const [categoryList, setCategoryList] = React.useState<any>([]);
    const [tabValue, setTabValue] = useState<any>(null);
     const [tableData, setTableData] = React.useState<any>([]);
    const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
    const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

    const isUrl = (url: any) => {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    };

  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setTabValue(res?.data[0]?.id);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };

    useEffect(() => {
      getCategoryList(database);
    }, [database]);

    const getToolTableData = (categoryName:any) => {
      setTabledataLoading(true)
      API.get("/api/db/tool/", {
            commodity: categoryName,
          })
            .then((res: any) => {
              
              setTableData(res?.data);
            })
            .catch((err: any) => {
              
              console.log("Sever EEror");
            }).finally(()=> {
              setTabledataLoading(false)
            });
    }

    useEffect(()=> {
      getToolTableData(tabValue);
    },[tabValue])

    const handleChange = (categoryName: any) => {
      setTabValue(categoryName);
    };



    let columns: any = [
        {
                field: "actions",
                headerName: "Select",
                width: 100,
                renderCell: (cellValues: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        columnGap: "1rem",
                      }}
                    >
                      <Checkbox
                        checked={selected?.id == cellValues?.row?.id}
                        onChange={() => setSelected(cellValues?.row)}
                      />
                    </Box>
                  );
                },
              },
      {
        field: 'sno',
        headerName: 'SNo.',
        width: width > 1370 ? 50 : width < 900 ? 50 : 50,
      },
      {
        field: 'toolCode',
        headerName: 'Tool Code',
        width: width > 1370 ? 120 : 100,
      },
      {
        field: 'toolName',
        headerName: 'Tool Name',
        headerAlign: 'left',
        //   align: 'right',
        minWidth: width > 1370 ? 100 : width < 900 ? 120 : 200,
        flex: 1,
      },
      {
        field: 'toolCost',
        cellClassName: 'super-app-theme--cell',
        headerName: 'Tool Cost',
        minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <Box title={`Tool cost : ${cellValues?.row?.toolCost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {cellValues?.row?.toolCost}
            </Box>
          );
          }
      },
      {
        field: 'specification',
        headerName: 'Specification',
        width: width > 1370 ? 120 : 100,
        //   flex: 1,
      },
      {
        field: 'category',
        headerName: 'Category',
        minWidth: width > 1370 ? 120 : 80,
      },
      {
        field: 'supplier',
        headerName: 'Supplier',
        width: width > 1370 ? 100 : 80,
      },
      {
        field: 'material',
        headerName: 'Material',
        minWidth: width > 1370 ? 150 : 120,
        flex: 1,
      },
      {
        field: 'other',
        headerName: 'Other',
        minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120,
        flex: 1,
      },
      {
        field: 'currency_code',
        headerName: 'Currency/Unit',
        sortable: false,
        minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
        renderCell: (cellValues: any) => {
  
  
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
              {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
            </Box>
          );
        },
      },
      {
        field: 'life',
        headerName: 'Life (No. of Parts)',
        minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
        flex: 1,
      },
    
      {
        field: 'toolCostPerPart',
        cellClassName: 'super-app-theme--cell',
        headerName: 'Tool Cost/Part (Curr/Part)',
        minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <Box title={`Tool cost per part : ${cellValues?.row?.toolCostPerPart}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {cellValues?.row?.toolCostPerPart}
            </Box>
          );
          }
      },
      // {
      //   field: 'reference',
      //   headerName: 'Reference',
      //   minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      //   renderCell: (cellValues: any) => {
      //     return (
      //       <>
      //         {isUrl(cellValues?.row?.reference) ? (
      //           <span
      //             style={{ textDecoration: 'underline', cursor: 'pointer' }}
      //             onClick={() =>
      //               handleOpenLinkReference(cellValues?.row?.reference)
      //             }>
      //             Link
      //           </span>
      //         ) : (
      //           <span
      //           //  style={{ textDecoration: "underline", cursor: "pointer" }}
      //           //  onClick={() =>
      //           //    handleOpenLinkReference(cellValues?.row?.reference)
      //           //  }
      //           >
      //             {cellValues?.row?.reference}
      //           </span>
      //         )}
      //       </>
      //     );
      //   },
      // },
    ];
  
    // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid
  
    let rows: any =
      tableData &&
      tableData?.map((ideaItem: any, index: any) => {
        let objShared: any = {
          id: ideaItem?.id,
          sno: index + 1,
          toolCode: ideaItem?.code,
          toolName: ideaItem?.name,
          specification: ideaItem?.specification,
          category: ideaItem?.category,
          supplier: ideaItem?.supplier,
          material: ideaItem?.material,
          other: ideaItem?.other,
          life: ideaItem?.shots,
          toolCost: ideaItem?.price,
          toolCostPerPart: (
            ideaItem?.price / (ideaItem?.shots == 0 ? 1 : ideaItem?.shots)
          ).toFixed(2),
          reference: ideaItem?.details,
          flag: ideaItem?.flag,
          commodity: ideaItem?.commodity_name,
          mapped_status: ideaItem?.mapped_status,
          currency_id: ideaItem?.currency_id,
          currency_code: ideaItem?.currency_code,
          unit: ideaItem?.unit,
          lock: ideaItem?.lock,
        };
        return objShared;
      });
  
    // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props
  
    let dataStructureDataGrid: any = {
      columns: columns,
      rows: rows,
    };

      // console.log(tableData, "parameter");
      // console.log(rows, "parameter");

  return (
    <Box sx={{ display: "flex", gap: "2%" }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        columnGap: "0.5rem",
        width: "19%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
        marginLeft: "1rem",
        maxHeight:"80vh"
      }}
      
      adl-scrollbar="true"
      adl-scrollbar-width="0.2"
    >
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          {categoryList &&
            categoryList?.map((item: any, index: any) => {
              // console.log(item?.id == tabValue, "parameter");
              
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      padding:"0.2rem 0.5rem",
                  
                      // height: "2rem",
                      // minHeight: "2.1rem",
                      maxWidth: "100% !important",
                      
                      fontSize: "1rem",
                      width: "100%",
                      backgroundColor:
                        item?.id == tabValue ? "primary.main" : "",
                      
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "0.1px solid #edf5ef",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          item?.id == tabValue
                            ? "white"
                            : "primary.main",
                        // height: "2rem",
                        // minHeight: "2.1rem",
                        maxWidth: "100% !important",
                        padding: "0.1rem 0rem",
                        fontSize: "1rem",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleChange(item?.id)}
                    >
                      {item?.name?.toLowerCase()} ({item?.count})
                    </Typography>
                  </Box>
                </>
              );
            })}
        </>
      )}
    </Box>

    <Box
      sx={{
        flex: 1,
        height: { lg: "84vh", xl: "85vh" },
        width: "100%",
        "& .super-app-theme--cell": {
          backgroundColor: "#ddefff !important",
          textAlign: "center !important",
        },
      }}
    >
      <DataGrid
        headerHeight={42}
        rowHeight={36}
        {...dataStructureDataGrid}
        loading={tableDataLoading}
        // components={{
        //   Toolbar: () => {
        //     return CustomToolbar();
        //   },
        // }}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </Box>
  </Box>
  )
}

export default ToolDB