// react
import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";




const ParameterDataBaseS3Doc = async (
   fileList: any,
   
   datatype:any,
   setFileUrl:any,
   setImageLoader:any,
   

) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );


   var obj: Array<any> = [];
   const sortAlphaNum = ( a: any, b: any ) => a.localeCompare( b, 'en', { numeric: true } );
   var img: any = "";
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
        setImageLoader(true)
      API.get( "cad/cad_upload/", {
         module: MODULES.CAD,
      },0 ).then( res => {

         const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append(
               "key",
               `inward/${datatype}/${random}/${file.name}`
            );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
               "X-Amz-Signature",
               res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );

            axios
               .post( s3Url, formData, {
                  onUploadProgress: data => {
                   
                     //Set the progress value to show the progress bar
                  },
               } )
               .then(  ( res: any ) => {
               //  console.log("dchidcdcidcihdicdhicdihc",res.status)
                  if ( res.status === 204 ) {
                    setImageLoader(false)

                    
                     var Url=`/inward/${datatype}/${random}/${file?.name}`
                     setFileUrl(Url)
                  //   console.log(file,"filefilefile")
                    // console.log("dchidcdcidcihdicdhicdihc",res.status)
                  
                    // `/inward/${parametername}/${random}/${file.name}`
                  }
               } );
         } );
      } );
   

};

// ----------------------------------------------------------------------------------

export default ParameterDataBaseS3Doc;
