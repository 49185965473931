import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Slide,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import Modal from "@mui/material/Modal";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
// styles
// import styles from "./ScanQRModal.module.scss";
// import styles from "../../ComponentUI/Header/BOMCostScanQRModal.module.scss";
import styles from "../../../Header/BOMCostScanQRModal.module.scss";
import { TransitionProps } from "@mui/material/transitions";
// import { useHistory, useRouteMatch } from "react-router-dom";
// import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
// import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
// import { useBaseModal } from "../SearchPage/useBaseModal";
// import BOMImageSingleUpload from ".././BOMCreate/BOMImageSingleUpload";

import { LoadingButton } from "@mui/lab";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import RViewerJS from "viewerjs-react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SearchIcon from "@mui/icons-material/Search";
import swal from "sweetalert";
import { useHistory, useRouteMatch } from "react-router-dom";
import useWindowDimensions from "../../../../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import { API } from "../../../../api-services";
import BOMImageSingleUpload from "../../BOMImageSingleUpload";
import ErrorModalFilesMail from "../../../ProjectMailModule/ErrorModalFilesMail";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
// import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BOMImageUploadMobileProps {
  isOpen: boolean;
  onClosess: any;
  changeRootId?: any;
}

interface Params {
  projectid: any;
  topVault: any;
  vaultId: any;
  vaultName: any;
}

const BOMImageUploadMobile: React.FC<BOMImageUploadMobileProps> = (props) => {
  const { isOpen, onClosess, changeRootId } = props;
  const { url } = useRouteMatch<any>();
  const { projectid, topVault, vaultId, vaultName } = useRouteParams<Params>();
  const scannerAPIDataRef = useRef<any>([]);
  const partClickEventDataRef = useRef<any>(undefined);
  const { height, width } = useWindowDimensions();
  const [openQRScanner, setOpenQRScanner] = React.useState(false);
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();
  const [openEntryMobile, setOpenEntryMobile] = React.useState(false);
  const [vaultData, setVaultData] = React.useState<any>();
  const [searchVaultsOptions, setSearchVaultsOptions] = useState<any>([]);
  const timeout = useRef<any>(null);
  const [AssemblyVaultData, setAssemblyVaultData] = React.useState<any>();
  const [weight, setWeight] = React.useState<any>();
  const [weightDetails, setWeightDetails] = React.useState<any>();

  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [id, setIsID] = React.useState<number>(0);
  const [isUpdating, setIsUpdating] = React.useState<any>(false);
  const [isUpdatingIconName, setIsUpdatingIconName] = React.useState<any>("");
  const [apiCallVaultData, setApiCallVaultData] = React.useState<any>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<any>(false);
  const [actions, setActions] = React.useState<any>()
  // const [projectId, setProjectId] = React.useState<any>();
  // const [showQRSCanner, setShowQRSCanner] = React.useState<any>(true);

  const inputElRef = useRef<any>();
  const inputElRefPhone = useRef<any>();

  const ErrorModalFiles = useBaseModal();
  const inputRef = React.useRef<any>(null);


  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers

  useEffect(() => {
    if (vaultId) {
      setVaultData(undefined);
      setWeight(undefined);
      setWeightDetails(undefined);

      API.get(
        `/xcpep/bom_sequence_image/`,
        {
          vault: vaultId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setVaultData(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });

      API.get(
        `/xcpep/bom_create_view/`,
        {
          vault: vaultId,
          weight: true,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setWeight(res.data?.weight);
          setWeightDetails(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });

      API.get(
        `/auth/calibration_user_permission/`,
        {
          action: true,
          bom_create: true,
          vault: vaultId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setActions(res.data?.action);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
    }
  }, [vaultId]);

  useEffect(() => {
    if (vaultId && id != 0) {
      API.get(
        `/xcpep/bom_sequence_image/`,
        {
          vault: vaultId,
          parameter_id: vaultData?.image_id,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setVaultData(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
    }
  }, [vaultId, apiCallVaultData]);

  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (id == 1) {
      weightDetails && BOMImageSingleUpload(
        [file],
        weightDetails?.["component_details"],
        vaultData?.image_id,
        vaultId,
        topVault,
        setApiCallVaultData,
        apiCallVaultData,
        vaultData?.next,
        setIsUpdating,
        callAPIOfNextImage,
      );
    } else {
      const data = new FormData();
      weightDetails && data.append(vaultData?.image_id, file);
      weightDetails && API.put(`/xcpep/component_details/${weightDetails?.["component_details"]}/`, data, { vault: vaultId, image: true, rgb: true }, 0).then((res: AxiosResponse) => {
        setIsUpdating(false);
        vaultData?.next ? callAPIOfNextImage('next') : setApiCallVaultData(!apiCallVaultData);
      }).catch((err: AxiosError) => {

      });
    };
  };

  // -----------------------------------------------------------------------------------------------------

  const browseFilesPhone = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRefPhone.current?.click?.();
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
  };

  const handleInputClickPhone = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------

  const browseFiles = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRef.current?.click?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------------

  const handleWeightChange = (e: any) => {
    e.stopPropagation();
    setWeight(e.target.value);
  };

  const handleWeightAPICall = (event: any) => {
    weightDetails &&
      API.put(
        `/xcpep/component_details/${weightDetails?.["component_details"]}/`,
        { [weightDetails?.["parameter_id"]]: Number(weight) },
        {
          vault: vaultId,
        },
        0
      )
        .then((res: AxiosResponse) => { })
        .catch((err: AxiosError) => {
          setErrorModalFileMsg(err.response?.data[0]);
          ErrorModalFiles.open();
        });
  };

  //----------------------------------------------------------------------------------------------------------------

  const handleDeleteSelectedImages = async (e: React.MouseEvent) => {
    e.stopPropagation();
    swal({
      title: `Delete Image Parameter`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Image`,
          value: `deleteImages`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteImages":
          setDeleteLoading(true);
          weightDetails &&
            API.put(
              `/xcpep/component_details/${weightDetails?.["component_details"]}/`,
              { [vaultData?.image_id]: null },
              {
                vault: vaultId,
                image: true,
                rgb: true,
              },
              0
            )
              .then((res: AxiosResponse) => {
                setApiCallVaultData(!apiCallVaultData);
                setDeleteLoading(false);
              })
              .catch((err: AxiosError) => { });
          break;
        default:
          swal(`Image saved from deleting`, { icon: "error" });
      }
    });
  };


  const callAPIOfNextImage = (type: any) => {
    if (type == "prev") {
      vaultData?.prev &&
        API.get(
          `/xcpep/bom_sequence_image/`,
          {
            vault: vaultId,
            parameter_id: vaultData?.prev,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setVaultData(res.data);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
    } else {
      vaultData?.next &&
        API.get(
          `/xcpep/bom_sequence_image/`,
          {
            vault: vaultId,
            parameter_id: vaultData?.next,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setVaultData(res.data);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------------------

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe) {
      vaultData?.prev != null && callAPIOfNextImage("prev");
    } else if (isLeftSwipe) {
      vaultData?.next != null && callAPIOfNextImage("next");
    }
    // add your conditional logic here
  };


  // const BOMMobileEntryHandler = () => {
  //   setOpenEntryMobile(true);
  // };


  // const handleCloseModal = () => {
  //   scannerAPIDataRef.current = [];
  //   partClickEventDataRef.current = undefined;
  //   setSearchVaultsOptions([]);
  //   setVaultData(undefined);
  //   setOpenEntryMobile(false);    
  // };

  // const redirectToBOMPage = (type: string,) => {
  //   if (type == "mobile") {
  //     BOMMobileEntryHandler();
  //   } else {
  //     if (partClickEventDataRef.current == undefined) {
  //       sessionStorage.setItem(
  //         "BOMCreateNode",
  //         JSON.stringify(scannerAPIDataRef.current?.ancestors)
  //       );
  //       history.push(
  //         `/bomentry/${scannerAPIDataRef.current?.project}/${scannerAPIDataRef.current?.project_name}/${scannerAPIDataRef.current?.top_vault
  //         }/${scannerAPIDataRef.current?.id}/${scannerAPIDataRef.current?.abbreviation == null
  //           ? "none"
  //           : scannerAPIDataRef.current?.abbreviation
  //         }/0/${scannerAPIDataRef.current?.is_leaf}/${scannerAPIDataRef.current?.is_copy}`
  //       );
  //     } else {
  //       sessionStorage.setItem(
  //         "BOMCreateNode",
  //         JSON.stringify(partClickEventDataRef.current?.ancestors)
  //       );
  //       history.push(
  //         `/bomentry/${partClickEventDataRef.current?.project}/${partClickEventDataRef.current?.project_name}/${partClickEventDataRef.current?.top_vault
  //         }/${partClickEventDataRef.current?.id}/${partClickEventDataRef.current?.abbreviation == null
  //           ? "none"
  //           : partClickEventDataRef.current?.abbreviation
  //         }/0/${partClickEventDataRef.current?.is_leaf}/${partClickEventDataRef.current?.is_copy}`
  //       );
  //     }
  //   }

  // };


  // const handleClickOutside = (event: any) => {
  //   // if (!isUndefined(projectCount) && projectCount > 0)

  //   {

  //     {


  //       inputRef.current.style.display = "none";
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, false);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, false);
  //   };
  // }, []);

  useEffect(() => {
    if (vaultId != null) {
      API.get(`/xcpep/vault_descendants/`, { vault: vaultId }, 0)
        .then((res) => {
          setAssemblyVaultData(res.data[0]);
          // redirectToBOMPage("mobile");
        }).catch((err: AxiosError) => {
          setSearchVaultsOptions([]);
          const { data }: any = err?.response;
          setErrorModalFileMsg(err.response?.data[0]);
          ErrorModalFiles.open();
        })
    }
  }, [vaultId]);

  // ----------------------------------------------------------------------------------

  return (
    <>

      {/* {<Box className={styles.child} onClick={(e) => handleClickOutside(e)}> */}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: '100%', padding: '2rem 0' }}>
        <ErrorModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
        />
        {width < '720' ? <Box sx={{ padding: "0rem", width: { xs: "22rem", sm: "34rem" }, overflow: "hidden", }}>
          <Box sx={{ width: '100%', alignItems: "center", }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
              }}
            >  BOM Entry Assembly Image Upload  </Typography>
            {/* <Typography style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
            }}>({vaultName}) </Typography> */}
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem 1rem" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.2rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                  Part Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "11rem",
                  }}
                >
                  :{" "}
                  {AssemblyVaultData && AssemblyVaultData?.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.2rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                  Part Number
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  :{" "}
                  {AssemblyVaultData && AssemblyVaultData?.part_no}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.2rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                  Weight <span style={{ color: "red" }}>*</span>
                </Typography>
                <div style={{ fontSize: "1rem", width: "60%" }}>
                  :{" "}
                  <TextField
                    type="number"
                    variant="standard"
                    placeholder="Weight"
                    disabled={actions?.includes('C') || actions?.includes('U') ? false : true}
                    size="small"
                    value={weight}
                    onChange={handleWeightChange}
                    onBlur={handleWeightAPICall}
                    inputProps={{
                      step: "any",
                      min: 0,
                      style: { fontSize: "1rem", marginLeft: "0.5rem" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      color: "primary",
                    }}
                    sx={{
                      width: "70%",
                      borderBottomColor: "primary.light",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                        paddingBottom: "0rem",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />{weightDetails && weightDetails?.parameter_unit}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.2rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                  Quantity
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  :{" "}
                  {AssemblyVaultData && AssemblyVaultData?.quantity}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "1.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <LoadingButton
                  disabled={vaultData?.prev == null}
                  onClick={() => callAPIOfNextImage("prev")}
                >
                  <ArrowCircleLeftRoundedIcon />
                </LoadingButton>
                <Typography sx={{ fontSize: "1rem" }}>
                  {vaultData?.image_name}
                  {vaultData?.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                </Typography>
                <LoadingButton
                  disabled={vaultData?.next == null}
                  onClick={() => callAPIOfNextImage("next")}
                >
                  <ArrowCircleRightRoundedIcon />
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "18rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                {/* @ts-ignore */}
                {vaultData?.image_url ? (<RViewerJS>
                  <img
                    src={vaultData?.image_url}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "18rem",
                      maxWidth: "78vw",
                    }}
                  />
                </RViewerJS>
                ) : (
                  <span onClick={() => actions?.includes('C') && browseFilesPhone(1, "OriginalCamera")}>
                    No Image Available
                  </span>
                )}
              </Box>
              <Box sx={{ margin: "1rem 0" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                    justifyContent: "center",
                  }}
                >

                  {actions?.includes('U') && <LoadingButton
                    startIcon={
                      <UploadIcon
                        titleAccess="Upload Original Background"
                        sx={{ cursor: "pointer", marginRight: "-1rem" }}
                      />
                    }
                    sx={{
                      padding: 0, minWidth: "2rem",
                      '.MuiCircularProgress-root': {
                        color: 'primary.main',
                      },
                    }}
                    onClick={() =>
                      browseFiles(1, "OriginalWithBackgroudGallery")
                    }
                    loading={
                      isUpdatingIconName == "OriginalWithBackgroudGallery" &&
                      isUpdating
                    }
                  >
                  </LoadingButton>}
                  {actions?.includes('U') && <LoadingButton
                    startIcon={
                      !isUpdating && <UploadIcon
                        titleAccess="Upload without Background"
                        sx={{
                          cursor: "pointer",
                          marginRight: "-1rem",
                        }}
                      />
                    }
                    sx={{
                      padding: 0, minWidth: "2rem",
                      color: "#999191",
                      '.MuiCircularProgress-root': {
                        color: 'primary.main',
                      },
                    }}
                    onClick={() =>
                      browseFiles(2, "OriginalWithoutBackgroudGallery")
                    }
                    loading={
                      isUpdatingIconName == "OriginalWithoutBackgroudGallery" && isUpdating
                    }
                  ></LoadingButton>}
                  {actions?.includes('U') &&
                    <LoadingButton
                      startIcon={
                        <CameraAltRoundedIcon
                          titleAccess="Camera"
                          sx={{
                            cursor: "pointer",
                            marginRight: "-1rem",
                          }}
                        />
                      }
                      sx={{
                        padding: 0, minWidth: "2rem",
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      onClick={() => browseFilesPhone(1, "OriginalCamera")}
                      loading={
                        isUpdatingIconName == "OriginalCamera" && isUpdating
                      }
                    ></LoadingButton>}
                  {!vaultData?.required && vaultData?.image_url && actions?.includes('D') && (
                    <LoadingButton
                      startIcon={
                        <DeleteIcon
                          titleAccess="Delete"
                          sx={{ cursor: "pointer", marginRight: "-1rem" }}
                        />
                      }
                      sx={{
                        padding: 0, minWidth: "2rem",
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      onClick={handleDeleteSelectedImages}
                      loading={deleteLoading}
                    ></LoadingButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <input
            type="file"
            style={{ display: "none" }}
            // required
            accept="image/*"
            id="file"
            name="file"
            ref={inputElRefPhone}
            onChange={handleChangePhone}
            onClick={handleInputClickPhone}
            capture="user"
          />

          <input
            type="file"
            style={{ display: "none" }}
            // required
            accept="image/*"
            id="file"
            name="file"
            ref={inputElRef}
            onChange={handleChange}
            onClick={handleInputClick}
          />
        </Box> : ''
        }
      </Box>
      {/* </Box>} */}



    </>
  );
};

export default BOMImageUploadMobile;
