import * as React from "react";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import styles from "./CostingConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import guidelines from "../../Assets/images/guidelines.svg";
import calculator from "../../Assets/images/calculator.svg";
import { useEffect, useRef, useState } from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import validatedGuidelinesImg from "../../Assets/images/guidelines_green.svg";
import validatedCalculatorImg from "../../Assets/images/calculator_green.svg";
import CostingConfigurationDrawer from "./CostingConfigurationDrawer";
import { CostingTemplateCategory } from "./CostingTemplateCategory";
import Swal from "sweetalert2";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import PerformCostingProjectModal from "./PerformCostingProjectModal";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShareIcon from '@mui/icons-material/Share';
import { ShareInstanceModal } from "../../ComponentUI/CostingScenarios/ShareInstanceModal";

export interface ICostingTemplateConfigProps { }

type Anchor = "top" | "left" | "bottom" | "right";

export const PermissionData = React.createContext<any>(null);

export function CostingTemplateConfig(props: ICostingTemplateConfigProps) {
  const inputConfigUpload = useRef<any>(null);
  const shareModal=useBaseParamsModal();
  const openPerformCostingModal = useBaseParamsModal();
  const { projectname, projectId, productId,costingType } = useRouteParams<any>();
  const [category, setCategory] = React.useState<any>({});
  const [parentCount,setParentCount]=useState<any>(0);
  const [uploadLoading, setUploadLoading] = React.useState<any>(false);

  // expand collapse 
  const [expanded, setExpanded] = useState<string | false>('leftpanel');

  const handleShowChange = (panel: string) => {
    setExpanded(expanded === panel ? false : panel);
  };


  //@ts-ignore
  const otherValidation = JSON.parse(sessionStorage.getItem("otherValidation"));
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [products, setProducts] = useState<any>([]);
  const [permission, setPermission] = useState<any>(null);
  const handleClick = () => {

    // history.goBack();
    history.push(
      `/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/0/true/true`
    );
  };

  const assumptionHandler = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/1/false/true`);
 }

  const handlePerformCosting = () => {
    openPerformCostingModal.open();
  };

  const browseFiles = () => {
    inputConfigUpload.current?.click();
  }

  const UploadConfigFile = (e: any) => {
    setUploadLoading(true);
    // console.log(e.target.files[0], "FILESSSSSSSSSS");
    const formData = new FormData();
    formData.append("project", projectId);
    formData.append("file", e.target.files[0]);
    API.post("/config/upload_costing_configuration/", formData, {}, 0)
      .then((res: any) => {
        setUploadLoading(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setUploadLoading(false);
        if (err && err.response && err.response.data) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data[0],
          });
        }
      });

  }

  const guideHandler = () => {
    history.push(
      `/costingconfiguration/${projectId}/${projectname}/guidelines`
    );
  };

  const costingModel = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
  };

  const getAllResult = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        costing_config: true,
      },
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
      })
      .catch((err: any) => { });
  };
  const getPermisions = () => {
    API.get(
      `/auth/calibration_user_permission/`,
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Costing Configuration"
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data?.action);

      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }
  useEffect(() => {
    getAllResult();

    getPermisions();
  }, []);

  // console.log(permission);


  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CostingConfigurationDrawer />
    </Box>
  );

  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  return (
    <Box sx={costingType==2?{border:'1.5px solid #833406',boxShadow:'24px',marginLeft:'1rem',marginRight:'1rem',marginBottom:'1rem'}:{}} >
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            width: "100%",
            height:'2.5rem',
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
            {/* <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton> */}
            <Typography
              sx={{ fontSize: "1rem", whiteSpace: "nowrap", fontWeight: "500" ,marginLeft:"4rem", width:{lg:'13rem',xl:'15rem'},overflow:'hidden',textOverflow:'ellipsis'}}
            >
              {projectname} Costing Configuration
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {/* Need download and upload icon */}

              {!uploadLoading ? (
                <>
                  {permission?.includes('C') ? <ShareIcon
                    sx={{ fontSize: "1.7rem", cursor: "pointer" }}
                    titleAccess="Upload configuration file"
                    onClick={() =>shareModal.open() }
                  /> : <ShareIcon
                  
                    sx={{ fontSize: "1.7rem", cursor: "default", color: "#ded7d7" }}
                    titleAccess="Upload configuration file"

                  />}
                </>
              ) : <CircularProgress size="small" color="success" />}
              <input
                disabled={!permission?.includes('C')}
                accept=".enc"
                id="profilePhoto1"
                type="file"
                style={{ display: "none" }}
                onChange={UploadConfigFile}
                ref={inputConfigUpload}
                multiple={false}
              />
            </Box>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ width: '100%' }}>
            <Box sx={{width:'50%'}}>
            </Box>
            <Box sx={{width:'50%'}}></Box>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }} >
            {otherValidation?.guidline_config ? (
              <img
                title="Guide lines"
                src={validatedGuidelinesImg}
                style={{ height: "1.2rem", width: "auto", cursor: "pointer" }}
                onClick={() => guideHandler()}
              />
            ) : (
              <img
                title="Guide lines"
                src={guidelines}
                style={{ height: "1.2rem", width: "auto", cursor: "pointer" }}
                onClick={() => guideHandler()}
              />
            )}
            {otherValidation?.calculator_config ? (
              <img
                title="Calulator"
                src={validatedCalculatorImg}
                style={{ height: "1.2rem", width: "auto", cursor: "pointer" }}
                onClick={() => costingModel()}
              />
            ) : (
              <img
                title="Calulator"
                src={calculator}
                style={{ height: "1.2rem", width: "auto", cursor: "pointer" }}
                onClick={() => costingModel()}
              />
            )}
            <CreditCardIcon sx={{ fontSize: '1.7rem', cursor: 'pointer' }}
              titleAccess='Assumption Card'
                            // onClick={() => AssumptionCardModal.open()} 
              onClick={() => assumptionHandler()}
            />
           {/* <ShareIcon sx={{ cursor: 'pointer'}} titleAccess='Share' onClick={() => shareModal.open()} />  */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>

                <IconButton
                  size="medium"
                  title={expanded === 'leftpanel' ? 'Hide Category' : 'Show Category'}
                  sx={{
                    marginTop: '1rem',
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={() => handleShowChange('leftpanel')}
                >
                  {expanded === 'leftpanel' ? (
                    <ArrowBackIcon sx={{ fontSize: "1.7rem" }} />
                  ) : (
                    <ArrowForwardIcon sx={{ fontSize: "1.7rem" }} />
                  )}
                </IconButton>

                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Costing Configuration
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Costing Configuration
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ width: "100%", height: { lg: '89vh', xl: "90vh" } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <PermissionData.Provider value={permission}>
            <CostingTemplateCategory permission={permission} expanded={expanded} handleShowChange={handleShowChange} setCattegory={setCategory} projectname={projectname} />
          </PermissionData.Provider>
        </Box>
      </Box>
      {openPerformCostingModal.isOpen && (
        <PerformCostingProjectModal
          isOpen={openPerformCostingModal.isOpen}
          onCloseModal={openPerformCostingModal.close}
          project_id={projectId}
        />
      )}

      {shareModal.isOpen &&
        <ShareInstanceModal isOpen={shareModal.isOpen} onCloseModal={shareModal.close} type={"instanceShare"} setParentCount={setParentCount}/>      }
    </Box >
  );
}
