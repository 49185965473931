import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { materialFields } from "./calculator.constant";
import MaterialDB from "./DatabaseTables/MaterialDB";
import MachineDB from "./DatabaseTables/MachineDB";
import LabourDB from "./DatabaseTables/LabourDB";
import ToolDB from "./DatabaseTables/ToolDB";
import BOPDB from "./DatabaseTables/BOPDB";
import OperationDB from "./DatabaseTables/OperationDB";
import CurrencyDB from "./DatabaseTables/CurrencyDB";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 90vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function DBSelectionCalculatorModal({ isOpen, database, input, onClose, onSubmit }: any) {
  const [selected, setSelected] = React.useState<any>();
  
  console.log(database, "parameter");
  

  const tableHeading = (datatype: any) => {
    switch (datatype) {
      case 1:
        return <span>Fetch material information from DB</span>;
      case 2:
        return <span> Fetch machine information from DB</span>;
      case 3:
        return <span> Fetch labour information from DB</span>;
      case 4:
        return <span> Fetch tool information from DB</span>;
      // case 5:
      //     return (<span> Fetch Fastener  from DB ( { Selectedrevision && Selectedrevision?.length > 0 ?Selectedrevision && Selectedrevision[0]?.name:revisiondata?.name})</span>);
      case 6:
        return <span> Fetch Bought-Out Parts from DB</span>;
      case 7:
        return <span>Fetch Operation from DB</span>;
        case 8:
          return <span>Fetch Currency from DB</span>;
    }
  };

  const getSelectedText = (datatype: any) => {
    switch (datatype) {
      case 1:
        return <span>{selected?.material_code}</span>;
      case 2:
        return <span>{selected?.machine_code}</span>;
      case 3:
        return <span>{selected?.labour_code}</span>;
      case 4:
        return <span>{selected?.toolCode}</span>;
      // case 5:
      //     return (<span> Fetch Fastener  from DB ( { Selectedrevision && Selectedrevision?.length > 0 ?Selectedrevision && Selectedrevision[0]?.name:revisiondata?.name})</span>);
      case 6:
        return <span> Fetch Bought-Out Parts from DB</span>;
      case 7:
        return <span>{selected?.operationCode}</span>;
    }
  };

  const tableContent = (datatype: any) => {
    switch (datatype) {
      case 1:
        return <MaterialDB database={database} selected={selected} setSelected={setSelected} />;
      case 2:
        return <MachineDB database={database} selected={selected} setSelected={setSelected} />;
      case 3:
        return <LabourDB database={database} selected={selected} setSelected={setSelected} />;
      case 4:
        return <ToolDB database={database} selected={selected} setSelected={setSelected} />;
      // case 5:
      //     return (<span> Fetch Fastener  from DB ( { Selectedrevision && Selectedrevision?.length > 0 ?Selectedrevision && Selectedrevision[0]?.name:revisiondata?.name})</span>);
      case 6:
        return <BOPDB database={database} selected={selected} setSelected={setSelected} />;
      case 7:
        return <OperationDB database={database} selected={selected} setSelected={setSelected} />;
      case 8:
        return <CurrencyDB database={database} selected={selected} setSelected={setSelected} />;
    }
  }; 


//   useEffect(() => {
//     const filteredArray = materialFields
//       ?.filter((item: any) =>
//         tableData?.some((obj: any) => obj?.hasOwnProperty(item?.key))
//       )
//       .map((item: any) => ({
//         ...tableData?.find((obj: any) => obj?.hasOwnProperty(item?.key)), // Get the matching object from arr1
//         value: item.value ?? "N/A", // Add the 'value' field from arr2 (default to "N/A" if not present)
//       }));

//     console.log(filteredArray);
//   }, [tableData]);

  //   const arr1 = [
  //     {id:1, name:"Bob", code:"TMK-12",},
  //  {id:2, name:"Akash", code:"AKA-12",parameter_details: [
  //     { name: "Cavity Pressure", value: 12, unit: "Nm2" },
  //     { name: "Branch Runner Length", value: 67, unit: "mm" },

  //  ] }
  //   ]
//   console.log(input, "parameter");

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "2.5rem",
            marginBottom:"1rem"
          }}
        >
            <Box>
            {selected && <Button variant="contained" sx={{marginLeft:"0.5rem", height:"2rem", fontSize:"0.85rem"}} onClick={()=> {
                onSubmit(selected)
            }}>Fetch</Button>
         }
         </Box>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            {tableHeading(database)}
          </Typography>

          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={() => {
              onClose();
            }}
          />
        </Box>

        {/* {tableContent(database, categoryList)} */}

        

        {tableContent(database)}
      </Box>
    </Modal>
  );
}

export default DBSelectionCalculatorModal;
