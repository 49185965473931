import { useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import MyChatComponent from "./Chattab/MyChatComponent";
import OtherChatComponent from "./Chattab/OtherChatComponent";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import AIChatIcon from "../Assets/images/xcQ.png";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import { AUTH } from "../Redux/Services/auth.service";
import editmail from "../Assets/images/mailEdit.png";

type Group = {
  id: number;
  name: string;
  chats: Chat[];
};

type Chat = {
  chat_id: number;
  chat_name: string;
};

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  fontFamily: "'Poppins', sans-serif",
  background: "linear-gradient(90deg, #6a11cb, #2575fc, #00c6ff)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  // textTransform: "uppercase",
  letterSpacing: "0.1rem",
}));

const ChatCollapseLeftSection: any = (props: any) => {
  const { setIsEmptyGroup, reCallgrooup, setExpanded, expanded } = props;
  const { grpId, chatId, userId, tab, grpName, chatName } =
    useRouteParams<any>();
  const history = useHistory();
  const [active, setActive] = useState("mychat");

  const chageChatTab = (tab: string) => {
    setActive(tab);
    // :grpId/:chatId/:userId/:tab
    history.push(`/chat/${0}/${0}/${0}/${tab}/${0}/${0}`);
  };

  return (
    <Box
      sx={{
        padding: 1,
        backgroundColor: "#f3f3ee",
        boxShadow: 1,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: 1,
          alignItems: "center",
          justifyContent: "space-between",
          width: "4rem",
          minWidth: "4rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <img
            src={AIChatIcon}
            alt="xcQ.ai"
            style={{ width: "2.8rem", height: "2.8rem", objectFit: "contain" }}
          />         
        </Box>
      </Box>
      <Box sx={{ height: { lg: "85vh", xl: "90vh" }, width: "100%" }}>
        {/* {tab === "mychat" ? (
          <MyChatComponent
            setIsEmptyGroup={setIsEmptyGroup}
            reCallgrooup={reCallgrooup}
            setWindowExpanded={setExpanded}
            expandedWindow={expanded}
          />
        ) : (
          <OtherChatComponent
            setIsEmptyGroup={setIsEmptyGroup}
            reCallgrooup={reCallgrooup}
            setWindowExpanded={setExpanded}
            expandedWindow={expanded}
          />
        )} */}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          position: "relative",
          bottom: 0,
        }}
      >
        {/* <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          {AUTH.isSuperUser && (
            <Box
              sx={{
                padding: "0.5rem 1rem",
                backgroundColor:
                  tab === "mychat" ? "primary.main" : "primary.light",
                color: tab === "mychat" ? "white" : "primary.main",
                textAlign: "center",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => chageChatTab("mychat")}
            >
              My Chat
            </Box>
          )}
          {AUTH.isSuperUser && (
            <Box
              sx={{
                padding: "0.5rem 1rem",
                backgroundColor:
                  tab === "otherchat" ? "primary.main" : "primary.light",
                color: tab === "otherchat" ? "white" : "primary.main",
                textAlign: "center",
                width: "50%",
                cursor: "pointer",
              }}
              onClick={() => chageChatTab("otherchat")}
            >
              Other Chat
            </Box>
          )}
        </Box> */}
        <Box sx={{ display: "flex", marginRight: "0.2rem", padding: "3px" }}>
          <StartIcon
            titleAccess="Expand window"
            sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => setExpanded(true)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatCollapseLeftSection;
