import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Divider,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "../../../api-services";
import LoaderImage from "../../../Assets/images/loading-screen-landing.gif";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import RViewerJS from "viewerjs-react";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import { CheckBoxComponent } from "./CheckBoxComponent";
import BOMImagesandVideosView from "./BOMImagesandVideos/BOMImagesandVideosView";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import BOMCalculatorValueInputModal from "./BOMCalculatorValueInputModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DataGrid } from "@mui/x-data-grid";
import calcIcon from "../../../Assets/images/calculator_green.svg";
import { LoadingButton } from "@mui/lab";
import { DatabaseInputBox } from "./DatabaseInputBox";
import { DatabaseInputBoxView } from "./DatabaseInputBoxView";
import { materialFields } from "../../../Configuration/Costing/calculator.constant";

export interface IBOMImageBasedSearchModalProps {
  onCloseModal: any;
  isOpen: any;
  BOMparameters?: any;
  handleChangeBOMStatusTopvaultValidate?: any;
  handleChangeBOMStatusValidate?: any;
  loadingStatus?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function BOMValidationView(
  props: IBOMImageBasedSearchModalProps
) {
  const {
    onCloseModal,
    isOpen,
    BOMparameters,
    handleChangeBOMStatusTopvaultValidate,
    handleChangeBOMStatusValidate,
    loadingStatus,
  } = props;
  const ImageViewer = React.useRef<any>();
  const [srcImages, setSrcImages] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const { topVault, vault, treeabbr } = useRouteParams<any>();
  const [TableData, setTableData] = React.useState<any>();
  const [calcData, setCalcData] = React.useState<any>();
  const [dataFullfilled, setDataFullfilled] = React.useState<boolean>(false);
  const [tableCalcdata, setTableCalcdata] = React.useState<any>([]);
  const [details, setDetails] = React.useState<any>({});
  const [BOMStatus, setBOMStatus] = React.useState<any>("");
  const [weightLockStatus, setWeightLockStatus] = React.useState<any>(false);
  const [BomParameter, setBomParameter] = React.useState<any>();
  const [pageNumber, setPageNumber] = React.useState(0);
  const [calcId, setCalcId] = React.useState<any>(0);
  const [tableCalcDataLoader, setTableCalcDataLoader] =
    React.useState<any>(false);
  const inputModal = useBaseModal();
  const [loadingWeightLock, setLoadingWeightLock] = React.useState<any>(false);
  const [count, setCount] = React.useState<number>(0);
  const [PartLoader, setPartLoader] = React.useState<any>(false);
  const [dbParameters, setDBParameters] = React.useState<any>();
  const [TableLoader, setTableLoader] = React.useState<any>(false);
  const [tableCalcData, setTableCalcData] = React.useState<any>([]);
  const [calcName, setCalcName] = React.useState<any>();
  const [costingDBParams, setCostingDBParams] = React.useState<any>([]);

  const [data, setData] = React.useState<any>([]);
  const history = useHistory();

  const parameterMapping: Record<string, string> = materialFields
    ?.filter((field: any) => field?.value) // Only consider fields that have `value`
    .reduce((acc: any, field: any) => {
      acc[field?.value] = field?.key; // Map value -> key
      return acc;
    }, {} as Record<string, string>);

  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { top_vault: tv };
    } else {
      return { vault: vlt };
    }
  };

  const getVaultDetails = () => {
    API.get(
      "/xcpep/vault_info/",
      {
        ...obj(topVault, vault),
        module: 9,
        current_top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setDetails(res.data[0]);
        // setBOMStatus(res.data[0]?.status)

        setWeightLockStatus(res.data[0]?.weight_lock);
        setLoadingWeightLock(false);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getVaultDetails();
  }, []);
  const handleClick = (srcImg: any) => {
    if (ImageViewer.current) {
      ImageViewer.current.src = srcImg;
      ImageViewer.current?.click?.();
    }
    setSrcImages(srcImg);
  };

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getTableCalculatorData = (id: any) => {
    setDataFullfilled(false);
    API.get(
      `/xcpep/bom_entry_calculator/`,
      {
        calculation_id: id,
        ...getObjKeyName(),
      },
      0
    )
      .then((res: any) => {
        setCalcData(res.data);
        setDataFullfilled(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleCalculatorClick = (id: any, name: any) => {
    setCalcId(id);
    setCalcName(name);
    getTableCalculatorData(id);
    inputModal.open();
  };

  const PcbTableHeader = (HeaderData: any) => {
    var valuedata: any =
      HeaderData &&
      HeaderData?.map((key: any, value: any) => {
        return {
          field: key?.name,
          flex: 1,

          align: key?.data_type === "int" && "left",
          headerAlign: key?.data_type === "int" && "left",
          type: key?.data_type === "int" ? "number" : "string",
          //  headerAlign: 'center',
          // type: 'string',
          disableExport: key?.data_type === "image" ? true : false,
          headerName: key?.name,
          // headerClassName: styles.Dataheader,

          renderCell: (params: any) => {
            if (key?.data_type == "image") {
              return (
                <>
                  <CameraAltIcon
                    titleAccess={
                      params.row[key?.name] && params.row[key?.name]?.length > 1
                        ? "View Image"
                        : "No Image Available"
                    }
                    style={{
                      cursor:
                        params.row[key?.name] &&
                        params.row[key?.name]?.length > 1
                          ? "pointer"
                          : "not-allowed",
                      opacity:
                        params.row[key?.name] &&
                        params.row[key?.name]?.length > 1
                          ? 1
                          : 0.5,
                    }}
                    onClick={() => {
                      if (
                        params.row[key?.name] &&
                        params.row[key?.name]?.length > 1
                      ) {
                        handleClick(params.row[key?.name]);
                      }
                    }}
                  />
                  <div style={{ display: "none" }}>
                    {/* @ts-ignore */}
                    <RViewerJS>
                      <img
                        src={ImageViewer.current?.src}
                        alt="image"
                        ref={ImageViewer}
                      />
                    </RViewerJS>
                  </div>
                </>
              );
            } else {
              return (
                <span title={params.row[key?.name]}>
                  {params.row[key?.name]}
                </span>
              );
            }
          },
        };
      });
    return valuedata;
  };

  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  const toggleTopvault = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getCalculatorList = () => {
    setTableCalcDataLoader(true);
    API.get(
      `/xcpep/bom_entry_calculator/`,
      {
        ...getObjKeyName(),
      },
      0
    )
      .then((res: any) => {
        setTableCalcData(res?.data);
        setTableCalcDataLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setTableCalcDataLoader(false);
      });
  };

  React.useEffect(() => {
    getCalculatorList();
  }, [vault, treeabbr, topVault]);

  const costModalColumnRender: any = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span title={params.row?.calculator?.name}>
            {params.row?.calculator?.name}
          </span>
        );
      },
    },
    {
      field: "result",
      headerName: "Result",
      flex: 1,
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Calculation",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <img
            src={calcIcon}
            alt="calculator"
            style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
            title="Expand Calculator"
            // style={{ cursor: "pointer" }}
            onClick={() =>
              handleCalculatorClick(
                params.row?.id,
                params.row?.calculator?.name
              )
            }
          />
        );
      },
    },
  ];
  React.useEffect(() => {
    setPartLoader(true);
    setTableLoader(true);

    API.get(
      "/customer/Bom_view_data/",
      {
        table: true,
        ...toggleTopvault(),
      },
      0
    )
      .then((res: any) => {
        setTableData(res?.data);
        setTableLoader(false);

        // let parameterValues = {};

        // if (
        //   res?.data?.costing_db_parameters?.[0]?.material_parameter?.length > 0
        // ) {
        //   // Extract parameter values based on the mapping
        //   parameterValues =
        //     res?.data?.costing_db_parameters?.[0]?.material_parameter?.reduce(
        //       (acc: any, param: any) => {
        //         const key = parameterMapping?.[param?.name]; // Get corresponding key
        //         if (key) {
        //           acc[key] = param?.value; // Assign value to the mapped key
        //         }
        //         return acc;
        //       },
        //       {} as Record<string, any>
        //     );
        // }

        // setDBParameters({
        //   ...res?.data?.costing_db_parameters?.[0],
        //   ...parameterValues,
        // });
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        setTableData(null);
        setTableLoader(false);
      });
  }, [vault, treeabbr, topVault]);

  React.useEffect(() => {
    const costingDBParamArr = BOMparameters?.filter(
      (itm: any) => {
        if (itm?.data_type == "costing-database" && itm?.material_details?.material_code) {
          return itm;
        }
      }
    );
    if (costingDBParamArr?.length > 0) {
      const arr = costingDBParamArr?.map((itemm:any)=> ({ paramter_name: itemm?.paramter_name, ...itemm?.material_details }));
      
      setCostingDBParams(arr);
    }
    // console.log(materialDetails, "parameters");
    // let parameterValues = {};

    // console.log(parameterMapping, "parameter");

    // if (materialDetails?.material_parameter?.length > 0) {
    //   // Extract parameter values based on the mapping
    //   parameterValues = materialDetails?.material_parameter?.reduce(
    //     (acc: any, param: any) => {
    //       const key = parameterMapping?.[param?.name]; // Get corresponding key
    //       if (key) {
    //         acc[key] = param?.value; // Assign value to the mapped key
    //       }
    //       return acc;
    //     },
    //     {} as Record<string, any>
    //   );
    // }

    // setDBParameters({
    //   ...materialDetails,
    //   ...parameterValues,
    // });


  }, [BOMparameters]);

  React.useEffect(()=> {
if (costingDBParams?.length > 0) {
  // Map over all items in costing_db_parameters
  const formattedParameters = costingDBParams?.map(
    (item: any) => {
      let parameterValues: Record<string, any> = {};
      
      
      if (item?.material_parameter?.length > 0) {
        parameterValues = item?.material_parameter?.reduce(
          (acc: any, param: any) => {
            
            const key = parameterMapping?.[param?.name]; // Get corresponding key
            if (key) {
              acc[key] = param?.value; // Assign value to the mapped key
            }
            return acc;
          },
          {} as Record<string, any>
        );
      }

      

      return {
        ...item,
        ...parameterValues,
      };
    }
  );

  setDBParameters(formattedParameters);

 
  
}
  },[costingDBParams])

   

  const costModalDataRender: any = {
    columns: costModalColumnRender,
    rows: tableCalcData && tableCalcData,
  };

  const filteredFieldsArray = dbParameters?.map((param: any) =>
    materialFields?.filter((field: any) => param?.[field?.key] !== undefined)
  );

  const columns = [
    { field: "name", headerName: "Name", width:  210 },
    { field: "value", headerName: "Value", width:200 },
    { field: "unit", headerName: "Unit", width:  80 },
  ];

  const renderInputs = (item: any, index: any) => {
    switch (item?.data_type) {
      case "number":
      case "float":
      case "nonzero-positive-int":
      case "nonzero-positive-float":
      case "positive-float":
      case "nonzero-float":
      case "percentage":
      case "positive-int":
      case "nonzero-integer":
      case "integer":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "text":
      case "string":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "textarea":
      case "longtext":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "dropdown":
      case "singleselect":
      case "country":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>

            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "multiselect":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "boolean":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value ? "Yes" : "No"}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "date":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "database":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {/* {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )} */}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.value &&
              item?.value !== "NA" &&
              Object.keys(item?.value)?.length > 0 ? (
                <DatabaseInputBoxView item={item} />
              ) : (
                "NA"
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            ></TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {/* {item?.value || "NA"} */}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
    }
  };

   

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {inputModal.isOpen && dataFullfilled && (
            <BOMCalculatorValueInputModal
              isOpen={inputModal.isOpen}
              onClose={inputModal.close}
              fetchCalculator={getTableCalculatorData}
              CalculatorItem={calcId}
              calculatorData={calcData}
              calcName={calcName}
              setCalcData={setCalcData}
              setCount={setCount}
              count={count}
            />
          )}
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Check Data for validation
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              height: {
                xs: "76vh",
                sm: "76vh",
                md: "76vh",
                lg: "76vh",
                xl: "83vh",
              },
              width: "100%",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box>
              <BOMImagesandVideosView />
            </Box>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "12rem",
                        sm: "12vw",
                        md: "12vw",
                        lg: "12vw",
                        xl: "17vw",
                      },
                    }}
                  >
                    Parameters
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "3rem",
                        sm: "3rem",
                        md: "3rem",
                        lg: "2rem",
                        xl: "3rem",
                      },
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "25rem",
                        sm: "25vw",
                        md: "25vw",
                        lg: "25vw",
                        xl: "25vw",
                      },
                    }}
                  >
                    Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "7rem",
                        sm: "7rem",
                        md: "7rem",
                        lg: "7rem",
                        xl: "7rem",
                      },
                    }}
                  >
                    Unit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BOMparameters ? (
                  BOMparameters?.map((item: any, index: any) => {
                    return renderInputs(item, index);
                  })
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{ padding: "1rem 0.5rem", borderBottom: "none" }}
                        colSpan={4}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="2rem"
                            width="100%"
                            sx={{ marginBottom: "1rem" }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
            <Box sx={{ margin: "1rem 0rem" }}>

               <Box sx={{}}>
                            {filteredFieldsArray?.length > 0 && (
                              <Box
                                sx={{
                                  margin: "2rem 0",
              display:"flex", flexWrap:"wrap", gap:"2%",
                                  padding: "0.2rem 1rem",
                                  width: "100%",
                                  zIndex: "1000",
                                  position: "relative",
                                }}
                              >
                               
              
                                <>
                                  {filteredFieldsArray?.map(
                                    (fields: any, dataGridIndex: any) => {
                                      const rows = fields?.map(
                                        (field: any, index: number) => ({
                                          id: index + 1,
                                          name: field?.text,
                                          value:
                                            dbParameters?.[dataGridIndex]?.[field?.key] ??
                                            "N/A",
                                          unit: field?.unit || "", // Placeholder
                                        })
                                      );
                                      
                                      return (
                                        <div
                                          key={dataGridIndex}
                                          style={{ marginBottom: "20px", width: "49%", }}
                                        >
                                           <Typography sx={{ fontSize: "1rem", marginLeft: "1rem",  }}>
                                  {" "}
                                  {dbParameters?.[dataGridIndex]?.paramter_name}
                                </Typography>
                                <Box
                                  sx={{
                                    width: "100%",
                                    // marginTop: "-1.75rem",
                                  }}
                                >
                                          <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            rowHeight={30}
                                            headerHeight={30}
                                            // components={{
                                            //   Toolbar: () => {
                                            //     return CustomToolbar();
                                            //   },
                                            // }}
                                            getRowHeight={() => "auto"}
                                            autoHeight={true}
                                            hideFooter={true}
                                            sx={{
                                              fontSize: "12px",
                                              borderColor: "primary.light !important",
                                              "& .MuiDataGrid-cell:hover": {
                                                color: "primary.main",
                                              },
              
                                              "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                                                {
                                                  borderBottomColor: "primary.light",
                                                },
                                              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                                borderBottomColor: "primary.light",
                                                minHeight: "26px",
                                              },
                                            }}
                                          />
                                          </Box>
                                        </div>
                                      );
                                    }
                                  )}
                                  
                                </>
                              </Box>
                            )}
                          </Box>


              <>
                {tableCalcDataLoader ? (
                  // <SubBouncingLoader />
                  <Box sx={{ height: "4rem", marginTop: "2rem" }}>
                    <Skeleton
                      sx={{
                        width: "37vw",
                        height: "3rem",
                        marginLeft: "1.2rem",
                      }}
                    />
                    <Skeleton
                      sx={{
                        width: "37vw",
                        height: "3rem",
                        marginLeft: "1.2rem",
                      }}
                    />
                    <Skeleton
                      sx={{
                        width: "37vw",
                        height: "3rem",
                        marginLeft: "1.2rem",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                        padding: "0.2rem 1rem",
                        margin: "2rem 0",
                        width: "50%",
                        zIndex: "1000",
                        position: "relative",
                      }}
                    >
                      {tableCalcData && tableCalcData.length > 0 && (
                        <Typography
                          sx={{ fontSize: "1rem", marginLeft: "1rem" }}
                        >
                          {" "}
                          Calculated Parameters
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "0 1rem",
                        marginTop: "-2rem",
                      }}
                    >
                      {/* <Typography style={{ width: '100%', textAlign: 'center' }}>BOM Parameters</Typography> */}
                      {tableCalcData && tableCalcData.length > 0 && (
                        <DataGrid
                          {...costModalDataRender}
                          rowHeight={30}
                          hideFooterPagination="true"
                          headerHeight={30}
                          // components={{
                          //   Toolbar: () => {
                          //     return CustomToolbar();
                          //   },
                          // }}
                          getRowHeight={() => "auto"}
                          autoHeight={true}
                          hideFooter={true}
                          sx={{
                            fontSize: "12px",
                            borderColor: "primary.light !important",
                            "& .MuiDataGrid-cell:hover": {
                              color: "primary.main",
                            },

                            "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                              {
                                borderBottomColor: "primary.light",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              borderBottomColor: "primary.light",
                              minHeight: "26px",
                            },
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              </>
              <>
                {TableData &&
                  TableData?.map((tableItem: any, ind: any) => {
                    return (
                      <>
                        {TableLoader ? (
                          <>
                            <Skeleton
                              sx={{
                                width: "70vw",
                                height: "3rem",
                                marginLeft: "1.2rem",
                              }}
                            />
                            <Skeleton
                              sx={{
                                width: "70vw",
                                height: "3rem",
                                marginLeft: "1.2rem",
                              }}
                            />
                            <Skeleton
                              sx={{
                                width: "70vw",
                                height: "3rem",
                                marginLeft: "1.2rem",
                              }}
                            />
                            <Skeleton
                              sx={{
                                width: "70vw",
                                height: "3rem",
                                marginLeft: "1.2rem",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: "1rem",
                                alignItems: "center",
                                padding: "0.2rem 1rem",
                                width: "50%",
                                zIndex: "1000",
                                position: "relative",
                                marginTop: "2rem",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "1rem",
                                  marginLeft: "1rem",
                                  marginBottom: "2rem",
                                  marginTop: "1rem",
                                }}
                              >
                                {" "}
                                {tableItem?.table_name}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                padding: "0 1rem",
                                marginTop: "-2rem",
                              }}
                            >
                              {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                              <DataGrid
                                headerHeight={48}
                                rowHeight={36}
                                rows={PcbTableRow(
                                  pageNumber * 100 > tableItem?.data.length
                                    ? tableItem?.data?.slice(
                                        pageNumber * 100,
                                        (pageNumber + 1) * 100
                                      )
                                    : tableItem?.data?.slice(
                                        pageNumber * 100,
                                        tableItem?.data.length
                                      )
                                )}
                                // rows={PcbTableRow(tableItem?.data)}
                                columns={PcbTableHeader(
                                  tableItem && tableItem?.header_item
                                )}
                                // pageSize={5}
                                // rowsPerPageOptions={[5]}
                                sx={{
                                  fontSize: "12px",
                                  borderColor: "primary.light",
                                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                                    {
                                      borderBottomColor: "primary.light",
                                    },
                                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root":
                                    {
                                      border: "none !important",
                                    },
                                }}
                                density="compact"
                                // checkboxSelection
                                // @ts-ignore
                                // components={{ Toolbar: GridToolbar }}

                                pageSize={100}
                                // components={{
                                //   Toolbar: () => {
                                //     return tableItem?.data.length > 100 ? (
                                //       <CustomToolbarIncludingPagination
                                //         count={tableItem?.data.length}
                                //       />
                                //     ) : (
                                //       <CustomToolbar />
                                //     );
                                //   },
                                // }}
                                autoHeight={true}
                                disableSelectionOnClick
                                pagination
                                hideFooterPagination
                                // experimentalFeatures={{ newEditingApi: true }}
                              />
                              {/* {tableItem?.data.length > 100 && <Pagination count={Math.ceil(tableItem?.data.length / 100)} shape="rounded" showFirstButton showLastButton onClick={(e:any) => handlePageChange(e)} />  } */}
                            </Box>
                          </>
                        )}
                      </>
                    );
                  })}
              </>
              <Box sx={{ float: "right", margin: "1rem" }}>
                <LoadingButton
                  loading={loadingStatus || false}
                  variant="contained"
                  onClick={() => {
                    if (vault == 0) {
                      handleChangeBOMStatusTopvaultValidate(3);
                    } else {
                      handleChangeBOMStatusValidate(3);
                    }
                  }}
                >
                  Validate
                </LoadingButton>
              </Box>
            </Box>
            {/* <Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(5, 1fr)',
								gap: '1rem',
								justifyContent: 'center',
								height: '85vh',
							}}>
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
							<Skeleton variant='rectangular' height={150} />
						</Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
