import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { LoadingButton } from "@mui/lab";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import BOMDBParamModal from "./BOMDBParamModal";
import { ADMIN } from "../../../Redux/Services/admin.service";
import CreateRevisionDatabaseModal from "../../../DataBaseModule/Costing/CreateRevisionDatabaseDataModal";
import Swal from "sweetalert2";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 90vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function BOMDatabaseModal({
  isOpen,
  onClose,
  parameter,
  vault,
  getBOMParametersData,
  BOMValidationStatus,
  updateMaterialDetails,
}: any) {
  const bomDBParamModal = useBaseParamsModal();
  const [value, setValue] = React.useState(0);
      const [categoryList, setCategoryList] = React.useState<any>([]);
  const [fetchCount, setFetchCount] = React.useState(0);
  const [category, setCategory] = React.useState("STEEL");
  const [fetchedMaterial, setFetchedMaterial] = React.useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [loadingRowId, setLoadingRowId] = useState<number | null>(null);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [materialList, setMaterialLialList] = useState([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const getCategoryList = () => {
        
        API.get(
            '/api/db/cost_databases/',
            {
                database_type: 'material',
            },
            0
        )
            .then((res: any) => {
                setCategoryList(res.data);
                
            })
            .catch((err: any) => {
                
            });
    };




  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchMaterials = () => {
    setTabledataLoading(true);
    API.get(
      `xcpep/bom_db_parameter/?all_db_parameter=True&category=${category}&parameter_id=${parameter?.key}&vault=${vault}`,
      {},
      0
    )
      .then((res: any) => {
        setMaterialLialList(res.data);
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setTabledataLoading(false);
      });
  };

  const getFetchedMaterial = () => {
    API.get(
      `xcpep/bom_db_parameter/?part_material_parameter=True&vault=${vault}&parameter=${parameter?.key}`,
      {},
      0
    )
      .then((res: any) => {
        setFetchedMaterial(res.data);
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchMaterials();
    getFetchedMaterial();
    getCategoryList();
  }, [category, value]);

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const fetchData = (row?:any) => {
    setLoadingRowId(row?.id); // Start loading state

    API.put(
    //   `/xcpep/bom_db_parameter/`,
      `/xcpep/component_details/74699/?vault=${vault}`,
      {
        [parameter?.key]: row?.id,
        // vault: vault,
        // material: row?.id,
        // parameter: parameter?.key,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.success("Item fetched successfully", "bottom-right");
        getFetchedMaterial();
        fetchMaterials();
        console.log(row, "parameter");
        updateMaterialDetails(row, parameter?.key);
        setFetchCount((prev: any) => prev + 1);
        // getBOMParametersData();
      })
      .catch((err: any) => {
        const { data } = err.response;
        onClose();
       
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
          backdrop: `
          rgba(0,0,0,0.4)
          !important; z-index: 1000
        `,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container") as HTMLElement;
          if (swalPopup) {
            swalPopup.style.zIndex = "1000";
          }
        },
        });
      })
      .finally(() => {
        setLoadingRowId(null);
      });
  }

  const handleFetch = (row: any) => {

    if (parameter?.costing_effected == true || parameter?.material_details?.material_code) {
        


           Swal.fire({
                title: "Confirm?",
                text: "This action will impact the material cost",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Fetch",
                cancelButtonText: "Cancel",
                customClass: {
                  container: "swal2Container",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                    fetchData(row); 
                    Swal.close();
                } else {
                    Swal.close(); 
                }
              });
    } else {
        fetchData(row)
    }
 
  };

  let rows: any = [];
  let dataGridDataGenerator: any =
    materialList &&
    materialList?.map((item: any, index: any) => {
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        material_parameter: item?.material_parameter,
        item_fetched: item?.item_fetched,
        material_code: item?.material_code,
        material_grade: item?.material_grade,
        material_subgrade: item?.material_subgrade,
        shape: item?.shape,
        density: item?.density,
        description: item?.description,
        material_cost: ` ${item?.material_cost}`,
        material_source: item?.material_source,
        material_reference: item?.material_reference,
        scrap_cost: ` ${item?.scrap_cost}`,
        scrap_source: item?.scrap_source,
        scrap_reference: item?.scrap_reference,
        mapped_status: item?.mapped_status,
        category: item?.category,
        currency_id: item?.currency,
        currency_code: item?.currency_code,
        unit: item?.unit,
      };
      rows.push(objShared);
    });

  // Move items with item_fetched === true to the beginning of the array
  rows?.sort(
    (a: any, b: any) => (b?.item_fetched ? 1 : 0) - (a?.item_fetched ? 1 : 0)
  );

  let columns: any = [
    {
      field: "actions",
      headerName: "Action",
      width: 180,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            {cellValues?.row?.item_fetched == true ? (
              <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
                Fetched
              </Box>
            ) : (
              <LoadingButton
                sx={{ height: "1.75rem", width: "1.5rem", fontSize: "0.75rem" }}
                onClick={() => handleFetch(cellValues.row)}
                loading={loadingRowId === cellValues.row?.id}
                variant="contained"
              >
                Fetch
              </LoadingButton>
            )}

            {/* <span onClick={(e:any)=> {
                 if (cellValues?.row?.material_parameter?.length > 0) {
                    
                   handleClick(e) ;
                }
              }}>
              <InfoIcon aria-describedby={id} sx={{cursor:"pointer", color: cellValues?.row?.material_parameter?.length > 0 ? "primary.main" : "grey"}} />
              </span> */}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              elevation={0}
              // PaperProps={{
              //     sx: {
              //       boxShadow: 1, // Reduce shadow

              //     },
              //   }}
            >
              {cellValues?.row?.material_parameter?.length > 0 && (
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Value</b>
                        </TableCell>
                        <TableCell>
                          <b>Unit</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cellValues?.row?.material_parameter?.map(
                        (row: any, index: any) => (
                          <TableRow key={index}>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.value}</TableCell>
                            <TableCell>{row?.unit}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Popover>
          </Box>
        );
      },
    },
    {
      field: "material_code",
      headerName: "Material Code",
      width: 270,
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title={"Costing Database Parameters"}>
            <Box
              sx={{
                textDecoration: "underline",

                cursor: "pointer",
                color:
                  cellValues?.row?.material_parameter?.length > 0
                    ? "green"
                    : "inherit",
              }}
              onClick={(e: any) => {
                // if (cellValues?.row?.material_parameter?.length > 0) {
                bomDBParamModal.open(cellValues?.row);
                // }
              }}
            >
              {cellValues?.row?.material_code}
            </Box>
          </Tooltip>
        );
      },
    },

    {
      field: "material_grade",
      headerName: "Grade",
      width: 270,
    },
    {
      field: "material_subgrade",
      headerName: "Sub Grade",
      align: "left",
      headerAlign: "left",

      width: 250,
    },

    {
      field: "material_cost",
      headerName: "Material Cost",
      width: 250,
    },
    {
      field: "scrap_cost",
      headerName: "Scrap Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,

      minWidth: 250,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Scrap Cost: ${cellValues?.row?.scrap_cost ?? "N/A"}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {typeof cellValues?.row?.scrap_cost === "number"
              ? cellValues.row.scrap_cost.toFixed(3)
              : cellValues?.row?.scrap_cost}
          </Box>
          // <Box title={`Scrap Cost: ${cellValues?.row?.scrap_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          //   {`${cellValues?.row?.scrap_cost?.toFixed(3)}`}
          // </Box>
        );
      },
    },

    {
      field: "density",
      headerName: "Density (kg/m3)",
      sortable: false,
      minWidth: 250,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Density (kg/m3): ${cellValues?.row?.density}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${cellValues?.row?.density?.toFixed(3)}`}
          </Box>
        );
      },
    },

    //    ------

    // {
    //     field: "shape",
    //     headerName: "Initial Shape",

    //     sortable: false,
    //     width: 80,
    //   },

    // {
    //   field: "currency_code",
    //   headerName: "Currency/Unit",
    //   sortable: false,
    //   minWidth: 80,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "flex-end",
    //           columnGap: "1rem",
    //         }}
    //       >
    //         {`${
    //           cellValues?.row?.currency_code
    //             ? cellValues?.row?.currency_code
    //             : "--"
    //         }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
    //       </Box>
    //     );
    //   },
    // },

    // {
    //   field: "material_source",
    //   headerName: "Material Source",
    //   sortable: false,
    //   minWidth: 120,
    // },

    // {
    //   field: "material_reference",
    //   headerName: "Material Ref",
    //   sortable: false,
    //   minWidth: 120,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <>
    //         {isUrl(cellValues?.row?.material_reference) ? (
    //           <span
    //             style={{ textDecoration: "underline", cursor: "pointer" }}
    //             onClick={() =>
    //               handleOpenLinkReference(cellValues?.row?.material_reference)
    //             }
    //           >
    //             Link
    //           </span>
    //         ) : (
    //           <span style={{ textDecoration: "underline", cursor: "pointer" }}>
    //             {cellValues?.row?.material_reference}
    //           </span>
    //         )}
    //       </>
    //     );
    //   },
    // },

    // {
    //   field: "scrap_source",
    //   headerName: "Scrap Source",
    //   sortable: false,
    //   minWidth: 100,
    // },
    // {
    //   field: "scrap_reference",
    //   headerName: "Scrap Ref",

    //   sortable: false,
    //   width: 50,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <>
    //         {isUrl(cellValues?.row?.scrap_reference) ? (
    //           <span
    //             style={{ textDecoration: "underline", cursor: "pointer" }}
    //             onClick={() =>
    //               handleOpenLinkReference(cellValues?.row?.scrap_reference)
    //             }
    //           >
    //             Link
    //           </span>
    //         ) : (
    //           <span
    //           // style={{ textDecoration: "underline", cursor: "pointer" }}
    //           // onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}
    //           >
    //             {cellValues?.row?.scrap_reference}
    //           </span>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "created_at",
    //   headerName: "Date Added",

    //   sortable: false,
    //   minWidth: 100,
    // },
  ];

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows && rows,
  };

  //   tableData?.map((item: any, index: any) => {
  //     let objShared: any = {
  //         id: item?.id,
  //         sno: index + 1,
  //         material_code: item?.material_code,
  //         material_grade: item?.material_grade,
  //         material_subgrade: item?.material_subgrade,
  //         shape: item?.shape,
  //         density: item?.density,
  //         description: item?.description,
  //         material_cost: item?.material_cost,
  //         material_source: item?.material_source,
  //         material_reference: item?.material_reference,
  //         scrap_cost: item?.scrap_cost,
  //         scrap_source: item?.scrap_source,
  //         scrap_reference: item?.scrap_reference,
  //         mapped_status: item?.mapped_status,
  //         category: item?.category,
  //         currency_id: item?.currency,
  //         currency_code: item?.currency_code,
  //         unit: item?.unit,
  //         lock: item?.lock,
  //         parameter_details:item?.parameter_details
  //     };
  //     rows.push(objShared);
  // });

  console.log(parameter, "parameter");

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "2.5rem",
          }}
        >
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            {/* {fetchedMaterial?.material_code &&  */}
            <>
              <span style={{ color: "red", marginRight: "0.25rem" }}>*</span>
              Fetched Material&nbsp;: &nbsp;
              {fetchedMaterial?.material_code ? (
                <b>
                  {fetchedMaterial?.material_code}{" "}
                  {`(${fetchedMaterial?.category})`}
                </b>
              ) : (
                <span style={{ color: "grey" }}>No material fetched yet</span>
              )}
            </>
            {/* } */}
          </Typography>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",

              textAlign: "center",
              marginRight: "-2rem",
            }}
          ></Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={() => {
              if (fetchCount > 0) {
                getBOMParametersData();
                BOMValidationStatus();
              }
              onClose();
            }}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />

        <Box
          sx={{
            width: "100%",
            // display:"flex",alignItems:"flex-start"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                width: "50%",
                marginBottom: "0.5rem",
              }}
            >
              <Box
                sx={{
                  padding: "0.5rem 0.5rem",
                  borderRadius: "0.1rem",
                  width: "32%",
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  backgroundColor: value == 0 ? "primary.main" : "inherit",
                  color: value == 0 ? "#fff" : "primary.main",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;",
                }}
                onClick={() => {
                  setValue(0);
                  setCategory("STEEL");
                }}
              >
                Steel
              </Box>

              <Box
                sx={{
                  padding: "0.5rem 0.5rem",
                  borderRadius: "0.1rem",
                  width: "32%",
                  cursor: "pointer",
                  display: "grid",
                  placeItems: "center",
                  backgroundColor: value == 1 ? "primary.main" : "inherit",
                  color: value == 1 ? "#fff" : "primary.main",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;",
                }}
                onClick={() => {
                  setValue(1);
                  setCategory("PLASTIC");
                }}
              >
                Plastic
              </Box>

              <Box
                sx={{
                  padding: "0.5rem 0.5rem",
                  borderRadius: "0.1rem",
                  cursor: "pointer",
                  width: "32%",
                  display: "grid",
                  placeItems: "center",
                  backgroundColor: value == 2 ? "primary.main" : "inherit",
                  color: value == 2 ? "#fff" : "primary.main",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;",
                }}
                onClick={() => {
                  setValue(2);
                  setCategory("RUBBER");
                }}
              >
                Rubber
              </Box>
            </Box>

            {/* {fetchedMaterial?.material_code && <Box sx={{marginRight:"1.5rem"}}>
        
              Fetched Material&nbsp;: &nbsp;<b>{fetchedMaterial?.material_code} {`(${fetchedMaterial?.category})`}</b>

          </Box>} */}
          </Box>

          <Box
            sx={{
              height: "70vh",
              width: "100%",
              "& .super-app-theme--true": {
                bgcolor: "#d8e0e6",
                //    color:"#fff"
              },
            }}
          >
            <DataGrid
              headerHeight={42}
              rowHeight={36}
              {...dataStructureDataGrid}
              pagination={false}
              hideFooter
              loading={tableDataLoading}
              getRowClassName={(params) =>
                `super-app-theme--${params.row?.item_fetched}`
              }
              // checkboxSelection
              // disableRowSelectionOnClick
              // components={{
              //   Toolbar: () => {
              //     return CustomToolbar();
              //   },
              // }}
              sx={{
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
            />
          </Box>
        </Box>

        {/* {bomDBParamModal.isOpen && (
          <BOMDBParamModal
            isOpen={bomDBParamModal.isOpen}
            onClose={() => bomDBParamModal.close()}
            parameters={bomDBParamModal.propsId}
          />
        )} */}

        {bomDBParamModal.isOpen && (
          <CreateRevisionDatabaseModal
            isOpen={bomDBParamModal.isOpen}
            onCloseModal={bomDBParamModal.close}
            actions={bomDBParamModal.propsId}
            expanded={false}
            getMaterialData={() => {}}
            categoryList={categoryList}
            getCategoryList={getCategoryList}
            databaseOnly={true}
            readOnly={true}
          />
        )}
      </Box>
    </Modal>
  );
}

export default BOMDatabaseModal;
