import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';
import { API } from '../../../api-services';
import { Box, Checkbox, Skeleton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function LabourDB({ database, selected, setSelected }: any) {
  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };
    const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
    const [categoryList, setCategoryList] = React.useState<any>([]);
    const [tabValue, setTabValue] = useState<any>(null);
    const [tableData, setTableData] = React.useState<any>([]);
    const [filteredFields, setFilteredFields] = React.useState<any>([]);
    const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

    const handleChange = (categoryName: any) => {
      setTabValue(categoryName);
    };
  
    const getCategoryList = (database: any) => {
      setAccordioinLoading(true);
      API.get(
        "/api/db/cost_databases/",
        {
          database_type: fetchObj?.[database],
        },
        0
      )
        .then((res: any) => {
          setCategoryList(res.data);
          setTabValue(res.data?.[0]?.id);
          setAccordioinLoading(false);
        })
        .catch((err: any) => {
          setAccordioinLoading(false);
        });
    };
  const getLabourTableData = (categoryName:any) => {
      setTabledataLoading(true)
      API.get("/api/db/labour/", {
            commodity: categoryName,
          })
            .then((res: any) => {
              
              setTableData(res?.data);
            })
            .catch((err: any) => {
              
              console.log("Sever EEror");
            }).finally(()=> {
              setTabledataLoading(false)
            });
    }



    const columns: any = [
       {
                field: "actions",
                headerName: "Select",
                width: 100,
                renderCell: (cellValues: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        columnGap: "1rem",
                      }}
                    >
                      <Checkbox
                        checked={selected?.id == cellValues?.row?.id}
                        onChange={() => setSelected(cellValues?.row)}
                      />
                    </Box>
                  );
                },
              },
      { field: "Ids", headerName: "Sl.No", width: 90 },
  
      {
        field: "labour_code",
        headerName: "Labour Code",
        width: 180,
      },
  
      {
        field: "labour_type",
        headerName: "Labour Type",
        width: 150,
      },
      {
        field: "currency_code",
        headerName: "Currency/Unit",
        sortable: false,
        minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
        renderCell: (cellValues: any) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                columnGap: "1rem",
              }}
            >
              {`${
                cellValues?.row?.currency_code
                  ? cellValues?.row?.currency_code
                  : "--"
              }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
            </Box>
          );
        },
      },
  
      {
        field: "description",
        headerName: "Description",
        width: width > 1400 ? 800 : 650,
        flex: 1,
      },
    ];
  
    const Rows: any = () =>
      tableData &&
      tableData?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));

      let dataStructureDataGrid: any = {
        columns: columns,
        rows: Rows(),
      };
  
   useEffect(() => {
      getCategoryList(database);
    }, [database]);
   useEffect(()=> {
      getLabourTableData(tabValue);
    },[tabValue])

  return (
    <Box sx={{ display: "flex", gap: "2%" }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        columnGap: "0.5rem",
        width: "19%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
        marginLeft: "1rem",
         maxHeight:"80vh"
      }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.2"
      
    >
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          {categoryList &&
            categoryList?.map((item: any, index: any) => {
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      padding:"0.2rem 0.5rem",
                      color: "primary.main",
                      // height: "2rem",
                      // minHeight: "2.1rem",
                      maxWidth: "100% !important",
                      
                      fontSize: "1rem",
                      width: "100%",
                      backgroundColor:
                        item?.id == tabValue ? "primary.main" : "",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "0.1px solid #edf5ef",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          item?.id == tabValue
                            ? "white"
                            : "primary.main",
                        // height: "2rem",
                        // minHeight: "2.1rem",
                        maxWidth: "100% !important",
                        padding: "0.1rem 0rem",
                        fontSize: "1rem",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleChange(item?.id)}
                    >
                      {item?.name?.toLowerCase()} ({item?.count})
                    </Typography>
                  </Box>
                </>
              );
            })}
        </>
      )}
    </Box>

    <Box
      sx={{
        flex: 1,
        height: { lg: "84vh", xl: "85vh" },
        width: "100%",
        "& .super-app-theme--cell": {
          backgroundColor: "#ddefff !important",
          textAlign: "center !important",
        },
      }}
    >
      <DataGrid
        headerHeight={42}
        rowHeight={36}
        {...dataStructureDataGrid}
        loading={tableDataLoading}
        // components={{
        //   Toolbar: () => {
        //     return CustomToolbar();
        //   },
        // }}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </Box>
  </Box>
  )
}

export default LabourDB