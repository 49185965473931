import React from "react";
import { Box, Button, Checkbox, CircularProgress, Skeleton, TableCell, TableRow } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import scenerioDoneIcon from "../../Assets/images/scenarioDone.svg";
import LppIcon from "../../Assets/images/LppIcon.svg";
import scenerioInProgressIcon from "../../Assets/images/scenarioInProgress.svg";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import styles from "./CostingCreateSystemInsideBodyRow.module.scss";
import Swal from "sweetalert2";
import swal from "sweetalert";
import successIcon from "../../Assets/images/success.png";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import CopySourceToDestModal from "./CopySourceToDestModal";


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Params {
  projectId: any;
  projectName: any;
  top_vault: any;
  abbr: any;
  top_vaultName: any;
  initialTopVault: any;
  topVaultIds: any;
  expandTable: any;
  vault_id: any;
}
interface ICostingCreateSystemInsideBodyRowProps {
  costingTableData: any;
  handleOpenHierarchy: any;
  SheetViewHandler: any;
  sessionGetInto: any;
  costingTableDataParent: any;
  setIconText: any;
  setContentText: any;
  openStatusContent: any;
  categoryCopy: any;
  setCategoryCopy: any;
  handleChangePasteCheckbox: any;
  pasteVaultNoList: any;
  copyVaultCategory: any;
  setCopyVaultCategory: any;
  setCopyVaultNo: any;
  showCheckboxCopyClicked: any;
  setShowCheckboxCopyClicked: any;
  hoveredIndex: any;
  setHoveredIndex: any;
  reloadCostingData: any;
  setReloadCostingData: any;
  permissionCosting: any;
  loaderRecursiveId: any;
}

const CostingCreateSystemInsideBodyRow: React.FC<
  ICostingCreateSystemInsideBodyRowProps
> = (props) => {
  const {
    costingTableData,
    handleOpenHierarchy,
    SheetViewHandler,
    sessionGetInto,
    costingTableDataParent,
    setIconText,
    setContentText,
    openStatusContent,
    categoryCopy,
    setCategoryCopy,
    handleChangePasteCheckbox,
    pasteVaultNoList,
    copyVaultCategory,
    setCopyVaultCategory,
    setCopyVaultNo,
    showCheckboxCopyClicked,
    setShowCheckboxCopyClicked,
    hoveredIndex,
    setHoveredIndex,
    reloadCostingData,
    setReloadCostingData,
    permissionCosting,
    loaderRecursiveId,
  } = props;
  const {
    projectId,
    projectName,
    top_vault,
    abbr,
    top_vaultName,
    initialTopVault,
    topVaultIds,
    expandTable,
    vault_id,
  } = useRouteParams<Params>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElDownload, setAnchorElDownload] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElDownload);
  const openDownload = Boolean(anchorEl);
  const [anchorElCopy, setAnchorElCopy] = React.useState<null | HTMLElement>(
    null
  );
  const handleDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDownload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };
  const [sourceId, setSourceID] = React.useState<any>();

  const [visibleChildren, setVisibleChildren] = React.useState<number>(300);
  const loadMoreChildren = () => {
   setVisibleChildren((prev) => prev + 300); // Load 300 more children
 };

  const openCopy = Boolean(anchorElCopy);
  const openDialogModal = useBaseModal();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const sheetview = useBaseModal()
  const history = useHistory();
  const CopySrcToDest = useBaseModal();

  const handleExcelReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
      handleCloseDownload();
    } catch (e) { }
  };

  const handleExcelDetailReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
          detail_report: true,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
      handleCloseDownload();
    } catch (e) { }
  }


  // console.log(costingTableData,"costingTableDatacostingTableData")
  const hadleDetailedReportDownload = (event: React.MouseEvent<HTMLElement>) => {
    costingTableData?.is_leaf
      ? handleExcelDetailReport(costingTableData?.id)
      : setAnchorEl(event.currentTarget);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    costingTableData?.is_leaf
      ? handleExcelReport(costingTableData?.id)
      : setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCopy = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElCopy(event.currentTarget);
  };

  const handleCloseCopy = () => {
    setAnchorElCopy(null);
  };

  const handleChangeCopyCheckbox: any = (
    vaultIdComing: any,
    vaultCategory: any
  ) => {
    setCopyVaultNo(vaultIdComing);
    setCopyVaultCategory(vaultCategory);
    setShowCheckboxCopyClicked(true);
    handleCloseCopy();
  };

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleCopyToOther: any = (e: any, id: any) => {
    e.stopPropagation();

    setSourceID(id);
    CopySrcToDest.open();

  }

  const handleDeleteCosting: any = (e: any, id: any) => {
    e.stopPropagation();
    swal({
      title: `Delete Data & Cost model`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteCosting`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteCosting":
          API.delete("cost/share_calculator/", { vault: id, type: "config" }, 0)
            .then((res: any) => {
              Swal.fire({
                html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                          <br />
                          <p style="color:#007fff;"> The cost model with data has been deleted.
                          </p>   
                        </div>`,
              });
              setReloadCostingData(!reloadCostingData);
            })
            .catch((err: any) => {
              console.log(
                err,
                "Unable to delete cost model & data. Server Error"
              );
            });
          break;
        default:
          swal(`Data & Cost model saved from deleting`, { icon: "error" });
      }
    });
  };

  const redirectBOMnotValidated = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please validate BOM first to redirect to the costing page.</p>   
       </div>`,
    });
  };

  const redirectConfigNotValidated = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please validate the costing configuration first to redirect to the costing page.</p>   
       </div>`,
    });
  };
  const redirectCategoryNotMapped = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please create costing configuration for this BOM category to redirect to the costing page.</p>   
       </div>`,
    });
  };

  const redirectToCostingPage = () => {
    sessionStorage.setItem(
      "CostingCreateCalc",
      JSON.stringify(costingTableData?.ancestors)
    );
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${top_vault}/${costingTableData?.id
      }/${costingTableData?.abbreviation == null
        ? "none"
        : costingTableData?.abbreviation
      }/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const redirectToCostingMainpage = (e: any, item: any) => {
    // console.log(item, "itemitemitem");
    let LastIndex = item?.ancestors?.length - 1;
    let mainIds = item?.ancestors[LastIndex];

    sessionStorage.setItem(
      "CostingCreateCalc",
      JSON.stringify(item?.ancestors)
    );
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${top_vault}/${mainIds}/${item?.abbreviation == null ? "none" : item?.abbreviation
      }/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const handleRedirectToLpp = (
    abbreviationComing: any,
    partid: any,
    depth: any
  ) => {
    if (abbreviationComing == null) {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/Part/${partid}/${depth}/${vault_id}`
      );
    } else {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbreviationComing}/0/0/${vault_id}`
      );
    }
  };
  const handleSheetView = (e: any, item: any) => {
    SheetViewHandler(item)
  }

  const [gotoloader, setgotoloader] = React.useState(false);
  const [bompermission, setbompermission] = React.useState<any>();
  
  const gotoBOMHandler = (id: any, category: any) => {    
    sessionStorage.removeItem("BOMCreateNode");
    const modifiedAncestors = category?.ancestors.map((item: any, index: number) => {
      return index === 0 ? String(item) : item;
    });
    sessionStorage.setItem("BOMCreateNode", JSON.stringify(modifiedAncestors));

    setgotoloader(true);
    API.get(`/auth/calibration_user_permission/`, { vault: id, action: true, bom_create: true }, 0)
      .then((res: any) => {
        setgotoloader(false);
        setbompermission(res?.data);

        // Check the permission after it's been updated
        if (res?.data?.action?.includes("R")) {
          window.open(`/#/bomentry/${projectId}/${projectName}/${initialTopVault}/${id}/none/0/false/false`);
        } else {
          Swal.fire({
            icon: "error",
            html: `<div>
            <br />
            <p style="color:"red">You do not have permission to access this page.</p>   
             </div>`,
          });
        }
      })
      .catch((err: any) => {
        setgotoloader(false);
        console.error(err);
      });

    // window.open(`/#/bomentry/${projectId}/${projectName}/${initialTopVault}/${id}/none/0/false/false`)
  }

  return (
    <>
      <DialogComponent
        isOpen={openDialogModal.isOpen}
        onClose={openDialogModal.close}
        content={
          "We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
        }
        type={"firstdownload"}
      />
      <TableRow
        sx={
          hoveredIndex == costingTableData?.id
            ? { backgroundColor: "#f7e1f4", zIndex: 1, position: "relative" }
            : {}
        }
        onMouseEnter={() => setHoveredIndex(costingTableData?.id)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <TableCell
          className={styles?.CheckboxTable}
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          <span
            style={
              sessionGetInto?.includes(costingTableDataParent?.id)
                ? {
                  marginLeft: `${costingTableData?.ancestors?.length - 2}rem`,
                  display: "flex",
                  alignItems: "center",
                }
                : { display: "flex", alignItems: "center" }
            }
          >
            {!costingTableData?.is_leaf &&
              (!sessionGetInto?.includes(costingTableData?.id) ? (
                <AddCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ) : (
                <RemoveCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ))}
            {costingTableData?.abbreviation != abbr &&
              copyVaultCategory == costingTableData?.category &&
              costingTableData?.cost_status != 3 &&
              showCheckboxCopyClicked && (
                <Checkbox
                  checked={pasteVaultNoList?.includes(costingTableData?.id)}
                  onChange={() =>
                    handleChangePasteCheckbox(costingTableData?.id)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ p: 0, mr: 1 }}
                  disabled={costingTableData?.bom_status < 3}
                />
              )}
            <span
              onClick={() => {
                costingTableData?.is_copy == false &&
                  (costingTableData?.cost_config_status === 1 ? redirectCategoryNotMapped() : costingTableData?.cost_config_status === 2 ? redirectConfigNotValidated()  :
                  
                
                    costingTableData?.bom_status === 3
                    ? redirectToCostingPage()
                    :  redirectBOMnotValidated());
              }}
              onContextMenu={handleClickCopy}
              style={
                !costingTableData?.is_leaf
                  ? {
                    color: `${costingTableData?.color}`,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "0.5rem",
                  }
                  : costingTableData?.abbreviation != abbr &&
                    copyVaultCategory == costingTableData?.category &&
                    costingTableData?.cost_status != 3 &&
                    showCheckboxCopyClicked
                    ? {
                      color: `${costingTableData?.color}`,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: "0.5rem",
                    }
                    : {
                      marginLeft: "2.3rem",
                      color: `${costingTableData?.color}`,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: "0.5rem",
                    }
              }
            >
              {costingTableData?.part_no}

              {costingTableData?.is_copy && (
                <CopyrightIcon style={{ color: "red" }} />
              )}
            </span>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              elevation={3}
              anchorEl={anchorElCopy}
              open={openCopy}
              onClose={handleCloseCopy}
            >{permissionCosting &&
              permissionCosting?.action?.includes("R") && (
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    width: '100%',
                  }}
                  onClick={() =>
                    gotoBOMHandler(
                      costingTableData?.id,
                      costingTableData
                    )
                  }
                >
                  Go to BOM {gotoloader && <CircularProgress size={18} />}
                </MenuItem>
              )}
              {costingTableData?.cost_status == 3 &&
                permissionCosting &&
                permissionCosting?.action?.includes("U") && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={() =>
                      handleChangeCopyCheckbox(
                        costingTableData?.id,
                        costingTableData?.category
                      )
                    }
                  >
                    Copy
                  </MenuItem>
                )}
              {costingTableData?.cost_status == 3 &&
                permissionCosting &&
                permissionCosting?.action?.includes("U") && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={(e: any) =>
                      handleCopyToOther(e, costingTableData?.id)
                    }
                  >
                    Copy to Other Project/Product
                  </MenuItem>
                )}
              {CopySrcToDest.isOpen && <CopySourceToDestModal isOpen={CopySrcToDest.isOpen} onClose={CopySrcToDest.close} sourceId={sourceId} handleAnchorClose={handleCloseCopy} />}
              {costingTableData?.is_copy == false &&
                userProfilePic &&
                userProfilePic[0]?.is_superuser && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={(e: any) =>
                      handleDeleteCosting(e, costingTableData?.id)
                    }
                  >
                    Delete Costing
                  </MenuItem>
                )}

              {costingTableData?.is_leaf == false && (
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                  }}
                  onClick={(e: any) =>
                    handleSheetView(e, costingTableData)
                  }
                >
                  Sheet View
                </MenuItem>
              )}

              {permissionCosting &&
                permissionCosting?.action?.includes("C") &&
                costingTableData?.is_copy == true && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={(e: any) =>
                      redirectToCostingMainpage(e, costingTableData)
                    }
                  >
                    Go to original costing
                  </MenuItem>
                )}
            </StyledMenu>
          </span>
        </TableCell>
        <TableCell
          onClick={() => {
            costingTableData?.is_copy == false &&
              (costingTableData?.cost_config_status === 1 ? redirectCategoryNotMapped()
               : costingTableData?.cost_config_status === 2 ? redirectConfigNotValidated()
                : costingTableData?.bom_status === 3
                ? redirectToCostingPage()
                : 
                redirectBOMnotValidated()
              )
          }}
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
          }}
        >
          <span style={{ textTransform: "capitalize" }}>
            {costingTableData?.name.toLowerCase()}
          </span>
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {costingTableData?.category}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.weight == 0
            ? costingTableData?.weight
            : costingTableData?.weight?.toFixed(2)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.Qty}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.cost_per_kg}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.per_unit_cost == 0
            ? costingTableData?.vault_details?.per_unit_cost
            : costingTableData?.vault_details?.per_unit_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.material_cost == 0
            ? costingTableData?.vault_details?.material_cost
            : costingTableData?.vault_details?.material_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.process_cost == 0
            ? costingTableData?.vault_details?.process_cost
            : costingTableData?.vault_details?.process_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.overhead_cost == 0
            ? costingTableData?.vault_details?.overhead_cost
            : costingTableData?.vault_details?.overhead_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >

          {costingTableData?.vault_details?.child_part_cost == 0
            ? costingTableData?.vault_details?.child_part_cost
            : costingTableData?.vault_details?.child_part_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.total_cost == 0
            ? costingTableData?.vault_details?.total_cost
            : costingTableData?.vault_details?.total_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "0.7rem",
            }}
          >
            {costingTableData?.cost_status == 3 && !costingTableData?.is_leaf ? (
              <Button
                id="demo-customized-button"
                aria-controls={
                  openDownload ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openDownload ? "true" : undefined}
                disableElevation
                onClick={(e: any) => handleClick(e)}
                style={{
                  padding: "0rem",
                  minWidth: "1rem",
                  margin: "0 0.2rem",
                }}
              >
                <CloudDownloadIcon sx={{ cursor: "pointer" }} />

              </Button>
            ) : (
              <Button
                id="demo-customized-button"
                aria-controls={
                  open ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleDownloadClick}
                style={{
                  padding: "0rem",
                  minWidth: "1rem",
                  margin: "0 0.2rem",
                }}
              >
                <CloudDownloadIcon sx={{ cursor: "pointer" }} />

              </Button>
            )}
            {costingTableData?.scenario_show &&
              (costingTableData?.scenario_exists ? (
                <img
                  src={scenerioDoneIcon}
                  height="18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sessionStorage.removeItem("nodeCostingScenario");
                    sessionStorage.setItem(
                      "nodeCostingScenario",
                      JSON.stringify(costingTableData?.ancestors)
                    );
                    sessionStorage.setItem("addScenarioStatus", "3");
                    window.open(
                      `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${costingTableData?.id
                      }/1/${costingTableData?.abbreviation
                        ? costingTableData?.abbreviation
                        : "none"
                      }/0`
                    );
                  }}
                />
              ) : (
                <img
                  src={scenerioInProgressIcon}
                  height="18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sessionStorage.removeItem("nodeCostingScenario");
                    sessionStorage.setItem(
                      "nodeCostingScenario",
                      JSON.stringify(costingTableData?.ancestors)
                    );
                    sessionStorage.setItem("addScenarioStatus", "3");
                    window.open(
                      `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${costingTableData?.id
                      }/1/${costingTableData?.abbreviation
                        ? costingTableData?.abbreviation
                        : "none"
                      }/0`
                    );
                  }}
                />
              ))}
            <img
              src={LppIcon}
              height="20px"
              style={{ marginLeft: "0.2rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirectToLpp(
                  costingTableData?.abbreviation,
                  costingTableData?.id,
                  costingTableData?.ancestors?.length - 1
                )
              }
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openDownload}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w", costingTableData?.id)}
              >
                Consolidated with images
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w/o", costingTableData?.id)}
              >
                Consolidated w/o images
              </MenuItem>
            </StyledMenu>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElDownload}
              open={open}
              onClose={handleCloseDownload}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={hadleDetailedReportDownload}
              >
                Detailed Costing Report
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={handleClick}
              >
                Summary Costing Report
              </MenuItem>
            </StyledMenu>
          </span>
        </TableCell>
      </TableRow>
      {loaderRecursiveId == costingTableData?.id && (
        <>
          <TableRow
          >
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
          <TableRow
          >
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
          <TableRow
          >
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        </>
      )}
      {/* {costingTableData?.data?.map((item: any, index: number) => {
        return (
          sessionGetInto?.includes(costingTableData?.id) && (
            <CostingCreateSystemInsideBodyRow
              costingTableData={item}

              handleOpenHierarchy={handleOpenHierarchy}
              SheetViewHandler={SheetViewHandler}
              sessionGetInto={sessionGetInto}
              costingTableDataParent={costingTableData}
              setIconText={setIconText}
              setContentText={setContentText}
              openStatusContent={openStatusContent}
              categoryCopy={categoryCopy}
              setCategoryCopy={setCategoryCopy}
              handleChangePasteCheckbox={handleChangePasteCheckbox}
              pasteVaultNoList={pasteVaultNoList}
              copyVaultCategory={copyVaultCategory}
              setCopyVaultCategory={setCopyVaultCategory}
              setCopyVaultNo={setCopyVaultNo}
              showCheckboxCopyClicked={showCheckboxCopyClicked}
              setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              reloadCostingData={reloadCostingData}
              setReloadCostingData={setReloadCostingData}
              permissionCosting={permissionCosting}
              loaderRecursiveId={loaderRecursiveId}
            />
          )
        );
      })} */}
      {costingTableData?.data &&
              costingTableData?.data
                .slice(0, visibleChildren) // Only render visible children
                .map((item: any) => (
                  sessionGetInto?.includes(costingTableData?.id) && (
                    <CostingCreateSystemInsideBodyRow
                      costingTableData={item}
        
                      handleOpenHierarchy={handleOpenHierarchy}
                      SheetViewHandler={SheetViewHandler}
                      sessionGetInto={sessionGetInto}
                      costingTableDataParent={costingTableData}
                      setIconText={setIconText}
                      setContentText={setContentText}
                      openStatusContent={openStatusContent}
                      categoryCopy={categoryCopy}
                      setCategoryCopy={setCategoryCopy}
                      handleChangePasteCheckbox={handleChangePasteCheckbox}
                      pasteVaultNoList={pasteVaultNoList}
                      copyVaultCategory={copyVaultCategory}
                      setCopyVaultCategory={setCopyVaultCategory}
                      setCopyVaultNo={setCopyVaultNo}
                      showCheckboxCopyClicked={showCheckboxCopyClicked}
                      setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
                      hoveredIndex={hoveredIndex}
                      setHoveredIndex={setHoveredIndex}
                      reloadCostingData={reloadCostingData}
                      setReloadCostingData={setReloadCostingData}
                      permissionCosting={permissionCosting}
                      loaderRecursiveId={loaderRecursiveId}
                    />
                  )
                ))}
            {costingTableData?.data &&
              costingTableData?.data.length > visibleChildren && (
                <Box display="flex" justifyContent="center" sx={{marginTop: '0.5rem'}}>
                  <p style={{cursor:'pointer',textDecoration:'underline'}} onClick={loadMoreChildren}>
                    load more
                  </p>
                </Box>
              )}
    </>
  );
};

export default CostingCreateSystemInsideBodyRow;
