import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Backdrop,
  Chip,
  Divider,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  category: yup.string().required("Please select a category"),
  material_code: yup.string().required("Please enter material code"),
  material_grade: yup.string(),
  material_subgrade: yup.string(),
  shape: yup.string(),
  density: yup.number().required("Please enter density"),
  description: yup.mixed(),
  material_cost: yup.number().required("Please enter Material Cost"),
  material_source: yup.mixed().required("Please enter Material Source"),
  material_reference: yup.mixed(),
  scrap_cost: yup.number().required("Please enter Scrap Cost"),
  scrap_source: yup.mixed(),
  scrap_reference: yup.mixed(),
  unit: yup.mixed(),
  currency_id: yup.mixed(),
});

const steelValidationSchema = yup.object({
  shearStrength: yup.object({
    unit: yup.mixed().required("Shear Strength unit is required"),
    value: yup
    .number()
    .nullable() // Allows null as a valid value
    .transform((value, originalValue) => {
      if (originalValue === "" || originalValue === null) return null; // Convert empty string or null to null
      return isNaN(value) ? null : value; // Convert invalid numbers to null
    })
      .required("Shear Strength is required")
      .min(0, "Value must be a positive number"),
  }),
  tensileStrength: yup.object({
    unit: yup.mixed().required("Tensile Strength unit is required"),
    value: yup
    .number()
    .nullable() // Allows null as a valid value
    .transform((value, originalValue) => {
      if (originalValue === "" || originalValue === null) return null; // Convert empty string or null to null
      return isNaN(value) ? null : value; // Convert invalid numbers to null
    })
      .required("Tensile Strength is required")
      .min(0, "Value must be a positive number"),
  }),
  yieldStrength: yup.object({
    unit: yup.mixed().required("Tensile Strength unit is required"),
    value: yup
    .number()
    .nullable() // Allows null as a valid value
    .transform((value, originalValue) => {
      if (originalValue === "" || originalValue === null) return null; // Convert empty string or null to null
      return isNaN(value) ? null : value; // Convert invalid numbers to null
    })
      .required("Yield Strength is required")
      .min(0, "Value must be a positive number"),
  }),
});

const plasticValidationSchema = yup.object({
  thermalDiffusivity: yup.object({
    unit: yup.mixed().required("Thermal Diffusivity unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Thermal Diffusivity is required")
      .min(0, "Value must be a positive number"),
  }),
  meltingTemperature: yup.object({
    unit: yup.mixed().required("Melting Temperature unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Melting Temperature is required")
      .min(0, "Value must be a positive number"),
  }),
  moldTemperature: yup.object({
    unit: yup.mixed().required("Mold Temperature unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Mold Temperature is required")
      .min(0, "Value must be a positive number"),
  }),
  heatDeflectionTemperature: yup.object({
    unit: yup.mixed().required("Heat Deflection Temperature unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Heat Deflection Temperature is required")
      .min(0, "Value must be a positive number"),
  }),
  specificHeatCapacityPlastics: yup.object({
    unit: yup.mixed().required("Specific Heat Capacity unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Specific Heat Capacity is required")
      .min(0, "Value must be a positive number"),
  }),
  annealingTemperaturePlastics: yup.object({
    unit: yup.mixed().required("Annealing Temperature unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Annealing Temperature is required")
      .min(0, "Value must be a positive number"),
  }),
  materialConstantInjectionMoulding: yup.object({
    unit: yup.mixed().required("Material Constant unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Material Constant is required")
      .min(0, "Value must be a positive number"),
  }),
  branchRunnerLength: yup.object({
    unit: yup.mixed().required("Branch Runner Length unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Branch Runner Length is required")
      .min(0, "Value must be a positive number"),
  }),
  cavityPressure: yup.object({
    unit: yup.mixed().required("Cavity Pressure unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Cavity Pressure is required")
      .min(0, "Value must be a positive number"),
  }),
  injectionEfficiency: yup.object({
    unit: yup.mixed().required("Injection Efficiency unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Injection Efficiency is required")
      .min(0, "Value must be a positive number"),
  }),
});

const rubberValidationSchema = yup.object({
  specificHeatCapacity: yup.object({
    unit: yup.mixed().required("Specific Heat Capacity unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Specific Heat Capacity is required")
      .min(0, "Value must be a positive number"),
  }),
  materialWorkingTemperature: yup.object({
    unit: yup.mixed().required("Material Working Temperature unit is required"),
    value: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || originalValue === null) return null;
        return isNaN(value) ? null : value;
      })
      .required("Material Working Temperature is required")
      .min(0, "Value must be a positive number"),
  }),
});


const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICreateRevisionDatabaseModalProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  categoryList?: any;
  getCategoryList: any;
  databaseOnly?:any
  readOnly?:any
  
}

function CreateRevisionDatabaseModal(props: ICreateRevisionDatabaseModalProps) {
  const {
    isOpen,
    onCloseModal,
    actions,
    expanded,
    getMaterialData,
    categoryList,
    getCategoryList,
    databaseOnly,
    readOnly
  } = props;
  const { revisionId, typeId, databaseName, categoryName } =
    useRouteParams<any>();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [category, setCategory] = React.useState<any>();
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");
  // ----------------------------------------------
  const classes = useStyles();
  React.useEffect(() => {
    if (actions !== "add") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes([actions?.currency_code]);
      } else {
        setSelectedCurrencyCodes([]);
      }

      // console.log(actions, "parameter", actions.category, actions.category == "STEEL");
      

      formik.setFieldValue("description", actions.description || "");
      formik.setFieldValue(
        "material_cost",
        parseFloat(actions?.material_cost) || ""
      );
      formik.setFieldValue("material_source", actions.material_source || "");
      formik.setFieldValue(
        "material_reference",
        actions?.material_reference || ""
      );
      formik.setFieldValue("scrap_cost", parseFloat(actions.scrap_cost) || "");
      formik.setFieldValue("scrap_source", actions.scrap_source || "");
      formik.setFieldValue("scrap_reference", actions.scrap_reference || "");
      formik.setFieldValue("category", actions.category || "");
      formik.setFieldValue("material_code", actions.material_code || "");
      formik.setFieldValue("material_grade", actions.material_grade || "");
      formik.setFieldValue(
        "material_subgrade",
        actions.material_subgrade || ""
      );
      formik.setFieldValue("shape", actions.shape || "");
      formik.setFieldValue("density", actions.density || "");
      formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);

      const parameterArr = actions?.parameter_details ? actions?.parameter_details : actions?.material_parameter;
      
     if (actions.category == "STEEL") {
     
      
      if (parameterArr) {
        parameterArr?.forEach((param:any) => {
          switch (param?.name) {
            case "Shear Strength":
              formik.setFieldValue("shearStrength", param);
              break;
            case "Ultimate Tensile Strength":
              formik.setFieldValue("tensileStrength", param);
              break;
            case "Yield Strength":
              formik.setFieldValue("yieldStrength", param);
              break;
            default:
              break;
          }
        });
      }
     
     }


     if (actions.category == "RUBBER") {
     
      
      if (parameterArr) {
        parameterArr?.forEach((param:any) => {
          switch (param?.name) {
            case "Specific Heat Capacity":
              formik.setFieldValue("specificHeatCapacity", param);
              break;
            case "Material Working Temperature":
              formik.setFieldValue("materialWorkingTemperature", param);
              break;
          
            default:
              break;
          }
        });
      }
     
     }

     if (actions.category == "PLASTIC") {
     
      
      if (parameterArr) {
        parameterArr?.forEach((param:any) => {
          switch (param?.name) {
            case "Thermal Diffusivity":
              formik.setFieldValue("thermalDiffusivity", param);
              break;
            case "Melting Temperature":
              formik.setFieldValue("meltingTemperature", param);
              break;
            case "Mold Temperature":
              formik.setFieldValue("moldTemperature", param);
              break;
              case "Heat Deflection Temperature":
              formik.setFieldValue("heatDeflectionTemperature", param);
              break;
            case "Specific Heat Capacity - Plastics":
              formik.setFieldValue("specificHeatCapacityPlastics", param);
              break;
            case "Annealing Temperature - Plastics":
              formik.setFieldValue("annealingTemperaturePlastics", param);
              break;
              case "Material Constant (Injection Moulding)":
                formik.setFieldValue("materialConstantInjectionMoulding", param);
                break;
              case "Branch Runner Length":
                formik.setFieldValue("branchRunnerLength", param);
                break;
              case "Cavity Pressure":
                formik.setFieldValue("cavityPressure", param);
                break;
                case "Injection Efficiency":
                formik.setFieldValue("injectionEfficiency", param);
                break;
            default:
              break;
          }
        });
      }
     
     }
    }
  }, [actions]);



  const formik: any = useFormik({
    initialValues: {
      category: "",
      material_code: "",
      material_grade: "",
      material_subgrade: "",
      shape: "",
      density: "",
      description: "",
      material_cost: "",
      material_source: "",
      material_reference: "",
      scrap_cost: "",
      scrap_source: "",
      scrap_reference: "",
      unit: "",
      currency_id: "",
      currency_code: "",

      // STEEL
      shearStrength: { name: "Shear Strength", unit: "N/mm^2", value: null },
      tensileStrength: {
        name: "Ultimate Tensile Strength",
        unit: "N/mm^2",
        value: null,
      },
      yieldStrength: { name: "Yield Strength", unit: "N/mm^2", value: null },

      // Plastic
    thermalDiffusivity: {
      name: "Thermal Diffusivity",
      unit: "mm^2/sec ​",
      value: null,
    },
    meltingTemperature: {
      name: "Melting Temperature",
      unit: "deg C",
      value: null,
    },
    moldTemperature: {
      name: "Mold Temperature",
      unit: "deg C",
      value: null,
    },
    heatDeflectionTemperature: {
      name: "Heat Deflection Temperature",
      unit: "deg C",
      value: null,
    },
    specificHeatCapacityPlastics: {
      name: "Specific Heat Capacity - Plastics",
      unit: "J/g-deg C",
      value: null,
    },
    annealingTemperaturePlastics: {
      name: "Annealing Temperature - Plastics",
      unit: "deg C",
      value: null,
    },
    materialConstantInjectionMoulding: {
      name: "Material Constant (Injection Moulding)",
      unit: "cnst",
      value: null,
    },
    branchRunnerLength: {
      name: "Branch Runner Length",
      unit: "mm",
      value: null,
    },
    cavityPressure: {
      name: "Cavity Pressure",
      unit: "PSI",
      value: null,
    },
    injectionEfficiency: {
      name: "Injection Efficiency",
      unit: "%",
      value: null,
    },
// Rubber
    specificHeatCapacity: { name: "Specific Heat Capacity", unit: "J/g-deg C", value: null },
  materialWorkingTemperature: { name: "Material Working Temperature", unit: "deg C", value: null },
    },
    validationSchema: (actions?.category == "STEEL" || category == "STEEL") ? validationSchema.concat(steelValidationSchema): (actions?.category == "PLASTIC" || category == "PLASTIC") ? validationSchema.concat(plasticValidationSchema) : (actions?.category == "RUBBER" || category == "RUBBER") ? validationSchema.concat(rubberValidationSchema) : validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
       // Exclude currency_code and extract other fields
       const { currency_code, shearStrength, tensileStrength, yieldStrength, injectionEfficiency,
        thermalDiffusivity, meltingTemperature, moldTemperature, heatDeflectionTemperature, 
        specificHeatCapacityPlastics, annealingTemperaturePlastics, materialConstantInjectionMoulding, 
        branchRunnerLength, cavityPressure, specificHeatCapacity, materialWorkingTemperature,
         ...rest } = values;

       const apiPayload: any = {
         ...rest,
         currency_id: String(values.currency_id), // Ensure currency_id is a string
       };
    
    

    if (values.category === "STEEL" || actions.category === "STEEL") {
      apiPayload.parameter_details = [
        { ...shearStrength },
        { ...tensileStrength },
        { ...yieldStrength },
      ];
    }

    if (values.category === "RUBBER" || actions.category === "RUBBER") {
      apiPayload.parameter_details = [
        { ...specificHeatCapacity },
        { ...materialWorkingTemperature },
        
      ];
    }

    if (values.category === "PLASTIC" || actions.category === "PLASTIC") {
      apiPayload.parameter_details = [
        { ...thermalDiffusivity },
        { ...meltingTemperature },
        { ...moldTemperature },
        { ...heatDeflectionTemperature },
        { ...specificHeatCapacityPlastics },
        { ...annealingTemperaturePlastics },
        { ...materialConstantInjectionMoulding },
        { ...branchRunnerLength },
        { ...cavityPressure },
        {...injectionEfficiency}
      ];
    }

      if (actions === "add") {
        API.post("/api/db/material/", apiPayload, {})
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              API.post("/api/db/material_cost/", {
                material_ids: [res.data.id],
                revision: revisionId,
              })
                .then((res: any) => {
                  setIsLoadingSave(false);
                  getMaterialData(categoryName);
                  onCloseModal();
                  // :revisionId/:typeId/:databaseName/:categoryName/:status/:search
                })
                .catch((err: any) => {})
                .finally(() => {
                  onCloseModal();
                });
            }
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          })
          .finally(() => {
            setIsLoadingSave(false);
            getMaterialData(expanded);
            getCategoryList();
            onCloseModal();
          });
      } else {
        API.put(`/api/db/material/${actions.id}/`, apiPayload, {}, 0)
          .then((res: any) => {
            setIsLoadingSave(false);
            getMaterialData(expanded);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });

 

  React.useEffect(()=> {
    if (formik.values) {
    setCategory(formik.values.category)
    }
  },[formik.values])

  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

 
  //  console.log(formik.values, "parameter", category);
  // console.log(actions, "parameter");

  const additionalInputsArr = (actions?.category == "STEEL" || category == "STEEL") ? ["shearStrength", "tensileStrength", "yieldStrength"] 
  : (actions?.category == "PLASTIC" || category == "PLASTIC") ? ["thermalDiffusivity", "meltingTemperature", "moldTemperature", "heatDeflectionTemperature", "specificHeatCapacityPlastics","injectionEfficiency", "annealingTemperaturePlastics", "materialConstantInjectionMoulding", "branchRunnerLength", "cavityPressure"]
  : (actions?.category == "RUBBER" || category == "RUBBER") ? ["specificHeatCapacity", "materialWorkingTemperature"] : []
 
  //  console.log(actions?.category, "parameter actions" , );
   console.log( "parameter formik", formik.values, actions?.category);
  //  console.log( "parameter categoryList", categoryList);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {readOnly ?  "" : (actions === "add" ? "Add" : "Edit")} Raw Material Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "0 2rem 1rem",
              textAlign: "center",
              maxHeight: "80vh",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select category <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    id="category"
                    name="category"
                    readOnly={readOnly}
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={
                      formik.values.category !== null
                        ? formik.values.category
                        : ""
                    }
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {categoryList &&
                      categoryList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.category}
                          >
                            {item.category}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.category &&
                      Boolean(formik.errors.category) && (
                        <p>{formik.errors.category}</p>
                      )}
                  </div>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                  
                    variant="standard"
                    label={
                      <span>
                        Material Code <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_code"
                    name="material_code"
                    onChange={formik.handleChange}
                    value={formik.values.material_code}
                    multiline
                    error={
                      formik.touched.material_code &&
                      Boolean(formik.errors.material_code)
                    }
                    helperText={
                      formik.touched.material_code &&
                      formik.errors.material_code
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                  
                    variant="standard"
                    label="Material Grade"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_grade"
                    name="material_grade"
                    onChange={formik.handleChange}
                    value={formik.values.material_grade}
                    multiline
                    error={
                      formik.touched.material_grade &&
                      Boolean(formik.errors.material_grade)
                    }
                    helperText={
                      formik.touched.material_grade &&
                      formik.errors.material_grade
                    }
                
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Material sub-grade"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_subgrade"
                    name="material_subgrade"
                    onChange={formik.handleChange}
                    value={formik.values.material_subgrade}
                    multiline
                    error={
                      formik.touched.material_subgrade &&
                      Boolean(formik.errors.material_subgrade)
                    }
                    helperText={
                      formik.touched.material_subgrade &&
                      formik.errors.material_subgrade
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Shape"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="shape"
                    name="shape"
                    onChange={formik.handleChange}
                    value={formik.values.shape}
                    multiline
                    error={formik.touched.shape && Boolean(formik.errors.shape)}
                    helperText={formik.touched.shape && formik.errors.shape}
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Density <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    //   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',style: { color: "#007fff" }}}
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="density"
                    name="density"
                    onChange={formik.handleChange}
                    value={formik.values.density}
                    error={
                      formik.touched.density && Boolean(formik.errors.density)
                    }
                    helperText={formik.touched.density && formik.errors.density}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Description"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Raw material Cost <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_cost"
                    name="material_cost"
                    onChange={formik.handleChange}
                    value={formik.values.material_cost}
                    error={
                      formik.touched.material_cost &&
                      Boolean(formik.errors.material_cost)
                    }
                    helperText={
                      formik.touched.material_cost &&
                      formik.errors.material_cost
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Material Source <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_source"
                    name="material_source"
                    onChange={formik.handleChange}
                    value={formik.values.material_source}
                    multiline
                    error={
                      formik.touched.material_source &&
                      Boolean(formik.errors.material_source)
                    }
                    helperText={
                      formik.touched.material_source &&
                      formik.errors.material_source
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_reference"
                    name="material_reference"
                    onChange={formik.handleChange}
                    value={formik.values.material_reference}
                    multiline
                    error={
                      formik.touched.material_reference &&
                      Boolean(formik.errors.material_reference)
                    }
                    helperText={
                      formik.touched.material_reference &&
                      formik.errors.material_reference
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Scrap Cost <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_cost"
                    name="scrap_cost"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_cost}
                    error={
                      formik.touched.scrap_cost &&
                      Boolean(formik.errors.scrap_cost)
                    }
                    helperText={
                      formik.touched.scrap_cost && formik.errors.scrap_cost
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_source"
                    name="scrap_source"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_source}
                    multiline
                    error={
                      formik.touched.scrap_source &&
                      Boolean(formik.errors.scrap_source)
                    }
                    helperText={
                      formik.touched.scrap_source && formik.errors.scrap_source
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_reference"
                    name="scrap_reference"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_reference}
                    multiline
                    error={
                      formik.touched.scrap_reference &&
                      Boolean(formik.errors.scrap_reference)
                    }
                    helperText={
                      formik.touched.scrap_reference &&
                      formik.errors.scrap_reference
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      readOnly={readOnly}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);

                        formik.setFieldValue("currency_id", selectedCurIds);
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                        readOnly: readOnly,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Divider sx={{marginTop:"3rem", marginBottom:"2rem"}} />
             {/* {formik.values.category == "STEEL" &&  */}
             <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: "2rem",
                  backgroundColor:"#e3e8e8",
                  padding:"1rem"
                }}
              >
                {/* {additionalInputsArr?.map((itmm:any)=> <>hi</>)} */}

                
                {additionalInputsArr?.map(
                  (field) => {
                  
                    return (
                      (
                        <Box
                          key={field}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            marginBottom: "1rem",
                            width: "49%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "0.5rem",
                              width: "100%",
                            }}
                          >
                            {/* Value Input */}
                            <FormControl fullWidth variant="standard">
                              <TextField
                                variant="standard"
                                inputProps={{
                                  readOnly: readOnly,
                                }}
                                label={
                                  <>
                                    {field
                                      .replace(/([A-Z])/g, " $1")
                                      ?.replace(/^./, (str) => str?.toUpperCase())}
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                }
                                name={`${field}.value`}
                                placeholder="Enter value"
                                onChange={formik.handleChange}
                                value={
                                  formik.values[field as keyof typeof formik.values]
                                    ?.value
                                }
                                type="number"
                                error={Boolean(
                                  formik.touched[
                                    field as keyof typeof formik.touched
                                  ]?.value &&
                                    formik.errors[
                                      field as keyof typeof formik.errors
                                    ]?.value
                                )}
                                helperText={
                                  formik.touched[
                                    field as keyof typeof formik.touched
                                  ]?.value &&
                                  formik.errors[field as keyof typeof formik.errors]
                                    ?.value
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  sx: { color: "primary.main" }, // Label color fix
                                }}
                                sx={{
                                  "& .MuiInputBase-root.MuiInput-root": {
                                    fontSize: "1rem",
                                    color: "primary.main", // Placeholder & input text color fix
                                  },
                                }}
                                
                              />
                            </FormControl>
    
                            {/* '/' Separator */}
                            {/* <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography> */}
    
                            {/* Unit Input */}
                            <FormControl fullWidth variant="standard">
                              <TextField
                              
                                variant="standard"
                                inputProps={{ readOnly: true,}}
                                label={
                                  <>
                                    Unit
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                }
                                name={`${field}.unit`}
                                onChange={formik.handleChange}
                                value={
                                  formik.values[field as keyof typeof formik.values]
                                    ?.unit
                                }
                                error={Boolean(
                                  formik.touched[
                                    field as keyof typeof formik.touched
                                  ]?.unit &&
                                    formik.errors[
                                      field as keyof typeof formik.errors
                                    ]?.unit
                                )}
                                helperText={
                                  formik.touched[
                                    field as keyof typeof formik.touched
                                  ]?.unit &&
                                  formik.errors[field as keyof typeof formik.errors]
                                    ?.unit
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  sx: { color: "primary.main" }, // Label color fix
                                }}
                                sx={{
                                  marginTop: (Boolean(
                                    formik.touched[
                                      field as keyof typeof formik.touched
                                    ]?.value &&
                                      formik.errors[
                                        field as keyof typeof formik.errors
                                      ]?.value
                                  ) 
                                  // && !Boolean(
                                  //   formik.touched[
                                  //     field as keyof typeof formik.touched
                                  //   ]?.unit &&
                                  //     !formik.errors[
                                  //       field as keyof typeof formik.errors
                                  //     ]?.unit
                                  // )
                                )  ? "-1.5rem" : "0",
                                  "& .MuiInputBase-root.MuiInput-root": {
                                    fontSize: "1rem",
                                    color: "primary.main", // Placeholder & input text color fix
                                  },
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      )
                    )
                  }
                )}
              </Box>
              {/* // } */}

              <Box sx={{ width: "100%", textAlign: "right" }}>
                {readOnly ? "" : <LoadingButton
                  size="small"
                  disabled={readOnly}
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: "1rem" }}
                >
                  {actions === "add" ? "Add" : "Save"}
                </LoadingButton>}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default CreateRevisionDatabaseModal;
