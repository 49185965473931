import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { Box, Skeleton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { API } from "../../../api-services";

const csvOptions: any = {
  fileName: "Raw Material DataBase CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: any) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      width: "100%",
      height: "2.3rem",
      justifyContent: "flex-end",
      paddingRight: "1rem",
      // marginBottom: '0.5rem',
      marginTop: "-2.4rem",
    }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

function MaterialDB({ database, selected, setSelected }: any) {
  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [tabValue, setTabValue] = useState<any>(null);
  const [tableData, setTableData] = React.useState<any>([]);
  const [filteredFields, setFilteredFields] = React.useState<any>([]);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    API.get("/api/db/material/", { category: category }, 0)
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };

  const handleChange = (categoryName: any) => {
    setTabValue(categoryName);
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setTabValue(res.data?.[0]?.category);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  let rows: any = [];
  let dataGridDataGenerator: any =
    tableData &&
    tableData?.map((item: any, index: any) => {
      
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        material_code: item?.material_code,
        material_grade: item?.material_grade,
        material_subgrade: item?.material_subgrade,
        shape: item?.shape,
        density: item?.density,
        description: item?.description,
        material_cost: item?.material_cost,
        material_source: item?.material_source,
        material_reference: item?.material_reference,
        scrap_cost: item?.scrap_cost,
        scrap_source: item?.scrap_source,
        scrap_reference: item?.scrap_reference,
        mapped_status: item?.mapped_status,
        category: item?.category,
        currency_id: item?.currency,
        currency_code: item?.currency_code,
        unit: item?.unit,
        lock: item?.lock,
        parameter_details: item?.parameter_details,
      };

      
      if (Array.isArray(item?.parameter_details)) {
        item?.parameter_details?.forEach((param: any) => {
          if (param?.name) {
            // Generate key dynamically by replacing spaces with underscores and converting to lowercase
            const key = param?.name?.toLowerCase()?.replace(/[\s\-\(\)]+/g, "_");
            objShared[key] = param?.value; // Assign value to the generated key
          }
        });
      }
     
      rows.push(objShared);
    });

  const columns: any = [
    {
      field: "actions",
      headerName: "Select",
      width: width > 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            <Checkbox
              checked={selected?.id == cellValues?.row?.id}
              onChange={() => setSelected(cellValues?.row)}
            />
          </Box>
        );
      },
    },
    // {
    // 	field: 'sno',
    // 	headerName: 'S.No',
    // 	width: 30,
    // },
    {
      field: "material_code",
      headerName: "Material Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "material_grade",
      headerName: "Grade",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "material_subgrade",
      headerName: "Sub Grade",
      type: "number",
      minWidth: 70,
    },
    // {
    // 	field: 'shape',
    // 	headerName: 'Initial Shape',

    // 	sortable: false,
    // 	width: width > 1370 ? 100 : 80,
    // },
    {
      field: "density",
      headerName: "Density (kg/m3)",
      sortable: false,
      minWidth: width > 1370 ? 150 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Density (kg/m3): ${cellValues?.row?.density}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.density ? `${cellValues?.row?.density?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    // {
    // 	field: 'description',
    // 	headerName: 'Remarks',

    // 	sortable: false,
    // 	minWidth: width > 1370 ? 180 : width < 1200 ? 150 : 160,
    // },
    {
      field: "material_cost",
      headerName: "Material Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      renderCell: (cellValues: any) => {
      
        return (
          <Box
            title={`Material cost: ${cellValues?.row?.material_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.material_cost ? `${cellValues?.row?.material_cost?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    {
      field: "currency_code",
      headerName: "Currency/Unit",
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${
              cellValues?.row?.currency_code
                ? cellValues?.row?.currency_code
                : "--"
            }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    // {
    // 	field: 'material_source',
    // 	headerName: 'Material Source',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    // },

    // {
    // 	field: 'material_reference',
    // 	headerName: 'Material Ref',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 100 : width < 1200 ? 100 : 100,
    // 	renderCell: (cellValues: any) => {
    // 		return (
    // 			<>
    // 				{isUrl(cellValues?.row?.material_reference) ? (
    // 					<span
    // 						style={{ textDecoration: 'underline', cursor: 'pointer' }}
    // 						onClick={() =>
    // 							handleOpenLinkReference(cellValues?.row?.material_reference)
    // 						}>
    // 						Link
    // 					</span>
    // 				) : (
    // 					<span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
    // 						{cellValues?.row?.material_reference}
    // 					</span>
    // 				)}
    // 			</>
    // 		);
    // 	},
    // },
    {
      field: "scrap_cost",
      headerName: "Scrap Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Scrap Cost: ${cellValues?.row?.scrap_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.scrap_cost ? `${cellValues?.row?.scrap_cost?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    // {
    // 	field: 'scrap_source',
    // 	headerName: 'Scrap Source',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
    // },
    // {
    // 	field: 'scrap_reference',
    // 	headerName: 'Scrap Ref',

    // 	sortable: false,
    // 	width: width > 1370 ? 100 : width < 900 ? 100 : 100,
    // 	renderCell: (cellValues: any) => {
    // 		return (
    // 			<>
    // 				{isUrl(cellValues?.row?.scrap_reference) ? (
    // 					<span
    // 						style={{ textDecoration: 'underline', cursor: 'pointer' }}
    // 						onClick={() =>
    // 							handleOpenLinkReference(cellValues?.row?.scrap_reference)
    // 						}>
    // 						Link
    // 					</span>
    // 				) : (
    // 					<span
    // 					// style={{ textDecoration: "underline", cursor: "pointer" }}
    // 					// onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}
    // 					>
    // 						{cellValues?.row?.scrap_reference}
    // 					</span>
    // 				)}
    // 			</>
    // 		);
    // 	},
    // },
    // {
    // 	field: 'created_at',
    // 	headerName: 'Date Added',

    // 	sortable: false,
    // 	minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    // },
  ];


  const steelColumns = [
    {
      field: "shear_strength",
      headerName: "Shear Strength (N/mm^2)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "yield_strength",
      headerName: "Yield Strength (N/mm^2)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "ultimate_tensile_strength (N/mm^2)",
      headerName: "Tensile Strength",
      minWidth: 100,
      flex: 1,
    },
  ]

  const plasticColumns = [
    {
      field: "thermal_diffusivity",
      headerName: "Thermal Diffusivity (mm^2/sec)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "melting_temperature",
      headerName: "Melting Temperature (deg C)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "mold_temperature",
      headerName: "Mold Temperature (deg C)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "heat_deflection_temperature",
      headerName: "Heat Deflection Temperature (deg C)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "specific_heat_capacity_plastics (j/g-deg C)",
      headerName: "Specific Heat Capacity",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "annealing_temperature_plastics (deg C)",
      headerName: "Annealing Temperature",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "material_constant_injection_moulding_",
      headerName: "Material Constant",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "branch_runner_length (mm)",
      headerName: "Branch Runner Length",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "cavity_pressure",
      headerName: "Cavity Pressure (PSI)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "injection_efficiency",
      headerName: "Injection Efficiency (%)",
      minWidth: 100,
      flex: 1,
    },
  ]

  const rubberColumns = [
    {
      field: "specific_heat_capacity",
      headerName: "Specific Heat Capacity (j/g-deg C)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "material_working_temperature",
      headerName: "Material Working Temperature (deg C)",
      minWidth: 100,
      flex: 1,
    },
    
  ]

  const finalColumn = tabValue == "STEEL" ? [...columns, ...steelColumns] : tabValue == "PLASTIC" ? [...columns, ...plasticColumns] : tabValue == "RUBBER" ? [...columns, ...rubberColumns] : columns

  let dataStructureDataGrid: any = {
    columns: finalColumn,
    rows: rows && rows,
  };

  useEffect(() => {
    getCategoryList(database);
  }, [database]);

  useEffect(() => {
    getMaterialData(tabValue);
  }, [tabValue]);

  //  console.log(tabValue, "parameter");
  //  console.log(rows, "parameter");



  return (
    <>
      <Box sx={{ display: "flex", gap: "2%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            columnGap: "0.5rem",
            width: "14%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
            marginLeft: "1rem",
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.2"
        >
          {accordionLoading ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
          ) : (
            <>
              {categoryList &&
                categoryList?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          padding:"0.2rem 0.5rem",
                          color: "primary.main",
                          // height: "2rem",
                          // minHeight: "2.1rem",
                          maxWidth: "100% !important",
                          
                          fontSize: "1rem",
                          width: "100%",
                          backgroundColor:
                            item?.category == tabValue ? "primary.main" : "",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "0.1px solid #edf5ef",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              item?.category == tabValue
                                ? "white"
                                : "primary.main",
                            // height: "2rem",
                            // minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0.1rem 0rem",
                            fontSize: "1rem",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            whiteSpace: "nowrap",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleChange(item?.category)}
                        >
                          {item?.category?.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                    </>
                  );
                })}
            </>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            height: { lg: "84vh", xl: "85vh" },
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "#ddefff !important",
              textAlign: "center !important",
            },
          }}
        >
          <DataGrid
            headerHeight={42}
            rowHeight={36}
            {...dataStructureDataGrid}
            loading={tableDataLoading}
            // components={{
            //   Toolbar: () => {
            //     return CustomToolbar();
            //   },
            // }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
          />
        </Box>
      </Box>
    
    </>
  );
}

export default MaterialDB;
