import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { increaseCounter, validationCheckCounter } from "../../Redux/Actions/compareopen.action";

const FeatureCreateImageModal = (fileList: any, itemId: any, setLoading: any, projectId: any, featureId: any, getFeatureData: any, selectedProduct: any,dispatch:any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  },0).then((res) => {
    setLoading(true);
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `feature/${itemId ? itemId : 0}/${featureId}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          if (res.status === 204) {
            API.put(
              `/xcpep/feature_details/${featureId}/`,
              {
                [itemId]: `/feature/${itemId ? itemId : 0}/${featureId}/${random}/${file.name}`,
                // is_feature:true
              },
              {},
              0
            )
              .then((res: any) => {
                getFeatureData(selectedProduct);
                setLoading(false);
                var randomm: any = Math.floor(Math.random() * 1000000 + 1);
                dispatch(increaseCounter(randomm));
                dispatch(validationCheckCounter(randomm + 1));
              })
              .catch((err: any) => {
                setLoading(false);
                const { data } = err.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
            <br />
            <p style="color:red;">${data[0]}</p>   
             </div>`,
                });
              });
          }
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default FeatureCreateImageModal;
