import { useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { animated, useSpring } from "@react-spring/web";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import calculator from "../../Assets/images/calculator.svg";
import { Box, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import DependencyCalcListModal from "./DependencyCalcListModal";

function TransitionComponent(props: any) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px, 0, 0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px, 0, 0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CostingTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  useraction?: any;
  setCheckedNodes?: any;
  checkedNodes?: any;
}

const CostingTreeTemplateConfig = (props: CostingTreeViewcomponentsProps) => {
  const { HierarchyData, setCheckedNodes, checkedNodes } = props;
  const [autoFetched, setAutoFetched] = useState<any>(null);
  const [expanded, setExpanded] = useState<any>([]);
  const depedentCalcopen = useBaseParamsModal();
  const [currentCheckedNode,setCurrentCheckedNode]=useState(null)


  

  const  checkAutoFetchSelected = (node:any) => {
    const findMAtchedNode = checkedNodes?.find((item:any)=> {
      return node?.sequence_id === item?.sequence_id 

    })

    // console.log("auto fetch calc selected");

    if (!findMAtchedNode && node?.individual_auto_fetch === true) {
     

      setAutoFetched(node?.sequence_id)

    } else {
      if (node?.individual_auto_fetch === true) {
        setAutoFetched(null)
        
      }
    }

    
    
  }


  function findHierarchyBySequenceId( obj: any, 
    sequenceId: string, 
    ancestors: any[] = [] // We'll store ancestor IDs here
  ) {
    // Check if the current object has a data array
    if (Array.isArray(obj?.data)) {
      // Iterate through each item in the data array
      for (let item of obj?.data) {
        // If this item has the matching sequence_id, return the current path (ancestors + current item's ID)
        if (item.sequence_id === sequenceId) {
          return [...ancestors, obj?.sequence_id]; // Add the current object's id to the ancestors list
        }
  
        // If the item has a nested data array, recurse into it
        const result:any = findHierarchyBySequenceId(item, sequenceId, [...ancestors, obj?.sequence_id]);
        if (result) {
          return result;
        }
      }
    }
    return null; // Return null if no matching item is found
  }

  // Handle the checkbox change and ensure child nodes are also checked
  const handleCheckboxChange = (
    nodeId: any,
    checked: boolean,
    event: any,
    qty: number = 1,
    individual_auto_fetch:any = null
  ) => {
    event.stopPropagation();
    event.preventDefault();

   
    
  
    

    // Recursively update checked nodes including children
    const updateCheckedNodes = (
      nodes: any[],
      checked: boolean,
      qty: number
    ) => {
      nodes.forEach((node) => {
        const nodeData = {
          sequence_id: node.sequence_id,
          id: node.id,
          type: node.type,
          qty: qty, // Default qty value for checked nodes
        };

        if (checked) {
          setCheckedNodes((prev: any[]) => {
            // Prevent duplicates
            if (!prev.some((item) => item.sequence_id === node.sequence_id)) {
              return [...prev, nodeData];
            }
            return prev;
          });
        } else {
          setCheckedNodes((prev: any[]) =>
            prev.filter((item) => item.sequence_id !== node.sequence_id)
          );
        }

        // Recursively update child nodes
        if (node.data && node.data.length) {
          updateCheckedNodes(node.data, checked, qty);
        }
      });
    };

    // Toggle the current node and update its children
    const toggleNode = (nodeId: any, checked: boolean, qty: number) => {
      const node = findNodeById([HierarchyData], nodeId);
      if (node) {
        const nodeData = {
          sequence_id: node.sequence_id,
          id: node.id,
          type: node.type,
          qty: qty, // Default qty value for checked nodes
        };

        if (checked) {
          if(node?.type!=="calculator"){
            setCurrentCheckedNode(node)
          }

          setCheckedNodes((prev: any[]) => {
            if (!prev.some((item) => item.sequence_id === node.sequence_id)) {
              return [...prev, nodeData];
            }
            return prev;
          });
        } else {
          const parent = findHierarchyBySequenceId(currentCheckedNode, nodeId);
          if (parent) {
            setCheckedNodes((prev: any[]) =>
              prev.filter((item) => !parent.includes(item.sequence_id))
            );
          } 
          setCheckedNodes((prev: any[]) =>
            prev.filter((item) => item.sequence_id !== node.sequence_id)
          );
        }
      }
    };

    // Recursive function to find the node by sequence_id
    const findNodeById = (nodes: any[], nodeId: any): any => {
      for (let node of nodes) {
        if (node.sequence_id === nodeId) return node;
        if (node.data && node.data.length) {
          const foundNode = findNodeById(node.data, nodeId);
          if (foundNode) return foundNode;
        }
      }
      return null;
    };

    // Toggle the current node
    toggleNode(nodeId, checked, qty);

    // Find the node in the hierarchy and update its children
    const node = findNodeById([HierarchyData], nodeId);
    if (node && node.data && node.data.length) {
      updateCheckedNodes(node.data, checked, qty);
    }
  };

  const handleQtyChange = (nodeId: any, value: number) => {
    setCheckedNodes((prev: any[]) =>
      prev.map((item) =>
        item.sequence_id === nodeId ? { ...item, qty: value } : item
      )
    );
  };

  // console.log(checkedNodes, "checkedNodes");
  

  const renderTreeItems = (nodes: any) => {
    return nodes.map((node: any) => (
      <StyledTreeItem
        key={node.sequence_id}
        nodeId={node.sequence_id.toString()}
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div 
              style={{display:'inline-flex',alignItems:'center'}}
              //  onClick={(e: any) => {
              //    if (node.type === "calculator") {
              //      handleCheckboxChange(node.sequence_id, !checkedNodes.some((checkedNode: any) => checkedNode.sequence_id === node.sequence_id), e);
              //    }
              //  }}

              onClick={(e: any) => {


                const copyArr = [...expanded];

           const expandedNodes = getAutoExpandedNodes(node);
           const index = expanded?.indexOf(node?.sequence_id);

           
         if (index === -1) {
          
           expandedNodes?.forEach((item: any) => {
             if (!copyArr?.includes(item)) {
              copyArr?.push(item); // Add to arr2 if not present
             }
           });
         } 
         else {
           
     
           expandedNodes?.forEach((item: any) => {
             const indexx = copyArr?.indexOf(item);
             if (indexx !== -1) {
              copyArr.splice(indexx, 1); // Add to arr2 if not present
             }
           });
         }
          
          setExpanded(copyArr);










                if (node && node.type === "calculator") {
                  if (autoFetched && autoFetched !== node?.sequence_id && node?.individual_auto_fetch === true) {
                    
                  } else{
                    handleCheckboxChange(node.sequence_id, !checkedNodes.some((checkedNode: any) => checkedNode.sequence_id === node.sequence_id), e, node?.individual_auto_fetch);
                  checkAutoFetchSelected(node);
                  }
                  
                }
              }}
               >
            {
              <Checkbox
                size="small"
                disabled={autoFetched && autoFetched !== node?.sequence_id && node?.individual_auto_fetch === true}
                checked={checkedNodes.some(
                  (checkedNode: any) =>
                    checkedNode.sequence_id === node.sequence_id
                )}
                color="success"
                onChange={(e: any) => {
                  if (node && node.type !== "calculator") {                   
                  handleCheckboxChange(node.sequence_id, e.target.checked, e, node?.individual_auto_fetch)
                  }
                }
                  
                }
              />
            }
            <Typography sx={{ marginRight: "1.5rem", color: node.type == "calculator" && autoFetched && autoFetched !== node?.sequence_id && node?.individual_auto_fetch === true ? "grey": "inherit" }}>
              {node.name}{" "}
              {node.type !== "calculator" && `(${node?.calc_count})`}
            </Typography>
            {node?.alias && `( as: ${node?.alias || ""})`}{" "}
            <p style={{ color: node.type == "calculator" && autoFetched && autoFetched !== node?.sequence_id && node?.individual_auto_fetch === true ? "grey": "black",  }}>
              {node?.type === "calculator" &&
                ` [ UI:${node?.user_input_count || 0}, DB:${
                  node?.database_count || 0
                }, F:${node?.formula_count || 0}, BP: ${
                  node?.bom_count || 0
                } ] ${node?.total_fields_count || 0} `}
            </p>
            {node.type === "calculator" &&
              node?.dependency &&
              node?.dependency?.length > 0 && (
                <InfoIcon
                  sx={{ ml: 1, cursor: "pointer", color: node.type == "calculator" && autoFetched && autoFetched !== node?.sequence_id && node?.individual_auto_fetch === true ? "grey": "inherit" }}
                  onClick={(e:any)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    depedentCalcopen.open(node?.dependency || [])
                  }}
                />
              )}
          </div>
            {checkedNodes.some(
              (checkedNode: any) => checkedNode.sequence_id === node.sequence_id
            ) &&
              node.type === "calculator" && (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <p style={{ fontSize: "13px" }}>
                    {" "}
                    Qty<sup style={{ color: "red" }}>*</sup> :{" "}
                  </p>
                  <TextField
                    type="number"
                    variant="standard" // Use 'standard' variant for underline
                    placeholder="Qty"
                    defaultValue={1}
                    value={
                      checkedNodes.find(
                        (item: any) => item.sequence_id === node.sequence_id
                      )?.qty || 1
                    }
                    onChange={(e) =>
                      +e.target.value > 0 &&
                      handleQtyChange(node.sequence_id, +e.target.value)
                    }
                    InputProps={{
                      disableUnderline: false, // Keep underline enabled
                      //@ts-ignore
                      min: 1, // Minimum value
                      sx: {
                        padding: "0px", // Remove extra padding
                        margin: "0px", // Remove extra margin
                        fontSize: "0.875rem", // Smaller font size
                        minHeight: "1.5rem", // Minimal height for compact design
                      },
                    }}
                    sx={{
                      width: "8rem", // Adjust width as needed
                      marginLeft: "1rem",
                      "& .MuiInput-root": {
                        padding: 0, // Remove internal padding
                        height: "1.5rem", // Minimal height
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px solid black", // Custom underline color before focus
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "1px solid #007fff", // Custom underline color on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px solid #007fff", // Custom underline color on focus
                      },
                    }}
                  />
                </Box>
              )}
         
          </div>
        }
        endIcon={
          node.type === "calculator" ? (
            <img src={calculator} style={{ width: "13px" }} />
          ) : (
            <FolderIcon color="primary" />
          )
        }
      >
        {node.data && node.data.length > 0 && renderTreeItems(node.data)}
      </StyledTreeItem>
    ));
  };

  const getAutoExpandedNodes = (data: any, resultArr: any = []) => {
    const nodes = Array.isArray(data) ? data : [data]; // Wrap in array if not already an array
  
    nodes.forEach((node: any) => {
      // Check if node has exactly one child in its data array
      if (node?.data?.length === 1) {
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Recursively continue with its single child
        getAutoExpandedNodes(node?.data, resultArr);
      } else if (node?.data?.length > 1) {
        // If node has multiple children in data array
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Push sequence_id of only the first child
        resultArr.push(node?.data[0]?.sequence_id);
        // Recursively continue with the first child
        getAutoExpandedNodes(node?.data[0], resultArr);
      }
    });
  
    return resultArr;
  };
  

  return (
    <>
      <TreeView
        expanded={expanded}
        onNodeToggle={(event, nodeIds,) => {
          
        //   const copyArr = [...expanded];

        //    const expandedNodes = getAutoExpandedNodes(HierarchyData);
        //    const index = expanded?.indexOf(HierarchyData?.sequence_id);

        //    console.log(expanded, "expanded");
        //    console.log(index, "expanded index");
        //    console.log(HierarchyData, "expanded HierarchyData");
        //    console.log(HierarchyData?.sequence_id, "expanded HierarchyData?.sequence_id");
           
        //  if (index === -1) {
          
        //    expandedNodes?.forEach((item: any) => {
        //      if (!copyArr?.includes(item)) {
        //       copyArr?.push(item); // Add to arr2 if not present
        //      }
        //    });
        //  } 
        //  else {
           
     
        //    expandedNodes?.forEach((item: any) => {
        //      const indexx = copyArr?.indexOf(item);
        //      if (indexx !== -1) {
        //       copyArr.splice(indexx, 1); // Add to arr2 if not present
        //      }
        //    });
        //  }
          
        //   setExpanded(copyArr);
        }}
        defaultCollapseIcon={<img src={openFolder} style={{ width: "18px" }} />}
        defaultExpandIcon={<FolderIcon color="primary" />}
        // defaultEndIcon={<img src={calculator} style={{ width: "13px" }} />}
      >
        {HierarchyData && renderTreeItems([HierarchyData])}
      </TreeView>
      {depedentCalcopen.isOpen && (
        <DependencyCalcListModal
          isOpen={depedentCalcopen.isOpen}
          onCloseModal={depedentCalcopen.close}
          data={depedentCalcopen.propsId}
        />
      )}
    </>
  );
};

export default CostingTreeTemplateConfig;
