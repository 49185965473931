import React, { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LinearProgress from '@mui/material/LinearProgress';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Paper,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Typography,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Chip,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../../Redux/Services/admin.service";
import ReplayIcon from "@mui/icons-material/Replay";
import RViewerJS from "viewerjs-react";
import { CustomMultiSelect } from "./CustomMultiSelect";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";

export function TableHeadersection({
  type,
  text,
  img,
  itemCounts,
  totalCount,
}: any) {
  // const renderedItems = Object.entries(itemCounts)
  //   ?.map(([key, value]) => `${key}: ${value}`)
  //   ?.join(" | ");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
        {text}:
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {totalCount && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginLeft: "1rem",
              marginRight: "-0.5rem",
            }}
          >
            Total: {totalCount} |
          </Box>
        )}

        {itemCounts &&
          Object.entries(itemCounts)?.map(([key, value]) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginLeft: "1rem",
                marginRight: "-0.5rem",
              }}
            >
              {key} : {`${value}`}
            </Box>
          ))}

        {/* {renderedItems} */}
        {/* @ts-ignore */}
        <RViewerJS>
          <img
            src={img}
            style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
            alt=""
          />
        </RViewerJS>
      </Box>
    </Box>
  );
}

export interface RowData {
  id: number;
  item_type: string | null;
  length: any;
  width: any;
  ocr: any;
  qty: any;
  image: string | null;
  location: string | null;
}


const BOMRobotoflowFetchDataTable: any = (props: any) => {
  const { tableId, onCloseModal } = props;
  const { vault } = useRouteParams<any>();
  
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [headerDetails, setHeaderDetails] = useState<any>(null);
  const [tableDataTop, setTableDataTop] = useState<RowData[]>([]);
  const [tableDataBottom, setTableDataBottom] = useState<RowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [acceptedIds, setAcceptedIds] = useState<any[]>([]);
  const [rejectedIds, setRejectedIds] = useState<any[]>([]);
  const [mergedIdsIds, setMergedIds] = useState<any[]>([]);
  const [rowStates, setRowStates] = useState<{ [key: number]: boolean }>({});
  const [rowStatesLoading, setRowStatesLoading] = useState<{ [key: number]: boolean }>({});
  const [rowStatesRejectLoading, setRowStatesRejectLoading] = useState<{ [key: number]: boolean }>({});

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getData = () => {
    if (activeTab === 0) {
      return tableDataTop;
    } else {
      return tableDataBottom;
    }
  };

  const rows = tableDataTop?.map((item: any, index: number) => {
    return {
      enabledStatus: item?.enable == true ? "true" : "false",
      ...item,
    };
  });

  const getTableData = () => {
    setLoading(true);
    API.post(`/pcb/fetch_pcb_automation_via_xcpep/${vault}/`, {}, {}, 0)
      .then((res: any) => {
        setLoading(false);
        let Topdata: RowData[] = res?.data?.components?.map(
          (item: any, index: any) => {
            return {
              id: item?.component_id,
              item_type: item?.item_name,
              length: item?.length_mm,
              width: item?.breadth_mm,
              ocr: item?.ocr,
              qty: item?.qty || 1,
              image: item?.cropped_image,
              location: item?.location,
              targetId:item?.targetId,
              enable: item?.enable,
              rejected: item?.rejected,
              accepted: item?.accepted,
              classification: item?.top5_classification,
            };
          }
        );

        Topdata?.map((item:any)=> {

          if (item?.accepted == true) {
           
           
           if (!acceptedIds?.includes(item?.id)) {
             setAcceptedIds((prevItems:any) => [...prevItems, item?.id]);
           }
          }
          if (item?.rejected == true) {
           if (!rejectedIds?.includes(item?.id)) {
             setRejectedIds((prevItems:any) => [...prevItems, item?.id]);
           }
          }
          if (item?.enable == false) {
           if (!mergedIdsIds?.includes(item?.id)) {
             setMergedIds((prevItems:any) => [...prevItems, item?.id]);
           }
          }
       });

        // let BOTTOM: RowData[] = res?.data?.BOTTOM?.map(
        //   (item: any, index: any) => {
        //     return {
        //       id: item?.component_id,
        //       item_type: item?.item_name,
        //       length: item?.length_mm,
        //       width: item?.breadth_mm,
        //       ocr: item?.ocr,
        //       qty: item?.qty || 1,
        //       image: item?.cropped_image,
        //       location: item?.location,
        //     };
        //   }
        // );
        setTableDataTop(Topdata);
        setHeaderDetails({
          topImg: res?.data?.top_detection_result,
          bottomImg: res.data?.bottom_detection_result,
          topItemCounts: res.data?.item_counts?.top_item_counts,
          totalTopItemCount: res.data?.item_counts?.total_top_counts,
          bottomItemCounts: res.data?.item_counts?.bottom_item_counts,
          totalBottomItemCount: res.data?.item_counts?.total_bottom_counts,
        });
        // setTableDataBottom(BOTTOM);
      })
      .catch((err: any) => {
        ADMIN.toast.error("Could not fetch data, Please try again");
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTableData();
  }, []);

  // Handle checkbox selection/unselection
  const handleSelectRow = (id: number) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id) // Unselect row
          : [...prevSelected, id] // Select row
    );
  };

  // Handle the Fetch button click
  const handleFetchSelectedRows = () => {
    const selectedData = [...tableDataTop, ...tableDataBottom]?.filter((row) =>
      selectedRows?.includes(row?.id)
    );
    setFetchLoading(true);
    API.post(
      `/pcb/create_bulk_pcb_component/${vault}/`,
      {
        bulk_data: selectedData,
        table_id: tableId,
      },
      {},
      0
    )
      .then((res: any) => {
        setFetchLoading(false);
        onCloseModal();
      })
      .catch((err: any) => {
        setFetchLoading(false);
      });
  };

  const handleButtonClick = (id: number) => {
    setRowStates((prev) => ({
      ...prev,
      [id]: !prev?.[id], // Toggle the state for the specific row
    }));
  };



 
  const columns: GridColDef[] = [
    // {
    //   field: "select",
    //   headerName: "Select",
    //   width: 100,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <Checkbox
    //       checked={selectedRows.includes(params.row.id)}
    //       onChange={() => handleSelectRow(params.row.id)}
    //     />
    //   ),
    // },
    { field: "id", headerName: "SL No.", width: 150,
      renderHeader: () => <Box sx={{paddingLeft:"2rem"}}>SL No.</Box>, 
      renderCell: (params: GridRenderCellParams) => {
        
        
        return (
          <Box sx={{paddingLeft:"2rem", color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }},
      {
        field: "image",
        headerName: "Image",
        width: 160,
        renderCell: (params: GridRenderCellParams) => (
          //@ts-ignore
          <RViewerJS>
            <img
              src={params.row.image}
              alt={`Image ${params.row.id}`}
              style={{
                width: "70px",
                height: "50px",
                objectFit: "contain",
                cursor: "pointer",
                opacity:params?.row?.enabledStatus == "false" ? "0.5" : "1"
              }}
            />
          </RViewerJS>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 300,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              {params?.row?.accepted == true ||
              params?.row?.enable == false ||
              params?.row?.rejected == true ? (
                <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                  {params?.row?.accepted == true ? (
                    <span style={{ color: "green" }}>Accepted</span>
                  ) : params?.row?.rejected == true ? (
                    <span style={{ color: "red" }}>Rejected</span>
                  ) : params?.row?.enable == false ? (
                    <span style={{ color: "primary.main" }}>Merged {params?.row?.targetId && `with ${params?.row?.targetId}`} </span>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
  {/* <LoadingButton
                onClick={() => {
                  handleAcceptItem(params?.row?.id);
                  
                }
                 }
                loading={rowStatesLoading?.[params.row?.id] == true || false}
                size="small"
                variant="contained"
                sx={{
                  padding: "0.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}>
  <CheckCircleOutlineIcon
  
                    sx={{ color: "green", cursor: "pointer" }}
                    
                  />
                </LoadingButton> */}
  
                <CheckCircleOutlineIcon
                    sx={{ color: "green", cursor: "pointer" }}
                    onClick={() => {
                      handleAcceptItem(params?.row?.id);
                      
                    }}
                  />
                  
                  
                  <HighlightOffIcon sx={{ color: "red", cursor: "pointer" }}
                   onClick={() => {
                    handleRejectItem(params?.row?.id);
                    
                  }}
                   />
                  <MergeTypeIcon
                    sx={{ color: "primary.main", cursor: "pointer" }}
                    onClick={() => handleButtonClick(params.row.id)}
                  />
  
  
                  {rowStates?.[params.row?.id] && (
  
              <TextField id="filled-basic" label="Target Row" variant="standard" sx={{
                "& .MuiInputLabel-root": {
                  color: "primary.main", // Always set the label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "primary.main", // Ensure the color remains the same when focused
                },
              }} value={params.row?.target || ""}
              onChange={(e)=> {
                handleEnterTextItem(params?.row?.id, "target", e.target.value)
              }}
              />
            )}
  
            {params.row?.target && (
                <LoadingButton
                onClick={()=> {
                  // setRowStatesLoading((prev) => ({
                  //   ...prev,
                  //   [params.row?.id]: true, // Toggle the state for the specific row
                  // }));
  
                  if (rejectedIds?.includes(params.row?.target)) {
                    ADMIN.toast.error("Cannot be merged to rejected row");
                    setRowStatesLoading((prev) => ({
                      ...prev,
                      [params.row?.id]: false, // Toggle the state for the specific row
                    }));
                    return;
                  }
                  if (params.row?.id == params.row?.target) {
                    ADMIN.toast.error("Cannot be merged to same row");
                    setRowStatesLoading((prev) => ({
                      ...prev,
                      [params.row?.id]: false, // Toggle the state for the specific row
                    }));
                    return;
                  }
  
                  const targetRow = tableDataTop?.find((item:any)=> {
                      return item?.id?.toLowerCase() == params.row?.target?.toLowerCase()
                  })
  
                  // console.log(targetRow?.qty,targetRow?.qty+1, "qty");
                  
                  
                 handleMergeItem(params.row?.id, params.row?.target, targetRow?.qty)
                }} 
                loading={rowStatesLoading?.[params.row?.id] == true || false}
                size="small"
                variant="contained"
                sx={{
                  padding: "0.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}>
                Merge
              </LoadingButton>
            )}
                </Box>
              )}
  
              {/* params?.row?.accepted == false ? <span style={{color:"green"}}>Accepted</span> */}
            </Box>
          );
        },
      },
    { field: "item_type", headerName: "Item Type", width: 170 , 
      renderCell: (params: GridRenderCellParams) => {
        
        
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }},
      
    {
      field: "length",
      headerName: "Length",
      width: 180,
      renderHeader: () => <Box>Length (mm)</Box>,
      renderCell:(params: GridRenderCellParams) => {
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }
      
    },
    {
      field: "width",
      headerName: "Width",
      width: 180,
      renderHeader: () => <Box>Width (mm)</Box>,

      renderCell:(params: GridRenderCellParams) => {
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }
    },
    { field: "ocr", headerName: "OCR", width: 230,
      renderCell:(params: GridRenderCellParams) => {
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }
     },
    { field: "qty", headerName: "Qty.", width: 150, 
      renderCell:(params: GridRenderCellParams) => {
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }
     },
    { field: "location", headerName: "Location", width: 180,
      renderCell:(params: GridRenderCellParams) => {
        return (
          <Box sx={{color: params?.row?.enabledStatus == "false" ? "grey" : "inherit"}}>
          {params?.value}
          </Box>
        )
      }
     },
    
  ];

  const toggleItem = (id: number, key:any, value:any) => {
    // console.log(id, value, "qty toggleItem");
    
    // if (key == "accepted") {
      setTableDataTop((prevItems:any) =>
        prevItems?.map((item:any) => {
         
          
          if (item?.id === id) {
            return { ...item,  [key]: value}
          } else {
            return item;
          }
        }
          
        )
      );
   



  };
 


  const handleEnterTextItem = (id: number, key:any, value:any) => {
    console.log(id, key, value, "img tableDataTopp");
   
      setTableDataTop((prevItems:any) =>
        prevItems?.map((item:any) =>
          item?.id === id ? { ...item,  [key]: value} : item
        )
      );
    

  };


  const handleMergeItem = (from:any, to:any, qty?:any) => {

    if (rejectedIds?.includes(to)) {
      ADMIN.toast.error("Cannot be merged to rejected row");
      setRowStatesLoading((prev) => ({
        ...prev,
        [from]: false, // Toggle the state for the specific row
      }));
      return;
    }
    if (from == to) {
      ADMIN.toast.error("Cannot be merged to same row");
      setRowStatesLoading((prev) => ({
        ...prev,
        [from]: false, // Toggle the state for the specific row
      }));
      return;
    }

    console.log(qty, "qty");
    

    API.get(
         `pcb/merge_pcb_data/${vault}/`,
         {
          merge_from_id: from,
          merge_to_id:to
         },
         0
       )
         .then((res: any) => {
          toggleItem(from?.toUpperCase(), "enable", false);
          toggleItem(to?.toUpperCase(), "qty", qty+1);
          toggleItem(from?.toUpperCase(), "targetId", to?.toUpperCase());
          if (!mergedIdsIds?.includes(from)) {
            setMergedIds((prevItems:any) => [...prevItems, from]);
          }
          
         })
         .catch((err: any) => {
         
           const { data } = err?.response;
           ADMIN.toast.error(data?.[0])
         }).finally(()=> {
          setRowStatesLoading((prev) => ({
            ...prev,
            [from]: false, // Toggle the state for the specific row
          }));
         });
   

 }

 const handleAcceptItem = (id:any) => {
  setRowStatesLoading((prev) => ({
    ...prev,
    [id]: true, // Toggle the state for the specific row
  }));
  const idArr = [id]
  API.get(
    `pcb/merge_pcb_data/${vault}/`,
    {
     accepted: idArr?.join(","),
   
    },
    0
  )
    .then((res: any) => {
      toggleItem(id?.toUpperCase(), "accepted", true);
      console.log("qty hi" );
                  if (!acceptedIds?.includes(id)) {
                    setAcceptedIds((prevItems:any) => [...prevItems, id]);
                  }
    })
    .catch((err: any) => {
      const { data } = err?.response;
           ADMIN.toast.error(data?.[0])
    }).finally(()=> {
     setRowStatesLoading((prev) => ({
       ...prev,
       [id]: false, // Toggle the state for the specific row
     }));
    });
 }

 const handleRejectItem = (id:any) => {
  setRowStatesRejectLoading((prev) => ({
    ...prev,
    [id]: true, // Toggle the state for the specific row
  }));
  const idArr = [id]
  API.get(
    `pcb/merge_pcb_data/${vault}/`,
    {
     rejected: idArr?.join(","),
   
    },
    0
  )
    .then((res: any) => {
      toggleItem(id?.toUpperCase(), "rejected", true)
      if (!rejectedIds?.includes(id)) {
        setRejectedIds((prevItems:any) => [...prevItems, id]);
      }
    })
    .catch((err: any) => {
      const { data } = err?.response;
           ADMIN.toast.error(data?.[0])
    }).finally(()=> {
     setRowStatesRejectLoading((prev) => ({
       ...prev,
       [id]: false, // Toggle the state for the specific row
     }));
    });
 }

 const handleFetchData = () => {
  setFetchLoading(true)
  API.post(
    `/pcb/create_bulk_pcb_component/${vault}/`,
    {
      table_id: tableId,
      bulk_data:[...acceptedIds,...rejectedIds, ...mergedIdsIds ]
    },
    {},
    0
  )
    .then((res: any) => {
      setLoading(false);
      ADMIN.toast.success("Accepted rows fetched successfully")
      onCloseModal();
      
    })
    .catch((err: any) => {
      
      const { data } = err?.response;
          
      Swal.fire({
        icon: "error",
        html: `<div>
        <br />
        <p style="color:"red">${data?.[0]}</p>   
         </div>`,
      }).finally(()=> {
        setFetchLoading(false)
      });
      
     
    })
 }



  
//  console.log(acceptedIds, "qty acceptedIds");
//  console.log(mergedIdsIds, "qty mergedIdsIds");
//  console.log(rejectedIds, "qty rejectedIds");
//  console.log(acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length, "qty table");
 
//  console.log(tableDataTop?.length, "qty tableDataTop");





  return (
    <Paper style={{ width: "100%", overflow: "hidden" }}>
      {/* <Box sx={{ margin: "1rem" }}> */}
      {/* <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ marginBottom: "1rem", color: "#007fff" }}
        >
          <Tab
            label={`Top ( ${tableDataTop?.length || "--"} )`}
            sx={{ color: "#007fff" }}
          />
          <Tab
            label={`Bottom ( ${tableDataBottom?.length || "--"} )`}
            sx={{ color: "#007fff" }}
          />
        </Tabs>
        <Box sx={{ width: "80%" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.4rem",
              cursor: "pointer",
              textDecoration: "underline",
              float: "right",
            }}
            onClick={() => getTableData()}
          >
            Retry <ReplayIcon fontSize="small" />
          </Typography>
        </Box>
      </Box> */}
      {/* </Box> */}
      <Box
        sx={{
          display: "flex",
          gap: "3rem",
          alignItems: "center",
          margin: "1rem",
        }}
      >
        {headerDetails?.totalTopItemCount ? <TableHeadersection
          type="top"
          text="Top Items"
          img={headerDetails?.topImg}
          itemCounts={headerDetails?.topItemCounts}
          totalCount={headerDetails?.totalTopItemCount}
        /> : ""}

       {headerDetails?.totalBottomItemCount ? <TableHeadersection
          type="bottom"
          text="Bottom Items"
          img={headerDetails?.bottomImg}
          itemCounts={headerDetails?.bottomItemCounts}
          totalCount={headerDetails?.totalBottomItemCount}
        /> : ""}
      </Box>
      <Box
        sx={{
          height: "74vh",
          width: "100%",
          "& .super-app-theme--false": { bgcolor: "#e4e7eb" }, // Light Green
        }}
      >
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowClassName={(params) =>
            `super-app-theme--${params.row?.enabledStatus?.toLowerCase()}`
          }
          checkboxSelection={false}
          disableSelectionOnClick
          loading={loading}
          components={{
            NoRowsOverlay: () => <Box>No Data Available</Box>,
            LoadingOverlay: () => (
              <Box>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
              </Box>
            ),
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" , marginTop:"-2rem", }}>
        {fetchLoading ? (
          <Box sx={{ width: '50%', display: "flex", justifyContent: "center" , alignItems:"center", gap:"0.5rem" }}>
            <Typography sx={{}}>Please wait while we process your request. This may take a moment...</Typography> <CircularProgress  size={20}/>
          
        </Box>
         ) : <LoadingButton
           variant="contained"
          
           loading={fetchLoading}  
            disabled={(acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length) !== tableDataTop?.length}
           onClick={handleFetchData}
           style={{ margin: "10px", color:"#fff", 
              //backgroundColor: "green",
             
              backgroundColor: (acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length) !== tableDataTop?.length ? "grey" : "green"
            }}
         >
           Fetch Accepted Rows
         </LoadingButton>}
      </Box>
    </Paper>
  );
};

export default BOMRobotoflowFetchDataTable;
