


import React, { useEffect, useState } from 'react'
import { API } from '../../../api-services';
import { Box, Checkbox, Skeleton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function MachineDB({ database, selected, setSelected}:any) {
  const [categoryList, setCategoryList] = React.useState<any>([]);
    const [tabValue, setTabValue] = useState<any>(null);
     const [tableData, setTableData] = React.useState<any>([]);
    const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
    const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

    const isUrl = (url: any) => {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    };

  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setTabValue(res?.data[0]?.id);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };

    useEffect(() => {
      getCategoryList(database);
    }, [database]);

    const getMachineTableData = (categoryName:any) => {
      setTabledataLoading(true)
      API.get("/api/db/machine/", {
            commodity: categoryName,
          })
            .then((res: any) => {
              
              setTableData(res?.data);
            })
            .catch((err: any) => {
              
              console.log("Sever EEror");
            }).finally(()=> {
              setTabledataLoading(false)
            });
    }

    useEffect(()=> {
      getMachineTableData(tabValue);
    },[tabValue])

    const handleChange = (categoryName: any) => {
      setTabValue(categoryName);
    };



    const columns: any = [
     {
           field: "actions",
           headerName: "Select",
           width: 100,
           renderCell: (cellValues: any) => {
             return (
               <Box
                 sx={{
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "flex-end",
                   columnGap: "1rem",
                 }}
               >
                 <Checkbox
                   checked={selected?.id == cellValues?.row?.id}
                   onChange={() => setSelected(cellValues?.row)}
                 />
               </Box>
             );
           },
         },
     
      {
        field: "machine_code",
        headerName: "Machine Code",
        width: 150,
      },
      {
        field: "name",
        headerName: "Machine Name",
        width: 150,
      },
  
      // {
      //   field: "brand_name",
      //   headerName: "Brand Name",
      //   width: 150,
      // },
      {
        field: "operation",
        headerName: "Operation",
        width: 150,
      },
  
      // {
      //   field: "description",
      //   headerName: "Description",
      //   width: 140,
      // },
      // {
      //   field: "machine_bed_area",
      //   headerName: "Machine Bed Area in m2",
      //   width: 140,
      // },
      {
        field: "capital_cost",
        headerName: "Capital Cost",
        align: "right",
        width: 120,
      },
      {
        field: "installation_cost",
        headerName: "Installation Cost ",
        align: "right",
        width: 150,
      },
  
      {
        field: "life",
        headerName: "Life (Years)",
        width: 70,
        align: "right",
      },
      {
        field: "maintanance",
        headerName: "Maintanance (%/Year/Shift)",
        width: 80,
        align: "right",
      },
  
      {
        field: "consumption",
        headerName: "Consumble Cost (%)",
        align: "right",
        width: 80,
      },
      {
        field: "power_consumption",
        headerName: "Avg Pwr Cons(kWh)",
        width: 80,
        align: "right",
      },
      {
        field: "effectiveness",
        headerName: "Effectiveness (%)",
        width: 80,
      },
      {
        field: 'currency_code',
        headerName: 'Currency/Unit',
        sortable: false,
        minWidth: 140,
        renderCell: (cellValues: any) => {
  
  
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
              {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
            </Box>
          );
        },
      },
      {
        field: "peak_power_factor",
        headerName: "Peak Power Factor",
        width: 130,
      },
      {
        field: "reference",
        headerName: "Reference",
        width: 80,
        renderCell: (cellvalue: any) => {
          var link = ["https", "http", "www"];
          var likData = String(cellvalue?.row?.reference);
  
          if (cellvalue?.row?.reference.includes("/")) {
            return (
              <>
                {isUrl(cellvalue?.row?.reference) ? (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      borderBottom: "1px solid",
                    }}
                    onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    Link
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      // borderBottom: "1px solid",
                    }}
                  // onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    {cellvalue?.row?.reference}
                  </Typography>
                )}
              </>
            );
          }
          return <>{cellvalue?.row?.reference}</>;
        },
      },
    ];
  
    const rows: any = () =>
      tableData &&
      tableData?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));
  

      let dataStructureDataGrid: any = {
        columns: columns,
        rows: rows(),
      };

      // console.log(tableData, "parameter");
      // console.log(rows, "parameter");

  return (
    <Box sx={{ display: "flex", gap: "2%" }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        columnGap: "0.5rem",
        width: "19%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
        marginLeft: "1rem",
        maxHeight:"80vh"
      }}
      
      adl-scrollbar="true"
      adl-scrollbar-width="0.2"
    >
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          {categoryList &&
            categoryList?.map((item: any, index: any) => {
              // console.log(item?.id == tabValue, "parameter");
              
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      padding:"0.2rem 0.5rem",
                  
                      // height: "2rem",
                      // minHeight: "2.1rem",
                      maxWidth: "100% !important",
                      
                      fontSize: "1rem",
                      width: "100%",
                      backgroundColor:
                        item?.id == tabValue ? "primary.main" : "",
                      
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "0.1px solid #edf5ef",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          item?.id == tabValue
                            ? "white"
                            : "primary.main",
                        // height: "2rem",
                        // minHeight: "2.1rem",
                        maxWidth: "100% !important",
                        padding: "0.1rem 0rem",
                        fontSize: "1rem",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleChange(item?.id)}
                    >
                      {item?.name?.toLowerCase()} ({item?.count})
                    </Typography>
                  </Box>
                </>
              );
            })}
        </>
      )}
    </Box>

    <Box
      sx={{
        flex: 1,
        height: { lg: "84vh", xl: "85vh" },
        width: "100%",
        "& .super-app-theme--cell": {
          backgroundColor: "#ddefff !important",
          textAlign: "center !important",
        },
      }}
    >
      <DataGrid
        headerHeight={42}
        rowHeight={36}
        {...dataStructureDataGrid}
        loading={tableDataLoading}
        // components={{
        //   Toolbar: () => {
        //     return CustomToolbar();
        //   },
        // }}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </Box>
  </Box>
  )
}

export default MachineDB