import { Box,  Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { LoadingButton } from "@mui/lab";
import RoboflowMultipleImages from "./RoboflowMultipleImages";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../../Redux/Services/admin.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "69vw",
  backgroundColor: "#fff",
  borderRadius: "10px",
  p: 2,
};

function PCBMaskingImgEditor({
  isOpen,
  onCloseModal,
  image,
  setImage,
  setPresignedImage,
  color,
  setColor,
  length,
  type,
  width,
  vault,
}: any) {
  const imageEditor = useRef<any>(null);

  const [apiloader, setApiLoader] = useState<any>(false);
  const [position, setPosition] = useState<any>(null);
  const [canvas, setCanvas] = useState<any>(null);
  const [resultImage, setResultImage] = useState<any>(null);

  const handleUpdateImage = async () => {
    setApiLoader(true);
    const updatedImage = imageEditor?.current?.imageEditorInst;

    const updatedData = updatedImage.toDataURL().split(",")[1];
    const byteCharacters = atob(updatedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "image/png" });
    const updatedFile = new File([file], "EditedImage.png", {
      type: "image/png",
    });

    const formData = new FormData();

    if (type == "top") {
      formData.append("top_image", updatedFile); // Append the file
    } else {
      formData.append("bottom_image", updatedFile); // Append the file
    }

    API.put(`pcb/pcb_info_for_vault/${vault}/`, formData, {}, 0)
      .then((res) => {
        if (type == "top") {
          if (res.data?.top_image && res.data?.presigned_top_image) {
            setImage(res.data?.top_image);
            setPresignedImage(res.data?.presigned_top_image);
            onCloseModal();
          } else {
            ADMIN.toast.error("Overlay addition failed");
          }
        } else {
          if (res.data?.bottom_image && res.data?.stitched_bottom_image) {
            setImage(res.data?.bottom_image);
            setPresignedImage(res.data?.presigned_bottom_image);
            onCloseModal();
          } else {
            ADMIN.toast.error("Overlay addition failed");
          }
        }
      })
      .catch((err: any) => {
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
        });
      })
      .finally(() => {
        setApiLoader(false);
      });

    // let imgagessData = await RoboflowMultipleImages(
    //     newImages,
    //     vault,
    //     apiloader,
    //     setApiLoader,
    //     prevState,
    //     setResultImage
    //   );

    // setImage(updatedFile);
    // onCloseModal();
    // setLoader(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageEditor.current) {
        // console.log("ImageEditor ref (using interval):", imageEditor);
        // console.log("ImageEditor current (using interval):", imageEditor.current);

        const editorInstance = imageEditor.current?.imageEditorInst;

        if (editorInstance) {
          console.log("ImageEditor instance:", editorInstance);
          const canvas = editorInstance._graphics?._canvas;
          setCanvas({
            height: canvas?.height,
            width: canvas?.width,
            maxWidth: editorInstance._graphics?.cssMaxWidth,
            maxHeight: editorInstance._graphics?.cssMaxHeight,
          });

          // Add event listener for when a shape is added
          editorInstance.on("objectAdded", (event: any) => {
            console.log("Shape added:", event);

            if (event?.type === "rect" || event?.type === "circle") {
              const shapeFillColor = event?.fill || event?.stroke;
              if (shapeFillColor) {
                setPosition({
                  x: event?.left,
                  y: event?.top,
                  height: event?.height,
                  width: event?.width,
                });
                setColor(shapeFillColor?.color);
              }
            }
          });

          // Add event listener for when a shape is modified
          editorInstance.on("objectModified", (event: any) => {
            console.log("Shape modified:", event);

            if (event?.type === "rect" || event?.type === "circle") {
              const updatedFillColor = event?.fill || event?.stroke;
              if (updatedFillColor) {
                setColor(updatedFillColor);
              }
            }
          });

          editorInstance.on("objectMoved", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });

          editorInstance.on("objectRotated", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });

          // editorInstance.on("undoStackChanged", (event: any) => {
          //   console.log(event, editorInstance?._graphics?._objects);
          //   const transformData = (data: any) => {
          //     return Object.values(data)?.map((innerObj: any) => ({
          //       id: innerObj?.__fe_id,
          //       text: innerObj?.text,
          //       left: innerObj?.left,
          //       top: innerObj?.top,
          //     }));
          //   };
          //   const transformedArray = transformData(
          //     editorInstance?._graphics?._objects
          //   );

          //   const hasRectangle = transformedArray?.some(
          //     (item:any) => !item.hasOwnProperty("type") && !item.hasOwnProperty("text")
          //   );

          //   console.log(hasRectangle);

          // });

          editorInstance.on("objectScaled", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });
        
          // Cleanup event listeners
          const cleanupListeners = () => {
            editorInstance?.off("objectAdded");
            editorInstance?.off("objectModified");
            editorInstance?.off("objectMoved");
            editorInstance?.off("objectRotated");
            editorInstance?.off("undo");
            editorInstance?.off("objectScaled");
          };

          // Cleanup interval and attach cleanup listeners
          clearInterval(intervalId);
          return cleanupListeners;
        }
      }
    }, 1000); // Poll every 1000ms

    return () => clearInterval(intervalId); // Ensure cleanup on unmount
  }, [imageEditor]);

  useEffect(() => {
    if (imageEditor.current) {
      const editorInstnce = imageEditor.current?.imageEditorInst;
      if (editorInstnce && length && position) {
        const transformData = (data: any) => {
          return Object.values(data)?.map((innerObj: any) => ({
            id: innerObj?.__fe_id,
            text: innerObj?.text,
            left: innerObj?.left,
            top: innerObj?.top,
          }));
        };
        const transformedArray = transformData(
          editorInstnce?._graphics?._objects
        );

        const existsItem = transformedArray?.find((item: any) =>
          item?.text?.includes("Length")
        );

        if (length) {
          // Ensure the addText method exists before calling it
          if (editorInstnce.addText) {
            if (!existsItem) {
              editorInstnce.addText(`Length: ${length} mm`, {
                styles: {
                  fill: color ? color : "#4287f5",
                  fontSize: 30,
                  textAlign: "center",
                  fontWeight: "bold",
                },

                position: {
                  x: position?.x - position?.width / 4 + 10,
                  y: position?.y - position?.height / 2 - 50,
                },
              });
            } else {
              editorInstnce.changeText(existsItem?.id, `Length: ${length}`);
            }
          }
        }
      }
    }
  }, [length, imageEditor]);

  useEffect(() => {
    if (imageEditor.current) {
      const editorInstnce = imageEditor.current?.imageEditorInst;
      if (editorInstnce && width && position) {
        const transformData = (data: any) => {
          return Object.values(data)?.map((innerObj: any) => ({
            id: innerObj?.__fe_id,
            text: innerObj?.text,
            left: innerObj?.left,
            top: innerObj?.top,
          }));
        };
        const transformedArray = transformData(
          editorInstnce?._graphics?._objects
        );
        console.log(transformedArray, "objects");

        const existsItem = transformedArray?.find((item: any) =>
          item?.text?.includes("Width")
        );

        if (width) {
          // Ensure the addText method exists before calling it
          if (editorInstnce.addText) {
            if (!existsItem) {
              editorInstnce.addText(`Width: ${width} mm`, {
                styles: {
                  fill: color ? color : "#4287f5",
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bold",
                },
                position: {
                  x: position?.x + position?.width / 2 + 10,
                  y: position?.y,
                },
              });
            } else {
              editorInstnce.changeText(existsItem?.id, `Width: ${width} mm`);
            }
          }
        }
      }
    }
  }, [width, imageEditor]);



  return (
    <Modal
      disableEnforceFocus
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Edit PCB Image
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>

        <ImageEditor
          includeUI={{
            loadImage: {
              path:
                //"https://media.istockphoto.com/id/2152193820/photo/fire-png-burning-flame-isolated-on-a-black-background.jpg?s=1024x1024&w=is&k=20&c=ypvaeZisl8b_sb0NLdRm_GFqrpKiOlvI-sK_ON-lCA4=",
                //"https://plus.unsplash.com/premium_photo-1734626880757-a107821e718e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                image,
              name: "EditedImage",
            },
            menu: ["mask"],
            uiSize: {
              width: "100%",
              height: "73vh",
            },
            menuBarPosition: "left",
            // initMenu: "mask", // Open shape menu by default
            shape: {
              // Default shape configurations
              fill: "blue", // Set default fill color to blue
              stroke: "black", // Optional: Set default stroke color
              strokeWidth: 2, // Optional: Set default stroke width
              rect: false,
              triangle: false,
              circle: false,
              ellipsis: false,
            },
            
          }}
          cssMaxHeight={500}
          cssMaxWidth={1000}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
          ref={imageEditor}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1rem",
          }}
        >
          <LoadingButton
            size="small"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            variant="contained"
            onClick={handleUpdateImage}
            loading={apiloader}
          >
            Save
          </LoadingButton>
        </Box>
      </div>
    </Modal>
  );
}

export default PCBMaskingImgEditor;
