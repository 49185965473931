import React from 'react';
import { Box, ButtonProps, Typography } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";


export interface CostingDBParameterTableProps {
    rows: any[];
    dbParameters: any;
}

const csvOptions: GridCsvExportOptions = {
    fileName: "BOM module CSV file",
    delimiter: ",",
    utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
);

const CustomToolbar = (dbParameters:any) => (
    <GridToolbarContainer style={{ height: "2rem", marginBottom: "0.2rem", width: "100%" }}>
        <span style={{ position: "absolute", left: "0rem" }}>
            <Typography sx={{ fontSize: "1rem" }}>
                {" "}
                {dbParameters}
            </Typography>
        </span>
        <span style={{ position: "absolute", right: "0rem" }}>
            <GridToolbarColumnsButton />
            <CustomExportButton />
        </span>
    </GridToolbarContainer>
);

const CostingDBParameterTable = (props: CostingDBParameterTableProps) => {
    const { dbParameters,rows } = props;

    const columns = [{
        field: "name",
        headerName: "Name",
        minWidth: 180,
        flex: 1.5,
    },
    {
        field: "value",
        headerName: "Value",
        minWidth: 100,
        flex: 1.5,
    },
    {
        field: "unit",
        headerName: "Unit",
        minWidth: 100,
        flex: 1.5,
    }
    ];

    return (
        <Box
            sx={{
                width: "100%",
                marginTop: "1rem",
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                headerHeight={30}
                components={{
                    Toolbar: () => {
                        return CustomToolbar(dbParameters);
                    },
                }}
                getRowHeight={() => "auto"}
                autoHeight={true}
                hideFooter={true}
                sx={{
                    fontSize: "12px",
                    borderColor: "primary.light !important",
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },

                    "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                    {
                        borderBottomColor: "primary.light",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                        minHeight: "26px",
                    },
                }}
            />
        </Box>
    );

}
export default CostingDBParameterTable;