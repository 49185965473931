import React from "react";
import { getResponseDisplayType } from "./ChatRightSection";
import { Box, Button, Divider, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import DoneIcon from "@mui/icons-material/Done";
import { ADMIN } from "../Redux/Services/admin.service";
import RViewerJS from "viewerjs-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import { PieChart, Pie, Cell, Legend } from "recharts";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Define types
type ResponseDisplay = "table" | "textAndImages" | "normal" | "mixed";
interface ParsedRow {
  [key: string]: string;
}

interface ResponseProps {
  responseData: string; // The raw response
  sender: string; // The sender of the response
}

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "#f5f5f5",
        borderBottom: "1px solid #ccc",
      }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

// Utility function to format plain text
function formatResponse(response: string): string[] {
  const cleanedResponse = response?.replace(/\\n/g, "\n")?.replace(/\\'/g, "'");
  return cleanedResponse?.split("\n\n")?.map((paragraph) => paragraph?.trim());
}

// const getRandomColor = () => {
//   const letters = "0123456789ABCDEF";
//   let color = "#";
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

const ResponseRenderer: React.FC<ResponseProps> = ({
  responseData,
  sender,
}) => {
  // const pdfRef = React.useRef<HTMLDivElement>(null);
  const { type, tableData, content } = getResponseDisplayType(responseData);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleCopySuccess = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const colors = [
    "#1A237E", // Dark Blue
    "#C2185B", // Dark Pink
    "#D32F2F", // Dark Red
    "#1976D2", // Medium Blue
    "#388E3C", // Dark Green
    "#F57F20", // Dark Orange
    "#7B1FA2", // Dark Purple
    "#0288D1", // Light Blue (Darker Shade)
    "#FBC02D", // Dark Yellow
    "#5D4037"  // Dark Brown
  ];
  
  // Create a function to get colors sequentially
  let colorIndex = 0; // Initialize a counter to keep track of the current color
  
  const getNextColor = (): string => {
    const color = colors[colorIndex]; // Get the current color
    colorIndex = (colorIndex + 1) % colors.length; // Increment and wrap around
    return color; // Return the current color
  };



  if (type === "mixed" && content) {
    return (
      //@ts-ignore
      <RViewerJS>
        <Box>
        {/* {sender!== "user" && <PictureAsPdfIcon
            color="primary"
            onClick={handleDownloadPDF}
            sx={{
                cursor: "pointer",
                float: "right",
                fontSize: "1.3rem",
              }}
          />
          } */}
          {content.map((item, index) => {
            if (item.type === "text") {
              return (
                <Typography
                  key={`text-${index}`}
                  variant="body2"
                  sx={{
                    margin: "0.5rem",
                    display: "block",
                    color: "#13343b",
                    fontWeight: sender === "user"?"bold":"",
                    fontSize: sender === "user"?"1.5rem":"",
                    lineHeight: sender === "user"?"2.25rem":"",
                  }}
                >
                  {item.value}
                 {/* {sender === "user" && <Divider sx={{ marginBottom: "0.5rem" }} />} */}
                </Typography>
              );
            } else if (item?.type === "table" && item?.tableData) {            
              if (!Array.isArray(item?.tableData) || item?.tableData.length === 0) {
                return (
                  <Typography variant="body2">
                    No table data available.
                  </Typography>
                );
              }
            
              const columns: GridColDef[] = item?.headers?.map((key: any) => ({
                field: key,
                headerName: key?.charAt(0)?.toUpperCase() + key?.slice(1),
                flex: 1,
              }));
            
              // Filter rows where all column values are "---------------"
              const filteredTableData = item?.tableData?.filter((row: any) => {
                return Object.values(row).some(
                  (value) => !value?.toString()?.includes("-----")
                );
              });
            
              const rows = filteredTableData?.map((row, index) => ({
                id: index,
                ...row,
              }));
            
              return (
                <Box sx={{ height: 350, width: "49vw" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: CustomToolbar }}
                    density="compact"
                    hideFooter

                  />
                </Box>
              );
            }
            
             else if (item.type === "image") {
              return (
                <Box
                  key={`image-${index}`}
                  sx={{
                    margin: "1rem 0",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={`${"https://d15oguk17zlxt1.cloudfront.net"}${
                      item.value
                    }`}
                    alt={`content-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              );
            } else if (item.type === "graph" && item.graphData) {
              return (
                <Box
                  key={`graph-${index}`}
                  sx={{
                    margin: "1rem 0",
                    padding: "1rem",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    {item.graphName}
                  </Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={item.graphData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill={getNextColor()} />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              );
            }
            //  else if (item.type === "graph" && item.graphData) {
            //   return (
            //     <Box
            //       key={`pie-${index}`}
            //       sx={{
            //         margin: "1rem 0",
            //         padding: "1rem",
            //         backgroundColor: "#f5f5f5",
            //         borderRadius: "4px",
            //         width: "100%",
            //         height: "350px",
            //       }}
            //     >
            //       <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
            //         {item.graphName}
            //       </Typography>
            //       <ResponsiveContainer width="100%" height="100%">
            //         <PieChart>
            //           <Pie
            //             data={item.graphData}
            //             dataKey="value"
            //             nameKey="name"
            //             cx="50%"
            //             cy="50%"
            //             outerRadius={100}
            //             fill="#8884d8"
            //             label
            //           >
            //             {item.graphData.map((_, i) => (
            //               <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
            //             ))}
            //           </Pie>
            //           <Tooltip />
            //           <Legend />
            //         </PieChart>
            //       </ResponsiveContainer>
            //     </Box>
            //   );
            // }
            return null;
          })}
          {sender!== "user"&&<Divider sx={{ marginBottom: "0.5rem",width:'100%' }} />}
        </Box>
        
      </RViewerJS>
    );
  }

  // Render Text and Images


  // Default rendering for plain text
  return (
    <Box>
      {sender !== "user" && (
        <Box>
          {/* {isCopied ? (
            <DoneIcon
              fontSize="small"
              sx={{ color: "gray", float: "right", fontSize: "1.3rem" }}
            />
          ) : (
            <ContentCopyIcon
              fontSize="small"
              sx={{
                cursor: "pointer",
                color: "gray",
                float: "right",
                fontSize: "1.3rem",
              }}
              onClick={handleTextCopy}
            />
          )} */}
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
            sx={{
                cursor: "pointer",
                float: "right",
                // fontSize: "1.3rem",
              }}
          >
            Download PDF
          </Button> */}
        </Box>
      )}
      {formatResponse(responseData)?.map((paragraph, index) => (
        <Typography key={index} variant="body2" sx={{ margin: "0.5rem" }}>
          {paragraph?.split("\n")?.map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      ))}
    </Box>
  );
};

export default ResponseRenderer;
