import React, { useState } from "react";
import {
  Box,
  Divider,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Chip,
  Button,
  Slider,
  TextField,
  Stepper,
  Step,
  StepLabel,
  styled,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AmazonLogo from "../Assets/images/aws.png";
import AnthropicLogo from "../Assets/images/anthropiclogo.png";
import MetaLogo from "../Assets/images/metalogo.png";
import successIcon from "../Assets/images/iSuccess.svg";
import { API } from "../api-services";
import { set } from "lodash";

export interface IAIModelControlProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "35vw",
  maxWidth: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  p: 2,
};

const sidebarStyle = {
  flex: 1,
  borderRight: "1px solid #E0E0E0",
  overflowY: "auto",
  bgcolor: "background.default",
};

const contentStyle = {
  flex: 2,
  padding: "1.5rem",
  overflowY: "auto",
};

const headerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 2,
};

const categories = [
  { name: "Amazon", icon: AmazonLogo },
  { name: "Anthropic", icon: AnthropicLogo },
  { name: "Meta", icon: MetaLogo },
];

const models: any = {
  Amazon: [{ name: "Nova Pro v1.0", legacy: true }],
  Anthropic: [{ name: "Claude 3.5 Sonnet v1", legacy: true }],
  Meta: [{ name: "Llama 3.2 11B Instruct v1", legacy: true }],
};

const PrettoSlider = styled(Slider)({
  color: "primary.main",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "primary.main",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export function AIModelControlModal(props: IAIModelControlProps) {
  const { isOpen, onCloseModal } = props;
  const [selectedData, setSelectedData] = useState<any>("");
  const [configData,setConfigdata] = useState<any>({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("Amazon");
  const [selectedModel, setSelectedModel] = useState("");
  const [sliderValues, setSliderValues] = useState({
    temperature: 0,
    topP: 0,
    topK: 0,
    maxLength: 0,
    maxTokens: 0,
    responseLength: 0,
  });

  const handleFetch = () => {
    API.get("/xcpep/llm_model_config/",{name: selectedModel?.replace(/\s+/g, "_").replace(/[^\w.-]/g, "")},0).then((res:any)=>{
        setConfigdata(res.data?.config);
        if(Object.keys(res.data?.config)?.length>0){
        setSliderValues(res.data?.config);
        }
        setActiveStep(1);
    }).catch((err:any)=>{
        setActiveStep(1);
        setSliderValues({
            temperature: 0,
            topP: 0,
            topK: 0,
            maxLength: 0,
            responseLength: 0,
            maxTokens: 0,
        })
    })
    
  };

  const getSelectedData = () => {
    API.get("/xcpep/llm_model_config/", { get_seleted_model: true }, 0)
      .then((res: any) => {
        setSelectedData(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getSelectedData();
  }, []);


  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      API.post(
        "/xcpep/llm_model_config/",
        {
          status: true,
          name: selectedModel?.replace(/\s+/g, "_").replace(/[^\w.-]/g, ""),
          config: sliderValues,
        },
        0
      )
        .then((res: any) => {
          setActiveStep((prevStep) => prevStep + 1);
        })
        .catch((err: any) => {});
    } else if (activeStep === 0) {
      
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const getMaximumTokensLimit=()=>{
   if(selectedModel?.includes("Claude")){
       return 200000;
   }else if(selectedModel?.includes("Nova")){
       return 300000;
   }else{
        return 128000;
   }
  }

  // Generic handler for slider change
  const handleSliderChange =
    (key: string) => (event: Event, newValue: number | number[]) => {
      setSliderValues((prev) => ({
        ...prev,
        [key]: newValue as number,
      }));
    };

  // Generic handler for text field change
  const handleTextFieldChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value);

      // Validation for each slider range
      const ranges: { [key: string]: { min: number; max: number } } = {
        temperature: { min: 0, max: 1 },
        topP: { min: 0, max: 1 },
        topK: { min: 0, max: 500 },
        maxLength: { min: 0, max: 4096 },
        maxTokens: { min: 0, max: getMaximumTokensLimit() },
        responseLength: { min: 0, max: 4096 },
      };

      if (
        !isNaN(newValue) &&
        newValue >= ranges[key].min &&
        newValue <= ranges[key].max
      ) {
        setSliderValues((prev) => ({
          ...prev,
          [key]: newValue,
        }));
      }
    };

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Box sx={modalStyle}>
        {/* Header */}
        <Box sx={headerStyle}>
          <Typography variant="h6" fontWeight="600">
            Model Controller
          </Typography>
          <Typography variant="body2" fontWeight="600">
            {typeof selectedData === "string"
              ? selectedData:""}
          </Typography>
          <CancelIcon
            sx={{ cursor: "pointer", color: "primary.main" }}
            onClick={onCloseModal}
          />
        </Box>

        <Divider />

        {/* Stepper */}
        <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 2 }}>
          <Step>
            <StepLabel>Select Model</StepLabel>
          </Step>
          <Step>
            <StepLabel>Configure Model</StepLabel>
          </Step>
          <Step>
            <StepLabel>Update Value</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <Box sx={{ display: "flex", height: "70vh", mt: 2 }}>
            {/* Left Sidebar (Categories) */}
            <Box sx={sidebarStyle}>
              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ px: 2, py: 1, color: "primary.main" }}
              >
                Categories
              </Typography>
              <List disablePadding>
                {categories.map((category) => (
                  <ListItem key={category.name} disablePadding>
                    <ListItemButton
                      selected={selectedCategory === category.name}
                      onClick={() => {
                        setSelectedCategory(category.name)
                        setSelectedModel("")
                    }}
                      sx={{
                        borderRadius: "8px",
                        mx: 1,
                        mb: 1,
                        "&.Mui-selected": {
                          bgcolor: "primary.light",
                          color: "primary.main",
                          fontWeight: "600",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={category.icon}
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={category.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Right Content (Models) */}
            <Box sx={contentStyle}>
              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ mb: 1, color: "primary.main" }}
              >
                Models with Access
              </Typography>

              {models[selectedCategory]?.map((model: any) => (
                <Box
                  key={model.name}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: 2,
                    py: 1,
                    mb: 1,
                    borderRadius: "8px",
                    bgcolor:
                      selectedModel === model.name
                        ? "primary.light"
                        : "background.default",
                    cursor: "pointer",
                    boxShadow:
                      selectedModel === model.name
                        ? "0 2px 6px rgba(0, 0, 0, 0.1)"
                        : "none",
                    "&:hover": { bgcolor: "action.hover" },
                  }}
                  onClick={() => setSelectedModel(model.name)}
                >
                  <Typography fontSize="0.95rem" fontWeight="500">
                    {model.name}
                  </Typography>
                  {model.legacy && (
                    <Chip
                      label="Legacy"
                      size="small"
                      sx={{
                        bgcolor: "warning.light",
                        color: "white",
                        fontWeight: "500",
                      }}
                    />
                  )}
                </Box>
              ))}

              <Button
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
                disabled={!selectedModel}
                onClick={handleFetch}
              >
                Fetch Model
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 1 && (
          <Box sx={{ height: "fit-content", mt: 2, px: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ mb: 2, color: "grey" }}
              >
                {selectedCategory} {`>`}
              </Typography>
              <Typography variant="subtitle2" fontWeight="600" sx={{ mb: 2 }}>
                {" "}
                {selectedModel} - Configurations
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />

            <Typography variant="body2" fontWeight="500" gutterBottom>
              Randomness and diversity
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              {/* Temperature Slider */}
              <Box sx={{ alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 1,
                  }}
                >
                  <Typography variant="caption">Temperature</Typography>
                  <TextField
                    size="small"
                    sx={{ width: "7rem" }}
                    value={sliderValues.temperature}
                    onChange={handleTextFieldChange("temperature")}
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: 1,
                      type: "number",
                    }}
                  />
                </Box>
                <PrettoSlider
                  value={sliderValues.temperature}
                  onChange={handleSliderChange("temperature")}
                  valueLabelDisplay="auto"
                  aria-label="temperature slider"
                  min={0}
                  max={1}
                  step={0.001}
                />
              </Box>

              {/* Top P Slider */}
              <Box sx={{ alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 1,
                  }}
                >
                  <Typography variant="caption">Top P</Typography>
                  <TextField
                    size="small"
                    sx={{ width: "7rem" }}
                    value={sliderValues.topP}
                    onChange={handleTextFieldChange("topP")}
                    inputProps={{
                      step: 0.001,
                      min: 0,
                      max: 1,
                      type: "number",
                    }}
                  />
                </Box>
                <PrettoSlider
                  value={sliderValues.topP}
                  onChange={handleSliderChange("topP")}
                  valueLabelDisplay="auto"
                  aria-label="top p slider"
                  min={0}
                  max={1}
                  step={0.001}
                />
              </Box>

              {/* Top K Slider */}
              {selectedModel?.includes("Claude") && (
                <Box sx={{ alignItems: "center", mb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <Typography variant="caption">Top K</Typography>
                    <TextField
                      size="small"
                      sx={{ width: "7rem" }}
                      value={sliderValues.topK}
                      onChange={handleTextFieldChange("topK")}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 500,
                        type: "number",
                      }}
                    />
                  </Box>
                  <PrettoSlider
                    value={sliderValues.topK}
                    onChange={handleSliderChange("topK")}
                    valueLabelDisplay="auto"
                    aria-label="top k slider"
                    min={0}
                    max={500}
                    step={1}
                  />
                </Box>
              )}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" fontWeight="500" gutterBottom>
              Length
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              {/* Maximum Length Slider */}
              <Box sx={{ alignItems: "center", mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 1,
                  }}
                >
                  <Typography variant="caption">
                    {selectedModel?.includes("Claude")
                      ? "Maximum Length"
                      : "Response Length"}
                  </Typography>
                  <TextField
                    size="small"
                    sx={{ width: "7rem" }}
                    value={
                      selectedModel?.includes("Claude")
                        ? sliderValues?.maxLength
                        : sliderValues?.responseLength
                    }
                    onChange={handleTextFieldChange(
                      selectedModel?.includes("Claude")
                        ? "maxLength"
                        : "responseLength"
                    )}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 4096,
                      type: "number",
                    }}
                  />
                </Box>
                <PrettoSlider
                  value={
                    selectedModel?.includes("Claude")
                      ? sliderValues.maxLength
                      : sliderValues.responseLength
                  }
                  onChange={handleSliderChange(
                    selectedModel?.includes("Claude")
                      ? "maxLength"
                      : "responseLength"
                  )}
                  valueLabelDisplay="auto"
                  aria-label="max length slider"
                  min={0}
                  max={2000}
                  step={10}
                />
              </Box>
              <Box sx={{ alignItems: "center", mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 1,
                  }}
                >
                  <Typography variant="caption">
                   Max Tokens
                  </Typography>
                  <TextField
                    size="small"
                    sx={{ width: "7rem" }}
                    value={sliderValues?.maxTokens}
                    onChange={handleTextFieldChange("maxTokens")}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: getMaximumTokensLimit(),
                      type: "number",
                    }}
                  />
                </Box>
                <PrettoSlider
                  value={
                    sliderValues.maxTokens
                  }
                  onChange={handleSliderChange("maxTokens" )}
                  valueLabelDisplay="auto"
                  aria-label="max length slider"
                  min={0}
                  max={getMaximumTokensLimit()}
                  step={10}
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              sx={{ mt: 2 }}
              fullWidth
              onClick={handleNext}
            >
              {activeStep === 1 ? "Save" : "Next Step"}
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              onClick={handleBack}
            >
              Back
            </Button>
          </Box>
        )}
        {activeStep === 2 && (
          <Box sx={{ height: "fit-content", mt: 2, px: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ mb: 2, color: "grey" }}
              >
                {selectedCategory} {`>`}
              </Typography>
              <Typography variant="subtitle2" fontWeight="600" sx={{ mb: 2 }}>
                {" "}
                {selectedModel} - Update Status
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={successIcon}
                  style={{ width: "10rem", height: "10rem" }}
                />
              </Box>
              <Typography>Configuration Updated Successfully</Typography>
            </Box>

            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              onClick={onCloseModal}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
