import { Box, Button, Chip, debounce, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import CompareTreeViewcomponents from "./CompareTreevViewcomponents";
import CompareViewpage from "./CompareViewpage";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { isUndefined } from "lodash";
import CancelIcon from '@mui/icons-material/Cancel';
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import excelIcon from "../../Assets/images/excel.png"
import { DialogComponent } from "../../utlis/DialogComponent";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CurrencyChangeDynamic } from "../CostingView/CurrecyChangeDynamic";


type Props = {};
const options = ['Option 1', 'Option 2'];
const NewcompareLandingPage = (props: Props) => {
  const { projectId, moduleType, topVaultId, abbr, NodeId, Count, TopId, Partname, Nodeleaf, depthNode, pathname } =
    useParams<any>();
    const [defaultCurrency, setDefaultCurrency] = useState<any>("INR");
const { url } = useRouteMatch();

  const [AllHierarchy, setAllHierarchy] = useState<any>();
  // @ts-ignore
  const [value, setValue] = React.useState<any>([]);
  const currencyModal =useBaseModal();
  const [inputValue, setInputValue] = React.useState(null);
  const history = useHistory<any>();
  const [SearchValue, setSearchValue] = useState([])
  const [AllProjectdata, setAllProjectdata] = useState()
  const [selectedTopVaultIds, setSelectedTopVaultIds] = useState([])
  const [counts,setCount]=React.useState<any>(0)
  const [loader, setLoader] = useState<any>(false)
  const [Counter, setCounter] = useState(1)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [LoaderStatus, setLoaderStatus] = useState<any>(false);
  const [reportLoader,setReportLoader] = useState<any>(false)
  const openDialogModal = useBaseModal();
  const open = Boolean(anchorEl);
  const ModalOpen = useBaseModal();
  const topVaultsParam: any =
    topVaultId && typeof topVaultId == "string"
      ? topVaultId?.split(",")?.map(Number)
      : [topVaultId];
  const projectIdParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });

  useEffect(() => {
    setValue([])
    setInputValue(null)
  }, [Count])

  useEffect(() => {
    setValue([])
    // API.get("/customer/compare_module/", {
    //   hierarchy: true,
    //   product: true,
    //   top_vaults: topVaultId,
    // }).then((res: any) => {
    //   setAllHierarchy(res?.data);
    // });
    setLoader(true)
    API.get("/xcpep/system_master_hirechy_fetch/", { top_vault: topVaultId }, 0).then((res: any) => {
      setAllHierarchy(res?.data?.data);
      setLoader(false)
    }).catch((err: any) => {
      setLoader(false)
    })


  }, [Count]);
  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "Costing",top_vault:topVaultId },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency", res.data[0]?.current_currency);
      sessionStorage.setItem(
          "actionKey",
          res.data[0]?.is_manual ? "mannual" : "auto"
        );
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
            "currencyrate_mannual",
            res.data[0]?.conversion_factor
          );
      } else {
       sessionStorage.setItem(
            "currencyrate",
            (1 / res.data[0]?.conversion_factor).toString()
          );
      }
    });
  }, [counts]);

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { top_vault: topVaultId, module: "Costing", get_default_currency: true },
      0
    )
      .then((res: any) => {
        url?.includes('analysis') ? sessionStorage.setItem("currency_analysis",res.data[0]?.current_currency) : url.includes("costingsystemlanding") || url.includes("costingscenarioabbrlanding") || url.includes("costingscenario") ? sessionStorage.setItem("costingtable",res.data[0]?.current_currency) : url.includes("costingqualitycheck") ? sessionStorage.setItem("costingLpp",res.data[0]?.current_currency) : sessionStorage.setItem("currency",res.data[0]?.current_currency)
        setDefaultCurrency(res.data[0]?.current_currency);
      })
      .catch((err: any) => {});
  }, [topVaultId]);









  useEffect(() => {
    if (TopId != null) {
      let a: any = sessionStorage?.getItem("Comparenode");
      let val: any = a && JSON?.parse(a);
      if (val == null) {
        sessionStorage.setItem("Comparenode", JSON.stringify([Number(TopId)]));
      }
    }
  }, [TopId])





  const onSelect = (value: any, abbreviation: any, name: any, leafNode: any, depth: any, path: any) => {
    if (abbreviation == "product") {
      history.push(
        `/newcompare/${projectId}/${moduleType}/${topVaultId}/${abbreviation}/${0}/productassembly/${Count}/${value}/${leafNode}/${depth}/${path}`
      );
    } else {
      history.push(
        `/newcompare/${projectId}/${moduleType}/${topVaultId}/${abbreviation}/${value}/${name}/${Count}/${TopId}/${leafNode}/${depth}/${path}`
      );
    }
  };
  const handleChange = (newValue: any) => {
    setValue(newValue);

    setAllHierarchy(null)
    // setCounter(Counter + 1)
    if (newValue !== null) {
      history.push(`/newcompare/${projectId}/${moduleType}/${filterDataIds}/${newValue.abbreviation}/${newValue?.id}/${newValue?.title}/${Counter}/${TopId}/${newValue.is_leaf}/${newValue?.depthNode}/${newValue.path}`)
      API.get("/customer/compare_module/", {
        hierarchy: true,
        part_name: newValue?.title,
        depth: newValue?.depthNode,
        part_id: newValue?.id,
        top_vaults: topVaultId,
        path: pathname
      },0).then((res: any) => {
        setAllHierarchy(res?.data);

      });
    }
    if (newValue == null) {
      sessionStorage.setItem("Comparenode", JSON.stringify(["vehicle"]));
      history.push(`/newcompare/${projectId}/${moduleType}/${filterDataIds}/product/0/productassembly/${Counter}/vehicle/false/-1/null`)
      // history.push(`/newcompare/${projectId}/${moduleType}/${filterDataIds}/product/0/${Partname}/${Counter}/${TopId}/false/0/null}`)
      setLoader(true)
      API.get("/xcpep/system_master_hirechy_fetch/", { top_vault: topVaultId }, 0).then((res: any) => {
        setAllHierarchy(res?.data?.data);
       
        setLoader(false)
      }).catch((err: any) => {
        setLoader(false)
      })
    }
  }
  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, Ids: any) => {
      await API.get("/customer/compare_name_search/", { top_vaults: Ids.toString(), part_name: criteria, path: pathname }).then(
        (res: any) => {
          setSearchValue(res.data);
        }
      );
      //  setCharacters();
    }, 500)
  ).current;
  const PartNameHandler = (item: any) => {
    setSearchValue([])
    setInputValue(item);
    if (item.length > 0)
      ExactDebounce(item, filterDataIds)
  }
  const SearchOption = () => {
    return SearchValue?.map((item: any, index: any) => {
      return ({
        title: item.parts_name,
        id: item?.id,
        abbreviation: item?.abbreviation,
        depthNode: item?.depth,
        path: item?.path,
        is_leaf: item?.is_leaf,
      })
    })
  }
  const ChipContainer = (chipItem: any) => {
    var chipdata = AllProjectdata && AllProjectdata[0][chipItem]
    // @ts-ignore
    var mapChipData = chipdata?.map((item: any, index: any) => {
      return (<>{filterDataIds && filterDataIds?.length <= 2 ?
        <Chip
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            color: "primary.light !important",
            display: 'flex',
            columnGap: '5px',
            backgroundColor: '#007fff14 !important',
            "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChip-deleteIconColorPrimary":
            {
              fontSize: "1rem !important",
              color: 'primary.main !important',
            },
          }}
        /> :
        <Chip
          icon={
            <CancelIcon
              onClick={() => handleDelete(item?.id)}
              color="primary"
              sx={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: 'primary.main !important',
                opacity: 0.8,
                "& :hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              titleAccess="Delete"
            />
          }
          // onDelete={() => handleDelete(item?.id)}
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            margin: "2px 0",
            color: "primary.light !important",
            backgroundColor: "#007fff14 !important",
            '& :hover': {
              opacity: 1,
            }
          }}
        />}
      </>)
    })
    return mapChipData;
  }
  const handleDelete = (item1: any) => {
    setCounter(Counter + 1)
    var ProjectIdsdata = AllProjectdata && AllProjectdata[0];
    var CollectionProjectids = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    }).flat(1).filter((item3: any, index: any) => { return item3 != item1 })
    //  return CollectionProjectids;
    // @ts-ignore

    API.get("/customer/compare_module/", { product_ids: CollectionProjectids?.toString(), path: pathname },0).then((res: any) => {
      var datavalues = res?.data?.map((item: any, index: any) => {
        return Object.values(item).map((item1: any, index: any) => {
          return item1.map((item2: any, index: any) => { return item2?.project_id })
        })
      }).flat(2)
      // @ts-ignore
       // @ts-ignore
      var duplicateData = [...new Set(datavalues)];
        // @ts-ignore
      history.push(`/newcompare/${duplicateData.toString()}/${moduleType}/${CollectionProjectids?.toString()}/${abbr}/${NodeId}/${Partname}/${Counter}/${TopId}/${Nodeleaf}/${depthNode}/${pathname}`)
      //  @ts-ignore
      setAllProjectdata(res.data)
    })
    // history.push(`/newcompare/${projectId}/${moduleType}/${CollectionProjectids?.toString()}/${abbr}/${NodeId}/${Partname}/${Counter}/${TopId}/${Nodeleaf}/${depthNode}/${pathname}`)
  }
  const deleteProject = (item: any) => {
    setCounter(Counter + 1)
    var dataArray = [item];
    const filteredProject =
      AllProjectdata && AllProjectdata[0] && Object.keys(AllProjectdata[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = AllProjectdata[0][key];
          return obj;
        }, {});
    const FilterTopVault =
      filteredProject && Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);
// @ts-ignore
        API.get("/customer/compare_module/", { product_ids: FilterTopVault?.toString(), path: pathname },0).then((res: any) => {
          var datavalues = res?.data?.map((item: any, index: any) => {
            return Object.values(item).map((item1: any, index: any) => {
              return item1.map((item2: any, index: any) => { return item2?.project_id })
            })
          }).flat(2)
          // @ts-ignore
          var duplicateData = [...new Set(datavalues)];
          // @ts-ignore
          history.push(`/newcompare/${duplicateData.toString()}/${moduleType}/${FilterTopVault?.toString()}/${abbr}/${NodeId}/${Partname}/${Counter}/${TopId}/${Nodeleaf}/${depthNode}/${pathname}`)
          //  @ts-ignore
          setAllProjectdata(res.data)
        })


    

    //  @ts-ignore
    // history.push(`/newcompare/${projectId}/${moduleType}/${FilterTopVault?.toString()}/${abbr}/${NodeId}/${Partname}/${Counter}/${TopId}/${Nodeleaf}/${depthNode}/${pathname}`)
  }
  useEffect(() => {
    API.get("/customer/compare_module/", { product_ids: topVaultId, path: pathname }).then((res: any) => {
      // var datavalues = res?.data?.map((item: any, index: any) => {
      //   return Object.values(item).map((item1: any, index: any) => {
      //     return item1.map((item2: any, index: any) => { return item2?.project_id })
      //   })
      // }).flat(2)
      // // @ts-ignore
      // var duplicateData = [...new Set(datavalues)];
      // history.push(`/newcompare/${duplicateData.toString()}/${moduleType}/${topVaultId}/${abbr}/${NodeId}/${Partname}/${Counter}/${TopId}/${Nodeleaf}/${depthNode}/${pathname}`)
      //  @ts-ignore
      setAllProjectdata(res.data)
    })
  }, [topVaultId,Count])
  var projectLength = AllProjectdata && Object.keys(AllProjectdata[0])?.length;


  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const downLoadReport=(reportTypes:any)=>{
    // openDialogModal.open();
    setReportLoader(true)
    let newPartName ;
    if(Partname=="productassembly" ||  Partname == "Vehicle"){
      newPartName="product"
    }
    else{
      newPartName=Partname
    }
  API.get("/customer/compare_report_download/",
    
    
   { 
    top_vaults:topVaultId,
    part_name:newPartName,
    is_leaf:Nodeleaf,
    depth:depthNode,
    path:pathname,
    module:moduleType==1?"BOM":"Costing",
    // id:FeatureId,
    // ...ToggleTopVaultdata(),
    
    // category:categorydata.toString(),
    // top_vault_list:topVault,
    // download:true,
    // with_image:reportTypes,
    // consolidate_type:"Features",

  
  },0).then((res:any)=>{
    setReportLoader(false)
    window.open(res?.data,"_blank");

  
  }).catch((err)=>{

    setReportLoader(false)
  })
}
  return (
    <Box sx={{
      width: '100%',
      marginRight: '1rem',
      marginBottom: '1rem',
    }}>

     <DialogComponent
        isOpen={openDialogModal.isOpen}
        onClose={openDialogModal.close}
        content="We’ve got this report downloading faster than a cheetah running. You will also receive a notification.
                But in the meantime, keep using xcPEP like the productivity champion !"
        type={"firstdownload"}
      />


      <Box sx={{ height: '3rem', display: 'flex', alignItems: 'center',
      
      
    
    flexdirection: "row",
    justifyContent:"space-between",
      
      width: { sm: '91%', md: '100%', lg: '100%', xl: '100%' }, }}>

    
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: '10px' }}>{/* @ts-ignore */}
          {AllProjectdata && AllProjectdata?.map((item: any, index: any) => {
            return Object.keys(item).map((key: any, index: any) => {
              return (
                <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '1.5rem' }}>
                  {(filterDataIds && filterDataIds?.length <= 2 && projectLength && projectLength <= 2) || (projectLength && projectLength == 1 && filterDataIds?.length > 2) || (projectLength && projectLength == 2 && filterDataIds?.length < 5) ? " " :
                    <CancelIcon titleAccess="Delete" sx={{
                      "& :hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }} onClick={() => deleteProject(key)} style={{ cursor: "pointer" }} />
                  } <Typography style={{ fontSize: '1rem', paddingLeft: '5px' }}>{key}</Typography>
                  <Box sx={{ display: 'flex', columnGap: '5px' }}>
                    {ChipContainer(key)}
                  </Box>
                </Box>)
            })
          })}



        </Box>



        <div >
        {moduleType !=1 && <Button
                variant="contained"
                // className={styles.downloadBtn}
                size="small"
                endIcon={<ArrowDropDownIcon />}
                style={{ padding:'0.2rem 0.5rem',marginRight:'1rem' }}
                onClick={() => currencyModal.open()}
              >
                {sessionStorage.getItem("currency")}
              </Button>}
          <LoadingButton size="small" 
            // id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            // aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            // onClick={handleClick}
            onClick={() => downLoadReport("excel")}
            loading={reportLoader}
            endIcon={<DownloadIcon />}
            sx={{
              padding:'0.2rem 0.5rem',
              "&:hover": {
                transform: 'Scale(1.1)',
                transition: 'transform 0.5s ease',
              }
            }}

          >
           <Typography style={{fontSize:'1rem',fontWeight:'500',marginRight:'-0.5rem'}}>Download</Typography>  
          </LoadingButton>



          {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
           
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{".MuiList-root.MuiMenu-list":{
              padding:'0',
            }
          }}
          >
            <MenuItem

            disabled={reportLoader}
            
            sx={{ width: "10rem",fontSize:'1rem'}} onClick={() => downLoadReport("excel")}><img src={excelIcon} style={{ width: '1.5rem', paddingRight: '5px' }} /> Excel</MenuItem>
            

          </Menu> */}


        </div>

       
      </Box>


    
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 5fr",
          columnGap: "0.5rem",
          border: 'none',
          // borderColor: 'primary.light',
          borderRadius: '10px',
          width: { sm: '91%', md: '100%', lg: '100%', xl: '100%' },
          height: { sm: '91vh', md: '87vh', lg: '86vh', xl: '89vh' },
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 0px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
        }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        <Box sx={{ minWidth: '15rem', maxWidth: "30rem", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 0px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px", height: { sm: '91vh', md: '87vh', lg: '86vh', xl: '89vh' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Autocomplete
            value={value}
            id="controllable-states-demo"
            onChange={(event: any, newValue: string | null) => {
              handleChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            // @ts-ignore
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            // @ts-ignore
            getOptionLabel={(option) => 
              
            
              
              option?.title == undefined ? "" : option?.title
            
            }
            // @ts-ignore
            options={SearchOption() && SearchOption()}
            sx={{
              width: '100%',
              padding: "0 0.5rem",
            }}
            renderInput={(params) => <TextField {...params} variant="standard" sx={{
              fontSize: '1rem',
              '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
              "& input::placeholder": {
                fontSize: "1rem",
              }
            }}
              placeholder="Search Part Name"
            // label="Search Part Name"
            />}
          />
          {loader ?
            <Box sx={{ width: "100%", padding: '0 0.5rem' }}>
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2 rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
            </Box>
            :
            <CompareTreeViewcomponents
              HierarchyData={AllHierarchy && AllHierarchy}
              onSelectCallback={onSelect}
            />

          }


        </Box>
        <Box sx={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px" }}>      
          <CompareViewpage counts={counts} />
        </Box>
      </Box>
      <CurrencyChangeDynamic
        isOpen={currencyModal.isOpen}
        onCloseModal={currencyModal.close}
        setCount={setCount}
        currentCurrency={defaultCurrency}
      />
    </Box>);
};
export default NewcompareLandingPage;