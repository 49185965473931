import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  InputBase,
  styled,
  Skeleton,
  Badge,
  IconButton,
  Button,
} from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { barGraphData } from "./DummyData";
import ResponseRenderer from "./ResponseRenderer";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useSelector } from "react-redux";
import { userSelectors } from "../Redux/Reducers/user.reducer";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { useHistory } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import HomeIcon from "@mui/icons-material/Home";
import TuneIcon from "@mui/icons-material/Tune";
import { AUTH } from "../Redux/Services/auth.service";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { AIModelControlModal } from "./AIModelControlModal";

export interface IChatRightSectionProps {
  isEmptyGroup: any;
  setReCallGroup: any;
  setExpanded: any;
  expanded: any;
}

interface ChatMessage {
  id: number;
  type:
    | "text"
    | "image"
    | "graph"
    | "text+graph"
    | "pareto"
    | "multi-image"
    | "table";
  content: string | JSX.Element | any;
  sender: "user" | "ai";
  timestamp?: string | null;
  project?: string | null;
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

type ResponseDisplay = "table" | "normal";

interface ParsedRow {
  [key: string]: string;
}

/**
 * Dynamically determines if the response should be displayed as a table or normal response.
 * @param responseData - The string data from the AI response.
 * @returns "table" if the data should be displayed in a table, "normal" otherwise.
 */
export function getResponseDisplayType(input: string): {
  type: "mixed";
  tableData?: Array<Record<string, string>>;
  content?: Array<{
    type: "text" | "image" | "graph" | "table";
    value: string;
    graphName?: string;
    graphData?: Array<{ name: string; value: number }>;
    tableData?: any;
    headers?: any;
  }>;
} {
  // Initialize content array and a flag for mixed content
  const content: Array<{
    type: "text" | "image" | "graph" | "table";
    value: string;
    graphName?: string;
    graphData?: Array<{ name: string; value: number }>;
    tableData?: any;
    headers?: any;
  }> = [];
  let isMixedContent = false;

  // Split input into lines
  let lines = input?.split("\n") ?? [];

  while (lines.length > 0) {
    const line = lines.shift()?.trim();

    if (!line) continue;

    if (line.startsWith("item_url:")) {
      const imageUrl = line.replace("item_url:", "").trim();
      content.push({ type: "image", value: imageUrl });
      isMixedContent = true;
    } else if (line.includes("start_table_response")) {
      // Extract table data
      const startIndex = 0; // Current line
      const endIndex = lines.findIndex(
        (l) => l?.trim() === "end_table_response"
      );

      if (endIndex !== -1) {
        const tableLines = lines
          .slice(startIndex, endIndex)
          .map((l) => l.trim());
        lines = lines.slice(endIndex + 1); // Skip the processed lines

        const headers = tableLines[0]
          ?.split("|")
          ?.map((header) => header.trim())
          ?.filter((header) => header);

        if (headers) {
          const rows = tableLines.slice(1).map((row) => {
            const cells = row
              ?.split("|")
              ?.map((cell) => cell.trim())
              ?.filter((cell) => cell);

            const rowData: Record<string, string> = {};
            headers.forEach((header, index) => {
              rowData[header] = cells[index] || "";
            });
            return rowData;
          });

          content.push({
            type: "table",
            value: "tabledata",
            tableData: rows,
            headers: headers,
          });
          isMixedContent = true;
        }
      } else {
        console.error(
          "Table data format invalid: Missing 'end_table_response'"
        );
      }
    } else if (line.includes("[")) {
      // Parse graph data within square brackets
      const startIndex = line.indexOf("[");
      const endIndex = line.indexOf("]") + 1;

      if (startIndex !== -1 && endIndex !== -1) {
        const graphDataString = line.substring(startIndex, endIndex).trim();

        try {
          const sanitizedString = graphDataString.replace(/'/g, '"');

          if (
            sanitizedString.startsWith("[") &&
            sanitizedString.endsWith("]")
          ) {
            const graphDataParsed = JSON.parse(sanitizedString);
            content.push({
              type: "graph",
              value: sanitizedString,
              graphData: graphDataParsed,
            });
          } else {
            content.push({ type: "text", value: sanitizedString });
          }
        } catch (error) {
          console.error("Error parsing graph data:", error);
          console.error("Invalid graph data:", graphDataString);
        }
      }
    } else {
      // Add remaining text
      content.push({ type: "text", value: line });
      isMixedContent = true;
    }
  }

  // Return the structured content
  if (isMixedContent) {
    return { type: "mixed", content };
  }

  return { type: "mixed" };
}

export default function ChatRightSection(props: IChatRightSectionProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const openAIModel = useBaseModal();
  const cancelTokenSource = React.useRef<CancelTokenSource | null>(null);
  const history = useHistory();
  const { isEmptyGroup, setReCallGroup, setExpanded, expanded } = props;
  const userProfileDetails = useSelector(userSelectors.selectAll);
  const { grpId, chatId, userid, tab, userId, grpName, chatName } =
    useRouteParams<any>();
  const theme = useTheme();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = React.useState<any>({});

  const getProfileDetails = () => {
    API.get(`/auth/user/${userId}/`, {}, 0)
      .then((res: any) => {
        setProfileDetails(res.data);
      })
      .catch((err: any) => {});
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "auto", // Smooth scrolling
      });
    }
  };

  React.useEffect(() => {
    getProfileDetails();
  }, [userId]);

  React.useEffect(() => {
    if (chatId == 0) {
      setMessages([]);
    }
  }, [chatId]);

  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Request canceled by the user.");
      cancelTokenSource.current = null; // Reset the cancel token
    }
  };

  function hasMinimumTwoWords(input: string): boolean {
    // Trim the string to remove extra spaces at the beginning and end
    const trimmedInput = input?.trim();

    // Split the string by spaces and filter out empty strings (caused by multiple spaces)
    const words = trimmedInput?.split(/\s+/);

    // Check if the length of the resulting array is at least 2
    return words.length >= 2;
  }

  const getName = () => {
    return (
      userProfileDetails &&
      userProfileDetails[0] &&
      (userProfileDetails[0].first_name[0] || "") +
        (userProfileDetails[0].last_name[0] || "")
    );
  };
  const getnameOtherChat = (firstName: any, lastName: any) => {
    return (
      ((firstName && firstName[0]) || "") + ((lastName && lastName[0]) || "")
    );
  };
  const getfullnameOtherChat = (firstName: any, lastName: any) => {
    return (
      ((firstName && firstName) || "") + " " + ((lastName && lastName) || "")
    );
  };
  const getFullName = () => {
    return (
      userProfileDetails &&
      userProfileDetails[0] &&
      (userProfileDetails[0].first_name || "") +
        " " +
        (userProfileDetails[0].last_name || "")
    );
  };

  const getAllMessages = () => {
    API.get(
      "/xcpep/get_LLM_search_history/",
      {
        chat_id: chatId,
        user_id: userId != 0 ? userId : undefined,
      },
      0
    )
      .then((res: any) => {
        const apiMessages = res.data?.map((item: any, index: number) => ({
          id: item.id || index + 1, // Use `id` from the response or fallback to index
          type: item.type || "text", // Use `type` from the response, default to "text"
          content: item?.content, // Format content based on type
          sender: item.sender || "ai", // Use `sender` from the response or default to "ai"
          timestamp: item.timestamp || null, // Use `timestamp` from the response or default to null
          project: item.project || null, // Use `project` from the response or default to null
        }));

        // Set the fetched messages into state
        setMessages(apiMessages);
        scrollToBottom();
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (grpId != 0) getAllMessages();
  }, [chatId]);

  const handleAIResponse = (question: string, answer: any) => {
    if (answer !== 0) {
      if (question.toLowerCase().includes("bar graph")) {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "graph",
            content: (
              <Box sx={{ width: "100%", height: 300 }}>
                <BarChart width={700} height={300} data={barGraphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
              </Box>
            ),
            sender: "ai",
          },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            type: "multi-image",
            content: <ResponseRenderer responseData={answer} sender={"ai"} />,
            sender: "ai",
          },
        ]);
      }
    } else {
      setLoading(true);
      setTimeout(() => {
        setReCallGroup((prev: any) => prev + 1);
      }, 1000);

      cancelTokenSource.current = axios.CancelToken.source();
      API.get(
        "/xcpep/get_llm_response/",
        {
          query: userInput,
          chat: chatId != 0 ? chatId : undefined,
          group: grpId != 0 ? grpId : undefined,
        },
        0,
        cancelTokenSource.current.token
      )
        .then((res: any) => {
          setLoading(false);
          if (question.toLowerCase().includes("bar graph")) {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "graph",
                content: (
                  <Box sx={{ width: "100%", height: 300 }}>
                    <BarChart width={700} height={300} data={barGraphData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="uv" fill="#8884d8" />
                    </BarChart>
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          } else {
            setMessages((prev) => [
              ...prev,
              {
                id: prev.length + 1,
                type: "multi-image",
                content: (
                  // <ResponseRenderer responseData={res.data} sender={"ai"} />
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#13343b",
                        fontWeight: 700,
                        marginBottom: "0.5rem",
                      }}
                    >
                      {res?.data?.project && `Source: ${res?.data?.project}`}
                    </Typography>
                    {/* {res?.data.project && (
                      <Divider sx={{ marginBottom: "0.5rem" }} />
                    )} */}
                    <ResponseRenderer
                      responseData={res?.data?.content}
                      sender={res?.data?.sender}
                    />
                  </Box>
                ),
                sender: "ai",
              },
            ]);
          }
          // scrollToBottom();
        })
        .catch((err: any) => {
          setLoading(false);
          setMessages((prev) => [
            ...prev,
            {
              id: prev.length + 1,
              type: "text",
              content: `Sorry, Unable to fetch the response due to Request canceled by the user or internet connection. Please check your connection and try again.`,
              sender: "ai",
            },
          ]);
        });
    }
  };

  const handleSendMessage = () => {
    if (!userInput.trim()) return;

    const newMessage: ChatMessage = {
      id: messages?.length + 1,
      type: "text",
      content: userInput.trim(),
      sender: "user",
    };
    setMessages((prev) => [...prev, newMessage]);
    setUserInput("");

    setTimeout(() => handleAIResponse(newMessage.content, 0), 500);
    setTimeout(() => {
      scrollToBottom();
    }, 800);
    scrollToBottom();
  };

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0.1rem 0.5rem",
          gap: "0.5rem",
          width: "100%",
          zIndex: 5,
          margin: "0 auto",
          background: "linear-gradient(90deg, #00c6ff, #2575fc, #6a11cb)",
          // borderBottom: `0.5px solid ${theme.palette.divider}`,
          height: "3rem",
        }}
      >
        {/* Dont remove empty boxes */}
        <Box sx={{ width: { lg: "10rem", xl: "15rem" } }}></Box>
        <Box
          sx={{
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { lg: "75%", xl: "72%" },
            color: "white",
            // background:
            //   "linear-gradient(90deg, rgba(253,247,255,1) 0%, rgba(255,255,255,1) 100%)",
          }}
        >
          {grpName != 0 && chatName != 0 && `${grpName} / ${chatName}`}
        </Box>
        <Box
          sx={{
            width: { lg: "10rem", xl: "15rem" },
            display: "flex",
            justifyContent: "flex-end",
            gap: "1.5rem",
          }}
        >
          {AUTH.isSuperUser && (
            <IconButton
              sx={{ padding: "3px", color: "white" }}
              onClick={() => openAIModel.open()}
            >
              <TuneIcon />
            </IconButton>
          )}
          <Button
            variant="text"
            sx={{ padding: "3px", color: "white", fontSize: "1.5rem",textTransform: 'none'}}
            onClick={() => history.push("/")}
          >
            xcPEP
          </Button>
        </Box>
      </Box>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          height: { lg: "92vh", xl: "94vh" },
          overflow: "auto",
          // backgroundColor: "#e6e6fa",
          // backgroundColor: "white",
          background:
            "linear-gradient(90deg, rgba(253,247,255,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        {/* Messages */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            width: "75%",
            margin: "0 auto",
            marginBottom: "2rem",
            // overflowY: "auto",
            // flexGrow: 1,
          }}
        >
          {messages &&
            Array.isArray(messages) &&
            messages?.map((msg) => (
              <Box
                key={msg.id}
                sx={{
                  display: "flex",
                  // flexDirection: msg.sender === "user" ? "row-reverse" : "row",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    // backgroundColor:
                    // msg.sender === "user" ? "#515989" : "#f9f9f9",
                    color: theme.palette.text.primary,
                    padding: "0.2rem 0.4rem",
                    borderRadius: "1rem",
                    maxWidth: "70%",
                    fontSize: "1rem",
                    // boxShadow: theme.shadows[1],
                    textAlign: "left",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#13343b",
                      margin: "0.5rem 0",
                      fontWeight: 700,
                      marginBottom: "0.5rem",
                    }}
                  >
                    {msg.project && `Source: ${msg.project}`}
                  </Typography>
                  {/* {msg.project && <Divider sx={{ marginBottom: "0.5rem" }} />} */}
                  {msg.type === "text" && (
                    <ResponseRenderer
                      responseData={msg.content}
                      sender={msg.sender}
                    />
                  )}
                  {msg.type === "image" && msg.content}
                  {msg.type === "multi-image" && msg.content}
                  {msg.type === "graph" && msg.content}
                  {msg.type === "pareto" && msg.content}
                  {msg.type === "table" && msg.content}
                </Box>
              </Box>
            ))}

          {/* Skeleton loader */}
          {loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              {/* <AIChatIcon
              style={{ color: "red", width: "3rem", height: "3rem" }}
            /> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  padding: "1rem",
                  borderRadius: "1rem",
                  width: "60%",
                  boxShadow: theme.shadows[1],
                }}
              >
                <Skeleton
                  variant="text"
                  width="80%"
                  sx={{ fontSize: "1rem", marginBottom: "0.5rem" }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  sx={{ fontSize: "1rem", marginBottom: "0.5rem" }}
                />
                <Skeleton
                  variant="text"
                  width="90%"
                  sx={{ fontSize: "1rem" }}
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* Input */}
        <Box
          sx={{
            width: "83%",
            position: "absolute",
            padding: "0 0.5rem 2px",
            bottom: "0px",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Box sx={{ width: { lg: "10rem", xl: "15rem" } }}></Box>
          <Box
            sx={{
              width: "55%",
              backgroundColor: "white",
              borderRadius: "3rem",
              border: tab === "mychat" ? "3px solid #e2e2e1" : "none",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                // borderTop: `1px solid ${theme.palette.divider}`,
                width: "99%",
                backgroundColor: "white",
              }}
            >
              {tab !== "otherchat" && (
                <StyledInputBase
                  sx={{ backgroundColor: "white", borderRadius: "5px" }}
                  placeholder="Type your question here..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      hasMinimumTwoWords(userInput) &&
                      !loading
                    )
                      handleSendMessage();
                  }}
                />
              )}
              {!loading
                ? tab !== "otherchat" && (
                    <SendOutlinedIcon
                      sx={{
                        color: "#515989",
                        cursor: "pointer",
                        opacity: hasMinimumTwoWords(userInput) ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (hasMinimumTwoWords(userInput)) {
                          handleSendMessage();
                        }
                      }}
                    />
                  )
                : tab !== "otherchat" && (
                    <StopCircleIcon
                      sx={{ cursor: "pointer", color: "#515989" }}
                      onClick={handleCancel}
                    />
                  )}
            </Box>
          </Box>
          <Box sx={{ width: { lg: "10rem", xl: "15rem" } }}></Box>
        </Box>
      </Box>
      {openAIModel.isOpen && (
        <AIModelControlModal
          isOpen={openAIModel.isOpen}
          onCloseModal={openAIModel.close}
        />
      )}
    </Box>
  );
}
