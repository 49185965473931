import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import EditIcon from "@mui/icons-material/Edit";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useState } from "react";
import swal from "sweetalert";

import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";

import MachineFlagModal from "./MachineFlagModal";
import MHRmoveModal from "./MHRmoveModal";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";
import FetchMachineListModal from "./FetchMachineListModal";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import MachineGraph from "./MachineGraph";
import MachineHourRateActionComponents from "../ActionComponents/MachineHourRateActionComponents";
import MenuIcon from '@mui/icons-material/Menu';
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import FormulalistModal from "../../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/FormulalistModal";
import EditMachineModal from "./EditMachineModal";


export interface IRawMaterialRevisionTableProps {
  setRecyclebinCounter: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function SearchMHRrevisionPage(props: IRawMaterialRevisionTableProps) {
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();
    const formulamodal:any = useBaseParamsModal();
  const { setRecyclebinCounter } = props;
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);

  const [headerData, setHeaderData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);
  const infomodal = useBaseModal();
  const [HourValue, setHourValue] = useState<any>();
  const [dayValues, setdayValues] = useState<any>();
  const [WokringValues, setWokringValues] = useState<any>();
  const [PowerRate, setPowerRate] = useState<any>();
  const [PowerCut, setPowerCut] = useState<any>();
  const [DieselGen, setDieselGen] = useState<any>();
  const [BankLoan, setBankLoan] = useState<any>();
  const [statusColor, setStatusColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const history = useHistory<any>();
  const machinemodal = useBaseModal();
  const [Counter, setCounter] = useState<any>(0);
  const [tableLoader, settableLoder] = useState<any>(false);
  const [RowItem, setRowItem] = useState<any>();
  const flagmodal = useBaseModal();
  const [rowItems, setRowItems] = useState<any>({});
  const MachineMove = useBaseModal();
  const [SingleData, setSingleData] = useState<any>([]);
  const { height, width } = useWindowDimensions();
  const graphmodal = useBaseModal();
  const [useraction, setuseraction] = useState<any>(null)
 const [RowsItem, setRowsItem] = useState<any>();
  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
    const Editmachinemodal = useBaseModal();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const handleShareWith: any = (item: any) => {
    setRowItems(item);
    MachineMove.open();
    // toolMaterialShare.open(idGet,shareItemCodeGet,listCommodityGet,shareItemNameGet)
  };
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  React.useEffect(() => {
    settableLoder(true);
    API.get("/api/db/costDB_search/", {
      machine_revision: true,

      revision: revisionId,

      search_key: search,
    })
      .then((res: any) => {
        settableLoder(false);
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false);
        console.log("Sever EEror");
      });
  }, [search, Counter]);

  const redirectionHanlder = (item: any) => {
    history.push(
      `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machineDB/${categoryName}/true/0/0`
    );
  };
  const InfoHandler: any = (item: any) => {
    setRowItems(item);
    infomodal.open();
  };

  const EditHandler = (row: any) => {
    Editmachinemodal.open();
    setRowsItem(row);
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);
  const DeleteHandler = (item: any) => {
    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/machine_cost/${item?.id}/`)
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            setRecyclebinCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => { });
      }
    });
  };
  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      renderCell: (cellvalues: any) => {
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {/*Direct Menus here  */}
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellvalues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: 'primary.main', }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              elevation={1}
            >
              <MachineHourRateActionComponents
                cellValues={cellvalues.row}
                rowId={rowId}
                InfoHandler={InfoHandler}
                DeleteHandler={DeleteHandler}
                GraphHandler={GraphHandler}
                redirectionHanlder={redirectionHanlder}
                handleShareWith={handleShareWith}
                useraction={useraction}
                setAnchorEl={setAnchorEl}
              />
            </Popover>

            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Edit"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                  opacity: cellvalues?.row?.lock == true ? 0.3 : 1
                }}
                onClick={() => {
                  if (cellvalues?.row?.lock != true) { EditHandler(cellvalues?.row) }
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
            {/* <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
              <Box>
                {cellvalues?.row.status ? (
                  <IconButton
                    title="Info"
                    sx={{ color: "primary.main", padding: "0", cursor: "pointer" }}
                    onClick={() => InfoHandler(cellvalues?.row)}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  useraction && useraction?.includes("D") && (
                    <IconButton
                      title="Delete"
                      sx={{
                        color: "primary.main",
                        padding: "0",
                        cursor: "pointer",
                      }}
                      onClick={() => DeleteHandler(cellvalues?.row)}
                    >
                      {" "}
                      <DeleteIcon />
                    </IconButton>
                  )
                )}
              </Box>
              <IconButton
                title="Graph"
                sx={{ color: "primary.main", padding: "0", cursor: "pointer" }}
                onClick={() => GraphHandler(cellvalues?.row)}
              >
                <EqualizerIcon />
              </IconButton>

              <IconButton
                title="Redirect"
                sx={{ color: "primary.main", padding: "0", cursor: "pointer" }}
                onClick={() => redirectionHanlder(cellvalues?.row)}
              >
                <LaunchIcon />
              </IconButton>

              {cellvalues.row.flag !== null ? (
                <IconButton
                  title="Machine Cost"
                  sx={{ color: "primary.main", padding: "0", cursor: "pointer" }}
                  onClick={() =>
                    cellvalues.row.flag !== null &&
                    window.open(cellvalues?.row?.flag, "_blank")
                  }
                >
                  {" "}
                  <FlagIcon sx={{ color: "green" }} />
                </IconButton>
              ) : (
                <IconButton
                  title="Machine Cost"
                  sx={{ color: "primary.main", padding: "0" }}
                >
                  {" "}
                  <FlagOutlinedIcon />
                </IconButton>
              )}

              {cellvalues.row.specification !== null ? (
                <IconButton
                  title="Machine Specification"
                  sx={{ color: "primary.main", padding: "0", cursor: "pointer" }}
                  onClick={() =>
                    cellvalues.row.specification !== null &&
                    window.open(cellvalues?.row?.specification, "_blank")
                  }
                >
                  {" "}
                  <FlagIcon sx={{ color: "green" }} />
                </IconButton>
              ) : (
                <IconButton
                  title="Machine Specification"
                  sx={{ color: "primary.main", padding: "0" }}
                >
                  {" "}
                  <FlagOutlinedIcon />
                </IconButton>
              )}

              {useraction && useraction?.includes("U") && (
                <IconButton
                  title="Move"
                  sx={{ color: "#007fff", padding: "0rem", cursor: "pointer" }}
                  onClick={() => handleShareWith(cellvalues?.row)}
                >
                  {" "}
                  <img
                    src={move}
                    style={{ height: "1.3rem", cursor: "pointer" }}
                  />{" "}
                </IconButton>
              )}
            </Box> */}
          </>
        );
      },
    },
    { field: "Ids", headerName: "Sl.No", width: 60 },
    {
      field: "machine_code",
      headerName: "Machine Code",
      width: 150,
    },
    {
      field: "name",
      headerName: "Machine Name",
      width: 150,
    },

    {
      field: "brand_name",
      headerName: "Brand Name",
      width: 130,
    },
    {
      field: "commodity_name",
      headerName: "Category Name",
      width: 150,
    },
    {
      field: "operation",
      headerName: "Operation",
      width: 150,
    },
    {
      field: "cost_per_hour",
      description: "Machine Hour Rate",
      headerName: "MHR ",
      headerAlign: "right",
      width: width > 1400 ? 160 : 140,
      align: "right",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography  sx={{ fontSize: '1rem', textDecoration:"underline",cursor:"pointer" }} onClick={()=> {
                          formulamodal.open({
                            revision_id: revisionId,
                            machine_id:cellvalue?.row?.rowId,
                            mhr_details:true
                          })

                          
                          
                        }}>
              {cellvalue?.row?.cost_per_hour?.toFixed(2)}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Hour',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "rental_cost",
      headerName: "Rental Cost",
      width: 120,
    },
    {
      field: "capital_cost",
      headerName: "Capital Cost",
      width: width > 1400 ? 130 : 110,
      align: "right",
    },
    {
      field: "installation_cost",
      description: "Installation Cost",
      headerName: "Inst Cost",
      width: width > 1400 ? 100 : 100,
      align: "right",
    },

    {
      field: "life",
      headerName: "Life (Yrs)",
      width: width > 1400 ? 80 : 70,
    },
    {
      field: "maintanance",
      description: "Maintanance Cost(%/Yrs/Shift)",
      headerName: "Maint.(%/Yrs/Shift)",
      width: width > 1400 ? 140 : 120,
    },

    {
      field: "consumption",
      description: "Consumption Cost(%)",
      headerName: "Cons. Cost (%)",
      width: width > 1400 ? 110 : 100,
      align: "right",
    },
    {
      field: "power_consumption",
      description: "Average Power Consumption (kWh)",
      headerName: "Avg Pwr Cons.(kWh)",
      width: width > 1400 ? 140 : 120,
      align: "right",
    },
    {
      field: "effectiveness",
      description: "Effectiveness (%)",
      headerName: "Effect (%)",
      width: width > 1400 ? 90 : 80,
    },
    {
      field: "peak_power_factor",
      description: "Peak Power Factor",
      headerName: "Peak Pwr Fact",
      width: width > 1400 ? 120 : 100,
    },

    {
      field: "reference",
      headerName: "Reference",
      width: width > 1400 ? 100 : 80,
      renderCell: (cellvalue: any) => {
        var link = ["https", "http", "www"];
        var likData = String(cellvalue?.row?.reference);
        if (cellvalue?.row?.reference !== null) {
          if (cellvalue?.row?.reference.includes("/")) {
            return (
              <>
                {isUrl(cellvalue?.row?.reference) ? (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      borderBottom: "1px solid",
                    }}
                    onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    Link
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      textDecoration: "1px solid",
                      cursor: "pointer",
                      // borderBottom: "1px solid",
                    }}
                  // onClick={() => window.open(cellvalue?.row?.reference)}
                  >
                    {cellvalue?.row?.reference}
                  </Typography>
                )}
              </>
            );
          }
        } else {
          return <>{cellvalue?.row?.reference}</>;
        }
      },
    },
    {
      field: "fixed_cost",
      description: "Fixed Cost(Currency/Year)",
      headerName: "Fxd Cst.(Curr/Yr)",
      headerAlign: "right",
      width: width > 1400 ? 150 : 140,
      align: "right",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellvalues: any) => {
        return <>{cellvalues?.row?.fixed_cost}</>;
      },
    },
    {
      field: "maintanance_cost",
      description: "Maintanance Cost(Currency/Year)",
      headerName: "Maint Cost(Curr/Yr)",
      headerAlign: "right",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 150 : 140,
      align: "right",
      renderCell: (cellvalues: any) => {
        return <>{cellvalues?.row?.maintanance_cost}</>;
      },
    },
    {
      field: "consumption_cost",
      description: "Consumption Cost(Currency/Year)",
      headerName: "Cons.Cst(Curr/Yr)",
      headerAlign: "right",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 150 : 140,
      align: "right",
      renderCell: (cellvalues: any) => {
        return <>{cellvalues?.row?.consumption_cost}</>;
      },
    },
    {
      field: "variable_cost",
      description: "Variable Cost(Currency/Year)",
      headerName: "Var.Cst(Curr/Yr)",
      headerAlign: "right",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 150 : 140,
      align: "right",
      renderCell: (cellvalues: any) => {
        return <>{cellvalues?.row?.variable_cost}</>;
      },
    },
  ];

  const Rows: any = () => {
    return (
      TableData &&
      TableData?.map((item: any, index: any) => {
        return {
          Ids: index + 1,
          id: item?.id,
          rowId:item?.machine?.id,
          machine_code: item?.machine?.machine_code,
          name: item?.machine?.name,
          brand_name: item?.machine?.brand_name,
          operation: item?.machine?.operation,
          description: item?.machine?.description,
          commodity_name: item?.machine?.commodity_name,
          capital_cost: item?.capital_cost,
          installation_cost: item?.installation_cost,
          life: item?.machine?.life,
          cell_id: item?.machine?.id,
          status: item?.mapped_status,
          rental_cost: item?.rental_cost,
          fixed_cost: item?.fixed_cost,
          maintanance: item?.machine?.maintanance,
          maintanance_cost: item?.maintanance_cost,
          consumption: item?.machine?.consumption,
          consumption_cost: item?.consumption_cost,
          power_consumption: item?.machine?.power_consumption,
          cost_per_hour: item?.cost_per_hour,
          variable_cost: item?.variable_cost,
          reference: item?.machine?.reference,
          peak_power_factor: item?.machine?.peak_power_factor,
          effectiveness: item?.machine?.effectiveness,
          flag: item?.machine?.flag,
          currency_id: item?.currency_id,
          currency_code: item?.currency_code,
          unit: item?.unit,
          duty: item?.duty,
        };
      })
    );
  };

  const SaveHandler = () => {
    setLoader(true);
    API.put(`/api/db/machine_detail/${headerData[0]?.id}/`, {
      hours_per_shift: Number(HourValue),
      shifts_per_day: Number(dayValues),
      days_per_year: Number(WokringValues),
      power_cost: Number(PowerRate),
      powercut: Number(PowerCut),
      generator_cost: Number(DieselGen),
      interest_rate: Number(BankLoan),
    })
      .then((res: any) => {
        setStatusColor(false);

        setHourValue(res && res?.hours_per_shift);
        setdayValues(res && res?.shifts_per_day);
        setWokringValues(res && res?.days_per_year);
        setPowerRate(res && res?.power_cost);
        setPowerCut(res && res?.powercut);
        setDieselGen(res && res?.generator_cost);
        setBankLoan(res && res?.interest_rate);
        setLoader(false);
      })
      .catch((err: any) => {
        setStatusColor(false);
        setLoader(false);
      });
  };

  const AddmachineHanlder = (e: any) => {
    e?.stopPropagation();
    machinemodal.open();
  };
  const handlechange = (e: any, type: any) => {
    setStatusColor(true);

    if (type == "hours") {
      setHourValue(e?.target?.value);
    }
    if (type == "days") {
      setdayValues(e?.target?.value);
    }
    if (type == "Working") {
      setWokringValues(e?.target?.value);
    }
    if (type == "Rate") {
      setPowerRate(e?.target?.value);
    }
    if (type == "Cut") {
      setPowerCut(e?.target?.value);
    }
    if (type == "Diesel") {
      setDieselGen(e?.target?.value);
    }
    if (type == "loan") {
      setBankLoan(e?.target?.value);
    }
  };
  const GraphHandler: any = (item: any) => {
    setRowItems(item);
    graphmodal.open();
  };
  React.useEffect(() => {
    API.get("/api/db/machine_detail/", {
      revision: revisionId,
    }).then((res: any) => {
      setHeaderData(res?.data);
    });
  }, [revisionId]);

  React.useEffect(() => {
    setCommodityLoader(true);
    API.get("/api/db/revision_database/", {
      revision_id: revisionId,
      category_type: "machinedetail",
    })
      .then((res: any) => {
        setCommodityLoader(false);
        setCategoryList(res?.data);
        setSingleData(res?.data[0]?.id);
      })
      .catch((err: any) => {
        setCommodityLoader(false);
      });
  }, [revisionId, Counter]);

  const swalalterHandler = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,
      });
    } else {
      Swal.fire({
        html: `<div>
  
    
  
      <p style="color:red;">Sever Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);

  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }
    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }
    }
  }, []);

  
  return (
    <Box sx={{ width: "100%" }}>

{formulamodal.isOpen && (
    <FormulalistModal revisionId={formulamodal.propsId?.revision_id} isOpen={formulamodal.isOpen} onClose={()=> formulamodal.close()} machine_id={formulamodal.propsId?.machine_id} mhr_details={formulamodal.propsId?.mhr_details} labour_id={formulamodal.propsId?.labour_id} lhr_details={formulamodal.propsId?.lhr_details} />
  )}
    {Editmachinemodal.isOpen && (
        <EditMachineModal
          type={"edit"}
          swalalterHandlerAdd={swalalterHandlerAdd}
          actions={RowsItem}
          setCounter={setCounter}
          onCloseModal={Editmachinemodal.close}
          isOpen={Editmachinemodal.isOpen}
        />
      )}

      {machinemodal.isOpen && (
        <FetchMachineListModal
          expanded={SingleData}
          onCloseModal={machinemodal.close}
          isOpen={machinemodal.isOpen}
          setCounter={setCounter}
        />
      )}
      {MachineMove.isOpen && (
        <MHRmoveModal
          isOpen={MachineMove.open}
          onCloseModal={MachineMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandler}
        />
      )}

      {graphmodal.isOpen && (
        <MachineGraph
          isOpen={graphmodal.open}
          onCloseModal={graphmodal.close}
          rowItems={rowItems}
        />
      )}
      {flagmodal?.isOpen && (
        <MachineFlagModal
          onCloseModal={flagmodal.close}
          isOpen={flagmodal.isOpen}
          RowItem={RowItem}
        />
      )}
      {
        <>
          <Box
            sx={{
              height: { lg: "88vh", xl: "90vh" },
              width: "100%",
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
            }}
          >
            {Rows() && Rows() && (
              <DataGrid
                loading={tableLoader}
                headerHeight={42}
                rowHeight={36}
                rows={Rows()}
                columns={columns}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "0.4rem",
                    height: "0.4rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
              />
            )}
          </Box>
        </>
      }
    </Box>
  );
}
