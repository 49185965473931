import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import { debounce } from "lodash";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import success from "../../Assets/images/success.png";
import Swal from "sweetalert2";

export interface ProjectDirectoryProductModalProps {
  isOpen: any;
  onCloseModal: any;
  projectIds: any;
  setproductCounter: any;

  VehicleIdstype: any;
  setProductListData: any;
  setProjectexpanded: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "38%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const ProjectDirectoryProductModal = (
  props: ProjectDirectoryProductModalProps
) => {
  const {
    isOpen,
    onCloseModal,
    setproductCounter,
    projectIds,

    VehicleIdstype,
    setProductListData,
    setProjectexpanded,
  } = props;

  const [Name, setName] = useState<any>(null);

  const [namemsg, setnamemsg] = useState<any>(false);
  const [ProductIdmsg, setProductIdmsg] = useState<any>(false);
  const [MfgDatamsg, setMfgDatamsg] = useState<any>(false);
  const [WeightErrormsg, setWeightErrormsg] = useState<any>(false);
  const [ProductId, setProductId] = useState<any>(null);
  const [MfgData, setMfgData] = useState<any>(null);
  const [WeightError, setWeightError] = useState<any>(null);
  const [Oemmsg, setOemmsg] = useState<any>(false);
  const [value, setValue] = React.useState<any>([]);
  const [inputValue, setInputValue] = React.useState(null);
  const [Successmsg, setSuccessmsg] = useState<any>(false);
  const [OemSearchData, setOemSearchData] = useState<any>([]);
  const [OemIds, setOemIds] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false);
  const [currencyCode, setCurrencyCode] = useState<any>("INR");
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryList, setCountryList] = React.useState<any[]>([]);

  const getCountryData = () => {
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  const handelChange = (e: any, type: any) => {
    if (type == "name") {
      setnamemsg(false);
      setName(e.target.value);
    } else if (type == "WeightError") {
      setWeightError(e?.target?.value);
      setWeightErrormsg(false);
    } else if (type == "MfgData") {
      // var date = new Date(e.target?.value);
      // var datedata = moment(date).format("YYYY-MM-DD");

      setMfgData(e.target?.value);
      setMfgDatamsg(false);
    } else if (type == "ProductId") {
      setProductId(e.target.value);
      setProductIdmsg(false);
    }
  };

  const ClosedHandler = () => {
    onCloseModal();
    setSuccessmsg(false);
    //   setDescription(null);
    // setName(null);
  };

  // console.log(ProductId, "projectIds", Name, parseFloat(WeightError), MfgData, OemIds)
  const savehandler = () => {
    // console.log( ProductId ,Name,WeightError,MfgData,OemIds,   (ProductId !== null && ProductId.length > 0 &&
    //   Name !== null && Name.length > 0  && MfgData.length > 0 &&
    //   WeightError !== null &&
    //   MfgData !== null &&
    //   OemIds?.length > 0) ,"OemIdsOemIds")

    if (
      ProductId !== null &&
      ProductId.length > 0 &&
      Name !== null &&
      Name.length > 0 &&
      WeightError !== null &&
      MfgData !== null &&
      OemIds?.length > 0&&
      currencyCode?.length>0
    ) {
      if (WeightError > 0 && WeightError <= 1) {
        setLoader(true);

        var data = Number(WeightError) / 100;
        API.post(
          "xcpep/top_vault/",
          {
            product_id: ProductId,
            name: Name,
            project: projectIds,
            type: VehicleIdstype && VehicleIdstype,
            error_acceptance: +data,
            manufacturing_year: MfgData,
            oem: OemIds?.toString(),
            currency: currencyCode,
          },
          {},
          0
        )
          .then((res: any) => {
            setproductCounter((prev: any) => prev + 1);
            setSuccessmsg(true);
            setLoader(false);

            API.get("xcpep/product_list/", { project: projectIds }).then(
              (res: any) => {
                setProductListData(res?.data);
                setProjectexpanded(res?.data[0]?.id);

                // setLoader(false);
              }
            );
          })
          .catch((err: any) => {
            const { data } = err.response;
            if (err && err?.response && data) {
              Swal.fire({
                icon: "error",
                customClass: {
                  container: "swal2Container",
                },
                html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
              });
            }
            setLoader(false);
          });
      } else {
        setWeightErrormsg("true");
      }
    }

    if (ProductId == null || ProductId.length == 0) {
      setProductIdmsg(true);
    }
    if (Name == null || Name.length == 0) {
      setnamemsg(true);
    }
    if (WeightError == null) {
      setWeightErrormsg(true);
    }
    if (MfgData == null || MfgData.length == 0) {
      setMfgDatamsg(true);
    }
    if (OemIds?.length == 0) {
      setOemmsg(true);
    }
  };

  // const savehandler = () => {
  //   if (FormType == "Add") {
  //     if (Name !== null && Description !== null) {
  //       API.post("/xcpep/project/", {
  //         name: Name,
  //         type: projectIds,
  //         description: Description,
  //       }).then((res: any) => {
  //         setSuccessmsg(true);

  //         setDescription(null);
  //         setName(null);
  //         (setproductCounter(prev: any) => prev + 1);
  //       });
  //     }
  //   }

  //   if (FormType == "Edit") {
  //     if (Name !== null && Description !== null) {
  //       API.put(`/xcpep/project/${projectIds}/`, {
  //         name: Name,

  //         description: Description,
  //       }).then((res: any) => {
  //         setSuccessmsg(true);

  //         setDescription(null);
  //         setName(null);
  //         setproductCounter((prev: any) => prev + 1);
  //       });
  //     }
  //   }
  //   if (Name == null) {
  //     setnamemsg(true);
  //   }

  //   if (Description == null) {
  //     setdesmsg(true);
  //   }
  // };

  const SearchOption = () => {
    return (
      OemSearchData &&
      OemSearchData?.map((item: any, index: any) => {
        return {
          title: item.name,
          id: item?.id,
        };
      })
    );
  };

  const handleChange = (newValue: any) => {
    setValue(newValue);
    if (newValue?.id !== undefined) {
      setOemIds([newValue?.id]);
    }

    setOemmsg(false);
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/xcpep/oem/search/", { search: criteria }).then(
        (res: any) => {
          setOemSearchData(res.data);

          // setSearchOemData(res.data);
          // SetselectValue([])
        }
      );
      //  setCharacters();
    }, 200)
  ).current;
  const PartNameHandler = (item: any) => {
    setInputValue(item);
    if (item?.length > 0) {
      ExactDebounce(item);
    }
  };

  //  ExactDebounce(item)

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={ClosedHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!Successmsg ? (
            <>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  Add Product Variant
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={ClosedHandler}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
            </>
          ) : (
            ""
          )}
          {Successmsg ? (
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <img src={success} style={{ width: "7rem" }} />
              </Box>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  // width: "100%",
                  textAlign: "center",
                  margin: "1rem",
                  color: "green",
                }}
              >
                Product Variant Added Successfully!
                {/* Feedback for View Page */}
              </Typography>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  size="small"
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  variant="contained"
                  onClick={() => ClosedHandler()}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ padding: "0 1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "2rem",
                }}
              >
                <Box>
                  <TextField
                    value={Name || ""}
                    error={namemsg}
                    focused
                    onChange={(e: any) => handelChange(e, "name")}
                    //   sx={{color:"red !important"}}

                    sx={{
                      width: "100%",
                      input: {
                        //    color: 'red',
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                        },
                      },
                    }}
                    id="standard-size-normal"
                    label="Variant Name *"
                    variant="standard"
                    // helperText={namemsg ? "Incorrect entry." : ""}

                    //   placeholder="Name"
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: " 1fr 1fr",
                    columnGap: "1rem",
                  }}
                >
                  <TextField
                    value={ProductId || ""}
                    error={ProductIdmsg}
                    focused
                    onChange={(e: any) => handelChange(e, "ProductId")}
                    //   sx={{color:"red !important"}}

                    sx={{
                      input: {
                        //    color: 'red',
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                        },
                      },
                    }}
                    type="number"
                    id="standard-size-normal"
                    label="Variant Product Id *"
                    variant="standard"
                    // helperText={ProductIdmsg ? "Incorrect entry." : ""}
                  />

                  {SearchOption() && SearchOption() && (
                    <Autocomplete
                      value={value && value}
                      id="controllable-states-demo"
                      onChange={(event: any, newValue: string | null) => {
                        handleChange(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      }
                      // @ts-ignore
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        PartNameHandler(newInputValue);
                      }}
                      // @ts-ignore
                      // getOptionLabel={(option: any | string) =>

                      getOptionLabel={(option) => {
                        if (option === false || option?.title == undefined) {
                          return " ";
                        }
                        return option.title?.toString();
                      }}
                      // option?.title === undefined ? "" : option?.title
                      // }
                      // @ts-ignore
                      options={SearchOption() && SearchOption()}
                      sx={{
                        width: "100%",
                        padding: "0 0.5rem",
                        ".MuiFormLabel-root-MuiInputLabel-root.Mui-focused.Mui-error":
                          {
                            color: "#d32f2f",
                          },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          focused
                          sx={{
                            fontSize: "1rem",
                            "& .MuiInput-underline:before": {
                              borderBottomColor: Oemmsg
                                ? "red"
                                : "primary.main",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: Oemmsg
                                ? "red"
                                : "primary.main",
                            },
                            "& input::placeholder": {
                              fontSize: "1rem",
                            },
                            ".Mui-error": {
                              color: "#d32f2f",
                            },
                          }}
                          placeholder="OEM"
                          label="OEM *"
                        />
                      )}
                    />
                  )}
                  {/* <TextField
                    value={Name || ""}
                    error={ProductIdmsg}
                    focused
                    onChange={(e: any) => handelChange(e, "name")}
                    //   sx={{color:"red !important"}}

                    sx={{
                      input: {
                        //    color: 'red',
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                        },
                      },
                    }}
                    id="standard-size-normal"
                    label="OEM *"
                    variant="standard"
                    helperText={namemsg ? "Incorrect entry." : ""}

                    //   placeholder="Name"
                  /> */}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: " 1fr 1fr",
                    columnGap: "1rem",
                  }}
                >
                  <TextField
                    value={MfgData || ""}
                    error={MfgDatamsg}
                    focused
                    onChange={(e: any) => handelChange(e, "MfgData")}
                    //   sx={{color:"red !important"}}
                    type="number"
                    sx={{
                      input: {
                        //    color: 'red',
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                        },
                      },
                    }}
                    // type="date"

                    // inputProps={{
                    //   min: moment(new Date()).format("YYYY-MM-DD")

                    // }}
                    id="standard-size-normal"
                    label="Manufacturing Year*"
                    variant="standard"
                    // helperText={MfgDatamsg ? "Incorrect entry." : ""}

                    //   placeholder="Name"
                  />
                  <TextField
                    value={WeightError || ""}
                    error={WeightErrormsg}
                    focused
                    onChange={(e: any) => handelChange(e, "WeightError")}
                    //   sx={{color:"red !important"}}

                    sx={{
                      input: {
                        //    color: 'red',
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                        },
                      },
                    }}
                    type="number"
                    id="standard-size-normal"
                    label="Weight Error (%)*"
                    variant="standard"
                    helperText={
                      WeightError == "true"
                        ? "Allowed Wt. Error should be b/w 0 to 1."
                        : ""
                    }
                    // helperText={WeightErrormsg ? "Incorrect entry." : ""}

                    //   placeholder="Name"
                  />
                </Box>
                <Box>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={currencyCode}
                      onChange={(event: any) => {
                        setCurrencyCode(event.target.value);
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={!currencyCode && Boolean(!currencyCode)}
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "5rem",
                }}
              >
                <LoadingButton
                  loading={Loader}
                  variant="contained"
                  size="small"
                  onClick={() => savehandler()}
                >
                  OK
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectDirectoryProductModal;
