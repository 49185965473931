import { Box, useTheme } from "@mui/material";
import * as React from "react";
import ChatLeftSection from "./ChatLeftSection";
import ChatRightSection from "./ChatRightSection";
import ChatCollapseLeftSection from "./ChatCollapseLeftSection";

export interface IChatLandingpageProps {}

export default function ChatLandingpage(props: IChatLandingpageProps) {
  const theme = useTheme();
  const [isEmptyGroup, setIsEmptyGroup] = React.useState<any>(false);
  const [reCallgrooup, setReCallGroup] = React.useState<any>(0);
  const [expanded, setExpanded] = React.useState<any>(true);
  return (
    <Box
      sx={{
        height: "100vh",
        // @ts-ignore
        background: "#f3f3ee",
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns:
              expanded == true
                ? { lg: "25rem 1fr", xl: "22rem 1fr" }
                : { lg: "4rem 1fr", xl: "4rem 1fr" },
            overflow: "hidden",
            width: "100%",
            height: "100%",
            padding: "0rem",
            transition: "all 0.4s ease",
          }}
        >
          {/* Left Panel */}
          <Box
            sx={{
              overflow: "hidden",
              // @ts-ignore
              background: theme.palette.background.paper,
              // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
            }}
          >
            {expanded ? (
              <ChatLeftSection
                isEmptyGroup={isEmptyGroup}
                setIsEmptyGroup={setIsEmptyGroup}
                reCallgrooup={reCallgrooup}
                setExpanded={setExpanded}
                expanded={expanded}
              />
            ) : (
              <ChatCollapseLeftSection
                setExpanded={setExpanded}
                expanded={expanded}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "100%",
              // overflow: "auto",
              // flexGrow: 1,
              // padding: "0.5rem",
              background:
                "linear-gradient(90deg, rgba(253,247,255,1) 0%, rgba(255,255,255,1) 100%)",
            }}
          >
            <ChatRightSection
              isEmptyGroup={isEmptyGroup}
              setReCallGroup={setReCallGroup}
              setExpanded={setExpanded}
              expanded={expanded}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
