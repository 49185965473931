import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
    Box,
    Button,
    ButtonProps,
    Divider,
    Modal,
    Typography,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
    FormControl,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import ChecmicalCompositionRowDataUpdate from "./ChecmicalCompositionRowDataUpdate";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { CDN_URL } from "../../../utlis/CDN_URL";
import BOMChemicalCompositionPDFViwer from "./BOMChemicalCompositionPDFViwer";
import VisibilityIcon from '@mui/icons-material/Visibility';

export interface ChemicalModalProps {
    onCloseModal: () => void;
    isOpen: boolean;
}

const csvOptions: GridCsvExportOptions = {
    fileName: `Flag Table`,
    delimiter: ",",
    utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
);

const CustomToolbar = () => (
    <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
        <span style={{ position: "absolute", right: "0rem" }}>
            <GridToolbarColumnsButton />
            <CustomExportButton />
        </span>
    </GridToolbarContainer>
);

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "96vw", // Increased width for better visibility
    maxHeight: "96vh", // Increased height to fit more data
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
    overflow: "auto",
};

export default function ChemicalModal({ onCloseModal, isOpen }: ChemicalModalProps) {
    const { topVault } = useRouteParams<any>();
    const [selectedOption, setSelectedOption] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [fileError, setFileError] = React.useState<string | null>(null);
    const [columns, setColumns] = React.useState<GridColDef[]>([]);
    const [rows, setRows] = React.useState<any[]>([]);
    const [updatedRows, setUpdatedRows] = React.useState<any[]>([]); // To track modified rows
    const [updatedLoader, setUpdatedLoader] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState<any | null>(null);
    const [selectedRowId, setSelectedRowId] = React.useState<any>();
    const openConfirmRowData = useBaseModal();
    const partPDFViewer = useBaseModal();
    const [rowStatusChangeLoader, setRowStatusChangeLoader] = React.useState<boolean>(false);
    const [replaceselectedOption, setReplacelectedOption] = React.useState<any>();
    const [saveLoader, setSaveLoader] = React.useState<boolean>(false);
    const [recjectedpart, setRecjectedpart] = React.useState<boolean>(false);
    const [rejectpartId, setRejectpartId] = React.useState<any>();
    const [partRowData, setPartRowData] = React.useState<any>();

    function isSaveEnabled<T extends { rejected?: boolean | null; status?: boolean | null }>(arr: T[]): boolean {
        return arr.every(item => item.rejected !== null && item.status !== null);
    }

    const openRowData = (id: number, rowData: any) => {
        setSelectedRowId(id);
        setSelectedRowData(rowData);
        openConfirmRowData.open();
    };


    const pdfViewer = (rowData: any) => {
        setPartRowData(rowData);
        // partPDFViewer.open();
        window.open(`${CDN_URL}${rowData?.doc_url}`, "_blank");
        // console.log(`${CDN_URL}${doc_url}`, "_blank");
    }

    function filterKeyFromObjects<T extends Record<string, any>>(arr: T[], keyToRemove: keyof T): Omit<T, typeof keyToRemove>[] {
        return arr.map(({ [keyToRemove]: _, ...rest }) => rest);
    }

    const chemical_columns = [
        "Aluminium-CC", "Antimony-CC", "Aurum-CC", "Cadmium-CC", "Carbon-CC",
        "Chromium-CC", "Cobalt-CC", "Copper-CC", "Iron-CC", "LEC-CC",
        "Lead-CC", "Manganese-CC", "Molybdenum-CC", "Nickel-CC", "Niobium-CC",
        "Palladium-CC", "Phosphorus-CC", "Selenium-CC", "Silicon-CC", "Silver-CC",
        "Tin-CC", "Sulfur-CC", "Titanium-CC", "Tungsten-CC", "Vanadium-CC",
        "Zinc-CC", "Zirconium-CC", "Bismuth-CC", "Magnesium-CC", "Boron-CC"
    ]

    const handleMaterialChange = (id: any, selectedValue: string) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, choose_material: selectedValue } : row
            )
        );
    };


    const generateColumns = (data: any) => {
        if (!data.length) return [];

        // Extract all unique column keys from all rows
        let columnKeys = Array.from(
            new Set(data.flatMap((row: any) => Object.keys(row)))
        );

        // Remove unwanted keys
        columnKeys = columnKeys.filter((key: any) => key !== "old_details_exists");

        // Filter out empty columns (columns that are empty across all rows)
        columnKeys = columnKeys.filter((key: any) => data.some((row: any) => row[key] !== "" && row[key] !== null && row[key] !== undefined));

        // Ensure specific order for first three columns: ["status", "part_no", "part_name"]
        columnKeys = ["status", "part_no", "doc_url", "part_name", "choose_material", ...columnKeys.filter((key: any) => !["status", "part_no", "part_name", "doc_url", "Delta"].includes(key))];

        return columnKeys.map((key: any) => {
            if (key === "status") {
                return {
                    field: "status",
                    headerName: "Action",
                    width: 170,
                    renderCell: (params: any) => {
                        const isMaterialSelected = params.row.choose_material && params.row.choose_material.trim() !== "";
                        return (<Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", width: "100%" }}>
                            {params.row.status === null && params.row.old_details_exists === true ? (
                                console.log("params.row.status: ", params.row.status, recjectedpart, rejectpartId, params.row.id),
                                <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", width: "100%" }}>
                                    {<Button
                                        size="small"
                                        sx={{
                                            backgroundColor: !isMaterialSelected ? "rgba(0, 0, 0, 0.12)" : "#ffc800", color: "#000", padding: '0.2rem 0.3rem',
                                            ':hover': {
                                                backgroundColor: "ffc800",
                                            },
                                        }}
                                        disabled={!isMaterialSelected}
                                        onClick={() => openRowData(params.row.id, params.row)}
                                    >
                                        Replace
                                    </Button>}

                                    {params.row.status !== true && <LoadingButton size="small" disabled={!isMaterialSelected} loading={rowStatusChangeLoader} sx={{
                                        backgroundColor: !isMaterialSelected ? "rgba(0, 0, 0, 0.12)" : '#ff7f7e', color: "#fff", padding: '0.2rem 0.3rem', ':hover': {
                                            backgroundColor: "ff7f7e",
                                        },
                                    }} onClick={() => handleRejectStatusChange(params.row.id, false)}>
                                        {(params.row.status == false) ? "Rejected" : "Reject"}
                                    </LoadingButton>}
                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", width: "100%" }}>
                                    {(params.row.status !== false) && <LoadingButton
                                        size="small"
                                        sx={{
                                            backgroundColor: !isMaterialSelected ? "rgba(0, 0, 0, 0.12)" : params.row.status == true ? "#008000" : "#c6e8ab",
                                            color: params.row.status == true ? "#fff" : "#000",
                                            padding: '0.2rem 0.3rem',
                                            ':hover': {
                                                backgroundColor: params.row.status == true ? "#008000" : "#c6e8ab",
                                            },
                                        }}
                                        disabled={!isMaterialSelected}
                                        loading={rowStatusChangeLoader}
                                        onClick={() => handleStatusChange(params.row.id, true)}
                                    >
                                        {params.row.status == true ? "Accepted" : "Accept"}
                                    </LoadingButton>}
                                    {params.row.status == true && <IconButton sx={{ padding: '0px', color: 'primary.main' }} title="Revert" onClick={() => handleStatusChange(params.row.id, null)}><SettingsBackupRestoreIcon /></IconButton>}
                                    {params.row.status !== true && <LoadingButton size="small" loading={rowStatusChangeLoader} sx={{
                                        backgroundColor: !isMaterialSelected ? "rgba(0, 0, 0, 0.12)" : '#ff7f7e', color: "#fff", padding: '0.2rem 0.3rem', ':hover': {
                                            backgroundColor: "#ff7f7e",
                                        },
                                    }}
                                        disabled={!isMaterialSelected}
                                        onClick={() => handleRejectStatusChange(params.row.id, false)}>
                                        {params.row.status}
                                        {(params.row.status == false) ? "Rejected" : "Reject"}
                                    </LoadingButton>}
                                    {params.row.status == false && <IconButton sx={{ padding: '0px', color: 'primary.main' }} title="Revert" onClick={() => handleRejectStatusChange(params.row.id, null)}><SettingsBackupRestoreIcon /></IconButton>}
                                </Box>
                            )}
                        </Box>)
                    },
                };
            }
            if (key === "part_no") {
                return {
                    field: "part_no",
                    headerName: "Part No",
                    width: 200,
                    renderCell: (params: any) => (
                        <Tooltip title={params.row.part_no} placement="top-start">
                            <Box sx={{ display: "block", gap: "0.5rem", maxWidth: "100%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {params.row.part_no || "--"}
                            </Box>
                        </Tooltip>
                    ),
                };
            }
            if (key === "doc_url") {
                return {
                    field: "doc_url",
                    headerName: "",
                    width: 40,
                    renderCell: (params: any) => (
                        <Tooltip title={params.row.part_name} placement="top-start">
                            <Box sx={{ display: "block", gap: "0.5rem", maxWidth: "100%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {params?.row?.doc_url && <IconButton sx={{ padding: '0', color: 'primary.main' }} onClick={() => pdfViewer(params?.row)}><VisibilityIcon /></IconButton>}
                            </Box>
                        </Tooltip>
                    ),
                };
            }
            if (key === "part_name") {
                return {
                    field: "part_name",
                    headerName: "Part Name",
                    width: 140,
                    renderCell: (params: any) => (
                        <Tooltip title={params.row.part_name} placement="top-start">
                            <Box sx={{ display: "block", gap: "0.5rem", maxWidth: "100%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {params.row.part_name || "--"}
                            </Box>
                        </Tooltip>
                    ),
                };
            }
            if (key === "choose_material") {
                return {
                    field: "choose_material",
                    headerName: "Select Delta Material",
                    width: 230,
                    renderCell: (params: any) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                            <FormControl fullWidth size="small">
                                <Select
                                    size="small"
                                    variant="standard"
                                    value={params.row.choose_material || ""}
                                    onChange={(event) => handleMaterialChange(params.row.id, event.target.value)}
                                    displayEmpty
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: "20rem", // Set height for dropdown options
                                            }
                                        }
                                    }}
                                    sx={{
                                        width: '100%',
                                        "&:before": {
                                            borderBottomColor: "primary.main",
                                        },
                                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                                            fontSize: '1rem'
                                        },
                                        '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                                    }}
                                >
                                    <MenuItem value="" disabled>Select Material</MenuItem>
                                    {chemical_columns.map((material) => (
                                        <MenuItem key={material} value={material}>
                                            {material}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography>({params.row.Delta})</Typography>
                        </Box>
                    ),
                };
            }
            return {
                field: key,
                headerName: key.replace("-CC", ""),
                // minWidth: 70,
                renderCell: (params: any) => (
                    <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", width: "100%" }}>
                        {params.row[key] !== undefined && params.row[key] !== "" ? params.row[key] : "--"}
                    </Box>
                ),
            };

        });
    };


    // Handle File Upload
    const handleFileUpload = () => {
        if (!selectedFile) {
            setFileError("Please select a PDF file before fetching.");
            return;
        }

        if (selectedFile.type !== "application/pdf") {
            setFileError("Only PDF files are allowed.");
            setSelectedFile(null);
            return;
        }

        setUpdatedLoader(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("Analyzer_name", selectedOption);
        formData.append("upload_values", "false");

        API.post("/xcpep/Upload_Chemical_Composition_value_PDF/", formData, {}, 0)
            .then((res: any) => {
                setColumns(generateColumns(res.data));

                // Add unique ID field for DataGrid
                const formattedRows = res.data.map((item: any, index: number) => ({
                    id: index + 1,
                    rejected: true,
                    ...item,
                }));
                setRows(formattedRows);
                setUpdatedLoader(false);
                ADMIN.toast.info("Data fetched successfully");
                setSelectedFile(null);
            })
            .catch((err: any) => {
                if (err && err.response && err.response.data) {
                    const { data } = err.response;
                    Swal.fire({
                        icon: "warning",
                        title: "Warning",
                        text: data[0],
                        confirmButtonText: "Ok",
                        customClass: {
                            container: "swal2Container",
                        },
                    });
                }
                setUpdatedLoader(false);
            });
    };

    // Handle Status Change
    const handleStatusChange = (id: number, newStatus: any) => {
        setRowStatusChangeLoader(true); // Start loading state
        setRecjectedpart(false);
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, status: newStatus } : row
            )
        );
        setUpdatedRows((prevUpdatedRows) => {
            const existingRow = prevUpdatedRows.find((row) => row.id === id);
            if (existingRow) {
                return prevUpdatedRows.map((row) =>
                    row.id === id ? { ...row, status: newStatus } : row
                );
            }
            return [...prevUpdatedRows, { id, status: newStatus }];
        });

        setTimeout(() => {
            setRowStatusChangeLoader(false); // Stop loading after update
        }, 500);
    };

    const handleRejectStatusChange = (id: number, newStatus: any) => {
        setRowStatusChangeLoader(true); // Start loading state
        setReplacelectedOption(false);
        console.log("id: ", id);
        setRejectpartId(id);
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, status: newStatus } : row
            )
        );

        setRecjectedpart(true);
        setUpdatedRows((prevUpdatedRows) => {
            const existingRow = prevUpdatedRows.find((row) => row.id === id);
            if (existingRow) {
                return prevUpdatedRows.map((row) =>
                    row.id === id ? { ...row, status: newStatus } : row
                );
            }
            return [...prevUpdatedRows, { id, status: newStatus }];
        });


        setTimeout(() => {
            setRowStatusChangeLoader(false); // Stop loading after update
        }, 500);
    }

    // Handle Save
    const handleSave = () => {
        setSaveLoader(true);
        const updatedRows = rows.map(row => ({
            ...row,
            [row.choose_material]: `${row.Delta}`, // Append selected material value
        }));

        API.post("/xcpep/Upload_Chemical_Composition_value_PDF/", { upload_values: true, product_id: topVault, details: filterKeyFromObjects(updatedRows, "rejected") }, {}, 0)
            .then(() => {
                setSaveLoader(false);
                ADMIN.toast.success("Data updated successfully");
                onCloseModal();
            })
            .catch((err: any) => {
                if (err && err.response && err.response.data) {
                    const { data } = err.response;
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: data[0],
                        confirmButtonText: "Ok",
                        customClass: {
                            container: "swal2Container",
                        },
                    });
                }
                setSaveLoader(false);
            });
    };


    // const [isSaveDisabled, setIsSaveDisabled] = useState(true);



    return (<>

        {openConfirmRowData.isOpen && <ChecmicalCompositionRowDataUpdate
            isOpen={openConfirmRowData.isOpen}
            onCloseModal={openConfirmRowData.close}
            selectedRowData={selectedRowData}
            selectedRowId={selectedRowId}
            replaceselectedOption={replaceselectedOption}
            setReplacelectedOption={setReplacelectedOption}
            handleStatusChange={handleStatusChange}
            handleRejectStatusChange={handleRejectStatusChange}
        />}

        {partPDFViewer.isOpen && <BOMChemicalCompositionPDFViwer
            isOpen={partPDFViewer.isOpen}
            onCloseModal={partPDFViewer.close}
            partRowData={partRowData}
        />}

        <Modal open={isOpen} onClose={onCloseModal} aria-labelledby="modal-title">
            <Box sx={style}>
                <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            width: "100%",
                            textAlign: "center",
                            marginRight: "-2rem",
                        }}
                    >
                        Fetch data from Chemical Composition PDF
                    </Typography>
                    <CancelIcon sx={{ cursor: "pointer", margin: "0.5rem" }} onClick={onCloseModal} />
                </Box>
                <Divider sx={{ borderColor: "primary.light", margin: "0 1rem", mb: "1rem" }} />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%', }}>
                    {/* Dropdown Selection */}
                    <Box sx={{ mb: 2, width: '100%' }}>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Select Company:
                        </Typography>
                        <Select
                            variant="standard"
                            fullWidth
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            sx={{
                                "&:before": {
                                    borderBottomColor: "primary.main",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                                    fontSize: '1rem'
                                },
                                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                            }}
                        >
                            <MenuItem value="SRI RAGHAVENDRA MATERIAL TESTING SERVICES">
                                SRI RAGHAVENDRA MATERIAL TESTING SERVICES
                            </MenuItem>
                            <MenuItem value="ANALYTICAL RESEARCH & METALLURGICAL LABORATORIES PVT. LTD.">
                                ANALYTICAL RESEARCH & METALLURGICAL LABORATORIES PVT. LTD.
                            </MenuItem>
                        </Select>
                    </Box>

                    {/* File Upload */}
                    <Box sx={{ mb: 2, width: '100%' }}>
                        <Typography variant="body2" sx={{ mb: '12px' }}>
                            Upload PDF File:
                        </Typography>
                        <input type="file" accept="application/pdf" disabled={!selectedOption}
                            onChange={(event) => {
                                if (event.target.files && event.target.files.length > 0) {
                                    setSelectedFile(event.target.files[0]); // Store the file in state
                                    setFileError(null);
                                }
                            }}
                        />
                        {fileError && <Typography variant="body2" color="error">{fileError}</Typography>}
                        {/* {selectedFile && <Typography variant="body2">Uploaded: {selectedFile.name}</Typography>} */}
                    </Box>
                    <LoadingButton loading={updatedLoader} variant="contained" sx={{ minWidth: '6rem' }} onClick={() => handleFileUpload()} disabled={!selectedFile}>
                        Fetch
                    </LoadingButton>
                </Box>
                {/* DataGrid */}
                {columns.length > 0 && (
                    <Box sx={{ height: "60vh", width: "100%" }}>
                        <Typography variant="body2" sx={{ mb: -1, textAlign: 'center' }}> <span style={{ color: 'red' }}>Note *:</span> Please select <b>Delta Material</b> to either accept or reject the new chemical composition values.</Typography>
                        <DataGrid rows={rows} columns={columns} components={{ Toolbar: CustomToolbar }}
                            headerHeight={42}
                            rowHeight={36}
                            getRowClassName={(params) => {
                                return params.row.status && params.row.status === true
                                    ? "acceptedRow" // Green for accepted
                                    : params.row.status === false ? "rejectedRow"
                                        : 'pendingRow'; // Yellow for pending
                            }}
                            sx={{
                                '& .acceptedRow': {
                                    backgroundColor: '#d4edda !important',  /* Light Green */
                                },
                                '& .rejectedRow': {
                                    backgroundColor: '#ffd1cd !important', /* Light red */
                                },
                                '& .pendingRow': {
                                    backgroundColor: '#ffffff !important', /* Light red */
                                },
                                '& ::-webkit-scrollbar': {
                                    width: '0.3rem',
                                    height: '0.3rem',
                                },
                                '& ::-webkit-scrollbar-thumb': {
                                    // background: $thumb-color;
                                    backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
                                    borderRadius: '10px',
                                },
                                '& ::- webkit-scrollbar-thumb: hover ': {
                                    // width: "4px",
                                    backgroundColor: '#045DE9 !important',
                                },
                                '::-webkit-scrollbar-track': {
                                    backgroundColor: '#e1e1f3 !important',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                    borderBottomColor: 'primary.light',
                                },
                            }} />
                    </Box>
                )}

                {/* Save Button */}
                {rows.length > 0 && <LoadingButton loading={saveLoader} disabled={!isSaveEnabled(rows)} variant="contained" sx={{ mt: 2 }} fullWidth onClick={handleSave}>
                    Upload PDF & Data to Respective Parts
                </LoadingButton>}
            </Box>
        </Modal>
    </>
    );
}


