export const CellNameOne = [
  "float",
  "nonzero-float",
  "positive-float",
  "nonzero-positive-float",
  "positive-int",
  "nonzero-positive-int",
  "percentage",
  "country",
  "negative-float",
  "nonzero-negative-float",
  "integer",
  "nonzero-integer",
  "negative-integer",
  "nonzero-negative-integer",
  "date",
  "date-and-time",
  "BOM-table-calculator"
];

export const CellNameSecond = [
  "360-video",
  "textarea",
  "table",
  "image",
  "boolean",
  "video",
 
  
];

export const CellNameThird = ["singleselect", "multiselect", "database"];
export const CellNameEleventh = ["costing-database"];
export const CellNameFour = ["document"];
export const CellNameFive = ["longtext", "string"];
export const CellNameSix = ["BOM calculator"];
export const FeatureCellNames = ["numerical"];
export const FeatureNumericalCellNames = [
  "nonzero-float",
  "float",
  "nonzero-integer",
  "positive-float",
  "nonzero-negative-float",
  "positive-int",
  "nonzero-positive-float",
  "nonzero-positive-int",
];
export const FeatureTextCellNames = ["longtext"];
