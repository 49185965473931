// lazy

import {
  debounce,
  invert,
  isNull,
  isNumber,
  isUndefined,
  omit,
  pickBy,
  uniqBy,
} from "lodash";
import { ID, ObjectType, fetched } from "../../utlis/commonInterfaces";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  CALCULATOR_INPUT_CONTENT_TYPE,
  CALCULATOR_INPUT_TYPE,
  CALCULATOR_TYPE,
  InputContentType,
  calculatorCategoryOptions,
  calculatorCostInputOptions,
  calculatorDatabaseColumnsOptions,
  calculatorDatabaseOptions,
  calculatorInputBackgroundColorMapView,
  calculatorInputOptions,
} from "./calculator.constant";
import { CalculatorInput } from "../../Redux/Reducers/calculatorInput.reducer";
import { ParameterConfig } from "../../Redux/Reducers/parameterConfig.reducer";
import { numericDataTypes } from "../../utlis/parameterDataTypes.constants";
import { pipe } from "../../utlis/other";
import * as yup from "yup";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  TableCell,
  TableRow,
  TableBody,
  Box,
  Table,
  FormControl,
  TextField,
  Skeleton,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState, useRef, useMemo, useEffect } from "react";
import RViewerJS from "viewerjs-react";
import useFetch from "../../Core/CustomHooks/useFetch";
import { ICalculator } from "../../Redux/Reducers/calculator.reducer";
import calculatorService from "../../Redux/Services/calculator.service";
import calculatorInputService from "../../Redux/Services/calculatorInput.service";
import processConfigService from "../../Redux/Services/processConfig.service";
import { API } from "../../api-services";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { useCollection } from "../../CustomHook/useCollection";
import bomconfigService from "../../Redux/Services/bomconfig.service";
import styles from "./createCostCalculator.module.scss";
import SaveIcon from "@mui/icons-material/Save";
import yupValidate from "../../utlis/yupValidate";
import { deleteSwal } from "../../utlis/deleteSwal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import React from "react";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import InfoIcon from "@mui/icons-material/Info";
import InfoTreeModal from "./InfoTreeModal";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBucket from "../../Assets/images/svgs/storage_bucket_add.svg";
import FetchBucket from "../../Assets/images/svgs/getstorage_bucket.svg";
import closeBucket from "../../Assets/images/svgs/close_bucket.svg"
import { ADMIN } from "../../Redux/Services/admin.service";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import FetchBucketDataModal from "./FetchBucketDataModal";
import FetchParameterInfoModal from "./FetchedParameterInfoModal";
import { PermissionData } from "./CostingTemplateConfig";
import RenderValueColumnItemPCBCalcView from "./RenderValueColumnItemPCBCalcView";
import RenderValueColumnItemView from "./RenderValueColumnItemView";
import ConfigCustomValidation from "./ConfigCustomValidation";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
// ----------------------------------------------------------------------------------
// params

type Params = {
  processId: string;
  commodityMappingId: string;
  pId: string;
  type: string;
  calculatorCategory: string;
  calculatorId: string;
};

// ----------------------------------------------------------------------------------
// constants

const inputKeysToOmit: Record<number, string[]> = {
  [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
    "database",
    "formula",
    "constant",
    "volume",
    "attribute",
    "user_input",
    "BOM-table-calculator",
  ],
  [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: [
    "database",
    "formula",
    "attribute",
    "constant",
    "user_input",
  ],
  [CALCULATOR_INPUT_TYPE.CONSTANT]: [
    "database",
    "formula",
    "parameter",
    "volume",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.VOLUME]: [
    "database",
    "formula",
    "parameter",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.DATABASE]: [
    "formula",
    "constant",
    "volume",
    "parameter",
    "user_input",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.FORMULA]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.USER_INPUT]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "formula",
    "table_element",
  ],
};
interface CalculatorInputFromApi {
  id: ID;
  content_type: InputContentType;
  name: string;
  /** string used to refere the input in the formula */
  alias: string;

  parameter?: ID;
  table_element?: ID;

  attribute?: string | any;

  formula?: string;
  constant?: number;
  user_input?: string;
  calculator_category?: number;
  volume?: number;
  calculator: ID;
  image_url?: string;
  test_input?: number;
  result?: number;
}

const styleDivider = {
  width: "100%",
  height: "1px",
  backgroundColor: "primary.main",
};

const inputContentTypeToDatabaseMap: ObjectType<number> = {
  Material: 1,
  Machine: 2,
  Labour: 3,
  ToolDatabase: 4,
  FastenerDatabase: 5,
  PurchasingItem: 6,
  StrokeRateDatabase: 7,
  RevisionCurrency: 8,
};

const databaseMapToConntentType = invert(inputContentTypeToDatabaseMap);

export const transformCalculatorInputFromApi = (
  input: CalculatorInputFromApi
): CalculatorInput => {
  const { content_type, ...rest } = input;
  const obj: any = {};
  if (content_type) {
    if (rest.parameter) {
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_TABLE_CALCULATOR)
        obj.type = CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR;
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
    } else if (rest.attribute) {
      if (rest.attribute === "PCB BOM Table") {
        obj.type = CALCULATOR_INPUT_TYPE.PCB_BOM_TABLE;
      }
      if (content_type == CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
        obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
      } else if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT) {

        obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
      }
      else {
        obj.type = CALCULATOR_INPUT_TYPE.DATABASE;
        obj.database = inputContentTypeToDatabaseMap[content_type];
      }
    }
  }
  // if(attribute)
  if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
  else if (!isNull(rest.user_input))
    obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
  if (rest.constant || rest.constant == 0) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
  if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;
  if (rest.attribute === "PCB BOM Table") obj.type = CALCULATOR_INPUT_TYPE.PCB_BOM_TABLE
  //changed {} to {content_type: content_type}
  return Object.assign({ content_type: content_type }, rest, obj);
};



const toApi = async (
  input: CalculatorInput
): Promise<CalculatorInputFromApi> => {
  const transformedInput = transformInput(input) as Required<CalculatorInput>;

  try {
    const validatedInput = (await yupValidate(
      schema,
      transformedInput
    )) as CalculatorInput;

    const { type, database, ...rest } = validatedInput;
    const obj: any = {};

    if (database) {
      obj.content_type = databaseMapToConntentType[database];
    }
    if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER;
    }
    if (type === CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_TABLE_CALCULATOR;
    }

    if (type === CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER;
    }
    if (type === CALCULATOR_INPUT_TYPE.COST_INPUT) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT;
    }
    if (type === CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT) {
      obj.attribute = input?.attribute
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT;
    }
    if (type === CALCULATOR_INPUT_TYPE.USER_INPUT) {
      obj.content_type = null;
    }
    if (type === CALCULATOR_INPUT_TYPE.PCB_BOM_TABLE) {
      obj.content_type = null;
      obj.attribute = "PCB BOM Table"
    }
    if (type === CALCULATOR_INPUT_TYPE.VOLUME) {
      obj.content_type = null;

    }


    return pickBy(
      Object.assign({}, rest, obj),
      (value) => !isUndefined(value)
    ) as any;
  } catch (e: any) {
    const msg = Object.values(e).join(", ");
    throw new Error(msg);
  }
};

// ----------------------------------------------------------------------------------
// helpers

const generateConfigsOptions = (configs: ParameterConfig[]) => {
  return configs.map((config) => ({
    key: config.id,
    value: config.parameter_id,
    text: config.name,
  }));
};

// to only maintain keys that are related to the input type during saving of data
const transformInputs = (inputs: CalculatorInput[]) => {
  return inputs.map(transformInput);
};

/** removes unwanted columns depending on type */
const transformInput = (input: CalculatorInput) => {
  return omit(input, inputKeysToOmit[input.type] || []) as CalculatorInput;
};

const numericOrTableDataTypes = numericDataTypes.concat("table");
const filterNumericOrTableConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) =>
    numericOrTableDataTypes.includes(config.data_type)
  );
};

const filterNonTableColumnConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) => !config.parameter_table_id);
};

const filterTableColumnConfigs = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  return configs.filter((config) => config.parameter_table_id === parameterId);
};

/** configs that need to be shown for BOM/Commodity parameters dropdown */
const getConfigsOptions = pipe(
  filterNumericOrTableConfigs,
  filterNonTableColumnConfigs,
  generateConfigsOptions
);

/** configs that need to shown for columns of selectedParameter */
const getTableColumnConfigsOptions = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  const tableColumnConfigs = filterTableColumnConfigs(configs, parameterId);
  return pipe(
    filterNumericOrTableConfigs,
    generateConfigsOptions
  )(tableColumnConfigs);
};

// ----------------------------------------------------------------------------------
// schema

const schema = yup.object().shape({
  type: yup.number().required('"Type" is required'),
  name: yup.string().required('"Name" is required'),
  alias: yup.string(),

  parameter: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('"Parameter" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  table_element: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .notRequired(),
    otherwise: yup.number().transform(() => undefined),
  }),
  attribute: yup.string().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.DATABASE,
        CALCULATOR_INPUT_TYPE.COST_INPUT,
      ].includes(type),
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('"Column/Cost Type" is required'),
    otherwise: yup.string().transform(() => undefined),
  }),

  database: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.DATABASE,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('"Database" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  formula: yup.string().when("type", {
    is: CALCULATOR_INPUT_TYPE.FORMULA,
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('"Formula" is required'),
    otherwise: yup.string().transform(() => undefined),
  }),
  constant: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CONSTANT,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : 0))
      // .notOneOf([0])
      .required('"Constant Value" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  user_input: yup.string().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.USER_INPUT,
        CALCULATOR_INPUT_TYPE.FORMULA,
        CALCULATOR_INPUT_TYPE.CONSTANT,
        CALCULATOR_INPUT_TYPE.VOLUME,
      ].includes(type),
    then: yup.string().transform((value) => (isNull(value) ? "" : value)),
    otherwise: yup.string().transform(() => undefined),
  }),
  calculator_category: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT,
    then: yup
      .number()
      .transform((value) => (isNumber(value) ? value : undefined))
      .required('"Cost Model Category" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
});

// ----------------------------------------------------------------------------------

interface InputConfigurationProps {
  selectedTreeData: any;
  setCounterApiCall?: any;
  getTreeData?: any;
  setDataType?: any;
  getBucketList?: any;
  categoryInfo?: any;
  bucketRefreshCounter?: any;
  outputClicked?: any
}

const CreateCostCalculatorView: React.FC<InputConfigurationProps> = (props) => {
  const customValidationModal: any = useBaseParamsModal()
  const { selectedTreeData, setCounterApiCall, getTreeData, setDataType, getBucketList, categoryInfo, bucketRefreshCounter, outputClicked } =
    props;
  const openFetchedBucketData = useBaseParamsModal();
  const { process, id, type } = selectedTreeData;
  const { projectId, projectname, categoryId, configMirror, config_id, isDelete } =
    useRouteParams<any>();
  const fetchBucketDataOpen = useBaseParamsModal();
  const PermissionValue = React.useContext<any>(PermissionData)

  // ----------------------------------------------------------------------------------
  // state
  const [namesAndParams, setNamesAndParams] = useState<any>([])
  const [inputsFetched, setInputsFetched] = useState<fetched>("FALSE");
  const [editableRow, setEditableRow] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isFlagDeleting, setIsFlagDeleting] = useState<boolean>(false);
  const [textToCopy, setTextToCopy] = useState<any>("");
  const [isCopied, setIsCopied] = useState<any>(false);
  const [activeCopyId, setActiveCopyId] = useState<any>();
  const [currentSelected, setCurrentSelected] = useState<any>(-1);
  const [currentImage, setCurrentImage] = useState<any>("");
  const [color, setColor] = useState<any>("");
  const [colorCalc, setColorCalc] = useState<any>("primary.main");
  const [calcNameLoading, setCalcnameloading] = useState<any>(false);
  const [activeId, setActiveId] = useState<any>();
  const [calcName, setCalcName] = useState<any>("");
  const [bomCalculatorColumns, setBomCalculatorColumns] = useState<any>([])
  const [validation, setValidation] = useState<any>();
  const [saveApiCallCount, setSaveApiCallCount] = useState<any>(0);
  const inputElRef = useRef<any>();
  const [expandInput, setExpandInput] = useState<any>();
  const [formulaTextArea, setFormulaTextArea] = useState<any>("");
  const [expand, setExpand] = useState<any>(false);
  const imageViewerElRef = useRef<any>();
  const [isCostSync, setIsCostSync] = useState<any>(false);
  const [formulaCheckedStatus, setFormulaCheckedStatus] = useState<any>(false);
  const [syncButtonColor, setSyncButtonColor] = useState<any>("");
  const [syncButtonAPICall, setSyncButtonAPICall] = useState<any>(0);
  const [useraction, setuseraction] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [resultInputColor, setResultInputColor] = useState<any>("");
  const [Lockstatus, setLockstatus] = useState<any>();
  const infoModal = useBaseModal();
  const userProfile = useSelector(userSelectors.selectAll);
  const errorMessageModel = useBaseModal();
  const [labourOptions, setLabourOptions] = useState<any>([]);
  const [machineOptions, setMachineOptions] = useState<any>([]);
  const [materialOptions, setMaterialOptions] = useState<any>([]);
  const [toolOptions, setToolOptions] = useState<any>([]);
  const [bopOptions, setBopOptions] = useState<any>([]);
  const [setUpOptions, setSetUpOptions] = useState<any>([]);
  const [ParentCount, setParentCount] = useState<any>(0)
  const [bucketLoading, setBucketLoading] = useState<any>(false)
  const [bomConfigs, setBOMConfigs] = useState<any>([])

  const { width } = useWindowDimensions();

  useEffect(() => {
    setExpand(false);
    setFormulaCheckedStatus(false);
  }, [id]);




  const addToBucket = (iddd: any) => {
    setBucketLoading(true)
    API.post(
      `/config/configuration_drop_bucket/`,
      {
        configuration_id: selectedTreeData?.config_id,
        field_id: iddd,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Added to bucket");
        getBucketList();
        setBucketLoading(false)
        fetchInputsWOL();
        // setCounterApiCall((prev: any) => prev + 1);
      })
      .catch((err: any) => { setBucketLoading(false) });
    // ExactDebounceBucket(iddd);
  };

  React.useEffect(() => {
    if (bucketRefreshCounter && bucketRefreshCounter !== 0) {
      fetchInputsWOL();
    }
  }, [bucketRefreshCounter])

  // ----------------------------------------------------------------------------------
  // collection hook to hold inputsconsole.log("jskdnk")

  const inputs: any = useCollection<CalculatorInput>();

  // ----------------------------------------------------------------------------------
  // fetch parameter mappings

  // fetch bom parameters
  // const { 1: bomConfigs } = useFetch<ParameterConfig[]>({
  //   method: bomconfigService.getByProjectIdWithCM,
  //   args: [projectId, true, true],
  //   dependencies: [type, id],
  //   condition: type === "calculator",
  //   transformResData(data) {
  //     const configs: any = transformConfigurationsFromApi(data);

  //     return uniqBy(configs, (config: any) => config.parameter_id);
  //   },
  //   fallBackErrMsg: "Could not fetch bom parameters",
  // });

  const { 1: bomConfigstablecalculator } = useFetch<ParameterConfig[]>({
    method: bomconfigService.getByProjectIdWithTableCM,
    args: [projectId, true, true],
    dependencies: [type, id],
    condition: type === "calculator",
    transformResData(data) {
      const configs: any = transformConfigurationsFromApi(data);

      return uniqBy(configs, (config: any) => config.parameter_id);
    },
    fallBackErrMsg: "Could not fetch bom parameters",
  });

  // fetch commodity parameters
  const { 1: processConfigs } = useFetch<ParameterConfig[]>({
    method: processConfigService.get,
    args: [process, true],
    dependencies: [type, id],
    condition: type === "calculator",
    transformResData: transformConfigurationsFromApi,
    fallBackErrMsg: "Could not fetch commodity parameters",
  });

  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getParamsAndItemName = () => {
    API.get("/api/db/parameter/", { table_id: 1002, pcb_bom_table: true }, 0).then((res: any) => {
      setNamesAndParams(res.data)
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    getParamsAndItemName();
  }, [selectedTreeData])

  const open = Boolean(anchorEl);
  const poid = open ? "simple-popover" : undefined;

  // useEffect(() => {

  //     setLockstatus(null);
  //   API.get("cost/calculator/", { id: id }, 0).then((res: any) => {
  //     setLockstatus(res.data?.[0]);
  //     setValidation(res.data?.[0]?.validation);
  //   });

  // }, [saveApiCallCount, syncButtonAPICall,id, ParentCount]);


  useEffect(() => {
    if (saveApiCallCount > 0) {
      setLockstatus(null);
      API.get("cost/calculator/", { id: id }, 0).then((res: any) => {
        setLockstatus(res.data?.[0]);
        setValidation(res.data?.[0]?.validation);
      });
    }
  }, [saveApiCallCount]);

  useEffect(() => {
    if (syncButtonAPICall > 0) {
      setLockstatus(null);
      API.get("cost/calculator/", { id: id }, 0).then((res: any) => {
        setLockstatus(res.data?.[0]);
        setValidation(res.data?.[0]?.validation);
      });
    }
  }, [syncButtonAPICall]);

  useEffect(() => {
    if (ParentCount > 0) {
      setLockstatus(null);
      API.get("cost/calculator/", { id: id }, 0).then((res: any) => {
        setLockstatus(res.data?.[0]);
        setValidation(res.data?.[0]?.validation);
      });
    }
  }, [ParentCount]);

  const getAllResult = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        costing_config: true,
      },
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    getAllResult();
  }, []);

  const { 1: calculator, 2: setCalculator } = useFetch<ICalculator>({
    method: calculatorService.getById,
    args: [{ id: id }],
    getZeroth: true,
    condition: type === "calculator",
    dependencies: [type, id],
  });
  const ExactDebounce = useRef(
    debounce(async (value: any) => {
      await API.put(
        `/cost/calculator/${id}/`,
        {
          name: value,
        },
        {},
        0
      )
        .then((res: any) => {
          // setCalcName(value);
          setParentCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          setCalcName((prev: any) => prev);
        });
    }, 500)
  ).current;
  const TestInputChange = useRef(
    debounce(async (value: any, name: any) => {
      API.put(`/cost/calculator_input/${value}/`, { test_input: name }, {}, 0)
        .then((res: any) => {
          setParentCount((prev: any) => prev + 1);
        })
        .catch((err: any) => { });
    }, 500)
  ).current;

  const aliasChange = useRef(
    debounce(async (value: any, name: any) => {
      API.put(`/cost/calculator_input/${value}/`, { alias: name }, {}, 0)
        .then((res: any) => {
          setColor("");
          setParentCount((prev: any) => prev + 1);
          fetchInputs();
        })
        .catch((err: any) => { });
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
      TestInputChange.cancel();
    };
  }, []);

  const handleModelNameChange = (ed: any) => {
    setColorCalc("red");
    const { value } = ed.target;
    setCalcName(value);
    // ExactDebounce(value);
  };
  useEffect(() => {
    if (calculator) {
      setCalcName(calculator?.name);
    }
  }, [calculator]);

  // ----------------------------------------------------------------------------------
  // helpers

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Costing Configuration",
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action);
        // sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const bomTableConfigOptions = (parameterId: ID) => {
    return bomConfigs
      ? getTableColumnConfigsOptions(bomConfigs, parameterId)
      : [];
  };
  const bomTableConfigTableCalcOptions = (parameterId: ID) => {
    return bomConfigstablecalculator
      ? getTableColumnConfigsOptions(bomConfigstablecalculator, parameterId)
      : [];
  };

  const processTableConfigOptions = (parameterId: ID) => {
    return processConfigs
      ? getTableColumnConfigsOptions(processConfigs, parameterId)
      : [];
  };

  type DE_TYPE =
    | typeof CALCULATOR_INPUT_TYPE.BOM_PARAMETER
    | typeof CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR
    | typeof CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
  /**
   * indicates whether the give parameter id is table or not
   * @param type whether the parameterId needs to found in bom or peocess paramaters
   * @param parameterId id
   */
  const isTableParameter = (type: DE_TYPE, parameterId: ID) => {
    const configs = type === 1 ? bomConfigs : processConfigs;
    return (
      //@ts-ignore
      configs?.find((config) => config.parameter_id === parameterId)
        ?.data_type === "table" ?? false
    );
  };

  // ----------------------------------------------------------------------------------
  // memorization

  const bomConfigsOptions = useMemo(() => {
    return bomConfigs ? getConfigsOptions(bomConfigs) : [];
  }, [bomConfigs]);

  const bomConfigsTableCalcOptions = useMemo(() => {
    return bomConfigstablecalculator
      ? getConfigsOptions(bomConfigstablecalculator)
      : [];
  }, [bomConfigstablecalculator]);

  const processConfigsOptions = useMemo(() => {
    return processConfigs ? getConfigsOptions(processConfigs) : [];
  }, [processConfigs]);

  // ----------------------------------------------------------------------------------
  // api helpers
  const fetchInputs = async () => {
    setInputsFetched("FETCHING");
    try {
      const { data } = await calculatorInputService.get(
        id,
        config_id != 0 ? config_id : undefined
      );



      inputs.setAll(
        data
          ?.map(transformCalculatorInputFromApi)
          ?.sort((a: any, b: any) => a.sequence - b.sequence)
      );

      setInputsFetched("TRUE");
    } catch (e) {
      //   ADMIN.toast.throwError(e, "Could not fetch input");
      setInputsFetched("ERROR");
    } finally {
      API.put(
        `/config/get_costing_configuration_based_on_costing_category/${config_id}/`,
        { test_input: true },
        {},
        0
      )
        .then((res: any) => { })
        .catch((err: any) => { });
    }
  };
  const fetchInputsWOL = async () => {
    try {
      const { data } = await calculatorInputService.get(
        id,
        config_id != 0 ? config_id : undefined
      );
      inputs.setAll(
        data
          ?.map(transformCalculatorInputFromApi)
          ?.sort((a: any, b: any) => a.sequence - b.sequence)
      );
    } catch (e) {
      //   ADMIN.toast.throwError(e, "Could not fetch input");
    } finally {
      API.put(
        `/config/get_costing_configuration_based_on_costing_category/${config_id}/`,
        { test_input: true },
        {},
        0
      )
        .then((res: any) => {
          setCounterApiCall((prev: any) => prev + 1);
        })
        .catch((err: any) => { });
    }
  };

  const fetchInputsTestRes = async () => {
    try {
      const { data } = await calculatorInputService.get(
        id,
        config_id != 0 ? config_id : undefined
      );
      inputs.setAll(
        data
          .map(transformCalculatorInputFromApi)
          .sort((a: any, b: any) => a.sequence - b.sequence)
      );
    } catch (e) {
      //   ADMIN.toast.throwError(e, "Could not fetch input");
      setInputsFetched("ERROR");
    } finally {
      API.put(
        `/config/get_costing_configuration_based_on_costing_category/${config_id}/`,
        { test_input: true },
        {},
        0
      )
        .then((res: any) => {
          outputClicked();
        })
        .catch((err: any) => { });
    }
  };

  // useEffect(() => {
  //   fetchInputs();
  // }, [saveApiCallCount]);

  const createInput = async (inputId: ID, input: CalculatorInput) => {
    try {
      const validatedInput = await toApi(input);
      var datavalue = validatedInput;
      // ? { ...validatedInput, attribute: "volume" }
      // : validatedInput;

      const { data } = await calculatorInputService.create(datavalue);
      inputs.overlapOne(inputId, transformCalculatorInputFromApi(data));
      // setSaveApiCallCount((prev: any) => prev + 1);
    } catch (e: any) {
      setErrorMessage(e.response.data);
      errorMessageModel.open();
    }
  };

  const updateInput = async (input: Partial<CalculatorInput>) => {
    try {
      const validatedInput = await toApi(input as any);

      var datavalue = validatedInput;
      await calculatorInputService.update(datavalue);
      setSaveApiCallCount((prev: any) => prev + 1);
    } catch (e: any) {
      setErrorMessage(e.response.data);
      errorMessageModel.open();
    }
  };

  const clearFetchedCostModels = (data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to clear fetched cost model",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(
          `config/get_costing_configuration_based_on_costing_category/${data?.config_id}/`,
          {},

          0
        )
          .then(() => {
            getTreeData();
            setDataType("");
            setCounterApiCall((prev: any) => prev + 1);
          })
          .catch((e) => {
            Swal.fire({
              title: "Error!",
              text: e?.response?.data?.[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const getData = (data: any) => {
    //@ts-ignore
    if (sessionStorage.getItem("calculator_type") != CALCULATOR_TYPE.OVERHEAD) {
      return data.filter((item: any) => item.value !== 7);
    } else {
      return data;
    }
  };

  const getVisible = (inputt: any) => {
    if (expandInput?.id !== inputt.id) {
      return true;
    } else if (inputt.type === CALCULATOR_INPUT_TYPE.FORMULA && !expand) {
      return true;
    } else if (inputt.alias !== "" && !expand) {
      return true;
    } else {
      return false;
    }
  };

  const fetchCalc = (value: any) => {
    API.get(
      "cost/calculator/",
      {
        process: Lockstatus.process,
        commodity_mapping: Lockstatus.commodity_mapping,
        category: value,
      },
      0
    )
      .then((res: any) => {
        switch (value) {
          case 1:
            setMaterialOptions(res?.data?.filter((item: any) => item.id != id));
            break;
          case 2:
            setBopOptions(res?.data?.filter((item: any) => item.id != id));
            break;
          case 3:
            setLabourOptions(res?.data?.filter((item: any) => item.id != id));
            break;
          case 4:
            setMachineOptions(res?.data?.filter((item: any) => item.id != id));
            break;
          case 5:
            setToolOptions(res?.data?.filter((item: any) => item.id != id));
            break;
          case 6:
            setSetUpOptions(res?.data?.filter((item: any) => item.id != id));
            break;

          default:
            break;
        }
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    if (Lockstatus) {
      [1, 2, 3, 4, 5, 6]?.map((item: any) => {
        fetchCalc(item);
      });
    }
  }, [Lockstatus]);

  const deleteInput = async (inputId: any) => {
    // delete directly from memory if not created in backend
    if (inputId < 1) return inputs.removeOne(inputId);

    try {
      await calculatorInputService.delete(inputId);
      inputs.removeOne(inputId);

      //   ADMIN.toast.success("Input deleted");
    } catch (e) {
      //   ADMIN.toast.throwError(e, "Could not delete input");
    }
  };

  const updateResult = async () => {
    if (!calculator) return;
    if (!calculator.result) return "";

    try {
      await calculatorService.update({
        id: id,
        updates: { result: calculator.result },
      });
      setSyncButtonAPICall((prev?: any) => prev + 1);
      setResultInputColor(undefined);
      //   ADMIN.toast.success("Result updated");
    } catch (e) {
      //   ADMIN.toast.throwError(e, "Could not update result");
    }
  };

  const correctFormula = (formula: string) => {
    const openingBrackets = "([{";
    const closingBrackets = ")]}";
    const stack: string[] = [];

    let correctedFormula = "";

    for (const char of formula) {
      if (openingBrackets.includes(char)) {
        stack.push(char); // Push opening brackets onto the stack
      } else if (closingBrackets.includes(char)) {
        const correspondingOpeningBracket =
          openingBrackets[closingBrackets.indexOf(char)];

        // If the stack is not empty and the top of the stack matches the corresponding opening bracket
        if (
          stack.length > 0 &&
          stack[stack.length - 1] === correspondingOpeningBracket
        ) {
          stack.pop(); // Pop the matching opening bracket from the stack
        } else {
          // Add a missing opening bracket to the corrected formula
          correctedFormula += correspondingOpeningBracket;
        }
      }

      correctedFormula += char; // Add the current character to the corrected formula
    }

    // Add any missing closing brackets for the remaining opening brackets on the stack
    while (stack.length > 0) {
      const missingClosingBracket =
        closingBrackets[openingBrackets.indexOf(stack.pop()!)]; // Use ! to assert non-null
      correctedFormula += missingClosingBracket;
    }
    if (correctedFormula) {
      setFormulaTextArea(correctedFormula);
      setColor("red");
      setActiveId(expandInput?.id);
      setFormulaCheckedStatus(false);
      inputs.updateOne({
        id: expandInput?.id,
        changes: { formula: correctedFormula },
      });
    }
  };

  // ----------------------------------------------------------------------------------
  // handlers

  const handleChange = (inputId: ID) => (e: any) => {
    setSyncButtonColor("red");

    const { name, value, selectionStart, selectionEnd } = e.target;

    if (name === "formula") {
      setFormulaTextArea(value);
    }

    if (name === "test_input") {
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      TestInputChange(inputId, value);
    } else if (name === "alias") {
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      setActiveId(inputId);
      aliasChange(inputId, value);
    } else if (name === "formula") {
      setColor("red");
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
    } else if (name === "database") {
      setColor("");
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
    } else if (name === "type" && value == 11) {
      setExpand(false);
      setActiveId(inputId);
      getVisible(5) && setColor("red");
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "Qty" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: 1 } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({
        id: inputId,
        changes: { ["calculator_category"]: null },
      });
    } else if (name === "type" && value == 4) {
      setExpand(false);
      setActiveId(inputId);
      setColor("");
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: 0 } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({
        id: inputId,
        changes: { ["calculator_category"]: null },
      });
    } else if (name === "type" && value != 5) {
      setExpand(false);
      setActiveId(inputId);
      setColor("");
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({
        id: inputId,
        changes: { ["calculator_category"]: null },
      });
    } else if (name === "type" && value == 5) {
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({
        id: inputId,
        changes: { ["calculator_category"]: null },
      });
    } else if (name === "calculator_category") {
      setColor("");
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      fetchCalc(value);
    } else {
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
    }
  };

  const handleResultChange = (ed: any) => {
    setCalculator((prev: any) =>
      Object.assign({}, prev, { result: ed.target.value })
    );
    setResultInputColor("red");
  };

  const handleAdd = () => {
    const newInput: CalculatorInput = {
      id: Math.random(),
      name: "",
      alias: "",
      calculator: id,
      type: CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
    };

    inputs.addOne(newInput);
  };

  const handleDelete = async (ed: any) => {
    const confirm: any = await deleteSwal("Delete Input");
    if (confirm) deleteInput(ed);
  };

  const handleSave = (inputId: any) => {
    // create
    setExpand(false);
    const input = inputs.getById(inputId);

    if (!input) return;

    if (inputId < 1) {
      createInput(inputId, { ...input, ...{ calculator: id } });
      return;
    }

    updateInput(input);
    setFormulaCheckedStatus(false);
    setColor("");
  };

  const clickSync = () => {
    setIsCostSync(true);
    Swal.fire({
      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
	<br />
	<p style="color:#007fff;"> Cost syncing is in progress in the background. You will be notified once it is finished.
	</p>   
	 </div>`,
    });
    API.get("/cost/cost_calculator_update/", { calculator: +id }, 0)
      .then((res: any) => {
        setIsCostSync(false);
        setSyncButtonAPICall((prev: any) => prev + 1);
        setSyncButtonColor("");
        // setCounterApiCall((prev:any)=>prev+1)
      })
      .catch((err: any) => {
        setIsCostSync(false);
      });
  };

  const getOptionData = (type: any) => {
    if (
      type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER ||
      type === CALCULATOR_INPUT_TYPE.USER_INPUT
    ) {
      return bomConfigsOptions;
    } else if (type === CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR) {
      return bomConfigsTableCalcOptions;
    } else {
      return processConfigsOptions;
    }
  };

  const getdatafromtype = (input: any) => {
    if (input.type === 1) {
      return bomTableConfigOptions(input.parameter);
    } else if (input.type === 12) {
      return bomTableConfigTableCalcOptions(input.parameter);
    } else {
      return processTableConfigOptions(input.parameter);
    }
  };

  const LockHandler = (e: any, type: any) => {
    if (type == false) {
      if (
        (userProfile && userProfile[0]?.is_superuser) ||
        userProfile[0]?.is_project_admin ||
        userProfile[0]?.id == Lockstatus?.locked_user
      ) {
        API.put(`cost/calculator/${id}/`, { lock: type }, 0).then(
          (res: any) => {
            setSaveApiCallCount((prev: any) => prev + 1);
          }
        );
      } else {
        infoModal.open();
      }
    }
    if (type == true) {
      API.put(`cost/calculator/${id}/`, { lock: type }, 0).then((res: any) => {
        setSaveApiCallCount((prev: any) => prev + 1);
      });
    }
  };

  const getCalculatorColumns = (tableId: any) => {
    API.get(`/api/db/parameter/`, { table_id: tableId })
      .then((res: any) => {
        setBomCalculatorColumns(res.data);
      })
      .catch((err: any) => console.log(err.response)
      );
  }
  // ----------------------------------------------------------------------------------
  // side effects

  // fetch input for the caluclator on mount
  useEffect(() => {
    if (type === "calculator") {
      fetchInputs();
    }
  }, [type, id]);

  const calculatorList = (calculator_category: any, input: any) => {
    switch (calculator_category) {
      case 1:
        return materialOptions;
      case 2:
        return bopOptions;
      case 3:
        return labourOptions;
      case 4:
        return machineOptions;
      case 5:
        return toolOptions;
      case 6:
        return setUpOptions;

      default:
        break;
    }
  };

  const refreshInputs = () => {
    inputs.getCollection();
  }



  // ----------------------------------------------------------------------------------
  // jsx

  // TODO: substitute 1,2,3 w/ CALC_INPUT_MAP values

  const renderValueColumnText = (input: CalculatorInput) => {
    switch (input.type) {
      case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:
      case CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <Box
              sx={{
                width: "50%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "gray",
                },
              }}
            >
              {
                input?.parameter_name
              }
            </Box>

            {input.parameter &&
              isTableParameter(input.type, input.parameter) && (
                <Box
                  sx={{
                    width: "100%",
                    color: "gray",
                    fontSize: "1rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {
                    getdatafromtype(input)?.filter(
                      (item: any) => item.value == input.table_element
                    )[0]?.text
                  }
                </Box>
              )}
          </Box>
        );

      case CALCULATOR_INPUT_TYPE.PCB_BOM_TABLE:
        return (
          <RenderValueColumnItemPCBCalcView Lockstatus={Lockstatus}
            input={input}
            getCalculatorColumns={getCalculatorColumns}
            namesAndParams={namesAndParams}
          />
        )
      case CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR:
        return (<RenderValueColumnItemView
          input={input}
          getOptionData={getOptionData}
          getCalculatorColumns={getCalculatorColumns}
          bomCalculatorColumns={bomCalculatorColumns}
          isTableParameter={isTableParameter}
          getdatafromtype={getdatafromtype}
        />
        )
      case CALCULATOR_INPUT_TYPE.DATABASE:
        return (
          <Tooltip
            title={`${calculatorDatabaseOptions?.filter(
              (item: any) => item.value == input.database
            )[0]?.text || ''} - ${calculatorDatabaseColumnsOptions[input.database || 0]?.filter(
              (item: any) => item.value == input.attribute
            )[0]?.text || ""
              }`}
            arrow
          >
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
            >
              <Box
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "gray",
                  },
                }}
              >
                {
                  calculatorDatabaseOptions?.filter(
                    (item: any) => item.value == input.database
                  )[0]?.text
                }
              </Box>
              <Box
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  color: "gray",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "gray",
                  },
                }}
              >
                {
                  calculatorDatabaseColumnsOptions[input.database || 0]?.filter(
                    (item: any) => item.value == input.attribute
                  )[0]?.text
                }
              </Box>
            </Box>
          </Tooltip>
        );
      case CALCULATOR_INPUT_TYPE.CONSTANT:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              color: "gray",
            }}
          >
            <Box>{input.constant} <Box sx={{color:'gray'}}>
              {(input.is_currency && input.currency && `${input.currency}${input.user_input && "/"} `) ||
                ""}
              {input.user_input}
            </Box></Box>
            {/* <FormControl fullWidth>
			  <TextField
				  variant="standard"
				  name="user_input"
				  disabled={
					  (Lockstatus?.lock_status==false && useraction?.includes("U"))? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
				  }
				  placeholder="User Input Unit*"
				  value={input.user_input || ""}
				  onChange={handleChange(input.id)}
				  onBlur={()=>(Lockstatus?.lock_status==false && useraction?.includes("U")) && input.user_input!=null && input.constant!=null && input.user_input!="" && handleSave(input.id)}
				  onFocus={() => {
					setExpandInput(input);
					setExpand(false);
				  }}
				  InputProps={{
					style: { fontSize: "1rem" },
				  }}
				  sx={{
					"& .MuiInput-underline:before": {
					  borderBottomColor: "primary.light",
					},
					"& .MuiInput-underline:after": {
					  borderBottomColor: "primary.main",
					},
				  }}
				/>
				</FormControl> */}
          </Box>
        );
      case CALCULATOR_INPUT_TYPE.VOLUME:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              color: "gray",
            }}
          >
            {"Qty"}
          </Box>
        );
      case CALCULATOR_INPUT_TYPE.FORMULA:
        return (
          <Tooltip title={`${input.formula} ${input.user_input || ""}`} arrow>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
                color: "gray",
                maxWidth: { lg: '8rem', xl: '10rem' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            // title={`${input.formula} ${input.user_input || ""}`}
            >
              {input.formula}
              <Box sx={{color:'gray'}}>
              {(input.is_currency && input.currency && `${input.currency}${input.user_input && "/"} `) ||
                ""}
              {input.user_input}
            </Box>
            </Box>
          </Tooltip>
        );
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <>
            <Box sx={{color: "gray"}}>
            <Box>
              {(input.is_currency && input.currency && `${input.currency}${input.user_input && "/"} `) ||
                ""}
              {input.user_input}
            </Box>
            </Box>
          </>
        );

      case CALCULATOR_INPUT_TYPE.COST_INPUT:
        return (
          <FormControl fullWidth>
            <Box sx={{ color: "gray" }}>
              {
                calculatorCostInputOptions?.filter(
                  (item: any) => item.value == input.attribute
                )[0]?.text
              }
            </Box>
          </FormControl>
        );
      case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <FormControl fullWidth>
              <Box sx={{ color: "gray" }}>
                {/* @ts-ignore */}
                {
                  calculatorCategoryOptions[
                    //@ts-ignore
                    sessionStorage.getItem("calculator_type")
                  ]?.filter(
                    (item: any) => item.value == input.calculator_category
                  )[0]?.text
                }
              </Box>
            </FormControl>
            <FormControl fullWidth>
              {/* <Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					variant="standard"
					size="small"
					placeholder="Select Calculator"
					disabled={
					  (Lockstatus?.lock_status==false && useraction?.includes("U"))? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
					}
					value={input.attribute || ""}
					onChange={handleChange(input.id)}
					onBlur={()=>(Lockstatus?.lock_status==false && useraction?.includes("U"))&& input.attribute!=null && handleSave(input.id)}
					onFocus={() => {
					  setExpandInput(input);
					  setExpand(false);
					}}
					name="attribute"
					sx={{
					  width: "50%",
					  fontSize: "1rem",
					  "&:before": {
						borderBottomColor: "primary.light",
					  },
					  ".MuiSvgIcon-root.MuiSelect-icon": {
						color: "primary.main",
					  },
					}}
				  >
					  {
						  calculatorList(input.calculator_category,input)?.map((item: any) => {
							  return (
								<MenuItem sx={{ fontSize: "1rem" }} value={item.id}>
								  {item.name}
								</MenuItem>
							  );
							}
					  )}
				  
				  </Select> */} SS
              <Box sx={{ color: "gray" }}>
                {
                  calculatorList(input.calculator_category, input)?.filter(
                    (item: any) => item.id == input.attribute
                  )[0]?.name
                }
              </Box>
            </FormControl>
          </Box>
        );
      default:
        return null;
    }
  };
  //this code is for multiple image upload

  const browseFiles = (index: any) => {
    setCurrentSelected(index);
    return inputElRef.current?.click?.();
  };
  const handleImageView = (index: any) => {
    setCurrentImage(inputs.getCollection()[index].image_url);
    return imageViewerElRef.current?.click?.();
  };

  const handleCopyClick = async (alias: any, idd: any) => {
    setTextToCopy(`val.${alias}`);
    setActiveCopyId(idd);
    try {
      await navigator.clipboard.writeText(`val.${alias}`);

      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    setIsUploading(true);
    const data = new FormData();
    if (e.target.files?.length) {
      //@ts-ignore
      data.append(`image0`, e.currentTarget.files[0]);

      API.put(
        `cost/calculator_field_image_upload/${inputs.getCollection()[currentSelected]?.id
        }/`,
        data,
        {},
        0
      )
        .then((res: any) => {
          setIsUploading(false);
          //   ADMIN.toast.success("Data uploaded successfully");
          fetchInputs();
        })
        .catch((err: any) => {
          //   ADMIN.toast.error("Could not upload file");
          setIsUploading(false);
        });
    }
  };

  const handleFileInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again

    (e.target as HTMLInputElement).value = "";
  };

  // ----------------------------------------------------------------------------------
  // jsx

  if (["FALSE", "FETCHING"].includes(inputsFetched))
    return (
      <Box sx={{ width: "100%", padding: "0 1rem", top: 0 }}>
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
        <Skeleton animation="wave" sx={{ height: "2rem" }} />
      </Box>
    );

  return (
    <div data-testid="input-configuration" className={styles.container}>
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={Lockstatus}
          type={"Lockstatus"}
          rightClickedId={id && id}
        />
      )}
      {customValidationModal.isOpen && (

        <ConfigCustomValidation
          cost_config={customValidationModal.propsId?.cost_config}
          calc_field={customValidationModal.propsId?.calc_field}
          isOpen={customValidationModal.isOpen}
          onCloseModal={customValidationModal.close}
          input={customValidationModal.propsId?.input}
          refreshInputs={fetchInputs}
        />
      )}
      {errorMessageModel.isOpen && (
        <ErrorModalFilesMail
          onCloseModal={errorMessageModel.close}
          isOpen={errorMessageModel.isOpen}
          ErrorMsg={errorMessage}
        />
      )}
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 1,
            mr: 1,
            fontSize: '1rem',
            height: '2.2rem',
            marginTop: '-2.2rem',
            position: 'fixed',
            width: { lg: '22%', xl: '27%' },
            paddingLeft: { lg: '0', xl: '1rem' },
            lineHeight: 1.2,
          }}
        >

          <Typography title={selectedTreeData && selectedTreeData?.crumbs} sx={{ width: { lg: '22rem', xl: '35rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', } }}>{selectedTreeData && selectedTreeData?.crumbs}{" "}</Typography>
          <Box sx={{
            display: "flex",
            gap: '1rem',
            alignItems: "center",
            ml: 1,
            position: 'fixed',
            right: { lg: '8.5rem', xl: '8rem' },
          }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.2",
                justifyContent: "center",
                textDecoration: "underline",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "0.2rem",
              }}
              onClick={() => fetchInputsTestRes()}
            >
              Input
            </Typography>
            <OpenInNewIcon
              sx={{ cursor: "pointer", fontSize: '1.5rem' }}
              titleAccess="Go to Cost model"
              onClick={() => {
                window.open(
                  `/#/costingconfiguration/${projectId}/${projectname}/${id}/calculator`
                );
              }}
            />{" "}
            {(PermissionValue?.includes("D") && !categoryInfo?.lock) ? <DeleteIcon
              color="error"
              sx={{ cursor: "pointer", fontSize: '1.5rem', opacity: isDelete === "true" ? "1" : "0.3" }}
              titleAccess="Delete fetched cost model"
              onClick={() => {
                if (isDelete === "true") {
                  clearFetchedCostModels(selectedTreeData)
                }
              }}

            /> : <DeleteIcon

              sx={{ cursor: "default", color: "#ded7d7", fontSize: '1.5rem' }}
              titleAccess="Delete fetched cost model"

            />}
          </Box>

        </Box>
      </div>
      <div className={styles.tableWrapper}>
        {inputs.length === 0 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {Lockstatus?.lock_status == false && useraction?.includes("C") && (
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  height: "84vh",
                }}
              >
                <AddIcon
                  onClick={handleAdd}
                  sx={{ fontSize: "4rem", cursor: "pointer" }}
                />
                <Button disabled={Lockstatus?.lock_status} onClick={handleAdd}>
                  Create a Cost Model
                </Button>
              </Typography>
            )} */}
          </Box>
        )}
        {inputs.length > 0 && (
          <>
            <Box
              sx={{
                height:
                  expand === true
                    ? { lg: "52vh", xl: "64.5vh" }
                    : { lg: "82vh", xl: "85vh" },
              }}
            // adl-scrollbar="true"
            // adl-scrollbar-width="0.3"
            >
              <Box
                sx={{
                  maxHeight:
                    expand === true
                      ? { lg: "51.2vh", xl: "65vh" }
                      : { lg: "82vh", xl: "85vh" },
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >

                <Table stickyHeader aria-label="sticky table">

                  <TableBody>
                    {inputs.getCollection()?.map((input: any, index: any) => {




                      return (
                        <TableRow
                          key={input.id}
                          onClick={() => setEditableRow(input.id)}
                          sx={{
                            cursor: !categoryInfo?.lock ? "pointer" : "default",
                            backgroundColor:
                              expandInput &&
                                expandInput.id === input?.id &&
                                input.type === 5
                                ? "#c4efb3"
                                : calculatorInputBackgroundColorMapView[
                                input.type
                                ],
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {<Box sx={{ color: "gray", }}>{input.sequence}</Box>}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Tooltip title={input.name} arrow>
                              {<Box sx={{ color: "gray", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '5rem', xl: '9rem' } }}>{input.name}</Box>}
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {/* <Box sx={{ display: "flex", columnGap: "0.5rem" }}>
                {<Box> */}
                            {input.alias}
                            {/* </Box>} */}
                            {/* {getVisible(input) && (
                  <FileCopyRoundedIcon
                   
                  sx={{
                    cursor: "pointer",
                    opacity:Lockstatus?.lock_status==false ?"1":"0.3",
                    color:
                    isCopied && activeCopyId === input.id
                      ? "green"
                      : "",
                  }}
                  onClick={() =>
                    handleCopyClick(input.alias, input.id)
                  }
                  />
                )} */}
                            {/* </Box> */}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Tooltip title={
                              getData(calculatorInputOptions)?.filter(
                                (item: any) => item.value == input.type
                              )[0]?.text
                            }>
                              {
                                // :
                                <Box
                                  sx={{
                                    width: "100%",
                                    color: "gray",
                                    fontSize: "1rem",
                                    "&:before": {
                                      borderBottomColor: "primary.light",
                                    },
                                    ".MuiSvgIcon-root.MuiSelect-icon": {
                                      color: "gray",
                                    },
                                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '5rem', xl: '7rem' },
                                  }}
                                >
                                  {
                                    getData(calculatorInputOptions)?.filter(
                                      (item: any) => item.value == input.type
                                    )[0]?.text
                                  }
                                </Box>
                              }
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {
                              <Box sx={{ color: "gray", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '6rem', xl: '9rem' } }}>
                                {renderValueColumnText(input)}
                              </Box>
                            }
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'space-between',
                              }}
                            >
                              {(input.type === 6 || input.type === 5) && (
                                <IconButton
                                  sx={{ padding: '0', cursor: "pointer", color: "primary.main", }}
                                  title="Add Custom validation"
                                  onClick={() => {
                                    customValidationModal.open({
                                      cost_config: config_id,
                                      input: input,
                                      calc_field: input.id
                                    })
                                  }}

                                >
                                  <AddCircleIcon sx={{ fontSize: '1.3rem', color: input?.custom_validation_exists ? "#f28b46" : "primary" }} />
                                </IconButton>
                              )}
                              <LoadingButton
                                sx={{
                                  minWidth: "0.5rem",
                                  maxWidth: "1.5rem",
                                  margin: "0 0.3rem",
                                  padding: "0.2rem",
                                  ".MuiCircularProgress-root": {
                                    color: "primary.main",
                                  },
                                }}
                                onClick={() => {
                                  if (PermissionValue?.includes("R")) {
                                    handleImageView(index)
                                  }
                                }}
                                disabled={input.image_url ? false : true}
                              >
                                <CameraAltIcon
                                  sx={
                                    !input.image_url
                                      ? { cursor: "not-allowed", fontSize: '1.3rem' }
                                      : { cursor: "pointer", fontSize: '1.3rem' }
                                  }
                                  titleAccess="View Image"
                                />

                              </LoadingButton>
                              {configMirror !== "true" && input?.formula && (
                                bucketLoading ?
                                  <>
                                    {(!categoryInfo?.lock && PermissionValue?.includes("U")) ? <img
                                      src={AddBucket}
                                      style={{ opacity: "0.5", color: '#007fff' }}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}

                                    /> : <img
                                      src={AddBucket}
                                      style={{ opacity: "0.4", filter: 'grayscale(100%)' }}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}
                                    />}
                                  </>
                                  : <>
                                    {(!categoryInfo?.lock && PermissionValue?.includes("U")) ? <img
                                      src={AddBucket}
                                      style={{ opacity: input?.drop ? "0.5" : "1", color: '#007fff' }}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}
                                      onClick={() => {
                                        if (input?.drop !== true) {
                                          addToBucket(input.id);
                                        } else {
                                        }
                                      }}
                                    /> : <img
                                      src={AddBucket}
                                      style={{ opacity: "0.4", filter: 'grayscale(100%)' }}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}
                                    />}
                                  </>
                              )}
                              {configMirror !== "true" &&
                                input?.type === 6 &&
                                input?.fetch !== true && (
                                  <>
                                    {!categoryInfo?.lock ? <img
                                      src={FetchBucket}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}
                                      onClick={() =>
                                        fetchBucketDataOpen.open(input.id)
                                      }
                                    /> : <img
                                      src={FetchBucket}
                                      width={width > 1380 ? 18 : 16}
                                      height={width > 1380 ? 18 : 16}
                                      style={{ opacity: "0.4", filter: 'grayscale(100%)' }}
                                    />}
                                  </>
                                )}
                              {input.fetch && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    paddingLeft: '0.5rem',
                                  }}
                                >
                                  <InfoIcon
                                    sx={{ cursor: "pointer", color: categoryInfo?.lock ? "grey" : "inherit" }}
                                    titleAccess="Info of fetched data from Reusable Parameters"
                                    onClick={() => {
                                      if (!categoryInfo?.lock) {
                                        openFetchedBucketData.open(input.id);
                                      }
                                    }}
                                  />

                                  <img
                                    src={closeBucket}
                                    style={{ cursor: categoryInfo?.lock || !PermissionValue?.includes("D") ? "default " : 'pointer', opacity: categoryInfo?.lock || !PermissionValue?.includes("D") ? "0.4" : "", filter: categoryInfo?.lock || !PermissionValue?.includes("D") ? 'grayscale(100%)' : '' }}
                                    title="Remove fetched bucket parameter"
                                    alt="Remove parameter"
                                    width={width > 1380 ? 18 : 16}
                                    height={width > 1380 ? 18 : 16}
                                    onClick={() => {
                                      if (!categoryInfo?.lock && PermissionValue?.includes("D")) {
                                        //have to ask confirmation before delete Swal alert
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "You want to delete this fetched parameter?",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes",
                                          cancelButtonText: "No",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            API.delete(
                                              `/config/configuration_fetch_bucket/`,
                                              {
                                                config_id: config_id,
                                                field_id: input.id,
                                              },
                                              0
                                            )
                                              .then((res: any) => {
                                                ADMIN.toast.info(
                                                  "Bucket Parameter removed successfully"
                                                );
                                                getBucketList();
                                                fetchInputsWOL();
                                              })
                                              .catch((err: any) => { });
                                          }
                                        });
                                      }

                                    }}
                                  />
                                </Box>
                              )}

                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.2rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: input?.formula
                                ? "#d9ffe0"
                                : "#fff",
                            }}
                          >

                            {editableRow == input.id ? (
                              <TextField
                                variant="standard"
                                name={(input?.formula || input.fetch) ? "result" : "test_input"}
                                placeholder="Enter Value"
                                type="number"
                                inputProps={{
                                  step: "any",
                                  min: 0,
                                  style: {
                                    fontSize: "1rem",
                                    color: "primary.main",
                                    textAlign: "center",
                                  },
                                }}
                                value={
                                  (input?.formula || input.fetch)
                                    ? +input.result?.toFixed(2)
                                    : +input.test_input
                                }
                                onChange={handleChange(input.id)}
                                disabled={
                                  (input.formula == null && input?.fetch === false
                                    //  && !categoryInfo?.lock
                                  )
                                    ? false
                                    : true
                                }
                                sx={{
                                  // width: "100%",
                                  width: '5rem',
                                  minWidth: '5rem',
                                  maxWidth: '5rem',
                                  "& .MuiInputBase-input.MuiInput-input": {
                                    fontSize: "1rem",
                                    paddingLeft: "0.5rem",
                                    // backgroundColor: "white",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  color: input.formula ? "black" : "primary.main",

                                  backgroundColor: input?.formula
                                    ? "#d9ffe0"
                                    : "#fff",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  // width: "100%",
                                  width: '5rem',
                                  minWidth: '5rem',
                                  maxWidth: '5rem',
                                  textAlign: "center",
                                  "& .MuiInputBase-input.MuiInput-input": {
                                    fontSize: "1rem",
                                    paddingLeft: "0.5rem",
                                    backgroundColor: "white",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  color: input.formula ? "black" : "primary.main",

                                  backgroundColor: input?.formula
                                    ? "#d9ffe0"
                                    : "#fff",
                                }}
                              >
                                {(input?.formula || input.fetch)
                                  ? (input.result ? (parseFloat(input.result)?.toFixed(2) || 0) : 0)
                                  : (+input.test_input || 0)}
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
              {/* {Lockstatus?.lock_status == false &&
                useraction?.includes("C") && (
                  <Box sx={{ position: "sticky", bottom: "0", width: "100%" }}>
                    <Button
                      title="Add Field"
                      onClick={handleAdd}
                      size="small"
                      sx={{ width: "100%" }}
                    >
                      <Box sx={styleDivider} />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "0.4rem",
                          whiteSpace: "nowrap",
                          margin: "0 1rem",
                          backgroundColor: "primary.light",
                          padding: "0.2rem 1rem 0.2rem 0.5rem",
                          borderRadius: "3px",
                        }}
                      >
                        <AddIcon />
                        Add Field
                      </Box>
                      <Box sx={styleDivider} />
                    </Button>
                  </Box>
                )} */}
            </Box>
            <Box>
              <Box>
                {/* formula */}
                {expand && (
                  <>
                    <Box
                      sx={{
                        // border: "1px solid #007fff",
                        borderRadius: "5px",
                        padding: "0.5rem 1rem 0",
                        backgroundColor: "primary.light",
                        marginBottom: "0.1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            whiteSpace: "nowrap",
                            fontWeight: "500",
                          }}
                        >
                          {expandInput?.sequence}. {expandInput?.name} Formula :{" "}
                        </Typography>
                        <Box>
                          {expand && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "1rem",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                title="Formula Fusion: Multi-Operation Showcase"
                                sx={{ padding: "0" }}
                                aria-describedby={id}
                                onClick={handleClick}
                              >
                                <InfoIcon sx={{ color: "primary.main" }} />
                              </IconButton>
                              <Popover
                                id={poid}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                disableRestoreFocus
                                sx={{
                                  ".MuiPaper-root.MuiPopover-paper": {
                                    borderRadius: "1rem",
                                    //   backgroundColor:'primary.light'
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    width: "100%",
                                    textAlign: "center",
                                    fontSize: "1rem",
                                    padding: "0.5rem",
                                    fontWeight: "600",
                                    backgroundColor: "#ddefff",
                                  }}
                                >
                                  Formula Fusion: Multi-Operation Showcase
                                </Typography>
                                <Divider
                                  sx={{ borderColor: "primary.light" }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    columnGap: "0.5rem",
                                    padding: "0.5rem",
                                  }}
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          1
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a + val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Addition
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          2
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a - val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Subtraction
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          3
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a * val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Multiplication
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          4
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a / val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Division
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          5
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a ** val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Exponentiation
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          6
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a % val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Modulo
                                        </TableCell>
                                      </TableRow>
                                      {/* <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          7
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a and val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          AND
                                        </TableCell>
                                      </TableRow> */}
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          8
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a or val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          OR (first nonzero value will be shown)
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          9
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          not val.a
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          NOT
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          10
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a == val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Equal
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          11
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a != val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Not equal
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          12
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a {">"} val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Greater than
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          13
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a {"<"} val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Less than
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  <Divider
                                    sx={{ borderColor: "primary.light" }}
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                  />
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          14
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a {">"}= val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Greater than or equal to
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          15
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a {"<"}= val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Less than or equal to
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          16
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          val.a + val.b if val.a {">"} val.b
                                          else val.a - val.b
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          If and else condition
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          17
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          min(val.a, val.b, val.c, val.d)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          min
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          18
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          max(val.a, val.b, val.c, val.d)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          max
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          19
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          round(val.a, 2)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          round
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          20
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.floor(val.a)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          rounded down
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          21
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.ceil(val.a)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          rounded up
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          22
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.sqrt(val.a)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Square root
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          23
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.sin(val.a)<sup style={{ color: 'red' }}>**</sup>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Sine function
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          24
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.cos(val.a)<sup style={{ color: 'red' }}>**</sup>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Cosine function
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          25
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#fffaea",
                                          }}
                                        >
                                          math.log(val.a,10)
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Natural logarithm
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          colSpan={3}
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            borderBottomColor: "primary.light",
                                            whiteSpace: "nowrap",
                                            backgroundColor: "#edfbee",
                                          }}
                                        >
                                          Note:  <sup style={{ color: 'red' }}>**</sup> The values entered should be in radians.
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Popover>
                              {formulaCheckedStatus ? (
                                <FactCheckIcon
                                  sx={{
                                    cursor: !categoryInfo?.lock ? "pointer" : "default",
                                    color: "green",
                                  }}
                                  titleAccess="Check formula"
                                  onClick={() => {
                                    if (!categoryInfo?.lock) {
                                      correctFormula(formulaTextArea);
                                      setFormulaCheckedStatus(true);
                                    }
                                  }}
                                />
                              ) : (
                                <FactCheckOutlinedIcon
                                  sx={{
                                    cursor: !categoryInfo?.lock ? "pointer" : "default",
                                    // color:  "green" : "",
                                  }}
                                  titleAccess="Check formula"
                                  onClick={() => {
                                    if (!categoryInfo?.lock) {
                                      correctFormula(formulaTextArea);
                                      setFormulaCheckedStatus(true);
                                    }
                                  }}
                                />
                              )}
                              <SaveIcon
                                titleAccess="Save Formula"
                                sx={{ color: color, cursor: !categoryInfo?.lock ? "pointer" : "default", }}
                                onClick={() => {
                                  if (!categoryInfo?.lock) {
                                    handleSave(expandInput.id)
                                  }
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <TextareaAutosize
                        aria-label="minimum height"
                        name="formula"
                        // value={input.formula || ""}

                        disabled={
                          Lockstatus?.lock_status == false && categoryInfo?.lock === false &&
                            useraction?.includes("U")
                            ? false
                            : true
                        }
                        onChange={handleChange(expandInput?.id)} // Set the minimum number of rows
                        placeholder="Enter your formula"
                        value={formulaTextArea || ""}
                        minRows={5}
                        style={{
                          color: "#007fff",
                          width: "100%",
                          border: "1px solid #ddefff",
                          borderRadius: "5px",
                          padding: "0.5rem 1rem 0",
                        }} // Set the width
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Table
                sx={{
                  backgroundColor: "primary.light",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1,
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "25rem",
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      {calcName} Result
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      <TextField
                        variant="standard"
                        size="small"
                        disabled={
                          Lockstatus?.lock_status == false && categoryInfo?.lock === false &&
                            useraction?.includes("U")
                            ? false
                            : true
                        }
                        name="result"
                        placeholder="Formula"
                        value={calculator?.result || ""}
                        onChange={handleResultChange}
                        inputProps={{
                          style: {
                            fontSize: "1rem",
                          },
                        }}
                        sx={{
                          backgroundColor: "white",
                          "& .MuiInputBase-input.MuiInput-input": {
                            fontSize: "1rem",
                            paddingLeft: "0.5rem",
                            paddingBottom: "0",
                            height: "2rem",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {/* <LoadingButton
						disabled={Lockstatus?.lock_status}
						  sx={{
							minWidth: "0.5rem",
							maxWidth: "1.5rem",
							margin: "0 0.3rem",
							padding: "0",
							color:resultInputColor
						  }}
						  onClick={updateResult}
						>
						  <SaveIcon titleAccess="save" />
						</LoadingButton> */}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        <div style={{ display: "none" }}>
          {/* @ts-ignore */}
          <RViewerJS>
            <img src={currentImage} alt="image" ref={imageViewerElRef} />
          </RViewerJS>
        </div>
      </div>
      {fetchBucketDataOpen.isOpen && (
        <FetchBucketDataModal
          isOpen={fetchBucketDataOpen.isOpen}
          onCloseModal={fetchBucketDataOpen.close}
          fieldId={fetchBucketDataOpen.propsId}
          fetchInputsWOL={fetchInputsWOL}
          getBucketList={getBucketList}
          setCounterApiCall={setCounterApiCall}
        />
      )}
      {openFetchedBucketData.isOpen && (
        <FetchParameterInfoModal
          isOpen={openFetchedBucketData.isOpen}
          onCloseModal={openFetchedBucketData.close}
          fieldId={openFetchedBucketData.propsId}
        />
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default CreateCostCalculatorView;
