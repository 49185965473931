import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { Box, Skeleton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { BearingsColumns, ConnectorsColumns, ConsumablesColumns, ElectronicChildColumns, FastenersCols, MEPartsColumns, OthersColumns } from "../../../utlis/BoughtOutPart.constant";
import { BulbColumns, FlasherModules, HornModules, IgnitionMOdules, RemoteKeys } from "../../../utlis/BoughtOutElectricalParts.constant"; 
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { API } from "../../../api-services";

const csvOptions: any = {
  fileName: "Raw Material DataBase CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: any) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      width: "100%",
      height: "2.3rem",
      justifyContent: "flex-end",
      paddingRight: "1rem",
      // marginBottom: '0.5rem',
      marginTop: "-2.4rem",
    }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

function BOPDB({ database, selected, setSelected }: any) {
  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [tabValue, setTabValue] = useState<any>("Bearings");
  const [tableData, setTableData] = React.useState<any>([]);
   const [electricalCategories, setElectricalCategories] = React.useState<any>([]);
  const [electricalTableData, setElectricalTableData] = React.useState<any>([]);
  const [filteredFields, setFilteredFields] = React.useState<any>([]);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [selectedModule, setSelectedModule] = React.useState('Remote Key/Lock parameter');

  const [subElectricalLoadings, setSubElectricalLoadings] = React.useState<any>(false);
    const [expandedElectrical, setExpandedElectrical] = React.useState<string | false>(false);


   const getCategoryData = () => {
      setSubElectricalLoadings(true);
      API.get(
        "/api/db/bought_out/",
        {
          commodity: tabValue,
          module_type: expandedElectrical,
        },
        0
      )
        .then((res: any) => {
          setElectricalTableData(res.data);
          setSubElectricalLoadings(false);
        })
        .catch((err: any) => {
          setSubElectricalLoadings(false);
        });
    };

  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    API.get("/api/db/bought_out/", { commodity: category }, 0)
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };



  const handleChange = (categoryName: any) => {
    setTabValue(categoryName);
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
      setTabValue(res.data?.[0]?.name);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  let rows: any = [];
  const getRowDataElectrical = (category: any) => {
    switch (category) {
      case "Bulb":
        let rowsBulb: any = [];
        let connectors: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsBulb.push(objShared);
          });

        let dataStructureDataGridBulb: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsBulb && rowsBulb,
        };
        return dataStructureDataGridBulb && dataStructureDataGridBulb;

      case "Remote Key/Lock":
        let rowsRemoteKey: any = [];
        let remoteKey: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              sensing_technology: item?.sensing_technology,
              type_of_key_fob: item?.type_of_key_fob,
              no_of_function: item?.no_of_function,
              function: item?.function,
              range: item?.range,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsRemoteKey.push(objShared);
          });

        let dataStructureDataGridRemoteKey: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsRemoteKey && rowsRemoteKey,
        };
        return dataStructureDataGridRemoteKey && dataStructureDataGridRemoteKey;

      case "Horn":
        let rowsHorn: any = [];
        let horn: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              current_rating: item?.current_rating,
              power_rating: item?.power_rating,
              frequency: item?.frequency,
              sound_level: item?.sound_level,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsHorn.push(objShared);
          });

        let dataStructureDataGridHorn: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsHorn && rowsHorn,
        };
        return dataStructureDataGridHorn && dataStructureDataGridHorn;

      case "Ignition":
        let rowsIgnition: any = [];
        let Ignition: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              no_of_position: item?.no_of_position,
              no_of_terminal: item?.no_of_terminal,
              with_handle_lock: item?.with_handle_lock,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsIgnition.push(objShared);
          });

        let dataStructureDataGridIgnition: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsIgnition && rowsIgnition,
        };
        return dataStructureDataGridIgnition && dataStructureDataGridIgnition;

      case "Flasher":
        let rowsFlasher: any = [];
        let Flasher: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              frequency: item?.frequency,
              no_of_terminal: item?.no_of_terminal,
              watttage: item?.watttage,
              adjustable: item?.adjustable,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsFlasher.push(objShared);
          });

        let dataStructureDataGridFlasher: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsFlasher && rowsFlasher,
        };
        return dataStructureDataGridFlasher && dataStructureDataGridFlasher;
    }
  };

  const getColumnDataElectrical = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };
    switch (category) {
      case "Bulb":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...BulbColumns,
          newColumn
        ];
      case "Remote Key/Lock":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...RemoteKeys,
          newColumn
        ];

      case "Horn":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...HornModules,
          newColumn
        ];

      case "Ignition":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...IgnitionMOdules,
          newColumn
        ];

      case "Flasher":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...FlasherModules,
          newColumn
        ];
      default:
        return [];
    }
  };

    const getElectricalCategories = () => {
      API.get(
        "/api/db/bought_out/",
        {
          commodity: tabValue,
          module_count: true,
        },
        0
      )
        .then((res: any) => {
          setElectricalCategories(res.data);
          if (res.data.length > 0) {
            setSelectedModule(res.data[0]?.module_type);
            // handleChangeElectrical(res.data[0]?.module_type);
          }
        })
        .catch((err: any) => { });
    }

    const handleChangeElectrical = (selectedModule: any) => {
  
      setSelectedModule(selectedModule);
      if (selectedModule) {
        setElectricalTableData([]);
        setSubElectricalLoadings(true);
  
        API.get(
          "/api/db/bought_out/",
          {
            commodity: tabValue,
            module_type: selectedModule,
            revision: undefined,
          },
          0
        )
          .then((res: any) => {
            setElectricalTableData(res.data);
            setSubElectricalLoadings(false);
          })
          .catch((err: any) => {
            setSubElectricalLoadings(false);
          });
      }
  
    };



  const getColumnData = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };
    switch (category) {
      case "Connectors":
        return [
         {
                    field: "actions",
                    headerName: "Select",
                    width: 100,
                    renderCell: (cellValues: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            columnGap: "1rem",
                          }}
                        >
                          <Checkbox
                            checked={selected?.id == cellValues?.row?.id}
                            onChange={() => setSelected(cellValues?.row)}
                          />
                        </Box>
                      );
                    },
                  },
          ...ConnectorsColumns,
          newColumn
        ];
      case "Bearings":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...BearingsColumns,
          newColumn
        ];
      case "Consumables":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...ConsumablesColumns,
          newColumn
        ];
      case "Electronic Child Components":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...ElectronicChildColumns,
          newColumn
        ];
      case "Mechanical Proprietory Parts":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...MEPartsColumns,
          newColumn
        ];

      case "Fasteners":
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...FastenersCols,
          newColumn
        ];
      default:
        return [
          {
            field: "actions",
            headerName: "Select",
            width: 100,
            renderCell: (cellValues: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Checkbox
                    checked={selected?.id == cellValues?.row?.id}
                    onChange={() => setSelected(cellValues?.row)}
                  />
                </Box>
              );
            },
          },
          ...OthersColumns,
          newColumn
        ];
    }
  };

  const getRowData = (category: any) => {
    switch (category) {
      case "Connectors":
        let rowsConnectors: any = [];
        let connectors: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              no_of_pins: item?.no_of_pins,
              postion_config: item?.postion_config,
              fit_type: item?.fit_type,
              pin_pitch: item?.pin_pitch,
              shape: item?.shape,
              connection_type: item?.connection_type,
              gender: item?.gender,
              material: item?.material,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsConnectors.push(objShared);
          });

        let dataStructureDataGridConnectors: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsConnectors && rowsConnectors,
        };
        return dataStructureDataGridConnectors && dataStructureDataGridConnectors;

      case "Bearings":
        let rowsBearings: any = [];
        let dataGridDataGeneratorBearings: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              height: item?.height,
              weight: item?.weight,
              item_id: item?.item_id,
              item_od: item?.item_od,
              bearing_code: item?.bearing_code,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsBearings.push(objShared);
          });

        let dataStructureDataGridBearings: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsBearings && rowsBearings,
        };
        return dataStructureDataGridBearings && dataStructureDataGridBearings;

      case "Consumables":
        let rowsConsumables: any = [];
        let dataGridDataGeneratorConsumables: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              description: item?.description,
              unit: item?.unit,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              lock: item?.lock,
            };
            rowsConsumables.push(objShared);
          });

        let dataStructureDataGridConsumables: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsConsumables && rowsConsumables,
        };
        return dataStructureDataGridConsumables && dataStructureDataGridConsumables;

      case "Electronic Child Components":
        let rowsElectronicsChild: any = [];
        let dataGridDataGeneratorElectricChild: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              unit: item?.unit,
              component_type: item?.component_type,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              lock: item?.lock,
            };
            rowsElectronicsChild.push(objShared);
          });

        let dataStructureDataGridElectricalChild: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsElectronicsChild && rowsElectronicsChild,
        };
        return dataStructureDataGridElectricalChild && dataStructureDataGridElectricalChild;

      case "Mechanical Proprietory Parts":
        let rowsMEParts: any = [];
        let dataGridDataGeneratorMEParts: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              brand_name: item?.brand_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsMEParts.push(objShared);
          });

        let dataStructureDataGridMEParts: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsMEParts && rowsMEParts,
        };
        return dataStructureDataGridMEParts && dataStructureDataGridMEParts;
      case "Uncategorized":
        let rowsOthers: any = [];
        let dataGridDataGeneratorOthers: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              item_detail: item?.item_detail,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              place: item.place,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowsOthers.push(objShared);
          });

        let dataStructureDataGridOthers: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowsOthers && rowsOthers,
        };
        return dataStructureDataGridOthers && dataStructureDataGridOthers;

      case "Fasteners":
        let rowFastners: any = [];
        let dataGridDataGeneratorFastner: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              type: item?.type,
              grade: item?.grade,
              material: item?.material,
              m_size: item?.m_size,
              length: item?.length,
              thread_length: item?.thread_length,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              place: item.place,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock: item?.lock,
            };
            rowFastners.push(objShared);
          });

        let dataStructureDataGridFastners: any = {
          columns: getColumnData(tabValue) && getColumnData(tabValue),
          rows: rowFastners && rowFastners,
        };
        return dataStructureDataGridFastners && dataStructureDataGridFastners;

      default:
        return [];
    }
  };
  const columns: any = [
    {
      field: "actions",
      headerName: "Select",
      width: width > 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            <Checkbox
              checked={selected?.id == cellValues?.row?.id}
              onChange={() => setSelected(cellValues?.row)}
            />
          </Box>
        );
      },
    },
    // {
    // 	field: 'sno',
    // 	headerName: 'S.No',
    // 	width: 30,
    // },
    {
      field: "material_code",
      headerName: "Material Code",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "material_grade",
      headerName: "Grade",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "material_subgrade",
      headerName: "Sub Grade",
      type: "number",
      minWidth: 70,
    },
    // {
    // 	field: 'shape',
    // 	headerName: 'Initial Shape',

    // 	sortable: false,
    // 	width: width > 1370 ? 100 : 80,
    // },
    {
      field: "density",
      headerName: "Density (kg/m3)",
      sortable: false,
      minWidth: width > 1370 ? 150 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Density (kg/m3): ${cellValues?.row?.density}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.density ? `${cellValues?.row?.density?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    // {
    // 	field: 'description',
    // 	headerName: 'Remarks',

    // 	sortable: false,
    // 	minWidth: width > 1370 ? 180 : width < 1200 ? 150 : 160,
    // },
    {
      field: "material_cost",
      headerName: "Material Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      renderCell: (cellValues: any) => {
      
        return (
          <Box
            title={`Material cost: ${cellValues?.row?.material_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.material_cost ? `${cellValues?.row?.material_cost?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    {
      field: "currency_code",
      headerName: "Currency/Unit",
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${
              cellValues?.row?.currency_code
                ? cellValues?.row?.currency_code
                : "--"
            }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    // {
    // 	field: 'material_source',
    // 	headerName: 'Material Source',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    // },

    // {
    // 	field: 'material_reference',
    // 	headerName: 'Material Ref',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 100 : width < 1200 ? 100 : 100,
    // 	renderCell: (cellValues: any) => {
    // 		return (
    // 			<>
    // 				{isUrl(cellValues?.row?.material_reference) ? (
    // 					<span
    // 						style={{ textDecoration: 'underline', cursor: 'pointer' }}
    // 						onClick={() =>
    // 							handleOpenLinkReference(cellValues?.row?.material_reference)
    // 						}>
    // 						Link
    // 					</span>
    // 				) : (
    // 					<span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
    // 						{cellValues?.row?.material_reference}
    // 					</span>
    // 				)}
    // 			</>
    // 		);
    // 	},
    // },
    {
      field: "scrap_cost",
      headerName: "Scrap Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Scrap Cost: ${cellValues?.row?.scrap_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {cellValues?.row?.scrap_cost ? `${cellValues?.row?.scrap_cost?.toFixed(3)}` : ""}
          </Box>
        );
      },
    },
    // {
    // 	field: 'scrap_source',
    // 	headerName: 'Scrap Source',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
    // },
    // {
    // 	field: 'scrap_reference',
    // 	headerName: 'Scrap Ref',

    // 	sortable: false,
    // 	width: width > 1370 ? 100 : width < 900 ? 100 : 100,
    // 	renderCell: (cellValues: any) => {
    // 		return (
    // 			<>
    // 				{isUrl(cellValues?.row?.scrap_reference) ? (
    // 					<span
    // 						style={{ textDecoration: 'underline', cursor: 'pointer' }}
    // 						onClick={() =>
    // 							handleOpenLinkReference(cellValues?.row?.scrap_reference)
    // 						}>
    // 						Link
    // 					</span>
    // 				) : (
    // 					<span
    // 					// style={{ textDecoration: "underline", cursor: "pointer" }}
    // 					// onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}
    // 					>
    // 						{cellValues?.row?.scrap_reference}
    // 					</span>
    // 				)}
    // 			</>
    // 		);
    // 	},
    // },
    // {
    // 	field: 'created_at',
    // 	headerName: 'Date Added',

    // 	sortable: false,
    // 	minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
    // },
  ];

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows && rows,
  };

  useEffect(() => {
    getCategoryList(database);
  }, [database]);

  useEffect(()=> {
if (tabValue == "electrical & electronics proprietary parts" && selectedModule) {
  handleChangeElectrical(selectedModule)
}
  },[])

    // React.useEffect(() => {
    //   if (tabValue === "Electrical & Electronics Proprietary Parts") {
    //     getElectricalCategories()
    //   }
    // }, [tabValue]);

  useEffect(() => {
    getMaterialData(tabValue);
  }, [tabValue]);

    React.useEffect(() => {
      if (tabValue === "Electrical & Electronics Proprietary Parts") {
        getElectricalCategories()
      }
    }, [tabValue]);
    
  //   console.log(selectedModule, "parameter");
  //  console.log(getRowDataElectrical(selectedModule), "parameter");
  //  console.log(getRowData(tabValue), "parameter");

  return (
    <>
      <Box sx={{ display: "flex", gap: "2%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            columnGap: "0.5rem",
            width: "24%",
borderLeft:"0.1px solid #edf5ef",
borderRight:"0.1px solid #edf5ef",
            marginLeft: "1rem",
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.2"
        >
          {accordionLoading ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
          ) : (
            <>
              {categoryList &&
                categoryList?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          padding:"0.2rem 0.5rem",
                          color: "primary.main",
                          // height: "2rem",
                          // minHeight: "2.1rem",
                          maxWidth: "100% !important",
                          
                          fontSize: "1rem",
                          width: "100%",
                          backgroundColor:
                            item?.name == tabValue ? "primary.main" : "",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "0.1px solid #edf5ef",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              item?.name == tabValue
                                ? "white"
                                : "primary.main",
                            // height: "2rem",
                            // minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0.1rem 0rem",
                            fontSize: "1rem",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            whiteSpace: "nowrap",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleChange(item?.name)}
                        >
                          {item?.name?.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                    </>
                  );
                })}
            </>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            height: { lg: "84vh", xl: "85vh" },
            width: "100%",
            "& .super-app-theme--cell": {
              backgroundColor: "#ddefff !important",
              textAlign: "center !important",
            },
          }}
        >
          {tabValue !== "Electrical & Electronics Proprietary Parts" && <DataGrid
                            headerHeight={42}
                            rowHeight={36}
                            {...getRowData(tabValue)}
                            loading={tableDataLoading}
                            // checkboxSelection
                            // disableRowSelectionOnClick
                            // components={{
                            //   Toolbar: () => {
                            //     return CustomToolbar();
                            //   },
                            // }}
                            sx={{
                              "& ::-webkit-scrollbar": {
                                width: "0.3rem",
                                height: "0.3rem",
                              },
                              "& ::-webkit-scrollbar-thumb": {
                                // background: $thumb-color;
                                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                borderRadius: "10px",
                              },
                              "& ::- webkit-scrollbar-thumb: hover ": {
                                // width: "4px",
                                backgroundColor: "#045DE9 !important",
                              },
                              "::-webkit-scrollbar-track": {
                                backgroundColor: "#e1e1f3 !important",
                              },
          
                              "&.MuiDataGrid-root": {
                                border: "none",
                              },
          
                              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                borderBottomColor: "primary.light",
                              },
                            }}
                          />}


          

           {tabValue == "Electrical & Electronics Proprietary Parts" && (
<>
<Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '0.5rem',
                            width: '100%',
                            borderBottom: '1px solid',
                          }} adl-scrollbar='true'
                            adl-scrollbar-width='0.2'>
                            {electricalCategories && tabValue &&
                              electricalCategories?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <Box
                                      key={index}
                                      sx={{
                                        color: '#007fff',
                                        height: '2rem',
                                        minHeight: '2.1rem',
                                        maxWidth: '100% !important',
                                        padding: '0',
                                        fontSize: '1rem',
                                        width: '100%',
                                        backgroundColor:
                                          item?.module_type == selectedModule
                                            ? 'primary.main'
                                            : 'primary.light',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        zIndex: 1,
                                      }}>
                                      <Typography
                                        sx={{
                                          color:
                                            item?.module_type == selectedModule ? 'white' : '#007fff',
                                          height: '2rem',
                                          minHeight: '2.1rem',
                                          maxWidth: '100% !important',
                                          padding: '0 0.5rem',
                                          fontSize: '1rem',
                                          width: '100%',
                                          textAlign: 'center',
                                          display: 'flex',
                                          whiteSpace: 'nowrap',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          textTransform: 'capitalize',
                                        }}
                                        onClick={() => handleChangeElectrical(item?.module_type)}>
                                        {item?.module_type.toLowerCase()} ({item?.count})
                                      </Typography>
                                    </Box>
                                  </>
                                );
                              })}
                          </Box>
{selectedModule && getColumnDataElectrical(selectedModule)?.length > 0 && (
                                <DataGrid
                                  headerHeight={42}
                                  rowHeight={36}
                                  {...getRowDataElectrical(selectedModule)}
                                  loading={subElectricalLoadings}
                                  // checkboxSelection
                                  // disableRowSelectionOnClick
                                  // components={{
                                  //   Toolbar: () => {
                                  //     return CustomToolbarElectrical();
                                  //   },
                                  // }}
                                  sx={{
                                    "& ::-webkit-scrollbar": {
                                      width: "0.3rem",
                                      height: "0.3rem",
                                    },
                                    "& ::-webkit-scrollbar-thumb": {
                                      // background: $thumb-color;
                                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                      borderRadius: "10px",
                                    },
                                    "& ::- webkit-scrollbar-thumb: hover ": {
                                      // width: "4px",
                                      backgroundColor: "#045DE9 !important",
                                    },
                                    "::-webkit-scrollbar-track": {
                                      backgroundColor: "#e1e1f3 !important",
                                    },
                                    "&.MuiDataGrid-root": {
                                      border: "none",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      borderBottomColor: "primary.light",
                                    },
                                  }}
                                />
                              )}
</>
           ) }
           

        
        </Box>
      </Box>
    
    </>
  );
}

export default BOPDB;
