import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import success from "../../Assets/images/success.png";
import { debounce } from "lodash";

export interface ProjectDirectoryModalProps {
  isOpen: any;
  onCloseModal: any;
  type: any;
  setCounter: any;
  projectIds: any;
  FormType: any;
  ProjectData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "40%", xl: "35%" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const ProjectDirectoryModal = (props: ProjectDirectoryModalProps) => {
  const {
    isOpen,
    onCloseModal,
    type,
    setCounter,
    projectIds,
    FormType,
    ProjectData,
  } = props;

  const [Name, setName] = useState<any>("");
  const [onTypingText, setOnTypingText] = useState<any>("");
  const [increaseCount, setIncreaseCount] = React.useState<any>(0);
  const [currencyCode, setCurrencyCode] = React.useState<any>("INR");
  const [Description, setDescription] = useState<any>("");
  const [namemsg, setnamemsg] = useState<any>(false);
  const [desmsg, setdesmsg] = useState<any>(false);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryList, setCountryList] = React.useState<any[]>([]);

  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});
  const [Successmsg, setSuccessmsg] = useState<any>(false);

  const handelChange = (e: any, type: any) => {
    if (type == "name") {
      setnamemsg(false);
      setName(e.target.value);
    } else if (type == "discription") {
      setdesmsg(false);
      setDescription(e.target.value);
    } else if (type == "currency") {
      setCurrencyCode(e.target.value);
    }
  };


  useEffect(() => {
    if (FormType == "Add") {
      setDescription("");
      setName("");
      setCurrencyCode("INR");
      setSuccessmsg(false);
    }

    if (FormType == "Edit") {
      setDescription(ProjectData && ProjectData?.description);
      setName(ProjectData && ProjectData?.name);
      setSuccessmsg(false);
      setOnTypingText(ProjectData && ProjectData?.currency);
      setCurrencyCode(ProjectData && ProjectData?.currency);
    }
  }, [FormType, ProjectData]);

  const ClosedHandler = () => {
    if (FormType == "Add") {
      onCloseModal();
      setSuccessmsg(false);
      setDescription("");
      setName("");
    } else {
      onCloseModal();
      setSuccessmsg(false);
    }
    // if(FormType=="Edit"){
    //   setDescription(ProjectData && ProjectData?.description);
    //   setName(ProjectData && ProjectData?.name);
    //   setSuccessmsg(false);
    //   onCloseModal();
    //  }
  };

  // console.log(FormType, "FormTypeFormType")
  const savehandler = () => {
    {
      if (
        Name !== null &&
        Description !== null &&
        Name?.length > 0 &&
        Description?.length > 0 &&
        currencyCode !== null
      ) {
        if (FormType == "Add") {
          API.post("/xcpep/project/", {
            name: Name,
            currency: currencyCode,
            type: projectIds,
            description: Description,
          }).then((res: any) => {
            setSuccessmsg(true);

            setDescription(null);
            setName(null);
            setCounter((prev: any) => prev + 1);
          });
        }

        if (FormType == "Edit") {
          API.put(`/xcpep/project/${projectIds}/`, {
            name: Name,
            currency: currencyCode,
            description: Description,
          }).then((res: any) => {
            setSuccessmsg(true);

            setDescription(null);
            setName(null);
            setCounter((prev: any) => prev + 1);
          });
        }
      }
    }

    if (Name == null || Name?.length == 0) {
      setnamemsg(true);
    }

    if (Description == null || Description?.length == 0) {
      setdesmsg(true);
    }
  };

  return (
    <div>
      <Modal
        keepMounted
        open={isOpen}
        // onClose={ClosedHandler}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {!Successmsg ? (
            <>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  {FormType == "Edit" ? "Edit Project" : "Add Project"}
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={ClosedHandler}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
            </>
          ) : (
            ""
          )}

          {Successmsg ? (
            <div>
              <Box sx={{ textAlign: "center" }}>
                <img src={success} style={{ width: "7rem" }} />
              </Box>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  // width: "100%",
                  textAlign: "center",
                  margin: "1rem",
                  color: "green",
                }}
              >
                {FormType == "Edit"
                  ? "Project Updated Successfully!"
                  : "Project Added Successfully!"}

                {/* Feedback for View Page */}
              </Typography>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  size="small"
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  variant="contained"
                  onClick={() => ClosedHandler()}
                >
                  Ok
                </Button>
              </Box>
            </div>
          ) : (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  columnGap: "1rem",
                  padding: "0 1rem",
                }}
              >
                <TextField
                  value={Name}
                  error={namemsg}
                  focused
                  onChange={(e: any) => handelChange(e, "name")}
                  //   sx={{color:"red !important"}}

                  sx={{
                    width: "50%",
                    input: {
                      //    color: 'red',
                      "&::placeholder": {
                        // <----- Add this.
                        opacity: 1,
                      },
                    },
                    // '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                  id="standard-size-normal"
                  label="Name *"
                  variant="standard"
                  // helperText={namemsg ? "" : "Incorrect entry."}

                  //   placeholder="Name"
                />

                <TextField
                  error={desmsg}
                  value={Description}
                  onChange={(e: any) => handelChange(e, "discription")}
                  //   sx={{color:"red !important"}}
                  sx={{
                    width: "100%",
                    input: {
                      //    color: 'red',
                      "&::placeholder": {
                        // <----- Add this.
                        opacity: 0,
                      },
                    },
                    // '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                  label="Description *"
                  variant="standard"
                  focused
                  id="standard-size-normal"
                  // helperText={desmsg ? "" : "Incorrect entry."}
                />
              </Box>
              <Box>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ ".MuiFormLabel-asterisk": { color: "red" },mt:'1rem', columnGap: "1rem",
                  padding: "0 1rem", }}
                >
                  <InputLabel
                    id="demo-multiple-name-label"
                    sx={{ fontSize: "0.75rem",ml:'1rem' }}
                    style={{
                      color: "#007fff",
                      fontSize: "1.1rem",
                    }}
                  >
                    Currency*
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple={false}
                    value={currencyCode}
                    // onChange={(event: any) => {
                    //   setSelectedCurrencyCodes(event.target.value);

                    //   const selectedCurIds = countryList
                    //     .filter(
                    //       (item: any) =>
                    //         event.target.value === item?.currency_code
                    //     )
                    //     .map((obj: any) => obj?.id);

                    //   formik.setFieldValue("currency_id", selectedCurIds);
                    // }}
                    onChange={(e: any) => handelChange(e, "currency")}
                    input={<Input id="standard-input" placeholder="Currency" />}
                    error={!currencyCode && Boolean(!currencyCode)}
                    //@ts-ignore
                    // helperText={
                    //   formik.touched.currency_id && formik.errors.currency_id
                    // }
                    sx={{
                      "& .MuiSelect-select": {
                        textAlign: "left",
                        fontSize: "1rem",
                        borderBottom: "1px solid #007fff",
                      },
                    }}
                  >
                    {currencyCodes.map((name: any, index: any) => (
                      <MenuItem key={index} value={name}>
                        {flagMap[name] && (
                          <img
                            src={`data:image/png;base64, ${flagMap[name]}`}
                            alt={`${name} flag`}
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                            }}
                          />
                        )}
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "2rem",
                  padding: "0 1rem",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => savehandler()}
                >
                  {FormType == "Edit" ? "Update" : "OK"}
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectDirectoryModal;
