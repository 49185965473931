// react
import axios from "axios";
import { API } from "../api-services";
import { MODULES } from "../Constants/modules.constants";
import { s3Url } from "../utlis/s3url.utils";




const MailAttachDocsS3Upload = async (
   fileList: any,
   projectIDs: any,
   setImageUploadCounter: any,
   fileSize: any,
   setAttchLoader:any,
   mailType:any


) => {
   // const file_name = file?.name;
   var random: any = Math.floor(Math.random() * 1000000 + 1);


   var obj: Array<any> = [];
   const sortAlphaNum = (a: any, b: any) => a.localeCompare(b, 'en', { numeric: true });
   var img: any = "";
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
   setAttchLoader(true)
   API.get("cad/cad_upload/", {
      module: MODULES.CAD,
   },0).then(res => {

      const data = Array.from(fileList).forEach((file: any, index: any) => {
         const formData = new FormData();
         formData.append(
            "key",
            `mail/${0}/${random}/${file.name}`
         );
         formData.append( "Content-Type", file?.type );
         formData.append( "x-amz-server-side-encryption", "AES256" );
         formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
         formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
         formData.append( "X-Amz-Date", res.data.data.x_amz_date );
         formData.append(
            "X-Amz-Signature",
            res.data.data.aws_policy_signature
         );
         formData.append( "Policy", res.data.data.aws_policy );
         formData.append( "file", file, file?.name );

         axios
            .post(s3Url, formData, {
               onUploadProgress: data => {
                 
                  //Set the progress value to show the progress bar
               },
            })
            .then((res: any) => {

               if (res.status === 204) {

                  setAttchLoader(true)



                  API.post(
                     "/analytics/store_attachment_details/",
                     {
                        file_path:`/mail/${0}/${random}/${file?.name}`,

                        project: projectIDs,

                        file_name: file?.name,

                        file_size: fileSize,
                        mail_type:5



                     }).then((res: any) => {
                        setAttchLoader(true)
                        setImageUploadCounter((prev: any) => prev + 1)

                     })

               }
            });
      });
   });


};

// ----------------------------------------------------------------------------------

export default MailAttachDocsS3Upload;
