import { Box, FormControl, MenuItem, Select, Tooltip } from "@mui/material";

import React, { useEffect } from "react";

function RenderValueColumnItemView({
  input,
  getOptionData,
  getCalculatorColumns,
  bomCalculatorColumns,
  isTableParameter,
  getdatafromtype,
}: any) {


  useEffect(() => {
    if (input?.parameter) {
      getCalculatorColumns(input?.parameter);
    }
  }, [input]);





  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.3rem",width:'100%' }}>
      <Tooltip title={
        getOptionData(input.type)?.filter((item: any) => item.value == input.parameter)[0]?.text || ""
      } arrow>
        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '3rem', xl: '5rem' } }} >
          {
            getOptionData(input.type)?.filter((item: any) => item.value == input.parameter)[0]?.text || ""
          }
        </Box>
      </Tooltip>

      {input?.parameter && (
        <Tooltip title={
          bomCalculatorColumns?.filter((item: any) => item.id == input?.table_column_parameter)[0]?.name || ""
        } arrow>
          <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '3rem', xl: '5rem' } }} >
            {
              bomCalculatorColumns?.filter((item: any) => item.id == input?.table_column_parameter)[0]?.name || ""}
          </Box>
        </Tooltip>
      )}

      {input?.parameter && (
        <Tooltip title={input?.table_element_type || ""} arrow>
          <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '3rem', xl: '5rem' } }} >
            {input?.table_element_type || ""}
          </Box>
        </Tooltip>
      )}

      {input.parameter && isTableParameter(input.type, input.parameter) && (
        <Tooltip title={getdatafromtype(input)?.filter((item: any) => item.value == input.table_element)[0]?.text || ""} arrow>
          <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { lg: '3rem', xl: '5rem' } }} >
            {getdatafromtype(input)?.filter((item: any) => item.value == input.table_element)[0]?.text || ""}
          </Box>
        </Tooltip>
      )}

    </Box>
  );
}

export default RenderValueColumnItemView;
