import {
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridCsvExportOptions,
} from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//@ts-ignore
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
export interface ICostingTopLevelTableProps {
  productLevelData?: any;
  count?: any;
}

export default function CostingTopLevelTable(
  props: ICostingTopLevelTableProps
) {
  const [CostLoader, setCostLoader] = useState<any>(false);
  const { productLevelData, count } = props;
  const { projectId, topVault, vault, treeabbr, leafNode } = useRouteParams<any>();
  const [productLevelDataCost, setProductLevelDataCost] = useState<any>([])
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const csvOptions: GridCsvExportOptions = {
    fileName: " Costing Table In CSV",
    delimiter: ",",
    utf8WithBom: true,
  };
  useEffect(() => {
    setCostLoader(true)
    API.get("/customer/system_cost/", { top_vault: topVault, vehicle_cost: true, view_page: true }).then((res: any) => {
      // console.log("setProductLevelDataCost", res.data)
      setProductLevelDataCost(res.data);
      setCostLoader(false);
    }).catch((err: any) => {
      setProductLevelDataCost([]);
      setCostLoader(false);
    })
  }, [])


  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "Costing",top_vault:topVault },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency", res.data[0]?.current_currency);

    });
  }, [topVault]);
  let rows: any = [];
  // .toLocaleString("en-US");
  let rowGenerator =
    productLevelDataCost &&
    productLevelDataCost?.map((item: any, index: any) => {
      let tempObj = {
        id: item?.id,
        name: item?.name,
        cost: String(item?.cost?.toLocaleString("en-US")),

        //   weight: item?.weight,
        //   contribution_percent: item?.contribution_percent,
        //   count: item?.count,
        abbreviation: item?.abbreviation
      };
      rows.push(tempObj);
    });


  var rowsdata = () => {
    var data = productLevelDataCost &&
      productLevelDataCost?.map((item: any, index: any) => item?.cost)

    let total = data.reduce(function (previousValue: any, currentValue: any) {
      return previousValue + currentValue
    }, 0);


    return total.toLocaleString('en-IN');



  }


  useEffect(() => {

    API.get(

      "/customer/costing_conversion/",

      { get_conversion_factor: true, module: "Costing",top_vault:topVault },

      0

    ).then((res: any) => {

      sessionStorage.setItem("currency", res.data[0]?.current_currency);



    });

  }, [topVault]);

  var totalCostdata = { id: 0, "name": "Total Cost", "cost": rowsdata() && rowsdata(), "abbreviation": "product" }
  var arrayData = [totalCostdata, ...rows]




  const setRoute = (idd: any, abb: any) => {



    sessionStorage.setItem("node", JSON.stringify([Number(topVault), Number(idd)]));
    window.open(`/#/view/${projectId}/${topVault}/${idd}/${abb}/costing`, "_blank")
  }





  // console.log(rows,"rowsrows")
  const columns = [
    {
      field: "name",
      headerName: "Sub-System",
      headerClassName: "super-app-theme--header",
      minWidth: width > 1440 ? 120 : 100,
      sortable: false,
      flex: 1,
    },

    {
      field: "cost",
      headerName: `Cost(${sessionStorage.getItem("currency")})`,
      headerClassName: "super-app-theme--header",
      headerAlign: 'right',
      align: 'right',
      // sortable: false,
      // flex: 1,
    },


  ];

  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        height: "2rem",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
        width: "100%",
      }}
    >
      <Typography style={{ color: "red", fontSize: "12px" }}>
        {/* *Only completed systems data is visible {" "} */}
      </Typography>
      {/* <CustomExportButton />      */}
      <CSVLink
        data={rows}
        filename={"product-details-cost.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        <Box
          sx={{
            color: "#007fff",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            textDecoration: "none",
          }}
        >
          <SaveAltIcon sx={{ fontSize: "1.2rem" }} />
          EXPORT
          {/* <GetAppIcon /> */}
        </Box>
      </CSVLink>
      {/* <CSVDownload data={rows} target="_blank" /> */}
    </GridToolbarContainer>
  );
  return (
    <div>
      <Box
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
        sx={{
          height: { xs: 630, sm: 630, md: 630, lg: "76vh", xl: "82vh" },
          overflowY: "auto",
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },

          "& ::-webkit-scrollbar-thumb": {
            // background: $thumb-color;
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            // borderRadius: "10px",
          },

          "& ::- webkit-scrollbar-thumb: hover ": {
            // width: "4px",
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          width: "100%",
          "& .nowrap-cells": {
            whiteSpace: "normal !important",
          },
          "& .super-app-theme--header": {
            backgroundColor: "primary.main",
            color: '#fff !important',
            minHeight: '34px',
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: '#fff !important',
          },
          " .MuiDataGrid-cell --textRight": {
            textAlign: "right",
          },
          ".MuiDataGrid-columnHeaders": {
            maxHeight: '34px',
          },
          "& .super-app-theme--product": {
            // color: "#0021f3 !important",
            // #f7d8d9
            backgroundColor: "primary.light",
            color: "primary.main",
            // bgcolor: (theme) => getBackgroundColor("#d32f2f", theme.palette.mode),
            "&:hover": {
              // "#FF6962 !important",
              backgroundColor: "primary.light",
              color: "primary.main",
            },
          },
        }}
      >
        {CostLoader ? <>
          <Box sx={{ width: "100%", padding: '0 0.5rem' }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton /></Box></>
          : (
            <DataGrid
              disableColumnFilter
              disableColumnMenu
              headerHeight={48}
              rowHeight={36}
              {...{
                columns: columns,
                rows: arrayData && arrayData,
              }}
              //   initialState={{
              //     sorting: {
              //       sortModel: [{ field: "name", sortable: false }],
              //     },
              //   }}
              isRowSelectable={() => false}
              density="compact"
              //@ts-ignore
              hideFooter="true"
              onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;

                if (params?.field == "name") {
                  setRoute(
                    params?.row?.id,
                    params?.row?.abbreviation

                  );
                }
              }}
              // rowsPerPageOptions={[16]}
              sx={{
                fontSize: "12px",
                boxShadow: 2,
                border: 2,
                cursor: "pointer",
                ".MuiDataGrid-cell": {
                  borderBottomColor: 'primary.light',
                },
                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                  display: "none"
                },
                "& .MuiDataGrid-columnHeader:first-child .MuiDataGrid-columnSeparator": {
                  display: "none"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  textTransform: 'capitalize'
                }
              }}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.abbreviation}`
              }
            />)}
      </Box>
    </div>
  );
}
