import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "../CostingCreateSystemLandingPage.module.scss";
import { useHistory } from "react-router-dom";
// import CompressIcon from '@mui/icons-material/Compress';
// import ExpandIcon from '@mui/icons-material/Expand';
// import InsightsIcon from '@mui/icons-material/Insights';
// import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// import { AxiosError, AxiosResponse } from 'axios';
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
// import { CostingCreateDrawer } from '../CostingCreateDrawer';
import CostingScenarioCreateSystemTable from "./CostingScenarioCreateSystemTable";
// import { API } from '../../../api-services';
import CostingScenerioCreateSystemTableCompressed from "./CostingScenerioCreateSystemTableCompressed";
import { CostingScenarioCreateDrawer } from "./CostingScenarioCreateDrawer";
import { API } from "../../../api-services";
import { AxiosError, AxiosResponse } from "axios";

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  projectName: any;
  expandTable: any;
  initialScenarioId: any;
  scenarioIds: any;
  topVaultId: any;
}

interface ICostingScenarioCreateSystemLandingPageProps { }

const CostingScenarioCreateSystemLandingPage: React.FC<ICostingScenarioCreateSystemLandingPageProps> = (props) => {
  const { projectId, projectName, scenarioIds, expandTable, topVaultId, initialScenarioId } = useRouteParams<Params>();  

  const [productList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        API.get(`/xcpep/product_list_new/`,
          { project: `${projectId}`, module: "Cost-Scenario" },
          0
        )
          .then((res: AxiosResponse) => {
            setProductList(res?.data);
            setSelectedProduct([topVaultId]);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <CostingScenarioCreateDrawer
      anchorComing={anchor}
      toggleDrawer={toggleDrawer}
      productList={productList}
      setProductList={setProductList}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
    />
  );

  const handleClickBack = () => {
    history.push(`/costingscenario/${projectId}/${projectName}/${topVaultId}/0/1/PRODUCT/0`);
  };

  // const handleClickExpand = () => {
  //   expandTable == 'true' ?
  //     history.push(`/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list?.toString()}/${expandTable == 'true' ? 'false' : 'true'}/${scenerio}`)
  //     : history.push(`/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list?.slice(0, 4)?.toString()}/${expandTable == 'true' ? 'false' : 'true'}/${scenerio}`);
  // }

  return (
    <>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClickBack}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Scenario Estimate Cost ( {projectName} )
            </Typography>
          </Box>         
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={
            String(scenarioIds)?.includes(",")
              ? scenarioIds?.split(",")?.length == 2
                ? {
                  display: "grid",
                  gridTemplateColumns: `1.5fr 1fr 1fr`,
                  width: "98%",
                  maxHeight: { lg: "86vh", xl: "90vh" },
                }
                : {
                  display: "grid",
                  gridTemplateColumns: `1.5fr repeat(${scenarioIds?.split(",")?.length
                    }, 1fr)`,
                  width: "98%",
                  maxHeight: { lg: "86vh", xl: "90vh" },
                }
              : {
                display: "grid",
                gridTemplateColumns: `1.5fr 1fr`,
                width: "98%",
                maxHeight: { lg: "86vh", xl: "90vh" },
              }
          }
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {expandTable == "true" ? (
            <CostingScenarioCreateSystemTable />
          ) : (
            <CostingScenerioCreateSystemTableCompressed />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CostingScenarioCreateSystemLandingPage;
