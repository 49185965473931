// react
import React, { useContext, useEffect, useMemo, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Checkbox,
  Divider,
  IconButton,
  InputBase,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
// vendors

// services
// utils
// constants
// factories
// core
// import MappedTable from 'app/Core/MappedTable';

// redux
// ui-components
// others
// import databaseSelectionModalUtils from './databaseSelectionModal.utils';

// styles
import styles from "./DatabaseSelectionModal.module.scss";
import { Modal } from "@mui/material";
import { ID } from "../../../../../../utlis/commonInterfaces";
import { API } from "../../../../../../api-services";
import CloseIcon from "@mui/icons-material/Close";
import {
  AssumptionIdSelectedContext,
  OtherAssumptionIdContext,
} from "../../../CreateCostingPart";
import { useBaseModal } from "../../../../../SearchPage/useBaseModal";
import OtherAssumptionSingleCardModal from "../../../OtherAssumptionSingleCardModal";
import BoughtOutOtherAssumptionSingleCardModal from "../../../BoughtOutOtherAssumptionSingleCardModal";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ADMIN } from "../../../../../../Redux/Services/admin.service";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../../../../../Assets/images/svgs/openfolderblue.svg";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import calcImageGreen from "../../../../../../Assets/images/calculator_green.svg";
// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "85vw",
  maxWidth: "90vw",
  height: "92vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface DatabaseSelectionModalProps {
  isOpen: boolean | any;
  database: number | any;
  revisionId: ID | any;
  calInfo: any;
  onClose: () => void;
  onSubmit: (record: any) => void;
  revisiondata: any;
  calculatorId: any;
  userInput: any;
  itemCategory?: any;
  revision_id?: any;
  getRevisionId?: any;
  dbFetchCount?: any;
  setDbFetchCount?: any;
  assumptionList?: any;
  mainAssumption?: any;
  updateCosting?:any;
  setUpdateCosting?:any;
  calctype?:any
}

const DatabaseSelectionModal: React.FC<DatabaseSelectionModalProps> = (
  props
) => {
  const {
    isOpen,
    onClose,
    database,
    revisionId,
    onSubmit,
    revisiondata,
    calculatorId,
    userInput,
    calInfo,
    itemCategory,
    revision_id,
    getRevisionId,
    dbFetchCount,
    setDbFetchCount,
    assumptionList,
    mainAssumption,
    updateCosting,
    setUpdateCosting,
    calctype
  } = props;

  // ----------------------------------------------------------------------------------
  // state

  const [selection, setSelection] = useState<any>([]);
  const [DataBaseList, setDataBaseList] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [Innerloader, setInnerloader] = useState<any>(false);
  const [TableData, setTableData] = useState<any>([]);
  const [SelectedGroup, setSelectedGroup] = useState<any>({});
  const [searchValue, setSearchValue] = useState<any>("");
  const AssumtionIds = useContext<any>(OtherAssumptionIdContext);
  const AssumptionData = useContext<any>(AssumptionIdSelectedContext);
  const BoughtOutmodal = useBaseModal();
  const [SelectedItem, setSelectedItem] = useState<any>(null);
  const [DataBaseStatus, setDataBaseStatus] = useState<any>(null);
  const [DataBaseStatusBOP, setDataBaseStatusBOP] = useState<any>(null);

  const [PaginationCounter, setPaginationCounter] = useState<any>(1);
  const [expanded, setExpanded] = React.useState<any>();
  const [electricalTableData, setElectricalTableData] = React.useState<any>([]);
  const [CommodityIds, setCommodityIds] = useState<any>();
  const [PageCount, setPageCount] = useState<any>(0);
  const [Selectedrevision, setSelectedRevision] = useState<any>([]);
  const othermodal = useBaseModal();

  // ----------------------------------------------------------------------------------
  // memoizations

  // const db = useMemo( () => {
  //     return databaseSelectionModalUtils( revisionId, database, isOpen );
  // }, [revisionId, database, isOpen] );

  // ----------------------------------------------------------------------------------
  // handlers
  const CALCULATOR_DATABASES_MAP = {
    MATERIAL: 1,
    MACHINE: 2,
    LABOUR: 3,
    TOOL: 4,
    // FASTENER: 5,
    PURCHASEING_ITEM: 6,
    STROKE_RATE: 7,
  };

  useEffect(() => {
    if (getRevisionId) {
      if (database == 1) {
        getRevisionId(revisiondata?.material_revision);
      }
      if (database == 2) {
        getRevisionId(revisiondata?.machine_revision);
      }
      if (database == 3) {
        getRevisionId(revisiondata?.labour_revision);
      }
      if (database == 4) {
        getRevisionId(revisiondata?.tools_revision);
      }
      if (database == 6) {
        getRevisionId(revisiondata?.bop_revision);
      }
      if (database == 7) {
        getRevisionId(revisiondata?.operations_revision);
      }
    }
  }, [database]);

  const tableHeading = (datatype: any) => {
    switch (datatype) {
      case 1:
        return (
          <span>
            Fetch Material from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            )
          </span>
        );
      case 2:
        return (
          <span>
            {" "}
            Fetch MHR from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            )
          </span>
        );
      case 3:
        return (
          <span>
            {" "}
            Fetch LHR from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            ){" "}
          </span>
        );
      case 4:
        return (
          <span>
            {" "}
            Fetch Tool from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            )
          </span>
        );
      // case 5:
      //     return (<span> Fetch Fastener  from DB ( { Selectedrevision && Selectedrevision?.length > 0 ?Selectedrevision && Selectedrevision[0]?.name:revisiondata?.name})</span>);
      case 6:
        return (
          <span>
            {" "}
            Fetch Bought-Out Parts from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            )
          </span>
        );
      case 7:
        return (
          <span>
            Fetch Operation from DB ({" "}
            {Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.name
              : revisiondata?.name}
            )
          </span>
        );
    }
  };

  useEffect(() => {
    if (revisionId !== 0 && revisionId !== undefined) {
      setDataBaseList([]);
      if (database == 1) {
        setLoader(true);
        API.get(
          "/api/db/revision_database/",
          {
            revision_id: revisiondata?.material_revision,
            category_type: "materialcost",
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
      if (database == 2) {
        if (AssumtionIds && AssumtionIds?.length == 0) {
          setLoader(true);
          API.get(
            "/api/db/revision_database/",
            {
              revision_id: revisiondata?.machine_revision,
              category_type: "machinedetail",
            },
            0
          )
            .then((res: any) => {
              setDataBaseList(res.data);
              setLoader(false);
            })
            .catch((err: any) => {
              setLoader(false);
            });
        }
      }
      if (database == 3) {
        if (AssumtionIds && AssumtionIds?.length == 0) {
          setLoader(true);
          API.get(
            "/api/db/revision_database/",
            {
              revision_id: revisiondata?.labour_revision,
              category_type: "labourdetail",
            },
            0
          )
            .then((res: any) => {
              setDataBaseList(res.data);
              setLoader(false);
            })
            .catch((err: any) => {
              setLoader(false);
            });
        }

      }
      if (database == 4) {
        if (AssumtionIds && AssumtionIds?.length == 0) {
          setLoader(true);
          API.get(
            "/api/db/revision_database/",
            {
              revision_id: revisiondata?.tools_revision,
              category_type: "toolcost",
            },
            0
          )
            .then((res: any) => {
              setDataBaseList(res.data);
              setLoader(false);
            })
            .catch((err: any) => {
              setLoader(false);
            });
        }

      }

      if (database == 6) {
        setLoader(true);
        API.get(
          "/api/db/revision_database/",
          {
            category_type: "boughtoutpart",
            revision_id: revisiondata?.bop_revision,
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }
    }
    if (database == 7) {
      if (AssumtionIds && AssumtionIds?.length == 0) {
        setLoader(true);
        API.get(
          "/api/db/revision_database/",
          {
            revision_id: revisiondata?.operations_revision,
            category_type: "operationdatabase",
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
    }
  }, [revisionId, isOpen]);

  const handleSelectionChange = (selection: any, item: any) => {
    setSelectedItem(item);
    setSelection([selection]);
  };

  const handleOnChange = (ed: any) => {
    const { value } = ed.target;
    if (value == "") {
      if (database == 6) {
        setExpanded(undefined);
        setSearchValue("");
        setTableData([]);

        API.get(
          "/api/db/revision_database/",
          {
            category_type: "boughtoutpart",
            revision_id: revisiondata?.bop_revision,
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      } else {
        setSearchValue("");
        setTableData([]);
      }
      fethTableData();
    } else {
      setSearchValue(value);
    }
  };

  const handleSearchIcon = () => {
    if (searchValue == "") {
      if (database == 6) {
        setExpanded(undefined);
        setSearchValue("");
        setTableData([]);

        API.get(
          "/api/db/revision_database/",
          {
            category_type: "boughtoutpart",
            revision_id: revisiondata?.bop_revision,
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      } else {
        setSearchValue("");
        setTableData([]);
      }
    } else {
      setTableData([]);
      setExpanded(undefined);
      setSelection(undefined);
      setInnerloader(false);
      setPaginationCounter(1);

      if (database == 1) {
        API.get(
          "/api/db/revision_database/",
          {
            revision_id: revisiondata?.material_revision,
            search: searchValue?.trim(),
            category_type: "materialcost",
            item_category: itemCategory,
            get_integrated_search: true,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 2) {
        API.get(
          "api/db/costDB_search/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.machine_revision
                : revisiondata?.machine_revision,
            search_key: searchValue?.trim(),
            item_category: itemCategory,
            machine_revision: true,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 3) {
        API.get(
          "api/db/costDB_search/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.labour_revision
                : revisiondata?.labour_revision,
            search_key: searchValue?.trim(),
            item_category: itemCategory,
            labour_revision: true,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      } else if (database == 4) {
        API.get(
          "api/db/costDB_search/",
          {
            tools_revision: true,
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.tools_revision
                : revisiondata?.tools_revision,
            search_key: searchValue?.trim(),
            item_category: itemCategory,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 7) {
        API.get(
          "/api/db/costDB_search/",
          {
            operation_revision: true,
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.operations_revision
                : revisiondata?.operations_revision,
            search_key: searchValue?.trim(),
            item_category: itemCategory,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      } else if (database == 6) {
        API.get(
          "/api/db/revision_database/",
          {
            category_type: "boughtoutpart",
            revision_id: revisiondata?.bop_revision,
            search: searchValue?.trim(),
            item_category: itemCategory,
          },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });

      }
    }
  };
  const searchAPICall = (ed: any) => {
    if (ed.key == "Enter") {
      if (searchValue == "") {
        if (database == 6) {
          setExpanded(undefined);
          setSearchValue("");
          setTableData([]);

          API.get(
            "/api/db/revision_database/",
            {
              category_type: "boughtoutpart",
              revision_id: revisiondata?.bop_revision,
            },
            0
          )
            .then((res: any) => {
              setDataBaseList(res.data);
              setLoader(false);
            })
            .catch((err: any) => {
              setLoader(false);
              console.log("Server Error");
            });
        } else {
          setSearchValue("");
          setTableData([]);
        }
      } else {
        setTableData([]);
        setExpanded(undefined);
        setSelection(undefined);
        setInnerloader(false);
        setPaginationCounter(1);

        if (database == 1) {
          API.get(
            "/api/db/revision_database/",
            {
              revision_id: revisiondata?.material_revision,
              search: searchValue?.trim(),
              category_type: "materialcost",
              item_category: itemCategory,
              get_integrated_search: true,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              console.log("Server Error");
              setInnerloader(false);
            });
        } else if (database == 2) {
          API.get(
            "api/db/costDB_search/",
            {
              revision:
                Selectedrevision && Selectedrevision?.length > 0
                  ? Selectedrevision && Selectedrevision[0]?.machine_revision
                  : revisiondata?.machine_revision,
              search_key: searchValue?.trim(),
              machine_revision: true,
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              console.log("Server Error");
              setInnerloader(false);
            });
        } else if (database == 3) {
          API.get(
            "api/db/costDB_search/",
            {
              revision:
                Selectedrevision && Selectedrevision?.length > 0
                  ? Selectedrevision && Selectedrevision[0]?.labour_revision
                  : revisiondata?.labour_revision,
              search_key: searchValue?.trim(),
              labour_revision: true,
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else if (database == 4) {
          API.get(
            "api/db/costDB_search/",
            {
              tools_revision: true,
              revision:
                Selectedrevision && Selectedrevision?.length > 0
                  ? Selectedrevision && Selectedrevision[0]?.tools_revision
                  : revisiondata?.tools_revision,
              search_key: searchValue?.trim(),
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              console.log("Server Error");
              setInnerloader(false);
            });
        } else if (database == 7) {
          API.get(
            "/api/db/costDB_search/",
            {
              operation_revision: true,
              revision:
                Selectedrevision && Selectedrevision?.length > 0
                  ? Selectedrevision && Selectedrevision[0]?.operations_revision
                  : revisiondata?.operations_revision,
              search_key: searchValue?.trim(),
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else if (database == 6) {
          API.get(
            "/api/db/revision_database/",
            {
              category_type: "boughtoutpart",
              revision_id: revisiondata?.bop_revision,
              search: searchValue?.trim(),
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setDataBaseList(res.data);
              setLoader(false);
            })
            .catch((err: any) => {
              setLoader(false);
              console.log("Server Error");
            });

          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              search: searchValue?.trim(),
              commodity: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      }
    }
  };

  const PreviousHandler = () => {
    setPaginationCounter((prev: any) => prev - 1);
    setInnerloader(true);
    if (database == 1) {
      API.get(
        "api/db/material_cost/",
        {
          revision: revisiondata?.material_revision,
          category: expanded,
          page: PaginationCounter - 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 2) {
      API.get(
        "api/db/machine_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.machine_revision
              : revisiondata?.machine_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter - 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 3) {
      API.get(
        "api/db/labour_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision &&
                Selectedrevision[0]?.machine_revision?.labour_revision
              : revisiondata?.labour_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter - 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 4) {
      //    console.log(database, "hrbvbfvbfvhbfvhb")
      API.get(
        "api/db/tool_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision &&
                Selectedrevision[0]?.machine_revision?.tools_revision
              : revisiondata?.tools_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter - 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 7) {
      API.get(
        "/api/db/stroke_rate_revision/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision &&
                Selectedrevision[0]?.machine_revision?.operations_revision
              : revisiondata?.operations_revision,
          category: expanded,
          page: PaginationCounter - 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 6) {
      if (
        CommodityIds?.category == "Electrical & Electronics Proprietary Parts"
      ) {
        {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: expanded,
              get_integrated_bop: true,
              page: PaginationCounter - 1,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      } else {
        API.get(
          "api/db/bought_out_cost/",
          {
            revision: revisiondata?.bop_revision,
            commodity: expanded,
            page: PaginationCounter - 1,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      }
    }
  };

  const NextHandler = () => {
    setPaginationCounter((prev: any) => prev + 1);
    setInnerloader(true);
    if (database == 1) {
      API.get(
        "api/db/material_cost/",
        {
          revision: revisiondata?.material_revision,
          category: expanded,
          page: PaginationCounter + 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 2) {
      API.get(
        "api/db/machine_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.machine_revision
              : revisiondata?.machine_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter + 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 3) {
      API.get(
        "api/db/labour_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.labour_revision
              : revisiondata?.labour_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter + 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 4) {
      //    console.log(database, "hrbvbfvbfvhbfvhb")
      API.get(
        "api/db/tool_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.tools_revision
              : revisiondata?.tools_revision,
          commodity: CommodityIds?.id,
          page: PaginationCounter + 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 7) {
      API.get(
        "/api/db/stroke_rate_revision/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.operations_revision
              : revisiondata?.operations_revision,
          category: expanded,
          page: PaginationCounter + 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 6) {
      if (
        CommodityIds?.category == "Electrical & Electronics Proprietary Parts"
      ) {
        {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: expanded,
              get_integrated_bop: true,
              page: PaginationCounter + 1,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      } else {
        API.get(
          "api/db/bought_out_cost/",
          {
            revision: revisiondata?.bop_revision,
            commodity: expanded,
            page: PaginationCounter + 1,
          },
          0
        )
          .then((res: any) => {
            setTableData(res.data);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      }
    }
  };

  const handleChange = (panel: string, type: any, item: any) => {
    if (type == 1) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(panel);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/material_cost/",
        { revision: revisiondata?.material_revision, category: panel, page: 1 },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (type == 2) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(panel);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/machine_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.machine_revision
              : revisiondata?.machine_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (type == 3) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(panel);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/labour_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.labour_revision
              : revisiondata?.labour_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (type == 4) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(panel);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      //    console.log(database, "hrbvbfvbfvhbfvhb")
      API.get(
        "api/db/tool_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.tools_revision
              : revisiondata?.tools_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (type == 7) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(panel);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "/api/db/stroke_rate_revision/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.operations_revision
              : revisiondata?.operations_revision,
          category: panel,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (type == 6) {
      if (item?.category == "Electrical & Electronics Proprietary Parts") {
        setPaginationCounter(1);
        setInnerloader(true);
        setExpanded(panel);
        // setSearchValue('');
        // setTableData([])
        setCommodityIds(item);
        if (searchValue == ``) {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: panel,
              get_integrated_bop: true,
              page: 1,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: panel,
              get_integrated_bop: true,
              search: searchValue?.trim(),
              item_category: itemCategory,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      } else {
        setPaginationCounter(1);
        setInnerloader(true);
        setExpanded(panel);
        // setSearchValue('');
        // setTableData([])
        setCommodityIds(item);
        // console.log(item, "cxshvjchvjscvcdvhcjvhj")
        setSelection(undefined);
        if (searchValue == "") {
          API.get(
            "api/db/bought_out_cost/",
            { revision: revisiondata?.bop_revision, commodity: panel, page: 1 },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else {
          API.get(
            "api/db/bought_out_cost/",
            {
              item_category: itemCategory,
              revision: revisiondata?.bop_revision,
              commodity: panel,
              search: searchValue?.trim(),
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      }
    }
  };

  useEffect(() => {
    if (database == 1) {
      API.get(`/cost/update_calculation/`, {
        calculation_id: userInput?.id,
        calculator_id: calInfo?.id,
      })
        .then((res: any) => {
          setDataBaseStatus(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  }, [isOpen]);
  useEffect(() => {
    if (database == 6) {
      API.get(`/cost/update_calculation/`, {
        calculation_id: userInput?.id,
        calculator_id: calInfo?.id,
        bop: true,
      })
        .then((res: any) => {
          setDataBaseStatusBOP(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  }, [isOpen]);

  const handleSubmit = () => {
    if (AssumtionIds?.length > 0) {
      if (DataBaseStatusBOP && DataBaseStatusBOP?.is_bop == true) {
        if (database == 6) {
          if (setDbFetchCount) {
            setDbFetchCount(dbFetchCount + 1);
          }
          BoughtOutmodal.open();
        }
      } else {
        if (!selection) return console.log("Select an item");
        if (setDbFetchCount) {
          setDbFetchCount(dbFetchCount + 1);
        }

        onSubmit(selection[0]);
      }
    } else {
      if (!selection) return console.log("Select an item");
      if (setDbFetchCount) {
        setDbFetchCount(dbFetchCount + 1);
      }

      onSubmit(selection[0]);
    }
  };

  const AssumptionHandler = () => {
    onSubmit(selection[0]);
  };

  const handleSubmitMaterial = () => {
    if (AssumtionIds?.length > 0 || assumptionList?.length > 0) {
      if (
        (DataBaseStatus && DataBaseStatus?.is_scrap == true) ||
        DataBaseStatus?.is_material == true
      ) {
        if (database == 1) {
          othermodal.open();
        }
      } else {
        if (!selection) return console.log("Select an item");
        onSubmit(selection[0]);
      }
    } else {
      if (!selection) return console.log("Select an item");
      onSubmit(selection[0]);
    }
  };

  const getDataBaseType: any = (value: any, revisionIdss: any) => {
    switch (value) {
      case 2:
        return {
          machine_type: "single",
          revision_id: revisionIdss && revisionIdss[0]?.machine_revision,
        };
      case 3:
        return {
          labour_type: "single",
          revision_id: revisionIdss && revisionIdss[0]?.labour_revision,
        };

      case 4:
        return {
          tool_type: "single",
          revision_id: revisionIdss && revisionIdss[0]?.tools_revision,
        };
      case 7:
        return {
          operation_type: "single",
          revision_id: revisionIdss && revisionIdss[0]?.operations_revision,
        };
    }
  };
  const handleSubmitRevision = () => {
    if (Selectedrevision && Selectedrevision?.length > 0) {
      if (!selection) {
        console.log("Select an item");
      } else {
        API.post(`/cost/update_calculation/`, {
          calculation_id: userInput?.id,

          ...getDataBaseType(database, Selectedrevision && Selectedrevision),
        })
          .then((res: any) => {
            if (setDbFetchCount) {
              setDbFetchCount(dbFetchCount + 1);
            }
            onSubmit(selection[0]);
            // setCostScrapData(null)
            // setScrapCostType([])
          })
          .catch((err: any) => {
            //         setNextLoader(false)
            //         const { data } = err.response;

            //   ErrorModalFiles.open();
            //   setErrorModalFileMsg(data);
            console.log("Server Error");
          });
      }
    } else {
      if (!selection) return console.log("Select an item");
      onSubmit(selection[0]);
    }
  };
  const BoughtOutOthermodalHandler = () => {
    onSubmit(selection[0]);
  };

  // ----------------------------------------------------------------------------------
  // side effects

  // reset state on close
  useEffect(() => {
    if (isOpen) return;
    setTableData([]);
    setExpanded(undefined);
    setSelection(undefined);
    // setInnerloader(false)
    setSearchValue("");
    setPaginationCounter(1);
  }, [isOpen]);

  useEffect(() => {
    if (expanded !== undefined) {
      if (database == 1) {
        API.get(
          "api/db/material_cost/",
          {
            revision: revisiondata?.material_revision,
            category: expanded,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 2) {
        API.get(
          "api/db/machine_cost/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.machine_revision
                : revisiondata?.machine_revision,
            commodity: CommodityIds?.id,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 3) {
        API.get(
          "api/db/labour_cost/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.labour_revision
                : revisiondata?.labour_revision,
            commodity: CommodityIds?.id,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      } else if (database == 4) {
        //    console.log(database, "hrbvbfvbfvhbfvhb")
        API.get(
          "api/db/tool_cost/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.tools_revision
                : revisiondata?.tools_revision,
            commodity: CommodityIds?.id,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setInnerloader(false);
          });
      } else if (database == 7) {
        API.get(
          "/api/db/stroke_rate_revision/",
          {
            revision:
              Selectedrevision && Selectedrevision?.length > 0
                ? Selectedrevision && Selectedrevision[0]?.operations_revision
                : revisiondata?.operations_revision,
            category: expanded,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      } else if (database == 6) {
        API.get(
          "api/db/bought_out_cost/",
          {
            revision: revisiondata?.bop_revision,
            commodity: expanded,
            get_page_count: true,
          },
          0
        )
          .then((res: any) => {
            setPageCount(res?.data?.page_count);
            setInnerloader(false);
          })
          .catch((err: any) => {
            setInnerloader(false);
            console.log("Server Error");
          });
      }
    }
  }, [expanded]);

  const databaseColumns1 = (type: any) => {
    switch (type) {
      case 3:
        return (
          <>
            <TableHead>
              <TableCell className={styles.tableHead}>Select</TableCell>
              <TableCell className={styles.tableHead}>Labour Code</TableCell>
              <TableCell className={styles.tableHead}>Labour Type</TableCell>
              {searchValue !== "" && (
                <TableCell className={styles.tableHead}>
                  {" "}
                  Commodity Name{" "}
                </TableCell>
              )}
              <TableCell className={styles.tableHead}>Description</TableCell>
              <TableCell className={styles.tableHead}>
                Minimum Wage (INR/Day)
              </TableCell>
              <TableCell className={styles.tableHead}>
                MiD.A (INR/Month)
              </TableCell>
              <TableCell className={styles.tableHead}>Perks (%)</TableCell>
              <TableCell className={styles.tableHead}>
                Minimum Wage (INR/Month)
              </TableCell>
              <TableCell className={styles.tableHead}>LHR</TableCell>
            </TableHead>
          </>
        );
      case 2:
        return (
          <>
            <TableHead>
              <TableCell className={styles.tableHead}>Select</TableCell>
              <TableCell className={styles.tableHead}>Machine Code</TableCell>
              <TableCell className={styles.tableHead}>Name</TableCell>
              <TableCell className={styles.tableHead}>Brand Name</TableCell>
              {searchValue !== "" && (
                <TableCell className={styles.tableHead}>
                  {" "}
                  Commodity Name{" "}
                </TableCell>
              )}
              <TableCell className={styles.tableHead}>Description</TableCell>
              <TableCell className={styles.tableHead}>
                Cost Per Hour(INR/Hour)
              </TableCell>
            </TableHead>
          </>
        );
      case 4:
        return (
          <>
            <TableHead>
              <TableCell className={styles.tableHead}>Select</TableCell>
              <TableCell className={styles.tableHead}>Tool Code</TableCell>
              <TableCell className={styles.tableHead}>Tool Name</TableCell>
              <TableCell className={styles.tableHead}>Specification</TableCell>
              <TableCell className={styles.tableHead}>Catogory</TableCell>
              <TableCell className={styles.tableHead}>Supplier</TableCell>
              <TableCell className={styles.tableHead}>Material</TableCell>
              <TableCell className={styles.tableHead}>Other</TableCell>
              <TableCell className={styles.tableHead}>No. of Shots</TableCell>
              <TableCell className={styles.tableHead}>
                Unit Rate (INR)
              </TableCell>
              <TableCell className={styles.tableHead}>Reference</TableCell>
            </TableHead>
          </>
        );
      case 1:
        return (
          <>
            <TableHead>
              <TableCell className={styles.tableHead}>Select</TableCell>
              <TableCell className={styles.tableHead}>Material Code</TableCell>
              <TableCell className={styles.tableHead}>
                Material Category
              </TableCell>
              <TableCell className={styles.tableHead}>Grade</TableCell>
              <TableCell className={styles.tableHead}>Sub Grade</TableCell>
              <TableCell className={styles.tableHead}>Shape</TableCell>
              <TableCell className={styles.tableHead}>Density</TableCell>
              <TableCell
                className={styles.tableHead}
                sx={{ textAlign: "right" }}
              >
                Material Cost
              </TableCell>
              <TableCell
                className={styles.tableHead}
                sx={{ textAlign: "left" }}
              >
                Scrap Cost
              </TableCell>
              <TableCell
                className={styles.tableHead}
                sx={{ maxWidth: "25rem", wordWrap: "break-word" }}
              >
                Remarks
              </TableCell>
            </TableHead>
          </>
        );
      case 6:
        if (
          CommodityIds?.category == "Electrical & Electronics Proprietary Parts"
        ) {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>

                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>
                  Item Description
                </TableCell>
                <TableCell className={styles.tableHead}>Type</TableCell>
                <TableCell className={styles.tableHead}>Module Type</TableCell>
                <TableCell className={styles.tableHead}>
                  Rated Voltage
                </TableCell>
                <TableCell className={styles.tableHead}>Watttage</TableCell>
                <TableCell className={styles.tableHead}>Frequency</TableCell>

                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Fasteners") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Code</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>
                  Type of Fastener
                </TableCell>
                <TableCell className={styles.tableHead}>Grade</TableCell>
                <TableCell className={styles.tableHead}>Material</TableCell>
                <TableCell className={styles.tableHead}>M-Size</TableCell>
                <TableCell className={styles.tableHead}>Length</TableCell>
                <TableCell className={styles.tableHead}>
                  Thread Length
                </TableCell>
                <TableCell className={styles.tableHead}>Weight</TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Bearings") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Code</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>Bearing Code</TableCell>
                <TableCell className={styles.tableHead}>ID(mm)</TableCell>
                <TableCell className={styles.tableHead}>OD(mm)</TableCell>
                <TableCell className={styles.tableHead}>Height (mm)</TableCell>
                <TableCell className={styles.tableHead}>Weight (g)</TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Connectors") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>No. of Pins</TableCell>
                <TableCell className={styles.tableHead}>
                  Position Config(mm)
                </TableCell>
                <TableCell className={styles.tableHead}>Shape</TableCell>
                <TableCell className={styles.tableHead}>
                  Terminal Pin Pitch
                </TableCell>
                <TableCell className={styles.tableHead}>
                  Connection Type
                </TableCell>
                <TableCell className={styles.tableHead}>Male/Female</TableCell>
                <TableCell className={styles.tableHead}>Fit Type</TableCell>
                <TableCell className={styles.tableHead}>Material</TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Consumables") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Code</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>
                  Item Descriptions
                </TableCell>
                <TableCell className={styles.tableHead}>Unit</TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Electronic Child Components") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>
                  Item Description
                </TableCell>
                <TableCell className={styles.tableHead}>Length (mm)</TableCell>
                <TableCell className={styles.tableHead}>Width (mm)</TableCell>
                <TableCell className={styles.tableHead}>Size Unit</TableCell>
                <TableCell className={styles.tableHead}>
                  Component Type
                </TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else if (CommodityIds?.category == "Mechanical Proprietory Parts") {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Code</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>
                  Item Description
                </TableCell>
                <TableCell className={styles.tableHead}>
                  Brand and Model
                </TableCell>
                <TableCell className={styles.tableHead}>Length (mm)</TableCell>
                <TableCell className={styles.tableHead}>Width (mm)</TableCell>
                <TableCell className={styles.tableHead}>Height (mm)</TableCell>
                <TableCell className={styles.tableHead}>Weight</TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        } else {
          return (
            <>
              <TableHead>
                <TableCell className={styles.tableHead}>Select</TableCell>
                <TableCell className={styles.tableHead}>Item Code</TableCell>
                <TableCell className={styles.tableHead}>Item Name</TableCell>
                <TableCell className={styles.tableHead}>Item Details</TableCell>
                <TableCell className={styles.tableHead}>
                  Item Description
                </TableCell>
                <TableCell className={styles.tableHead}>Length (mm)</TableCell>
                <TableCell className={styles.tableHead}>Width (mm)</TableCell>
                <TableCell className={styles.tableHead}>Height (mm)</TableCell>
                <TableCell className={styles.tableHead}>Weight (kg)</TableCell>
                <TableCell className={styles.tableHead}>
                  Place of Mfg.
                </TableCell>
                <TableCell className={styles.tableHead}>
                  Per unit Cost (INR)
                </TableCell>
                <TableCell className={styles.tableHead}>Reference</TableCell>
              </TableHead>
            </>
          );
        }

      case 7:
        return (
          <>
            <TableHead>
              <TableCell className={styles.tableHead}>Select</TableCell>
              <TableCell className={styles.tableHead}>Operation Code</TableCell>
              <TableCell className={styles.tableHead}>Category</TableCell>
              <TableCell className={styles.tableHead}>Description</TableCell>
              <TableCell className={styles.tableHead}>Unit</TableCell>
              <TableCell className={styles.tableHead}>Rate/Unit</TableCell>
              <TableCell
                className={styles.tableHead}
                sx={{ maxWidth: "25rem", wordWrap: "break-word" }}
              >
                Remarks
              </TableCell>
            </TableHead>
          </>
        );
    }
  };

  const checkboxrender = (item: any, index: any, type: any) => {
    switch (type) {
      case 1:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.material_id) ? true : false}
              onClick={() => handleSelectionChange(item?.material_id, item)}
            />
          </>
        );
      case 2:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.machine?.id) ? true : false}
              onClick={() => handleSelectionChange(item?.machine?.id, item)}
            />
          </>
        );
      case 3:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.labour?.id) ? true : false}
              onClick={() => handleSelectionChange(item?.labour?.id, item)}
            />
          </>
        );
      case 4:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.tools?.id) ? true : false}
              onClick={() => handleSelectionChange(item?.tools?.id, item)}
            />
          </>
        );
      case 6:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.purchase_item) ? true : false}
              onClick={() => handleSelectionChange(item?.purchase_item, item)}
            />
          </>
        );
      case 7:
        return (
          <>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              key={index + 1}
              checked={selection?.includes(item?.operation?.id) ? true : false}
              onClick={() => handleSelectionChange(item?.operation?.id, item)}
            />
          </>
        );
    }
  };

  // console.log(DataBaseList, "databasedatabase")
  const dataBaseRows = (type: any) => {
    switch (type) {
      case 1:
        return (
          <>
            {TableData &&
              TableData?.map((item: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className={styles.tableBody}>
                        {" "}
                        {checkboxrender(item, index, 1)}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.material_code}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.catgory}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{ maxWidth: "20rem", wordWrap: "break-word" }}
                      >
                        {item?.material_grade}{" "}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{ maxWidth: "20rem", wordWrap: "break-word" }}
                      >
                        {item?.material_subgrade}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {" "}
                        {item?.shape}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.density}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{
                          textAlign: "right",
                          // display: "flex",
                          // alignItems: "center",
                          // gap: "0.5rem",
                        }}
                      >
                        {item?.material_cost_is_user_input
                          ? item?.material_cost
                          : item?.material_cost_std}
                        {item?.material_cost_is_user_input === false && (
                          <img
                            src={calcImageGreen}
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{ textAlign: "left" }}
                      >
                        {item?.scrap_cost}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{ maxWidth: "25rem", wordWrap: "break-word" }}
                      >
                        {item?.description}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </>
        );
      case 2:
        return (
          <>
            {TableData &&
              TableData?.map((item: any, index: any) => {
                // console.log(item, "itemitem")
                return (
                  <>
                    <TableRow>
                      <TableCell className={styles.tableBody}>
                        {" "}
                        {checkboxrender(item, index, 2)}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.machine?.machine_code}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.machine?.name}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.machine?.brand_name}{" "}
                      </TableCell>
                      {searchValue !== "" && (
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {item?.machine?.commodity_name}{" "}
                        </TableCell>
                      )}

                      <TableCell className={styles.tableBody}>
                        {item?.machine?.description}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {" "}
                        {item?.cost_per_hour}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </>
        );
      case 3:
        return (
          <>
            {TableData &&
              TableData?.map((item: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className={styles.tableBody}>
                        {" "}
                        {checkboxrender(item, index, 3)}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.labour?.labour_code}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.labour?.labour_type}
                      </TableCell>
                      {searchValue !== "" && (
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {item?.labour?.commodity_name}{" "}
                        </TableCell>
                      )}
                      <TableCell className={styles.tableBody}>
                        {item?.labour?.description}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.wage_per_day}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.dearness_allowance}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.perks}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.wage_per_month}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.cost_per_hour}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </>
        );
      case 4:
        return (
          <>
            {TableData &&
              TableData?.map((item: any, index: any) => {
                // console.log(item, "itemitem")
                return (
                  <>
                    <TableRow>
                      <TableCell className={styles.tableBody}>
                        {" "}
                        {checkboxrender(item, index, 4)}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.tools?.code}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.name}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.specification}{" "}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.commodity_name}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.supplier}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.material}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.other}
                      </TableCell>

                      <TableCell className={styles.tableBody}>
                        {item?.tools?.shots}
                      </TableCell>
                      <TableCell
                        className={styles.tableBody}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        {item?.price}
                        {item?.tool_cost_is_user_input === false && (
                          <img
                            src={calcImageGreen}
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.tools?.details}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </>
        );
      case 6:
        if (
          CommodityIds?.category == "Electrical & Electronics Proprietary Parts"
        ) {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.description}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.module_type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.rated_voltage}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.watttage}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.weifrequencyght}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Fasteners") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.code}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.grade}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.material}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.m_size}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.length}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.thread_length}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.weight}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Bearings") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.code}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.bearing_code}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.item_id}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.item_od}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.height}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.weight}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Connectors") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.no_of_pins}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.postion_config}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.shape}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.pin_pitch}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.connection_type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.gender}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.fit_type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.material}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Consumables") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.code}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.description}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Electronic Child Components") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.description}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.length}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.width}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.component_type}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else if (CommodityIds?.category == "Mechanical Proprietory Parts") {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.code}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.description}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.brand_name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.length}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.width}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.height}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.weight}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        } else {
          return (
            <>
              {TableData &&
                TableData?.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={styles.tableBody}>
                          {" "}
                          {checkboxrender(item, index, 6)}
                        </TableCell>

                        <TableCell className={styles.tableBody}>
                          {item?.code}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.name}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.item_detail}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.description}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.length}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.width}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.height}{" "}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.weight}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.place}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.unit_cost}
                        </TableCell>
                        <TableCell className={styles.tableBody}>
                          {item?.reference?.includes("https://") ? (
                            <VisibilityIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.reference)}
                            />
                          ) : (
                            item?.reference
                          )}
                        </TableCell>

                        {/* <TableCell className={styles.tableBody}>{item?.material}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.other}</TableCell>
                                        
                                           <TableCell className={styles.tableBody}>{item?.shots}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.price}</TableCell>
                                           <TableCell className={styles.tableBody}>{item?.details}</TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
            </>
          );
        }

      case 7:
        return (
          <>
            {TableData &&
              TableData?.map((item: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className={styles.tableBody}>
                        {checkboxrender(item, index, 7)}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.operation?.code}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.operation?.category}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.operation?.description}{" "}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.operation?.unit}{" "}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.rate}{" "}
                      </TableCell>
                      <TableCell className={styles.tableBody}>
                        {item?.operation?.remark}{" "}
                      </TableCell>

                      {/* <TableCell>{item?.material}</TableCell>
                                           <TableCell>{item?.other}</TableCell>
                                        
                                           <TableCell>{item?.shots}</TableCell>
                                           <TableCell>{item?.price}</TableCell>
                                           <TableCell>{item?.details}</TableCell> */}
                    </TableRow>
                  </>
                );
              })}
          </>
        );
    }
  };

  const databaseColumns: any = {
    [CALCULATOR_DATABASES_MAP.LABOUR]: [
      {
        key: "labour_code",
        label: "Labour Code",
        control: false,
        headerCellProps: { width: 2 },
      },
      {
        key: "labour_type",
        label: "Labour Type",
        control: false,
        headerCellProps: { width: 2 },
      },
      { key: "description", label: "Description", control: false },
      { key: "wage_per_day", label: "Minimum Wage (INR/Day)", control: false },
      { key: "dearness_allowance", label: "D.A (INR/Month)", control: false },
      { key: "perks", label: "Perks (%)", control: false },
      // { key: "reference", label: "Reference", control: false },
      {
        key: "wage_per_month",
        label: "Minimum Wage (INR/Month)",
        control: false,
      },
      { key: "cost_per_hour", label: "LHR", control: false },
    ],
    [CALCULATOR_DATABASES_MAP.MACHINE]: [
      { key: "machine_code", label: "Machine Code", control: false },
      { key: "name", label: "Name", control: false },
      { key: "brand_name", label: "Brand Name", control: false },
      // { key: "operation", label: "Operation", control: false },
      { key: "description", label: "Description", control: false },
      // { key: "capital_cost", label: "Capital Cost(INR)", control: false },
      // { key: "installation_cost", label: "Installation Cost(INR)", control: false },
      // { key: "life", label: "Life(Years)", control: false },
      // { key: "maintanance", label: "Maintanance(%/Year/Shift)", control: false },
      // { key: "consumption", label: "Consumble Cost(%)", control: false },
      // { key: "power_consumption", label: "Average Power Consumption(kWh)", control: false },
      // { key: "effectiveness", label: "Effectiveness(%)", control: false },
      // { key: "peak_power_factor", label: "Peak Power Factor", control: false },
      // { key: "reference", label: "Reference", control: false },
      // { key: "fixed_cost", label: "Fixed Cost(INR/Year)", control: false },
      // { key: "maintenance_cost", label: "Maintanance Cost(INR/Year)", control: false },
      // { key: "consumption_cost", label: "Consumption Cost(INR/Year)", control: false },
      // { key: "variable_cost", label: "Variable Cost(INR/Year)", control: false },
      {
        key: "cost_per_hour",
        label: "Cost Per Hour(INR/Hour)",
        control: false,
      },
    ],
    // [CALCULATOR_DATABASES_MAP.FASTENER]: [
    //     { key: "fastener_code", label: "Fastener Code", control: false },
    //     { key: "fastener_type", label: "Fastener Type", control: false },
    //     { key: "fastener_detail", label: "Fastener Detail", control: false },
    //     { key: "material", label: "Material", control: false },
    //     { key: "coated", label: "Coated", control: false },
    //     { key: "coating_type", label: "Coating Type", control: false },
    //     { key: "unit_cost", label: "Unit Cost (INR)", control: false },
    //     { key: "volume_cost", label: "Cost/Kg (INR)", control: false },
    // ],
    [CALCULATOR_DATABASES_MAP.TOOL]: [
      { key: "code", label: "Tool Code", control: false },
      { key: "name", label: "Tool Name", control: false },
      { key: "specification", label: "Specification", control: false },
      { key: "category", label: "Catogory", control: false },
      { key: "supplier", label: "Supplier", control: false },
      { key: "material", label: "Material", control: false },
      { key: "other", label: "Other", control: false },
      { key: "shots", label: "No. of Shots", control: false },
      { key: "price", label: "Unit Rate (INR)", control: false },
      { key: "details", label: "Reference", control: false },
    ],
    [CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM]: [
      { key: "code", label: "Purchase Item Code", control: false },
      { key: "name", label: "Purchase Item Name", control: false },
      { key: "item_detail", label: "Item Details", control: false },

      { key: "length", label: "Length (mm)", control: false },
      { key: "width", label: "Width (mm)", control: false },
      { key: "height", label: "Height (mm)", control: false },
      { key: "weight", label: "Weight (kg)", control: false },
      { key: "place", label: "Place of Mfg.", control: false },
      { key: "reference", label: "Reference", control: false },
      { key: "unit_cost", label: "Per unit Cost (INR)", control: false },
    ],
    [CALCULATOR_DATABASES_MAP.MATERIAL]: [
      { key: "material_code", label: "Material Code", control: false },
      { key: "category", label: "Material Category", control: false },
      { key: "material_grade", label: "Grade", control: false },
      { key: "material_subgrade", label: "Sub Grade", control: false },
      { key: "shape", label: "Shape", control: false },
      { key: "density", label: "Density", control: false },
      { key: "description", label: "Remarks", control: false },
      { key: "material_cost", label: "Material Cost", control: false },
      // { key: "material_source", label: "Material Source", control: false },
      // { key: "material_reference", label: "Material Reference", control: false },
      { key: "scrap_cost", label: "Scrap Cost", control: false },
      // { key: "scrap_source", label: "Scrap Source", control: false },
      // { key: "scrap_reference", label: "Scrap Reference", control: false },
      // { key: "specification", label: "Specification", control: false },
      // { key: "application", label: "Application", control: false },
    ],
    [CALCULATOR_DATABASES_MAP.STROKE_RATE]: [
      { key: "code", label: "Operation Code", control: false },
      { key: "category", label: "Category", control: false },
      { key: "description", label: "Description", control: false },
      { key: "unit", label: "Unit", control: false },
      { key: "rate_per_unit", label: "Rate Per Unit", control: false },
      { key: "remark", label: "Remarks", control: false },
    ],
  };

  const handleGroupSelectChange = (item: any) => {
    if (item !== null) {
      setExpanded(undefined);
      setSelectedGroup(item);
      setDataBaseList([]);
      setTableData([]);

      API.get(`cost/assumption/`, { id: item?.id })
        .then((res: any) => {
          setLoader(true);
          setSelectedRevision(res?.data);
          if (database == 2) {
            API.get(
              "/api/db/revision_database/",
              {
                revision_id: res?.data[0]?.machine_revision,
                category_type: "machinedetail",
              },
              0
            )
              .then((res: any) => {
                setDataBaseList(res.data);
                setLoader(false);
              })
              .catch((err: any) => {
                console.log("Server Error");
                setLoader(false);
              });
          } else if (database == 3) {
            API.get(
              "/api/db/revision_database/",
              {
                revision_id: res?.data[0]?.labour_revision,
                category_type: "labourdetail",
              },
              0
            )
              .then((res: any) => {
                setDataBaseList(res.data);
                setLoader(false);
              })
              .catch((err: any) => {
                console.log("Server Error");
                setLoader(false);
              });
          } else if (database == 4) {
            API.get(
              "/api/db/revision_database/",
              {
                revision_id: res?.data[0]?.tools_revision,
                category_type: "toolcost",
              },
              0
            )
              .then((res: any) => {
                setDataBaseList(res.data);
                setLoader(false);
              })
              .catch((err: any) => {
                console.log("Server Error");
                setLoader(false);
              });
          } else if (database == 7) {
            API.get(
              "/api/db/revision_database/",
              {
                revision_id: res?.data[0]?.operations_revision,
                category_type: "operationdatabase",
              },
              0
            )
              .then((res: any) => {
                setDataBaseList(res.data);
                setLoader(false);
              })
              .catch((err: any) => {
                console.log("Server Error");
                setLoader(false);
              });
          }
        })
        .catch((err: any) => {
          ADMIN.toast.error("Server Error");
          setLoader(false);
        });
    } else {
      setExpanded(undefined);
      setSelectedGroup({});
      setDataBaseList([]);
      setTableData([]);
    }
  };

  let OptionList = [...AssumtionIds, AssumptionData[0]];

  // ----------------------------------------------------------------------------------
  // jsx

  const fethTableData = () => {
    const item = {
      category: itemCategory,
      id: itemCategory,
    };

    if (database == 1) {
      const item = {
        category: itemCategory,
      };

      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(itemCategory);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/material_cost/",
        {
          revision: revisiondata?.material_revision,
          category: itemCategory,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 2) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(itemCategory);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/machine_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.machine_revision
              : revisiondata?.machine_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 3) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(itemCategory);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "api/db/labour_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.labour_revision
              : revisiondata?.labour_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 4) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(itemCategory);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      //    console.log(database, "hrbvbfvbfvhbfvhb")
      API.get(
        "api/db/tool_cost/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.tools_revision
              : revisiondata?.tools_revision,
          commodity: item?.id,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setInnerloader(false);
        });
    } else if (database == 7) {
      setPaginationCounter(1);
      setInnerloader(true);
      setExpanded(itemCategory);
      setSearchValue("");
      // setTableData([])
      setCommodityIds(item);
      setSelection(undefined);
      API.get(
        "/api/db/stroke_rate_revision/",
        {
          revision:
            Selectedrevision && Selectedrevision?.length > 0
              ? Selectedrevision && Selectedrevision[0]?.operations_revision
              : revisiondata?.operations_revision,
          category: itemCategory,
          page: 1,
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
          setInnerloader(false);
        })
        .catch((err: any) => {
          setInnerloader(false);
          console.log("Server Error");
        });
    } else if (database == 6) {
      if (item?.category == "Electrical & Electronics Proprietary Parts") {
        setPaginationCounter(1);
        setInnerloader(true);
        setExpanded(itemCategory);
        // setSearchValue('');
        // setTableData([])
        setCommodityIds(item);
        if (searchValue == ``) {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: itemCategory,
              get_integrated_bop: true,
              page: 1,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: itemCategory,
              get_integrated_bop: true,
              item_category: itemCategory,
              search: searchValue?.trim(),
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      } else {
        setPaginationCounter(1);
        setInnerloader(true);
        setExpanded(itemCategory);
        // setSearchValue('');
        // setTableData([])
        setCommodityIds(item);
        // console.log(item, "cxshvjchvjscvcdvhcjvhj")
        setSelection(undefined);
        if (searchValue == "") {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: itemCategory,
              page: 1,
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        } else {
          API.get(
            "api/db/bought_out_cost/",
            {
              revision: revisiondata?.bop_revision,
              commodity: itemCategory,
              item_category: itemCategory,
              search: searchValue?.trim(),
            },
            0
          )
            .then((res: any) => {
              setTableData(res.data);
              setInnerloader(false);
            })
            .catch((err: any) => {
              setInnerloader(false);
              console.log("Server Error");
            });
        }
      }
    }
  };

  useEffect(() => {
    fethTableData();
  }, [itemCategory, database, SelectedGroup, isOpen]);

  console.log(database, "parameter");

  return (
    <Modal className={styles.container} open={isOpen} onClose={onClose}>
      <Box sx={style}>
        {othermodal.isOpen && (
          <OtherAssumptionSingleCardModal
            onCloseModal={othermodal.close}
            isOpen={othermodal.isOpen}
            CalculatorIds={calculatorId}
            SelectedItem={SelectedItem}
            assumptionList={AssumtionIds}
            ApplyCostType={AssumptionHandler}
            selection={selection}
            userInput={userInput}
            DataBaseStatus={DataBaseStatus}
            assumptionListt={assumptionList}
          />
        )}

        {BoughtOutmodal.isOpen && (
          <BoughtOutOtherAssumptionSingleCardModal
            onCloseModal={BoughtOutmodal.close}
            isOpen={BoughtOutmodal.isOpen}
            CalculatorIds={calculatorId}
            SelectedItem={SelectedItem}
            assumptionList={AssumtionIds}
            ApplyCostType={BoughtOutOthermodalHandler}
            selection={selection}
            userInput={userInput}
          />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "2.5rem",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              columnGap: "1rem",
              //   width: "30%",
            }}
          >
            {selection?.length > 0 && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    cursor: "pointer",
                    lineHeight: 1,
                    padding: "0.5rem 1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  onClick={() => {
                    if (!updateCosting?.includes(calctype)) {
                      // If not, push it into the array
                      setUpdateCosting && setUpdateCosting((prevNumbers:any) => [...prevNumbers, calctype]);
                    }
                    if (database == 1) {
                      handleSubmitMaterial();
                    } else if (
                      database == 2 ||
                      database == 3 ||
                      database == 4 ||
                      database == 7
                    ) {
                      handleSubmitRevision();
                    } else {
                      handleSubmit();
                    }
                    
                    // database == 1
                    //   ? handleSubmitMaterial()
                    //   : database == 2 ||
                    //     database == 3 ||
                    //     database == 4 ||
                    //     database == 7
                    //     ? handleSubmitRevision()
                    //     : handleSubmit()
                  }}
                >
                  Fetch
                </Button>
                <Box sx={{ width: "10rem" }}>
                  <Typography>
                    Selected Item:{" "}
                    <span style={{ color: "green" }}>{selection?.length}</span>{" "}
                  </Typography>
                </Box>

                
              </>
            )}
            {calInfo?.database_constant && selection?.length > 0 ? "" : <Box sx={{  }}>
                  <Typography>
                    <span style={{color:"red"}}>*</span> DB Constant Parameter Value:{" "}
                    <span >{calInfo?.value}</span>{" "}
                    <span>
                    <Checkbox sx={{color:"primary.main",     "&.Mui-disabled": {
      color: "primary.main", // Keeps the primary color when disabled
    },
}} disabled checked={selection?.length > 0 ? false : true} />
                    </span>
                  </Typography>
                </Box>}
          </Box>

          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "40%",
              textAlign: "center",
            }}
          >
            {tableHeading(database)}
          </Typography>

          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onClose}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        {AssumtionIds &&
          AssumtionIds?.length > 0 &&
          (database == 2 ||
            database == 3 ||
            database == 4 ||
            database == 7) && (
              <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Box >
                {calInfo?.database_constant && selection?.length > 0 ? <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                  <Typography sx={{width:"18rem"}}>
                    <span style={{color:"red",     
}}>*</span> DB Constant Parameter Value:{" "}
                    <span >{calInfo?.value}</span>{" "}
                   
                  </Typography>
                  <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              
              checked={selection?.length > 0 ? false : true}
              onClick={()=>  setSelection(null)}
              
            />
                  {/* <Checkbox 
                  
                  sx={{color:"primary.main", "&.Mui-disabled": {
      color: "primary.main", // Keeps the primary color when disabled
    },}} disabled checked={selection?.length > 0 ? false : true} /> */}
                </Box> : 
                ""}
                </Box>
            <Box
              sx={{
                display: "flex",

                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                gap: " 3rem",
                marginBottom: "1rem",
              }}
            >
              
              <Typography>Select Assumption :</Typography>
              <Autocomplete
                id="combo-box-demo"
                value={SelectedGroup}
                sx={{
                  width: "20%",
                  ".MuiAutocomplete-root .MuiAutocomplete-input": {
                    fontSize: "1rem",
                  },
                }}
                onChange={(event, newValue) => {
                  handleGroupSelectChange(newValue);
                }}
                options={OptionList && OptionList}
                // @ts-ignore
                getOptionLabel={(option) =>
                  option?.name == undefined ? "" : option?.name
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    placeholder="Select assumption"
                    {...params}
                    variant="standard"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </Box>
            <Box></Box>
            </Box>
          )}

        {
          <Box adl-scrollbar="true" adl-scrollbar-width="0.3">
            {loader ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                <>
                  {DataBaseList && DataBaseList?.length > 0 && (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "1rem",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "22rem",
                            borderBottom: "1px solid #007fff",
                          }}
                        >
                          <InputBase
                            sx={{
                              ml: 1,
                              flex: 1,
                              fontSize: "1rem",
                              width: "20rem",
                            }}
                            type="search"
                            placeholder="Search code/name...."
                            onChange={handleOnChange}
                            onKeyDown={searchAPICall}
                            value={searchValue}
                            inputProps={{ "aria-label": "Search.." }}
                          />
                          <IconButton
                            type="button"
                            sx={{ p: "5px" }}
                            onClick={handleSearchIcon}
                            aria-label="search"
                          >
                            <SearchIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        </Box>
                      </Box>
                      {searchValue == "" &&
                        expanded == undefined &&
                        TableData?.length == 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              height: "48rem",

                              alignItems: "center",
                            }}
                          >
                            <Typography>Select Commodity/Category</Typography>
                          </Box>
                        )}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "0.5rem",
                        }}
                      >
                        {(database === 1 ||
                          database === 6 ||
                          database === 2 ||
                          database === 3 ||
                          database === 4 ||
                          database === 7) && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: { lg: "25rem", xl: "28rem" },
                              height: { lg: "78vh", xl: "80vh" },
                            }}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3"
                          >
                            {DataBaseList &&
                              DataBaseList?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <Box
                                      onClick={() =>
                                        handleChange(
                                          item?.category,
                                          database,
                                          item
                                        )
                                      }
                                      sx={{
                                        display: "contents",
                                        width: "max-content",
                                        backgroundColor:
                                          expanded == item?.category
                                            ? "#007fff"
                                            : "white",
                                        color:
                                          expanded == item?.category
                                            ? "white"
                                            : "#007fff",
                                        padding: " 0.5rem 1.5rem",
                                        cursor: "pointer",
                                        border:
                                          expanded == item?.category
                                            ? "none"
                                            : "1px solid",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          padding: "0.5rem",
                                          backgroundColor:
                                            expanded == item?.category
                                              ? "#007fff"
                                              : "white",
                                          color:
                                            expanded == item?.category
                                              ? "white"
                                              : "#007fff",
                                          width: "100%",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                              width: "100%",
                                              lineHeight: 1,
                                            }}
                                          >
                                            {expanded == item?.category ? (
                                              <FolderOpenIcon />
                                            ) : (
                                              <FolderIcon />
                                            )}{" "}
                                            {item.category}
                                          </Box>
                                          <Typography
                                            sx={{
                                              width: "5rem",
                                              textAlign: "right",
                                            }}
                                          >
                                            ({item?.count})
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                          </Box>
                        )}
                        <Box
                          sx={{
                            height: { lg: "72vh", xl: "75vh" },
                            width: "100%",
                            position: "relative",
                          }}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3"
                        >
                          {Innerloader ? (
                            <>
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                              <Skeleton />
                            </>
                          ) : (
                            <>
                              {TableData?.length > 0 && (
                                <Table stickyHeader aria-label="sticky table">
                                  {databaseColumns1(database)}
                                  <TableBody>
                                    {dataBaseRows(database)}
                                  </TableBody>
                                </Table>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>

                      {searchValue == "" &&
                        PageCount !== 1 &&
                        TableData &&
                        TableData?.length > 0 && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "0.6rem",
                              right: "1rem",
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            <Box>
                              <Button
                                size="small"
                                disabled={
                                  PaginationCounter !== 1 ? false : true
                                }
                                startIcon={
                                  <ArrowBackIosIcon sx={{ margin: "0rem" }} />
                                }
                                sx={{ cursor: "pointer" }}
                                onClick={() => PreviousHandler()}
                              >
                                Previous ({PaginationCounter - 1})
                              </Button>
                            </Box>

                            <Box>
                              <Button
                                size="small"
                                disabled={
                                  PageCount <= PaginationCounter ? true : false
                                }
                                endIcon={<ArrowForwardIosIcon />}
                                sx={{ cursor: "pointer" }}
                                onClick={() => NextHandler()}
                              >
                                Next ({PageCount - PaginationCounter})
                              </Button>
                            </Box>
                          </Box>
                        )}
                    </>
                  )}
                </>
              </>
            )}
          </Box>
        }
      </Box>
    </Modal>
  );
};

// ----------------------------------------------------------------------------------

export default DatabaseSelectionModal;
