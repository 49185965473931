import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FolderIcon from "@mui/icons-material/Folder";
import Divider from "@mui/material/Divider";
import ChatIcon from "@mui/icons-material/Chat";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <FolderIcon sx={{ fontSize: "1.4rem", color: "primary.main" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

type Group = {
  id: number;
  name: string;
  chats: Chat[];
};

type Chat = {
  chat_id: number;
  chat_name: string;
};

export interface IMyChatComponentProps {
  setIsEmptyGroup: any;
  reCallgrooup: any;
  setWindowExpanded: any;
  expandedWindow: any;
}

export default function MyChatComponent(props: IMyChatComponentProps) {
  const { setIsEmptyGroup, reCallgrooup, setWindowExpanded, expandedWindow } =
    props;
  const { grpId, chatId, userId, tab,grpName,chatName } = useRouteParams<any>();
  const history = useHistory();
  const [groups, setGroups] = useState<Group[]>([]);
  const [grpLoading, setGrpLoading] = useState<boolean>(false);
  const [openGroups, setOpenGroups] = useState<number[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "addGroup" | "editGroup" | "addChat" | "editChat"
  >("addGroup");
  const [currentGroupId, setCurrentGroupId] = useState<number | null>(null);
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const fetchGroups = async () => {
    setGrpLoading(true);
    API.get("/xcpep/get_LLM_search_history/", { header_list: true }, 0)
      .then((res: any) => {
        setGroups(res.data);
        setGrpLoading(false);
        if (
          grpId == 0 &&
          chatId == 0 &&
          res?.data?.length > 0 &&
          res?.data[0]?.chats?.length > 0
        ) {
          history.push(
            `/chat/${res.data[0].id}/${res.data[0].chats[0]?.chat_id}/${userId}/${tab}/${res.data[0]?.name}/${res?.data[0]?.chats[0]?.chat_name}`
          );
        }
        if (res?.data?.length > 0) {
          setIsEmptyGroup(false);
        } else {
          setIsEmptyGroup(true);
        }
        setOpenGroups(res.data?.map((group: any) => group.id)); // Open all groups initially
      })
      .catch((e: any) => {
        setGrpLoading(false);
        setIsEmptyGroup(true);
      });
  };
  const fetchGroupsWol = async () => {
    API.get("/xcpep/get_LLM_search_history/", { header_list: true }, 0)
      .then((res: any) => {
        setGroups(res.data);
        if (
          grpId == 0 &&
          chatId == 0 &&
          res?.data?.length > 0 &&
          res?.data[0]?.chats?.length > 0
        ) {
          history.push(
            `/chat/${res.data[0].id}/${res.data[0].chats[0]?.chat_id}/${userId}/${tab}/${res.data[0]?.name}/${res?.data[0]?.chats[0]?.chat_name}`
          );
        }
        if (res?.data?.length > 0) {
          setIsEmptyGroup(false);
        } else {
          setIsEmptyGroup(true);
        }
        setOpenGroups(res.data?.map((group: any) => group.id)); // Open all groups initially
      })
      .catch((e: any) => {
        setGrpLoading(false);
        setIsEmptyGroup(true);
      });
  };

  const handleAddGroup = async (name: string) => {
    API.post(
      `/xcpep/get_LLM_search_history/`,
      { group_name: name },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Group Added Successfully");
      history.push(`/chat/0/0/${userId}/${tab}/${name}/0`);
      fetchGroups();
    });
  };

  const handleEditGroup = async (groupId: number, name: string) => {
    API.put(
      `/xcpep/get_LLM_search_history/${groupId}/`,
      { name: name, group: true },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Group Updated Successfully");
      fetchGroups();
    });
  };

  const handleDeleteGroup = async (groupId: number) => {
    API.delete(
      `/xcpep/get_LLM_search_history/${groupId}/`,
      { group: true },
      0
    ).then(() => {
      ADMIN.toast.info("Group Deleted Successfully");
      history.push(`/chat/0/0/${userId}/${tab}/0/0`);
      fetchGroups();
    });
  };

  const handleAddChat = async (groupId: number, name: string) => {
    API.post(`/xcpep/get_LLM_search_history/`, { group: groupId }, {}, 0).then(
      () => {
        ADMIN.toast.info("Chat Added Successfully");
        history.push(`/chat/${groupId}/0/${userId}/${tab}/${grpName}/${name}`);
        fetchGroups();
      }
    );
  };

  const handleEditChat = async (chatId: number, name: string) => {
    API.put(
      `/xcpep/get_LLM_search_history/${chatId}`,
      { name: name, chat: true },
      {},
      0
    ).then(() => {
      ADMIN.toast.info("Chat Updated Successfully");
      fetchGroups();
    });
  };

  const handleDeleteChat = async (chatId: number) => {
    API.delete(
      `/xcpep/get_LLM_search_history/${chatId}/`,
      { chat: true },
      0
    ).then(() => {
      ADMIN.toast.info("Group Deleted Successfully");
      history.push(`/chat/0/0/${userId}/${tab}/${grpName}/0`);
      fetchGroups();
    });
  };

  useEffect(() => {
    fetchGroupsWol();
  }, [reCallgrooup]);

  React.useEffect(() => {
    fetchGroups();
  }, []);

  const toggleGroupOpen = (groupId: number) => {
    setOpenGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  const handleDialogOpen = (
    type: "addGroup" | "editGroup" | "addChat" | "editChat",
    groupId?: number,
    chatId?: number
  ) => {
    setDialogType(type);
    setCurrentGroupId(groupId ?? null);
    setCurrentChatId(chatId ?? null);
    setInputValue("");
    if (type === "editGroup" && groupId) {
      const group = groups.find((g) => g.id === groupId);
      setInputValue(group?.name || "");
    }
    if (type === "editChat" && chatId) {
      const group = groups.find((g) =>
        g.chats.some((c) => c.chat_id === chatId)
      );
      const chat = group?.chats.find((c) => c.chat_id === chatId);
      setInputValue(chat?.chat_name || "");
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = () => {
    switch (dialogType) {
      case "addGroup":
        handleAddGroup(inputValue);
        break;
      case "editGroup":
        if (currentGroupId) handleEditGroup(currentGroupId, inputValue);
        break;
      case "addChat":
        if (currentGroupId) handleAddChat(currentGroupId, inputValue);
        break;
      case "editChat":
        if (currentChatId) handleEditChat(currentChatId, inputValue);
        break;
    }
    handleDialogClose();
  };

  return (
    <div>
      {!grpLoading ? (
        groups.map((group) => (
          <Box key={group.id}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ backgroundColor: "transparent" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "5px",
                  "&:hover .action-buttons": {
                    visibility: "visible",
                  },
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{
                    backgroundColor: "transparent",
                    minHeight: "24px",
                    padding: "0",
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    sx={{
                      marginLeft: "0.5rem",
                      color: "#1976d2",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                    onClick={(ed: any) => {
                      ed.stopPropagation();
                      ed.preventDefault();
                      history.push(`/chat/${group.id}/0/${userId}/${tab}/${group.name}/0`);
                      setSelectedGroupId(group.id);
                    }}
                  >
                    {group?.name} ({group?.chats?.length})
                  </Typography>
                </AccordionSummary>
                <Box
                  className="action-buttons"
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    visibility: "hidden",
                  }}
                >
                  <IconButton
                    title="Add Chat"
                    size="small"
                    onClick={() => {
                      history.push(`/chat/${grpId}/0/${userId}/${tab}/${grpName}/0`);
                    }}
                  >
                    <Add color="primary" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDialogOpen("editGroup", group.id)}
                  >
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteGroup(group.id)}
                  >
                    <Delete sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              </Box>

              <AccordionDetails>
                <Box
                  sx={{
                    marginLeft: "0.7rem",
                    borderLeft: "1px dashed",
                    borderColor: "primary.main",
                  }}
                >
                  {group?.chats?.map((chat) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "5px",
                        paddingLeft: "0.7rem",
                        cursor: "pointer",
                        backgroundColor:
                          chatId == chat.chat_id ? "primary.light" : "",
                        "&:hover .action-buttons": {
                          visibility: "visible",
                        },
                      }}
                    >
                      <Box sx={{ margin: "4px 0", display: "flex" }}>
                        <ChatIcon />
                      </Box>
                      <Tooltip title={chat.chat_name}>
                        <ListItemText
                          onClick={(ed: any) => {
                            ed.stopPropagation();
                            ed.preventDefault();
                            history.push(
                              `/chat/${group.id}/${chat.chat_id}/${userId}/${tab}/${group.name}/${chat.chat_name}`
                            );
                            setSelectedChatId(chat.chat_id);
                          }}
                          primary={
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                              }}
                            >
                              {chat.chat_name}
                            </Typography>
                          }
                        />
                      </Tooltip>

                      <Box
                        className="action-buttons"
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                          visibility: "hidden",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleDialogOpen("editChat", group.id, chat.chat_id)
                          }
                        >
                          <Edit color="primary" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteChat(chat.chat_id)}
                        >
                          <Delete sx={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            gap: "0.5rem",
          }}
        >
          <Skeleton variant="rectangular" width={300} height={100} />
          <Skeleton variant="rectangular" width={300} height={100} />
          <Skeleton variant="rectangular" width={300} height={100} />
        </Box>
      )}
      {(!grpLoading && groups && groups?.length > 0) && (
        <Box sx={{ display: "flex", gap: 2, marginBottom: 1 }}>
          <Divider
            sx={{
              width: "100%",
              alignItems: "flex-start",
              borderColor: "primary.light",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              sx={{ whiteSpace: "nowrap" }}
              startIcon={<Add />}
              onClick={() => handleDialogOpen("addGroup")}
              disabled={grpId == 0 ? true : false}
            >
              Add Group
            </Button>
          </Divider>
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogType === "addGroup"
            ? "Add Group"
            : dialogType === "editGroup"
            ? "Edit Group"
            : dialogType === "addChat"
            ? "Add Chat"
            : "Edit Chat"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}