import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import SkeletonLoader from "../../SkeletonLoader/SkeletonLoader";

export interface IChecmicalCompositionRowDataUpdateProps {
  onCloseModal: () => void;
  isOpen: boolean;
  selectedRowData: any;
  selectedRowId: number;
  replaceselectedOption?: any;
  setReplacelectedOption?: any;
  handleStatusChange?: any;
  handleRejectStatusChange?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  maxHeight: "96vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
  overflow: "auto",
};

export default function ChecmicalCompositionRowDataUpdate(props: IChecmicalCompositionRowDataUpdateProps) {
  const { onCloseModal, isOpen, selectedRowData, selectedRowId, handleStatusChange, handleRejectStatusChange } = props;
  const [replaceColumns, setReplaceColumns] = React.useState<any[]>([]);
  const [replaceLoader, setReplaceLoader] = React.useState<boolean>(false);


  React.useEffect(() => {
    setReplaceLoader(true);
    API.get("/xcpep/Upload_Chemical_Composition_value_PDF/", { part_no: selectedRowData?.part_no, replace: true, }, 0)
      .then((res: any) => {
        setReplaceColumns(res?.data?.[0]);
        setReplaceLoader(false);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.data) {
          const { data } = err.response;
          setReplaceLoader(false);
        }
      });
  }, [selectedRowData]);

  const handleAccept = (rowId: number, status: boolean) => {
    handleStatusChange(rowId, status);
    onCloseModal();
  };

  const handleReject = (rowId: number, status: boolean) => {
    handleRejectStatusChange(rowId, status);
    onCloseModal();
  }
  // console.log("Selected Row Data: ", replaceColumns);

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal} aria-labelledby="modal-title">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Choose the Chemical Composition to be updated
            </Typography>
            <CancelIcon
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light", margin: "0 1rem", mb: "1rem" }} />
          <Box sx={{ width: '100%', maxHeight: '70vh', padding: '0 1rem' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", width: "15rem" }}>
                      Chemical Composition
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", width: "8rem" }} align="right">
                      Existing Value
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", width: "8rem" }} align="right">
                      New Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(new Set([...Object.keys(selectedRowData), ...Object.keys(replaceColumns)])) // Get unique keys
                    .filter((key) =>
                      key !== "id" && key !== "part_no" && key !== "status" && key !== "part_name" && key !== "old_details_exists" && key !== "doc_url"  && key !== "Delta" && key !== "choose_material"  && key !== "rejected" // Exclude metadata fields
                    )
                    .filter((key: any) => {
                      // Ensure we show only rows where at least one value is present
                      const newValue = selectedRowData[key] ? String(selectedRowData[key]) : "--";
                      const existingValue = replaceColumns[key] && replaceColumns[key] !== "---" ? String(replaceColumns[key]) : "--";

                      return newValue !== "--" || existingValue !== "--"; // Show only if at least one column has a value
                    })
                    .map((key: any, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ padding: "0.2rem 0.5rem" }}>{key.replace("-CC", "")}</TableCell> {/* Clean key name */}
                        <TableCell sx={{ padding: "0.2rem 0.5rem" }} align="right">
                          {replaceColumns[key] && replaceColumns[key] !== "---" ? String(replaceColumns[key]) : "--"} {/* Show Existing Value or "--" */}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem" }} align="right">
                          {selectedRowData[key] ? String(selectedRowData[key]) : "--"} {/* Show New Value or "--" */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "1rem", width: "100%", gap: "2rem", padding: '0 0rem' }}>
              {/* <Button size="small" sx={{
                backgroundColor: '#ff7f7e', color: "#fff", padding: '0.2rem 0.5rem',
                ':hover': {
                  backgroundColor: "#ff7f7e",
                },
              }} onClick={() => handleReject(selectedRowId, false)} >Reject New Value</Button> */}
              <Button size="small" sx={{
                // backgroundColor: '#ff7f7e', color: "#fff", padding: '0.2rem 0.5rem',
                // ':hover': {
                //   backgroundColor: "#ff7f7e",
                // },
              }} onClick={() => onCloseModal()} >Cancel</Button>
              <Button size="small" sx={{
                backgroundColor: '#c6e8ab', color: "#000", padding: '0.2rem 0.5rem', ':hover': {
                  backgroundColor: "#c6e8ab",
                },
              }} onClick={() => handleAccept(selectedRowId, true)}>Accept New Value</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
