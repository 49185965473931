import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./BOMEntrySection.module.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import { ADMIN } from "../../../Redux/Services/admin.service";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberInputBox } from "./NumberInputBox";
import { TextInputBox } from "./TextInputBox";
import { DescriptionInputBox } from "./DescriptionInputBox";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import BomEntryTableComponent from "./BomEntryTableComponent";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import {
  getCustomValidations,
  resetCustomValidations,
} from "../../../Redux/Actions/customValidations.actions";
import {
  getBOMConfigurationsByCategory,
  resetParameterConfigs,
} from "../../../Redux/Actions/parameterConfig.actions";
import {
  getMappedWorkstations,
  resetWorkstations,
} from "../../../Redux/Actions/workstation.actions";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { BooleanInputBox } from "./BooleanInputBox";
import { DateInputBox } from "./DateInputBox";
import { DatabaseInputBox } from "./DatabaseInputBox";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { CheckBoxComponent } from "./CheckBoxComponent";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import LoadingButton from "@mui/lab/LoadingButton";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import BOMImageBasedSearchModal from "./BOMImageBasedSearchModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { AxiosError } from "axios";
import { AUTH } from "../../../Redux/Services/auth.service";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import BOMValidationView from "./BOMValidationView";
import BPMPCBRoboflowModal from "./BOMPCBRobodlowModal";

import BOMDataBaseSection from "./BOMDataBaseSection";
import BOMDatabaseModal from "./BOMDatabaseModal";

export interface IBOMEntrySectionProps {
  setrecyclebinCounter: any;
  setParentsCounter: any;
  ParentsCounter: any;
  setSyncColor: any;
  setCategoryCounter: any;
  categoryCounter: any;
  setBomCategory?: any;
  bomCategory?: any;
  setLocatorCount?: any;
}

export default function BOMEntrySection(props: IBOMEntrySectionProps) {
  const {
    setrecyclebinCounter,
    setParentsCounter,
    ParentsCounter,
    setSyncColor,
    setCategoryCounter,
    categoryCounter,
    setBomCategory,
    bomCategory,
    setLocatorCount,
  } = props;
  const history = useHistory();
  const validationViewModal = useBaseParamsModal();
  const roboFlowModal = useBaseParamsModal();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const inputFieldRef = React.useRef<any>();
  const nextInputFieldRef = React.useRef<any>();
  const {
    projectId,
    projectName,
    vault,
    topVault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
  } = useRouteParams<any>();
  const bomdbModal = useBaseParamsModal();
  const [workStationList, setWorkStationList] = React.useState<any>([]);
  const [workstationLoader, setWorkstationLoader] =
    React.useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
  const [loadingMaterial, setLoadingMaterial] = React.useState<any>(null);
  const [partName, setPartName] = React.useState<any>("");
  const [oldPartName, setOldPartName] = React.useState<any>("");
  const [searchValueOptions, setSearchVaultsOptions] = React.useState<any>();
  const [PartData, setPartData] = React.useState<any>();
  const [partQty, setPartQty] = React.useState<any>("");
  const [category, setCategory] = React.useState<any>("");
  const [BOMDetails, setBOMDetails] = React.useState<any>([]);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [BOMparameters, setBOMparameters] = React.useState<any>([]);
  const [isBOMparametersLoading, setIsBOMparametersLoading] =
    React.useState<boolean>(false);
  const [details, setDetails] = React.useState<any>({});
  const [BOMStatus, setBOMStatus] = React.useState<any>("");
  const [weightLockStatus, setWeightLockStatus] = React.useState<any>(false);
  const [OriginaLoader, setOriginaLoader] = React.useState<any>(false);
  const [loadingStatus, setLoadingstatus] = React.useState<any>(false);
  const [loadingWeightLock, setLoadingWeightLock] = React.useState<any>(false);
  const [ProductloadingWeightLock, setProductloadingWeightLock] =
    React.useState<any>(false);
  const [ProductWeightLock, setProductWeightLock] = React.useState<any>(false);
  const [tableDataGetCount, setTableDataGetCount] = React.useState<any>(1);
  const [allValues, setAllValues] = React.useState<any>({});
  const userAction = React.useContext(userPermissionAction);
  const [counter, setCounter] = React.useState<any>(0);
  const [leafNode, setLeafNode] = React.useState<any>(false);

  let UserAction = userAction && userAction?.action;
  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { top_vault: tv };
    } else {
      return { vault: vlt };
    }
  };

  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { top_vault: tv };
    } else {
      return { vault: v };
    }
  };
  const getVaultDetails = () => {
    setLoadingWeightLock(true);
    API.get(
      "/xcpep/vault_info/",
      {
        ...obj(topVault, vault),
        module: 9,
        current_top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setDetails(res.data[0]);
        // setBOMStatus(res.data[0]?.status)

        setWeightLockStatus(res.data[0]?.weight_lock);
        setLoadingWeightLock(false);
      })
      .catch((err: any) => { setLoadingWeightLock(false)});
  };

  const getAllValues = () => {
    API.get("/xcpep/component_details/", { ...obj(topVault, vault) }, 0)
      .then((res: any) => {
        setAllValues(res.data[0]?.details);
        setCounter((prev: any) => prev + 1);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getAllValues();
  }, [vault, topVault]);

  const BOMValidationStatus = () => {
    API.get(
      "/xcpep/bom_hirearchy_function/",
      {
        bom_status: true,
        ...obj(topVault, vault),
      },
      0
    )
      .then((res: any) => {
        setBOMStatus(res.data?.bom_status);
      })
      .catch((err: any) => {});
  };

  console.log(BOMStatus, "BOMStatus");
  

  React.useEffect(() => {
    BOMValidationStatus();
  }, [vault, topVault, BOMStatus]);

  React.useEffect(() => {
    if (ParentsCounter !== 0) {
      BOMValidationStatus();
    }
  }, [ParentsCounter]);

  React.useEffect(() => {
    getVaultDetails();
  }, [vault, topVault]);
  const getBOMParametersData = () => {
    setBOMparameters([]);
    setIsBOMparametersLoading(true);
    if (workstation != 0) {
      API.get(
        "/xcpep/bom_create_view/",
        {
          ...obj(topVault, vault),
          part_details: true,
          workstation: workstation,
        },
        0
      )
        .then((res: any) => {
          setIsBOMparametersLoading(false);
          setBOMparameters(res.data);
          // setCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          setIsBOMparametersLoading(false);
        });
    }
  };

  useQueryDispatch({
    query: {
      action: getCustomValidations,
      arg: { categoryId: category && category, topVaultId: +topVault },
      condition: Boolean(1),
    },
    cleanup: {
      action: resetCustomValidations,
      args: [],
    },
    dependency: [topVault, vault, category],
  });

  useQueryDispatch({
    query: {
      action: getMappedWorkstations,
      arg: { topVaultId: +topVault, vaultId: +vault },
    },
    cleanup: {
      action: resetWorkstations,
      args: [],
    },
    dependency: [topVault, vault, category],
  });

  const configsFetched = useQueryDispatch({
    query: {
      action: getBOMConfigurationsByCategory,
      arg: {
        topVaultId: +topVault,
        categoryId: category,
        all: true,
      },
      condition: Boolean(category),
    },
    cleanup: {
      action: resetParameterConfigs,
      args: [],
    },
    dependency: [topVault, vault, category],
  });

  React.useEffect(() => {
    getBOMParametersData();
  }, [vault, workstation, category, topVault]);

  const getWorkStationsData = () => {
    setWorkstationLoader(true);
    API.get(
      "/auth/workstation/",
      {
        top_vault: topVault,
        vault: vault != 0 ? vault : undefined,
        workstation_list: true,
      },
      0
    )
      .then((res: any) => {
        setWorkstationLoader(false);
        setWorkStationList(res.data);
        if (workstation == 0) {
          history.push(
            `/bomentry/${projectId}/${projectName}/${topVault}/${vault}/${treeabbr}/${res.data[0]?.id}/${isLeaf}/${copyStatus}`
          );
        }
      })
      .catch((err: any) => {
        setWorkstationLoader(false);
      });
  };
  const getCategoryList = () => {
    API.get(
      "/config/template_config/",
      {
        top_vault: topVault,
        bom_create: true,
        is_leaf: isLeaf,
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getCategoryList();
  }, [vault, topVault]);

  const getBOMTVDetailsData = () => {
    setProductloadingWeightLock(true);
    API.get(
      "/xcpep/top_vault/",
      {
        top_vault: topVault,
        module: 9,
      },
      0
    )
      .then((res: any) => {
        setProductWeightLock(res.data[0]?.weight_lock);
        setBOMDetails(res.data[0]);
        setPartData(res?.data[0]?.product_id);
        setPartName(res.data[0]?.name);
        setOldPartName(res.data[0]?.name);
        setCategory(res.data[0]?.category);
        setBomCategory(res.data[0]?.category);
        sessionStorage.setItem("BOM CategoryId", res.data[0]?.category);
        setPartQty(res.data[0]?.quantity);
        setProductloadingWeightLock(false);
      })
      .catch((err: any) => {
        setProductloadingWeightLock(false);
      });
  };
  const getVaultBOMDetailsData = () => {
    API.get(
      "/xcpep/vault_info/",
      {
        ...obj(topVault, vault),
        module: 9,
      },
      0
    )
      .then((res: any) => {
        setPartName(res.data[0]?.name);
        setOldPartName(res.data[0]?.name);
        setPartData(res?.data[0]?.part_no);
        setCategory(res.data[0]?.category);
        setBomCategory(res.data[0]?.category);
        setPartQty(res.data[0]?.quantity);
        setBOMDetails(res.data[0]);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    if (vault == 0) {
      getBOMTVDetailsData();
    } else {
      getVaultBOMDetailsData();
    }
  }, [topVault, vault]);
  React.useEffect(() => {
    getWorkStationsData();
  }, [ParentsCounter, vault, topVault, category]); //topvalut & valut removed here for multilple id select looping issue
  const getUrl = (vltt: any) => {
    if (vltt == 0) {
      return "/xcpep/top_vault";
    } else {
      return "/xcpep/vault_info";
    }
  };
  const updateDataVault = async (
    name: any,
    value: any,
    vlt: any,
    topvlt: any,
    rt: any
  ) => {
    API.put(
      `${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
      {
        [name]: value,
        validated: false,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        if (name === "name") {
          setOldPartName(value);
        }
        BOMValidationStatus();
        getWorkStationsData();

        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const updateDataVaultParams = async (
    name: any,
    value: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/xcpep/component_details/74699/`,
      {
        [name]: value,
      },
      {
        ...getobj(vlt, tv),
      },
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        // getBOMParametersData();
        BOMValidationStatus();
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        if (err.response && data) {
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const updateDataVaultCheckbox = async (
    name: any,
    value: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/xcpep/component_details/74699/`,
      {
        [name]: value,
      },
      {
        ...getobj(vlt, tv),
      },
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        getBOMParametersData();
        BOMValidationStatus();
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const ExactDebounce = React.useRef(
    debounce(async (value: any, name: any, idd: any, topvlt: any, rt: any) => {
      await updateDataVault(name, value, idd, topvlt, rt);
    }, 1000)
  ).current;

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any, tv) => {
      await updateDataVaultParams(name, value, idd, tv);
    }, 1000)
  ).current;

  const ExactDebounceCheckBox = React.useRef(
    debounce(async (value: any, name: any, idd: any, tv: any) => {
      await updateDataVaultCheckbox(name, value, idd, tv);
    }, 100)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
      ExactDebounceParameters.cancel(); // Cancel the debounced function
    };
  }, []);

  const handleChangeInput = (event: any) => {
    const { name, value } = event.target;

    if (name === "name" || name === "quantity") {
      if (name == "name") {
        setPartName(value);
      } else if (name === "quantity") {
        setPartQty(value);
        if (name === "name" && partName != oldPartName) {
          // ExactDebounce(+value, name, vault, topVault, "wo");
        } else {
          // ExactDebounce(+value, name, vault, topVault, "wo");
        }
      }
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will delete the data mapped with the previous category and costing will be deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result: any) => {
        if (result.isConfirmed == true) {
          API.put(
            `${getUrl(vault)}/${vault != 0 ? +vault : +topVault}/`,
            {
              [name]: value,
              validated: false,
            },
            {},
            0
          )
            .then((res: any) => {
              setSyncColor("red");
              BOMValidationStatus();
              API.get(
                "/auth/workstation/",
                {
                  top_vault: topVault,
                  vault: vault != 0 ? vault : undefined,
                  workstation_list: true,
                },
                0
              )
                .then((res: any) => {
                  setWorkstationLoader(false);
                  setWorkStationList(res.data);

                  setCategory(value);
                  setBomCategory(value);
                  setCategoryCounter((prev: any) => prev + 1);
                  // if (workstation == 0) {
                  //   history.push(
                  //     `/bomentry/${projectId}/${projectName}/${topVault}/${vault}/${treeabbr}/${res.data[0]?.id}/${isLeaf}/${copyStatus}`
                  //   );
                  // }
                })

                .catch((err: any) => {
                  setWorkstationLoader(false);
                });
              getBOMParametersData();
              setTableDataGetCount((prev: any) => prev + 1);
              ADMIN.toast.info("Updated Successfully");
            })
            .catch((err: any) => {
              const { data } = err.response;
              Swal.fire({
                title: "Error!",
                text: data[0],
                icon: "error",
                confirmButtonText: "Ok",
              });
            });
        }
      });
    }
  };

  // const getChecked = (itemm: any) => {
  //   if (itemm?.exceptions?.includes(itemm?.value)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  const OriginalPartRounting = () => {
    setOriginaLoader(true);

    API.get("xcpep/vault_copy_info/", { vault_id: vault })

      .then((res: any) => {
        sessionStorage.setItem(
          "BOMCreateNode",

          JSON.stringify(res?.data?.ansector)
        );

        setOriginaLoader(false);

        window.open(
          `/#/bomentry/${projectId}/${projectName}/${topVault}/${
            res?.data?.id
          }/${treeabbr}/${workstation}/${isLeaf}/${false}`,

          "_self"
        );
      })

      .catch((err: any) => {
        console.log("Sever Error");

        setOriginaLoader(false);
      });
  };

  const handleSelectSystems = (
    event: any,
    newSystemValue: any,
    key: any,
    v: any,
    tv: any
  ) => {
    ExactDebounceParameters(newSystemValue, key, v, tv);
  };

  const [openRow, setOpenRow] = React.useState<number | null>(null);

  const updateMaterialDetails = (material: any, paramId: any) => {
    const updatedMaterialsArray = BOMparameters?.map((item: any) => {
      //console.log(item?.material_details, material?.material_details, "parameter");

      // Check if parameter id matches
      if (item?.key == paramId) {
        return {
          ...item,
          material_details: material,
        };
      }
      return item;
    });

  };

  const materialFields = [
    { key: "material_code", text: "Material Code" },
    { key: "material_grade", text: "Grade" },
    { key: "material_subgrade", text: "Sub Grade" },
    // { key: "material_cost", text: "Material Cost" },
    // { key: "scrap_cost", text: "Scrap Cost" },
    { key: "shape", text: "Initial Shape" },
    { key: "density", text: "Density" },
    { key: "currency_code", text: "Currency" },
    { key: "unit", text: "Unit" },
    { key: "material_source", text: "Material Source" },
    { key: "material_reference", text: "Material Ref" },
    { key: "scrap_source", text: "Scrap Source" },
    { key: "scrap_reference", text: "Scrap Ref" },

    // Bearing
    { key: "name", text: "Item Name" },
    { key: "code", text: "Code" },
    { key: "height", text: "Height" },
    { key: "weight", text: "Weight" },
    { key: "item_id", text: "ID (mm)" },
    { key: "item_od", text: "OD (mm)" },
    { key: "reference", text: "Reference" },
    { key: "unit_cost", text: "Per Unit Cost" },
// Connector
    { key: "no_of_pins", text: "No. of pins" },
    { key: "postion_config", text: "Position config (mxn)" },
    { key: "shape", text: "Shape" },
    { key: "pin_pitch", text: "Terminal pin pitch" },
    { key: "connection_type", text: "Connection type" },
    { key: "gender", text: "Male/Female" },
    { key: "fit_type", text: "Fit Type" },
    { key: "material", text: "MAterial" },

    // Consumables
    { key: "description", text: "Description" },
    { key: "commodity_name", text: "Item Name" },
    
      //Electrical Components
     // Bulb
     { key: "type", text: "Type" },
     { key: "rated_voltage", text: "Rated Voltage" },
     { key: "base_code", text: "Base Code" },
     { key: "no_of_filament", text: "No. of filaments" },
     { key: "watttage", text: "Filament Wattage" },

      // Remote/lock
     { key: "sensing_technology", text: "Sensing Technology" },
     { key: "type_of_key_fob", text: "Type of key fob" },
     { key: "no_of_function", text: "No. of functions" },
     { key: "function", text: "Functions" },
     { key: "range", text: "Range" },

      // Horn
     { key: "current_rating", text: "Current Rating" },
     { key: "power_rating", text: "Power Rating" },
     { key: "frequency", text: "Frequency" },
     { key: "sound_level", text: "Sound Level" },

     // Ignition
     { key: "no_of_position", text: "No. of positions" },
     { key: "no_of_terminal", text: "No. of terminals" },
     { key: "with_handle_lock", text: "With handle lock" },
    
    // Flasher
     { key: "frequency", text: "Flasher frequency" },
     { key: "watttage", text: "Wattage" },
     { key: "adjustable", text: "Adjustable" },
         
     // Electronic Child Part
     { key: "length", text: "Length" },
     { key: "width", text: "Width" },
     { key: "component_type", text: "Component Type" },

           
     // Fastener
     { key: "type", text: "Type of fastener" },
     { key: "grade", text: "Grade" },
     { key: "m_size", text: "M-Size" },
     { key: "thread_length", text: "Thread Length" },

     // Mechanical
     { key: "brand_name", text: "Brand and Model" },
         
     // Uncategorized
     { key: "place", text: "Place of Mfg" },
    
    

  ];

  const renderInputs = (item: any, index: any) => {
    switch (item?.data_type) {
      case "number":
      case "float":
      case "nonzero-positive-int":
      case "nonzero-positive-float":
      case "positive-float":
      case "nonzero-float":
      case "percentage":
      case "positive-int":
      case "nonzero-integer":
      case "integer":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <NumberInputBox
                  item={item}
                  details={details && details}
                  vaultIdd={vault}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "text":
      case "string":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <TextInputBox
                  item={item}
                  details={details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              }
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "textarea":
      case "longtext":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <DescriptionInputBox
                  item={item}
                  details={details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              }
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "dropdown":
      case "singleselect":
      case "country":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>

            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <SingleSelectInputBox
                item={item}
                details={details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "multiselect":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  disabled={
                    (copyStatus == false || copyStatus == "false") &&
                    (UserAction?.includes("U") || UserAction?.includes("C"))
                      ? false
                      : true
                  }
                  limitTags={1}
                  // onChange={handleOnChange}
                  disableClearable
                  disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={item?.allowed_values}
                  getOptionLabel={(option: any) => option}
                  defaultValue={item?.value || []}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectSystems(
                      event,
                      newSystemValue,
                      item?.key,
                      vault,
                      topVault
                    );
                  }}
                  // getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "12px" }}>
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Option"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "transparent",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "12px !important",
                          backgroundColor: "rgba(255,255,255,0.3)",
                          paddingLeft: "0.3rem",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "boolean":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <BooleanInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "date":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <DateInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );

      case "database":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <DatabaseInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                "NA"
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.6rem 0.5rem",
                borderBottomColor: "primary.light",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item?.unit ? item?.unit : "-"}{" "}
              {item?.info_doc &&
                item?.info_doc !== null &&
                item?.info_doc !== undefined &&
                item?.cdn &&
                item?.cdn !== null &&
                item?.cdn !== undefined && (
                  <Tooltip title="Guidelines">
                    <VisibilityIcon
                      sx={{ cursor: "pointer", fontSize: "1.55rem" }}
                      onClick={() => {
                        window.open(`${item?.cdn}${item?.info_doc}`, "_blank");
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          </TableRow>
        );
      case "costing-database":
        return (
          <>
            <TableRow
              sx={{
                backgroundColor: "#ccd8db",
                // backgroundColor: "item?.color"
              }}
            >
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                  cursor: "pointer",
                  // item?.material_details?.material_parameter?.length > 0
                  //   ? "pointer"
                  //   : "inherit",
                }}
                onClick={() =>
                  setOpenRow(openRow === item?.key ? null : item?.key)
                }
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* {item?.material_details?.material_parameter?.length > 0 &&
                    ( */}
                  {openRow === item?.key ? (
                    <KeyboardArrowDownIcon sx={{ fontSize: "1.5rem" }} />
                  ) : (
                    <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                  )}
                  {/* // )} */}
                  {item?.paramter_name}{" "}
                  {item?.required && (
                    <sup style={{ color: "red", marginLeft: "0.25rem" }}>*</sup>
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.exceptions?.length > 0 && (
                  <CheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getBOMParametersData={getBOMParametersData}
                    BOMValidationStatus={BOMValidationStatus}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                <Box
                  sx={{
                    padding: "0.5rem",
                    backgroundColor: "rgba(255,255,255,0.3)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item?.material_details?.material_code ? (
                    <Typography
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "0.85rem",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        bomdbModal.open(item);
                        // console.log(item, "parameter");
                      }}
                    >
                      {item?.material_details?.material_code &&
                        `${item?.material_details?.material_code} (${item?.material_details?.category})`}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "0.85rem",
                      }}
                      onClick={() => {
                        bomdbModal.open(item);
                        // console.log(item, "parameter");
                      }}
                    >
                      Fetch material from Database
                    </Typography>
                  )}
                  {item?.material_details?.material_code && (
                    <>
                      {loadingMaterial == item?.key ? (
                        <CircularProgress size={15} />
                      ) : (
                        <span
                          onClick={() => {
                            setLoadingMaterial(item?.key);
                            handleRemoveDBMaterial(item);
                          }}
                        >
                          <CloseIcon
                            sx={{ color: "red", cursor: "pointer" }}
                            fontSize="small"
                          />
                        </span>
                      )}
                    </>
                  )}
                </Box>

                {/* {
                <TextInputBox
                  item={item}
                  details={details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              } */}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.6rem 0.5rem",
                  borderBottomColor: "primary.light",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ cursor: "pointer", fontSize: "1.1rem" }}>
                  {item?.unit ? item?.unit : "-"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                <Collapse
                  in={openRow === item?.key}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    sx={{
                      margin: "0.5rem 0",
                      marginBottom:"0.2rem",
                      display: "flex",
                      flexWrap: "wrap",
                      // gap: "1%",
                    }}
                  >
                    {materialFields?.map((field: any) => {
                      // Check if the key exists in item?.material_details
                      const hasKey =
                        field?.key &&
                        field?.key in (item?.material_details || {});

                      if (!hasKey) return null; // Skip rendering if key is not present

                      return (
                        <Box sx={{ display: "flex", width: "50%" }}>
                          <Box
                            sx={{
                              padding: "0.4rem",
                              // display: "grid",
                              // placeItems: "center",
                              width: "60%",
                              textAlign: "left",
                              border: "0.1px solid #edf2ed",
                              fontSize: "0.85rem",
                              background: "#edf2ed",
                              marginTop: "0.1rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={field?.text} // Shows full text on hover
                          >
                            {field?.text}
                          </Box>
                          <Box
                            sx={{
                              padding: "0.4rem",
                              // display: "grid",
                              // placeItems: "center",
                              width: "40%",
                              border: "0.1px solid #edf2ed",
                              fontSize: "0.85rem",
                              marginTop: "0.1rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={field?.text} // Shows full text on hover
                          >
                            {item?.material_details?.[field?.key] ? (
                              <span>
                                {typeof item?.material_details?.[field?.key] ==
                                "number"
                                  ? item?.material_details?.[field?.key] % 1 !==
                                    0
                                    ? item?.material_details?.[
                                        field?.key
                                      ]?.toFixed(2)
                                    : item?.material_details?.[field?.key]
                                  : item?.material_details?.[field?.key]}
                                &nbsp;
                                {item?.material_details?.[field?.key] &&
                                  field?.key == "density" &&
                                  "kg/m3"}
                              </span>
                            ) : (
                              "-"
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      // margin: "0.5rem 0",
                      display: "flex",
                      // padding: "0.2rem",
                      flexWrap: "wrap",
                      // gap: "1%",
                     
                    }}
                  >
                    {item?.material_details?.material_parameter?.map(
                      (itemm: any) => {
                        return (
                          <Box sx={{ display: "flex", width: "50%" }}>
                            <Box
                              sx={{
                                padding: "0.4rem",
                                // display: "grid",
                                // placeItems: "center",
                                width: "60%",
                                textAlign: "left",
                                border: "0.1px solid #edf2ed",
                                fontSize: "0.85rem",
                                background: "#edf2ed",
                                marginTop: "0.1rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={itemm?.name} // Shows full text on hover
                            >
                              {itemm?.name}
                            </Box>
                            <Box
                              sx={{
                                padding: "0.4rem",
                                // display: "grid",
                                // placeItems: "center",
                                width: "40%",
                                border: "0.1px solid #edf2ed",
                                fontSize: "0.85rem",
                                marginTop: "0.1rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={itemm?.value}
                            >
                              {typeof itemm?.value == "number"
                                ? itemm?.value % 1 !== 0
                                  ? itemm?.value.toFixed(2)
                                  : itemm?.value
                                : itemm?.value}{" "}
                              &nbsp;{itemm?.unit}
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );
    }
  };


  const bomStatus = "Not Started";

  const ValidateStausConfirmModelHandler = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Select to proceed",
        text: "If BOP Costing has to be done for this part, please select the option to proceed",
        customClass: {
          container: "swal2Container",
        },
        html: `
          <div style="display: flex; align-items: center; flex-direction: column;">
            <p>If BOP Costing has to be done for this part, please select the option to proceed.</p>
            <div style="display: flex; align-items: center;margin-top:1rem;">
             <div style="display: flex; align-items: center;">
              <input type="radio" id="dc" name="radioOption" value="dc" style="height:1.5rem;width:1.5rem; color:#007fff;">
              <label for="dc" style="margin-right: 10px;margin-top: 2px;">DC</label>
              </div>
               <div style="display: flex; align-items: center;">
              <input type="radio" id="bop" name="radioOption" value="bop" style="height:1.5rem;width:1.5rem; color:#007fff;">
              <label for="bop" style="margin-right: 10px;margin-top: 2px;">BOP</label>
              </div>
            </div>
          </div>
        `,
        confirmButtonText: "OK",
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton) {
            confirmButton.disabled = true;

            document
              .querySelectorAll('input[name="radioOption"]')
              .forEach((radio) => {
                radio.addEventListener("change", () => {
                  confirmButton.disabled = false;
                });
              });
          }
        },
        preConfirm: () => {
          const selectedOption = document.querySelector(
            'input[name="radioOption"]:checked'
          ) as HTMLInputElement;
          if (!selectedOption) {
            Swal.showValidationMessage("Please select an option");
            return false;
          }
          return selectedOption.value;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(result.value); // Resolve the selected value here
        } else {
          resolve(null); // Resolve as null if the dialog was canceled
          setLoadingstatus(false);
        }
      });
    });
  };

  const handleChangeBOMStatus = async (inputvalue: any) => {
    const value = inputvalue;
    //@ts-ignore
    if (value == 3) {
      validationViewModal.open();
    } else {
      setLoadingstatus(true);

      try {
        const res = await API.get(
          `cost/get_all_validated_configuration_cat/`,
          {
            check_category: true,
            category_id: category,
            vault_id: vault,
          },
          0
        );

        if (res.data?.status === true) {
          await API.put(
            `/xcpep/vault_info/${vault}/`,
            {
              validated: true,
              costing_category: null,
            },
            0
          );
          setSyncColor("red");
          setBOMStatus(+value);
          setLoadingstatus(false);
          Swal.fire({
            icon: "success",
            html: `<div><br /><p style="color:green">Status Updated Successfully</p></div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        } else if (res.data?.status === false) {
          const selectedValue = await ValidateStausConfirmModelHandler();

          if (selectedValue !== null) {
            await API.put(
              `/xcpep/vault_info/${vault}/`,
              {
                validated: true,
                costing_category: selectedValue === "dc" ? false : true,
              },
              0
            );
            setSyncColor("red");
            setBOMStatus(+value);
            setLoadingstatus(false);
            Swal.fire({
              icon: "success",
              html: `<div><br /><p style="color:red">Status Updated Successfully</p></div>`,
              customClass: {
                container: "swal2Container",
              },
            });
          }
        }
      } catch (err: any) {
        setLoadingstatus(false);
        if (err.response) {
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            html: `<div><br /><p style="color:red">${data[0]}</p></div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        }
      }
    }
  };
  const handleChangeBOMStatusValidate = async (value: any) => {
    setLoadingstatus(true);

    try {
      const res = await API.get(
        `cost/get_all_validated_configuration_cat/`,
        {
          check_category: true,
          category_id: category,
          vault_id: vault,
        },
        0
      );

      if (res.data?.status === true) {
        await API.put(
          `/xcpep/vault_info/${vault}/`,
          {
            validated: true,
            costing_category: null,
          },
          0
        );
        setSyncColor("red");
        setBOMStatus(+value);
        validationViewModal.close();
        setLoadingstatus(false);
        Swal.fire({
          icon: "success",
          html: `<div><br /><p style="color:green">Status Updated Successfully</p></div>`,
        });
      } else if (res.data?.status === false) {
        const selectedValue = await ValidateStausConfirmModelHandler();

        if (selectedValue !== null) {
          await API.put(
            `/xcpep/vault_info/${vault}/`,
            {
              validated: true,
              costing_category: selectedValue === "dc" ? false : true,
            },
            0
          );
          setSyncColor("red");
          setBOMStatus(+value);
          validationViewModal.close();
          setLoadingstatus(false);
          Swal.fire({
            icon: "success",
            html: `<div><br /><p style="color:red">Status Updated Successfully</p></div>`,
          });
        }
      }
    } catch (err: any) {
      setLoadingstatus(false);
      if (err.response) {
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          html: `<div><br /><p style="color:red">${data[0]}</p></div>`,
          customClass: {
            container: "swal2Container",
          },
        });
      }
    }
  };

  const handleRemoveDBMaterial = (itm: any) => {
    API.delete(
      `/xcpep/bom_db_parameter/`,
      {
        vault: vault,
        material: itm?.material_details?.id,
        parameter: itm?.key,
      },
      0
    )
      .then((res: any) => {
        BOMValidationStatus();
        getBOMParametersData();
        
      })
      .catch((err: any) => {
            const { data } = err.response;
            Swal.fire({
              title: "Error!",
              text: data[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          })
      .finally(() => {
        setLoadingMaterial(null);
      });
  };

  const handleChangeBOMStatusTopvault = (event: SelectChangeEvent) => {
    // setAge(event.target.value as string);
    const { value } = event.target;
    //@ts-ignore
    if (value == 3) {
      validationViewModal.open();
    } else {
      setLoadingstatus(true);
      API.put(
        `/xcpep/top_vault/${topVault}/`,
        {
          validated: true,
        },
        {},
        0
      )
        .then((res: any) => {
          setSyncColor("red");
          setBOMStatus(+value);
          setLoadingstatus(false);
          Swal.fire({
            icon: "success",
            html: `<div>
             <br />
             <p style="color:"red">Status Updated Successfully</p>   
              </div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        })
        .catch((err: any) => {
          setLoadingstatus(false);
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            html: `<div>
             <br />
             <p style="color:"red">${data[0]}</p>   
              </div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        });
    }
  };

  const handleChangeBOMStatusTopvaultValidate = (value: any) => {
    // setAge(event.target.value as string);
    setLoadingstatus(true);
    API.put(
      `/xcpep/top_vault/${topVault}/`,
      {
        validated: true,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        setBOMStatus(+value);
        validationViewModal.close();
        setLoadingstatus(false);
        Swal.fire({
          icon: "success",
          html: `<div>
             <br />
             <p style="color:"red">Status Updated Successfully</p>   
              </div>`,
        });
      })
      .catch((err: any) => {
        setLoadingstatus(false);
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          html: `<div>
             <br />
             <p style="color:"red">${data[0]}</p>   
              </div>`,
        });
      });
  };

  const DeleteHandler = () => {
    if (BOMDetails?.cost_status >= 1) {
      swal({
        // text: `Are you sure ?`,
        title: `This action will delete the BOM Parameter and link Costing`,
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);

              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
              });
            });
        }
      });
    } else {
      swal({
        // text: `Are you sure ?`,
        title: `This action will  delete ${BOMDetails?.name}`,
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);

              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
              });
            });
        }
      });
    }
  };

  const handleOptionClick = async () => {
    setLoadingOptions(true);
    let body = { ...{ vault_id: vault }, ...{ validate: "bom" } };
    API.put("/cost/superuser_force_validate/", body, {}, 0)
      .then((res: any) => {
        setLoadingOptions(false);
        BOMValidationStatus();
        ADMIN.toast.info(`BOM force validation successfully updated`);
      })
      .catch((err: any) => {
        setLoadingOptions(false);
      });
  };

  const weightLockHandler = (status: any) => {
    if (BOMStatus && BOMStatus === 2) {
      setLoadingWeightLock(true);
      API.put(
        `/xcpep/vault_info/${BOMDetails?.id}/`,
        { weight_lock: !status },
        {},
        0
      )
        .then((res: any) => {
          setLoadingWeightLock(false);
          setWeightLockStatus(!status);
        })
        .catch((err: any) => {
          setLoadingWeightLock(false);
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        html: `<div>
           <br />
           <p style="color:"red">Please change the status to Completed</p>   
            </div>`,
      });
    }
  };
  const ProductweightLockHandler = (status: any) => {
    if ((BOMStatus && BOMStatus === 2) || (BOMStatus && BOMStatus == 1)) {
      setProductloadingWeightLock(true);
      API.put(
        `/xcpep/top_vault/${BOMDetails?.id}/`,
        { weight_lock: !status },
        {},
        0
      )
        .then((res: any) => {
          setProductWeightLock(!status);
          setProductloadingWeightLock(false);
        })
        .catch((err: any) => {
          setProductloadingWeightLock(false);
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        html: `<div>
           <br />
           <p style="color:"red">Please change the status to Completed</p>   
            </div>`,
      });
    }
  };

  const ImageSearchModal = useBaseModal();
  const ImageSearchHandler = () => {
    ImageSearchModal.open();
  };

  // const ValidateStausConfirm = useBaseModal();
  // const ValidateStausConfirmModelHandler = () => {
  //   ValidateStausConfirm.open();
  // }

  const getPartInfo = (newInputValue: any) => {
    API.get(
      `/xcpep/vault/search/`,
      { search: newInputValue, project: projectId },
      0
    )
      .then((res) => {
        setSearchVaultsOptions(res.data);
      })
      .catch((err: AxiosError) => {
        setSearchVaultsOptions([]);
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                  <br />
                  <p style="color:"red">${data[0]}</p>   
                </div>`,
        });
      });
  };

  //   React.useEffect(()=> {
  // if (searchValueOptions?.length > 0) {

  //   setLocatorCount(searchValueOptions?.[0])
  // }
  //   },[searchValueOptions])

  React.useEffect(() => {
    if (PartData) {
      getPartInfo(PartData);
    }

    //
  }, [PartData]);

  return (
    <div>
      {bomdbModal.isOpen && (
        <BOMDatabaseModal
          isOpen={bomdbModal.isOpen}
          onClose={() => bomdbModal.close()}
          parameter={bomdbModal.propsId}
          vault={vault}
          getBOMParametersData={getBOMParametersData}
          updateMaterialDetails={(material: any, paramId: any) =>
            updateMaterialDetails(material, paramId)
          }
          BOMValidationStatus={BOMValidationStatus}
        />
      )}
      {ImageSearchModal.isOpen && (
        <BOMImageBasedSearchModal
          onCloseModal={ImageSearchModal.close}
          isOpen={ImageSearchModal.open}
          projectId={projectId}
          projectName={projectName}
          topVault={topVault}
          vault={vault}
          treeabbr={treeabbr}
          isLeaf={isLeaf}
          copyStatus={copyStatus}
        />
      )}
      {/* {ValidateStausConfirm.isOpen && (
        <ValidateStausConfirmModel
          onCloseModal={ValidateStausConfirm.close}
          isOpen={ValidateStausConfirm.open}
          seletctRadio={seletctRadio}
          setSelectRadio={setSelectRadio}
        />
      )} */}
      <Box className={styles.titleSectionMiddel}>
        {copyStatus == true || copyStatus == "true" ? (
          <Box
            sx={{
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => OriginalPartRounting()}
          >
            Go to Original Part{" "}
            {OriginaLoader ? <CircularProgress size={20} /> : ""}
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}></Box>
        )}
        <Box
          sx={{
            width: "100%",
            textDecoration: "underline",
            cursor: "pointer",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
        >
          {!workstationLoader ? (
            <Box
              onClick={() => {
                if (searchValueOptions?.length > 0) {
                  setLocatorCount(searchValueOptions?.[0]);
                }
              }}
            >
              {PartData}
            </Box>
          ) : (
            <Skeleton width="100%" sx={{ height: "2rem" }} />
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "1rem",
              alignItems: "center",
            }}
          >
            {AUTH.isSuperUser && BOMStatus !== 3 && BOMStatus !== 0 && (
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                sx={{ whiteSpace: "nowrap", padding: "2px 1rem" }}
                onClick={handleOptionClick}
                disabled={loadingOptions}
              >
                {loadingOptions ? "Loading..." : "Force Validate BOM"}
              </LoadingButton>
            )}

            {treeabbr == "product" && (
              <LoadingButton
                sx={{ minWidth: "1rem" }}
                loading={ProductloadingWeightLock}
                size="small"
              >
                {treeabbr !== "none" &&
                  treeabbr &&
                  ProductloadingWeightLock === false &&
                  ProductWeightLock && (
                    <LockIcon
                      sx={{ color: "green", cursor: "pointer" }}
                      onClick={() =>
                        (userProfilePic[0]?.is_superuser ||
                          userProfilePic[0]?.is_project_admin) &&
                        ProductweightLockHandler(true)
                      }
                    />
                  )}
                {treeabbr !== "none" &&
                  treeabbr &&
                  ProductloadingWeightLock === false &&
                  !ProductWeightLock && (
                    <LockOpenIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => ProductweightLockHandler(false)}
                    />
                  )}
              </LoadingButton>
            )}
            {treeabbr != "product" && (
              <LoadingButton
                sx={{ minWidth: "1rem" }}
                loading={loadingWeightLock}
                size="small"
              >
                {treeabbr !== "none" &&
                  treeabbr &&
                  loadingWeightLock === false &&
                  weightLockStatus && (
                    <LockIcon
                      sx={{ color: "green", cursor: "pointer" }}
                      onClick={() =>
                        (userProfilePic[0]?.is_superuser ||
                          userProfilePic[0]?.is_project_admin) &&
                        weightLockHandler(true)
                      }
                    />
                  )}
                {treeabbr !== "none" &&
                  treeabbr &&
                  loadingWeightLock === false &&
                  !weightLockStatus && (
                    <LockOpenIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => weightLockHandler(false)}
                    />
                  )}
              </LoadingButton>
            )}

            {isLeaf === "false" && (
              <ImageSearchIcon
                titleAccess="Image Based Navigation"
                sx={{ cursor: "pointer" }}
                onClick={() => ImageSearchHandler()}
              />
            )}

            <Box
              sx={{
                display: "flex",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >              
              {BOMStatus !== undefined && BOMStatus === 0 ? (
                <Box
                  sx={{
                    // border: "2px solid #d32f2f",
                    color: "#d32f2f",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.15rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Not Started
                </Box>
              ) : BOMStatus && BOMStatus == '1' ? (
                <Box
                  sx={{
                    // border: "2px solid #ffc800",
                    color: "#ffc800",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.1rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    whiteSpace: "nowrap",
                  }}
                >
                  In Progress
                </Box>
              ) : BOMStatus && BOMStatus == '2' ? (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Typography> Completed</Typography>
                  <LoadingButton
                    loading={loadingStatus}
                    title="Validate"
                    size="small"
                    variant="contained"
                    sx={{ padding: "2px 1rem" }}
                    endIcon={<KeyboardArrowRightIcon />}
                    disabled={
                      (copyStatus == false || copyStatus == "false") &&
                      (UserAction?.includes("C") || UserAction?.includes("U"))
                        ? false
                        : true
                    }
                    onClick={(e: any) =>
                      treeabbr == "product"
                        ? handleChangeBOMStatusTopvault(e)
                        : handleChangeBOMStatus("3")
                    }
                  >
                    Validate                    
                  </LoadingButton>
                </Box>
              ) : BOMStatus && BOMStatus == '3' ? (
                <Box
                  sx={{
                    // border: "2px solid #008000",
                    color: "#008000",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.15rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                  }}
                >
                  Validated
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
          {UserAction?.includes("D") && (
            <DeleteIcon
              titleAccess="Delete Part"
              sx={{ cursor: "pointer" }}
              onClick={() => DeleteHandler()}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: "49rem",
            sm: "49vw",
            md: "49vw",
            lg: "49vw",
            xl: "49vw",
          },
          paddingBottom: "0.2rem",
        }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        {!workstationLoader ? (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Chip className={styles.workstation} variant="outlined" label="CS" /> */}
              {workStationList &&
                workStationList?.map((item: any) => {
                  return (
                    <Chip
                      className={
                        item?.id == workstation
                          ? styles.workstation_active
                          : styles.workstation
                      }
                      variant="outlined"
                      sx={{
                        color:
                          item?.id == workstation
                            ? ""
                            : `${item?.status} !important`,
                        backgroundColor:
                          item?.id == workstation
                            ? `${item?.status} !important`
                            : "",
                        borderColor:
                          item?.id == workstation
                            ? `${item?.status} !important`
                            : "",
                      }}
                      label={item?.name}
                      onClick={() => {
                        history.push(
                          `/bomentry/${projectId}/${projectName}/${topVault}/${vault}/${treeabbr}/${item?.id}/${isLeaf}/${copyStatus}`
                        );
                      }}
                    />
                  );
                })}
              {/* <Chip
            className={styles.workstation}
            variant="outlined"
            label="WS-1"
          /> */}
            </Stack>
          </>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={25} />
        )}
      </Box>
      <Box sx={{ marginTop: { lg: "0.5rem", xl: "0" } }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  width: { xs: "5rem", sm: "6vw" },
                  fontSize: "12px",
                }}
              >
                Part Name
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  backgroundColor: "primary.light",
                }}
              >
                {treeabbr === "none" ? (
                  <TextField
                    type="text"
                    variant="standard"
                    name="name"
                    placeholder="Part Name *"
                    value={partName}
                    disabled={
                      (copyStatus == false || copyStatus == "false") &&
                      (UserAction?.includes("C") || UserAction?.includes("U"))
                        ? false
                        : true
                    }
                    onChange={handleChangeInput}
                    inputProps={{
                      step: "any",
                      min: 0,
                      style: { fontSize: "12px", paddingBottom: "0.1rem" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      color: "primary",
                    }}
                    sx={{
                      width: "100%",
                      borderBottomColor: "primary.light",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    onBlur={(event: any) => {
                      if (partName !== oldPartName) {
                        updateDataVault(
                          "name",
                          event.target.value,
                          vault,
                          topVault,
                          "wo"
                        );
                      }
                    }}
                  />
                ) : (
                  <Box>{partName}</Box>
                )}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  width: { xs: "5rem", sm: "3vw" },
                  fontSize: "12px",
                }}
              >
                {vault == 0 ? "MFG Year" : "Qty"}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  backgroundColor: "primary.light",
                  width: { xs: "6rem", sm: "4vw" },
                }}
              >
                {vault != 0 ? (
                  <TextField
                    type="number"
                    variant="standard"
                    name="quantity"
                    // label="Life in Yrs *"
                    placeholder="Quantity *"
                    disabled={
                      UserAction?.includes("C") || UserAction?.includes("U")
                        ? false
                        : true
                    }
                    value={partQty}
                    onChange={handleChangeInput}
                    inputProps={{
                      step: "any",
                      min: 0,
                      style: { fontSize: "12px" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      color: "primary",
                    }}
                    onBlur={(event: any) => {
                        updateDataVault(
                          "quantity",
                          event.target.value,
                          vault,
                          topVault,
                          "wo"
                        );
                      
                    }}
                    sx={{
                      width: "100%",
                      borderBottomColor: "primary.light",
                      ".MuiInputBase-root.MuiInput-root": {
                        paddingBottom: "0.1rem",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                ) : (
                  <Box>{BOMDetails?.manufacturing_year}</Box>
                )}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  width: { xs: "8rem", sm: "6vw" },
                  fontSize: "12px",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  backgroundColor: "primary.light",
                  width: { xs: "12rem", sm: "12vw" },
                }}
              >
                <FormControl fullWidth variant="standard">
                  <Select
                    size="small"
                    name="category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    disabled={
                      (copyStatus == false || copyStatus == "false") &&
                      (UserAction?.includes("C") || UserAction?.includes("U"))
                        ? false
                        : true
                    }
                    onChange={handleChangeInput}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "12px",
                          paddingBottom: "0",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {categoryList &&
                      categoryList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "12px" }}
                            value={item?.category}
                          >
                            {item?.category_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      <Box
        sx={{
          height: {
            xs: "76vh",
            sm: "76vh",
            md: "76vh",
            lg: "76vh",
            xl: "83vh",
          },
          width: "100%",
        }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "primary.main" }}>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {
                    xs: "12rem",
                    sm: "12vw",
                    md: "12vw",
                    lg: "12vw",
                    xl: "17vw",
                  },
                }}
              >
                Parameters
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {
                    xs: "3rem",
                    sm: "3rem",
                    md: "3rem",
                    lg: "2rem",
                    xl: "3rem",
                  },
                }}
              ></TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {
                    xs: "25rem",
                    sm: "25vw",
                    md: "25vw",
                    lg: "25vw",
                    xl: "25vw",
                  },
                }}
              >
                Value
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {
                    xs: "7rem",
                    sm: "7rem",
                    md: "7rem",
                    lg: "7rem",
                    xl: "7rem",
                  },
                }}
              >
                Unit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isBOMparametersLoading && BOMparameters ? (
              BOMparameters?.map((item: any, index: any) => {
                return renderInputs(item, index);
              })
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{ padding: "1rem 0.5rem", borderBottom: "none" }}
                    colSpan={4}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        {/* <Box sx={{ margin: "0rem 0rem" }}>
          <BOMDataBaseSection vault={vault} />
          </Box> */}

        <Box sx={{ margin: "1rem 0rem" }}>
          <BomEntryTableComponent
            tableDataGetCount={tableDataGetCount}
            BOMValidationStatus={BOMValidationStatus}
            counter={counter}
          />
        </Box>
      </Box>
      {validationViewModal.isOpen && (
        <BOMValidationView
          onCloseModal={validationViewModal.close}
          isOpen={validationViewModal.isOpen}
          BOMparameters={BOMparameters && BOMparameters}
          handleChangeBOMStatusTopvaultValidate={
            handleChangeBOMStatusTopvaultValidate
          }
          handleChangeBOMStatusValidate={handleChangeBOMStatusValidate}
          loadingStatus={loadingStatus}
        />
      )}
    </div>
  );
}
