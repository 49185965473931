import { Box, Button, Dialog, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import CostingCreateLPPTableRow from './CostingCreateLPPTableRow';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Bar, BarChart, CartesianGrid, Cell, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Swal from 'sweetalert2';
import swal from "sweetalert";
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from '../CostingCreateSystemTable.module.scss';
import { LoadingButton } from '@mui/lab';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CurrencyChangeDynamic } from '../../../ViewModule/CostingView/CurrecyChangeDynamic';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];
interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  abbr: any;
  partId: any;
  depth: any;
  vault_id: any;
}

interface ICostingCreateLPPTableProps {
  openFilter: any;
  setOpenFilter: any;
  assemblyPart: any;
  setAssemblyPart: any;
  category: any;
  setCategory: any;
  status: any;
  setStatus: any;
  calculatorType: any;
  setCalculatorType: any;
  setValue: any;
  setSearchValue: any;
  apiCallMain: any;
  setApiCallMain: any;
  getSearchResult: any;
  setGetSearchResult: any;
}

const CostingCreateLPPTable: React.FC<ICostingCreateLPPTableProps> = (props) => {
  const { openFilter, setOpenFilter, assemblyPart, setAssemblyPart, category, setCategory, status, setStatus, calculatorType, setCalculatorType, setValue, setSearchValue, apiCallMain, setApiCallMain, getSearchResult, setGetSearchResult } = props;
  const { projectId, projectName, topVaultIds, expandTable, initialTopVault, abbr, partId, depth, vault_id } = useRouteParams<Params>();
  const [costKgData, setCostKgData] = useState<any>([]);
  const [sessionGetInto, setSessionGetInt] = useState<any>([]);
  const [openDelta, setOpenDelta] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openGraph, setOpenGraph] = React.useState(false);
  const [openInference, setOpenInference] = React.useState(false);
  const [deltaData, setDeltaData] = React.useState<any>([]);
  const [graphData, setGraphData] = React.useState<any>([]);
  const [infoData, setInfoData] = React.useState<any>([]);
  const [inferenceData, setInferenceData] = React.useState<any>([]);
  const [inferenceDataButtonToggle, setInferenceDataButtonToggle] = React.useState<any>([]);
  const [inferenceDepth, setInferenceDepth] = React.useState<any>();
  const [inferenceName, setInferenceName] = React.useState<any>();
  const [costKgDataApiCall, setCostKgDataApiCall] = React.useState<boolean>(false);
  const [costKgDataLoader, setCostKgDataLoader] = React.useState<boolean>(false);
  const [paginationProduct, setPaginationProduct] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [infoAboutCostingSubsystem, setInfoAboutCostingSubsystem] = useState<any>({});
  const [firstTimeAPIRestrict, setFirstTimeAPIRestrict] = useState<boolean>(false);
  const [filterIsWorking, setFilterIsWorking] = useState<boolean>(false);
  const [permissionData, setPermissionData] = useState<any>();
  const [loaderRecursiveId, setLoaderRecursivId] = React.useState<any>();
  const [count, setCount] = React.useState<any>(0);
  const [defaultCurrency, setDefaultCurrency] = useState<any>("INR");
const { url } = useRouteMatch();

  const currencyModal = useBaseModal();
  const history = useHistory();

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "lpp",top_vault:initialTopVault },
      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingLpp",
        res.data[0]?.current_currency
      );
      sessionStorage.setItem(
        "actionkey_costingLpp",
        res.data[0]?.is_manual ? "mannual" : "auto"
      )
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingLpp",
          res.data[0]?.conversion_factor
        )
      } else {
        sessionStorage.setItem(
          "currencyrate_costingLpp",
          (1 / res.data[0]?.conversion_factor).toString()
        )
      }
    });
  }, [count]);

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { top_vault: initialTopVault, module: "lpp", get_default_currency: true },
      0
    )
      .then((res: any) => {
        url?.includes('analysis') ? sessionStorage.setItem("currency_analysis",res.data[0]?.current_currency) : url.includes("costingsystemlanding") || url.includes("costingscenarioabbrlanding") || url.includes("costingscenario") ? sessionStorage.setItem("costingtable",res.data[0]?.current_currency) : url.includes("costingqualitycheck") ? sessionStorage.setItem("costingLpp",res.data[0]?.current_currency) : sessionStorage.setItem("currency",res.data[0]?.current_currency)
        setDefaultCurrency(res.data[0]?.current_currency);
      })
      .catch((err: any) => {});
  }, [initialTopVault]);



  useEffect(() => {
    setCostKgDataLoader(true);

    API.get('cost/costing_analysis/', { master_hierarchy: projectId, level: abbr, top_vaults: topVaultIds, part_id: partId, depth: depth,single_level:true }, 0).then((res: AxiosResponse) => {
      setCostKgData(res?.data);
      sessionStorage.setItem("hierarchyCostingLPPSelection", JSON.stringify([res.data?.data?.[0]?.id]));
      setSessionGetInt([res.data?.data?.[0]?.id]);
      setFirstTimeAPIRestrict(true);
      setCostKgDataLoader(false);
    }).catch((err: AxiosError) => {
      console.log(err, "error");
    })
  }, []);

  useEffect(() => {
    if (firstTimeAPIRestrict) {
      setCostKgDataLoader(true);
      API.get('cost/costing_analysis/', { master_hierarchy: projectId, level: abbr=="none"?"Assembly":abbr, top_vaults: topVaultIds, part_id: partId, depth: depth ,single_level:true}, 0).then((res: AxiosResponse) => {
        setCostKgData(res.data);
        setCostKgDataLoader(false);
      }).catch((err: AxiosError) => {
        console.log(err, "error");
      })
    }

    API.get(`cost/costing_create_ui/`, { get_costing_vehicle: projectId, start_top_vault: initialTopVault, series: topVaultIds }, 0)
      .then((res: AxiosResponse) => {
        setPaginationProduct(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [costKgDataApiCall, count, topVaultIds]);

  useEffect(() => {
    if (firstTimeAPIRestrict) {
      setCostKgDataLoader(true);
      API.get('cost/costing_analysis/', { master_hierarchy: projectId, level: abbr=="none"?"Assembly":abbr, top_vaults: topVaultIds, part_id: partId, depth: depth, status_filter: status, category_filter: category, general_filter: assemblyPart, calculator_type: calculatorType, get_search_result: getSearchResult}, 0).then((res: AxiosResponse) => {
        setCostKgData(res?.data);
        setCostKgDataLoader(false);
      }).catch((err: AxiosError) => {
        console.log(err, "error");
      })
    }
  }, [apiCallMain])

  useEffect(() => {
    setSessionGetInt(sessionStorage.getItem("hierarchyCostingLPPSelection"));
  }, []);

  useEffect(() => {
    API.get("cost/cost_info_commodity_desc/", { parent: vault_id, module: 16 }, 0).then((res: any) => {
      setInfoAboutCostingSubsystem(res?.data);
    })
  }, [])


  function replaceDataWithSameId(obj:any, targetId:any, newData:any,setLoaderRecursivId:any) {
    if (obj.id === targetId) {
      // Replace data in the current object with the matching ID
      obj.data = newData.data;
      setLoaderRecursivId(-1);
      return;
      // console.log(obj.id,targetId,"targetId",obj.data,newData.data)
    }
  
    if (obj.data && obj.data.length > 0) {
      // Recursively traverse the children
      for (let i = 0; i < obj.data.length; i++) {
        replaceDataWithSameId(obj.data[i], targetId, newData,setLoaderRecursivId);
      }
    }
  }

  const handleOpenHierarchy = (idPush: any) => {
    let a: any = sessionStorage.getItem("hierarchyCostingLPPSelection");

    let val: any = JSON.parse(a);
    if (val?.includes(idPush?.id)) {
      sessionStorage.setItem("hierarchyCostingLPPSelection", JSON.stringify(val?.filter((item: any) => item != idPush?.id)));
      setSessionGetInt(val?.filter((item: any) => item != idPush?.id));
    }
    else {
      sessionStorage.setItem("hierarchyCostingLPPSelection", JSON.stringify([...val, idPush?.id]));
      setSessionGetInt([...val, idPush?.id]);
      setLoaderRecursivId(idPush?.id);
     
      // API.get('cost/costing_analysis/', { master_hierarchy: projectId, level: abbr=="none"?"Assembly":abbr, top_vaults: topVaultIds, part_id: partId, depth: depth, status_filter: status, category_filter: category, general_filter: assemblyPart, calculator_type: calculatorType, get_search_result: getSearchResult ,single_level:true}, 0).then((res: AxiosResponse) => {
      //   replaceDataWithSameId(costKgData, idPush, res?.data?.[0]?.data?.[0],setLoaderRecursivId);
       
      // }).catch((err: AxiosError) => {
      //   console.log(err, "error");
      // })

      API.get('cost/costing_analysis/', { master_hierarchy: projectId, level: idPush?.abbrivation==null?"Assembly":idPush?.abbreviation, top_vaults: topVaultIds, part_id: idPush?.id, depth: idPush?.depth ,single_level:true}, 0).then((res: AxiosResponse) => {
        replaceDataWithSameId(costKgData, idPush?.id, res?.data?.data?.[0],setLoaderRecursivId);
      }).catch((err: AxiosError) => {
        console.log(err, "error");
      })
      
    }
  }

  const handleCloseDelta = () => {
    setDeltaData(undefined);
    setOpenDelta(false);
  };
  const handleCloseGraph = () => {
    setOpenGraph(false);
  };

  const handleCloseInfo = () => {
    setInfoData(undefined);
    setOpenInfo(false);
  };

  const handleCloseInference = () => {
    setInferenceData(undefined);
    setInferenceDataButtonToggle(undefined);
    setOpenInference(false);
    setInferenceDepth(undefined);
    setInferenceName(undefined);
  };

  const handleOpenInference = (name: any, depth: any) => {
    API.get("/auth/calibration_user_permission/", { action: true, costing_create: true, project: projectId, top_vaults: topVaultIds },0).then((res: any) => {
      setPermissionData(res?.data);
      API.get('cost/costing_analysis/', { get_inference: name, depth: depth, project: projectId }, 0).then((res: AxiosResponse) => {
        setInferenceDepth(depth);
        setInferenceName(name);
        setInferenceData(res?.data?.[0]?.inference);
        setInferenceDataButtonToggle(res?.data?.[0]?.inference);
        setOpenInference(true);
      }).catch((err: AxiosError) => {
        console.log('error', err)
      });
    }).catch((err: AxiosError) => {
      console.log(err, "error");
    });
  }

  const handleOpenDelta = (name: any, depth: any, topVaultId: any) => {
    API.get('cost/costing_analysis/', { get_delta_info: name, depth: depth, top_vault: topVaultId }, 0).then((res: AxiosResponse) => {
      setDeltaData(res?.data?.[0]);
      setOpenDelta(true);
    }).catch((err: AxiosError) => {
      console.log('error', err)
    });
  }

  const handleOpenGraph = (name: any, depth: any) => {
    API.get('cost/costing_analysis/', { graph_data: name, depth: depth, project: projectId, start_top_vault: initialTopVault }, 0).then((res: AxiosResponse) => {
      setGraphData(res?.data);
      setOpenGraph(true);
    }).catch((err: AxiosError) => {
      console.log('error', err)
    });
  }

  const handleOpenInfo = (name: any, depth: any, topVaultId: any) => {
    API.get('cost/costing_analysis/', { get_costing_info: name, depth: depth, top_vault: topVaultId }, 0).then((res: AxiosResponse) => {
      setInfoData(res?.data);
      setOpenInfo(true);
    }).catch((err: AxiosError) => {
      console.log('error', err)
    });
  }

  const handleSaveInference = () => {
    API.post('cost/costing_analysis/', { part_name: inferenceName, depth: inferenceDepth, project: projectId, inference: inferenceData }).then((res: any) => {
      setCostKgDataApiCall(!costKgDataApiCall)
      handleCloseInference();
    }).catch((err: any) => {
      handleCloseInference();
      const { data } = err?.response;
      Swal.fire({
        icon: "error",
        html: `<div>
      <br />
      <p style="color:"red">${data[0]}</p>   
       </div>`,
      });
    })
  }

  const handleClearInference = () => {
    swal({
      title: `Clear Inference`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Clear`,
          value: `clearInference`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "clearInference":
          API.delete("/cost/costing_analysis/", { part_name: inferenceName, depth: inferenceDepth, project: projectId }, 0)
            .then((res: any) => {
              handleCloseInference();
              setCostKgDataApiCall(!costKgDataApiCall);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Inference saved from clearing`, { icon: "error" });
          handleCloseInference();
      }
    });
  }

  const handleChangeInference = (value: any) => {
    setInferenceData(value)
  }

  const redirectPaginationPush = (type: any) => {
    if (type == 'prev') {
      history.push(`/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${paginationProduct && paginationProduct?.[0]?.previous_index?.toString()}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}`)
    }
    else {
      history.push(`/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${paginationProduct && paginationProduct?.[0]?.next_index?.toString()}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}`)
    }
  }

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleChangeAssemblyPart = (event: any) => {
    setAssemblyPart(event.target.value);
  };

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
  };

  const handleChangeCategory = (event: any) => {
    setCategory(event.target.value);
  };

  const handleChangeCalculatorType = (event: any) => {
    setCalculatorType(event.target.value);
  };

  const handleClearFilter = () => {
    setAssemblyPart(undefined);
    setStatus(undefined);
    setCategory(undefined);
    setCalculatorType(undefined);
    setGetSearchResult(undefined);
    setValue([]);
    setSearchValue([]);
    setApiCallMain(!apiCallMain);
    setFilterIsWorking(false);
    handleCloseFilter();
  }

  const handleFilter = () => {
    setApiCallMain(!apiCallMain);
    setValue([]);
    setSearchValue([]);
    setGetSearchResult(undefined);
    setFilterIsWorking(true);
    handleCloseFilter();
  }

  const handleLoader = () => {
    return (<span>
      <Skeleton animation='wave' variant="text" height={100} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
      <Skeleton animation='wave' variant="text" height={40} />
    </span>)
  }


  const loaderLoadingComponent = () => {
    let arrayLoopComponent = [];
    for (let i = 0; i < 20; i++) {
      arrayLoopComponent.push(
        <TableRow>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      );
    }
    return arrayLoopComponent;
  };

  return (
    <>
      <Dialog
        open={openFilter}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFilter}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            // minWidth: '40vw',
            borderRadius: '10px',
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Filter
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseFilter} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Assembly/Part</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={assemblyPart || ''}
              onChange={handleChangeAssemblyPart}
              label="Assembly/Part"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={'Assembly'}>Assembly</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={'Part'}>Part</MenuItem>
            </Select>
          </FormControl>
          <h3 style={{ textAlign: 'center' }}>AND</h3>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Select Category</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={category || ''}
              onChange={handleChangeCategory}
              label="Select Category"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              {infoAboutCostingSubsystem && infoAboutCostingSubsystem?.data?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: '1rem' }} value={`${item?.category_name}`}>{item?.category_name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <h3 style={{ textAlign: 'center' }}>AND</h3>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={status == 0 ? status : status || ''}
              onChange={handleChangeStatus}
              label="Status"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={0}>Not Started</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={1}>In-Progress</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={2}>Completed</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={3}>Validated</MenuItem>
            </Select>
          </FormControl>
          <h3 style={{ textAlign: 'center' }}>AND</h3>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Costing Type</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={calculatorType || ''}
              onChange={handleChangeCalculatorType}
              label="Costing Type"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={'bop'}>Bought-out part costing</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={'dc'}>Detailed costing</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
            <FilterAltOffIcon sx={{ mr: 1, cursor: 'pointer' }} titleAccess='Clear Filter' onClick={handleClearFilter} />
            <LoadingButton variant='contained' sx={{ p: 0 }} onClick={handleFilter}>Filter</LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      {currencyModal.isOpen &&
        <CurrencyChangeDynamic
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
          currentCurrency={defaultCurrency}
        />}
      <Dialog
        open={openInference}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseInference}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            maxWidth: '50vw'
          }
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: '48vw' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Costing Inference
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseInference} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />
          <Box sx={{ mt: 3 }}>
            <textarea
              style={{ width: '100%', height: '8rem', color: "#007fff", padding: '0.5rem', border: '1px solid #ddefff', borderRadius: '10px', maxWidth: '45vw', maxHeight: '70vh', marginLeft: '2rem' }}
              placeholder="Write inference here....."
              value={inferenceData || ''}
              onChange={(e) => handleChangeInference(e.target.value)} />
            <span style={{ float: 'right' }}>
              {inferenceDataButtonToggle != undefined && permissionData?.[0]?.action?.includes("D") && <Button sx={{ p: '0 0.5rem', m: '1rem 1rem' }} variant='contained' onClick={handleClearInference}>Clear</Button>}
              {inferenceDataButtonToggle != undefined && permissionData && permissionData?.[0]?.action?.includes("U") && <Button sx={{ p: '0 0.5rem', m: '1rem 1rem' }} variant='contained' onClick={handleSaveInference}>Update</Button>}
              {inferenceDataButtonToggle == undefined && permissionData && permissionData?.[0]?.action?.includes("C") && <Button sx={{ p: '0 0.5rem', m: '1rem 1rem' }} variant='contained' onClick={handleSaveInference}>Save</Button>}
            </span>
          </Box>
        </DialogContent>
      </Dialog>

      {openInfo && <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelta}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            maxWidth: '80vw'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Costing Info
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseInfo} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent>
          <Typography>Part : {infoData?.[0]?.part_no}</Typography>
          {infoData?.[0]?.costing_type != '' && <Typography>Costing Type : {infoData?.[0]?.costing_type}</Typography>}
          <Typography>Cost : {sessionStorage.getItem("costingLpp")} {infoData?.[0]?.net_total_cost}</Typography>
          <Typography>Category : {infoData?.[0]?.bom_category}</Typography>
          <Box style={{ height: '15rem', width: '30rem' }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={infoData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis name='Cost' dataKey="part_no" />
                <YAxis>
                  <Label
                    style={{
                      textAnchor: "middle",
                      fontSize: "1rem",
                      fill: '#000000'
                    }}
                    angle={270}
                    dx={-17}
                    value={`Cost (${sessionStorage.getItem("costingLpp")})`}
                  />
                </YAxis>
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  margin={{ bottom: 10 }}
                  wrapperStyle={{ right: "10px", fontSize: '0.75rem' }}
                />
                <Bar name='RM Cost' dataKey="raw_material_cost" stackId="a" fill="#dd6b4d" />
                <Bar name='Process Cost' dataKey="total_process_cost" stackId="a" fill="#9b5a64" />
                <Bar name='Overhead Cost' dataKey="overhead_cost" stackId="a" fill="#8945aa" />
                {infoData && !infoData[0]?.is_child && <Bar name='Child Cost' dataKey="child_cost" stackId="a" fill="#90EE90" />}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </DialogContent>
      </Dialog>}

      <Dialog
        open={openGraph}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseGraph}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            maxWidth: '100vw'
          }
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: '95vw' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Costing Graph
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseGraph} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />
          <Box
            sx={{
              padding: "1rem",
              height: { lg: '85vh', xl: '88vh' },
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr'
            }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Cost/Kg</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={`Cost (${sessionStorage.getItem("costingLpp")}) / Kg`}
                        />
                      </YAxis>
                      <Bar dataKey="cost_per_kg" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Cost</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={`Cost ( ${sessionStorage.getItem("costingLpp")} )`}
                        />
                      </YAxis>
                      <Bar dataKey="cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Weight</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={"Weight ( Kg )"}
                        />
                      </YAxis>
                      <Bar dataKey="weight" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Raw material Cost</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={`Cost ( ${sessionStorage.getItem("costingLpp")} )`}
                        />
                      </YAxis>
                      <Bar dataKey="rm_cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Process Cost</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={`Cost ( ${sessionStorage.getItem("costingLpp")} )`}
                        />
                      </YAxis>
                      <Bar dataKey="process_cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Overhead Cost</Typography>
                <Box sx={{ margin: '0rem', width: '30vw', height: '45vh', padding: '1rem 1rem' }}>
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={graphData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="top_vault_name" interval={0} angle={graphData.length > 3 ? -25 : 0} textAnchor={graphData.length > 3 ? "end" : "middle"} />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-28}
                          value={`Cost ( ${sessionStorage.getItem("costingLpp")} )`}
                        />
                      </YAxis>
                      <Bar dataKey="overhead_cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {graphData?.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
            </Box>
            {graphData && !graphData[0]?.is_leaf && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Child Cost</Typography>
              <Box sx={{ margin: '0rem', width: '85vw', height: '45vh', padding: '1rem 1rem' }}>
                <ResponsiveContainer>
                  <BarChart
                    width={850}
                    height={300}
                    data={graphData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 25,
                      bottom: 55,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="top_vault_name" interval={0} angle={0} textAnchor="middle" />
                    <YAxis>
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "100%",
                          fontWeight: "600",
                        }}
                        angle={270}
                        dx={-28}
                        value={`Cost (${sessionStorage.getItem("costingLpp")})`}
                      />
                    </YAxis>
                    <Bar dataKey="child_cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                      {graphData?.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </div>}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelta}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelta}
        aria-describedby="alert-dialog-slide-description"
        // sx={{
        //   '.MuiPaper-root.MuiDialog-paper': {
        //     minWidth: '30vw'
        //   }
        // }}                      
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Cost/Kg Delta
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseDelta} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent>
          <Typography>{deltaData?.vault_name}</Typography>
          <Typography>Average Cost/Kg : {deltaData?.avg_cost_per_kg} {sessionStorage.getItem("costingLpp")}/Kg</Typography>
          <br />
          <Typography sx={{ color: `${deltaData?.color == '#c4efb3' ? 'green' : deltaData?.color == '#fe5c5c99' ? 'red' : '#007fff'}` }}>{deltaData?.product_name} is {deltaData?.above_below_status}</Typography>
          <Typography sx={{ color: `${deltaData?.color == '#c4efb3' ? 'green' : deltaData?.color == '#fe5c5c99' ? 'red' : '#007fff'}` }}>average by {deltaData?.percent} % ( {deltaData?.difference} {sessionStorage.getItem("costingLpp")} )</Typography>
        </DialogContent>
      </Dialog>
      {
       costKgDataLoader ?
       handleLoader()
       : 
        
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: 'primary.light', position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', minWidth: '30rem', maxWidth: '30rem', width: '30rem', borderRight: '0.1rem solid white' }} rowSpan={2}>Part Name</TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', textAlign: 'center', minWidth: '4rem', borderRight: '0.1rem solid white' }} colSpan={2}>
                <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  endIcon={<ArrowDropDownIcon />}
                  style={{ fontSize: "12px", marginRight: "1rem", marginTop: '0.1rem' }}
                  onClick={() => currencyModal.open()}
                >
                  {sessionStorage.getItem("costingLpp")}
                </Button>
              </TableCell>
              {costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.map((item: any, index: number) => {
                return (
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', textAlign: 'center', borderRight: '0.1rem solid white' }} colSpan={4}>
                    <span style={index == 0 ? { display: 'flex', columnGap: '0.5rem', justifyContent: 'space-between' } : index == (costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.length - 1) ? { display: 'flex', columnGap: '0.5rem', justifyContent: 'space-between' } : { display: 'flex', columnGap: '0.5rem', justifyContent: 'center' }}>
                      {index == 0 && <LoadingButton sx={{ p: 0 }} disabled={!(paginationProduct && paginationProduct?.[0]?.previous)} onClick={() => redirectPaginationPush('prev')} title="Previous Vehicle"><ChevronLeftIcon /></LoadingButton>}
                      {index == (costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.length - 1) && <span></span>}
                      <span style={index == 0 ? { marginRight: '4rem' } : index == (costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.length - 1) ? { marginLeft: '4rem' } : {}}>{item?.tp_name}</span>
                      {index == 0 && <span></span>}
                      {index == (costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.length - 1) && <LoadingButton sx={{ p: 0 }} disabled={!(paginationProduct && paginationProduct?.[0]?.next)} onClick={() => redirectPaginationPush('next')} title="Next Vehicle"><ChevronRightIcon /></LoadingButton>}
                    </span>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light',whiteSpace:'nowrap' }}>
                Actions
              </TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', borderRight: '0.1rem solid white', textAlign: 'right',whiteSpace:'nowrap' }}>
                Avg. Cost/Kg
              </TableCell>
              {costKgData && costKgData?.data?.[0]?.costing_data?.costing_tp_data?.map((item: any) => {
                return (<>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', textAlign: 'right',whiteSpace:'nowrap' }}>
                    Cost
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', textAlign: 'right',whiteSpace:'nowrap' }}>
                    Weight (Kg)
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', textAlign: 'right',whiteSpace:'nowrap' }}>
                    Cost/Kg
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', borderRight: '0.1rem solid white', textAlign: 'center',whiteSpace:'nowrap' }}>
                    Details
                  </TableCell>
                </>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>

            {

            
            costKgData && costKgData?.data?.map((item: any) => {
              return (
                <CostingCreateLPPTableRow itemIncoming={item} handleOpenHierarchy={handleOpenHierarchy} sessionGetInto={sessionGetInto} setOpenDelta={setOpenDelta} handleOpenDelta={handleOpenDelta} handleOpenGraph={handleOpenGraph} handleOpenInfo={handleOpenInfo} handleOpenInference={handleOpenInference} hoveredIndex={hoveredIndex} setHoveredIndex={setHoveredIndex} filterIsWorking={filterIsWorking} loaderRecursiveId={loaderRecursiveId}/>
              )
            })}
          </TableBody>
        </Table>}
    </>)
}

export default CostingCreateLPPTable;