const CALCULATOR_TYPE = {
    RAW_MATERIAL: 1,
    PROCESS: 2,
    OVERHEAD: 3,
};

export type InputContentType = "MaterialCost"

const DataBaseContentTypes: InputContentType[] = ["MaterialCost"];

const CALCULATOR_INPUT_CONTENT_TYPE = {
    BOM_PARAMETER: "ComponentDetails",
    COMMODITY_PARAMETER: "ProcessDetails",
    MATERIAL_DB: "Material",
    MACHINE_DB: "Machine",
    LABOUR_DB: "Labour",
    TOOL_DB: "ToolDatabase",
    FASTENER_DB: "FastenerDatabase",
    PURCHASING_ITEM_DB: "PurchasingItem",
    CALCULATOR_RESULT: "CostCalculation",
    COST_INPUT: "CostEstimation",
    SELL_BOM_PARAMETER: "SellBOMVersionDetail",
    PROCURE_DATABASE: "ProductVersion",
    STROKE_RATE_DATABASE: "StrokeRateDatabase",
    BOM_TABLE_CALCULATOR: "BOMTableCalculator"
};
const CALCULATOR_INPUT_CONTENT_TYPE_DB = {
    BOM_PARAMETER: "MaterialCost",
    COMMODITY_PARAMETER: "ProcessDetails",
    MATERIAL_DB: "Material",
    MACHINE_DB: "Machine",
    LABOUR_DB: "Labour",
    TOOL_DB: "ToolDatabase",
    FASTENER_DB: "FastenerDatabase",
    PURCHASING_ITEM_DB: "PurchasingItem",
    CALCULATOR_RESULT: "CostCalculation",
    COST_INPUT: "CostEstimation",
    SELL_BOM_PARAMETER: "SellBOMVersionDetail",
    PROCURE_DATABASE: "ProductVersion",
    STROKE_RATE_DATABASE: "StrokeRateDatabase",
    BOM_TABLE_CALCULATOR: "BOMTableCalculator"
};
const CALCULATOR_INPUT_CONTENT_TYPE_DB_TOOL = {
    BOM_PARAMETER: "ToolCost",
    COMMODITY_PARAMETER: "ProcessDetails",
    MATERIAL_DB: "Material",
    MACHINE_DB: "Machine",
    LABOUR_DB: "Labour",
    TOOL_DB: "ToolDatabase",
    FASTENER_DB: "FastenerDatabase",
    PURCHASING_ITEM_DB: "PurchasingItem",
    CALCULATOR_RESULT: "CostCalculation",
    COST_INPUT: "CostEstimation",
    SELL_BOM_PARAMETER: "SellBOMVersionDetail",
    PROCURE_DATABASE: "ProductVersion",
    STROKE_RATE_DATABASE: "StrokeRateDatabase",
    BOM_TABLE_CALCULATOR: "BOMTableCalculator"
};

const CALCULATOR_INPUT_TYPE = {
    BOM_PARAMETER: 1,
    COMMODITY_PARAMETER: 2,
    DATABASE: 3,
    CONSTANT: 4, // fixed value, default value filled during configuration    
    FORMULA: 5,
    USER_INPUT: 6,
    COST_INPUT: 7,
    CALCULATOR_RESULT: 8,
    SELL_BOM_PARAMETER: 9,
    PROCURE_DATABASE: 10,
    VOLUME: 11,
    BOM_TABLE_CALCULATOR: 12,
    PCB_BOM_TABLE: 13,
    DATABASE_CONSTANT: 14, 
    COSTING_DATABASE: 15, 
};
const CALCULATOR_DATABASE = {
    MATERIAL: 1,
    COMMODITY_PARAMETER: 2,
    DATABASE: 3,
    CONSTANT: 4, // fixed value, default value filled during configuration
    FORMULA: 5,
    USER_INPUT: 6,
    COST_INPUT: 7,
    CALCULATOR_RESULT: 8,
    SELL_BOM_PARAMETER: 9,
    PROCURE_DATABASE: 10,
    VOLUME: 11,
    BOM_TABLE_CALCULATOR: 12,
    PCB_BOM_TABLE: 13,
    DATABASE_CONSTANT: 14,
    COSTING_DATABASE: 15,
};

const BOM_CALCULATOR_INPUT_TYPE = {
    BOM_PARAMETER: 1,
    COMMODITY_PARAMETER: 2,
    BOM_TABLE_CALCULATOR: 12,
    CONSTANT: 4, // fixed value, default value filled during configuration
    FORMULA: 5,
    USER_INPUT: 6,
    COST_INPUT: 7,

};
const lightenColor = (color: any, factor: any) => {
    // Convert hex color to RGB
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(1, 5).substring(2, 4), 16);
    let b = parseInt(color.substring(1, 7).substring(4, 6), 16);

    // Increase lightness by multiplying each channel value
    r = Math.min(255, r + (255 - r) * factor);
    g = Math.min(255, g + (255 - g) * factor);
    b = Math.min(255, b + (255 - b) * factor);

    // Convert RGB back to hex
    return `#${((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1)}`;
};

const calculatorInputBackgroundColorMapView = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: lightenColor("#FFC1C1", 0.5), // Lightened light red
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: lightenColor("#FFEB80", 0.5), // Lightened light yellow
    [CALCULATOR_INPUT_TYPE.DATABASE]: lightenColor("#FFD1A1", 0.5), // Lightened light green
    [CALCULATOR_INPUT_TYPE.CONSTANT]: lightenColor("#FFB3FF", 0.5), // Lightened light magenta
    [CALCULATOR_INPUT_TYPE.FORMULA]: lightenColor("#B2FFC1", 0.5), // Lightened light orange
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: lightenColor("#F7F7F7", 0.5), // Lightened light gray
    [CALCULATOR_INPUT_TYPE.COST_INPUT]: lightenColor("#FFBFD3", 0.5), // Lightened light pink
    [CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT]: lightenColor("#D1C6FF", 0.5), // Lightened light lavender
    [CALCULATOR_INPUT_TYPE.SELL_BOM_PARAMETER]: lightenColor("#FFE5B4", 0.5), // Lightened light peach
    [CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR]: lightenColor("#C1E6FF", 0.5), // Lightened light cyan
    [CALCULATOR_INPUT_TYPE.VOLUME]: lightenColor("#B2B2FF", 0.5), // Lightened light periwinkle
    [CALCULATOR_INPUT_TYPE.DATABASE_CONSTANT]: lightenColor("#fff9a1", 0.5), // Lightened light green
};
const calculatorInputBackgroundColorMapViewScenario = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: lightenColor("#F7F7F71", 0.5), // Lightened light red
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: lightenColor("#F7F7F7", 0.5), // Lightened light yellow
    [CALCULATOR_INPUT_TYPE.DATABASE]: lightenColor("#F7F7F7", 0.5), // Lightened light green
    [CALCULATOR_INPUT_TYPE.CONSTANT]: lightenColor("#F7F7F7", 0.5), // Lightened light magenta
    [CALCULATOR_INPUT_TYPE.FORMULA]: lightenColor("#B2FFC1", 0.5), // Lightened light orange
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: lightenColor("#F7F7F7", 0.5), // Lightened light gray
    [CALCULATOR_INPUT_TYPE.COST_INPUT]: lightenColor("#F7F7F7", 0.5), // Lightened light pink
    [CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT]: lightenColor("#F7F7F7", 0.5), // Lightened light lavender
    [CALCULATOR_INPUT_TYPE.SELL_BOM_PARAMETER]: lightenColor("#F7F7F7", 0.5), // Lightened light peach
    [CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR]: lightenColor("#F7F7F7", 0.5), // Lightened light cyan
    [CALCULATOR_INPUT_TYPE.VOLUME]: lightenColor("#F7F7F7", 0.5), // Lightened light periwinkle
    [CALCULATOR_INPUT_TYPE.DATABASE_CONSTANT]: lightenColor("#F7F7F7", 0.5), // Lightened light green
};



const calculatorInputBackgroundColorMap = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.DATABASE]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.CONSTANT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.FORMULA]: "#d0f2d9",
    //a5e1a6 
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: "#FFF",
    [CALCULATOR_INPUT_TYPE.COST_INPUT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.SELL_BOM_PARAMETER]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.VOLUME]: "#add8e6",
    [CALCULATOR_INPUT_TYPE.DATABASE_CONSTANT]: "#FFFFFF",
};

const CALCULATOR_RESULT_COLOR = "#ddefff";

const calculatorInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    // { key: 2, value: 2, text: "Commodity Parameter" },
    { key: 3, value: 3, text: "Database (Fetch)" },
    { key: 4, value: 4, text: "Constant" },  
    { key: 14, value: 14, text: "Database (Constant)" },  
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
    { key: 7, value: 7, text: "Cost Input" },
    { key: 8, value: 8, text: "Calculator Result" },
    
    { key: 12, value: 12, text: "BOM Table Calculator" },
    { key: 11, value: 11, text: "Volume (Fetch from Assumptions Card)" },
    { key: 13, value: 13, text: "PCB BOM Table" },
    // { key: 15, value: 15, text: "Costing Database" },
];

const BOMcalculatorInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 12, value: 12, text: "BOM Table Calculator" },
    { key: 6, value: 6, text: "User Input" },
];

const BOMcalculatorInputOptionsDB = [
    { key: 1, value: 1, text: "Material" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
];
const BOMcalculatorInputOptionsToolDB = [
    { key: 1, value: 1, text: "Tool" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
];
const BOMcalculatorInputOptionsTool = [
    { key: 1, value: 1, text: "Tool" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
];
const BOMcalculatorTableInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    { key: 12, value: 12, text: "BOM Table Calculator" },
];

const TriangulationInputOptions = [
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
    { key: 7, value: 7, text: "Cost Input" },
    { key: 11, value: 11, text: "Volume" }
];

const CALCULATOR_COST_INPUT_MAP = {
    RAW_MATERIAL_COST: 1,
    PROCESS_COST: 2,
    BOP_COST: 3,
};

const CALCULATOR_CATEGORY = {
    RAW_MATERIAL: 1,
    PURCHASE_ITEM: 2,
    LABOUR: 3,
    MACHINE: 4,
    TOOL: 5,
    SET_UP: 6,
    STROKE_RATE: 7
};

const CALCULAOR_CATEGORY_LABEL = {
    [CALCULATOR_CATEGORY.RAW_MATERIAL]: "Raw Material",
    [CALCULATOR_CATEGORY.PURCHASE_ITEM]: "Purchase Item",
    [CALCULATOR_CATEGORY.LABOUR]: "Labour",
    [CALCULATOR_CATEGORY.MACHINE]: "Machine",
    [CALCULATOR_CATEGORY.TOOL]: "Tool",
    [CALCULATOR_CATEGORY.SET_UP]: "Set-up",
    [CALCULATOR_CATEGORY.STROKE_RATE]: "Stroke Rate",
    0: "Overhead"
};

const calculatorCategoryOptions = {
    [CALCULATOR_TYPE.RAW_MATERIAL]: [
        { key: 1, value: 1, text: "Raw Material" },
        { key: 2, value: 2, text: "Bought Out" },
    ],
    [CALCULATOR_TYPE.PROCESS]: [
        { key: 3, value: 3, text: "Labour" },
        { key: 4, value: 4, text: "Machine" },
        { key: 5, value: 5, text: "Tool" },
        { key: 6, value: 6, text: "Set-up" },
    ],
    [CALCULATOR_TYPE.OVERHEAD]: [
        { key: 0, value: 0, text: "Overhead" },
    ],
};

const getDefaultCalculatorCategory = (inputType: number) => {
    return inputType === CALCULATOR_TYPE.RAW_MATERIAL
        ? CALCULATOR_CATEGORY.RAW_MATERIAL
        : inputType === CALCULATOR_TYPE.PROCESS
            ? CALCULATOR_CATEGORY.LABOUR
            : 0;
};

const calculatorCostInputOptions = [
    { key: 1, value: "raw_material", text: "Raw Material Cost" },
    { key: 2, value: "bop", text: "Bought-out parts Cost" },
    { key: 3, value: "labour", text: "Labour Cost" },
    { key: 4, value: "machine", text: "Machine Cost" },
    { key: 5, value: "tool", text: "Tool Cost" },
    { key: 6, value: "setup", text: "Set-up Cost" },
    // { key: 7, value: "stoke_rate", text: "Stroke Rate Cost" },
    { key: 8, value: "child_part_cost", text: "Child Part Cost" },
];

const CALCULATOR_DATABASES_MAP = {
    MATERIAL: 1,
    MACHINE: 2,
    LABOUR: 3,
    TOOL: 4,
    FASTENER: 5,
    PURCHASEING_ITEM: 6,
    STROKE_RATE: 7,
    CURRENCY: 8,
};

const COSTING_DATABASES_MAP = {
    STEEL: "STEEL",
    PLASTIC: "PLASTIC",
    RUBBER: "RUBBER",
 
};

const calculatorDatabaseOptions = [
    { key: 1, value: 1, text: "Material" },
    { key: 2, value: 2, text: "Machine" },
    { key: 3, value: 3, text: "Labour" },
    { key: 4, value: 4, text: "Tool" },
    { key: 6, value: 6, text: "Bought-out Part" },
    { key: 7, value: 7, text: "Operation Rate Database" },
    { key: 8, value: 8, text: "Currency Database" },
];

const costingDatabaseOptions = [
    { key: 1, value: 1, text: "Material" },
    { key: 2, value: 2, text: "Bought out Part" },
];

const materialCategoryOptions = [
    { key: 1, value: "STEEL", text: "STEEL" },
    { key: 2, value: "PLASTIC", text: "PLASTIC" },
    { key: 3, value: "RUBBER", text: "RUBBER" },
];

const procureOptions = [
    { key: "dynamic_parameter", value: "dynamic_parameter", text: "Dynamic Parameter" },
    { key: "unit_rate", value: "unit_rate", text: "Unit Rate" },
];
const calculatorDatabaseColumnsOptions = {
    [CALCULATOR_DATABASES_MAP.MACHINE]: [
        { key: "cost_per_hour", value: "cost_per_hour", text: "Cost/Hour" },
    ],
    [CALCULATOR_DATABASES_MAP.MATERIAL]: [
        { key: "density", value: "density", text: "Density (kg/m3)" },
        { key: "material_cost", value: "material_cost", text: "Material Cost" },
        { key: "scrap_cost", value: "scrap_cost", text: "Scrap Cost" },
        // -----------------------------------------------------------------
        { key: "steel-shearStrength", value: "STEEL-Shear Strength", text: "STEEL-Shear Strength" },
        { key: "steel-tensileStrength", value: "STEEL-Ultimate Tensile Strength", text: "STEEL-Ultimate Tensile Strength" },
        { key: "steel-yieldStrength", value: "STEEL-Yield Strength", text: "STEEL-Yield Strength" },
        //-- -----------------------------------------------------------------
        { key: "plastic-thermalDiffusivity", value: "PLASTIC-Thermal Diffusivity", text: "PLASTIC-Thermal Diffusivity" },
        { key: "Plastic-meltingTemperature", value: "PLASTIC-Melting Temperature", text: "PLASTIC-Melting Temperature" },
        { key: "Plastic-moldTemperature", value: "PLASTIC-Mold Temperature", text: "PLASTIC-Mold Temperature" },
        { key: "Plastic-heatDeflectionTemperature", value: "PLASTIC-Heat Deflection Temperature", text: "PLASTIC-Heat Deflection Temperature" },
        { key: "Plastic-specificHeatCapacityPlastics", value: "PLASTIC-Specific Heat Capacity - Plastics", text: "PLASTIC-Specific Heat Capacity - Plastics" },
        { key: "Plastic-annealingTemperaturePlastics", value: "PLASTIC-Annealing Temperature - Plastics", text: "PLASTIC-Annealing Temperature - Plastics" },
        { key: "Plastic-materialConstantInjectionMoulding", value: "PLASTIC-Material Constant (Injection Moulding)", text: "PLASTIC-Material Constant (Injection Moulding)" },
        { key: "Plastic-branchRunnerLength", value: "PLASTIC-Branch Runner Length", text: "PLASTIC-Branch Runner Length" },
        { key: "Plastic-cavityPressure", value: "PLASTIC-Cavity Pressure", text: "PLASTIC-Cavity Pressure" },
        { key: "Plastic-injectionEfficiency", value: "PLASTIC-Injection Efficiency", text: "PLASTIC-Injection Efficiency" },
         //-- -----------------------------------------------------------------
        { key: "rubber-specificHeatCapacity", value: "RUBBER-Specific Heat Capacity", text: "RUBBER-Specific Heat Capacity" },
        { key: "rubber-materialWorkingTemperature", value: "RUBBER-Material Working Temperature", text: "RUBBER-Material Working Temperature" },
    ],
    [CALCULATOR_DATABASES_MAP.LABOUR]: [
        { key: "cost_per_hour", value: "cost_per_hour", text: "Cost/Hour" },
    ],
    [CALCULATOR_DATABASES_MAP.TOOL]: [
        { key: "price", value: "price", text: "Tool Cost" },
    ],
    [CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM]: [
        { key: "unit_cost", value: "unit_cost", text: "Per unit Cost (INR)" },
    ],
    [CALCULATOR_DATABASES_MAP.STROKE_RATE]: [
        { key: "rate_per_unit", value: "rate_per_unit", text: "Rate/Unit" },
    ],
    [CALCULATOR_DATABASES_MAP.CURRENCY]: [
        { key: "rate_per_unit", value: "currency", text: "Conversion to INR" },
    ],
};

const materialFields: any = [
    { key: "material_code", text: "Material Code" },
    { key: "material_grade", text: "Grade" },
    { key: "material_subgrade", text: "Sub Grade" },
    { key: "material_cost", text: "Material Cost" },
    { key: "scrap_cost", text: "Scrap Cost" },
    { key: "shape", text: "Initial Shape" },
    { key: "density", text: "Density" },

    { key: "material_source", text: "Material Source" },
    { key: "material_reference", text: "Material Ref" },
    { key: "scrap_source", text: "Scrap Source" },
    { key: "scrap_reference", text: "Scrap Ref" },






    // Bearing
    { key: "name", text: "Name" },
    { key: "code", text: "Code" },
    { key: "height", text: "Height" },
    { key: "weight", text: "Weight" },
    { key: "item_id", text: "ID (mm)" },
    { key: "item_od", text: "OD (mm)" },
    { key: "reference", text: "Reference" },
    { key: "unit_cost", text: "Per Unit Cost" },
// Connector
    { key: "no_of_pins", text: "No. of pins" },
    { key: "postion_config", text: "Position config (mxn)" },
    { key: "shape", text: "Shape" },
    { key: "pin_pitch", text: "Terminal pin pitch" },
    { key: "connection_type", text: "Connection type" },
    { key: "gender", text: "Male/Female" },
    { key: "fit_type", text: "Fit Type" },
    { key: "material", text: "MAterial" },

    // Consumables
    { key: "description", text: "Description" },
    { key: "commodity_name", text: "Item Name" },
    
      //Electrical Components
     // Bulb
     { key: "type", text: "Type" },
     { key: "rated_voltage", text: "Rated Voltage" },
     { key: "base_code", text: "Base Code" },
     { key: "no_of_filament", text: "No. of filaments" },
     { key: "watttage", text: "Filament Wattage" },

      // Remote/lock
     { key: "sensing_technology", text: "Sensing Technology" },
     { key: "type_of_key_fob", text: "Type of key fob" },
     { key: "no_of_function", text: "No. of functions" },
     { key: "function", text: "Functions" },
     { key: "range", text: "Range" },

      // Horn
     { key: "current_rating", text: "Current Rating" },
     { key: "power_rating", text: "Power Rating" },
     { key: "frequency", text: "Frequency" },
     { key: "sound_level", text: "Sound Level" },

     // Ignition
     { key: "no_of_position", text: "No. of positions" },
     { key: "no_of_terminal", text: "No. of terminals" },
     { key: "with_handle_lock", text: "With handle lock" },
    
    // Flasher
     { key: "frequency", text: "Flasher frequency" },
     { key: "watttage", text: "Wattage" },
     { key: "adjustable", text: "Adjustable" },
         
     // Electronic Child Part
     { key: "length", text: "Length" },
     { key: "width", text: "Width" },
     { key: "component_type", text: "Component Type" },

           
     // Fastener
     { key: "type", text: "Type of fastener" },
     { key: "grade", text: "Grade" },
     { key: "m_size", text: "M-Size" },
     { key: "thread_length", text: "Thread Length" },

     // Mechanical
     { key: "brand_name", text: "Brand" },
         
     // Uncategorized
     { key: "place", text: "Place of Mfg" },


         // Machine
    { key: "machine_code", text: "Machine Code" },
    { key: "capital_cost", text: "Capital Cost" },
    { key: "installation_cost", text: "Installation cost" },
    { key: "life", text: "Life(years)" },
    { key: "operation", text: "Operation" },
    { key: "maintanance", text: "Maintenance (%/years/shifts)" },
    { key: "consumption", text: "Consumable Cost" },
    { key: "power_consumption", text: "Avg power cons" },
    { key: "effectiveness", text: "Effectiveness (%)" },
    { key: "peak_power_factor", text: "Peak power factor" },

    // Labour
    { key: "labour_code", text: "Labour Code" },
    { key: "labour_type", text: "Labour Type" },

    // Tool
    { key: "specification", text: "Specification" },
    { key: "category", text: "Category" },
    { key: "material", text: "Material" },
    { key: "supplier", text: "Supplier" },

    // Labour
    { key: "remark", text: "Remark" },
    { key: "rate_per_unit", text: "Rate/Unit(Currency)" },




    { key: "shearStrength", value: "Shear Strength", text: "Shear Strength",   unit:"N/mm^2" },
    {
      key: "tensileStrength",
      value: "Ultimate Tensile Strength",
      text: "Ultimate Tensile Strength",
      unit:"N/mm^2"
    },
    { key: "yieldStrength", value: "Yield Strength", text: "Yield Strength", unit:"N/mm^2" },
    {
      key: "thermalDiffusivity",
      value: "Thermal Diffusivity",
      text: "Thermal Diffusivity",
      unit:"mm^2/sec"

      
    },
    {
      key: "meltingTemperature",
      value: "Melting Temperature",
      text: "Melting Temperature",
       unit:"deg C"
    },
    {
      key: "moldTemperature",
      value: "Mold Temperature",
      text: "Mold Temperature",
      unit:"deg C"
    },
    {
      key: "heatDeflectionTemperature",
      value: "Heat Deflection Temperature",
      text: "Heat Deflection Temperature",
      unit:"deg C"
    },
    {
      key: "specificHeatCapacityPlastics",
      value: "Specific Heat Capacity - Plastics",
      text: "Specific Heat Capacity - Plastics",
      unit:"j/g-deg C"
    },
    {
      key: "annealingTemperaturePlastics",
      value: "Annealing Temperature - Plastics",
      text: "Annealing Temperature - Plastics",
      unit:"deg C"
    },
    {
      key: "materialConstantInjectionMoulding",
      value: "Material Constant (Injection Moulding)",
      text: "Material Constant (Injection Moulding)",
      unit:"cnst"
    },
    {
      key: "branchRunnerLength",
      value: "Branch Runner Length",
      text: "Branch Runner Length",
      unit:"mm"
    },
    {
      key: "cavityPressure",
      value: "Cavity Pressure",
      text: "Cavity Pressure",
      unit:"PSI"
    },
    {
      key: "injectionEfficiency",
      value: "Injection Efficiency",
      text: "Injection Efficiency",
      unit:"%"
    },
    {
      key: "specificHeatCapacity",
      value: "Specific Heat Capacity",
      text: "Specific Heat Capacity",
      unit:"j/g-deg C"
    },
    {
      key: "materialWorkingTemperature",
      value: "Material Working Temperature",
      text: "Material Working Temperature",
      unit:"deg C"
    },

    { key: "currency_code", text: "Currency" },
    { key: "unit", text: "Unit" },

  ];

 



const costingDatabaseColumnsOptions = {
    [COSTING_DATABASES_MAP.STEEL]: [
        { key: "shearStrength", value: "Shear Strength", text: "Shear Strength" },
        { key: "tensileStrength", value: "Ultimate Tensile Strength", text: "Ultimate Tensile Strength" },
        { key: "yieldStrength", value: "Yield Strength", text: "Yield Strength" },
    ],
    [COSTING_DATABASES_MAP.PLASTIC]: [
        { key: "thermalDiffusivity", value: "Thermal Diffusivity", text: "Thermal Diffusivity" },
        { key: "meltingTemperature", value: "Melting Temperature", text: "Melting Temperature" },
        { key: "moldTemperature", value: "Mold Temperature", text: "Mold Temperature" },
        { key: "heatDeflectionTemperature", value: "Heat Deflection Temperature", text: "Heat Deflection Temperature" },
        { key: "specificHeatCapacityPlastics", value: "Specific Heat Capacity - Plastics", text: "Specific Heat Capacity - Plastics" },
        { key: "annealingTemperaturePlastics", value: "Annealing Temperature - Plastics", text: "Annealing Temperature - Plastics" },
        { key: "materialConstantInjectionMoulding", value: "Material Constant (Injection Moulding)", text: "Material Constant (Injection Moulding)" },
        { key: "branchRunnerLength", value: "Branch Runner Length", text: "Branch Runner Length" },
        { key: "cavityPressure", value: "Cavity Pressure", text: "Cavity Pressure" },
        { key: "injectionEfficiency", value: "Injection Efficiency", text: "Injection Efficiency" },
      
    ],
    [COSTING_DATABASES_MAP.RUBBER]: [
        { key: "specificHeatCapacity", value: "Specific Heat Capacity", text: "Specific Heat Capacity" },
        { key: "materialWorkingTemperature", value: "Material Working Temperature", text: "Material Working Temperature" },
    ],
   
};


const triangulation_calculator = [
    { key: 1, value: "Manufacturing Cost 1", text: "Manufacturing Cost 1" },
    { key: 2, value: "Manufacturing Cost 2", text: "Manufacturing Cost 2" },
    { key: 3, value: "Ex-Showroom Price Excluding GST 1", text: "Ex-Showroom Price Excluding GST 1" },
    { key: 4, value: "Ex-Showroom Price Excluding GST 2", text: "Ex-Showroom Price Excluding GST 2" },
    { key: 5, value: "OEM Assembly cost", text: "OEM Assembly cost" },
    // { key: 6, value: "MFG cost", text: "MFG cost" },
    { key: 7, value: "Warranty", text: "Warranty" },
    { key: 8, value: "OBL", text: "OBL" },
    // { key: 9, value: "OEM Cost", text: "OEM Cost" },
    { key: 10, value: "R&D", text: "R&D" },
    { key: 11, value: "Finance costs", text: "Finance costs" },
    { key: 12, value: "ICC", text: "ICC" },
    { key: 13, value: "overheads", text: "overheads" },
    { key: 14, value: "OEM profit", text: "OEM profit" },
    { key: 15, value: "Dealer profit", text: "Dealer profit" },
    // { key: 16, value: "Ex showroom price", text: "Ex showroom price" },

];

const BOMPCBOptions = [
    {
        id: 1,
        title: "ALL",
        value: "ALL"
    },
    {
        id: 2,
        title: "SMD-TOP",
        value: "SMD-Top"
    },
    {
        id: 3,
        title: "SMD-BOTTOM",
        value: "SMD-Bottom"
    },
    {
        id: 4,
        title: "THD-TOP",
        value: "THD-Top"
    },
    {
        id: 5,
        title: "THD-BOTTOM",
        value: "THD-Bottom"
    },
    
]

const pcb_table_element_list:any = [
    { id: 1, title: "Individual row parameter", value: "Individual row parameter" },
    { id: 2, title: "Sum of all rows", value: "Sum of all rows" },
    { id: 3, title: "Average of all rows", value: "Average of all rows" },  
    { id: 4, title: "Minimum value", value: "Minimum value" }, 
    { id: 5, title: "Maximum value", value: "Maximum value" },
    
]

export {
    CALCULATOR_TYPE,
    DataBaseContentTypes,
    CALCULATOR_INPUT_CONTENT_TYPE,
    CALCULATOR_DATABASES_MAP,
    CALCULATOR_INPUT_TYPE,
    CALCULATOR_COST_INPUT_MAP,
    CALCULATOR_CATEGORY,
    calculatorCategoryOptions,
    calculatorInputOptions,
    calculatorDatabaseOptions,
    costingDatabaseOptions,    
    calculatorDatabaseColumnsOptions,
    calculatorCostInputOptions,
    calculatorInputBackgroundColorMap,
    procureOptions,
    CALCULATOR_RESULT_COLOR,
    getDefaultCalculatorCategory,
    CALCULAOR_CATEGORY_LABEL,
    TriangulationInputOptions,
    BOM_CALCULATOR_INPUT_TYPE,
    BOMcalculatorInputOptions,
    triangulation_calculator,
    BOMcalculatorTableInputOptions,
    calculatorInputBackgroundColorMapView,
    BOMPCBOptions,
    pcb_table_element_list,
    calculatorInputBackgroundColorMapViewScenario,
    CALCULATOR_DATABASE,
    CALCULATOR_INPUT_CONTENT_TYPE_DB,
    BOMcalculatorInputOptionsDB,
    BOMcalculatorInputOptionsTool,
    BOMcalculatorInputOptionsToolDB,
    CALCULATOR_INPUT_CONTENT_TYPE_DB_TOOL,
    materialCategoryOptions,
    costingDatabaseColumnsOptions,
    materialFields
};
