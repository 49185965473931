import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { API } from "../../api-services";
import { isUndefined } from "lodash";
import { Item } from "devextreme-react/form";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";
type ProjectDirectoryRows = {
  items: any;
  keys: any;
  currencyCodes: any;
  countryList: any;

  // SearchData:any;
  // SearchHandler:any;
};

const ProjectDirectoryRows = (props: ProjectDirectoryRows) => {
  const {
    items,
    keys,
    currencyCodes,
    countryList,

    // SearchData ,
    // SearchHandler
  } = props;

  const [selectValue, SetselectValue] = React.useState<any>(items?.oem);
  const [inputValue, setInputValue] = React.useState<any>(null);
  const [VariantName, setVariantName] = React.useState<any>();
  const [SearchOemData, setSearchOemData] = React.useState<any>(
    items?.oem_details && items?.oem_details
  );

  const [MfgData, setMfgData] = React.useState<any | string>();
  const [ErrorWeights, setErrorWeights] = React.useState<any | string>();

  const [EditStatus, setEditStatus] = useState(false);
  const [activeStatus, setActiveStatus] = useState(true);

  const [VariantNameStatus, setVariantNameStatus] = useState<any>(false);
  const [MfgDataStatus, setMfgDataStatus] = React.useState(false);
  const [saveLoader, setSaveLoader] = useState<any>(false);
  const [ErrorWeightsStatus, setErrorWeightsStatus] = useState<any>(false);
  const [currencyCode, setCurrencyCode] = useState<any>(items?.currency);
  // const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  // const [countryList, setCountryList] = React.useState<any[]>([]);

  // const getCountryData = () => {
  //   API.get(
  //     "customer/currency_search/",
  //     { all_currencies: true, database: true },
  //     0
  //   )
  //     .then((res: any) => {
  //       const c_codes = res?.data.map((item: any) => item.currency_code);
  //       setCurrencyCodes(c_codes);
  //       setCountryList(res.data);
  //     })
  //     .catch((err: any) => {
  //       console.error("Error fetching currency data:", err);
  //     });
  // };

  // React.useEffect(() => {
  //   getCountryData();
  // }, []);

  const flagMap = countryList?.reduce((acc: any, country: any) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  const handleChangeSelected = (newValue: any, keydata: any) => {
    if (keydata == keys) {
      SetselectValue([newValue?.id]);
    }
  };

  useEffect(() => {
    if (!isUndefined(items)) {
      var data;
      SetselectValue(items?.oem);

      var ErrorAcceptance =
        items?.error_acceptance && items?.error_acceptance * 100;
      setErrorWeights(ErrorAcceptance);
      setVariantName(items?.name && items?.name);
      setMfgData(items?.manufacturing_year && items?.manufacturing_year);
      setSearchOemData(items?.oem_details && items?.oem_details);
      setCurrencyCode(items?.currency);
    }
  }, [items]);

  // console.log(SearchOemData,"vdvbdvdiuvdvdvb")

  //   console.log(selectValue,"ccvhdbjvdjvhbjdvhbj",items)

  const SearchOption = () => {
    if (SearchOemData?.length > 0) {
      return (
        SearchOemData?.length > 0 &&
        SearchOemData?.map((item: any, index: any) => {
          return {
            lable: item?.name?.toString(),

            id: item?.id,
          };
        })
      );
    }

    //     console.log(                SearchOption() &&
    //     SearchOption()?.length > 0 &&
    //     SearchOption().filter((item: any, index: any) => {
    //       return item?.id == selectValue && selectValue[0];
    //     })[0],"Vdvbdhvbdbjvdbvdbjv"
    // )

    if (SearchOemData && SearchOemData?.length == 0) {
      return [
        {
          lable: " ",

          id: 0,
        },
      ];
    }
  };

  const EditHandler = (status: any) => {
    setEditStatus(status);

    setActiveStatus(!status);
    // @ts-ignore
  };

  const ClosedHanlder = (status: any) => {
    setEditStatus(false);
    setActiveStatus(true);
    setErrorWeightsStatus(false);
    setMfgDataStatus(false);
    setVariantNameStatus(false);
    SetselectValue(items?.oem);
    var ErrorAcceptance =
      items?.error_acceptance && Number(items?.error_acceptance) * 100;
    setErrorWeights(ErrorAcceptance);
    setVariantName(items?.name && items?.name);
    setMfgData(items?.manufacturing_year && items?.manufacturing_year);
  };

  const Texthandlechange = (e: any, type: any) => {
    if (type == "VariantName") {
      setVariantNameStatus(false);
      setVariantName(e?.target?.value);
    }
    if (type == "mfgData") {
      setMfgDataStatus(false);
      setMfgData(e?.target?.value);
    }
    if (type == "error") {
      setErrorWeightsStatus(false);

      setErrorWeights(e?.target?.value);
    }
  };

  const SaveHandler = () => {
    if (
      !isUndefined(VariantName) &&
      VariantName?.length > 0 &&
      !isUndefined(MfgData) &&
      !isUndefined(ErrorWeights) &&
      currencyCode?.length > 0
    ) {
      if (ErrorWeights > 0 && ErrorWeights <= 1) {
        setSaveLoader(true);
        setEditStatus(false);
        var data = Number(ErrorWeights) / 100;
        API.put(`/xcpep/top_vault/${items?.id}/`, {
          error_acceptance: data,
          name: VariantName,
          currency: currencyCode,
          oem: selectValue?.toString(),

          manufacturing_year: MfgData,
        }).then((res: any) => {
          setSaveLoader(false);
          setActiveStatus(true);
          setEditStatus(false);
          ADMIN.toast.info("Data Updated Successfully");
          var ErrorAcceptance =
            res?.data?.error_acceptance &&
            Number(res?.data?.error_acceptance) * 100;
          setErrorWeights(ErrorAcceptance);
        });
      } else {
        setErrorWeightsStatus("true");
      }
    }

    if (VariantName?.length == 0) {
      setVariantNameStatus(true);
    }
    if (MfgData?.length == 0) {
      setMfgDataStatus(true);
    }
    if (ErrorWeights?.length == 0) {
      setErrorWeightsStatus(true);
    }
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/xcpep/oem/search/", { search: criteria }).then(
        (res: any) => {
          setSearchOemData(res.data);
        }
      );
    }, 700)
  ).current;
  const PartNameHandler = (e: any, Searchkey: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    // setSearchValue([])
    setInputValue(Searchkey);
    if (e?.type == "change") {
      if (Searchkey !== "False" && Searchkey?.length > 0) {
        ExactDebounce(Searchkey);
      }
    }
  };

  return (
    <>
      <TableRow key={keys}>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderColor: "primary.light",
            paddingLeft: "1rem",
          }}
        >
          {keys + 1}
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <TextField
            id="standard-helperText"
            focused={EditStatus}
            // label="Helper text"
            value={VariantName && VariantName}
            InputProps={{
              readOnly: activeStatus,
              style: { fontSize: "1rem" },
            }}
            error={VariantNameStatus}
            onChange={(e: any) => Texthandlechange(e, "VariantName")}
            // helperText="Some important text"
            variant="standard"
            sx={{
              fontSize: "1rem",
              "& .MuiInput-underline:before": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "&:hover .MuiInput-underline": {
                borderBottomColor: "FFFFFF",
              },
              "& input::placeholder": {
                fontSize: "1rem",
              },
            }}
          />
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <Typography sx={{ fontSize: "1rem" }}>{items?.product_id}</Typography>
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <Typography sx={{ fontSize: "1rem" }}>{items?.created_at}</Typography>
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          {
            <Autocomplete
              value={
                SearchOption() &&
                SearchOption()?.length > 0 &&
                SearchOption().filter((item: any, index: any) => {
                  return item?.id == selectValue && selectValue[0];
                })[0]
              }
              id="controllable-states-demo"
              onChange={(event: any, newValue: string | null) => {
                handleChangeSelected(newValue, keys);
              }}
              disabled={activeStatus}
              // isOptionEqualToValue={(option, value) => option.value === value?.id}
              isOptionEqualToValue={(option, value) => option?.lable == value}
              // @ts-ignore
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                PartNameHandler(event, newInputValue);
              }}
              getOptionLabel={(option) => {
                if (option === false) {
                  return " ";
                }
                return option.lable?.toString();
              }}
              // @ts-ignore
              options={SearchOption() && SearchOption()}
              sx={{
                width: "100%",
                padding: "0 0.5rem",
                ".MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input":
                  {
                    fontSize: "1rem !important",
                  },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid",
                      borderBottomColor: EditStatus
                        ? "primary.main"
                        : "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "2px solid",
                      borderBottomColor: EditStatus
                        ? "primary.main"
                        : "primary.light",
                    },
                    "&:hover .MuiInput-underline": {
                      borderBottomColor: "FFFFFF",
                    },
                    ".MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input":
                      {
                        fontSize: "1rem",
                      },
                    "& input::placeholder": {
                      fontSize: "1rem",
                    },
                  }}
                  // placeholder="Search Part Name"
                />
              )}
            />
          }
        </TableCell>

        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <TextField
            id="standard-helperText"
            // label="Helper text"
            value={MfgData && MfgData}
            onChange={(e: any) => Texthandlechange(e, "mfgData")}
            InputProps={{
              readOnly: activeStatus,
              style: { fontSize: "1rem" },
            }}
            type="number"
            error={MfgDataStatus}
            focused={EditStatus}
            // helperText="Some important text"
            variant="standard"
            sx={{
              fontSize: "1rem",
              "& .MuiInput-underline:before": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "&:hover .MuiInput-underline": {
                borderBottomColor: "FFFFFF",
              },
              "& input::placeholder": {
                fontSize: "1rem",
              },
            }}
          />
          {/* {items?.manufacturing_year}  */}
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <TextField
            id="standard-helperText"
            error={ErrorWeightsStatus}
            // label="Helper text"
            value={ErrorWeights && ErrorWeights}
            focused={EditStatus}
            type="number"
            helperText={
              ErrorWeightsStatus == "true"
                ? "Allowed Wt. Error should be b/w 0 to 1."
                : ""
            }
            onChange={(e: any) => Texthandlechange(e, "error")}
            InputProps={{
              readOnly: activeStatus,
              style: { fontSize: "1rem" },
            }}
            // helperText="Some important text"
            variant="standard"
            sx={{
              fontSize: "1rem",
              "& .MuiInput-underline:before": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: EditStatus
                  ? "primary.main"
                  : "primary.light",
              },
              "&:hover .MuiInput-underline": {
                borderBottomColor: "FFFFFF",
              },
              "& input::placeholder": {
                fontSize: "1rem",
              },
            }}
          />
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          <FormControl
            fullWidth
            variant="standard"
            sx={{
              ".MuiFormLabel-asterisk": { color: "red" },
              columnGap: "1rem",
              padding: "0rem",
            }}
          >
            {/* <InputLabel
              id="demo-multiple-name-label"
              sx={{ fontSize: "0.87rem", color: "#007fff",}}              
            >
              Currency*
            </InputLabel> */}
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple={false}
              readOnly={activeStatus}
              value={currencyCode}
              placeholder="Currency*"
              // onChange={(event: any) => {
              //   setSelectedCurrencyCodes(event.target.value);

              //   const selectedCurIds = countryList
              //     .filter(
              //       (item: any) =>
              //         event.target.value === item?.currency_code
              //     )
              //     .map((obj: any) => obj?.id);

              //   formik.setFieldValue("currency_id", selectedCurIds);
              // }}
              onChange={(e: any) => setCurrencyCode(e.target.value)}
              input={<Input id="standard-input" placeholder="Currency" />}
              error={!currencyCode && Boolean(!currencyCode)}
              //@ts-ignore
              // helperText={
              //   formik.touched.currency_id && formik.errors.currency_id
              // }
              sx={{
                "& .MuiSelect-select": {
                  textAlign: "left",
                  fontSize: "1rem",
                  borderBottom: "1px solid #007fff",
                },
              }}
            >
              {currencyCodes.map((name: any, index: any) => (
                <MenuItem key={index} value={name}>
                  {flagMap[name] && (
                    <img
                      src={`data:image/png;base64, ${flagMap[name]}`}
                      alt={`${name} flag`}
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    />
                  )}
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderColor: "primary.light" }}
        >
          {EditStatus ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LoadingButton loading={saveLoader} sx={{ minWidth: "16px" }}>
                <SaveRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => SaveHandler()}
                />
              </LoadingButton>
              <ClearRoundedIcon
                sx={{ cursor: "pointer" }}
                color="error"
                onClick={() => ClosedHanlder(false)}
              />
            </Box>
          ) : (
            <>
              <EditIcon
                onClick={() => EditHandler(true)}
                sx={{ cursor: "pointer" }}
              />
              <span></span>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectDirectoryRows;
