import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Divider,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { EditScenarioModal } from "./EditScenarioModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { ShareScenarioModal } from "./ShareScenarioModal";
import LaunchIcon from "@mui/icons-material/Launch";
import { AUTH } from "../../Redux/Services/auth.service";
import SyncIcon from "@mui/icons-material/Sync";

export interface IScenarioMenuItemsProps {
  TableData: any;
  getTableData?: any;
  itemId?: any;
  userProfile?: any;
  downloadLoader?: any;
  setDownloadLoader?: any;
  getActiveVaultScenarios?: any;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ScenarioMenuItems(props: IScenarioMenuItemsProps) {
  const { TableData, getTableData, setDownloadLoader, downloadLoader, getActiveVaultScenarios } = props;
  const { projectId, projectName, topVault, vault, scenarioId, treeabbr } =
    useRouteParams<any>();
  const [copyLoading, setCopyLoading] = React.useState(false);
  const [selectedIdForCopy, setSelectedIdForCopy] = React.useState<any>(null);
  const [hoveredData, setHoveredData] = React.useState<any>({});
  const [costStatus, setCostStatus] = React.useState(false);
  const userProfile = useSelector(userSelectors.selectAll);
  const editScenarioModal = useBaseParamsModal();
  const shareScenario = useBaseParamsModal();

  // const menuItem = TableData?.filter((item: any) => item.id === itemId);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  // Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowData: any
  ) => {
    setHoveredData(rowData);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Download
  const [anchorDownEl, setAnchorDownEl] = React.useState<null | HTMLElement>(
    null
  );
  const openDownload = Boolean(anchorDownEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDownEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorDownEl(null);
  };

  const detailSceanrioDownload = (module: any, cellValues: any) => {
    if (module === "w") {
      // setIconText("firstdownload");
      // setContentText(
      // "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      // );
      // openStatusContent.open();
      API.get(
        "/customer/consolidated_report/",
        {
          vault: cellValues?.vault,
          with_image: "true",
          consolidate_type: "Costing",
          scenario: cellValues?.id,
        },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => { });
    } else if (module === "w/o") {
      //   setIconText("firstdownload");
      //   setContentText(
      //    "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      //    );
      //  openStatusContent.open();
      API.get(
        "/customer/consolidated_report/",
        {
          vault: cellValues?.vault,
          with_image: "false",
          consolidate_type: "Costing",
          scenario: cellValues?.id,
        },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => { });
    }
  };

  const detailSceanrioDirectDownload = (cellValues: any) => {
    // setIconText("firstdownload");
    //   setContentText(
    //    "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    //    );
    //  openStatusContent.open();
    API.get(
      "customer/individual_report/",
      {
        vault: cellValues?.vault,
        individual_type: "Costing",
        scenario: cellValues?.id,
      },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch((err: any) => { });
  };

  const individualScenarioDownload = (scenId: any) => {
    setDownloadLoader(true);
    API.get(
      "customer/individual_report/",
      {
        vault: vault,
        individual_type: "Costing",
        scenario: scenId,
      },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch((err: any) => { })
      .finally(() => {
        setDownloadLoader(false);
      });
  };

  return (
    <>
      {editScenarioModal.isOpen && (
        <EditScenarioModal
          isOpen={editScenarioModal.isOpen}
          onCloseModal={editScenarioModal.close}
          exsistingData={editScenarioModal.propsId}
          getTableData={getTableData}
        // getCompareGraphData={getCompareGraphData}
        // getTotalCompareGraphData={getTotalCompareGraphData}
        />
      )}
      {shareScenario.isOpen && (
        <ShareScenarioModal
          isOpen={shareScenario.isOpen}
          onCloseModal={shareScenario.close}
          data={shareScenario.propsId}
          getTableData={getTableData}
        />
      )}
      <Box
        style={{
          display: "flex",

          // alignItems: "center",
          // justifyContent: "center",
          columnGap: "0.5rem",
          padding: "1rem",
          flexDirection: "column",
        }}
      >
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            sx={{
              // p: 1,
              border: "1px solid #007fff",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                width: "100%",
                backgroundColor: "primary.light",
                // color: "white",
              }}
            >
              Scenario Info
            </Typography>
            <Divider />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Assumption Card/s
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {" "}
                    : {hoveredData.config}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Scenario Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {" "}
                    : {hoveredData?.with_link ? "Linked" : "Delinked"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Configuration
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {" "}
                    : {hoveredData?.scenario_configuration}
                  </TableCell>
                </TableRow>
                {Object.keys(hoveredData)?.length > 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Created By
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {" "}
                      : {hoveredData.created_by_name}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Created Date
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {" "}
                    : {hoveredData?.created_at}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Popover>
        {TableData?.scenario_creation !== "Main" &&
          (TableData?.created_by === AUTH.user || AUTH.isSuperUser) && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => {
                API.get(
                  "/customer/update_costing/",
                  {
                    top_vault: vault == 0 ? topVault : undefined,
                    scenario: TableData?.id,
                    system: vault != 0 ? vault : undefined,
                  },
                  0
                )
                  .then((res: any) => {

                  })
                  .catch((err: any) => { });
                Swal.fire({
                  title: "Success",
                  text: "Scenario sync has started and will run in the background. You will be notified once it is complete.",
                  icon: "success",
                  confirmButtonColor: "#007FFF",
                });
              }}
            >
              <SyncIcon sx={{ cursor: "pointer" }} />
              <Typography sx={{ textDecoration: "underline" }}>
                Sync Scenario
              </Typography>
            </Box>
          )}
        {TableData?.scenario_creation !== "Main" && (
          <Box
            sx={{
              cursor: "pointer",
              lineHeight: 1,
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginBottom: "0.75rem",
            }}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={(event: any) => {
              if (TableData?.action?.includes("R")) {
                handlePopoverOpen(event, TableData);
              }
            }}
            onMouseLeave={handlePopoverClose}
          >
            <InfoIcon />
            <Typography>Scenario Info</Typography>
          </Box>
        )}

        {TableData?.lock === false && TableData?.scenario_creation !== "Main" &&
          TableData?.action?.includes("U") &&
          TableData?.scenario_creation !== "Dependent" && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => {
                editScenarioModal.open(TableData);
              }}
            >
              <EditIcon sx={{ cursor: "pointer" }} />
              <Typography>Edit Scenario</Typography>
            </Box>
          )}

        {TableData?.scenario_creation !== "Main" &&
          TableData?.action?.includes("U") &&
          TableData?.scenario_creation === "Dependent" && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => {
                // /costingscenario/:projectId/:projectName/:topVault/:vault/:scenarioType/:treeabbr/:scenarioId
                if (TableData?.scenario_parent_abbreviation === "product") {
                  window.open(
                    `/#/costingscenario/${projectId}/${projectName}/${TableData?.scenario_main_parent
                    }/${0}/1/${"PRODUCT"}/${scenarioId}`
                  );
                } else {
                  window.open(
                    `/#/costingscenario/${projectId}/${projectName}/${topVault}/${TableData?.scenario_main_parent}/1/${TableData?.scenario_parent_abbreviation}/${scenarioId}`
                  );
                }
              }}
            >
              <LaunchIcon sx={{ cursor: "pointer" }} />
              <Typography sx={{ textDecoration: "underline" }}>
                Parent Scenario- {TableData?.scenario_parent_part_no}
              </Typography>
            </Box>
          )}



        {TableData?.lock === false && TableData?.scenario_creation !== "Main" &&
          TableData?.action?.includes("D") &&
          TableData?.scenario_creation !== "Dependent" && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You want to delete this scenario",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#007FFF",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    API.delete(`/cost/scenario/${TableData?.id}/`, {}, 0)
                      .then((res: any) => {
                        getTableData();
                        getActiveVaultScenarios();
                        // getCompareGraphData();
                        // getTotalCompareGraphData();
                        Swal.fire(
                          "Deleted!",
                          "Scenario has been deleted.",
                          "success"
                        );
                      })
                      .catch((err: any) => { });
                  }
                });
              }}
            >
              <DeleteIcon sx={{ cursor: "pointer" }} />
              <Typography>Delete Scenario</Typography>
            </Box>
          )}

        {TableData?.scenario_creation !== "Main" &&
          TableData?.action?.includes("C") && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => {
                setSelectedIdForCopy(TableData?.id);
                setCopyLoading(true);
                API.get(
                  "/cost/scenario_copy/",
                  {
                    scenario: TableData?.id,
                    ...getObj(),
                  },
                  0
                )
                  .then((res: any) => {
                    getTableData();
                    setCopyLoading(false);
                    // getCompareGraphData();
                    // getTotalCompareGraphData();
                    Swal.fire({
                      title: "Success",
                      text: "Scenario has been copied successfully",
                      icon: "success",
                      confirmButtonColor: "#007FFF",
                    });
                  })
                  .catch((err: any) => {
                    setCopyLoading(false);
                  });
              }}
            >
              <LoadingButton
                sx={{ minWidth: "0.5rem", padding: "0" }}
                loading={
                  selectedIdForCopy === TableData?.id ? copyLoading : false
                }
              >
                <FileCopyIcon sx={{ cursor: "pointer", fontSize: "1.4rem" }} />
              </LoadingButton>
              <Typography>Copy Scenario</Typography>
            </Box>
          )}

        {/* {cellValues.row.cost !== "Inprogress" &&
          cellValues.row.action?.includes("R") && (
            <img
              title={
                !getDisableStatus()
                  ? ""
                  : "Validate main costing to enable this scenario"
              }
              src={extlink}
              alt="extlink"
              onClick={() => {
                if (!getDisableStatus()) {
                  getRedirection(cellValues?.row);
                }
              }}
              style={{
                height: "1.4rem",
                // margin: "0 0.2rem",
                cursor: "pointer",
              }}
            />
          )} */}

        {costStatus == true && TableData?.is_leaf == true ? (
          <>
            {TableData?.action?.includes("R") && (
              <Box
                sx={{
                  cursor: "pointer",
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "0.75rem",
                }}
                onClick={() => detailSceanrioDirectDownload(TableData)}
              >
                <DownloadIcon
                  sx={{ cursor: "pointer" }}
                  titleAccess="Download"
                />
                <Typography>Download Report</Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {TableData?.action?.includes("R") && costStatus == true && (
              <Box
                sx={{
                  cursor: "pointer",
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "0.75rem",
                }}
                onClick={handleClick}
                id="demo-customized-button"
                aria-controls={
                  openDownload ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openDownload ? "true" : undefined}
              // disableElevation
              >
                <Button
                  style={{ padding: "0rem", minWidth: "1rem" }}
                  title="Download"
                >
                  <DownloadIcon
                    sx={{ cursor: "pointer" }}
                    titleAccess="Download"
                  />
                </Button>
                <Typography>Download Scenario Report</Typography>
              </Box>
            )}
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorDownEl}
              open={openDownload}
              onClose={handleClose}
              sx={{
                borderRadius: "10px",
                border: "1px solid",
                borderColor: "primary.light",
              }}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => detailSceanrioDownload("w", TableData)}
              >
                Consolidated with images
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => detailSceanrioDownload("w/o", TableData)}
              >
                Consolidated w/o images
              </MenuItem>
            </StyledMenu>
          </>
        )}

        {TableData?.scenario_creation !== "Main" &&
          TableData?.scenario_creation !== "Dependent" &&
          userProfile &&
          (userProfile[0]?.id === TableData?.created_by ||
            userProfile[0].is_superuser) &&
          TableData.action?.includes("U") && (
            <Box
              sx={{
                cursor: "pointer",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "0.75rem",
              }}
              onClick={() => shareScenario.open(TableData)}
            >
              <ShareIcon sx={{ cursor: "pointer" }} />
              <Typography>share Scenario</Typography>
            </Box>
          )}

        {TableData?.scenario_creation !== "Main" &&
          TableData?.action?.includes("R") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                cursor: downloadLoader ? "default" : "pointer",
              }}
              onClick={() => {
                if (!downloadLoader) {
                  individualScenarioDownload(TableData?.id);
                }
              }}
            >
              <DownloadIcon sx={{ cursor: "pointer" }} titleAccess="Download" />
              <Typography>Individual Scenario Download</Typography>

              {downloadLoader && <CircularProgress size="15px" />}
            </Box>
          )}

        {/* {cellValues.row.scenario_creation !== "Main" &&
                userProfile &&
                (userProfile[0]?.id === cellValues.row?.created_by ||
                  userProfile[0].is_superuser) &&
                cellValues.row.action?.includes("U") &&
                (cellValues.row.lock ? (
                  <LockIcon
                    sx={{ cursor: "pointer", color: "green" }}
                    onClick={() => {
                      API.put(
                        `/cost/scenario/${cellValues.row.id}/`,
                        {
                          lock: false,
                        },
                        {},
                        0
                      )
                        .then((res: any) => {
                          Swal.fire({
                            title: "Success",
                            text: "Scenario has been unlocked successfully",
                            icon: "success",
                            confirmButtonColor: "#007FFF",
                          });
                          getTableData();
                        })
                        .catch((err: any) => {
                          const { data } = err.response;
                          Swal.fire({
                            title: "Error",
                            text: data[0],
                            icon: "error",
                            confirmButtonColor: "#007FFF",
                          });
                        });
                    }}
                  />
                ) : (
                  <LockOpenIcon
                    sx={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      API.put(
                        `/cost/scenario/${cellValues.row.id}/`,
                        {
                          lock: true,
                        },
                        {},
                        0
                      )
                        .then((res: any) => {
                          Swal.fire({
                            title: "Success",
                            text: "Scenario has been locked successfully",
                            icon: "success",
                            confirmButtonColor: "#007FFF",
                          });
                          getTableData();
                        })
                        .catch((err: any) => {
                          const { data } = err.response;
                          Swal.fire({
                            title: "Error",
                            text: data[0],
                            icon: "error",
                            confirmButtonColor: "#007FFF",
                          });
                        });
                    }}
                  />
                ))} */}
      </Box>
    </>
  );
}
