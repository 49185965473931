


import React, { useEffect, useState } from 'react'
import { API } from '../../../api-services';
import { Box, Checkbox, Skeleton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';

function CurrencyDB({ database, selected, setSelected}:any) {

    const [countryLoading, setCountryLoading] = useState<boolean>(true);
      const [countryList, setCountryList] = useState<any>([]);


  const { height, width } = useWindowDimensions();
  const [categoryList, setCategoryList] = React.useState<any>([]);
    const [tabValue, setTabValue] = useState<any>(null);
     const [tableData, setTableData] = React.useState<any>([]);
    const [accordionLoading, setAccordioinLoading] = React.useState<any>(false);
    const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);

    const isUrl = (url: any) => {
      try {
        new URL(url);
        return true;
      } catch (error) {
        return false;
      }
    };

  const fetchObj: any = {
    1: "material",
    2: "machine",
    6: "purchaseitem",
    3: "labour",
    4: "tools",
    7: "operationdb",
    8: "currency",
  };

  const getCategoryList = (database: any) => {
    setAccordioinLoading(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: fetchObj?.[database],
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setTabValue(res?.data[0]?.id);
        setAccordioinLoading(false);
      })
      .catch((err: any) => {
        setAccordioinLoading(false);
      });
  };

  useEffect(() => {
   
      setCountryLoading(true);
      API.get("customer/currency_search/", { all_currencies: true }, 0)
        .then((res: any) => {
          setCountryList(res.data);
          setCountryLoading(false);
        })
        .catch((err: any) => {
          setCountryLoading(false);
        });

  }, []);



  let columns: any = [
    {
         field: "actions",
         headerName: "Select",
         width: width > 100,
         renderCell: (cellValues: any) => {
           return (
             <Box
               sx={{
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "flex-end",
                 columnGap: "1rem",
               }}
             >
               <Checkbox
                 checked={selected?.id == cellValues?.row?.id}
                 onChange={() => setSelected(cellValues?.row)}
               />
             </Box>
           );
         },
       },
    {
      field: "flag",
      headerName: "Flag",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 170 : 150,
      flex: 1,
      disableExport: true,
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
          <img style={{ height: '1.7rem', width: '3rem' }} src={`data:image/png;base64, ${cellValues?.row?.flag}`} />
        </Box>)
      }
    },
    { field: "currencyName", headerName: "Currency Name", width: width > 1370 ? 540 : 420, },
    {
      field: "currencyCode",
      headerName: "Currency Symbol",
      width: width > 1370 ? 270 : 250,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
          <span>{cellValues?.row?.currencyCode} </span>{`${cellValues?.row?.currencySymbol != null && cellValues?.row?.currencySymbol != "_" ? `( ${cellValues?.row?.currencySymbol} )` : ""}`}
        </Box>)
      }
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = countryList && countryList?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.currency_id,
      sno: index + 1,
      flag: ideaItem?.flag,
      currencyName: ideaItem?.currency_name,
      currencyCode: ideaItem?.currency_code,
      currencySymbol: ideaItem?.symbol,
    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  return (
    <Box sx={{ display: "flex", gap: "2%" }}>
   

    <Box
      sx={{
        flex: 1,
        height: { lg: "84vh", xl: "85vh" },
        width: "100%",
        "& .super-app-theme--cell": {
          backgroundColor: "#ddefff !important",
          textAlign: "center !important",
        },
      }}
    >
      <DataGrid
        headerHeight={42}
        rowHeight={36}
        {...dataStructureDataGrid}
        loading={tableDataLoading}
        // components={{
        //   Toolbar: () => {
        //     return CustomToolbar();
        //   },
        // }}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </Box>
  </Box>
  )
}

export default CurrencyDB