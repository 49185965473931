import styles from "./Header.module.scss";
import * as React from "react";
import {
  Grid,
  FormControl,
  IconButton,
  Box,
  Badge,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Popover,
  Switch,
  FormControlLabel,
  CardMedia,
  Button,
} from "@mui/material";
import logo from "../../Assets/images/svgs/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { AUTH } from "../../Redux/Services/auth.service";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import { API, apiBase } from "../../api-services";
import Modal from "@mui/material/Modal";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import ChatIcon from "@mui/icons-material/Chat";
import BadgeIcon from "@mui/icons-material/Badge";
import StorageIcon from "@mui/icons-material/Storage";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyIcon from "@mui/icons-material/Key";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useEffect, useState } from "react";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useQueryDispatch } from "../../CustomHook/useQueryDispatch";
import { getUserProfilePic } from "../../Redux/Actions/user.action";
import useFetch from "../../Core/CustomHooks/useFetch";
import { USER } from "../../Redux/Services/user.service";
import GuestUserModal from "./GuestUserModal";
import HandoverModal from "../Handover/HandoverModal";
import LoginInfo from "./LoginInfo";
import landingPageService from "../../Redux/Services/landingPage.service";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailIcon from "@mui/icons-material/Mail";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useRef } from "react";
import HistoryIcon from "@mui/icons-material/History";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { debounce } from "lodash";
import menu from "../../Assets/images/menu.png";
import menuwhite from "../../Assets/images/menu-white.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ScanQRModal from "./ScanQRModal";
import QRRedirectionModal from "./QRRedirectionModal";
import DescriptionIcon from "@mui/icons-material/Description";
import UserAlertsModal from "./UserAlertsModal";
import { encode } from "../../utlis/encoder";
import noNoti from "../../Assets/images/no_notification.png";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import useWebSocket from "react-use-websocket";
import MoMIcon from "../../Assets/images/MoMIcon.svg";

// @ts-ignore
import io from "socket.io-client";
// @ts-ignore

import { toast } from "react-toastify";
import "./Header.scss";

import qrscan from "../../Assets/images/QRscan.png";
import qrscanwhite from "../../Assets/images/QRscanwhite.png";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import database from "../../Assets/images/database.svg";
import databasewhite from "../../Assets/images/database_white.svg";
import avaimg from "../../Assets/images/maleavatar.png";
import goto from "../../Assets/images/rightArrows.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import CompareIcon from "@mui/icons-material/Compare";
import sessionExpired from "../../Core/sessionExpired";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import QuickCompareLanding from "../QuickCompare/QuickCompareLanding";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import ChatComponent from "../../utlis/ChatComponent";
import ChatWithAI from "../../utlis/ChatWithAI";
import BOMDataEntryQRModal from "./BOMDataEntryQRModal";
// import AIChatIcon from "../../utlis/AIChatIcon";
import AIChatIcon from "../../Assets/images/xcQ.png"

export interface IAppProps {
  defaultselectedValue?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minWidth: { lg: '55%', xl: '50%' },
  width: { xs: "20rem", sm: "auto" },
  height: "fit-content",
  maxHeight: "90%",
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export function Header(props: IAppProps) {
  const { defaultselectedValue } = props;
  const history = useHistory();
  const userProfile = useSelector(userSelectors.selectAll);
  const { pathname } = useLocation<any>();
  const [compareStatus, setCompareStatus] = useState<any>("false");

  //*******************************************************  *Wlcome Popup*  **************************************************** */

  const [recentActivity, setrecentActivity] = useState<any>({});
  // const [openAct, setActOpen] = React.useState(false);
  // const handleActOpen = () => setActOpen(true);
  // const handleActClose = () => { setActOpen(false) };
  const [activitypop, setactivitypop] = useState("");
  const [reloadUserData, setReloadUserData] = useState<boolean>(true);
  const store = useSelector((state) => state);
  //@ts-ignore

  useEffect(() => {
    //@ts-ignore
    setCompareStatus(store.is_compare?.is_compare);
    //@ts-ignore
  }, [store.is_compare?.is_compare]);
  //*******************************************************  *Wlcome Popup End*  **************************************************** */

  const [deleteGuest, setDeleteGuest] = useState<any>(0);
  const [count, setCount] = useState<any>(0);
  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [ActiveId, setActiveId] = useState<any>();
  const [fillfromId, setFillfromId] = useState<any>();
  const [PrefilledValue, setPrefilledValue] = useState<any>();
  const [tabId, setTabId] = useState<any>();
  // ******************************************************** set selected Product Ids through the sessionStorage******************************************************************************************************//
  const ProjectIdd: any = sessionStorage.getItem("ProjectId");
  const [hide, setHide] = useState<boolean>(false);
  const GuestUserModalOpen = useBaseModal();
  const HandoverModalOpen = useBaseModal();
  const currentUrlinstance = window.location.href;
  const ScannerModalOpen = useBaseModal();
  const QRRedirectionModalOpen = useBaseModal();
  const [alerts, setAlerts] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const { url } = useRouteMatch();
  const [routeStatus, setRouteStatus] = React.useState<any>("internallanding");
  React.useEffect(() => {
    if (url?.includes("internallanding")) {
      setRouteStatus("internallanding");
    } else if (url.includes("landing")) {
      setRouteStatus("landing");
    } else {
      setRouteStatus(undefined);
    }
  }, [url]);

  // console.log("routeStatusrouteStatus", routeStatus, url)
  const [anchorElpop, setAnchorElpop] = React.useState<
    HTMLButtonElement | null | any
  >(null);
  const [anchorElpopNotification, setAnchorElpopNotification] = React.useState<
    HTMLButtonElement | null | any
  >(null);
  const [ReadNotifications, setReadNotifactions] = useState<any>([]);
  const [UnReadNotifications, setUnReadNotifications] = useState<any>([]);
  const [alertStatus, setAlertStatus] = useState<any>(true);
  const [ReadStatus, setReadStatus] = useState<any>(false);
  const [UnReadStatus, setUnReadStatus] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [closeCount, setCloseCounts] = useState<any>(0);
  var [countuerData, setCounterData] = useState<any>(1);
  const openNotification = Boolean(anchorElpopNotification);
  const NotificationId = openNotification ? "simple-popover" : undefined;
  const inputRef = useRef<any>(null);
  const descRef = useRef<any>();
  const status = sessionStorage.getItem("is_compare");
  const OuterRef = useRef<any>();
  const [focusedIndex, setFocusedIndex] = useState<any>(-1);
  const [SearchValue, setSearchValue] = useState<any>([]);
  const [projectCount, setProjectCount] = React.useState<any>();
  const socketRef = useRef();
  const [quickCompare, setquickCompare] = useState<any>(false);
  const [liveNotifications, setLiveNotification] = useState<any>([]);
  const [calibrationAccess, setCalibrationAccess] = useState<any>();

  const [liveCount, setLiveCount] = useState<any>(0);
  const handleClickPopup = (event: any) => {
    setAnchorElpopNotification(event.currentTarget);
    setLoader(true);
  };
  const { height, width } = useWindowDimensions();
  const resItConatiner = useRef<any>(null);
  const [compareOptions, setCompareOptions] = useState<any>([]);

  const [DataBaseAccess, setDataBaseAccess] = useState<any>();

  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const openNotifaction = Boolean(anchorElNotification);

  const { 0: load, 1: userData } = useFetch({
    method: USER.getUsers,
    args: [AUTH.user],
    fallBackErrMsg: "Could not fetch the user Details",
    dependencies: [reloadUserData],
    condition: AUTH.user != null,
  });

  useEffect(() => {
    API.get("/auth/calibration_user_permission/", {}, 0)
      .then((res: any) => {
        setDataBaseAccess(res?.data?.database);
        setCalibrationAccess(res?.data?.calibration);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, []);

  // const getActivityData = () => {

  //   API.get("/customer/recent_activity", {

  //   }, 0).then((resActivity: any) => {

  //     setrecentActivity(resActivity?.data);
  //     setActOpen(true)

  //   }).catch((err: any) => {
  //     console.log("err", err)
  //   })

  // }
  // // setCloseCounts

  // useEffect(() => {
  //   const items = sessionStorage.getItem('items');
  //   if (items == "false") {
  //     getActivityData()
  //     sessionStorage.setItem('items', "true");
  //   }
  // }, [])

  const historyCard = () => {
    API.get("/customer/compare_cart/", {}, 0)
      .then((res: any) => {
        setCompareOptions(res.data);
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    historyCard();
  }, [closeCount]);

  var AvatarName = (item: any) => {
    const FirstLatter = item && item?.first_name?.charAt(0);
    const lastWord = item && item?.last_name?.charAt(0);
    return `${FirstLatter}${lastWord}`;
  };

  const moveToOldUi = () => {
    // API.get("/activity/pinneditems/",{flag:'oldui'},0)
    const obj = {
      user: AUTH.user,
      isSuperUser: AUTH.isSuperUser,
      username: AUTH.username,
      phone: AUTH.phone,
      email: AUTH.email,
      empId: AUTH.empId,
      token: AUTH.token,
      organisation: AUTH.organisation,
      organisationId: AUTH.organisationId,
      user_image: AUTH.user_image,
      user_category: AUTH.user_category,
      user_expiration: AUTH.user_expiration,
      designation: AUTH.designation,
    };
    const currentUrl = window.location.href;
    const getRedirectUrl = () => {
      let url = "";
      if (typeof currentUrl !== "undefined") {
        if (currentUrl.includes("tvsm.xcpep.com")) {
          url = `https://oui.tvsm.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("daimler.xcpep.com")) {
          url = `https://dam.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("app.xcpep.com")) {
          url = `https://oui.app.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("mnm.xcpep.com")) {
          url = `https://oui.mnm.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("hmie.xcpep.com")) {
          url = `https://oui.hmie.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("bal.xcpep.com")) {
          url = `https://ba.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("ae.xcpep.com")) {
          url = `https://oui.ae.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("olaelectric.xcpep.com")) {
          url = `https://ol.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("mlmm.xcpep.com")) {
          url = `https://oui.mlmm.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("subscription.xcpep.com")) {
          url = `https://oui.subscription.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("mck.xcpep.com/")) {
          url = `https://oui.mck.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("av.xcpep.com/")) {
          url = `https://oui.av.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("cnhi.xcpep.com/")) {
          url = `https://oui.cnhi.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("tm.xcpep.com/")) {
          url = `https://oui.tm.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("ucal.xcpep.com/")) {
          url = `https://oui.ucal.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("mgi.xcpep.com/")) {
          url = `https://oui.mgi.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("gcl.xcpep.com/")) {
          url = `https://oui.gcl.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("hrid.xcpep.com/")) {
          url = `https://oui.hrid.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("bsl.xcpep.com/")) {
          url = `https://oui.bsl.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("ax.xcpep.com/")) {
          url = `https://oui.ax.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("em.xcpep.com/")) {
          url = `https://oui.em.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("vl.xcpep.com/")) {
          url = `https://oui.vl.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("ag.xcpep.com/")) {
          url = `https://oui.ag.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("aa.xcpep.com/")) {
          url = `https://oui.aa.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        } else if (currentUrl.includes("zfr.xcpep.com/")) {
          url = `https://oui.zfr.xcpep.com/#/landing/{${encode(
            JSON.stringify(obj)
          )}`;
        }
      }
      return url;
    };
    // document.cookie = `auth=${JSON.stringify(obj)}; expires=Thu, 01-Jan-30000 00:00:01 GMT`;

    API.get(
      "/customer/vehicle_level_activity/",
      { switch_interface: true, old: false },
      0
    )
      .then((res: any) => {
        window
          .open
          // `https://qa2.xcpep.com/#/landing/{${encode(JSON.stringify(obj))}`
          // getRedirectUrl()
          ();
      })
      .catch((err: any) => { });
  };

  const Alertsmodal = useBaseModal();
  const ratingModal = useBaseModal();
  const handleClickNotifaction = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
  };

  const openPop = Boolean(anchorElpop);
  var querySearch = sessionStorage.getItem("SearchKey");
  var recentQuerSearch = sessionStorage.getItem("recentKey");
  var ProjectSearchKey: any = sessionStorage.getItem("projectKey");
  var FreezeStatus: any = sessionStorage.getItem("FreeseStatus");
  var ExactKey: any = sessionStorage.getItem("ExactSearchKey");

  //************** Switch Page Interal & Landing *************** */

  const switchpage = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked == true && history.push("/internallanding");
    event.target.checked == false && history.push("/landing");
  };

  useEffect(() => {
    url.includes("/internallanding") && setChecked(true);
    url.includes("/landing") && setChecked(false);
  }, [url]);

  //**************Switch Page End ********************* */

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/customer/exact_search/", { search: criteria }).then(
        (res: any) => {
          setSearchValue(res.data);
        }
      );
    }, 500)
  ).current;

  const getName = (
    ItemId: any,
    ValueName: any,
    Name: any,
    PartName: any,
    TopvaultName: any,
    topVoult: any,
    moduleName: any,
    original_model_ob: any
  ) => {
    if (original_model_ob === "ideastorage") {
      return ValueName + "(" + PartName + ")" + "|" + TopvaultName;
    } else {
      return moduleName == "topvault"
        ? TopvaultName
        : Name + "(" + PartName + ")" + "|" + TopvaultName;
    }
  };

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);

    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    }

    // @ts-ignore
    sessionStorage.removeItem("PageCount");

    if (event?.target?.value?.length > 0) {
      if (event.key == "Enter") {
        setCounterData((pre: any) => pre + 1);
        inputRef.current.style.display = "none";
        descRef.current.blur();
        // @ts-ignore
        sessionStorage.setItem("PageCount", 1);
        // @ts-ignore
        sessionStorage.setItem("Counter", countuerData);
        sessionStorage.removeItem("VoultId");
        // @ts-ignore
        sessionStorage.removeItem("ExactSearchKey");

        //@ts-ignore
        debouncedSearch(event.target.value);
        sessionStorage.removeItem("recentKey");

        if (FreezeStatus == "true") {
          // @ts-ignore
          descRef.current.blur();
          // descRef?.current.blur.();
          sessionStorage.removeItem("SearchKey");
          sessionStorage.removeItem("VoultId");
          // @ts-ignore
          sessionStorage.removeItem("ExactSearchKey");
          // @ts-ignore
          sessionStorage.setItem("PageCount", 1);
          sessionStorage.setItem("projectKey", event?.target?.value);
        } else if (FreezeStatus == "false") {
          sessionStorage.removeItem("projectKey");
          // @ts-ignore
          sessionStorage.setItem("PageCount", 1);
          sessionStorage.removeItem("VoultId");
          // @ts-ignore
          sessionStorage.removeItem("ExactSearchKey");
          descRef.current.blur();
          inputRef.current.style.display = "none";
          sessionStorage.setItem("SearchKey", event?.target?.value);
          sessionStorage.removeItem("ProjectIds");
        }
      }
    }
  };

  const GuestUserHandler = () => {
    var data = GuestUserModalOpen.open();
  };
  const HandoverUserHandler = () => {
    var data = HandoverModalOpen.open();
  };

  //******************************************************searchHandler******************************************************************//
  const searchHandler = () => {
    if (selectedValue !== null) {
      // debouncedSearch(selectedValue);

      setCounterData((pre: any) => pre + 1);
      inputRef.current.style.display = "none";
      descRef.current.blur();
      // @ts-ignore
      sessionStorage.setItem("PageCount", 1);
      // @ts-ignore
      sessionStorage.setItem("Counter", countuerData);
      sessionStorage.removeItem("VoultId");
      // @ts-ignore
      sessionStorage.removeItem("ExactSearchKey");

      //@ts-ignore
      debouncedSearch(selectedValue);
      sessionStorage.removeItem("recentKey");

      if (FreezeStatus == "true") {
        // @ts-ignore
        descRef.current.blur();
        // descRef?.current.blur.();
        sessionStorage.removeItem("SearchKey");
        sessionStorage.removeItem("VoultId");
        // @ts-ignore
        sessionStorage.removeItem("ExactSearchKey");
        // @ts-ignore
        sessionStorage.setItem("PageCount", 1);
        sessionStorage.setItem("projectKey", selectedValue);
      } else if (FreezeStatus == "false") {
        sessionStorage.removeItem("projectKey");
        // @ts-ignore
        sessionStorage.setItem("PageCount", 1);
        sessionStorage.removeItem("VoultId");
        // @ts-ignore
        sessionStorage.removeItem("ExactSearchKey");
        descRef.current.blur();
        inputRef.current.style.display = "none";
        sessionStorage.setItem("SearchKey", selectedValue);
        sessionStorage.removeItem("ProjectIds");
      }

      // history.push(`/search`);
      // sessionStorage.removeItem("recentKey");
    }
  };
  const searchHandlerIdeaClick = (
    ItemId: any,
    ValueName: any,
    Name: any,
    PartName: any,
    TopvaultName: any,
    topVoult: any,
    moduleName: any,
    original_model_ob: any
  ) => {
    if (ValueName !== null) {
      setSelectedValue(
        getName(
          ItemId,
          ValueName,
          Name,
          PartName,
          TopvaultName,
          topVoult,
          moduleName,
          original_model_ob
        )
      );
      // debouncedSearch(selectedValue);

      setCounterData((pre: any) => pre + 1);
      inputRef.current.style.display = "none";
      descRef.current.blur();
      // @ts-ignore
      sessionStorage.setItem("PageCount", 1);
      // @ts-ignore
      sessionStorage.setItem("Counter", countuerData);
      sessionStorage.removeItem("VoultId");
      // @ts-ignore
      sessionStorage.removeItem("ExactSearchKey");

      //@ts-ignore
      debouncedSearch(ValueName);
      sessionStorage.removeItem("recentKey");

      if (FreezeStatus == "true") {
        // @ts-ignore
        descRef.current.blur();
        // descRef?.current.blur.();
        sessionStorage.removeItem("SearchKey");
        sessionStorage.removeItem("VoultId");
        // @ts-ignore
        sessionStorage.removeItem("ExactSearchKey");
        // @ts-ignore
        sessionStorage.setItem("PageCount", 1);
        sessionStorage.setItem("projectKey", ValueName);
      } else if (FreezeStatus == "false") {
        sessionStorage.removeItem("projectKey");
        // @ts-ignore
        sessionStorage.setItem("PageCount", 1);
        sessionStorage.removeItem("VoultId");
        // @ts-ignore
        sessionStorage.removeItem("ExactSearchKey");
        descRef.current.blur();
        inputRef.current.style.display = "none";
        sessionStorage.setItem("SearchKey", ValueName);
        sessionStorage.removeItem("ProjectIds");
      }

      // history.push(`/search`);
      // sessionStorage.removeItem("recentKey");
    }
  };

  // console.log(inputRef?.current, "inputRef");
  const handleChangeSelection = (
    ItemId: any,
    ValueName: any,
    Name: any,
    PartName: any,
    TopvaultName: any,
    topVoult: any,
    moduleName: any,
    original_model_ob: any
  ) => {
    var WholeData = getName(
      ItemId,
      ValueName,
      Name,
      PartName,
      TopvaultName,
      topVoult,
      moduleName,
      original_model_ob
    );
    //  moduleName == "topvault" ? TopvaultName : Name + "(" + PartName + ")" + "|" + TopvaultName;
    var conditionWise = moduleName == "topvault" ? topVoult : ItemId;
    sessionStorage.setItem("VoultId", conditionWise);
    sessionStorage.setItem("moduletype", moduleName);
    // @ts-ignore
    sessionStorage.setItem("ExactSearchKey", WholeData);
    // @ts-expect-error
    sessionStorage.setItem("FreeseStatus", false);
    setCounterData((pre: any) => pre + 1);
    history.push(`/search`);
    //  @ts-ignore

    sessionStorage.setItem("Counter", countuerData);
    sessionStorage.removeItem("SearchKey");
    sessionStorage.removeItem("recentKey");
    sessionStorage.removeItem("projectKey");
    sessionStorage.removeItem("ProjectIds");
    setSelectedValue(WholeData);
    inputRef.current.style.display = "none";
  };

  useEffect(() => {
    if (querySearch == null && ProjectSearchKey == null && ExactKey == null) {
      setSelectedValue(recentQuerSearch);
    } else if (
      recentQuerSearch == null &&
      ProjectSearchKey == null &&
      ExactKey == null
    ) {
      setSelectedValue(querySearch);
    } else if (
      recentQuerSearch == null &&
      querySearch == null &&
      ExactKey == null
    ) {
      setSelectedValue(ProjectSearchKey);
    } else if (
      recentQuerSearch == null &&
      querySearch == null &&
      ProjectSearchKey == null &&
      ExactKey == null
    ) {
      setSelectedValue("");
    }
    if (
      recentQuerSearch == null &&
      querySearch == null &&
      ProjectSearchKey == null &&
      ExactKey == null
    ) {
      setSelectedValue("");
    } else if (
      recentQuerSearch == null &&
      querySearch == null &&
      ProjectSearchKey == null
    ) {
      setSelectedValue(ExactKey);
    }
  }, [querySearch, recentQuerSearch, ProjectSearchKey, FreezeStatus, ExactKey]);

  // console.log(recentQuerSearch,"recentQuerSearch")
  // **************************************************** search function*****************************
  const debouncedSearch = () => {
    history.push(`/search`);
    // }
  };

  const dispatch = useDispatch();
  const Logout = () => {
    swal({
      title: "Logout",
      text: "Are you sure?",
      buttons: ["Cancel", "Logout"],
      //@ts-ignore
      confirmButtonColor: "primary.main",
    }).then((logout: any) => {
      sessionStorage.removeItem("items");
      if (logout) AUTH.logout(() => dispatch({ type: "RESET_ALL" }));
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useQueryDispatch({
    query: {
      action: getUserProfilePic,
      arg: {
        id: AUTH.user,
      },
    },
  });
  const { 0: fetched, 1: PrefilledData } = useFetch<any>({
    method: USER.guestUser,
    args: [AUTH.user],
    dependencies: [count, deleteGuest],
  });
  // React.useEffect(() => {
  //   // setActOpen(false)
  //   let timeout = setTimeout(() => {
  //     setActOpen(true)
  //   }, 2500)
  //   return () => { clearTimeout(timeout) }
  // }, [])
  const FetchNotificationData = () => {
    API.get("/activity/alerts/", {}, 0)
      .then((res: any) => {
        setAlerts(res.data);
        setLoader(false);
      })
      .catch(() => {
        console.log("error");
      });
  };
  const NotificationHandler = (Id: any) => {
    setActiveId(Id);
    API.put(`/activity/alerts/${Id}/`, { is_read: true }, 0)
      .then((res: any) => {
        FetchNotificationData();
      })
      .catch(() => {
        console.log("error");
      });
  };
  // console.log("jbfkjdbfjkbdkjfb", url)
  const FilterReadNotificationHandler = (Id: any) => {
    setActiveId(Id);

    API.put(`/activity/alerts/${Id}/`, { is_read: false }, 0)
      .then((res: any) => {
        API.get("/activity/alerts/", {}, 0)
          .then((res: any) => {
            // setAlerts(res.data);
            setAlerts(res.data);
            var DataReadNotification =
              res.data &&
              res.data.filter((item: any, index: any) => item?.is_read == true);

            var DataUnReadNotification =
              res.data &&
              res.data.filter(
                (item: any, index: any) => item?.is_read == false
              );
            setUnReadNotifications(DataUnReadNotification);

            setReadNotifactions(DataReadNotification);
            setAlertStatus(false);
            setUnReadStatus(false);
            setReadStatus(true);
            setAnchorElNotification(null);

            setLoader(false);
          })
          .catch((err: any) => {
            console.log("error");
          });
      })
      .catch((err: any) => {
        console.log("error");
      });
  };

  const FilterUnReadNotificationHandler = (Id: any) => {
    setActiveId(Id);
    API.put(`/activity/alerts/${Id}/`, { is_read: true }, 0)
      .then((res: any) => {
        API.get("/activity/alerts/", {}, 0)
          .then((res: any) => {
            setAlerts(res.data);
            var DataUnReadNotification =
              res.data &&
              res.data.filter(
                (item: any, index: any) => item?.is_read == false
              );
            var DataReadNotification =
              res.data &&
              res.data.filter((item: any, index: any) => item?.is_read == true);
            setReadNotifactions(DataReadNotification);
            setUnReadNotifications(DataUnReadNotification);

            setAlertStatus(false);

            setUnReadStatus(true);
            setReadStatus(false);
            setAnchorElNotification(null);
          })
          .catch((err: any) => {
            console.log("error");
          });
      })
      .catch((err: any) => {
        console.log("error");
      });
  };

  const UnreadHandler = (Id: any) => {
    setActiveId(Id);
    API.put(`/activity/alerts/${Id}/`, { is_read: false }, 0)
      .then((res: any) => {
        FetchNotificationData();
      })
      .catch(() => {
        console.log("error");
      });
  };
  useEffect(() => {
    FetchNotificationData();
  }, []);

  var notificationStatus =
    alerts && alerts?.filter((item: any) => item?.is_read == false);

  // @ts-ignore
  const handleCancel = (id: any, username: any) => {
    API.post("/auth/user_request/", { id: id, check_action: true }).then(
      (res: any) => {
        if (res?.data?.action == false) {
          Alertsmodal.open();
        } else {
          swal({
            text: `Are you sure you want to reject the new sign up request from ${username} ?`,
            buttons: ["Cancel", "Ok"],
            icon: "warning",
          }).then((confirm: any) => {
            if (confirm) {
              landingPageService
                .userData(id, false)
                .then((res) => {
                  if (res.status === 200) {
                    // ADMIN.toast.success("User Rejected");
                  }
                })
                .catch((e) => {
                  // ADMIN.toast.throwError(e, "Server Error");
                });
              const filterData = alerts.filter((item: any) => item.id !== id);
              setAlerts(filterData);
            }
          });
        }
      }
    );
  };

  const RemovedFilterNotifaction = () => {
    setAlerts(alerts);
    setAlertStatus(true);
    FetchNotificationData();
    setUnReadStatus(false);
    setReadStatus(false);
    setReadNotifactions([]);
    setUnReadNotifications([]);
    setAnchorElNotification(null);
  };
  const handleCloseNotication = () => {
    setAnchorElNotification(null);
    // RemovedFilterNotifaction()
  };

  const handleClosePopup = () => {
    setAnchorElpopNotification(null);
    RemovedFilterNotifaction();
  };

  const FilterNotificationUnread: any = () => {
    var DataUnReadNotification =
      alerts && alerts.filter((item: any) => item?.is_read == false);
    setUnReadNotifications(DataUnReadNotification);
    setAlertStatus(false);

    setUnReadStatus(true);
    setReadStatus(false);
    setAnchorElNotification(null);
  };

  const FilterNoticationRead = () => {
    var DataUnReadNotification =
      alerts && alerts.filter((item: any) => item?.is_read == true);
    setReadNotifactions(DataUnReadNotification);
    setAlertStatus(false);
    setUnReadStatus(false);
    setReadStatus(true);
    setAnchorElNotification(null);
  };

  const deleteNotification = (Id: any) => {
    // setActiveId(Id);
    API.delete(`/activity/update_alert/${Id}/`)
      .then(() => {
        FetchNotificationData();
      })
      .catch(() => {
        console.log("error");
      });
  };

  const deleteAllNotification = () => {
    swal({
      title: "Clear Notifications",
      text: "Are you sure?",
      buttons: ["Cancel", "Clear"],
      //@ts-ignore
      confirmButtonColor: "primary.main",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete("/activity/update_alert/")
          .then(() => {
            FetchNotificationData();

            setReadNotifactions([]);
            setUnReadNotifications([]);
          })
          .catch(() => {
            console.log("error");
          });
      }
    });
  };
  const driveRedirect = (group: any, project: any, Id: any) => {
    // history.push(`/drive/${project}/${group}`);
    UnreadHandler(Id);
  };
  const momRedirect = (url: string, Id: any) => {
    // history.push(url);
    UnreadHandler(Id);
  };
  const handleAccepte = (
    action: any,
    module: any,
    product: any,
    sub_module: any,
    id: any,
    userName: any
  ) => {
    swal({
      title: "Permission Access",
      text: "Are sure you want to accept request ?",
      buttons: ["Cancel", "Ok"],
      // icon: "sucess",
    }).then((confirm: any) => {
      if (confirm) {
        landingPageService
          .PermissionAccepted(action, module, product, sub_module, id)
          .then((res) => {
            if (res.status === 200) {
              FetchNotificationData();
              // ADMIN.toast.success("User Rejected");
            }
          })
          .catch((e) => {
            // ADMIN.toast.throwError(e, "Server Error");
          });
        const filterData = alerts.filter((item: any) => item.id !== id);
        setAlerts(filterData);
      }
    });
  };
  const handleReject = (
    action: any,
    module: any,
    product: any,
    sub_module: any,
    id: any,
    username: any
  ) => {
    swal({
      // Are you sure you want to reject the new sign up request from "user name"?
      text: "Are sure you want to Reject Request?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        landingPageService
          .PermissionDelete(action, module, product, sub_module, id)
          .then((res) => {
            if (res.status === 200) {
              FetchNotificationData();
              // ADMIN.toast.success("User Rejected");
            }
          })
          .catch((e) => {
            // ADMIN.toast.throwError(e, "Server Error");
          });
        const filterData = alerts.filter((item: any) => item.id !== id);
        setAlerts(filterData);
      }
    });
  };

  const feedbackButtonClick = () => {
    sessionStorage.setItem("historyurl", pathname);
    history.replace("/feedback/0/create");
  };

  const FocusHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    inputRef.current.style.display = "block";
    var DataSplit = selectedValue?.split("(")?.shift();
    if (DataSplit?.length > 0) {
      ExactDebounce(DataSplit);
    }
  };

  const handleClickOutside = (event: any) => {
    // if (!isUndefined(projectCount) && projectCount > 0)

    {
      if (OuterRef?.current && !OuterRef.current.contains(event?.target)) {
        inputRef.current.style.display = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const BOMDataEntryQRHandler = useBaseModal()
  const handleQRCodeClick = () => {
    if (AUTH.user_category == "Internal") {
      if (width < 768) {
        BOMDataEntryQRHandler.open()
      } else {
        QRRedirectionModalOpen.open();
      }
    } else {
      ScannerModalOpen.open();
    }
  };

  const handleCloseBOM = () => {
    BOMDataEntryQRHandler.close();
  }

  const OpenScannerModal = () => {
    ScannerModalOpen.open();
  };

  const OpenRedirectionModal = () => {
    QRRedirectionModalOpen.open();
  };

  const MyreportsHandler = () => {
    history.push(`/reports/0/my/0`);
  };

  const MinutesofMeeting = () => {
    history.push(`/MinutesofMeeting/0/0/0`);
  };

  const getApicallhandler = () => {
    FetchNotificationData();
    //  const filterData = alerts.filter((item: any) => item.id !== id);
    //  setAlerts(filterData);
    Alertsmodal.close();
  };

  useEffect(() => {
    API.get("/customer/dashboard/", { get_project_count: true }, 0).then(
      (res: any) => {
        // setIsLoadingCount(false)
        setProjectCount(res.data?.["project_count"]);
      }
    );
  }, []);

  const CustomliveRedirction = (data: any, item: any, Id: any) => {
    API.put(`/activity/alerts/${Id}/`, { is_read: true }, 0).then(
      (res: any) => {
        API.get("/activity/alerts/", {}, 0).then((res: any) => {
          setAlerts(res.data);
        });
      }
    );
    if (data?.ansector?.length !== 0) {
      sessionStorage.setItem("BOMCreateNode", JSON.stringify(data?.ansector));
    }

    window.open(item, "_blank");
  };
  useEffect(() => {
    // @ts-ignore

    const timer = setInterval(
      () => {
        API.get("/activity/notification/", {}, 0).then((res: any) => {
          res?.data?.map((item: any, index: any) => {
            return (
              <>
                {item?.is_notification == true &&
                  toast(
                    // @ts-ignore
                    <div>
                      <div
                        onClick={() => {
                          CustomliveRedirction(item, item?.url, item?.id);
                        }}
                        style={{
                          display: "flex",
                          fontSize: " 0.9rem",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ marginBottom: "0.1rem" }}>
                          <Typography
                            sx={{
                              padding: "3px 0.5rem 0rem",
                              lineHeight: "1",
                              borderColor: "#fff",
                              width: "fit-content",
                            }}
                          >
                            <Avatar sx={{ color: "#017fff  !important" }}>
                              {item?.type}
                            </Avatar>
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            textDecoration: "underline",
                            fontWeight: 600,
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </div>
                    </div>,
                    {
                      position: "bottom-right",

                      autoClose: 8000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      // closeButton:false,
                      className: "toast-massage",
                      // @ts-ignore
                      // onClose:closeHanler()
                    }
                  )}
              </>
            );
          });
          var notificationId = res?.data?.map((item: any, index: any) => {
            return item?.id;
          });
          if (res?.data[0]?.is_notification == true) {
            FetchNotificationData();
            API.post("/activity/notification/", {
              notification_id: notificationId?.toString(),
            }).then((res: any) => { });
          }
        });
      },

      120000
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isShow = () => {
    //@ts-ignore
    if (store.is_compare?.is_compare == "true") {
      return true;
    } else if (compareOptions && compareOptions[0]?.vault_list?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.Container}>
      <UserAlertsModal
        isOpen={Alertsmodal.isOpen}
        onCloseModal={Alertsmodal.close}
        getApicallhandler={() => getApicallhandler()}
      />
      {BOMDataEntryQRHandler.isOpen && <BOMDataEntryQRModal isOpen={BOMDataEntryQRHandler.isOpen} onClose={handleCloseBOM} />}

      <Grid container className={styles.header_container}>
        <Grid
          item
          xs={4}
          sm={3}
          md={3}
          lg={4}
          xl={4}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <img
            src={logo}
            className={styles.logo}
            alt="xcPEP"
            onClick={() =>
              history.push(
                AUTH.user_category == "Internal"
                  ? "/internallanding"
                  : "/landing"
              )
            }
          />
        </Grid>
        <Grid item xs={4} sm={6} md={6} lg={4} xl={4}>
          <div className={styles.search} ref={OuterRef}>
            {/* <FormControl> */}

            <div className={styles.searchForm}>
              {
                // !isUndefined(projectCount) && projectCount > 0 &&

                <div
                  style={{
                    display: "flex",
                    columnGap: "0.5rem",
                    height: "2rem",
                  }}
                >
                  <div
                    className={styles.inputbox}
                  // tabIndex={1} onKeyDown={(event:any)=>HandleKeyDown(event)}
                  >
                    <div className={styles.searchIcon}>
                      <QrCodeScannerIcon
                        onClick={() => handleQRCodeClick()}
                        titleAccess="Search QR Code"
                        sx={{ cursor: "pointer" }}
                      />
                    </div>
                    <QRRedirectionModal
                      isOpen={QRRedirectionModalOpen.isOpen}
                      onClose={QRRedirectionModalOpen.close}
                    />
                    <ScanQRModal
                      isOpen={ScannerModalOpen.isOpen}
                      onClose={ScannerModalOpen.close}
                    // module={ module }
                    // changeRootId={ changeRootId }
                    />
                    {
                      <input
                        type="search"
                        placeholder="Search"
                        value={selectedValue}
                        className={styles.searchInput}
                        autoFocus={false}
                        style={{ color: "#007fff", fontSize: "0.9rem" }}
                        // @ts-expect-
                        ref={descRef}
                        onKeyPress={(event: any) => handleOnChange(event)}
                        onChange={(event: any) => handleOnChange(event)}
                        onFocus={(e) => FocusHandler(e)}
                      />
                    }

                    <div
                      style={{ display: "none" }}
                      className={styles.AutoSearchBox}
                      ref={inputRef}
                    >
                      <>
                        <div className={styles.searchSubText}>
                          {SearchValue.map((item: any, index: any) => {
                            return (
                              <div>
                                <div
                                  key={index}
                                  //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                                  className={styles.innserSearchSubText}
                                  onClick={() => {
                                    if (
                                      item?.original_model_ob === "ideastorage"
                                    ) {
                                      searchHandlerIdeaClick(
                                        item?.vault_id,
                                        item?.value,
                                        item?.vault_name,
                                        item?.part_no,
                                        item?.topvault_name,
                                        item?.topvault_id,
                                        item?.model_ob,
                                        item?.original_model_ob
                                      );
                                    } else {
                                      handleChangeSelection(
                                        item?.vault_id,
                                        item?.value,
                                        item?.vault_name,
                                        item?.part_no,
                                        item?.topvault_name,
                                        item?.topvault_id,
                                        item?.model_ob,
                                        item?.original_model_ob
                                      );
                                    }
                                  }}
                                >
                                  <span
                                    onClick={() => {
                                      if (
                                        item?.original_model_ob ===
                                        "ideastorage"
                                      ) {
                                        searchHandlerIdeaClick(
                                          item?.vault_id,
                                          item?.value,
                                          item?.vault_name,
                                          item?.part_no,
                                          item?.topvault_name,
                                          item?.topvault_id,
                                          item?.model_ob,
                                          item?.original_model_ob
                                        );
                                      } else {
                                        handleChangeSelection(
                                          item?.vault_id,
                                          item?.value,
                                          item?.vault_name,
                                          item?.part_no,
                                          item?.topvault_name,
                                          item?.topvault_id,
                                          item?.model_ob,
                                          item?.original_model_ob
                                        );
                                      }
                                    }}
                                  >
                                    {item?.model_ob == "topvault" ? (
                                      <span>{item?.topvault_name}</span>
                                    ) : (
                                      <>
                                        {/* <span>{item?.value} </span> */}
                                        <span>
                                          {item?.original_model_ob ===
                                            "ideastorage"
                                            ? item?.value
                                            : item?.vault_name}
                                          <span>
                                            <span>(</span>
                                            {item?.part_no}
                                            <span>)</span>
                                          </span>
                                          | <span>{item?.topvault_name}</span>
                                        </span>
                                      </>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      // marginRight: "0.5rem",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    <SearchIcon />
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                          {SearchValue && SearchValue?.length == 0 && (
                            <p
                              style={{
                                paddingLeft: "1rem",
                                fontSize: "0.9rem",
                              }}
                            >
                              No Data
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                  <span
                    style={{
                      marginRight: "0.5rem",
                      cursor: "pointer",
                      // paddingTop: "5px",
                    }}
                  >
                    <SearchIcon
                      titleAccess="Search"
                      onClick={() => searchHandler()}
                    />
                  </span>
                </div>
              }
            </div>
          </div>
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={4} xl={4}>
          <div className={styles.menu}>
          {AUTH.isSuperUser &&  !window.location.href?.includes("chat") && (
                  <img
                    src={AIChatIcon}
                    style={{  width: "2rem", height: "2rem",cursor:'pointer' }}
                    onClick={() => history.push("/chat/0/0/0/mychat/0/0")}
                  />
                )}
            {AUTH.user_category == "Internal" ? (
              <>
                {routeStatus && (
                  <Switch
                    size="small"
                    color={width > 1200 ? "primary" : "default"}
                    onChange={(event: any) => switchpage(event)}
                    checked={checked}
                  />
                )}
                {/* && apiBase?.includes("localhost") */}
                
                {AUTH.isSuperUser && (
                  <IconButton
                    sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                  >
                    <RoomPreferencesOutlinedIcon
                      titleAccess="Project Directory"
                      sx={{
                        cursor: "pointer",
                        color: {
                          xs: "white",
                          sm: "white",
                          md: "white",
                          lg: "primary.main",
                          xl: "primary.main",
                        },
                      }}
                      onClick={() => history.push("/project_directory")}
                    />
                  </IconButton>
                )}
                {calibrationAccess && calibrationAccess && (
                  <IconButton
                    sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                  >
                    <ThermostatIcon
                      titleAccess="Calibration"
                      sx={{
                        cursor: "pointer",
                        color: {
                          xs: "white",
                          sm: "white",
                          md: "white",
                          lg: "primary.main",
                          xl: "primary.main",
                        },
                      }}
                      onClick={() => history.push("/calibration")}
                    />
                  </IconButton>
                )}
                {AUTH.isSuperUser && (
                  <IconButton
                    sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                  >
                    <ManageAccountsOutlinedIcon
                      titleAccess="User Management"
                      sx={{
                        cursor: "pointer",
                        color: {
                          xs: "white",
                          sm: "white",
                          md: "white",
                          lg: "primary.main",
                          xl: "primary.main",
                        },
                      }}
                      onClick={() =>
                        history.push("/user-permissions/Gen/0/all/allModule")
                      }
                    />
                  </IconButton>
                )}

                <IconButton
                  sx={{ display: { xs: "block", sm: "block", md: "none" } }}
                >
                  <CardMedia
                    component="img"
                    src={width > 1200 ? qrscan : qrscanwhite}
                    title="QR Code scanner"
                    style={{
                      width: "1.5rem",
                      height: "auto",
                      cursor: "pointer",
                      margin: "2px",
                    }}
                    onClick={handleQRCodeClick}
                  />
                </IconButton>
              </>
            ) : (
              ""
            )}

            {DataBaseAccess && (
              <IconButton
                onClick={() =>
                  history.push("/data-base/data-base-landing-page")
                }
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    cursor: "pointer",
                  },
                }}
              >
                <CardMedia
                  title="Database"
                  component="img"
                  sx={{
                    width: "auto",
                    height: "1.5rem",
                    cursor: "pointer",
                    margin: "2px",
                  }}
                  src={width > 1250 ? database : databasewhite}
                  alt="Database"
                />
              </IconButton>
            )}

            {typeof currentUrlinstance !== "undefined" &&
              currentUrlinstance.includes("tvsm") && (
                <IconButton
                  className={styles.videoIcon}
                  sx={{ margin: "0 1rem", display: { xs: "none", sm: "none" } }}
                >
                  <VideoSettingsIcon
                    className={styles.blinkText}
                    sx={{ color: "#007fff" }}
                    titleAccess="Video guide"
                    onClick={() => history.push("/videoguide")}
                  />
                </IconButton>
              )}
            <IconButton
              className={styles.Avatar}
              onClick={(e: any) => handleClickPopup(e)}
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <Badge
                badgeContent={notificationStatus && notificationStatus?.length}
                color="error"
              >
                <NotificationsIcon
                  titleAccess="Notification"
                  className={styles.menuIcon}
                  sx={{
                    color: {
                      sm: "white",
                      xs: "white",
                      md: "white",
                      lg: "primary.main",
                      xl: "primary.main",
                    },
                  }}
                />
              </Badge>
            </IconButton>

            {/* <Tooltip title="Menu"> */}
            <IconButton
              className={styles.Avatar}
              onClick={handleClick}
              sx={{ color: "white" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              title="Menu"
            >
              <Box
                component="img"
                sx={{
                  content: {
                    xs: `url(${menuwhite})`, //img src from xs up to md
                    lg: `url(${menu})`, //img src from md and up
                  },
                  height: "1.7rem",
                  width: "1.7rem",
                }}
                alt="Menu"
              />
            </IconButton>
            {/* </Tooltip> */}
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 8,
                    width: 20,
                    height: 18,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              sx={{
                ".MuiList-root.MuiMenu-list": {
                  padding: 0,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => feedbackButtonClick()}
              >
                <Avatar className={styles.Avatar}>
                  <ChatIcon
                    titleAccess="Feedback"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }} color="primary">
                  Feedback
                </Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => GuestUserHandler()}
              >
                <Avatar className={styles.Avatar}>
                  <BadgeIcon
                    titleAccess="Guest User"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>Guest User</Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => {
                  history.push("/drive/0/my/0");
                  sessionStorage.removeItem("SearchKey");
                  sessionStorage.removeItem("ProjectId");
                  sessionStorage.removeItem("SearchKey");
                  sessionStorage.removeItem("ProjectId");
                  sessionStorage.removeItem("ActiveIndex");
                  sessionStorage.removeItem("SearchKey");
                  sessionStorage.removeItem("projectKey");
                  sessionStorage.removeItem("ProjectIds");
                  sessionStorage.removeItem("ExactSearchKey");
                  sessionStorage.removeItem("VoultId");
                  // @ts-ignore
                  sessionStorage.setItem("FreeseStatus", false);
                }}
              >
                <Avatar className={styles.Avatar}>
                  <StorageIcon
                    titleAccess="Drive"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>Drive</Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => HandoverUserHandler()}
              >
                <Avatar className={styles.Avatar}>
                  <KeyIcon
                    titleAccess="Handover"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>Handover</Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => history.push("/activity-logs/activity/all")}
              >
                <Avatar className={styles.Avatar}>
                  <HistoryIcon
                    titleAccess="ActivityLogs"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>Activity Logs</Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => MinutesofMeeting()}
              >
                <Avatar className={styles.Avatar}>
                  <img
                    src={MoMIcon}
                    // fill="primary"
                    title="Minutes of Meeting"
                    className={styles.menuIcon}
                    color="primary"
                    style={{ width: "1.3rem" }}
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>MOM</Typography>
              </MenuItem>
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => MyreportsHandler()}
              >
                <Avatar className={styles.Avatar}>
                  <DescriptionIcon
                    fill="primary"
                    titleAccess="My Reports"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>My Reports</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                onClick={() => {
                  history.push("/ProfilePage/0/res/0");
                  sessionStorage.removeItem("SearchKey");
                  sessionStorage.removeItem("ProjectId");
                  sessionStorage.removeItem("ActiveIndex");
                  sessionStorage.removeItem("SearchKey");
                  sessionStorage.removeItem("projectKey");
                  sessionStorage.removeItem("ProjectIds");
                  // @ts-ignore
                  sessionStorage.setItem("FreeseStatus", false);
                  sessionStorage.removeItem("ExactSearchKey");
                  sessionStorage.removeItem("VoultId");
                }}
              >
                <Avatar color="primary">
                  <PersonRoundedIcon
                    titleAccess="Profile"
                    className={styles.menuIcon}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>My Account</Typography>
              </MenuItem>
              <MenuItem onClick={Logout}>
                <Avatar
                  color="primary"
                  className={styles.Avatar}
                  onClick={Logout}
                >
                  <PowerSettingsNewIcon
                    titleAccess="Logout"
                    className={styles.menuIconLog}
                    color="primary"
                  />
                </Avatar>
                <Typography sx={{ fontSize: "1rem" }}>Logout</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>

      <Box>
        <Popover
          id={NotificationId}
          open={openNotification}
          anchorEl={anchorElpopNotification}
          onClose={handleClosePopup}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          PaperProps={{
            elevation: 0,
            sx: {
              // overflowY: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              // mt: 1.5,
              mr: 1,
              width: "25rem",
              // left: "90rem !important",
              top: "3rem !important",
              overflow: "inherit",
              // height: "35.3rem",
              // "& .MuiAvatar-root": {
              //   width: 32,
              //   height: 32,
              //   ml: -0.5,
              //   mr: 1,
              // },
              "& .MuiPopover-paper": {
                overflowX: "inherit !important",
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 2,
                right: 10,
                width: 20,
                height: 18,
                bgcolor: "white",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
        >
          <Box className={styles.notifyHeader} sx={{ display: "block" }}>
            {/* <Typography color="primary" className={styles.notificationTitle}>
              Notifications
            </Typography>  */}
            {alerts && alerts?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  // padding: "0rem 1rem",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ margin: "0 auto" }}>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label">Notifications</FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ margin: "0 auto", fontSize: "1rem" }}
                  >
                    <FormControlLabel
                      value="All"
                      checked={alertStatus}
                      control={<Radio />}
                      label="All"
                      onChange={() => RemovedFilterNotifaction()}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                        "&.MuiFormControlLabel-root .MuiFormControlLabel-label":
                        {
                          fontSize: "1rem",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Read"
                      checked={ReadStatus}
                      control={<Radio />}
                      label="Read"
                      onChange={() => FilterNoticationRead()}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                        "&.MuiFormControlLabel-root .MuiFormControlLabel-label":
                        {
                          fontSize: "1rem",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="UnRead"
                      checked={UnReadStatus}
                      control={<Radio />}
                      label="Unread"
                      onChange={() => FilterNotificationUnread()}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "1.5rem" },
                        "&.MuiFormControlLabel-root .MuiFormControlLabel-label":
                        {
                          fontSize: "1rem",
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography
                  style={{
                    cursor: "pointer",
                    marginTop: "0rem",
                    fontSize: "1rem",
                    color: "red",
                    textDecoration: "underline",
                  }}
                  title="Clear All"
                  sx={{ padding: { lg: "1px 1rem", xl: "0.2rem 1rem" } }}
                  onClick={() => deleteAllNotification()}
                >
                  Clear All
                  {/* <HighlightOffIcon /> */}
                </Typography>
              </Box>
            )}
          </Box>

          {alertStatus && (
            <Box
              className={styles.inner_notification}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <>
                {alerts && alerts && alerts?.length > 0 ? (
                  alerts.map((notifiactionItem: any) => {
                    if (notifiactionItem.type === "Signup") {
                      return (
                        <>
                          <LoginInfo
                            alertsData={alerts}
                            itemId={notifiactionItem.id}
                            desc={notifiactionItem.description}
                            Handler={() =>
                              handleCancel(
                                notifiactionItem.id,
                                notifiactionItem.fullname
                              )
                            }
                            created={notifiactionItem.created_at}
                            description={notifiactionItem.description}
                            ModuleName={notifiactionItem?.module}
                            UnReadHandler={() =>
                              UnreadHandler(notifiactionItem?.id)
                            }
                            notifiactionItemStatus={notifiactionItem.is_read}
                            FetchNotification={() => FetchNotificationData()}
                          />
                        </>
                      );
                    } else if (notifiactionItem?.type === "Text") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}
                              <a
                                className={styles.userNotifydes}
                                style={{
                                  cursor: "pointer",
                                  color: "#007fff",
                                  borderBottom: "1px solid",
                                }}
                                onClick={() => {
                                  if (notifiactionItem?.ansector?.length > 0) {
                                    sessionStorage.setItem(
                                      "BOMCreateNode",
                                      JSON.stringify(notifiactionItem?.ansector)
                                    );
                                  }
                                  window.open(notifiactionItem?.url);
                                }}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type == "Feedback") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}

                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Trash") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Drive") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>

                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                {/* <div 
                              style ={{cursor:"pointer"}}
                              className={styles.userNotifydes} onClick={()=>window.open(notifiactionItem?.url,"_blank")}> */}
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Idea") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  title={notifiactionItem?.type}
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  <span title={notifiactionItem?.type}>
                                    {notifiactionItem?.module}
                                  </span>
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Mom") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Permission") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              NotificationHandler(notifiactionItem?.id)
                            }
                          >
                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>

                                    <Typography style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.description}
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}
                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>

                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div
                                    className={styles.userNotifydes}
                                    style={{ color: "green !important" }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "green !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}
                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>

                                    <Typography
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "red",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "red !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "red !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <>
                                  <CheckIcon
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Accept"}
                                    onClick={() =>
                                      handleAccepte(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                  <ClearIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Reject"}
                                    onClick={() =>
                                      handleReject(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          NotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          NotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                </>
                              )}
                          </a>
                        </>
                      );
                    }
                  })
                ) : (
                  <div className={styles.noNotification}>
                    <img src={noNoti} style={{ height: "5rem" }} />
                    {/* <NotificationsOffIcon
                      color="primary"
                      sx={{ fontSize: "3rem" }}
                    /> */}
                    <Typography sx={{ fontSize: "0.9rem" }} color="primary">
                      Hey there, your notification box is empty right now. Maybe
                      it's time to take a break !
                    </Typography>
                  </div>
                )}
              </>
            </Box>
          )}

          {ReadStatus && (
            <Box
              className={styles.inner_notification}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <>
                {ReadNotifications &&
                  ReadNotifications &&
                  ReadNotifications?.length > 0 ? (
                  ReadNotifications.map((notifiactionItem: any) => {
                    if (notifiactionItem.type === "Signup") {
                      return (
                        <>
                          <LoginInfo
                            alertsData={ReadNotifications}
                            itemId={notifiactionItem.id}
                            desc={notifiactionItem.description}
                            Handler={() =>
                              handleCancel(
                                notifiactionItem.id,
                                notifiactionItem.fullname
                              )
                            }
                            created={notifiactionItem.created_at}
                            description={notifiactionItem.description}
                            ModuleName={notifiactionItem?.module}
                            UnReadHandler={() =>
                              UnreadHandler(notifiactionItem?.id)
                            }
                            notifiactionItemStatus={notifiactionItem.is_read}
                            FetchNotification={() => FetchNotificationData()}
                          />
                        </>
                      );
                    } else if (notifiactionItem?.type === "Text") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}
                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type == "Feedback") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}

                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Trash") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Drive") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div 
                            
                              className={styles.userNotifydes}
                              style={{cursor:"pointer"}}
                              
                              onClick={()=>window.open(notifiactionItem?.url,"_blank")}> */}

                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Idea") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Mom") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    FilterReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Permission") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              FilterReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.description}
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}
                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <div
                                  style={{
                                    width: "100%",
                                    color: "green !important",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "green !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                        color: "red !important",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "red !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "red !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <>
                                  <CheckIcon
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Accept"}
                                    onClick={() =>
                                      handleAccepte(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                  <ClearIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Reject"}
                                    onClick={() =>
                                      handleReject(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          NotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          NotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                </>
                              )}
                          </a>
                        </>
                      );
                    }
                  })
                ) : (
                  <div className={styles.noNotification}>
                    <img src={noNoti} style={{ height: "5rem" }} />
                    {/* <NotificationsOffIcon
                      color="primary"
                      sx={{ fontSize: "3rem" }}
                    /> */}
                    <Typography sx={{ fontSize: "0.9rem" }} color="primary">
                      Hey there, your notification box is empty right now. Maybe
                      it's time to take a break !
                    </Typography>
                  </div>
                )}
              </>
            </Box>
          )}
          {UnReadStatus && (
            <Box
              className={styles.inner_notification}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <>
                {UnReadNotifications &&
                  UnReadNotifications &&
                  UnReadNotifications?.length > 0 ? (
                  UnReadNotifications?.map((notifiactionItem: any) => {
                    if (notifiactionItem.type === "Signup") {
                      return (
                        <>
                          <LoginInfo
                            alertsData={UnReadNotifications}
                            itemId={notifiactionItem.id}
                            desc={notifiactionItem.description}
                            Handler={() =>
                              handleCancel(
                                notifiactionItem.id,
                                notifiactionItem.fullname
                              )
                            }
                            created={notifiactionItem.created_at}
                            description={notifiactionItem.description}
                            ModuleName={notifiactionItem?.module}
                            UnReadHandler={() =>
                              UnreadHandler(notifiactionItem?.id)
                            }
                            notifiactionItemStatus={notifiactionItem.is_read}
                            FetchNotification={() => FetchNotificationData()}
                          />
                        </>
                      );
                    } else if (notifiactionItem?.type === "Text") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}
                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    FilterUnReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type == "Feedback") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              {/* <div className={styles.userNotifydes}> */}
                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    FilterUnReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Trash") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    NotificationHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Drive") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <a
                                className={styles.userNotifydes}
                                style={{ cursor: "pointer", color: "#007fff" }}
                                href={notifiactionItem?.url}
                                target="_blank"
                              // onClick={()=>window.open(notifiactionItem?.url,"_blank")}
                              >
                                <a
                                  style={{ fontSize: "0.87rem" }}
                                  href={notifiactionItem?.url}
                                >
                                  {notifiactionItem?.description}
                                </a>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </a>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    FilterUnReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Idea") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    FilterUnReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Mom") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            <div style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #007fff",
                                    width: "fit-content",
                                    padding: "0 0.5rem",
                                    fontSize: "0.87rem",
                                  }}
                                >
                                  {notifiactionItem?.module}
                                </Box>
                                <Typography style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.created_at}
                                </Typography>
                              </Box>
                              <div className={styles.userNotifydes}>
                                <div style={{ fontSize: "0.87rem" }}>
                                  {notifiactionItem?.description}
                                </div>
                                {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                              </div>
                            </div>

                            <Box>
                              {notifiactionItem?.is_read == true ? (
                                <span
                                  onClick={() =>
                                    UnreadHandler(notifiactionItem?.id)
                                  }
                                >
                                  <span title={"Mark Unread"}>
                                    <DraftsIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    notifiactionItem?.is_read == false &&
                                    FilterUnReadNotificationHandler(
                                      notifiactionItem?.id
                                    )
                                  }
                                >
                                  <span title={"Mark read"}>
                                    <MailIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        margin: "0 0.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </span>
                              )}
                            </Box>
                            <Box
                              onClick={() =>
                                deleteNotification(notifiactionItem?.id)
                              }
                            >
                              <HighlightOffIcon
                                className={styles.notificIcon}
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                }}
                                titleAccess="Clear"
                              />
                            </Box>
                          </a>
                        </>
                      );
                    } else if (notifiactionItem.type === "Permission") {
                      return (
                        <>
                          <a
                            className={
                              notifiactionItem?.is_read
                                ? styles.Notification_Container
                                : styles.Notification_Container_active
                            }
                            onClick={() =>
                              notifiactionItem?.is_read == false &&
                              FilterUnReadNotificationHandler(
                                notifiactionItem?.id
                              )
                            }
                          >
                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div style={{ fontSize: "0.87rem" }}>
                                      {notifiactionItem?.description}
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}
                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <div
                                  style={{
                                    width: "100%",
                                    color: "green !important",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "green !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "green !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <div style={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        border: "1px solid #007fff",
                                        width: "fit-content",
                                        padding: "0 0.5rem",
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      {notifiactionItem?.module}
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                        color: "red !important",
                                      }}
                                    >
                                      {notifiactionItem?.created_at}
                                    </Typography>
                                  </Box>
                                  <div className={styles.userNotifydes}>
                                    <div
                                      style={{
                                        fontSize: "0.87rem",
                                        color: "red !important",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: "red !important",
                                        }}
                                      >
                                        {notifiactionItem?.description}
                                      </Typography>
                                    </div>
                                    {/* <div style={{ fontSize: "0.87rem", width: '5rem' }}> {created}</div> */}
                                  </div>
                                </div>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Requested" && (
                                <>
                                  <CheckIcon
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Accept"}
                                    onClick={() =>
                                      handleAccepte(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                  <ClearIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    titleAccess={"Reject"}
                                    onClick={() =>
                                      handleReject(
                                        notifiactionItem.details.access,
                                        notifiactionItem.details.module,
                                        notifiactionItem.details.product,
                                        notifiactionItem.details.sub_module,
                                        notifiactionItem.id,
                                        notifiactionItem?.fullname
                                      )
                                    }
                                  />
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Accepted" && (
                                <>
                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          NotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>
                                </>
                              )}

                            {notifiactionItem?.permission_status ==
                              "Rejected" && (
                                <>
                                  <Box>
                                    {notifiactionItem?.is_read == true ? (
                                      <span
                                        onClick={() =>
                                          UnreadHandler(notifiactionItem?.id)
                                        }
                                      >
                                        <span title={"Mark Unread"}>
                                          <DraftsIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          notifiactionItem?.is_read == false &&
                                          FilterUnReadNotificationHandler(
                                            notifiactionItem?.id
                                          )
                                        }
                                      >
                                        <span title={"Mark read"}>
                                          <MailIcon
                                            className={styles.notificIcon}
                                            sx={{
                                              color: "primary.main",
                                              margin: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </Box>

                                  <Box
                                    onClick={() =>
                                      deleteNotification(notifiactionItem?.id)
                                    }
                                  >
                                    <HighlightOffIcon
                                      className={styles.notificIcon}
                                      sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                      }}
                                      titleAccess="Clear"
                                    />
                                  </Box>
                                </>
                              )}
                          </a>
                        </>
                      );
                    }
                  })
                ) : (
                  <div className={styles.noNotification}>
                    <img src={noNoti} style={{ height: "5rem" }} />
                    {/* <NotificationsOffIcon
                      color="primary"
                      sx={{ fontSize: "3rem" }}
                    /> */}
                    <Typography sx={{ fontSize: "0.9rem" }} color="primary">
                      Hey there, your notification box is empty right now. Maybe
                      it's time to take a break !
                    </Typography>
                  </div>
                )}
              </>
            </Box>
          )}
        </Popover>
      </Box>
      <GuestUserModal
        isOpen={GuestUserModalOpen.isOpen}
        onCloseModal={GuestUserModalOpen.close}
      />
      <HandoverModal
        isOpen={HandoverModalOpen.isOpen}
        onCloseModal={HandoverModalOpen.close}
      />
    </div>
  );
}
