import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddMachineModal.module.scss";

import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";

import { API } from "../../../api-services";
import { useState } from "react";
// import MachineFlagS3Upload from "./MachineFlagS3Upload";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
// import MachineFlagS3UploadFetch from "./MachineFlagS3UploadFetch";
// import MachineSpecificFlagS3Upload from "./MachineSpecificFlagS3Upload";
// import MachineSpecificFlagS3UploadFetch from "./MachineSpecificFlagS3UploadFetch";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30vw",
  maxWidth: "50vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  duty: yup.string().required("Please enter Duty"),
  // unit: yup.mixed().required("Please enter unit"),
  // currency_id: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  type?: any;
  getRevisionData?: any;
  setCounter: any;
  swalalterHandlerAdd: any;
}

function EditMachineModal(props: IWatermarkModelProps) {
  const {
    isOpen,
    onCloseModal,
    actions,
    getRevisionData,
    type,
    setCounter,
    swalalterHandlerAdd,
  } = props;
  const { databaseName, typeId, revisionId } = useRouteParams<any>();

  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [isSpecLoadingsave, setIsSpecLoadingSave] = React.useState<any>(false);

  const [flagFile, setFlagFile] = useState<any>(null);
  const [specflagFile, setSpecFlagFile] = useState<any>(null);

  const inputElRef = React.useRef<any>();
  const specinputElRef = React.useRef<any>();
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");
  // ----------------------------------------------

  var ErrorModalFiles = useBaseModal();
  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };
  const specbrowseFiles = () => {
    return specinputElRef.current?.click?.();
  };
  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setFlagFile(e.target.files[0]);
    }
  };
  const handleImgSpecific = (e: any) => {
    if (e.target.files[0]) {
      setSpecFlagFile(e.target.files[0]);
    }
  };
  const swalMsgalterHandlerAdd = (type: any, msgtype: any, msg: any) => {
    if (type == true) {
      swalalterHandlerAdd(type, msgtype);
    } else {
      ErrorModalFiles.open();
      setErrorModalFileMsg(msg);
    }
  };
  React.useEffect(() => {
    API.get("/api/db/cost_databases/", {
      database_type: "machine",
    })
      .then((res: any) => {
        setCategoryList(res?.data);
      })
      .catch((err: any) => { });
  }, []);

  React.useEffect(() => {
    if (type == "edit") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes(actions?.currency_code || "");
      } else {
        setSelectedCurrencyCodes([]);
      }

    //   const targetCurrency = countryList?.find((curr:any) => {
    //     console.log(curr?.currency_code, actions.currency_code, curr?.currency_code==actions.currency_code, "hii");
    
    //     if (curr?.currency_code == actions.currency_code) {
    //         return curr;
    //     }
    //   })
       console.log( actions, "hii");
      

      formik.setFieldValue("duty", actions?.duty);
      // formik.setFieldValue("unit", actions?.unit);
    //    formik.setFieldValue("currency_id", actions?.currency_id);
    // formik.setFieldValue("currency_code", actions?.currency_code);
    }
  }, [actions, countryList]);

  // console.log(actions);

  const formik = useFormik({
    initialValues: {

      id: 0,
duty:"",
      unit: "Hour",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        console.log("hi", values);
        
      values.currency_id = String(values.currency_id);
      const { currency_code, ...apiPayload } = values; // remove currency_code from apiPayload  as it is not needed in the api
    //   if (type == "edit") {
    //     if (flagFile != null || flagFile != undefined) {
    //       MachineFlagS3Upload(
    //         [flagFile],
    //         values,
    //         setIsLoadingSave,
    //         setCounter,
    //         "",
    //         setFlagFile,
    //         actions?.id,
    //         "Edit",
    //         swalMsgalterHandlerAdd,
    //         onCloseModal
    //       );
    //     } 
    //     else
    //      {
          setIsLoadingSave(true);
          API.put(`/api/db/machine_cost/${actions?.id}/`, apiPayload, {}, 0)
            .then((res: any) => {
              setIsLoadingSave(false);
              setFlagFile(null);
              onCloseModal();
              swalMsgalterHandlerAdd(true, "edit", "");
              setCounter((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles.open();
              setErrorModalFileMsg(data[0]);
              setIsLoadingSave(false);
            });
        }
    //     // specific flag upload
    //     if (specflagFile != null || specflagFile != undefined) {
    //       MachineSpecificFlagS3Upload(
    //         [specflagFile],
    //         values,
    //         setIsSpecLoadingSave,
    //         setCounter,
    //         "",
    //         setSpecFlagFile,
    //         actions?.id,
    //         "Edit",
    //         swalMsgalterHandlerAdd,
    //         onCloseModal
    //       );
    //     } else {
    //       setIsSpecLoadingSave(true);
    //       API.put(`/api/db/machine/${actions?.id}/`, values, {}, 0)
    //         .then((res: any) => {
    //           setIsSpecLoadingSave(false);
    //           setSpecFlagFile(null);
    //           onCloseModal();
    //           swalMsgalterHandlerAdd(true, "edit", "");
    //           setCounter((prev: any) => prev + 1);
    //         })
    //         .catch((err: any) => {
    //           const { data } = err?.response;
    //           ErrorModalFiles.open();
    //           setErrorModalFileMsg(data[0]);
    //           setIsSpecLoadingSave(false);
    //         });
    //     }
    //   } 
    // //   else {
    // //     if (flagFile != null || flagFile != undefined) {
    // //       if (!databaseName?.includes("DB")) {
    // //         MachineFlagS3UploadFetch(
    // //           [flagFile],
    // //           values,
    // //           setIsLoadingSave,
    // //           setCounter,
    // //           formik,
    // //           setFlagFile,
    // //           null,
    // //           "Create",
    // //           swalMsgalterHandlerAdd,
    // //           onCloseModal,
    // //           typeId,
    // //           revisionId
    // //         );
    // //       } else {
    // //         MachineFlagS3Upload(
    // //           [flagFile],
    // //           values,
    // //           setIsLoadingSave,
    // //           setCounter,
    // //           formik,
    // //           setFlagFile,
    // //           null,
    // //           "Create",
    // //           swalMsgalterHandlerAdd,
    // //           onCloseModal
    // //         );
    // //       }
    // //     } else {
    // //       setIsLoadingSave(true);
    // //       API.post(`/api/db/machine/`, values, {}, 0)
    // //         .then((res: any) => {
    // //           if (!databaseName?.includes("DB")) {
    // //             API.post("/api/db/machine_cost/", {
    // //               commodity: typeId,

    // //               machines: [res.data.id],

    // //               revision: revisionId,
    // //             })
    // //               .then((res: any) => {
    // //                 setIsLoadingSave(false);
    // //                 onCloseModal();
    // //                 // swalMsgalterHandlerAdd(true, "create", "");
    // //                 setFlagFile(null);
    // //                 setCounter((prev: any) => prev + 1);
    // //                 formik.resetForm();
    // //               })
    // //               .catch((err: any) => { });
    // //           } else {
    // //             setIsLoadingSave(false);
    // //             onCloseModal();
    // //             swalMsgalterHandlerAdd(true, "create", "");
    // //             setFlagFile(null);
    // //             setCounter((prev: any) => prev + 1);
    // //             formik.resetForm();
    // //           }
    // //         })
    // //         .catch((err: any) => {
    // //           const { data } = err?.response;
    // //           ErrorModalFiles.open();
    // //           setErrorModalFileMsg(data[0]);
    // //           setIsLoadingSave(false);
    // //         });
    // //     }
    // //     // Specific flag create
    // //     if (specflagFile != null || specflagFile != undefined) {
    // //       if (!databaseName?.includes("DB")) {
    // //         MachineSpecificFlagS3UploadFetch(
    // //           [specflagFile],
    // //           values,
    // //           setIsSpecLoadingSave,
    // //           setCounter,
    // //           formik,
    // //           setSpecFlagFile,
    // //           null,
    // //           "Create",
    // //           swalMsgalterHandlerAdd,
    // //           onCloseModal,
    // //           typeId,
    // //           revisionId
    // //         );
    // //       } else {
    // //         MachineSpecificFlagS3Upload(
    // //           [specflagFile],
    // //           values,
    // //           setIsSpecLoadingSave,
    // //           setCounter,
    // //           formik,
    // //           setSpecFlagFile,
    // //           null,
    // //           "Create",
    // //           swalMsgalterHandlerAdd,
    // //           onCloseModal
    // //         );
    // //       }
    // //     } else {
    // //       setIsSpecLoadingSave(true);
    // //       API.post(`/api/db/machine/`, values, {}, 0)
    // //         .then((res: any) => {
    // //           if (!databaseName?.includes("DB")) {
    // //             API.post("/api/db/machine_cost/", {
    // //               commodity: typeId,

    // //               machines: [res.data.id],

    // //               revision: revisionId,
    // //             })
    // //               .then((res: any) => {
    // //                 setIsSpecLoadingSave(false);
    // //                 onCloseModal();
    // //                 // swalMsgalterHandlerAdd(true, "create", "");
    // //                 setSpecFlagFile(null);
    // //                 setCounter((prev: any) => prev + 1);
    // //                 formik.resetForm();
    // //               })
    // //               .catch((err: any) => { });
    // //           } else {
    // //             setIsSpecLoadingSave(false);
    // //             onCloseModal();
    // //             swalMsgalterHandlerAdd(true, "create", "");
    // //             setSpecFlagFile(null);
    // //             setCounter((prev: any) => prev + 1);
    // //             formik.resetForm();
    // //           }
    // //         })
    // //         .catch((err: any) => {
    // //           const { data } = err?.response;
    // //           ErrorModalFiles.open();
    // //           setErrorModalFileMsg(data[0]);
    // //           setIsSpecLoadingSave(false);
    // //         });
    // //     }
    // //   }
    // },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database: true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  // console.log(formik.values);
  // console.log(formik.errors);
  // console.log(formik.touched);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Machine
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "0 2rem 1rem",
              textAlign: "center",
              maxHeight: "90vh",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <form onSubmit={formik.handleSubmit}>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box sx={{width:"100%", marginTop:"0.75rem"}}>
                    <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Duty <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{

                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="duty"
                    name="duty"
                    onChange={formik.handleChange}
                    value={formik.values.duty}
                    error={
                      formik.touched.duty &&
                      Boolean(formik.errors.duty)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.duty && formik.errors.duty
                    }
                  />
                </FormControl>
                </Box>
                

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);


                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));

                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                 
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        readOnly:true,
                        style: { color: "#007fff" },
                        inputProps: {
                          pattern: "^[^0-9]*$", // This regex pattern disallows any numerical input
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"                    
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box> */}
              
              </Box>

             

             

              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  variant="contained"
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1,
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  type="submit"
                  loading={isLoadingsave}
                >
                  {type == "edit" ? "Save" : "Add"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default EditMachineModal;
