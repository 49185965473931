import { Box, Typography } from '@mui/material';
import * as React from 'react';
import BOMEntrySection from './BOMEntrySection';
import BOMImagesandVideosCombine from './BOMImagesandVideos/BOMImagesandVideosCombine';
import BOMEntryTreeSection from './BOMEntryTreeSection';
import { useParams } from 'react-router-dom';

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { API } from "../../../api-services";
import { createContext } from "react";

export interface IBOMCreateCombinePageProps {
  setrecyclebinCounter: any;
  setBomCategory?:any;
  bomCategory?:any;
}

interface Params {
  projectId: any;
  projectName: any;
  expandTable: any;
  initialTopVault: any;
  topVaultIds: any;
}

export default function  BOMCreateCombinePage(
  props: IBOMCreateCombinePageProps
) {
  const [isActive, setIsActive] = React.useState(true);

  const { treeabbr } = useParams<any>()
  const [ParentsCounter, setParentsCounter] = React.useState<any>(0)
  const [syncColor, setSyncColor] = React.useState<any>("")
  const [categoryCounter, setCategoryCounter] = React.useState<any>(0)
  const [locatorCount, setLocatorCount] = React.useState<any>(null)
  const { setrecyclebinCounter, setBomCategory, bomCategory } = props
  const BOMTreeSecOpen = () => {
    // toggle
    setIsActive((current) => !current);
    // or set to true
    // setIsActive(true);
  };
  React.useEffect(() => {
    sessionStorage.removeItem("TragetIds")
    sessionStorage.removeItem("copyId")
  }, [])
  const { vault, topVault } = useParams<any>()


  

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "0 0.5rem",
          height: "100%",
          
        }}
      >

        <Box
          sx={{
            width: isActive ? {xs:'20rem', sm: "25vw", md: "25vw", lg: "25vw", xl: "22vw" } : "0vw",
            borderRight: isActive ? "1px solid #ddefff" : '0px',
            height: {xs:'84vh',sm:'',md:'', lg: "89vh", xl: "89vh" },
            // overflowY:{xs:'scroll', sm: "hidden", md: "hidden", lg: "hidden", xl: "hidden" },
            transition: "width 1s ease-in-out",
            
          }}
        >
          <BOMEntryTreeSection setrecyclebinCounter={setrecyclebinCounter} setParentsCounter={setParentsCounter} ParentsCounter={ParentsCounter} syncColor={syncColor} setSyncColor={setSyncColor} locatorCount={locatorCount} setLocatorCount={setLocatorCount} />
          
          <Box sx={{ position: "relative", marginTop: "-2rem", marginLeft: "-3.5rem", width:"fit-content" }}>
              {isActive ? (
                <ArrowCircleLeftIcon titleAccess="Collapse Tree" onClick={BOMTreeSecOpen} sx={{ cursor: 'pointer' }} />
              ) : (
                <ArrowCircleRightIcon titleAccess="Expand Tree" onClick={BOMTreeSecOpen} sx={{ cursor: 'pointer' }} />
              )}
            </Box>
          
        </Box>







        <Box
          sx={{
            width: isActive ? {xs:'50rem',sm:'',md:'', lg: "50vw", xl: "50vw" } : {xs:'70rem',sm:'',md:'', lg: "75vw", xl: "72vw" },
            borderRight: "1px solid #ddefff",
            height: {xs:'84vh',sm:'',md:'', lg: "89vh", xl: "89vh" },
            padding: "0 0.5rem",
            transition: "width 1s ease-in-out",
          }}
        >
          {
            treeabbr == "delete" ? <Box sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30rem"
            }}> <Typography>Select Subsystem/Assembly/Part.</Typography></Box> :
            
              <BOMEntrySection setrecyclebinCounter={setrecyclebinCounter} setParentsCounter={setParentsCounter} ParentsCounter={ParentsCounter} setSyncColor={setSyncColor} setCategoryCounter={setCategoryCounter} categoryCounter={categoryCounter} setBomCategory={setBomCategory} bomCategory={bomCategory} setLocatorCount={setLocatorCount}/>
          }

        </Box>





        <Box
          sx={{
            width: {xs:'21rem',sm:'21rem',md:'20vw', lg: "20vw", xl: "23vw" },
            height: {xs:'84vh', sm:'86vh',md:'86vh',lg: "86vh", xl: "89.5vh" },
            padding: "0 0.5rem",
          }}
        >
          {treeabbr !== "delete" &&
            <BOMImagesandVideosCombine setParentsCounter={setCategoryCounter} ParentsCounter={categoryCounter}/>
          }
        </Box>

      </Box>
    </div>
   
  );
}
